import { Component, EventEmitter, Input, OnInit, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss']
})
export class DateInputComponent implements OnInit, OnChanges {
  @Input() formCtrl: FormControl;
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() hintMessage: string = '';
  @Input() fromMon2Fri;
  @Input() day;
  @Input() disabled: boolean = false;
  @Input() isOnlyMonday: boolean = false;
  @Input() isOnlyFriday: boolean = false;
  @Input() isTodayMinDate = false;
  @Input() minDate: Date;
  @Input() isTwoWeeksMaxDate: Boolean = false;
  @Input() maxDate: Date;
  @Input() isNextWeekMinDate = false;

  @Output() dateChanged = new EventEmitter()

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
  }


  changeDay(event) {
    this.dateChanged.emit(event)
  }
}
