import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatCheckboxChange} from '@angular/material';
import { OnDestroy } from '@angular/core';

@Component({
  selector: 'app-default-popup',
  templateUrl: './default-popup.component.html',
  styleUrls: ['./default-popup.component.scss']
})
export class DefaultPopupComponent implements OnInit, OnDestroy {

  public title: string;
  public description: string;
  public type: string = 'close'; // Available default, close
  public buttons: [];

  constructor(
    public dialogRef: MatDialogRef<DefaultPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.title = data.title;
    this.description = data.description;
    this.type = data.type;
    this.buttons = data.buttons;
  }

  ngOnInit() {
  }

  close(data): void {
    this.dialogRef.close(data);
  }

  ngOnDestroy() {
  }

}
