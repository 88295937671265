import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Room } from 'src/app/shared/models/room.model';

@Component({
  selector: 'app-room-container',
  templateUrl: './room-container.component.html',
  styleUrls: ['./room-container.component.scss']
})
export class RoomContainerComponent implements OnInit {
  @Input() title: string = '';
  @Input() showDetails: boolean = true;
  @Input() rooms: any[];
  @Input() data: any;
  @Input() date: string;
  @Input() isOneRoom: Boolean;
  @Input() isPopup?: Boolean;
  @Input() isDisablePopup?: Boolean;
  @Input() showRoomsOverflow: boolean = false;
  @Input() startHour: string;

  @Output() refresh = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {}

  ngOnChanges() {}

  getRoomStartTime(room:Room):number{
		const date:Date = new Date(this.date);
		const horaire:any = room.horaire.find( horaire=> horaire.day === date.getUTCDay()+1);
		if (horaire) {
			const roomStartDate:Date = new Date(horaire.start);
			return roomStartDate.getUTCHours()-1;
		}
		return 7;
	}

	getRoomEndTime(room:Room):number{
		const date:Date = new Date(this.date);
		const horaire:any = room.horaire.find( horaire=> horaire.day === date.getUTCDay()+1);
		if (horaire) {
			const roomEndDate:Date = new Date(horaire.end);
			const endHour:number = roomEndDate.getUTCHours()+1;
			return 6 < endHour && endHour < 23 ? endHour : 23;
		}
		return 20;
	}

  refreshDate() {
    this.refresh.emit('refresh');
  }

  //Out of context here (for the condition of adding "Salle" or not in the tooltip)
  isNumeric(value: any): boolean {
    return !isNaN(value - parseFloat(value));
  }

}
