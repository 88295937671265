import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import * as moment from 'moment';
import { Observable, Subject, Subscription } from 'rxjs';
import { UtilisService } from '../../services/utilis.service';
import { CustomDateAdapter } from '../../utils/custom-date-picker';

@Component({
  selector: 'app-custom-date-picker-normal',
  templateUrl: './custom-date-picker-normal.component.html',
  styleUrls: ['./custom-date-picker-normal.component.scss'],
  // providers: [{provide: DateAdapter, useClass: CustomDateAdapter}]
})
export class CustomDatePickerNormalComponent implements OnInit, OnDestroy {

  @ViewChild('dp',{static : false}) dp;
  @Output() dateSwitch = new EventEmitter<string>();
  @Output() SelectedDay = new EventEmitter<string>();
  @Output() updateAgendas = new EventEmitter<boolean>();
  @Input() day: moment.Moment;
  @Input() showArrows:boolean = true;
  @Input() isDisableWeek:boolean;
  @Input() isDisablePicker:boolean;
  @Input() isThereNewModifs:boolean;
  @Input() isVerifyModifs:boolean;
  @Input() refreshDateSubject: Subject<void> = new Subject<void>();

  private refreshDateSubscription: Subscription;

  date: FormControl;
  popupSubscription: any;

  myFilter = (d: any): boolean => {
    const day = new Date(d._d).getUTCDay();
    // Prevent Saturday and Sunday from being selected.
    return this.isDisableWeek===true || this.isDisableWeek !== undefined
      ? day !== 5 && day !== 6 && this.isDisableWeek
      : true;
  }

  onlyMon2Fri = (d): boolean => {
     if(!this.isDisableWeek) return true;
     const day = new Date(d._d).getUTCDay();
    return day !== 5 && day !== 6
  };

  constructor(
    private utilisService : UtilisService
  ) {}


  ngOnDestroy(): void {
    if (this.refreshDateSubscription) {
      this.refreshDateSubscription.unsubscribe();
    }

    if (this.popupSubscription) {
      this.popupSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.day && changes.day.previousValue) {
      this.initDateFromDay();
    }
  }

  ngOnInit() {
    this.initDateFromDay();

    this.refreshDateSubscription = this.refreshDateSubject.subscribe(() => {
      this.initDateFromDay();
    });
  }

  initDateFromDay(): void {
    this.date = new FormControl({value:this.day,disabled:true});
  }

  dateSwitchFunc(type){
    this.dateSwitch.emit(type);
  }

  dayIsSelected(event,dp){
    this.SelectedDay.emit(event);
    dp.close();
  }
  filterWeekEnds(date){
    let day = date.getDay();
    return day === 0 || day === 6;
  }

  openDatePicker(){
    if(this.popupSubscription) this.popupSubscription.unsubscribe();
    this.popupSubscription = this.goToNextEvent().subscribe(res => {
      if (res && res !== "cancel") {
        this.dp.open()
      } else if (res === false) {
        this.updateAgendas.emit(true);
      }
    })
  }

  goToNextEvent() {
    if (this.isThereNewModifs) {
      return this.utilisService.openUnSavedChangesPopup();
    } else {
      return new Observable((sub) => sub.next(true));
    }
  }

}
