import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';

import {urlEndPoints} from '../config/end-points';
import { VacationDal } from '../dal/vacation.dal';

@Injectable()
export class VacationService {

  constructor(private vacationDal : VacationDal) {
  }

  addVacation(vacation): Observable<any>{
    return this.vacationDal.addVacation(vacation);
  }

  getAllVacations(){
    return this.vacationDal.getAllVacations();
  }

  getOpenVacations(){
    return this.vacationDal.getOpenVacations();
  }

  getClosedVacations(){
    return this.vacationDal.getClosedVacations();
  }

  getOpenVacationsFromTo(startDate?: string , endDate?: string, position?: string, page?: number){
    return this.vacationDal.getOpenVacationsFromTo(startDate, endDate, position, page);
  }

  getClosedVacationsFromTo(startDate?: string , endDate?: string, position?: string, page?: number){
    return this.vacationDal.getClosedVacationsFromTo(startDate , endDate, position, page);
  }

  updateVacation(vacation){
    return this.vacationDal.updateVacation(vacation);
  }

  updateVolunteers(vacation){
    return this.vacationDal.updateVolunteers(vacation);
  }

  getVacationRequestHistory(profileId, showOnHold, startDate?, endDate?){
    return this.vacationDal.getVacationRequestHistory(profileId, showOnHold, startDate, endDate);
  }

  deleteVacation(vacationID){
    return this.vacationDal.deleteVacation(vacationID);
  }
}
