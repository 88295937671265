import { E } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as moment from 'moment';

@Component({
  selector: 'app-hours-popup',
  templateUrl: './hours-popup.component.html',
  styleUrls: ['./hours-popup.component.scss']
})
export class HoursPopupComponent implements OnInit {
  public startTime: string;
  public endTime: string;
  public showErrorMessage: boolean = false;
  public errorMessage: string = `La période demandée est déjà occupée`;

  constructor(
    public dialogRef: MatDialogRef<HoursPopupComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any,) {
      this.dialogRef.backdropClick().subscribe(() => {
        this.close();
      });
  }

  get formattedEndTime(): string {
    return `${this.endTime.split(':')[0]} h ${this.endTime.split(':')[1]}`
  }

  ngOnInit() {
    this.startTime = this.getStartTime();
    this.endTime = this.getEndTime();
  }

  getStartTime(): string {
    const d = new Date(this.data.intervention.startTime);
    d.setTime(d.getTime() + d.getTimezoneOffset() * 60 * 1000);
    return `${this.getDoubleDidgetValue(d.getHours())}:${this.getDoubleDidgetValue(d.getMinutes())}`;
  }

  getDoubleDidgetValue(value: number): string {
    return ('0' + value).slice(-2);
  }

  setStartTime(event): void {
    this.startTime = event;
    this.endTime = this.getEndTime();
  }

  getEndTime(): string {
    const tmp = moment(this.data.intervention.startTime).toDate();
    let date = new Date(Date.UTC(tmp.getFullYear(), tmp.getMonth(), tmp.getDate(), 0, 0, 0, 0));

    date.setUTCHours(Number(this.startTime.split(':')[0]));
    date.setUTCMinutes(Number(this.startTime.split(':')[1]));

    date = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000 + this.data.interventionDuration);
    return `${this.getDoubleDidgetValue(date.getHours())}:${this.getDoubleDidgetValue(date.getMinutes())}`;
  }

  getDateAndTimeByDateAndTimeString(date: string, time: string): string {
    const tmp = moment(date).toDate();
    let dateTmp = new Date(Date.UTC(tmp.getFullYear(), tmp.getMonth(), tmp.getDate(), 0, 0, 0, 0));

    dateTmp.setUTCHours(Number(time.split(':')[0]));
    dateTmp.setUTCMinutes(Number(time.split(':')[1]));

    return dateTmp.toISOString();
  }

  close(): void {
    this.dialogRef.close({ allowMoving: false });
  }

  moveIntervention(): void {
    // Check availability
    let allowMoving = true;
    const startTime = new Date(this.getDateAndTimeByDateAndTimeString(this.data.intervention.startTime, this.startTime));
    const endTime = new Date(this.getDateAndTimeByDateAndTimeString(this.data.intervention.startTime, this.endTime));

    for (const da of this.data.destinationRoom.doctoragendas) {
      const DAStartTime = new Date(da.startTime);
      const DAEndTime = new Date(da.endTime);

      if (String(da._id) !== String(this.data.intervention._id)) { 
        if (DAStartTime <= startTime && DAEndTime > startTime) {
          allowMoving = false;
          break;
        } else if (DAStartTime < endTime && DAEndTime >= endTime) {
          allowMoving = false;
          break;
        } else if (DAStartTime >= startTime && DAEndTime <= endTime) {
          allowMoving = false;
          break;
        }
      }
    }
    
    if (allowMoving) {
      this.dialogRef.close({ allowMoving: true, startTime, endTime });
    } else {
      this.showErrorMessage = true;
    }
  }
}
