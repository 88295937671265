import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {


    //Text : button naming.
    @Input() text : string = 'Action';
    //Type : button styling : primary / outlined / lite / text.
    @Input() type : string = 'primary';
    //Color : button color : primary / danger.
    @Input() color : string = 'primary';
    //Icon : button icon : svg / mat-icon.
    @Input() icon : string;
    //iconPosition : center / left-icon / right-icon.
    @Input() iconPosition : string = "center";
    //iconType : 'image' / 'mat-icon'.
    @Input() iconType: string = "mat-icon";

    @Input() fxLayoutAlignParam: string = 'center center';

    @Input() isSpinner: boolean = false;
    @Input() isSpinning: boolean = false;

    @Input() iconOnly : boolean;
    @Input() disabled : boolean;
    @Input() badge : number;
    @Input() NotAllowedCursor : boolean;
    @Input() hoverBackground: boolean = true;
    @Input() isSmall: boolean = false;

    @Output() isLoaded = new EventEmitter<boolean>()

    constructor() { }

    ngOnInit() {
      this.isLoaded.emit(true)
    }

  stopPropagation(event) {
      if (this.disabled) {
        event.stopPropagation();
      }
  }
}
