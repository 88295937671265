import {Pipe, PipeTransform} from '@angular/core';
import {transformText} from '../utils/cross-functions';

@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {

  transform(text: string, isNurse: boolean): string {
    return transformText(text, isNurse);
  }

}
