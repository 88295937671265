import {
  Component,
  Inject,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UserService } from "../../../services/user.service";
import { User } from "../../../models/user.model";
import { TimeRange, consultationExtracliniqueData, profileCard, roomFinalData, roomFinalDataTeam, surgeonCard } from "../day-program-interfaces";
import { Role, RoleNeed } from "src/app/shared/models/role.model";
import { IADE, WEIGHT } from "src/app/shared/const/glabals.const";
import { HospitalService } from "src/app/shared/services/hospital.service";
import { Effective, Need } from "../../effective-needs/interfaces";
import { SO } from "src/app/shared/models/surgeonOpenings.model";
import { Paramedical } from "src/app/shared/models/paramedical.model";
import { Formation } from "src/app/shared/models/formation.model";
import { getFirstHospitalSelectedData, getHoursAndMinutes, getWorkForce } from "src/app/shared/utils/cross-functions";
import * as moment from "moment";
import { roomWithDoctorAgendas } from "src/app/shared/interfaces/room.interfaces";
import { ProgramCommentary } from "src/app/shared/models/program-commentary.model";
import { ProgramCommentaryService } from "src/app/shared/services/program-commentary.service";
import { Room } from "src/app/shared/models/room.model";

@Component({
  selector: "app-data-detail-popup",
  templateUrl: "./data-detail-popup.component.html",
  styleUrls: ["./data-detail-popup.component.scss"],
})
export class DataDetailPopupComponent implements OnInit, OnChanges {
  public day: Date;
  public subtitle: string = "";

  public isLoading: boolean = true;

  public room: roomFinalData | consultationExtracliniqueData;
  public roomID: string;
  public team: roomFinalDataTeam | profileCard[];
  public roles: Role[];
  public surgeonOpenings: SO[];
  public stillNeeded: RoleNeed[][] = [];
  public missingRolesCount: RoleNeed[] = [];
  public missingRolesMessage: string = '';
  public splitMorningAfternoon: boolean;
  public isMorning: boolean;
  public notSplitButHalfDay: boolean = false;

  public formations: Formation[] = [];
  public paramedicRules: Paramedical[] = [];

  public anesthWorkforce: number = 0;
  public internWorkforce: number = 0;
  public juniorWorkforce: number = 0;
  public seniorWorkforce: number = 0;
  public iadeWorkforce: number = 0;
  public nursesWorkforce: Effective = { morningNurseEffectif: [], afternoonNurseEffectif: [] };

  public anesthNeeds: number = 0;
  public juniorNeeds: number = 0;
  public seniorNeeds: number = 0;
  public internNeeds: number = 0;

  public missingIntern = 0;
  public missingJunior = 0;
  public missingSenior = 0;

  public iadeNeeds: number = 0;
  public nursesNeeds: Need[] = [];

  public anesthDiff: number;
  public iadeDiff: number;

  public currentUser: User;
  public levelOfAccess: number = 1;
  public isAnesth: boolean;
  public isCadre: boolean;
  public isIadeRes: boolean;
  public isInternRes: boolean;
  public isAnesthRes: boolean;
  public isBP: boolean;
  public status: string;
  public isHome: boolean = false;

  public doesHospitalHaveAnesthDetailsOption: boolean;
  public isConsult = false;

  public blocNeeds: any[] = [];
  public consultNeeds: any[] = [];

  public allInterventions: roomWithDoctorAgendas[] = [];
  public agenda: any;

  public isThereModif: boolean = false;
  public disabled: boolean = true;
  public comment: string;
  public  programCommentary: ProgramCommentary;
  public updatedBy: string;
  public position: string;
  public programCommentaries: any[];
  public existProgramCommentary: boolean = true;
  currentCommentIndex: number = 0;

  get date() {
    return moment(this.day).format("YYYY-MM-DD").toString();
  }

  constructor(
    private hospitalService: HospitalService,
    private userService: UserService,
    private programCommentaryService: ProgramCommentaryService,
    public dialogRef: MatDialogRef<DataDetailPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.currentUser = this.userService.getCurrentUser();
    this.doesHospitalHaveAnesthDetailsOption = this.hospitalService.doesHospitalHaveAnesthDetailsOption();
    this.levelOfAccess = this.currentUser.levelOfAccess;

    this.isHome = this.data.isHome
    this.isAnesth = this.userService.isAnesWithLowLevelAccess();
    this.isCadre = this.userService.isCadreBloc();
    this.isIadeRes = this.userService.isIadRes();
    this.isInternRes = this.userService.isInternResponsible();

    if (!this.isInternRes) {
      this.isAnesthRes = this.userService.isAnestgWithHighLevel();
    } else {
      this.isAnesthRes = false;
    }
    this.position = this.isAnesthRes || this.currentUser.profile.position === "Anesthésiste" ? "Anesthésiste" : this.isIadeRes || IADE.includes(this.currentUser.profile.position) ? "Iade" :(this.isCadre && this.levelOfAccess ===5) ? "CadreSup" : "Infirmiere"
    this.team = this.data.team;
    this.day = this.data.day;
    this.room = this.data.room;
    this.isMorning = this.data.isMorning
    this.notSplitButHalfDay = this.data.notSplitButHalfDay;
    this.programCommentary=null;

    if (this.isCadre && !this.room.isSplit) {
      this.splitMorningAfternoon = true;
    } else {
      this.splitMorningAfternoon = false;
    }
    this.isConsult = !this.data.title.includes('Salle');
    this.allInterventions = this.data.interventions;
    this.surgeonOpenings = this.data.surgeonOpenings;
    this.subtitle = this.formatDate(this.day);
  }

  initConsultNeeds() {
    if (this.isAnesth || this.isAnesthRes) {
      this.consultNeeds.forEach(element => {
        if (element.specialty._id === this.data.room.specialty._id) {
          this.internNeeds += element.internNeeds;
          this.juniorNeeds += element.juniorNeeds;
          this.seniorNeeds += element.seniorNeeds;
          this.anesthNeeds += element.anesthNeeds;
        }
      });
      if (this.doesHospitalHaveAnesthDetailsOption) {
        this.anesthNeeds = this.internNeeds * WEIGHT.INTERN + this.juniorNeeds * WEIGHT.JUNIOR + this.seniorNeeds * WEIGHT.SENIOR;
      }
    }
  }

  prevComment() {
    if (this.currentCommentIndex > 0) {
      this.currentCommentIndex--;
    }
    if(this.programCommentaries && this.programCommentaries[this.currentCommentIndex]) this.programCommentary = this.programCommentaries[this.currentCommentIndex]
  }
  
  nextComment() {
    if (this.currentCommentIndex < this.programCommentaries.length - 1) {
      this.currentCommentIndex++;
    }
    if(this.programCommentaries && this.programCommentaries[this.currentCommentIndex]) this.programCommentary = this.programCommentaries[this.currentCommentIndex]
  }

  initMissingRoles() {
    this.stillNeeded = this.surgeonOpenings.map((op) => {
      return op.needs.map((needs) => { return { ...needs }; })
    });
    this.regroupMissingRoles();
    (this.team as roomFinalDataTeam).others.forEach(nurse => this.deductFromNeeds(nurse));
    this.missingRolesMessage = "";
    this.missingRolesCount.forEach((need) => {
      if (need.need <= 0) {
        return;
      }

      if (this.missingRolesMessage.length != 0) {
        this.missingRolesMessage += ', '
      }
      
      this.missingRolesMessage += need.role.name + ' (-' + need.need + ')';
    });
    this.missingRolesCount = this.missingRolesCount.filter((missing) => missing.need > 0)
  }

  deductFromNeeds(nurse: profileCard) {
    if (nurse.profile.position === 'Anesthésiste' || nurse.profile.position.toLowerCase() === 'iade') {
      return;
    }

    let roleNeedObject = this.missingRolesCount.find((role) => role.role._id == nurse.role._id);

    if (roleNeedObject) {
      roleNeedObject.need--;
    }
  }

  regroupMissingRoles() {
    this.stillNeeded.forEach(soNeed => {
      soNeed.forEach((need) => {
        if (need && need.role && need.role._id) {
          let alreadyIn = this.missingRolesCount.find((val) => val.role._id == need.role._id);
          
          if (alreadyIn) {
            alreadyIn.need = Math.max(alreadyIn.need, need.need)
          } else if (need.need != 0) {
            this.missingRolesCount.push(need);
          }
        }
      })
    });
  }

  formatDate(date: string | number | Date): string {
    let dateObj = new Date(date);
    const dayOfWeek = moment(dateObj).format('dddd');
    const dayOfMonth = moment(dateObj).format('DD');
    const month = moment(dateObj).format('MMMM');

    return `${dayOfWeek.slice(0, 1).toUpperCase()}${dayOfWeek.slice(1)} ${dayOfMonth} ${month}`;
  }

  initNeeds() {
    if (this.isAnesth || this.isAnesthRes) {
      this.blocNeeds.forEach(element => {
        if (element.room._id === this.roomID) {
          this.internNeeds += element.internNeeds;
          this.juniorNeeds += element.juniorNeeds;
          this.seniorNeeds += element.seniorNeeds;
          this.anesthNeeds += element.anesthNeeds;
        }
      });
      if (this.doesHospitalHaveAnesthDetailsOption) {
        this.anesthNeeds = this.internNeeds * WEIGHT.INTERN + this.juniorNeeds * WEIGHT.JUNIOR + this.seniorNeeds * WEIGHT.SENIOR;
      }
    }
    if (this.isAnesth || this.isAnesthRes || this.isIadeRes || this.isInternRes) {
      this.blocNeeds.forEach(element => {
        if (element.room._id === this.roomID) {
          this.iadeNeeds += element.iadeNeeds;
        }
      });
    }

    if (this.isCadre) {
      this.nursesNeeds = this.getNurseNeedsOfRoomByRole(this.surgeonOpenings);
    }

    if (this.isInternRes) {
      this.anesthNeeds = 0;
      this.blocNeeds.forEach(element => {
        if (element.room._id === this.roomID) {
          this.anesthNeeds += element.internNeeds;
        }
      });
    }
  }

  getNurseNeedsOfRoomByRole(openings: SO[]): Need[] {
    const nursesNeedsByRole: any = {};
    // Get all morning SO from the room
    let morningOpenings = openings.filter((opening) => this.isMorningOpening(opening));

    // Get all afternoon SO from the room
    let afternoonOpenings = openings.filter((opening) => this.isAfternonOpening(opening));

    // Iterate over each SO for the room and get needs
    for (const opening of morningOpenings) {
      for (const need of opening.needs) {
        const role = need.role && need.role._id ? need.role._id : need.role;
        
        if (nursesNeedsByRole[String(role)]) {
          nursesNeedsByRole[String(role)].morningNursesNeeds = Math.max(need.need, nursesNeedsByRole[String(role)].morningNursesNeeds);
        } else {
          nursesNeedsByRole[String(role)] = {
            morningNursesNeeds: need.need,
            afternoonNursesNeeds: 0
          }
        }
      }
    }

    for (const opening of afternoonOpenings) {
      for (const need of opening.needs) {
        const role = need.role && need.role._id ? need.role._id : need.role;
        
        if (nursesNeedsByRole[String(role)]) {
          nursesNeedsByRole[String(role)].afternoonNursesNeeds = Math.max(need.need, nursesNeedsByRole[String(role)].afternoonNursesNeeds);;
        } else {
          nursesNeedsByRole[String(role)] = {
            morningNursesNeeds: 0,
            afternoonNursesNeeds: need.need
          }
        }
      }
    }

    // Prepare response
    // const response: { role: string, morningNursesNeeds: number, afternoonNursesNeeds: number }[] = [];
    const response: Need[] = [];
    for (const [key, value] of Object.entries(nursesNeedsByRole)) {
      const v = value as any;
      if (this.roles.find((val: Role) => { return val._id === key })) {
        const tmp: Need = {
          role: this.roles.find((val: Role) => { return val._id === key }),
          morningNursesNeeds: v.morningNursesNeeds,
          afternoonNursesNeeds: v.afternoonNursesNeeds
        };
  
        response.push(tmp);
      }
    }

    return response;
  }

  getProgramCommentaryByRoom() {

    return new Promise<void>((resolve, reject) => {
      this.programCommentaryService.getProgramCommentaryByRoom(this.date,this.roomID,this.position).subscribe(
        (res) => {
          if (res && res.length !== 0) {
            if(res.length>1){
              this.programCommentaries = res;
            }
        
            this.programCommentary = res[0];
            this.comment = res[0].comment;
            this.existProgramCommentary = this.comment.length > 0;
            this.updatedBy = res[0].profile.firstName + ' ' + res[0].profile.lastName

            if((this.programCommentaries && !this.programCommentaries.find((el)=> el.position === "Infirmiere"))  && this.position === "CadreSup"){
              this.programCommentaries.push({
                comment:""
              })
            }
            else{
              if((!this.programCommentaries && this.programCommentary && this.programCommentary.position !== "Infirmiere") && this.position === "CadreSup"){
                this.programCommentaries=[this.programCommentary]
                this.programCommentaries.push({
                  comment:""
                })
              }
            }
          }
          resolve();
        }
      );
    })
  }


  isMorningOpening(opening: SO): boolean {
    const hospital = getFirstHospitalSelectedData(this.userService.getCurrentUser(), this.userService.getSelectedHospitals())
    const middleTime = new Date(hospital.middleTime)
    const middleTimeModified = new Date("1970-01-01");
    middleTimeModified.setUTCHours(middleTime.getUTCHours(), middleTime.getUTCMinutes(), middleTime.getUTCSeconds(), middleTime.getUTCMilliseconds())

    const startTime = new Date("1970-01-01")
    startTime.setUTCHours(new Date(opening.startTime).getUTCHours(), new Date(opening.startTime).getUTCMinutes(), new Date(opening.startTime).getUTCSeconds(), new Date(opening.startTime).getUTCMilliseconds())
    
    return startTime.getTime() < (middleTimeModified.getTime() - 3600000) && opening.opening;
  }

  isAfternonOpening(opening: SO): boolean {
    const hospital = getFirstHospitalSelectedData(this.userService.getCurrentUser(), this.userService.getSelectedHospitals())
    const middleTime = new Date(hospital.middleTime)
    const middleTimeModified = new Date("1970-01-01");
    middleTimeModified.setUTCHours(middleTime.getUTCHours(), middleTime.getUTCMinutes(), middleTime.getUTCSeconds(), middleTime.getUTCMilliseconds())

    const endTime = new Date("1970-01-01")
    endTime.setUTCHours(new Date(opening.endTime).getUTCHours(), new Date(opening.endTime).getUTCMinutes(), new Date(opening.endTime).getUTCSeconds(), new Date(opening.endTime).getUTCMilliseconds())

    return endTime.getTime() > (middleTimeModified.getTime() + 3600000) && opening.opening;
  }

  getHospitalMiddleTime() {
    return getHoursAndMinutes((this.room as roomFinalData).room.hospital.middleTime);
  }

  initEffectif() {
    if (this.isConsult) {
      (this.team as profileCard[]).forEach(element => {
        if (element.profile.position.toLowerCase() === 'anesthésiste') {
          if (element.profile.seniority == null) {
            this.anesthWorkforce++;
          } else if (element.profile.seniority.toLowerCase() === 'interne') {
            this.internWorkforce++;
          } else if (element.profile.seniority.toLowerCase() === 'junior') {
            this.juniorWorkforce++;
          } else if (element.profile.seniority.toLowerCase() === 'senior') {
            this.seniorWorkforce++;
          } else {
            this.anesthWorkforce++;
          }
        } else if (element.profile.position.toLowerCase() === 'iade') {
          this.iadeWorkforce++;
        }
      })
      if (this.doesHospitalHaveAnesthDetailsOption) {
        this.anesthWorkforce = this.internWorkforce * WEIGHT.INTERN + this.juniorWorkforce * WEIGHT.JUNIOR + this.seniorWorkforce * WEIGHT.SENIOR;
      }
    } else if (this.isAnesthRes || this.isAnesth || this.isInternRes || this.isIadeRes) {
      (this.team as roomFinalDataTeam).others.forEach(element => {
        if (element.profile.position.toLowerCase() === 'anesthésiste') {
          if (element.profile.seniority == null) {
            this.anesthWorkforce++;
          } else if (element.profile.seniority.toLowerCase() === 'interne') {
            this.internWorkforce++;
          } else if (element.profile.seniority.toLowerCase() === 'junior') {
            this.juniorWorkforce++;
          } else if (element.profile.seniority.toLowerCase() === 'senior') {
            this.seniorWorkforce++;
          } else {
            this.anesthWorkforce++;
          }
        } else if (element.profile.position.toLowerCase() === 'iade') {
          this.iadeWorkforce++;
        }
      })
      if (this.doesHospitalHaveAnesthDetailsOption) {
        this.anesthWorkforce = this.internWorkforce * WEIGHT.INTERN + this.juniorWorkforce * WEIGHT.JUNIOR + this.seniorWorkforce * WEIGHT.SENIOR;
      }

      if (this.isInternRes) {
        this.anesthWorkforce = this.internWorkforce;
      }
    } else if (this.isCadre) {
      this.nursesWorkforce = getWorkForce(this.formations, (this.team as roomFinalDataTeam).others, (this.room as roomFinalData).room.hospital.middleTime)
    }
  }

  initInterventions() {
    this.agenda = this.allInterventions.find(
      (elem) => {
        return elem.room._id === this.roomID;
      });
    
    if (!this.agenda) {
      this.agenda = {
        doctoragendas: [],
        room: this.data.room.room,
        roomHospital: this.data.room.room.hospital.name
      }
    }
  }
  addProgramCommentary() {
    const positionId = this.position === "CadreSup" ? "Infirmiere" :this.position;
    let body: ProgramCommentary ;
    if(this.programCommentaries && this.programCommentaries[this.currentCommentIndex]){
      body = {
        date: this.date,
        comment: this.programCommentaries[this.currentCommentIndex].comment,
        hospital: getFirstHospitalSelectedData(this.userService.getCurrentUser(), this.userService.getSelectedHospitals()),
        room : { _id: this.roomID } as Room,
        updatedBy: this.userService.getCurrentUser().profile,
        position:positionId
      };
      this.comment = this.programCommentaries[this.currentCommentIndex].comment
      this.updatedBy = this.userService.getCurrentUser().profile._id;

    }
    else{
      body = {
        date: this.date,
        comment: this.comment,
        hospital: getFirstHospitalSelectedData(this.userService.getCurrentUser(), this.userService.getSelectedHospitals()),
        room : { _id: this.roomID } as Room,
        updatedBy: this.userService.getCurrentUser().profile,
        position:positionId
      };
    }   
    this.programCommentaryService.addProgramCommentary(body).subscribe(
      (res) => {
        if (res) {
          this.programCommentary = res;
          this.comment = res.comment;
        }
      }
    );
  }

  updateProgramCommentary() {
    const positionId = this.position === "CadreSup" ? "Infirmiere" :this.position;
    let body : ProgramCommentary;
    if(this.programCommentaries && this.programCommentaries[this.currentCommentIndex]){
    body = {
      date: this.date,
      comment: this.programCommentaries[this.currentCommentIndex].comment,
      hospital: getFirstHospitalSelectedData(this.userService.getCurrentUser(), this.userService.getSelectedHospitals()),
      room : { _id: this.roomID } as Room,
      updatedBy: this.userService.getCurrentUser().profile,
    };
    this.comment = this.programCommentaries[this.currentCommentIndex].comment
    this.updatedBy = this.userService.getCurrentUser().profile._id;
  }else{
    body = {
      date: this.date,
      comment: this.comment,
      hospital: getFirstHospitalSelectedData(this.userService.getCurrentUser(), this.userService.getSelectedHospitals()),
      room : { _id: this.roomID } as Room,
      updatedBy: this.userService.getCurrentUser().profile,
    };
  }
    this.programCommentaryService
      .updateProgramCommentary(body, this.programCommentary._id)
      .subscribe(
        (res) => {
          if (res && this.comment) {
            this.programCommentary.comment = this.comment;
            this.programCommentary.updatedBy = this.userService.getCurrentUser().profile;
          }
        }
      );
  }


  editComment(){
    this.disabled = false
    this.isThereModif = true
  }
  saveComment(){
    this.disabled = true
    this.isThereModif = false
    if (this.comment !== null) {
      if (this.programCommentary !== null && this.programCommentary._id)
        this.updateProgramCommentary();
      else this.addProgramCommentary();

    }
    this.updatedBy = this.userService.getCurrentUser().profile.firstName + ' ' + this.userService.getCurrentUser().profile.lastName 
    if(this.programCommentaries && this.programCommentaries[this.currentCommentIndex]){
      this.programCommentaries[this.currentCommentIndex].profile =  this.userService.getCurrentUser().profile
    }
    this.existProgramCommentary = this.comment.length > 0; 
  }


  formatDateAndHour(date: string) {
    let m = moment(date);

    return m.format('L') + ' ' + m.format('LT');
  }

  async ngOnInit() {
    if (!this.isConsult) {
      this.status = this.data.objectifStatus;
      this.roomID = (this.room as roomFinalData).roomId;
      this.roles = this.data.roleData;
      this.blocNeeds = this.data.blocNeeds;
      this.paramedicRules = this.data.paramedicals;
      this.formations = this.data.formations;
      this.formations = this.formations.filter((formation) => {
        return formation.name.toLowerCase() !== 'iade';
      })
      this.initInterventions();
    } else {
      this.consultNeeds = this.data.consultationNeeds;
      this.data.room.team.sort((a, b) => {
        if (a.timeRange === TimeRange.afternoon && b.timeRange != TimeRange.afternoon) {
          return 1;
        }
        
        if (b.timeRange === TimeRange.afternoon && a.timeRange != TimeRange.afternoon) {
          return -1;
        }
        return a.timeRange.localeCompare(b.timeRange);
      });
      this.anesthNeeds = this.data.room.needs;
    }

    this.initEffectif();

    if (!this.isConsult) {
      this.initNeeds();
      this.initInterventions();
    } else if (this.isConsult) {
      this.initConsultNeeds();
    }

    if (this.isCadre && !this.isConsult) {
      this.initMissingRoles();
    }

    this.anesthDiff = this.anesthWorkforce - this.anesthNeeds;
    this.iadeDiff = this.iadeWorkforce - this.iadeNeeds;

    this.isBP = this.data.room.isBP;
    if (this.doesHospitalHaveAnesthDetailsOption && (this.isAnesthRes || this.isAnesth)) {
      this.missingIntern = this.internNeeds - this.internWorkforce;
      this.missingJunior = this.juniorNeeds - this.juniorWorkforce;
      this.missingSenior = this.seniorNeeds - this.seniorWorkforce;
    }

    this.isLoading = false;
    this.getProgramCommentaryByRoom()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.isConsult) {
      this.initInterventions();
    }
  }

  // close the dialog ref and send back the value of existCommentary
  close(): void {
    this.dialogRef.close({ 
      data: { 
        existProgramCommentary: this.existProgramCommentary 
      }
    });
  }
}