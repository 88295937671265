import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss']
})
export class TextInputComponent implements OnInit {
  @Input() formCtrl: FormControl;
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() hintMessage: string = '';
  @Input() disabled: boolean;
  @Input() type: string = 'text';
  @Input() hideBorder: boolean = false;
  @Input() showNumberButtons: boolean = false;
  @Input() numberButtonsIconUp: string = 'arrow_drop_up';
  @Input() numberButtonsIconDown: string = 'arrow_drop_down';
  @Input() minNumber: number;
  @Input() maxNumber: number;
  @Input() initInput: string;
  public isItPassword: boolean;
  public inputValue: string = '';

  /*
  ** errorMessagesObject contains key value pairs of possible errors, 
  ** when 'key' represents the error name, 'value' represents the message to show when the error 'key' is present,
  */
  @Input() errorMessagesObject: any = {};


  //iconType : 'image' / 'mat-icon'. 
  @Input() iconType: string = "mat-icon";

  //Icon : button icon : svg / mat-icon. 
  @Input() icon : string;

  // This is used to handle two way binding
  @Input() inputModel: any;
  @Output() inputModelChange = new EventEmitter<any>();

  @Output() onKeyUp: EventEmitter<any> = new EventEmitter<any>();


  constructor() { }

  ngOnInit() {
    this.isItPassword = this.type === 'password';
    if (!this.initInput && this.formCtrl) {
      this.inputValue = this.formCtrl.value;
    } else {
      this.inputValue = this.initInput;
    }
  }

  getIsError() {
    if (this.formCtrl && this.formCtrl.touched && this.formCtrl.errors) {
      return true;
    }

    return false;
  }

  getErrorMessageToShow(): string {
    const errorMessagesArray = Object.entries(this.errorMessagesObject);
    const currentErrorsArray = Object.entries(this.formCtrl.errors);
    for (const [key, value] of errorMessagesArray) {
      for (const [key2, value2] of currentErrorsArray) {
        if (key === key2) {
          return String(value);
        }
      }
    }
  }

  inputModelChangeHandler(): void {
    let output;
    if (this.type === 'number') {
      output = Number(this.inputModel);
    } else {
      output = this.inputModel;
    }
    this.inputModelChange.emit(output);
  }

  onKeyUpHandler(input: any): void {
    let output:string = String(input);
    if (this.type === 'number') {
      output = input;
      if (this.maxNumber && Number(output) > this.maxNumber) {
        if (this.formCtrl) {
          this.formCtrl.setValue(this.maxNumber);
        }
        output = String(this.maxNumber);
      } else if (this.minNumber && Number(output) < this.minNumber) {
        if (this.formCtrl) {
          this.formCtrl.setValue(this.minNumber);
        }
        output = String(this.minNumber);
      }
    }
    this.inputValue = output;
    this.inputModel = output;
    if (this.type === 'number') {
      this.onKeyUp.emit(Number(output))
    } else {
      this.onKeyUp.emit(output);
    }
    this.inputModelChangeHandler();
  }

  increaseNumber(): void {
    if (this.formCtrl) {
      if (!this.formCtrl.disabled && !this.disabled) {
        if (this.maxNumber !== undefined && this.formCtrl.value < this.maxNumber) {
          this.formCtrl.setValue(Number(this.formCtrl.value) + 1);
          this.inputModelChangeHandler();
        } else if (this.maxNumber === undefined) {
          this.formCtrl.setValue(Number(this.formCtrl.value) + 1);
          this.inputModelChangeHandler();
        }
      }
    } else {
      if (!this.disabled) {
        if (this.maxNumber !== undefined && this.inputModel < this.maxNumber) {
          this.inputModel = Number(this.inputModel) + 1;
          this.inputModelChangeHandler();
        } else if (this.maxNumber === undefined) {
          this.inputModel = Number(this.inputModel) + 1;
          this.inputModelChangeHandler();
        }
      }
    }
  }

  decreaseNumber(): void {
    if (this.formCtrl) {
      if (!this.formCtrl.disabled && !this.disabled) {
        if (this.minNumber !== undefined && this.formCtrl.value > this.minNumber) {
          this.formCtrl.setValue(Number(this.formCtrl.value) - 1);
          this.inputModelChangeHandler();
        } else if (this.minNumber === undefined) {
          this.formCtrl.setValue(Number(this.formCtrl.value) - 1);
          this.inputModelChangeHandler();
        }
      }
    } else {
      if (!this.disabled) {
        if (this.minNumber !== undefined && this.inputModel > this.minNumber) {
          this.inputModel = Number(this.inputModel) - 1;
          this.inputModelChangeHandler();
        } else if (this.minNumber === undefined) {
          this.inputModel = Number(this.inputModel) - 1;
          this.inputModelChangeHandler();
        }
      }
    }
  }

  togglePassword(): void {
    if (this.type === 'text') {
      this.type = 'password';
    } else {
      this.type = 'text';
    }
  }
}
