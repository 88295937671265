import {Component, Input, SimpleChanges} from '@angular/core';
import { Observable } from 'rxjs';

import { SurgeonOpening } from 'src/app/shared/models/surgeonOpenings.model';
import { themeStylingColors } from 'src/themes/common/principal-theme';

@Component({
  selector: 'app-day-availability-of-surgeon',
  templateUrl: './day-availability-of-surgeon.component.html',
  styleUrls: ['./day-availability-of-surgeon.component.scss']
})
export class DayAvailabilityOfSurgeonComponent  {

  @Input() surgeonOpening: SurgeonOpening;
  color: Observable<string>;
  colorDisplayed;

  constructor() {
  }

  ngOnInit() {
    this.colorDisplayed = this.getColor();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.colorDisplayed = this.getColor();
  }

  getColor(): string {
    if (!this.surgeonOpening) {
      return themeStylingColors.color_disabled;
    } else {
      if (this.surgeonOpening.surgeonopening.length > 0) {
        if (this.isSOSContainTrue(this.surgeonOpening.surgeonopening[0])) {
          return themeStylingColors.positive_dark
        } else {
          return themeStylingColors.danger_base
        }
      } else if (this.surgeonOpening.surgeonopeninghistory.length > 0) {
        const so = this.surgeonOpening.surgeonopeninghistory[0][0];
        if (so.opening) {
          return themeStylingColors.positive_dark
        } else {
          return themeStylingColors.danger_base
        }
      }
    }
  }

  isSOSContainTrue(SOS: any): boolean {
    return SOS.findIndex(el => el.opening == true) != -1
  }
}
