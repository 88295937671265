import { Component, OnInit, Input } from '@angular/core';
import { Profile } from '../../models/profile.model';
import { StorageService } from 'src/app/core/services/storage.service';
import { getHospitalsNameBySelectedHospitals } from '../../utils/cross-functions';
import { UserService } from '../../services/user.service';
import { HospitalService } from '../../services/hospital.service';

@Component({
  selector: 'app-hospitals-hover',
  templateUrl: './hospitals-hover.component.html',
  styleUrls: ['./hospitals-hover.component.scss']
})
export class HospitalsHoverComponent implements OnInit {
  @Input() profile: Profile

  public isMultiHospital: boolean = false;
  public isHovered: boolean = false;
  public hospitalsName: string[] = [];
  public fullHospitalsName: string = ""
  public isTooLong: boolean = false

  constructor(
    private storageService: StorageService,
    private userService: UserService,
    private hospitalService: HospitalService
    
  ){}

  ngOnInit() {
    const selectedHospitalsId = this.userService.getSelectedHospitals();
    this.isMultiHospital = selectedHospitalsId.length >= 2;
    this.hospitalsName = getHospitalsNameBySelectedHospitals(this.profile, selectedHospitalsId)
    this.hospitalsName.forEach(name => {
      if (this.fullHospitalsName.length > 0) {
        this.fullHospitalsName += ", "
      }
      this.fullHospitalsName += name
    });
    this.isTooLong = this.fullHospitalsName.length > 23
  }

  
  onMouseOver() {
    this.isHovered = true;
    setTimeout(() => {
      this.isHovered = false;
    }, 3000);
  }

  onMouseOut() {
    this.isHovered = false;
  }

  // async ngOnInit() {
  //   const selectedHospitalsId = this.userService.getSelectedHospitals();
  //   this.isMultiHospital = selectedHospitalsId.length >= 2;
  //   await this.getHospitalsNameBySelectedHospitals2(selectedHospitalsId);

  //   this.hospitalsName.forEach(name => {
  //     if (this.fullHospitalsName.length > 0) {
  //       this.fullHospitalsName += ", "
  //     }
  //     this.fullHospitalsName += name
  //   });
  //   this.isTooLong = this.fullHospitalsName.length > 23
  // }

  // async getHospitalsNameBySelectedHospitals2(selectedHospitalsId) {
  //   const promises = selectedHospitalsId.map(async hospitalId => {
  //     const result = await this.hospitalService.getHospital(hospitalId).toPromise();
  //     this.hospitalsName.push(result[0].name);
  //   });
  //   await Promise.all(promises);
  // }

}
