import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import {urlEndPoints} from '../config/end-points';
import { Wish } from '../models/wish.model';

@Injectable()

export class WishDal {

  constructor(private httpClient: HttpClient) {
  }

  getWishesByProfileAndType(profileId: string, type: string): Observable<any[]> {
    return this.httpClient.get<any[]>(urlEndPoints.wishes + `wishTime/${profileId}?type=${type}`);
  }

  getWishesByProfileAndTypeFromHospital(profileId: string, type: string, hospitalId: string): Observable<any[]> {
    return this.httpClient.get<any[]>(urlEndPoints.wishes + `wishTime/${profileId}?type=${type}&hospital=${hospitalId}`);
  }

  editWishes(profileId: string, wishes: any) {
    return this.httpClient.post<any>(urlEndPoints.wishes + 'editWishes/' + profileId, wishes);
  }
}
