import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Specialty } from 'src/app/shared/models/specialty.model';

@Component({
  selector: 'app-filter-popup',
  templateUrl: './filter-popup.component.html',
  styleUrls: ['./filter-popup.component.scss']
})
export class FilterPopupComponent implements OnInit {
  public isFilterBySpecialty: boolean = true;
  public specialties: (Specialty & { checked?: boolean })[] = [];

  constructor(
    private dialogRef: MatDialogRef<FilterPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.specialties = data.specialties
  }

  ngOnInit() {
    
  }

  filter(): void {
    if (!this.isFilterBySpecialty) {
      this.reset();
      return;
    }

    const filtredSpecialties = this.specialties.filter((s) => s.checked);

    this.dialogRef.close(filtredSpecialties);
  }

  reset(): void {
    this.specialties.forEach((s) => {
      s.checked = false;
    });
    this.dialogRef.close([]);
  }

  close(): void {
    const result = { action: 'cancel' };
    this.dialogRef.close(result);
  }
}
