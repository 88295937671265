import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";

import { Profile } from "../models/profile.model";
import { ProfileDal } from "../dal/profile.dal";
import { Period } from "../models/period.model";
import { Calendar } from "../models/calendar.model";
import { IADE } from "../const/glabals.const";
import { server } from "typescript";
import { allAvailableNurses } from "../interfaces/profile.interfaces";

@Injectable()
export class ProfileService {
  constructor(private profileDal: ProfileDal) {}

  getSurgeonsByDayAvailability(
    day: string,
    opening: boolean,
    page,
    pageSize,
    searchValue
  ): Observable<Profile[]> {
    return this.profileDal.getSurgeonsByDayAvailability(day, opening, page, pageSize, searchValue);
  }

  getProfilesWithTimes(
    position?: string,
    day?,
    period?: Period,
    type?
  ): Observable<Calendar[]> {
    return this.profileDal.getProfilesWithTimes(position, day, period, type);
  }

  getProfilesByPosition(
    position: string,
    page: number,
    limit: number,
    name: string,
    justTitular?,
    allProfiles?,
    noUrgences?: boolean,
    seniorities?: string,
    isNight?: boolean,
    keepDefaultSpecialtySurgeons?: boolean,
    profileOnTop?: boolean
  ): Observable<any> {
    return this.profileDal.getProfilesByPosition(
      position,
      page,
      limit,
      name,
      justTitular,
      allProfiles,
      noUrgences,
      seniorities,
      isNight,
      keepDefaultSpecialtySurgeons,
      profileOnTop
    );
  }

  getProfilesByPositionFromHospital(
    hospital: string,
    position: string,
    page: number,
    limit: number,
    name: string,
    justTitular?,
    allProfiles?,
    noUrgences?: boolean,
    seniorities?: string,
    isNight?: boolean,
    isNoUserAssociate?: boolean
  ): Observable<any> {
    return this.profileDal.getProfilesByPositionFromHospital(
      hospital,
      position,
      page,
      limit,
      name,
      justTitular,
      allProfiles,
      noUrgences,
      seniorities,
      isNight,
      isNoUserAssociate
    );
  }

  getProfilesByPositionResidency(
    position: string,
    page: number,
    limit: number,
    name: string,
    residency?: string,
    allNurses?,
    noUrgences?: boolean,
  ): Observable<any> {
    return this.profileDal.getProfilesByPositionResidency(
      position,
      page,
      limit,
      name,
      residency,
      allNurses,
      noUrgences,
      
    );
  }

  getProfilesByPositionResidencyFromHospital(
    hospital: string,
    position: string,
    page: number,
    limit: number,
    name: string,
    residency?: string,
    allNurses?,
    noUrgences?: boolean,
  ): Observable<any> {
    return this.profileDal.getProfilesByPositionResidencyFromHospital(
      hospital,
      position,
      page,
      limit,
      name,
      residency,
      allNurses,
      noUrgences,
      
    );
  }
  getProfilesWithUserByPositionResidencyFromHospital(
    hospital: string,
    position: string,
    page: number,
    limit: number,
    name: string,
    residency?: string,
    allNurses?,
    noUrgences?: boolean,
  ): Observable<any> {
    return this.profileDal.getProfilesWithUserByPositionResidencyFromHospital(
      hospital,
      position,
      page,
      limit,
      name,
      residency,
      allNurses,
      noUrgences,
      
    );
  }

  getProfilesBySpecialty(position:string){
    return this.profileDal.getProfilesBySpecialty(position)
  }

  getProfilesBySpecialtyFromHospital(position: string, hospital: string){
    return this.profileDal.getProfilesBySpecialtyFromHospital(position, hospital)
  }

  getProfiles(
    page: number,
    limit: number,
    name: string,
    noUrgences?: boolean,
    searchType?,
    data?
  ): Observable<any> {
    return this.profileDal.getProfiles(page, limit, name, noUrgences, searchType, data);
  }

  getProfileWeekAvailability(position: string, date): Observable<any> {
    return this.profileDal.getProfileWeekAvailability(position, date);
  }

  getProfilesByDayAvailability(
    position: string,
    date: string,
    descriptions: string[],
    isNight,
    page = null, 
    pageSize = null, 
    searchValue = null
  ): Observable<Profile[]> {
    return this.profileDal.getProfilesByDayAvailability(
      position,
      date,
      descriptions,
      isNight,
      page, 
      pageSize, 
      searchValue
    );
  }

  getOutOfRoomNurses(date: string): Observable<Profile[]> {
    return this.profileDal.getOutOfRoomNurses(date);
  }
  getOutOfRoomIades(date: string): Observable<Profile[]> {
    return this.profileDal.getOutOfRoomIades(date);
  }

  getAllAvailableNurses(date: string): Observable<allAvailableNurses[]> {
    return this.profileDal.getAllAvailableNurses(date);
  }

  getOutOfRoomNursesFromTo(startDate: string,endDate: string): Observable<any[]> {
    return this.profileDal.getOutOfRoomNursesFromTo(startDate,endDate);
  }
  getOutOfRoomIadesFromTo(startDate: string,endDate: string): Observable<any[]> {
    return this.profileDal.getOutOfRoomIadesFromTo(startDate,endDate);
  }

  getConsultationsFromTo(startDate: string,endDate: string): Observable<any[]> {
    return this.profileDal.getConsultationsFromTo(startDate,endDate);
  }

  getGardeFromTo(startDate: string,endDate: string): Observable<any[]> {
    return this.profileDal.getGardeFromTo(startDate,endDate);
  }

  getProfileById(profileId: string): Observable<Profile> {
    return this.profileDal.getProfileById(profileId);
  }

  getProfileHRSuiteRecap(
    profileId: string,
    startDate: string,
    endDate: string,
    hospital?: string
  ): Observable<any> {
    return this.profileDal.getProfileHRSuiteRecap(
      profileId,
      startDate,
      endDate,
      hospital
    );
  }

  searchSpecInfByName(searchString: string): Observable<any> {
    return this.profileDal.searchSpecInfByName(searchString);
  }

  updateUserProfile(profile: Profile): Observable<Profile> {
    return this.profileDal.updateUserProfile(profile);
  }

  createProfileWithSkills(profileWithSkills: any): Observable<Profile> {
    return this.profileDal.createProfileWithSkills(profileWithSkills);
  }

  createDefaultSpecialtySurgeon(profile: any, specialty: string): Observable<Profile> {
    return this.profileDal.createDefaultSpecialtySurgeon(profile, specialty);
  }

  editProfileWithSkills(profile: any): Observable<Profile> {
    return this.profileDal.editProfileWithSkills(profile);
  }

  editProfile(profile: Profile): Observable<Profile> {
    return this.profileDal.editProfile(profile);
  }

  editVacationNeeds(profile: Profile ,originalSpe: string): Observable<any> {
    return this.profileDal.editVacationNeeds(profile,originalSpe);
  }


  addProfile(profile: Profile): Observable<Profile> {
    return this.profileDal.addProfile(profile);
  }

  modifyProfile(profile: Profile): Observable<Profile> {
    return this.profileDal.modifyProfile(profile);
  }

  deleteProfile(idProfile: string): Observable<Profile> {
    return this.profileDal.deleteProfile(idProfile);
  }

  getAverages(startDate: string, endDate: string , isGarde = false, hospitalId?: string): Observable<any> {
    return this.profileDal.getAverages(startDate, endDate, isGarde, hospitalId);
  }

  getNeeds(startDate: string, endDate: string): Observable<any> {
    return this.profileDal.getNeeds(startDate, endDate);
  }

  getNumberOfProfils(): Observable<any> {
    return this.profileDal.getNumberOfProfiles();
  }

  isProfileIade(profile: Profile): boolean {
    return IADE.includes(profile.position);
  }

  getprofileImageByscrapping(surgeonId: string, hospitalId: string) {
    return this.profileDal.getprofileImageByscrapping(surgeonId, hospitalId);
  }

  getAllProfilesIncludeDeleted(hospitalId?: string): Observable<Profile[]> {
    return this.profileDal.getAllProfilesIncludeDeleted(hospitalId);
  }

  softRestoreProfile(idProfile: string): Observable<Profile> {
    return this.profileDal.softRestoreProfile(idProfile);
  }

  hardRestoreProfile(idProfile: string): Observable<Profile> {
    return this.profileDal.hardRestoreProfile(idProfile);
  }

  softDeleteProfile(idProfile: string): Observable<Profile> {
    return this.profileDal.softDeleteProfile(idProfile);
  }

  getProfilesSummary(
    position: string,
    date1String: string,
    date2String: string
  ): Observable<any> {
    return this.profileDal.getProfilesSummary(
      position,
      date1String,
      date2String
    );
  }

  getListWishes(profileId: string, startDate: string, endDate: string): Observable<any[]> {
    return this.profileDal.getListWishes(profileId, startDate, endDate);
  }

  getWorkingTime(profileId: string, startDate: string, endDate: string): Observable<any> {
    return this.profileDal.getWorkingTime(profileId, startDate, endDate);
  }

  getSurgeonsBySpecialty(
    specialty: string,
    date?: string
  ): Observable<any> {
    return this.profileDal.getSurgeonsBySpecialty(
      specialty,
      date
    );
  }

  deleteDefaultSpecialtySurgeons(
    specialtyId: string
  ) : Observable<any> {
    return this.profileDal.deleteDefaultSpecialtySurgeons(specialtyId);
  }

  getAllSurgeons(
    hospital: string,
    date?: string
  ): Observable<any> {
    return this.profileDal.getAllSurgeons(
      hospital,
      date
    );
  }
  getAllSurgeonsOnlyUser(
    hospital: string,
    date?: string
  ): Observable<any> {
    return this.profileDal.getAllSurgeonsOnlyUser(
      hospital,
      date
    );
  }

}
