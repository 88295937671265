import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Data, DayDetails, NightData, NightDetails } from '../interfaces';
import { Hospital } from 'src/app/shared/models/hospital.model';

@Component({
  selector: 'app-effective-needs-details-popup',
  templateUrl: './effective-needs-details-popup.component.html',
  styleUrls: ['./effective-needs-details-popup.component.scss']
})
export class EffectiveNeedsDetailsPopupComponent implements OnInit {
  public title: string;
  public subTitle: string;
  public lastUpdateDate : string;
  public showDifference: boolean;
  public onlyNeeds: boolean;
  public period: string;
  public details: DayDetails[] | NightDetails[];

  public onlyMorning: boolean = false;
  public onlyAfternoon: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<EffectiveNeedsDetailsPopupComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {

  }

  ngOnInit() {
    this.title = this.data.title;
    this.subTitle = this.data.subTitle;
    this.lastUpdateDate = this.data.lastUpdateDate;
    this.period = this.data.period;
    this.details = this.data.details;
    this.onlyMorning = this.data.onlyMorning;
    this.onlyAfternoon = this.data.onlyAfternoon;

    this.showDifference = true;
    if (this.data.showDifference !== undefined)
      this.showDifference = this.data.showDifference;
    this.onlyNeeds = false;
    if (this.data.onlyNeeds !== undefined)
      this.onlyNeeds = this.data.onlyNeeds;
  }

  getDifference(arg: { effective: number, needs: number }): number {
    return arg.effective - arg.needs;
  }

  close() {
    this.dialogRef.close();
  }
}
