import {Component, OnInit, Input, EventEmitter, Output, SimpleChanges, OnChanges} from '@angular/core';
import {DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS, MatDatepicker} from '@angular/material';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {FormControl} from '@angular/forms';
import * as moment from 'moment';


@Component({
  selector: 'app-custom-date-picker-small',
  templateUrl: './custom-date-picker-small.component.html',
  styleUrls: ['./custom-date-picker-small.component.scss'],

})
export class CustomDatePickerSmallComponent implements OnInit, OnChanges {

  @Output() dateSwitch = new EventEmitter<string>();
  @Output() monthPicked = new EventEmitter<string>();
  @Input() day: moment.Moment;
  date: FormControl;

  constructor() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.day.previousValue) {

      this.date = new FormControl({value: this.day, disabled: true});

    }
  }

  ngOnInit() {
    this.date = new FormControl({value: this.day, disabled: true});
  }

  dateSwitchFunc(type) {
    this.dateSwitch.emit(type);
  }

  monthIsPicked(event, dp) {
    this.monthPicked.emit(event);
    dp.close();
  }

}
