
 const CHART_COLORS = {
    openCircBlue: '#00E7DF',
    openAideOpBlue: '#12F1F6',
    openGreen: '#A9D18E',
    openGrey: '#F2F2F2',
    secondary: '#002265',
    primary: '#4bc0c0',
    NEEDS: '#EB405E',
    EFFECTIFS: '#39CC85',
    BLUE_PRIMARY: '#37AEEB',
    BLUE_SECONDARY: '#C0E7FF',
    BLUE_TERTIARY: '#437088'
  };

   const BAR_CHART_COLORS = {
    aideOp: ['#B3FEFE', '#68FEFD', '#00D8D8', '#4CBABA', '#4CA5A6', '#198C8C', '#007F80'],
    circ: ['#B2F3F3', '#66E8E8', '#00D8D8', '#D9D9D9', '#BFBFBF', '#A6A6A6', '#7F7F7F']
  };
  

export const themeStyling = {
    fontFamilyPrimary: 'Inter',
};

export const themeStylingColors = {
    color_primary : "#37AEEB",
    primary_dark : "#127EB4",
    primary_light : "#BEE5F8",
    primary_subtle : "#EEF8FD",

    dark_red : "#EB405E",
    danger_base : "#EB405E",
    danger_subtle : "#EB405E",
    positive_dark : "#39CC85",
    positive_base : "#39CC85",
    positive_light : "#39CC85",
    warning : "#EFA764",

    color_disabled : "#9e9e9e",

    dark_high_emphasis : "rgba(1, 22, 34, 0.87)",
    dark_medium_emphasis: "rgba(1, 22, 34, 0.60)",
    dark_low_emphasis : "rgba(1, 22, 34, 0.38)",

    border_color :  "rgba(29, 29, 30, 0.12)",
    main_background_color  : "#F0F5F8",
    sticky_title_background_color : "#E4E9EC",
    color_room_number : "#37AEEB"
};

export const themeStylingPAGraphColors = {

    // General

    // formation Graphe
    color_graph_formation : [CHART_COLORS.BLUE_TERTIARY, CHART_COLORS.BLUE_SECONDARY, CHART_COLORS.BLUE_PRIMARY],
    font_color_graph_formation: themeStylingColors.dark_high_emphasis,

    // experience per specialty Graphe
    color_graph_experience_per_specialty_circ : [CHART_COLORS.BLUE_SECONDARY, CHART_COLORS.BLUE_PRIMARY, CHART_COLORS.BLUE_SECONDARY, CHART_COLORS.BLUE_PRIMARY],
    color_graph_experience_per_specialty_aideOp : [CHART_COLORS.BLUE_TERTIARY, CHART_COLORS.BLUE_SECONDARY, CHART_COLORS.BLUE_PRIMARY],
    experience_per_specialty_graph_label_color: themeStylingColors.dark_medium_emphasis,
    font_color_graph_experience_per_specialty: themeStylingColors.dark_high_emphasis,

    // variation graphe
    color_graph_variation_1 : CHART_COLORS.EFFECTIFS,
    color_graph_variation_2 : CHART_COLORS.NEEDS,
    font_color_graph_variation: themeStylingColors.dark_high_emphasis,

    // average experience per specialty graphe
    color_graph_average_year_specialty : CHART_COLORS.BLUE_PRIMARY,
    color_graph_average_year_general : CHART_COLORS.BLUE_SECONDARY,
    font_color_graph_average_year: themeStylingColors.dark_high_emphasis,
    experience_per_specialty_graph_grid_color: themeStylingColors.border_color,

    // progression per specialty graphe
    color_graph_progression_per_specialty_open : themeStylingColors.color_primary,
    color_graph_progression_per_specialty_positive : themeStylingColors.positive_base,
    color_graph_progression_per_specialty_negative : themeStylingColors.danger_base,
    font_color_graph_progression_per_specialty: themeStylingColors.dark_high_emphasis,

    // effectif distribution by experience graphe
    bubble_graph_grid_lines_color: themeStylingColors.border_color,
    bubble_graph_labels_color: themeStylingColors.dark_medium_emphasis,
    bubble_graph_border_color_on_hover: themeStylingColors.primary_dark,

    graph_level_1: '#C0E7FF',
    graph_level_2: '#ADDDFB',
    graph_level_3: '#9AD4F8',
    graph_level_4: '#86CAF5',
    graph_level_5: '#70C1F1',
    graph_level_6: '#57B7EE',
    graph_level_7: '#37AEEB',

    color_graph_effectif_distribution_per_specialty_autonomous : CHART_COLORS.BLUE_PRIMARY,
    color_graph_effectif_distribution_per_specialty_non_autonomous : CHART_COLORS.BLUE_SECONDARY,
    color_graph_effectif_distribution_per_specialty_aideOp : CHART_COLORS.openAideOpBlue,
    color_graph_effectif_distribution_per_specialty_circ : CHART_COLORS.openCircBlue,
    font_color_graph_effectif_distribution_per_specialty: themeStylingColors.dark_high_emphasis,

    // effectif by experience graphe
    color_graph_effectif_per_specialty_aideOp : BAR_CHART_COLORS.aideOp,
    color_graph_effectif_per_specialty_circ : BAR_CHART_COLORS.circ,
    effectif_by_level_graph_grid_color: themeStylingColors.border_color,
    effectif_by_level_graph_labels_color: themeStylingColors.dark_medium_emphasis,

    
    // one account
    radar_graph_labels_font_color: themeStylingColors.dark_medium_emphasis,
    radar_graph_body_color: `${themeStylingColors.color_primary}50`,
    radar_graph_border_color: themeStylingColors.color_primary,
    radar_graph_hover_color: themeStylingColors.primary_dark,

    // Individual average experience per specialty

    color_graph_individual_averag_experience_per_specialty_aideOp : CHART_COLORS.openAideOpBlue,
    color_graph_individual_averag_experience_per_specialty_circ : CHART_COLORS.openCircBlue,
    font_color_graph_individual_averag_experience_per_specialty: themeStylingColors.dark_high_emphasis,

    // Individual specialty progress

    color_graph_individual_specialty_progress_inner : '#e7e8ea',
    color_graph_individual_specialty_progress_outer : '#4882c2',
    font_color_graph_individual_specialty_progress: themeStylingColors.dark_high_emphasis,


};

export const themeStylingStatopGraphColors = {

    // General

    // Reasons table 
    color_graph_reasons_presence : themeStylingColors.positive_light,
    color_graph_reasons_absence : themeStylingColors.danger_subtle,
    color_graph_reasons_1 : themeStylingColors.primary_light,
    color_graph_reasons_2 : themeStylingColors.main_background_color,
    color_graph_reasons_3 : "#FFFF",
    font_color_graph_reasons: themeStylingColors.dark_high_emphasis,

    // Intervention per day 
    border_color_intervention_per_day : themeStylingColors.border_color,
    color_intervention_per_day : themeStylingColors.color_primary,
    font_color_intervention_per_day: themeStylingColors.dark_medium_emphasis,

     // Intervention per day per specialty 
     font_color_intervention_per_day_per_specialty: themeStylingColors.dark_medium_emphasis,

     // Intervention per surgeon per specialty 
    //  color_intervention_per_surgeon_per_specialty : ['#37AEEB', '#43B3EC', '#50B8ED', '#5CBDEF',
    //  '#68C2F0', '#74C7F1', '#81CCF2', '#8DD1F3',
    //  '#99D6F4', '#A5DBF6', '#B2E0F7', '#BEE5F8'],
     color_intervention_per_surgeon_per_specialty : ['#37AEEB', '#57B7EE', '#70C1F1', '#86CAF5',
     '#9AD4F8', '#ADDDFB', '#C0E7FF', '#C0E7FF',
     '#C0E7FF', '#C0E7FF', '#C0E7FF', '#C0E7FF'],
     font_color_intervention_per_surgeon_per_specialty: themeStylingColors.dark_medium_emphasis,
     hover_border_color_intervention_per_surgeon_per_specialty: themeStylingColors.primary_dark,

      // average efficacity rate
      color_average_efficacity_rate_1 : CHART_COLORS.secondary,
      color_average_efficacity_rate_2 : CHART_COLORS.primary,
      color_average_efficacity_rate_1_line : themeStylingColors.color_primary,
      color_average_efficacity_rate_2_line : 'rgb(75, 192, 192)',
      color_average_efficacity_rate_3_line : themeStylingColors.danger_base,
      font_color_average_efficacity_rate: themeStylingColors.dark_medium_emphasis,

      // period efficacy rate 
      color_period_efficacy_rate_needle : '#9EA6AB',
      color_period_efficacy_rate_arc : ['#EB405E','#F5CAA2', '#39CC85'],
      color_overflow_rate_arc : ['#39CC85', '#EFA764', '#EB405E'],

      // efficacity rate per day per day 
      color_efficacity_rate_per_day : themeStylingColors.color_primary,
      font_color_efficacity_rate_per_day: themeStylingColors.dark_medium_emphasis,

       // one surgeon doghnut 
       one_surgeon_doghnut : [ themeStylingColors.primary_dark , themeStylingColors.primary_light ],
       font_one_surgeon_doghnut: themeStylingColors.dark_high_emphasis,
};

export const themeStylingAccountGraphColors = {
  // Availability distribution 
  account_availability_graph : ['#0E166B', '#6672EE', '#1E2FEB', '#2E336B',
  '#1825B8', '#2E1B6B', '#9C83EE', '#262755',
  '#01FEFF', '#E0EAF5', '#37AFD2', '#262755'],
  account_availability_graph_absence : ['#ff2400' , '#ed2939' , '#cd5c5c' , '#e60026' , '#960018' , '#e0115f' , '#dc143c' , '#da2c43' , '#ce2029', '#6a0e00','#f42200', '#b31900', '#ff4c2e', '#ff684f', '#ff8b78', '#fecac1', '#fed1c9', '#490a00'],
  account_availability_graph_bloc : '#7FD7A7',
  account_availability_graph_consultation : '#BAB8B9',
  account_availability_graph_garde : '#FFDF80',

  font_account_availability_graph : themeStylingColors.dark_high_emphasis,
  font_account_availability_graph_presence : themeStylingColors.dark_high_emphasis,
  font_account_availability_graph_absence : "#FFFFFF",
}

export const ICONSETS = ['default' , 'lightTheme']
export const ICONSET = 'default' 

export function getSpecialtyFontColorPA(specialtyName) {
switch (specialtyName) {
    case "Orthopédie membre supérieur":
    return "white";
    case "Orthopédie membre inférieur":
    return "white";
    case "Vasculaire-thoracique":
    return "black";
    case "Digestif":
    return "black";
    case "Urologie":
    return "black";
    case "Orl":
    return "black";
    case "Rythmologie":
    return "white";
    case "Endoscopie":
    return "black";
    case "Stomatologie":
    return "black";
    case "Esthétique":
    return "white";
    case "Ophtalmologie":
    return "black";
    case "Gynéco-obstétrique":
    return "black";
    case "Pneumologie":
    return "black";
    case "Bariatrique":
    return "black";
    default:
    return "black";
}
}