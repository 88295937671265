import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {urlEndPoints} from '../config/end-points';
import {Skill} from '../models/skill.model';

@Injectable({
  providedIn: 'root'
})
export class SkillDal {

  constructor(private httpClient: HttpClient) {
  }

  getSkillsOfNurse(nurseId: String) {
    return this.httpClient.get<Skill[]>(`${urlEndPoints.skill}/by/profile/${nurseId}`);
  }

  getManySkillOfNurses(nursesIds: string[], specialesIds: string[]): Observable<Skill[]> {
    return this.httpClient.post<Skill[]>(urlEndPoints.skill + '/of-many/nurses', {
      nurses: nursesIds,
      specialties: specialesIds
    });
  }

  getNurseRating(profilId: string, hospital: string, date: string, vacationsId: string[]) :Observable<any> {
    return this.httpClient.post<any>(urlEndPoints.skill + '/getNurseRating', {
        profile: profilId,
        hospital: hospital,
        date: date,
        vacations: vacationsId,
    });
  }

  // NOT USED
  // getManySimulatedSkillOfNurses(date, nursesIds: string[], specialesIds: string[]): Observable<Skill[]> {
  //   return this.httpClient.post<Skill[]>(urlEndPoints.skill + `/of-many/nurses/simulated/${date}`, {
  //     nurses: nursesIds,
  //     specialties: specialesIds
  //   });
  // }

  // getAllLevelUpRequests(): Observable<Skill[]> {
  //   return this.httpClient.get<Skill[]>(`${urlEndPoints.skills}levelUpRequests`);
  // }
}
