import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'testIsNull'
})
export class TestIsNullPipe implements PipeTransform {

  transform(value: any, instead?): any {
    return value ? value : instead;
  }

}
