import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-edit-wish-popup',
  templateUrl: './edit-wish-popup.component.html',
  styleUrls: ['./edit-wish-popup.component.scss']
})
export class EditWishPopupComponent implements OnInit {
  public specialtyName: string = '';
  public inputValue: number = 0;
  public type: string = '';

  constructor(
    private dialogRef: MatDialogRef<EditWishPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.specialtyName = this.data.specialtyName;
    this.inputValue = this.data.inputValue;
    this.type = this.data.type;
  }

  ngOnInit() {
  }

  save(): void {
    this.dialogRef.close(this.inputValue);
  }

  close(): void {
    this.dialogRef.close();
  }
}
