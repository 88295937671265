import { Component, Input, OnInit } from '@angular/core';
import { themeStylingColors } from 'src/themes/common/principal-theme';

@Component({
  selector: 'app-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss']
})
export class LegendComponent implements OnInit {
  @Input() highlightLabelOnHover: boolean = false;
  @Input() label: string;
  @Input() color: string;
  @Input() borderRadius: string;
  @Input() data: any = null;
  @Input() boxSize: string = '15px';
  @Input() boxRightMargin: string = '7.5px';

  public isHovering: boolean = false;
  public hoverColor = themeStylingColors.primary_dark;

  constructor() { }

  ngOnInit() {

  }

}
