import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';

import { NavigationService } from './core/services/navigation.service';
import { ErrorService } from './shared/services/error.service';
import { RoutingStateService } from './core/services/routing-state.service';
import { ShareCalendarRequestService } from './shared/services/share-calendar-request.service';
import { ChatService } from './core/services/chat.service';
import { StorageService } from './core/services/storage.service';
import { HrsuiteComponent } from './hrsuite/hrsuite.component';
import { deactivateComponentService } from './shared/services/desactivate.service';
import { UtilisService } from './shared/services/utilis.service';
import { RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterEvent } from '@angular/router';
import { HrsuiteService } from './hrsuite/hrsuite.service';
import { SpecialtyColorService } from './specialty-color.service';
import { UserService } from './shared/services/user.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public isLoading: boolean = false;
  public isDarkMode: boolean = false;
  public isMenuEnabled: Observable<boolean>;
  public isMenuEnabledStatus: boolean = true;
  sideNavReduced: boolean;
  crToDisplayInfos: any;
  popupXLocation: string;
  popupYLocation: string;
  currentChildComp: HrsuiteComponent;
  public setSidenavSubscription: Subscription;
  public routeChangeSubscription: Subscription;
  public isLoggedIn: boolean = false;
  private routeSubscription: Subscription;
  private toggleNightSubscription: Subscription;

  get isThereNewModifs(): boolean{
    if(this.currentChildComp)
      return this.currentChildComp.isThereNewModifs
  }

  get isConnected(): boolean{
    if (this.isLoggedIn) {
      return true;
    } 
    if (this.userService.getCurrentUser()) {
      this.isLoggedIn = true;
      return true;
    }
  
    return false;
  }

  constructor(
    private shareCalendarRequestService: ShareCalendarRequestService,
    private hrsuiteService : HrsuiteService,
    private navigationService: NavigationService,
    private errorService: ErrorService,
    private chatService : ChatService,
    private storageService : StorageService,
    private deactivateService: deactivateComponentService,
    private userService: UserService,
    private routingState: RoutingStateService,
    private router: Router,
    private specialtyColorService: SpecialtyColorService
  ) {
    this.specialtyColorService.initialize();
    this.routingState.loadRouting();
    this.subscribeSetSidenav();
    this.subscribeRouteChange();
    this.shareCalendarRequestService.sharedMessage.subscribe(cr => {this.crToDisplayInfos = cr})
     // Connection to socket
   //  this.connectToSocket();
    this.routeSubscription = this.router.events.subscribe(
      (event: RouterEvent): void => {
        if (event instanceof RouteConfigLoadStart) {
          this.isLoading = true;
        } else if (event instanceof RouteConfigLoadEnd) {
          this.isLoading  = false;
        }
      }
    );
    
    this.toggleNightSubscription = this.hrsuiteService.toggleNightSubject.subscribe(val => {
      this.isDarkMode = val
    })
  }

  subscribeSetSidenav() {
    if (this.setSidenavSubscription) {
      this.setSidenavSubscription.unsubscribe();
    }

    this.setSidenavSubscription = this.navigationService.setSidenav.subscribe(() => {
      this.isMenuEnabledStatus = !this.isMenuEnabledStatus
    })
  }

  subscribeRouteChange() {
    if (this.routeChangeSubscription) {
      this.routeChangeSubscription.unsubscribe();
    }

    this.routeChangeSubscription = this.navigationService.routeChanges().subscribe((e) => {
      this.isMenuEnabledStatus = !this.navigationService.hideSideNavInCurrentPage(e)
    })
  }

  connectToSocket(){
    const user = this.storageService.getUser();
    if(user){
      this.chatService.setUser();
      this.chatService.connect();
    }
  }

  toggleSideNav() {
    this.sideNavReduced = !this.sideNavReduced
  }

  @HostListener('window:beforeunload', [ '$event' ])
  beforeUnloadHandler(event) {
    this.chatService.disconnectSocket();
  }

  onActivate(componentRef){
    if(componentRef instanceof HrsuiteComponent)
      this.currentChildComp = componentRef
  }

  save() {
    this.deactivateService.switchDeactivate.next(true);
    if(this.isThereNewModifs)
      this.currentChildComp.save();
  }  

  ngOnInit(): void {
      this.navigationService.routeChanges().pipe(map(event => {
      this.navigationService.initNavigation(event).catch((error) => {
        this.errorService.handleError(error);
      })
    }));
  }

  ngOnDestroy(): void {
    if (this.setSidenavSubscription) {
      this.setSidenavSubscription.unsubscribe();
    }

    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }

    if (this.toggleNightSubscription) {
      this.toggleNightSubscription.unsubscribe();
    }

    if (this.routeChangeSubscription) {
      this.routeChangeSubscription.unsubscribe();
    }
  }
}
