import {Component, Inject, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import * as moment from 'moment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { PrintService } from 'src/app/smart-planning/program-recap/print-service/print-service.service';
import { BufferProgramService } from '../../services/buffer-program.service';
import { ErrorService } from '../../services/error.service';
import { RoomService } from '../../services/room.service';
import { ToastService } from '../../services/toast.service';
import { dateWithTime, getFirstHospitalSelectedData, getSelectedHospitalData, setTypeAttributeArray, sortRoomsByPriority } from '../../utils/cross-functions';
import { ProfileService } from '../../services/profile.service';
import { Room } from '../../models/room.model';
import { ProgramCommentaryService } from '../../services/program-commentary.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Specialty } from '../../models/specialty.model';
import { SpecialtyService } from '../../services/specialty.service';
import { UserService } from '../../services/user.service';
import { UtilisService } from '../../services/utilis.service';
import { BufferProgram } from '../../models/buffer-program.model';

@Component({
  selector: 'app-export-day-popup',
  templateUrl: './export-day-popup.component.html',
  styleUrls: ['./export-day-popup.component.scss']
})
export class ExportDayComponent implements OnInit, OnDestroy{

  public comment: string;
  public startDate: string;
  public endDate: string;
  public isExporting: boolean;
  public isDataLoaded: boolean;
  private isDayWeek: boolean;
  public formGroup: FormGroup;
  isDateValid;
  public combineAllProgramsInOneFile: boolean = false;
  public showCombineProgramsCheckbox: boolean  = false;
  
  public isCadreDeBloc: boolean = false;
  public isRespIade: boolean = false;
  public isRespIntern: boolean = false;
  public isRespAnesth: boolean = false;
  public levelOfAccess: number = 1;

  public extracliniqueSpecialty: Specialty;

  private getAllSpecialtiesSubscription: Subscription;
  private getDataSub: Subscription;

  public allSpecialties: Specialty[];
  public consultationSpecialties: Specialty[];
  get getIsDateValid(){
    return this.formGroup.controls.startDate.value <= this.formGroup.controls.endDate.value;
  }

  constructor(
    private profileService: ProfileService,
    private toastService: ToastService,
    private printService : PrintService,
    private roomsService: RoomService,
    private errorService: ErrorService,
    private bufferProgramService: BufferProgramService,
    public dialogRef: MatDialogRef<any>,
    public programCommentaryService :ProgramCommentaryService,
    @Inject(MAT_DIALOG_DATA) public data,
    private formBuilder: FormBuilder,
    private specialtyService: SpecialtyService,
    private userService: UserService,
    private utilisService: UtilisService
  ) {
    this.isRespIade = this.userService.isIadRes();
    this.isRespAnesth = this.userService.isAnesthResponsable();
    this.isRespIntern = this.userService.isInternResponsible();
    this.levelOfAccess = this.userService.getLevelAccess();
      this.isCadreDeBloc = this.userService.isCadreBloc();
      this.isDayWeek = data.isDayWeek;
      this.comment = data.comment;
  }

  ngOnInit() {
    this.getSpecialties().then((spes)=>{
      this.allSpecialties = spes;
      this.extracliniqueSpecialty = this.allSpecialties.find((spe) => spe.name === 'Extraclinique')
      this.consultationSpecialties = this.allSpecialties
      .filter((spe) => spe.type.includes('consultation'))
      .sort((a, b) => {
        if (a.name === 'Anesthésie-réanimation') {
          return -1;
        }

        if (b.name === 'Anesthésie-réanimation') {
          return 1;
        }

        return a.priority - b.priority;
      });
    });
    this.makeForm();
    this.initListeners();
    this.isDateValid = this.getIsDateValid;
  }

  makeForm(): void {
    this.formGroup = this.formBuilder.group({
      startDate: [{ value: this.UTCDateToDate(new Date(this.data.date)), disabled: !this.isDayWeek }, [Validators.required]],
      endDate: [{ value: this.UTCDateToDate(new Date(this.data.date)), disabled: !this.isDayWeek }, [Validators.required]]
    });
  }

  initListeners(): void {
    this.formGroup.controls.startDate.valueChanges.subscribe((val) => {
      this.updateShowCombineProgramsCheckbox();
      this.isDateValid = this.getIsDateValid;
    });

    this.formGroup.controls.endDate.valueChanges.subscribe((val) => {
      this.updateShowCombineProgramsCheckbox();
      this.isDateValid = this.getIsDateValid;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.isDateValid = this.getIsDateValid;
  }

  updateShowCombineProgramsCheckbox(): void {
    // This function used to update the variable showCombineProgramsCheckbox when the dates are modified
    const startDate = new Date(this.formGroup.controls.startDate.value);
    const endDate = new Date(this.formGroup.controls.endDate.value);
    if (startDate >= endDate) {
      this.showCombineProgramsCheckbox = false;
    } else {
      this.showCombineProgramsCheckbox = true;
    }
  }

  exportPlanning(){
    const startDate = moment(this.formGroup.controls.startDate.value).format("YYYY-MM-DD").toString();
    const endDate  = moment(this.formGroup.controls.endDate.value).format("YYYY-MM-DD").toString();
    this.isExporting = true;
    this.isDataLoaded = false;
    this.getExportRooms(startDate,endDate);
  }

  UTCDateToDate(date: Date): Date {
    const result = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
      date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
    return result;
  }


  close(): void {
    this.dialogRef.close();
  }

  async getExportRooms(startDate,endDate) {
    if (startDate && endDate) {
      if(this.getDataSub)
        this.getDataSub.unsubscribe();
      let forks = [
        this.profileService.getOutOfRoomNursesFromTo(startDate,endDate),
        this.bufferProgramService.getAllRoomsBufferProgramsFromTo(startDate,endDate),
        this.roomsService.getHospitalRoomsDaysSurgeonOpeningsFromTo(startDate,endDate),
        this.programCommentaryService.getProgramCommentaryPeriod(startDate,endDate)
      ]

      if (!(this.isCadreDeBloc && this.levelOfAccess === 4)) {
        forks.push(this.profileService.getOutOfRoomIadesFromTo(startDate,endDate))
      }

      this.getDataSub = await forkJoin(forks)
      .subscribe(
        async (data: any[]) => {
          this.isDataLoaded = true;
          const allOutOfRoomsNurses = data && data[0] ? data[0] : [];
          const allRooms = data && data[1] ? data[1] : [];
          const allRoomsSurgeonOpenings = data && data[2] ? data[2] : [];
          const comments = data && data[3] ? data[3] : [];
          const allOutOfRoomsIades = data && data[4] ? data[4] : [];

          let consultationAndExtracliniqueBufferPrograms = allRooms.find((obj) => obj._id == null);
          consultationAndExtracliniqueBufferPrograms = consultationAndExtracliniqueBufferPrograms ? consultationAndExtracliniqueBufferPrograms.programs : [];
          consultationAndExtracliniqueBufferPrograms = consultationAndExtracliniqueBufferPrograms.filter((program) => program.anesthesists.length === 1);
          const extracliniqueBufferPrograms = consultationAndExtracliniqueBufferPrograms.filter((program) => program.type === 'bloc' && String(program.specialty) === this.extracliniqueSpecialty._id);
          const consultationsBufferPrograms = consultationAndExtracliniqueBufferPrograms.filter((program) => program.type === 'consultation');

          let contents = [];

          for (let index = 0; index < allOutOfRoomsNurses.length; index++) {
            const day = allOutOfRoomsNurses[index].date;
            const commentObj = comments.find(c => moment(c.date).format('YYYY-MM-DD') === day)
            const comment = commentObj ? commentObj.comment : "";
            let outOfRoomsNurses = this.isRespIade || this.isRespAnesth || this.isRespIntern ? [] : allOutOfRoomsNurses && allOutOfRoomsNurses[index] && allOutOfRoomsNurses[index].profiles ? allOutOfRoomsNurses[index].profiles : [];
            let outOfRoomsIades = (this.isRespAnesth || this.isRespIntern && !this.isRespIade) ? [] :allOutOfRoomsIades && allOutOfRoomsIades[index] && allOutOfRoomsIades[index].profiles ? allOutOfRoomsIades[index].profiles : [];
            let roomPrograms = [];
            allRooms
            .forEach( roomProgram=>{
              const room = {...roomProgram};
              room.programs =
                [...roomProgram.programs
                  .filter( prog=> moment(prog.date).format('YYYY-MM-DD') === day)];
              if(room.programs.length)
                roomPrograms.push(room);
            })

            let roomsSurgeonOpenings = allRoomsSurgeonOpenings.find( room=>  room.date === day)
              ? allRoomsSurgeonOpenings.find( room=>  room.date === day).data
              : [];

              sortRoomsByPriority(roomsSurgeonOpenings, false);

            let typeAttributeArray = setTypeAttributeArray(
              roomsSurgeonOpenings,
              false
            );

            roomsSurgeonOpenings =  roomsSurgeonOpenings.map(
              (roomSurgeonOpenings) => {
                return {
                  ...roomSurgeonOpenings,
                  surgeonopeninghistory: this.getOnlyOpenedSurgeonOpeningsOfRoom(
                    roomSurgeonOpenings.surgeonopeninghistory
                  ),
                  surgeonopening: this.getOnlyOpenedSurgeonOpeningsOfRoom(
                    roomSurgeonOpenings.surgeonopening
                  ),
                };
              }
            );

            // Construct extracliniqueObject
            const dateExtracliniqueBufferPrograms = extracliniqueBufferPrograms.filter((buffer) => new Date(buffer.date).getTime() === new Date(day).getTime());
            const extracliniqueDayPrograms = this.getFormattedDayPrograms(dateExtracliniqueBufferPrograms);
            const extracliniqueMorningPrograms = this.getFormattedMorningPrograms(dateExtracliniqueBufferPrograms);
            const extracliniqueAfternoonPrograms = this.getFormattedAfternoonPrograms(dateExtracliniqueBufferPrograms);
            const extracliniqueObject = {
              specialty: this.extracliniqueSpecialty,
              dayPrograms: extracliniqueDayPrograms,
              morningPrograms: extracliniqueMorningPrograms,
              afternoonPrograms: extracliniqueAfternoonPrograms,
            }

            // Construct consultationsObjects
            let consultationsObjects = [];
            const dateConsultationsBufferPrograms = consultationsBufferPrograms.filter((buffer) => new Date(buffer.date).getTime() === new Date(day).getTime());
            this.consultationSpecialties.forEach((spe) => {
              const specialtyBufferPrograms = dateConsultationsBufferPrograms.filter((program) => program.specialty === spe._id);
        
              // the field anesthesists in bufferprogram contain only one anesth if the bufferprogram is for consultation or extraclinique
              const dayPrograms = this.getFormattedDayPrograms(specialtyBufferPrograms);
              const morningPrograms = this.getFormattedMorningPrograms(specialtyBufferPrograms);
              const afternoonPrograms = this.getFormattedAfternoonPrograms(specialtyBufferPrograms);
        
              consultationsObjects.push({
                specialty: spe,
                dayPrograms: dayPrograms,
                morningPrograms: morningPrograms,
                afternoonPrograms: afternoonPrograms,
              });
            });

            const weekDay: number = new Date(day).getUTCDay();

            if( ( 0<weekDay && weekDay<6 )|| !this.isDayWeek) {
              const data = this.getDataToExport([extracliniqueObject],consultationsObjects, roomPrograms,roomsSurgeonOpenings,typeAttributeArray,outOfRoomsNurses, outOfRoomsIades, comment, day);
              let content = await this.printService.constructContent([data]);
              if (this.combineAllProgramsInOneFile) {
                if (index < allOutOfRoomsNurses.length - 1) {
                  // If its not the last date, we add a page break
                  content.push({ text: '', pageBreak: 'after' });
                }
                contents.push(content);
              } else {
                await this.printService.exportOnePlanning(content, data.date);
              }
            }
          }

          if (this.combineAllProgramsInOneFile) {
            await this.printService.exportManyPlanningsIntoOneFile(contents, new Date(this.formGroup.controls.startDate.value), new Date(this.formGroup.controls.endDate.value));
          }

          this.dialogRef.close(true);
        },
        (error) => {
          this.isExporting = false;
          this.errorService.handleError(error);
        }
      );
    }
  }

  getOnlyOpenedSurgeonOpeningsOfRoom(roomSurgeonOpenings) {
    return roomSurgeonOpenings.filter(
      (roomSurgeonOpening) =>
        !("opening" in roomSurgeonOpening) || roomSurgeonOpening.opening
    );
  }

  getDataToExport(extracliniqueObject,consultationsObjects,rooms,roomsSurgeonOpenings,typeAttribute,outOfRoomsNurses,outOfRoomsIades, comment,date)
  : {extracliniqueObject,consultationsObjects,mergedRooms, outOfRoomsNurses,outOfRoomsIades,comment, date}
  {
    const sortRooms = this.sortPrograms(rooms);
    let mergedRooms = this.mergeExportRoomsSurgonOpeningsWithRoomsBufferPrograms(
      roomsSurgeonOpenings,
      sortRooms,
      typeAttribute
    );

    mergedRooms= mergedRooms.filter(room=> (room.surgeons && room.surgeons.length) || (room.programs) );
    return {extracliniqueObject,consultationsObjects, mergedRooms, outOfRoomsNurses, outOfRoomsIades, comment, date};
  }

  mergeExportRoomsSurgonOpeningsWithRoomsBufferPrograms(
    roomsSurgeonOpenings: any[],
    fullRooms: any[],
    typeAttributeArray: any[]
  ) {

    let mergedRooms = [];
    const fullRoomsIds = fullRooms.map((room) => room._id);
    mergedRooms = roomsSurgeonOpenings.map((roomSurgeonOpenings, i) => {
      const roomId = roomSurgeonOpenings.room._id;
      const index = fullRoomsIds.findIndex(
        (fullRoomId) => fullRoomId == roomId
      );
      if (index >= 0) {
        return {
          ...fullRooms[index],
          emptyRoom: false,
        };
      } else {
        const typeAttribute = typeAttributeArray[i];
        return this.formatSurgeonOpeningRoom(
          roomSurgeonOpenings,
          typeAttribute
        );
      }
    });
    return mergedRooms;
  }

  formatSurgeonOpeningRoom(roomSurgeonOpenings, typeAttribute) {
    return {
      ...roomSurgeonOpenings.room,
      roomNumber: roomSurgeonOpenings.room.roomNumber,
      surgeonOpenings: roomSurgeonOpenings[typeAttribute],
      emptyRoom: true,
      surgeons: this.getSurgeons(roomSurgeonOpenings, typeAttribute),
      anesthesiste: null,
      nurses: null,
      firstSurgeonOpeningId: this.isRoomWithoutSurgeonOpenings(
        roomSurgeonOpenings,
        typeAttribute
      )
        ? null
        : roomSurgeonOpenings[typeAttribute][0]._id,
      noSurgeonOpenings: this.isRoomWithoutSurgeonOpenings(
        roomSurgeonOpenings,
        typeAttribute
      ),
    };
  }

  getSurgeons(roomSurgeonOpenings, typeAttribute) {
    return roomSurgeonOpenings[typeAttribute].map(
      (surgeonOpening) => surgeonOpening.surgeon
    );
  }

  isRoomWithoutSurgeonOpenings(roomSurgeonOpenings, typeAttribute) {
    return roomSurgeonOpenings[typeAttribute].length === 0;
  }

  sortPrograms(rooms: Room[]): any[] {
    for (let i = 0; i < rooms.length; i++) {
      if (rooms[i].programs) {
        rooms[i].programs = rooms[i].programs.sort((a, b) =>
          new Date(a.startTime) >= new Date(b.startTime) ? 1 : -1
        );
      }
    }
    return rooms;
  }

  getSpecialties(): Promise<Specialty []>{
    return new Promise((resolve,reject)=>{
      this.getAllSpecialtiesSubscription = this.specialtyService.getAllSpecialties(true).subscribe((specialties) =>{
        resolve(specialties);
      });
    })
    
  }

  getFormattedMorningPrograms(programs: BufferProgram[]): BufferProgram[] {
    const response = this.utilisService.getMorningPrograms({ programs });
    return this.formatPrograms(response, 'morning');
  }

  getFormattedAfternoonPrograms(programs: BufferProgram[]): BufferProgram[] {
    const response = this.utilisService.getAfternoonPrograms({ programs });
    return this.formatPrograms(response, 'afternoon');
  }

  getFormattedDayPrograms(programs: BufferProgram[]): BufferProgram[] {

    const response = this.utilisService.getDayPrograms({ programs });
    return this.formatPrograms(response, 'day');
  }
  formatPrograms(programs: BufferProgram[], period: string): any[] {
    if (!programs || programs.length === 0) {
      return [];
    }

    let date;
    date = new Date(moment(programs[0].date).format("YYYY-MM-DD").toString());
    date.setUTCHours(0, 0, 0, 0);

    let currentStartTime, currentEndTime;

    const hospital = getSelectedHospitalData(this.userService.getCurrentUser(), programs[0].hospital._id ? programs[0].hospital._id : programs[0].hospital)

    let hospitalStartTime = new Date(dateWithTime(date, hospital.startTime));
    let hospitalMiddleTime = new Date(dateWithTime(date, hospital.middleTime));
    let hospitalEndTime = new Date(dateWithTime(date, hospital.endTime));

    switch (period) {
      case 'day':
        currentStartTime = hospitalStartTime;
        currentEndTime = hospitalEndTime;
        break;
      case 'morning':
        currentStartTime = hospitalStartTime;
        currentEndTime = hospitalMiddleTime;
        break;
      case 'afternoon':
        currentStartTime = hospitalMiddleTime;
        currentEndTime = hospitalEndTime;
        break;
      default:
        currentStartTime = hospitalStartTime;
        currentEndTime = hospitalEndTime;
    }

    return programs.map((bufferProgram) => { return { anesth: bufferProgram.anesthesists[0], currentStartTime, currentEndTime } })
  }
  ngOnDestroy(): void {
    if(this.getDataSub){
      this.getDataSub.unsubscribe();
      if(!this.isExporting)
        this.toastService.errorToast('Annulation');
    }
  }

}