import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatCheckboxChange} from '@angular/material';
import { OnDestroy } from '@angular/core';
import {ErrorService} from '../../services/error.service';
import {Profile} from '../../models/profile.model';
import {ProfileService} from '../../services/profile.service';
import {UserService} from '../../services/user.service';
import {NavigationService} from '../../../core/services/navigation.service';

@Component({
  selector: 'app-default-popup',
  templateUrl: './annuaire-popup.component.html',
  styleUrls: ['./annuaire-popup.component.scss']
})
export class AnnuairePopupComponent implements OnInit, OnDestroy {

  private pageSize = 10;
  private hasNextPage: any;
  private getProfilesSubscription: any;
  private usersSubscription: any;
  private page = 1;
  public searchValue = '';
  public isLoading = true;
  public profiles: Profile[];
  public scrollLoading: boolean;
  public isInMessagerie = false;

  constructor(
    private navigationService: NavigationService,
    private profileService: ProfileService,
    private userService: UserService,
    private errorService: ErrorService,
    public dialogRef: MatDialogRef<AnnuairePopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    if (this.navigationService.getCurrentPage().substr(0, 11) === '/messagerie')
    this.isInMessagerie = true;
  this.getProfileList(false);
}

doFilter = (value: string): void => {
  this.page = 1;
  this.searchValue = value;
  this.isLoading = true;
  this.getProfileList();
}

getProfileList(concat?) {
    if (this.getProfilesSubscription) {
      this.getProfilesSubscription.unsubscribe();
    }

    this.getProfilesSubscription = this.profileService.getProfiles(this.page, this.pageSize, this.searchValue, true)
    .subscribe(res => {
        concat ? this.profiles = this.profiles.concat(res.docs) : this.profiles = res.docs;
        this.profiles = this.profiles.filter(profile => profile.lastName !== 'Urgences');
        this.isLoading = false;
        this.scrollLoading = false;
        this.hasNextPage = res.hasNextPage;
        this.page++;
        this.pageSize = 10;
      }, error => {
        this.isLoading = false;
        this.errorService.handleError(error);
      });
  }

  getEmailByUserOrProfile(profile): string {
    const associatedUser = profile.user;
    if (associatedUser && associatedUser.email) {
      if(associatedUser.email.length > 22){
      return associatedUser.email.substr(0, 22) + '...';}
      else
      {
        return associatedUser.email;
      }
    }
    if (profile && profile.mail) {
      if (profile.mail.length > 22){
      return profile.mail.substr(0, 22) + '...';}
      else
      {
        return profile.mail;
      }
    }
    return '';
  }  

  getEmailByUserOrProfileTooltip(profile): string {
    const associatedUser = profile.user;
    if (associatedUser && associatedUser.email) {
      {
        return associatedUser.email;
      }
    }
    if (profile && profile.mail) {
        return profile.mail;
    }
    return '';
  }  
  
  goToMessagerie(profile) {
    if (this.isInMessagerie === true || profile.position === 'Chirurgien')
      return ;
    this.navigateTo(profile);
  }

  navigateTo(data){
    this.close();
    this.navigationService.navigateTo('/messagerie', null, {profile: JSON.stringify(data)}, false);
  }

  close(): void {
    this.dialogRef.close();
  }

  onScroll(e) {
    const tableViewHeight = e.target.offsetHeight;
    const tableScrollHeight = e.target.scrollHeight;
    const scrollLocation = e.target.scrollTop;
    const buffer = 100;
    const limit = tableScrollHeight - tableViewHeight - buffer;
    if (scrollLocation > limit && this.hasNextPage && !this.scrollLoading) {
      this.scrollLoading = true
      this.getProfileList(true);
    }
  }

  ngOnDestroy() {
  }

}
