import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { emit } from "process";

@Component({
  selector: "app-secondary-header",
  templateUrl: "./secondary-header.component.html",
  styleUrls: ["./secondary-header.component.scss"],
})
export class SecondaryHeaderComponent implements OnInit {
  @Output() date1Changed = new EventEmitter();
  @Output() date2Changed = new EventEmitter();

  @Input() hideExportButton: boolean;
  @Input() day1: Date;
  @Input() day2: Date;
  @Output() exportClicked = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  date1ChangedHandler(event) {
    this.date1Changed.emit(event);
  }

  date2ChangedHandler(event) {
    this.date2Changed.emit(event);
  }

  exportClickedHandler() {
    this.exportClicked.emit();
  }
}
