import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
  selector: "app-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"],
})
export class ConfirmDialogComponent implements OnInit {
  public message: string;
  public cssClass: string = "success";
  message2: string;

  constructor(
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.message = this.data.message;
    this.message2 = this.data.message2;
    if (this.data.cssClass) {
      this.cssClass = this.data.cssClass;
    } else if (this.data.isBlueButton) {
      this.cssClass = "info";
    }
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  accept(): void {
      this.dialogRef.close(true);
  }
}
