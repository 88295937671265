import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { ANESTHETIST, levelOfAccess, NURSE, SURGEON } from './../../const/glabals.const';
import { UserService } from 'src/app/shared/services/user.service';
import { ToastService } from "src/app/shared/services/toast.service";
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {
  formGroup: any;
  levelOfAccess = levelOfAccess
  userAdded: boolean = false;
  profile: any;
  searchProfileParam: any;
  public multiType: string[] = [];
  userLevelOfAccess: number;
  public isLoading: boolean = true;
  public disablePersonalProfileInfos: boolean = false;
  private submitProfileSubscription: Subscription;
  private valueChangedSubscription: Subscription;

  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    public dialogRef: MatDialogRef<AddUserComponent>,
  ) {}

  ngOnInit() {
    this.userLevelOfAccess = this.userService.getLevelAccess();
    if (!this.userService.isCadreBloc()) {
      this.levelOfAccess = this.levelOfAccess.slice(0 , this.userLevelOfAccess ? this.userLevelOfAccess -1 : 1)
      }
    this.setSearchType()
    this.createForm()
    this.onValueChanges();
  }

  setSearchType() {
    if (this.userService.isCadreBlocWithHighLevel()) {
      this.multiType = [NURSE + "_" + SURGEON, "Iade", ANESTHETIST]
      this.searchProfileParam = '';
    } else if (this.userService.isCadreBloc()) {
      this.searchProfileParam = NURSE + "_" + SURGEON
    } else if (this.userService.isIadRes()){
      this.searchProfileParam = "Iade"
    }  else {
      this.searchProfileParam = ANESTHETIST
    }
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required]],
      levelOfAccess: ["", Validators.required]
    });
  }

  setProfile(event) {
    this.profile = event
    this.disablePersonalProfileInfos = true
    this.levelOfAccess = [1, 2, 3];
    if (this.profile.position == "Chirurgien") {
      this.formGroup.get("levelOfAccess").setValue("1");
    } else {
      this.formGroup.get("levelOfAccess").setValue("");
    }
  }

  get firstName() {
    return this.profile && this.profile.firstName;
  }

  get lastName() {
    return this.profile && this.profile.lastName;
  }

  get position() {
    return this.profile && this.profile.position;
  }

  get residency() {
    return this.profile && this.profile.residency;
  }

  close() {
    this.dialogRef.close(this.userAdded)
  }

  get email() {
    return this.formGroup.controls['email'].value
  }

  get password() {
    return this.formGroup.controls['password'].value
  }

  get _levelOfAccess() {
    return this.formGroup.controls['levelOfAccess'].value
  }

  submitProfile() {
    if (this.formGroup.valid) {
      this.isLoading = true;
      const user = {
        profile: this.profile._id,
        email: this.email,
        password: this.password,
        levelOfAccess: this._levelOfAccess
      }
      try {
        if(this.submitProfileSubscription) this.submitProfileSubscription.unsubscribe();
        this.submitProfileSubscription = this.userService.addUser(user).subscribe((res) => {
          this.userAdded = true;  
          this.isLoading = false;
          this.close()               
        },(err)=> {
          if(err.error && err.error.payload && err.error.payload.message && err.error.payload.message.includes('duplicate key')){
            this.toastService.errorToast('Cet email existe déja.')
            this.isLoading = false;
          }
          else
          {
            this.toastService.errorToast('Une erreur est survenue lors de la création de l\'utilisateur');
            this.isLoading = false;
            this.close();
          }
        })
      } catch (error) {
        console.error(error);
      }
    }
  }

  onValueChanges(): void {
    if(this.valueChangedSubscription) this.valueChangedSubscription.unsubscribe();
    this.valueChangedSubscription = this.formGroup.get('email').valueChanges
    .subscribe((val) => {
      //  this.errorMessage = '';
     });
  }
  
  displayLevelOfAccess(levelOfAccess) {
    if (levelOfAccess) {
      // if (this.profile && this.profile.position == "Chirurgien") {
      //   return "Collaborateur limité";
      // } else {
        switch (levelOfAccess) {
          case 1:
            return "Collaborateur limité";
          case 2:
            return "Collaborateur";
          case 3:
            return "Responsable planning";
          case 4:
            return "Référent hôpital";
          case 5:
            return "Administrateur";
        }
      // }
    }
    return "-";
  }
  
  onChildLoadingChange(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  ngOnDestroy() {
    if(this.submitProfileSubscription) this.submitProfileSubscription.unsubscribe();
    if(this.valueChangedSubscription) this.valueChangedSubscription.unsubscribe();
  }
}
