import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import * as moment from "moment";
import { RoomService } from "../../../services/room.service";
import { UserService } from "../../../services/user.service";
import { Subscription } from "rxjs";
import { ProfileService } from "src/app/shared/services/profile.service";
import { Profile } from "src/app/shared/models/profile.model";
import { ErrorService } from "src/app/shared/services/error.service";
import { ANESTHETIST, IADE, NURSE_TYPES } from "src/app/shared/const/glabals.const";
import { Calendar, isGuard } from "src/app/shared/models/calendar.model";
import { HospitalService } from "src/app/shared/services/hospital.service";
import { CdkDrag, CdkDragDrop, CdkDragMove, CdkDropList, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import { TimeRange, consultationExtracliniqueData, consultationExtracliniquePrograms, profileCard, roomFinalData } from "../day-program-interfaces";
import { cloneWithoutPromise, getFirstHospitalSelectedData, getHoursAndMinutes } from "src/app/shared/utils/cross-functions";
import { newUserConfiguration } from "src/app/shared/interfaces/userConfiguration.interfaces";
import { UserConfiguration } from "src/app/shared/models/userConfiguration.model";
import { UserConfigurationService } from "src/app/shared/services/userConfiguration.service";
import { StorageService } from 'src/app/core/services/storage.service';

@Component({
  selector: "app-available-profiles",
  templateUrl: "./available-profiles.component.html",
  styleUrls: ["./available-profiles.component.scss"],
})
export class AvailableProfilesComponent implements OnInit, OnDestroy, OnChanges {
  @Input() day: Date;
  @Input() isBloc: boolean = true; // To know is bloc of consult/extra
  @Input() isConsult: boolean = false; // To know if consult or extra
  @Input() listIdsBloc: string[] = [];
  @Input() listIdsConsultationExtraclinique: string[] = [];
  @Input() anesthetistsCalendars: Calendar[] = [];
  @Input() rooms: roomFinalData[];
  @Input() extracliniqueSpecialty: consultationExtracliniqueData[];
  @Input() consultationSpecialties: consultationExtracliniqueData[];
  @Input() readOnly: boolean = false;
  @Input() sortByName: boolean = true;
  @Input() reasonMapColors: Map<string, string>; // key : profileID   value : color in hexadecimal

  @Output() onHeaderClick: EventEmitter<void> = new EventEmitter<void>();

  public isLoading: boolean = true;
  
  // List of profiles
  public profilesDropBloc: profileCard[] = []
  public profilesDropBlocNonFiltered: profileCard[] = []
  public profilesDropConsultExtra: profileCard[] = []
  public availableSplitNursesUnderTwoHours: profileCard[] = []

  // Filter data
  public filterMenuOpen: boolean = false;
  public filters: {name: string, active: boolean}[] = [
    {name: "Anesthésistes", active: true},
    {name: "IADE", active: true},
    {name: "Infirmières", active: true},
    {name: "Horaire Partiels", active: true},
  ]
  @ViewChild('filterButton', {static: false}) filterButton: ElementRef
  @ViewChild('filterMenu', {static: false}) filterMenu: ElementRef

  // User Data
  public userConfiguration: UserConfiguration;
  public levelOfAccess: number = 1;
  public hospitalHaveAnesthDetailsOption: boolean = false;
  public isRespIade: boolean = false;
  public isCadreDeBloc: boolean = false;
  public isRespAnesth: boolean = false;
  public isRespInterne: boolean = false;

  // Screen Data
  public hidden: boolean = false;

  // Subscription
  private availableAnesthetistsSubscription: Subscription;
  private availableNursesSubscription: Subscription;
  private refreshAvailabeProfilesSubscription: Subscription;
  private removeCardSubscription: Subscription;
  private getRoomServiceUpdateEmptyRoomSubscription: Subscription;
  private getRoomServiceUpdateEmptyRoomOnSplitSubscription: Subscription;
  private modifyUserConfigurationsSubscription: Subscription;
  private getUserConfigurationsSubscription: Subscription;

  get date() {
    return moment(this.day).format("YYYY-MM-DD").toString();
  }

  get availableNurses(): profileCard[] {
    const filterData = this.filters.find((filter) => filter.name === 'Infirmières')
    let availableNurses = filterData && filterData.active ? this.profilesDropBlocNonFiltered.filter((profile) => NURSE_TYPES.includes(profile.profile.position) && this.isSameTimes(profile)) : []
    
    if (this.sortByName) {
      const reverseNameOrder = this.storageService.getData("reverseOrderName");
  
      availableNurses.sort((a, b) => {
        return this.createFullName(a,b, reverseNameOrder);
      });
    } else {
      availableNurses.sort((a, b) => {
        return (new Date(a.time.startTime)).getTime() - (new Date(b.time.startTime)).getTime()
      })
    }

    return availableNurses;
  }

  get availableBlocAnesthetists(): profileCard[] {
    const filterData = this.filters.find((filter) => filter.name === 'Anesthésistes')
    let availableBlocAnesthetists = filterData && filterData.active ? this.isRespInterne ? this.profilesDropBlocNonFiltered.filter((profile) => profile.profile.position === "Anesthésiste" && profile.profile.seniority === "Interne") : this.profilesDropBlocNonFiltered.filter((profile) => profile.profile.position === "Anesthésiste") : []
    const reverseNameOrder = this.storageService.getData("reverseOrderName");

    availableBlocAnesthetists = availableBlocAnesthetists.sort((a, b) => {
      return this.createFullName(a,b, reverseNameOrder);
    });

    return [].concat(availableBlocAnesthetists)
  }

  get availableBlocIades(): profileCard[] {
    const filterData = this.filters.find((filter) => filter.name === 'IADE')
    let availableBlocIades = filterData && filterData.active ? this.profilesDropBlocNonFiltered.filter((profile) => IADE.includes(profile.profile.position)) : []
    const reverseNameOrder = this.storageService.getData("reverseOrderName");

    availableBlocIades = availableBlocIades.sort((a, b) => {
      return this.createFullName(a,b, reverseNameOrder);
    });
    
    return availableBlocIades;
  }

  get availableSplitNurses(): profileCard[] {
    const filterData = this.filters.find((filter) => filter.name === 'Horaire Partiels')
    let availableSplitNurses = filterData && filterData.active ? this.profilesDropBlocNonFiltered.filter((profile) => NURSE_TYPES.includes(profile.profile.position) && !this.isSameTimes(profile)) : []
    
    if (this.sortByName) {
      const reverseNameOrder = this.storageService.getData("reverseOrderName");
  
      availableSplitNurses.sort((a, b) => {
        return this.createFullName(a,b, reverseNameOrder);
      });
    } else {
      availableSplitNurses.sort((a, b) => {
        return (new Date(a.time.startTime)).getTime() - (new Date(b.time.startTime)).getTime()
      })
    }

    return availableSplitNurses;
  }

  get availableConsultExtraAnesthetists(): profileCard[] {
    let availableConsultExtraAnesthetists = !this.isRespIade ? this.profilesDropConsultExtra : []
    const reverseNameOrder = this.storageService.getData("reverseOrderName")

      availableConsultExtraAnesthetists = availableConsultExtraAnesthetists.sort((a, b) => {
        return this.createFullName(a,b, reverseNameOrder);
      });
    

    return availableConsultExtraAnesthetists
  }
  
  constructor(
    private roomsService: RoomService,
    private userService: UserService,
    private profileService: ProfileService,
    private errorService: ErrorService,
    private hospitalService: HospitalService,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
    private userConfigurationService: UserConfigurationService,
    private storageService: StorageService,
  ) {
    this.levelOfAccess = this.userService.getLevelAccess();
    this.hospitalHaveAnesthDetailsOption = this.hospitalService.doesHospitalHaveAnesthDetailsOption()
    this.isRespIade = this.userService.isIadRes();
    this.isCadreDeBloc = this.userService.isCadreBloc();
    this.isRespAnesth = this.userService.isAnesthResponsable()
    this.isRespInterne = this.userService.isInternResponsible()

    this.getUserConfigurations();
    this.setPermission();
    this.subscribeRefreshAvailableProfiles();
    this.subscribeRemoveCard();
    this.subscribeUpdateEmptyRoom();
    this.subscribeUpdateEmptyRoomOnSplit();

    // Check click outside of menu to close it
    this.renderer.listen('window', 'click', (e:Event) => {
      if (this.filterMenuOpen) {
        if (this.filterButton && this.filterButton.nativeElement && e.target !== this.filterButton.nativeElement &&
          this.filterMenu && this.filterMenu.nativeElement && e.target !== this.filterMenu.nativeElement) {
          this.filterMenuOpen = false;
        }
      }
    })
  }

  setPermission() {
    if (this.isCadreDeBloc && this.levelOfAccess === 4) {
      this.filters = [
        {name: "Infirmières", active: true},
        {name: "Horaire Partiels", active: true},
      ]
    } else if (this.isRespIade) {
      this.filters = [
        {name: "IADE", active: true}
      ]
    } else if (this.isRespAnesth && !this.isRespInterne) {
      this.filters = [
        {name: "Anesthésistes", active: true},
        {name: "IADE", active: true}
      ]
    } else if (this.isRespInterne) {
      this.filters = [
        {name: "Anesthésistes", active: true}
      ]
    }
  }

  subscribeUpdateEmptyRoomOnSplit() {
    if (this.getRoomServiceUpdateEmptyRoomOnSplitSubscription) {
      this.getRoomServiceUpdateEmptyRoomOnSplitSubscription.unsubscribe();
    }

    this.getRoomServiceUpdateEmptyRoomOnSplitSubscription = this.roomsService.updateEmptyRoomOnSplitSmartPlanning.subscribe((data) => {
      if (data.isBloc) {
        (data.data as roomFinalData).team.others.forEach(card => {
          if (NURSE_TYPES.includes(card.profile.position)) {
            this.setSplitNurse(card, false, true)
            this.updateIntersectingRooms();
          }
        });
      }
      this.getAvailableAnesthetists();
      this.setBlocAnesthFromConsultAnesth();
      this.setProfilesDrop();
      this.setNbAssignments(this.availableBlocAnesthetists);
      if (this.hospitalHaveAnesthDetailsOption) {
        this.setNbAssignments(this.profilesDropConsultExtra);
      }
    })
  }

  subscribeUpdateEmptyRoom() {
    if (this.getRoomServiceUpdateEmptyRoomSubscription) {
      this.getRoomServiceUpdateEmptyRoomSubscription.unsubscribe();
    }

    this.getRoomServiceUpdateEmptyRoomSubscription = this.roomsService.updateEmptyRoomSmartPlanning.subscribe((data) => {
      if (data.isBloc) {
        (data.data as roomFinalData).team.others.forEach(card => {
          if (NURSE_TYPES.includes(card.profile.position)) {
            this.setSplitNurse(card, false, true)
            this.updateIntersectingRooms();
          }
        });
      }
      this.getAvailableAnesthetists();
      this.setBlocAnesthFromConsultAnesth();
      this.setProfilesDrop();
      this.setNbAssignments(this.availableBlocAnesthetists);
      if (this.hospitalHaveAnesthDetailsOption) {
        this.setNbAssignments(this.profilesDropConsultExtra);
      }
    })
  }

  subscribeRemoveCard() {
    if (this.removeCardSubscription) {
      this.removeCardSubscription.unsubscribe();
    }

    this.removeCardSubscription = this.roomsService.removeCardSmartPlanning.subscribe((data) => {
      this.changeDetectorRef.detectChanges();
      const card = data.profile
      if (this.isBloc) {
        if (NURSE_TYPES.includes(card.profile.position)) {
          this.setSplitNurse(card, false, true)
          this.updateIntersectingRooms();
        } else {
          if (this.hospitalHaveAnesthDetailsOption && card.profile.position === "Anesthésiste") {
            card.onlyConsultExtra = this.isProfileAssignedToConsultationProgram(card.profile) && !this.isProfilealreadyAssignedBloc(card.profile)
            card.onlyExtra = this.isProfileAssignedToExtracliniqueProgram(card.profile) && !this.isProfilealreadyAssignedBloc(card.profile)
          }
          this.addCard(card);
        }
        this.getAvailableAnesthetists(false);
        this.setBlocAnesthFromConsultAnesth();
        this.setProfilesDrop();
        this.setNbAssignments(this.availableBlocAnesthetists);
        if (this.hospitalHaveAnesthDetailsOption) {
          this.setNbAssignments(this.profilesDropConsultExtra);
        }
      }
    })
  }

  isSameCard(cardA: profileCard, cardB: profileCard) {
    // IADE
    if (IADE.includes(cardA.profile.position) && IADE.includes(cardB.profile.position) &&
    (String(cardA.profile._id) === String(cardB.profile._id))) {
      return true;
    }

    if (((!cardA.time || !cardA.time.startTime) &&
      (!cardA.time || !cardA.time.endTime) &&
      (!cardB.time || !cardB.time.startTime) &&
      (!cardB.time || !cardB.time.endTime)) ||
      (!cardA.originalTime.startTime &&
        !cardA.originalTime.endTime &&
        !cardB.originalTime.startTime &&
        !cardB.originalTime.endTime)) {
      if (String(cardA.profile._id) === String(cardB.profile._id)) {
        return true;
      }
    }

    if (String(cardA.profile._id) === String(cardB.profile._id) &&
      new Date(cardA.time.startTime).getTime() === new Date(cardB.time.startTime).getTime() &&
      new Date(cardA.time.endTime).getTime() === new Date(cardB.time.endTime).getTime() &&
      new Date(cardA.originalTime.startTime).getTime() === new Date(cardB.originalTime.startTime).getTime() &&
      new Date(cardA.originalTime.endTime).getTime() === new Date(cardB.originalTime.endTime).getTime()) {
        return true;
    }

    return false;
  }

  removeCardFromNonFiltered(card: profileCard) {
    const index = this.profilesDropBlocNonFiltered.findIndex((card2) => (card2 === card) || this.isSameCard(card, card2))

    this.profilesDropBlocNonFiltered.splice(index, 1);
  }

  setBlocAnesthFromConsultAnesth() {
    this.availableConsultExtraAnesthetists.forEach(card => {
      if (this.isSameTimes(card) && !this.profilesDropBlocNonFiltered.find((card2) => String(card2.profile._id) === String(card.profile._id))) {
        const newCard: profileCard = JSON.parse(JSON.stringify(card))
        newCard.time = {startTime: null, endTime: null}
        newCard.originalTime = {startTime: null, endTime: null}
        this.profilesDropBlocNonFiltered.push(newCard);
      }
    });
  }

  setNbAssignments(anesths: profileCard[]) {
    const hospital = getFirstHospitalSelectedData(this.userService.getCurrentUser(), this.userService.getSelectedHospitals())
    const middleTime = getHoursAndMinutes(hospital.middleTime);

    anesths.forEach((card) => {
      const rooms = []
      for (let index = 0; index < this.rooms.length; index++) {
        const room = this.rooms[index];
        let found = false;
        if (!rooms.find((room2) => String(room2.roomId) === String(room.roomId))) {
          if (room.isSplit) {
            if (room.splitByVacation) {
              if (room.isBP) {
                for (let index = 0; index < room.programs.length; index++) {
                  const program = room.programs[index];
                  
                  if (program.team.others.find((card2) => String(card2.profile._id) === String(card.profile._id))) {
                    rooms.push(room)
                    found = true;
                    continue;
                  }
                }
                if (found) {
                  continue;
                }
              } else {
                for (let index = 0; index < room.surgeonOpenings.length; index++) {
                  const surgeonOpening = room.surgeonOpenings[index];
                  
                  if (surgeonOpening.team.others.find((card2) => String(card2.profile._id) === String(card.profile._id))) {
                    rooms.push(room)
                    found = true
                    continue;
                  }
                }
                if (found) {
                  continue;
                }
              }
            } else {
              if (room.morningPrograms.team.others.find((card2) => String(card2.profile._id) === String(card.profile._id))) {
                rooms.push(room)
                continue;
              }
              if (room.afternoonPrograms.team.others.find((card2) => String(card2.profile._id) === String(card.profile._id))) {
                rooms.push(room)
                continue;
              }
            }
          } else {
            if (room.team.others.find((card2) => String(card2.profile._id) === String(card.profile._id))) {
              rooms.push(room)
              continue;
            }
          }
        }
      }

      if (this.hospitalHaveAnesthDetailsOption) {
        const consults = []
        let countConsults = 0;
        this.consultationSpecialties.forEach((consult) => {
          if (!consults.find((consult2) => String(consult2.specialty._id) === String(consult.specialty._id)) && consult.team.find((card2) => String(card2.profile._id) === String(card.profile._id))) {
            consults.push(consult)
            const placement = consult.team.find((member) => String(card.profile._id) === String(member.profile._id));
            if (getHoursAndMinutes(placement.time.startTime) >= middleTime
                || getHoursAndMinutes(placement.time.endTime) <= middleTime) {
                  countConsults += 0.5;
            } else {
              countConsults++;
            }
          }
        })
  
        const extracliniques = []
        let countExtracliniques = 0;
        this.extracliniqueSpecialty.forEach((extra) => {
          if (!extracliniques.find((extra2) => String(extra2.specialty._id) === String(extra.specialty._id)) && extra.team.find((card2) => String(card2.profile._id) === String(card.profile._id))) {
            extracliniques.push(extra)
            const placement = extra.team.find((member) => String(card.profile._id) === String(member.profile._id));
            if (getHoursAndMinutes(placement.time.startTime) >= middleTime
                || getHoursAndMinutes(placement.time.endTime) <= middleTime) {
                  countExtracliniques += 0.5;
            } else {
              countExtracliniques++;
            }
          }
        })
        
        card.nbAssignments = rooms.length + countConsults + countExtracliniques;
        card.onlyConsultExtra = this.isProfileAssignedToConsultationProgram(card.profile) && !this.isProfilealreadyAssignedBloc(card.profile)
        card.onlyExtra = this.isProfileAssignedToExtracliniqueProgram(card.profile) && !this.isProfilealreadyAssignedBloc(card.profile)
      } else {
        card.nbAssignments = rooms.length
      }
    })
  }

  isInIntersectingRooms(card: profileCard, roomCheck: roomFinalData) {
    let result = false;
    const roomsWithCard: roomFinalData[] = [];
    let timeRoomCheck;
  
    if (roomCheck.isBP) {
      timeRoomCheck = { startTime: moment.utc(roomCheck.programs[0].startTime).toDate(), endTime: moment.utc(roomCheck.programs[0].endTime).toDate() }
      
      if (roomCheck && roomCheck.isSplit && roomCheck.splitByVacation) {
        roomCheck.programs.forEach(program => {
          if (program.team && program.team.others && program.team.others.find((card2) => card2 === card)) {
            timeRoomCheck.startTime = moment.utc(program.startTime).toDate()
            timeRoomCheck.endTime = moment.utc(program.endTime).toDate()
          }
        })
      } else {
        roomCheck.programs.forEach(program => {
          let startTime = moment.utc(program.startTime).toDate();
          let endTime = moment.utc(program.endTime).toDate();
      
          if (startTime.getTime() < new Date(timeRoomCheck.startTime).getTime()) {
            timeRoomCheck.startTime = startTime
          }
      
          if (endTime.getTime() > new Date(timeRoomCheck.endTime).getTime()) {
            timeRoomCheck.endTime = endTime
          }
        });
      }
    } else {
      timeRoomCheck = { startTime: moment.utc(roomCheck.surgeonOpenings[0].startTime).toDate(), endTime: moment.utc(roomCheck.surgeonOpenings[0].endTime).toDate() }
      
      if (roomCheck && roomCheck.isSplit && roomCheck.splitByVacation) {
        roomCheck.surgeonOpenings.forEach(program => {
          if (program.team && program.team.others && program.team.others.find((card2) => card2 === card)) {
            timeRoomCheck.startTime = moment.utc(program.startTime).toDate()
            timeRoomCheck.endTime = moment.utc(program.endTime).toDate()
          }
        })
      } else {
        roomCheck.surgeonOpenings.forEach(surgeonOpening => {
          let startTime = moment.utc(surgeonOpening.startTime).toDate();
          let endTime = moment.utc(surgeonOpening.endTime).toDate();
      
          if (startTime.getTime() < new Date(timeRoomCheck.startTime).getTime()) {
            timeRoomCheck.startTime = startTime
          }
      
          if (endTime.getTime() > new Date(timeRoomCheck.endTime).getTime()) {
            timeRoomCheck.endTime = endTime
          }
        });
      }
    }

    for (let index = 0; index < this.rooms.length; index++) { //Get rooms with card
      const room = this.rooms[index];
      
      if (String(room.roomId) !== String(roomCheck.roomId)) {
        if (room.isSplit && !room.splitByVacation && room.morningPrograms && room.afternoonPrograms) {
          if (room.morningPrograms.team.others.find((card2) => String(card2.profile._id) === String(card.profile._id))) {
            roomsWithCard.push(room.morningPrograms)
          }

          if (room.afternoonPrograms.team.others.find((card2) => String(card2.profile._id) === String(card.profile._id))) {
            roomsWithCard.push(room.afternoonPrograms)
          }
        } else if (room.isSplit && room.splitByVacation) {
          if (room.isBP) {
            room.programs.forEach(program => {
              if (program.team && program.team.others && program.team.others.find((card2) => String(card2.profile._id) === String(card.profile._id))) {
                const tmpRoom = cloneWithoutPromise(room)
                tmpRoom.programs = [program]
                roomsWithCard.push(tmpRoom)
              }
            });
          } else {
            room.surgeonOpenings.forEach(surgeonOpening => {
              if (surgeonOpening.team && surgeonOpening.team.others && surgeonOpening.team.others.find((card2) => String(card2.profile._id) === String(card.profile._id))) {
                const tmpRoom = cloneWithoutPromise(room)
                tmpRoom.surgeonOpenings = [surgeonOpening]
                roomsWithCard.push(tmpRoom)
              }
            });
          }
        } else {
          if (room.team.others.find((card2) => String(card2.profile._id) === String(card.profile._id))) {
            roomsWithCard.push(room)
          }
        }
      }
    }

    for (let index = 0; index < roomsWithCard.length; index++) { // Check if the card collide with other cards. And if other cards collide with the card
      const room = roomsWithCard[index];
      let time;

      if (room.isBP) {
        time = { startTime: moment.utc(room.programs[0].startTime).toDate(), endTime: moment.utc(room.programs[0].endTime).toDate() }
        
        room.programs.forEach(program => {
          let startTime = moment.utc(program.startTime).toDate();
          let endTime = moment.utc(program.endTime).toDate();
    
          if (startTime.getTime() < new Date(time.startTime).getTime()) {
            time.startTime = startTime
          }
    
          if (endTime.getTime() > new Date(time.endTime).getTime()) {
            time.endTime = endTime
          }
        });
      } else {
        time = { startTime: moment.utc(room.surgeonOpenings[0].startTime).toDate(), endTime: moment.utc(room.surgeonOpenings[0].endTime).toDate() }
        
        room.surgeonOpenings.forEach(surgeonOpening => {
          let startTime = moment.utc(surgeonOpening.startTime).toDate();
          let endTime = moment.utc(surgeonOpening.endTime).toDate();
    
          if (startTime.getTime() < new Date(time.startTime).getTime()) {
            time.startTime = startTime
          }
    
          if (endTime.getTime() > new Date(time.endTime).getTime()) {
            time.endTime = endTime
          }
        });
      }

      // Check if card time is inside room Time
      if ((this.formatCompareTime(new Date(card.time.startTime)) > this.formatCompareTime(new Date(time.startTime)) &&
          this.formatCompareTime(new Date(card.time.startTime)) < this.formatCompareTime(new Date(time.endTime))) ||
          (this.formatCompareTime(new Date(card.time.endTime)) < this.formatCompareTime(new Date(time.endTime)) &&
          this.formatCompareTime(new Date(card.time.endTime)) > this.formatCompareTime(new Date(time.startTime))) ||
          (this.formatCompareTime(new Date(card.time.startTime)) === this.formatCompareTime(new Date(time.startTime)) &&
          this.formatCompareTime(new Date(card.time.endTime)) === this.formatCompareTime(new Date(time.endTime)))) {
            result = true;
          break;
      }

      // Check if room time is inside checkRoom time
      if ((this.formatCompareTime(new Date(time.startTime)) > this.formatCompareTime(new Date(timeRoomCheck.startTime)) &&
          this.formatCompareTime(new Date(time.startTime)) < this.formatCompareTime(new Date(timeRoomCheck.endTime))) ||
          (this.formatCompareTime(new Date(time.endTime)) < this.formatCompareTime(new Date(timeRoomCheck.endTime)) &&
          this.formatCompareTime(new Date(time.endTime)) > this.formatCompareTime(new Date(timeRoomCheck.startTime))) ||
          (this.formatCompareTime(new Date(time.startTime)) === this.formatCompareTime(new Date(timeRoomCheck.startTime)) &&
          this.formatCompareTime(new Date(time.endTime)) === this.formatCompareTime(new Date(timeRoomCheck.endTime)))) {
            result = true;
          break;
      }
    }

    return result;
  }

  updateIntersectingRooms() {
    // TODO: j'ai rajouté des checks pour exclure les cards des anesths
    this.rooms.forEach(room => {
        if (room.isSplit && !room.splitByVacation) {
          room.morningPrograms.team.others.forEach(card => {
            if (card.profile.position !== ANESTHETIST) {
              card.isIntersectingRoom = null;
              card.isIntersectingRoom = this.isInIntersectingRooms(card, room.morningPrograms) 
            }
          });

          room.afternoonPrograms.team.others.forEach(card => {
            if (card.profile.position !== ANESTHETIST) {
              card.isIntersectingRoom = null;
              card.isIntersectingRoom = this.isInIntersectingRooms(card, room.afternoonPrograms)
            }
          });
        } else if (room.isSplit && room.splitByVacation) {
          if (room.isBP) {
            room.programs.forEach(program => {
              if (program.team && program.team.others) {
                program.team.others.forEach(card => {
                  if (card.profile.position !== ANESTHETIST) {
                    card.isIntersectingRoom = null;
                    card.isIntersectingRoom = this.isInIntersectingRooms(card, room)
                  }
                });
              }
            });
          } else {
            room.surgeonOpenings.forEach(surgeonOpening => {
              if (surgeonOpening.team && surgeonOpening.team.others) {
                surgeonOpening.team.others.forEach(card => {
                  if (card.profile.position !== ANESTHETIST) {
                    card.isIntersectingRoom = null;
                    card.isIntersectingRoom = this.isInIntersectingRooms(card, room)
                  }
                });
              }
            });
          }
          room.team.others.forEach(card => {
            if (card.profile.position !== ANESTHETIST) {
              card.isIntersectingRoom = null;
              card.isIntersectingRoom = this.isInIntersectingRooms(card, room)
            }
          });
        } else {
          room.team.others.forEach(card => {
            if (card.profile.position !== ANESTHETIST) {
              card.isIntersectingRoom = null;
              card.isIntersectingRoom = this.isInIntersectingRooms(card, room)
            }
          });
        }

    });
    this.roomsService.refreshChangesCard.next();
  }

  subscribeRefreshAvailableProfiles() {
    if (this.refreshAvailabeProfilesSubscription) {
      this.refreshAvailabeProfilesSubscription.unsubscribe()
    }
    
    this.refreshAvailabeProfilesSubscription = this.roomsService.refreshAvailableProfilesSmartPlanning.subscribe((data) => {
      this.changeDetectorRef.detectChanges();
      if (this.isBloc) {
        if (data && data.card) {
          if (NURSE_TYPES.includes(data.card.profile.position)) {
            this.setSplitNurse(data.card, false, data.removing ? data.removing : false);
            this.updateIntersectingRooms();
          } else if (data.card.profile.position !== 'Anesthésiste') {
            this.removeCardFromNonFiltered(data.card);
          }
        }
      } else {
        this.getAvailableAnesthetists();
        this.setBlocAnesthFromConsultAnesth();
      }
      this.setProfilesDrop();
      this.setNbAssignments(this.availableBlocAnesthetists);
      if (this.hospitalHaveAnesthDetailsOption) {
        this.setNbAssignments(this.profilesDropConsultExtra);
      }
    })
  }

  initSplitNurses() {
    this.rooms.forEach(room => {
      if (!room.isSplit) {
        room.team.others.forEach(card => {
          if (NURSE_TYPES.includes(card.profile.position)) {
            this.setSplitNurse(card, true);
          }
        });
      } else if (room.splitByVacation) {
        if (room.isBP) {
          room.programs.forEach(program => {
            program.team.others.forEach(card => {
              if (NURSE_TYPES.includes(card.profile.position)) {
                this.setSplitNurse(card, true);
              }
            });
          });
        } else {
          room.surgeonOpenings.forEach(surgeonOpening => {
            surgeonOpening.team.others.forEach(card => {
              if (NURSE_TYPES.includes(card.profile.position)) {
                this.setSplitNurse(card, true);
              }
            });
          });
        }
      } else {
        room.morningPrograms.team.others.forEach(card => {
          if (NURSE_TYPES.includes(card.profile.position)) {
            this.setSplitNurse(card, true);
          }
        });

        room.afternoonPrograms.team.others.forEach(card => {
          if (NURSE_TYPES.includes(card.profile.position)) {
            this.setSplitNurse(card, true);
          }
        });
      }
    });
  }

  async ngOnInit() {
    const promises = []
    promises.push(this.getAvailableNurses());

    await Promise.all(promises)
    this.initSplitNurses();
    this.getAvailableAnesthetists()
    this.setProfilesDrop();
    this.setNbAssignments(this.availableBlocAnesthetists);
    if (this.hospitalHaveAnesthDetailsOption) {
      this.setNbAssignments(this.profilesDropConsultExtra);
    }
    this.isLoading = false;
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.rooms) {
      this.updateIntersectingRooms();
      this.initSplitNurses();
      this.getAvailableAnesthetists()
      this.setProfilesDrop();
      this.setNbAssignments(this.availableBlocAnesthetists);
      if (this.hospitalHaveAnesthDetailsOption) {
        this.setNbAssignments(this.profilesDropConsultExtra);
      }
    }

    if (changes && changes.sortByName) {
      this.setProfilesDrop();
    }
  }

  getStartTime(date: any): string {
    let d = new Date(date);
    d.setTime(d.getTime() + d.getTimezoneOffset() * 60 * 1000);
    return `${d.getHours()}:${d.getMinutes() === 0 ? "00" : d.getMinutes()}`;
  }

  formatCompareTime(time: Date) {
    const tmp = [time.getUTCHours(), time.getUTCMinutes(), time.getUTCSeconds()]

    return tmp[0] * 3600 + tmp[1] * 60 + tmp[2]
  }

  canStillBePlaced(card: profileCard) {
    if ((this.formatCompareTime(new Date(card.time.startTime)) - 7200 > this.formatCompareTime(new Date(card.originalTime.startTime))) ||
    (this.formatCompareTime(new Date(card.time.endTime)) + 7200 < this.formatCompareTime(new Date(card.originalTime.endTime)))) {
      return true;
    }
    return false;
  }

  isTwoHoursGap(card: profileCard) {
    let startTimeString = this.getStartTime(card.time.startTime);
    let startTimeNumber = parseInt(startTimeString.replace(":", ""), 10);

    let endTimeString = this.getStartTime(card.time.endTime);
    let endTimeNumber = parseInt(endTimeString.replace(":", ""), 10);
    if (startTimeNumber + 200 < endTimeNumber) {
      return false;
    }
    return true;
  }

  // Get all card of nurse in rooms and sort them by time.
  // Then, from the orignal starttime of the card to the original endTime, check all gaps with the nurses in room and create cards
  // If gap under 2 hours, set doNotShow to true
  setSplitNurse(card: profileCard, init: boolean = false, removing: boolean = false) {
    let inRoomCards: profileCard[] = [];

    // card.invalidTime = false;

    // Profile already splitted on init
    if (init && this.profilesDropBlocNonFiltered.find((card2) => String(card2.profile._id) === String(card.profile._id))) {
      return;
    }

    if (!removing) {
      if (!this.canStillBePlaced(card)) {
        let alreadyExistingCardInAvailableProfiles = this.profilesDropBlocNonFiltered.findIndex((card2) => String(card2.profile._id) === String(card.profile._id))
  
        while(alreadyExistingCardInAvailableProfiles !== -1) {
          this.profilesDropBlocNonFiltered.splice(alreadyExistingCardInAvailableProfiles, 1);
          alreadyExistingCardInAvailableProfiles = this.profilesDropBlocNonFiltered.findIndex((card2) => String(card2.profile._id) === String(card.profile._id))
        }
        return;
      }
    }
    
    this.rooms.forEach(room => {
      if (!room.isSplit) {
        const nurse = room.team.others.find((nurse) => String(nurse.profile._id) === String(card.profile._id))
        if (nurse) {
          inRoomCards.push(nurse)
        }
      } else if (room.splitByVacation) {
        if (room.isBP) {
          room.programs.forEach(program => {
            const nurse = program.team.others.find((nurse) => String(nurse.profile._id) === String(card.profile._id))
            if (nurse) {
              inRoomCards.push(nurse)
            }
          });
        } else {
          room.surgeonOpenings.forEach(surgeonOpening => {
            const nurse = surgeonOpening.team.others.find((nurse) => String(nurse.profile._id) === String(card.profile._id))
            if (nurse) {
              inRoomCards.push(nurse)
            }
          });
        }
      } else {
        const nurse = room.morningPrograms.team.others.find((nurse) => String(nurse.profile._id) === String(card.profile._id))
        if (nurse) {
          inRoomCards.push(nurse)
        }

        const nurse2 = room.afternoonPrograms.team.others.find((nurse) => String(nurse.profile._id) === String(card.profile._id))
        if (nurse2) {
          inRoomCards.push(nurse2)
        }
      }
    });

    inRoomCards.sort((a, b) => {
      if (((new Date(a.time.endTime)).getTime()) < ((new Date(b.time.startTime)).getTime())) {
        return -1
      }

      if (((new Date(a.time.endTime)).getTime()) > ((new Date(b.time.startTime)).getTime())) {
        return 1
      }

      if (((new Date(a.time.endTime)).getTime()) === ((new Date(b.time.startTime)).getTime())) {
        if (((new Date(a.time.startTime)).getTime()) < ((new Date(b.time.endTime)).getTime())) {
          return -1
        }

        if (((new Date(a.time.startTime)).getTime()) > ((new Date(b.time.endTime)).getTime())) {
          return 1
        }
      }

      return 0
    })

    let alreadyExistingCardInAvailableProfiles = this.profilesDropBlocNonFiltered.findIndex((card2) => String(card2.profile._id) === String(card.profile._id))

    while(alreadyExistingCardInAvailableProfiles !== -1) {
      this.profilesDropBlocNonFiltered.splice(alreadyExistingCardInAvailableProfiles, 1);
      alreadyExistingCardInAvailableProfiles = this.profilesDropBlocNonFiltered.findIndex((card2) => String(card2.profile._id) === String(card.profile._id))
    }

    let splitted: boolean = false

    for (let index = 0; index < inRoomCards.length; index++) {
      const roomCard = inRoomCards[index];
      if (index === 0 || index === inRoomCards.length - 1) {
        if (index === 0) {
          if (moment.utc(card.originalTime.startTime).toDate().getTime() !== moment.utc(roomCard.time.startTime).toDate().getTime()) {
            const newCard = {
              profile: card.profile,
              role: card.role,
              originalTime: card.originalTime,
              time: {startTime: moment.utc(card.originalTime.startTime).toDate(), endTime: moment.utc(roomCard.time.startTime).toDate()},
              isGarde: card.isGarde,
              partTime: true
            }
  
            splitted = true;
            if (!this.isTwoHoursGap(newCard)) {
              this.profilesDropBlocNonFiltered.push(newCard)
            }
          }
        }

        if (index === 0 && inRoomCards.length === 2) {
          if (moment.utc(roomCard.time.endTime).toDate().getTime() !== moment.utc(inRoomCards[index + 1].time.endTime).toDate().getTime()) {
            const newCard = {
              profile: card.profile,
              role: card.role,
              originalTime: card.originalTime,
              time: {startTime: moment.utc(roomCard.time.endTime).toDate(), endTime: moment.utc(inRoomCards[index + 1].time.startTime).toDate()},
              isGarde: card.isGarde,
              partTime: true
            }
            splitted = true;
            if (!this.isTwoHoursGap(newCard)) {
              this.profilesDropBlocNonFiltered.push(newCard)
            }
          }
        }

        if (index === inRoomCards.length - 1) {
          if (moment.utc(card.originalTime.endTime).toDate().getTime() !== moment.utc(roomCard.time.endTime).toDate().getTime()) {
            const newCard = {
              profile: card.profile,
              role: card.role,
              originalTime: card.originalTime,
              time: {startTime: moment.utc(roomCard.time.endTime).toDate(), endTime: moment.utc(card.originalTime.endTime).toDate()},
              isGarde: card.isGarde,
              partTime: true
            }
            splitted = true;
            if (!this.isTwoHoursGap(newCard)) {
              this.profilesDropBlocNonFiltered.push(newCard)
            }
          }
        }
      } else {
        if (moment.utc(roomCard.time.endTime).toDate().getTime() !== moment.utc(inRoomCards[index + 1].time.endTime).toDate().getTime()) {
          const newCard = {
            profile: card.profile,
            role: card.role,
            originalTime: card.originalTime,
            time: {startTime: moment.utc(roomCard.time.endTime).toDate(), endTime: moment.utc(inRoomCards[index + 1].time.startTime).toDate()},
            isGarde: card.isGarde,
            partTime: true
          }
          splitted = true;
          if (!this.isTwoHoursGap(newCard)) {
            this.profilesDropBlocNonFiltered.push(newCard)
          }
        }
      }
    };

    if (!splitted && inRoomCards.length === 0) {
      card.partTime = false;
      this.addCard(card)
    }
  }

  setProfilesDrop() {
    this.profilesDropBloc = [];
    this.profilesDropBloc = [].concat(this.availableBlocAnesthetists, this.availableBlocIades, this.availableNurses, this.availableSplitNurses)
    this.profilesDropConsultExtra = this.availableConsultExtraAnesthetists
  }

  isSameTimes(card: profileCard) {
    if (moment.utc(card.time.startTime).toDate().getTime() === moment.utc(card.originalTime.startTime).toDate().getTime() &&
    moment.utc(card.time.endTime).toDate().getTime() === moment.utc(card.originalTime.endTime).toDate().getTime()) {
        return true;
    }
    return false;
  }

  dropCard(event: CdkDragDrop<profileCard[]>) {
    if (event.previousContainer !== event.container) {
      // Move items between lists
      const card = JSON.parse(JSON.stringify(event.previousContainer.data[event.previousIndex]))
      
      if (card.profile.position === "Anesthésiste") {
        if (event.item.data && event.item.data.isSplit) {
          const room: roomFinalData = this.rooms.find((room) => String(room.roomId) === String(event.item.data.roomId))
        
          if (room.splitByVacation) {
            if (room.isBP) {
              room.programs.forEach((program) => {
                program.team.others = program.team.others.filter((card2) => String(card2.profile._id) !== String(card.profile._id))
              })
            } else {
              room.surgeonOpenings.forEach((surgeonOpening) => {
                surgeonOpening.team.others = surgeonOpening.team.others.filter((card2) => String(card2.profile._id) !== String(card.profile._id))
              })
            }
          } else {
            room.morningPrograms.team.others = room.morningPrograms.team.others.filter((card2) => String(card2.profile._id) !== String(card.profile._id))
            room.afternoonPrograms.team.others = room.afternoonPrograms.team.others.filter((card2) => String(card2.profile._id) !== String(card.profile._id))
          }
        }
      }
      
      if (card.invalidTime) {
        card.invalidTime = false;
      }
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );

      if (this.isBloc) {
        if (card) {
          if (NURSE_TYPES.includes(card.profile.position)) {
            this.setSplitNurse(card, false, true);
            this.updateIntersectingRooms();
          } else {
            this.addCard(card);
          }
        }
      } else {
        this.getAvailableAnesthetists();
        this.setBlocAnesthFromConsultAnesth();
      }
      this.setProfilesDrop();
      this.setNbAssignments(this.availableBlocAnesthetists);
      if (this.hospitalHaveAnesthDetailsOption && card.profile.position === "Anesthésiste") {
        card.onlyConsultExtra = this.isProfileAssignedToConsultationProgram(card.profile) && !this.isProfilealreadyAssignedBloc(card.profile)
        card.onlyExtra = this.isProfileAssignedToExtracliniqueProgram(card.profile) && !this.isProfilealreadyAssignedBloc(card.profile)
      }
      this.roomsService.reloadObjectifStatusSmartPlanning.next();
    }
    if (!this.isBloc && this.hospitalHaveAnesthDetailsOption) {
      this.setNbAssignments(this.profilesDropConsultExtra);
    }
  }
  stopPropagation(event: Event) {
    event.stopPropagation()
  }

  getUserConfigurations() {
    if (this.getUserConfigurationsSubscription) {
      this.getUserConfigurationsSubscription.unsubscribe()
    }

    this.getUserConfigurationsSubscription = this.userConfigurationService.getUserConfigurations().subscribe((userConfiguration) => {
      this.userConfiguration = userConfiguration

      const anesthFilterData = this.filters.find((filter) => filter.name === 'Anesthésistes')
      const nursesFilterData = this.filters.find((filter) => filter.name === 'Infirmières')
      const iadeFilterData = this.filters.find((filter) => filter.name === 'IADE')
      const partTimeFilterData = this.filters.find((filter) => filter.name === 'Horaire Partiels')

      if (anesthFilterData) {
        if (this.userConfiguration && this.userConfiguration.smartplanning && this.userConfiguration.smartplanning.filters &&  'anesthetists' in this.userConfiguration.smartplanning.filters) {
          anesthFilterData.active = this.userConfiguration.smartplanning.filters.anesthetists
        }
      }

      if (nursesFilterData) {
        if (this.userConfiguration && this.userConfiguration.smartplanning && this.userConfiguration.smartplanning.filters && 'nurses' in this.userConfiguration.smartplanning.filters) {
          nursesFilterData.active = this.userConfiguration.smartplanning.filters.nurses
        }
      }

      if (iadeFilterData) {
        if (this.userConfiguration && this.userConfiguration.smartplanning && this.userConfiguration.smartplanning.filters && 'iade' in this.userConfiguration.smartplanning.filters) {
          iadeFilterData.active = this.userConfiguration.smartplanning.filters.iade
        }
      }
      
      if (partTimeFilterData) {
        if (this.userConfiguration && this.userConfiguration.smartplanning && this.userConfiguration.smartplanning.filters && 'partTime' in this.userConfiguration.smartplanning.filters) {
          partTimeFilterData.active = this.userConfiguration.smartplanning.filters.partTime
        }
      }
    })
  }

  modifyUserConfigurations() {
    if (this.modifyUserConfigurationsSubscription) {
      this.modifyUserConfigurationsSubscription.unsubscribe()
    }

    const anesthFilterData = this.filters.find((filter) => filter.name === 'Anesthésistes')
    const nursesFilterData = this.filters.find((filter) => filter.name === 'Infirmières')
    const iadeFilterData = this.filters.find((filter) => filter.name === 'IADE')
    const partTimeFilterData = this.filters.find((filter) => filter.name === 'Horaire Partiels')

    let newUserConfiguration: newUserConfiguration = {
      smartplanning: {
        filters: {
          anesthetists: anesthFilterData ? anesthFilterData.active : false,
          iade: iadeFilterData ? iadeFilterData.active : false,
          nurses: nursesFilterData ? nursesFilterData.active : false,
          partTime: partTimeFilterData ? partTimeFilterData.active : false
        }
      }
    }
    
    this.modifyUserConfigurationsSubscription = this.userConfigurationService.modifyUserConfigurations(newUserConfiguration).subscribe((userConfiguration) => {
    })
  }

  onClickFilter() {
    this.modifyUserConfigurations();
    this.setProfilesDrop();
  }

  filterButtonClick(event: Event) {
    event.stopPropagation();

    this.filterMenuOpen = !this.filterMenuOpen
  }

  isProfileAssignedToConsultationProgram(profile: Profile): boolean {
    const consultationAndExtracliniquePrograms: profileCard[] = [];
    this.consultationSpecialties.forEach((obj) => {
      consultationAndExtracliniquePrograms.push(...obj.team);
    });

    const elems = consultationAndExtracliniquePrograms
      .filter((team) => team.profile._id === profile._id);
    let isAssigned = elems.length != 0 ? true : false;

    if (isAssigned && elems.length == 1 && elems[0].timeRange
        && elems[0].timeRange != TimeRange.day && elems[0] && getHoursAndMinutes(elems[0].originalTime.startTime)) {
      isAssigned = false;
    }

    return isAssigned;
  }

  isProfileAssignedToExtracliniqueProgram(profile: Profile): boolean {
    const consultationAndExtracliniquePrograms: profileCard[] = [];
    this.extracliniqueSpecialty.forEach((obj) => {
      consultationAndExtracliniquePrograms.push(...obj.team);
    });

    const elems = consultationAndExtracliniquePrograms
      .filter((team) => team.profile._id === profile._id);
    let isAssigned = elems.length != 0 ? true : false;

    if (isAssigned && elems.length == 1 && elems[0].timeRange
        && elems[0].timeRange != TimeRange.day && elems[0] && getHoursAndMinutes(elems[0].originalTime.startTime)) {
      isAssigned = false;
    }

    return isAssigned;
  }

  isProfileAssignedToConsultationOrExtracliniqueProgram(profile: Profile): boolean {
    const consultationAndExtracliniquePrograms: profileCard[] = [];
    this.consultationSpecialties.forEach((obj) => {
      consultationAndExtracliniquePrograms.push(...obj.team);
    });
    this.extracliniqueSpecialty.forEach((obj) => {
      consultationAndExtracliniquePrograms.push(...obj.team);
    });

    const elems = consultationAndExtracliniquePrograms
      .filter((team) => team.profile._id === profile._id);
    let isAssigned = elems.length != 0 ? true : false;

    if (isAssigned && elems.length == 1 && elems[0].timeRange
        && elems[0].timeRange != TimeRange.day && elems[0] && getHoursAndMinutes(elems[0].originalTime.startTime)) {
      isAssigned = false;
    }

    return isAssigned;
  }

  isProfilealreadyAssignedBloc(profile: Profile): boolean {
    let isAssigned = false;
    this.rooms.forEach(room => {
      if (room.isSplit) {
        if (room.splitByVacation) {
          if (room.isBP) {
            room.programs.forEach(program => {
              if (program.team.others.find((card) => String(card.profile._id) === String(profile._id))) {
                isAssigned = true;
              }
            });
          } else {
            room.surgeonOpenings.forEach(surgeonOpening => {
              if (surgeonOpening.team.others.find((card) => String(card.profile._id) === String(profile._id))) {
                isAssigned = true;
              }
            });
          }
        } else {
          if (room.morningPrograms.team.others.find((card) => String(card.profile._id) === String(profile._id))) {
            isAssigned = true;
          }

          if (room.afternoonPrograms.team.others.find((card) => String(card.profile._id) === String(profile._id))) {
            isAssigned = true;
          }
        }
      } else {
        if (room.team.others.find((card) => String(card.profile._id) === String(profile._id))) {
          isAssigned = true;
        }
      }
    });

    return isAssigned;
  }

  getAssignedPeriod(profileId: string): string {
    let isAssignedInTheMorning = false;
    let isAssignedInTheAfternoon = false;

    let found: boolean | consultationExtracliniquePrograms;

    // Extraclinique
    for (const item of this.extracliniqueSpecialty) {
      found = item.dayPrograms.find((elt) => elt.anesth._id === profileId);
      if (found) {
        return 'day';
      } else {
        found = item.morningPrograms.find((elt) => elt.anesth._id === profileId);
  
        if (found) {
          isAssignedInTheMorning = true;
        }
  
        found = item.afternoonPrograms.find((elt) => elt.anesth._id === profileId);
  
        if (found) {
          isAssignedInTheAfternoon = true;
        }
  
        if (isAssignedInTheMorning && isAssignedInTheAfternoon) {
          return 'day';
        }
      }
    }
    

    // Consultation
    for (const item of this.consultationSpecialties) {
      if (!isAssignedInTheMorning && !isAssignedInTheAfternoon) {
        found = item.dayPrograms.find((elt) => elt.anesth._id === profileId);
      } else {
        found = false;
      }

      if (found) {
        return 'day';
      } else {
        if (!isAssignedInTheMorning) {
          found = item.morningPrograms.find((elt) => elt.anesth._id === profileId);
        } else {
          found = false;
        }

        if (found) {
          isAssignedInTheMorning = true;
        }

        if (!isAssignedInTheAfternoon) {
          found = item.afternoonPrograms.find((elt) => elt.anesth._id === profileId);
        } else {
          found = false;
        }
  
        if (found) {
          isAssignedInTheAfternoon = true;
        }
  
        if (isAssignedInTheMorning && isAssignedInTheAfternoon) {
          return 'day';
        }
      }
    }

    if (isAssignedInTheMorning) {
      return 'morning';
    }

    if (isAssignedInTheAfternoon) {
      return 'afternoon';
    }

    return null;
  }

  getAvailableTime(calendar: Calendar): { startTime: Date, endTime: Date } {
    return { startTime: calendar.morningStartTime, endTime: calendar.afternoonEndTime };
  }

  getAvailableAnesthetists(removeIades: boolean = true) {
    this.profilesDropConsultExtra = []

    // Remove all anesth to set new ones
    let anesthIndex = this.profilesDropBlocNonFiltered.findIndex((card) => card.profile.position === "Anesthésiste")
    let maxIteration = 100
    let nbIterations = 0
    while ((anesthIndex !== -1) && (nbIterations < maxIteration)) {
      this.profilesDropBlocNonFiltered.splice(anesthIndex, 1)
      anesthIndex = this.profilesDropBlocNonFiltered.findIndex((card) => card.profile.position === "Anesthésiste")
      nbIterations++;
    }

    // Remove all iades to set new ones
    if (removeIades) {
      let iadeIndex = this.profilesDropBlocNonFiltered.findIndex((card) => IADE.includes(card.profile.position))
      nbIterations = 0
      while ((iadeIndex !== -1) && (nbIterations < maxIteration)) {
        this.profilesDropBlocNonFiltered.splice(iadeIndex, 1)
        iadeIndex = this.profilesDropBlocNonFiltered.findIndex((card) => IADE.includes(card.profile.position))
        nbIterations++;
      }
    }

    if (this.hospitalHaveAnesthDetailsOption) {
      this.anesthetistsCalendars.forEach((calendar) => {
        // Get available IADE and anesth for bloc
        if (IADE.includes(calendar.profile.position)) {
          if (!this.isProfilealreadyAssignedBloc(calendar.profile) && !this.profilesDropBlocNonFiltered.find((card) => String(card.profile._id) === String(calendar.profile._id))) {
            this.profilesDropBlocNonFiltered.push({
              profile: calendar.profile,
              role: null,
              time: {
                startTime: moment.utc(calendar.morningStartTime).toDate(),
                endTime: moment.utc(calendar.afternoonEndTime).toDate()
              },
              originalTime: {
                startTime: moment.utc(calendar.morningStartTime).toDate(),
                endTime: moment.utc(calendar.afternoonEndTime).toDate()
              },
              isGarde: isGuard(calendar)
            })
          }
        } else {
          if (!this.profilesDropBlocNonFiltered.find((card) => String(card.profile._id) === String(calendar.profile._id))) {
            this.profilesDropBlocNonFiltered.push({
              profile: calendar.profile,
              time: {
                startTime: null,
                endTime: null
              },
              originalTime: {
                startTime: null,
                endTime: null
              },
              isGarde: isGuard(calendar),
              nbAssignments: 0,
              onlyConsultExtra: this.hospitalHaveAnesthDetailsOption ? this.isProfileAssignedToConsultationProgram(calendar.profile) && !this.isProfilealreadyAssignedBloc(calendar.profile) : null,
              onlyExtra: this.hospitalHaveAnesthDetailsOption ? this.isProfileAssignedToExtracliniqueProgram(calendar.profile) && !this.isProfilealreadyAssignedBloc(calendar.profile) : null
            })
          }
        }
        if (calendar.profile.position === ANESTHETIST && ['Senior', 'Junior'].includes(calendar.profile.seniority)) {
          let availableTime = this.getAvailableTime(calendar);
          if (!this.profilesDropConsultExtra.find((card) => String(card.profile._id) === String(calendar.profile._id))) {
            this.profilesDropConsultExtra.push({
              profile: calendar.profile,
              time: {
                startTime: availableTime ? availableTime.startTime : null,
                endTime: availableTime ? availableTime.endTime : null
              },
              originalTime: {
                startTime: moment.utc(calendar.morningStartTime).toDate(),
                endTime: moment.utc(calendar.afternoonEndTime).toDate()
              },
              isGarde: isGuard(calendar),
              nbAssignments: 0,
              onlyConsultExtra: this.hospitalHaveAnesthDetailsOption ? this.isProfileAssignedToConsultationProgram(calendar.profile) && !this.isProfilealreadyAssignedBloc(calendar.profile) : null,
              onlyExtra: this.hospitalHaveAnesthDetailsOption ? this.isProfileAssignedToExtracliniqueProgram(calendar.profile) && !this.isProfilealreadyAssignedBloc(calendar.profile) : null
            })
          }
        }
      })
    } else {
      this.anesthetistsCalendars.forEach((calendar) => {
        if (IADE.includes(calendar.profile.position)) {
          if (!this.isProfilealreadyAssignedBloc(calendar.profile) && !this.profilesDropBlocNonFiltered.find((card) => String(card.profile._id) === String(calendar.profile._id))) {
            this.profilesDropBlocNonFiltered.push({
              profile: calendar.profile,
              role: null,
              time: {
                startTime: moment.utc(calendar.morningStartTime).toDate(),
                endTime: moment.utc(calendar.afternoonEndTime).toDate()
              },
              originalTime: {
                startTime: moment.utc(calendar.morningStartTime).toDate(),
                endTime: moment.utc(calendar.afternoonEndTime).toDate()
              }
            });
          }
        } else {
          this.profilesDropBlocNonFiltered.push({
            profile: calendar.profile,
            role: null,
            time: {
              startTime: null,
              endTime: null
            },
            originalTime: {
              startTime: null,
              endTime: null
            }
          });
        }
      });
    }
  }

  getAvailableNurses() {
    return new Promise<void>(async (resolve, reject) => {
      if (this.availableNursesSubscription) {
        this.availableNursesSubscription.unsubscribe();
      }

      this.availableNursesSubscription = this.profileService.getOutOfRoomNurses(this.date).subscribe((nurses: any[]) => {
        nurses = nurses.map((nurse) => ({
          profile: nurse,
          role: null,
          originalTime: {startTime: moment.utc(nurse.startTime).toDate(), endTime: moment.utc(nurse.endTime).toDate()},
          time: {startTime: moment.utc(nurse.startTime).toDate(), endTime: moment.utc(nurse.endTime).toDate()}
        }))

        this.profilesDropBlocNonFiltered = this.profilesDropBlocNonFiltered.concat(nurses)
        this.setProfilesDrop();
        resolve();
      }, (error) => {
        this.errorService.handleError(error);
        reject();
      })
    })
  }

  addCard(card: profileCard) {
    if (!this.profilesDropBlocNonFiltered.find((card2) => this.isSameCard(card, card2))) {
      let time = card.originalTime;
      let newCard = cloneWithoutPromise(card);
      newCard.originalTime = time;
      if (card.profile.position !== "Anesthésiste") {
        (newCard as profileCard).time.startTime = (newCard as profileCard).originalTime.startTime ? moment.utc((newCard as profileCard).originalTime.startTime).toDate() : null;
        (newCard as profileCard).time.endTime = (newCard as profileCard).originalTime.endTime ? moment.utc((newCard as profileCard).originalTime.endTime).toDate() : null;
      } else {
        if ((newCard as profileCard).time) {
          (newCard as profileCard).time.startTime = null;
          (newCard as profileCard).time.endTime = null;
        }
      }
      this.profilesDropBlocNonFiltered.push((newCard as profileCard));
    }
  }

  headerClickHandler() {
    this.hidden = !this.hidden
    this.filterMenuOpen = false;
    this.onHeaderClick.emit();
  }


  createFullName(a, b, reverseNameOrder) {
    if (reverseNameOrder) {
      return `${a.profile.lastName.toLowerCase()} ${a.profile.firstName.toLowerCase()}`.localeCompare(
        `${b.profile.lastName.toLowerCase()} ${b.profile.firstName.toLowerCase()}`
      );
    } else {
      return `${a.profile.firstName.toLowerCase()} ${a.profile.lastName.toLowerCase()}`.localeCompare(
        `${b.profile.firstName.toLowerCase()} ${b.profile.lastName.toLowerCase()}`
      );
    }
  }

  ngOnDestroy(): void {
    if (this.availableAnesthetistsSubscription) {
      this.availableAnesthetistsSubscription.unsubscribe();
    }

    if (this.availableNursesSubscription) {
      this.availableNursesSubscription.unsubscribe();
    }

    if (this.refreshAvailabeProfilesSubscription) {
      this.refreshAvailabeProfilesSubscription.unsubscribe();
    }

    if (this.removeCardSubscription) {
      this.removeCardSubscription.unsubscribe();
    }

    if (this.getRoomServiceUpdateEmptyRoomSubscription) {
      this.getRoomServiceUpdateEmptyRoomSubscription.unsubscribe();
    }

    if (this.getRoomServiceUpdateEmptyRoomOnSplitSubscription) {
      this.getRoomServiceUpdateEmptyRoomOnSplitSubscription.unsubscribe();
    }

    if (this.modifyUserConfigurationsSubscription) {
      this.modifyUserConfigurationsSubscription.unsubscribe();
    }

    if (this.getUserConfigurationsSubscription) {
      this.getUserConfigurationsSubscription.unsubscribe()
    }
  }
}