import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";

import { urlEndPoints } from "../config/end-points";
import { Profile } from "../models/profile.model";
import { map } from "rxjs/operators";
import { Period } from "../models/period.model";
import { Calendar } from "../models/calendar.model";
import { ANESTHETIST, NURSE } from "../const/glabals.const";
import { allAvailableNurses } from "../interfaces/profile.interfaces";

@Injectable()
export class ProfileDal {
  constructor(private httpClient: HttpClient) {}

  getProfiles(
    page: number,
    limit: number,
    name: string,
    noUrgences?: boolean,
    searchType?: string,
    data?: any
  ): Observable<Profile[]> {
    const commonParams = "page=" + (page ? page : 1) + "&limit=" + limit;
    let endPoint =
      urlEndPoints.profiles +
      "?" +
      commonParams +
      (name !== "" ? "&search=" + name : "") +
      (noUrgences ? "&noUrgences=true" : "") +
      (searchType ? "&searchType=" + searchType : "");
      (data ? "&data=" + data : "");
    return this.httpClient.get<Profile[]>(endPoint);
  }

  getSurgeonsByDayAvailability(
    date: string,
    opening: boolean,
    page,
    pageSize,
    searchValue
  ): Observable<Profile[]> {
    return this.httpClient.post<Profile[]>(
      urlEndPoints.profiles +
        "hrsuiteFilter?date=" +
        date +
        "&opening=" +
        opening,
        { searchValue: searchValue? searchValue : "", page, pageSize }
    );
  }

  getNumberOfProfiles(): Observable<number[]> {
    return this.httpClient.get<number[]>(
      urlEndPoints.profiles + "numberOfProfiles"
    );
  }

  searchSpecInfByName(searchString: string, limit: number = 4): any {
    return this.httpClient
      .get<any>(
        urlEndPoints.profiles +
          "byName/" +
          searchString +
          "?limit=" +
          limit +
          "&position=Anesthésiste"
      )
      .pipe(
        map((profiles: any) => {
          return profiles.docs;
        })
      );
  }

  getProfilesWithTimes(
    position?: string,
    day?,
    period?: Period,
    type: string = "allDay"
  ): Observable<Calendar[]> {
    let baseUrl = urlEndPoints.profiles;
    if (position) {
      baseUrl = `${urlEndPoints.profiles}by/isgarde/${position}`;
    }
    let params = new HttpParams();
    if (day) {
      params = params.append("jour", day);
    }
    let param1, param2;
    if (type == "allDay") {
      param1 = "from";
      param2 = "to";
    } else if (type == "morning") {
      param1 = "morningFrom";
      param2 = "morningTo";
    } else {
      param1 = "afternoonFrom";
      param2 = "afternoonTo";
    }

    if (period && period.from) {
      params = params.append(param1, period.from);
    }
    if (period && period.to) {
      params = params.append(param2, period.to);
    }
    return this.httpClient.get<Calendar[]>(baseUrl, { params });
  }

  updateUserProfile(profile: Profile): Observable<Profile> {
    return this.httpClient.put<Profile>(
      `${urlEndPoints.profiles}${profile._id}`,
      profile
    );
  }

  createProfileWithSkills(profile: Profile): Observable<Profile> {
    return this.httpClient.post<Profile>(`${urlEndPoints.profiles}`, profile);
  }

  createDefaultSpecialtySurgeon(profile: Profile, specialty: string): Observable<Profile> {
    return this.httpClient.post<Profile>(`${urlEndPoints.profiles}defaultSpecialtySurgeon/${specialty}`, profile);
  }

  editProfile(profile: Profile): Observable<Profile> {
    return this.httpClient.put<Profile>(
      `${urlEndPoints.profiles}${profile._id}`,
      profile
    );
  }
  
  editVacationNeeds(profile: Profile , originalSpe: string): Observable<any> {
    return this.httpClient.put<any>(
      urlEndPoints.profiles +"updateVacations/" + profile._id +  "?originalSpecialty=" +originalSpe,
      profile
    );
  }

  editProfileWithSkills(profile: any): Observable<Profile> {
    return this.httpClient.put<Profile>(
      `${urlEndPoints.profiles}withSkills/${profile.profile._id}`,
      profile
    );
  }

  getProfileWeekAvailability(profileId, date): Observable<any> {
    return this.httpClient.get<any>(
      urlEndPoints.calendars + "byProfile/" + profileId + "/" + date
    );
  }

  getProfilesByPosition(
    position: string,
    page: number,
    limit: number,
    name: string,
    justTitular = false,
    allProfiles = false,
    noUrgences: boolean = false,
    seniorities: string = null,
    isNight: boolean = false,
    keepDefaultSpecialtySurgeons: boolean = true,
    profileOnTop: boolean = false
  ): Observable<any> {
    let endPoint;
    if (allProfiles) {
      endPoint =
        urlEndPoints.profiles +
        "by/" +
        position +
        "?" +
        (name !== "" ? "search=" + name : "") +
        (justTitular ? "&residency=Titulaire" : "") +
        (noUrgences ? "&noUrgences=true" : "") +
        (seniorities ? `&seniorities=${seniorities}` : "") +
        (isNight ? `&isNight=${isNight}` : '') +
        (profileOnTop ? `&profileOnTop=${profileOnTop}` : '') +
        `&keepDefaultSpecialtySurgeons=${keepDefaultSpecialtySurgeons}`;
    } else {
      endPoint =
        urlEndPoints.profiles +
        "by/" +
        position +
        "?page=" +
        (page ? page : 1) +
        "&limit=" +
        limit +
        (name !== "" ? "&search=" + name : "") +
        (justTitular ? "&residency=Titulaire" : "") +
        (noUrgences ? "&noUrgences=true" : "") +
        (seniorities ? `&seniorities=${seniorities}` : "") +
        (isNight ? `&isNight=${isNight}` : '') +
        (profileOnTop ? `&profileOnTop=${profileOnTop}` : '') +
        `&keepDefaultSpecialtySurgeons=${keepDefaultSpecialtySurgeons}`;
    }
    return this.httpClient.get<any>(endPoint);
  }

  getProfilesByPositionFromHospital(
    hospital: string,
    position: string,
    page: number,
    limit: number,
    name: string,
    justTitular = false,
    allProfiles = false,
    noUrgences: boolean = false,
    seniorities: string = null,
    isNight: boolean = false,
    isNoUserAssociate?: boolean
  ): Observable<any> {
    let endPoint;
    if (allProfiles) {
      endPoint =
        urlEndPoints.profiles +
        "by/" +
        position +
        "?hospital=" + hospital +
        (name !== "" ? "&search=" + name : "") +
        (justTitular ? "&residency=Titulaire" : "") +
        (noUrgences ? "&noUrgences=true" : "") +
        (seniorities ? `&seniorities=${seniorities}` : "") +
        (isNight ? `&isNight=${isNight}` : '') +
        (isNoUserAssociate? "&isNoUserAssociate=" + isNoUserAssociate : "");
    } else {
      endPoint =
        urlEndPoints.profiles +
        "by/" +
        position +
        "?hospital=" + hospital +
        "&page=" +
        (page ? page : 1) +
        "&limit=" +
        limit +
        (name !== "" ? "&search=" + name : "") +
        (justTitular ? "&residency=Titulaire" : "") +
        (noUrgences ? "&noUrgences=true" : "") +
        (seniorities ? `&seniorities=${seniorities}` : "") +
        (isNight ? `&isNight=${isNight}` : '') +
        (isNoUserAssociate? "&isNoUserAssociate=" + isNoUserAssociate : "");
    }
    return this.httpClient.get<any>(endPoint);
  }

  getProfilesByPositionResidency(
    position: string,
    page: number,
    limit: number,
    name: string,
    residency?: string,
    allNurses = false,
    noUrgences: boolean = false,
  ): Observable<any> {
    let endPoint;
    if (allNurses) {
      endPoint =
        urlEndPoints.profiles +
        "by/" +
        position +
        "?" +
        (name !== "" ? "search=" + name : "") +
        (residency ? "&residency=" + residency : "") +
        (noUrgences ? "&noUrgences=true" : "");
    } else {
      endPoint =
        urlEndPoints.profiles +
        "by/" +
        position +
        "?page=" +
        (page ? page : 1) +
        "&limit=" +
        limit +
        (name !== "" ? "&search=" + name : "") +
        (residency ? "&residency=" + residency : "") +
        (noUrgences ? "&noUrgences=true" : "");
    }
    return this.httpClient.get<any>(endPoint);
  }

  getProfilesByPositionResidencyFromHospital(
    hospital: string,
    position: string,
    page: number,
    limit: number,
    name: string,
    residency?: string,
    allNurses = false,
    noUrgences: boolean = false,
  ): Observable<any> {
    let endPoint = urlEndPoints.profiles +
      "by/" +
      position +
      "?" +
      "hospital=" + hospital +
      (name !== "" ? "&search=" + name : "") +
      (residency ? "&residency=" + residency : "") +
      (noUrgences ? "&noUrgences=true" : "");
  
    if (!allNurses) {
      endPoint += "&page=" + (page ? page : 1) + "&limit=" + limit;
    }
  
    return this.httpClient.get<any>(endPoint);
  }

  getProfilesWithUserByPositionResidencyFromHospital(
    hospital: string,
    position: string,
    page: number,
    limit: number,
    name: string,
    residency?: string,
    allNurses = false,
    noUrgences: boolean = false,
  ): Observable<any> {
    let endPoint = urlEndPoints.profiles +
    "with-user/" +
    "by/" +
    position +
    "?" +
    "hospital=" + hospital +
    (name !== "" ? "&search=" + name : "") +
    (residency ? "&residency=" + residency : "") +
    (noUrgences ? "&noUrgences=true" : "");
  
  if (!allNurses) {
    endPoint += "&page=" + (page ? page : 1) + "&limit=" + limit;
  }
    return this.httpClient.get<any>(endPoint);
  }

  deleteDefaultSpecialtySurgeons(
    specialtyId: string
  ): Observable<any> {
    return this.httpClient.delete<Profile>(
      urlEndPoints.profiles +
      "defaultSpecialtySurgeon/" +
      specialtyId
    )
  }

  getProfilesBySpecialty(
    position: string,
  ): Observable<any> {
    let endPoint =urlEndPoints.profiles + "ratedBySpecialty/"+position;
    return this.httpClient.get<any>(endPoint);
  }

  getProfilesBySpecialtyFromHospital(
    position: string,
    hospital: string
  ): Observable<any> {
    let endPoint = urlEndPoints.profiles + "ratedBySpecialty/"+ position + "?hospital=" + hospital;
    return this.httpClient.get<any>(endPoint);
  }

  getProfilesByDayAvailability(
    position: string,
    date: string,
    descriptions: string[],
    isNight,
    page, 
    pageSize, 
    searchValue
  ): Observable<Profile[]> {
    const body = this.getBody(descriptions, date, position, page, pageSize, searchValue, isNight,);
    return this.httpClient.post<Profile[]>(
      urlEndPoints.profiles + "hrsuiteFilter",
      body
    );
  }

  getBody(descriptions: string[], date: string, position: string,page, pageSize, searchValue, isNight = false): any {
    let body: any = { position, date, isNight, searchValue: searchValue? searchValue : "", page, pageSize };

    if (position == ANESTHETIST || position == NURSE) {
      if (this.isFilterAvaSelected(descriptions)) {
        body.descriptions = [descriptions[0]];
        descriptions.splice(0, 1);
      } else {
        body.descriptions = ["AVAILABLE", "ABSENT"];
      }

      if (this.isFilterStatusSelected(descriptions)) {
        body.residency = descriptions[0];
      }
    } else {
      body.descriptions = descriptions;
    }

    return body;
  }

  isFilterStatusSelected(descriptions: string[]): boolean {
    return (
      descriptions.includes("Titulaire") || descriptions.includes("Vacataire")
    );
  }

  isFilterAvaSelected(descriptions: string[]): boolean {
    return (
      descriptions.includes("AVAILABLE") || descriptions.includes("ABSENT")
    );
  }

  getAllProfiles(): Observable<Profile[]> {
    return this.httpClient.get<Profile[]>(urlEndPoints.profiles).pipe(
      map((profiles: any) => {
        return profiles.docs;
      })
    );
  }

  getOutOfRoomNurses(date: string): Observable<Profile[]> {
    return this.httpClient.get<Profile[]>(
      urlEndPoints.profiles + "outOfRoomNurses?date=" + date
    );
  }

  getOutOfRoomIades(date: string): Observable<Profile[]> {
    return this.httpClient.get<Profile[]>(
      urlEndPoints.profiles + "outOfRoomIades?date=" + date
    );
  }

  getAllAvailableNurses(date: string): Observable<allAvailableNurses[]> {
    return this.httpClient.get<allAvailableNurses[]>(
      urlEndPoints.profiles + "allAvailableNurses?date=" + date
    );
  }

  getOutOfRoomNursesFromTo(startDate: string,endDate: string): Observable<any[]> {
    return this.httpClient.get<Profile[]>(
      urlEndPoints.profiles + "outOfRoomNurses/interval?from=" + startDate+"&to="+ endDate
    );
  }
  getOutOfRoomIadesFromTo(startDate: string,endDate: string): Observable<any[]> {
    return this.httpClient.get<Profile[]>(
      urlEndPoints.profiles + "outOfRoomIades/interval?from=" + startDate+"&to="+ endDate
    );
  }

  getConsultationsFromTo(startDate: string,endDate: string): Observable<any[]> {
    return this.httpClient.get<Profile[]>(
      urlEndPoints.profiles + "getConsultations/interval?from=" + startDate+"&to="+ endDate
    );
  }

  getGardeFromTo(startDate: string,endDate: string): Observable<any[]> {
    return this.httpClient.get<Profile[]>(
      urlEndPoints.profiles + "getGardes/interval?from=" + startDate+"&to="+ endDate
    );
  }

  getProfileById(profileId): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.profiles + profileId);
  }

  getProfileHRSuiteRecap(
    profileId: string,
    startDate: string,
    endDate: string,
    hospital?: string
  ): Observable<any> {
    return this.httpClient.get<Profile>(
      urlEndPoints.ibodeGraphs +
        "hrsuiteRecap/" +
        profileId +
        "?fromDate=" +
        startDate +
        "&toDate=" +
        endDate +
        (hospital ? "&hospital=" + hospital : "")
    );
  }

  addProfile(profile: Profile): Observable<Profile> {
    return this.httpClient.post<Profile>(urlEndPoints.profiles, profile);
  }

  getAllProfilesIncludeDeleted(hospitalId?: string): Observable<Profile[]> {
    return this.httpClient.get<Profile[]>(
      urlEndPoints.profiles + "allProfiles" + (hospitalId ? "?hospital=" + hospitalId : "")
    );
  }

  getprofileImageByscrapping(surgeonId: string, hospitalId: string, ): Observable<any> {
    return this.httpClient.get<any>(
      urlEndPoints.profiles + "/ScrappingSurgeon/by/" + surgeonId + "/" + hospitalId
    );
  }

  softDeleteProfile(idProfile: string): Observable<Profile> {
    return this.httpClient.put<Profile>(
      urlEndPoints.profiles + "softDeleteProfile/" + idProfile,
      {}
    );
  }

  softRestoreProfile(idProfile: string): Observable<Profile> {
    return this.httpClient.put<Profile>(
      urlEndPoints.profiles + "softRestoreProfile/" + idProfile,
      {}
    );
  }

  hardRestoreProfile(idProfile: string): Observable<Profile> {
    return this.httpClient.put<Profile>(
      urlEndPoints.profiles + "hardRestoreProfile/" + idProfile,
      {}
    );
  }

  modifyProfile(profile: Profile): Observable<Profile> {
    return this.httpClient.put<Profile>(
      urlEndPoints.profiles + profile._id,
      profile
    );
  }

  deleteProfile(idProfile): Observable<Profile> {
    return this.httpClient.delete<Profile>(
      urlEndPoints.profiles + "/delete/" + idProfile
    );
  }

  getAverages(startDate: string, endDate: string, isGarde = false, hospitalId?: string): Observable<any> {
    return this.httpClient.get<any>(
      urlEndPoints.calendars +
        "effectifByDay?fromDate=" +
        startDate +
        "&toDate=" +
        endDate +
        (isGarde ? "&isGarde=true" : "") + 
        (hospitalId ? "&hospital=" + hospitalId : "")
    );
  }
  
  getEffectifByDaySkills(startDate: string, endDate: string, specialties: any): Observable<any> {
    let param = '';
    
    const body = {
      specialties: specialties
    };
  
    return this.httpClient.post<any>(
      urlEndPoints.calendars + `effectifPerDayFilterSpecialty/?fromDate=${startDate}&toDate=${endDate}`,
      body
    );
  }
  
  getNeeds(startDate: string, endDate: string): Observable<any> {
    return this.httpClient.get<any>(
      urlEndPoints.calendars +
        "effectifByDayDA?fromDate=" +
        startDate +
        "&toDate=" +
        endDate
    );
  }

  getProfilesSummary(
    position: string,
    fromDate: string,
    toDate: string
  ): Observable<any> {
    return this.httpClient.get<any>(
      urlEndPoints.profiles +
        `vacatairesSummary?position=${position}&fromDate=${fromDate}&toDate=${toDate}`
    );
  }

  getListWishes(profileId: string, startDate: string, endDate: string): Observable<any[]> {
    return this.httpClient.get<any[]>(urlEndPoints.realtimes + 'listwishes/' + profileId + `?fromDate=${startDate}&toDate=${endDate}`);
  }

  getWorkingTime(profileId: string, startDate: string, endDate: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.realtimes + 'all/' + profileId + `?fromDate=${startDate}&toDate=${endDate}`);
  }

  getSurgeonsBySpecialty(
    specialty: string,
    date: string
  ): Observable<any> {    
    return this.httpClient.get<any>(
      urlEndPoints.profiles +
      "surgeonsBySpecialty" +
      (date ? `?date=${date}` : ""));
  }

  getAllSurgeons(
    hospital: string,
    date: string
  ): Observable<any> {    
    return this.httpClient.get<any>(
      urlEndPoints.profiles +
      "surgeonsBySpecialty" +
      "?hospital=" + hospital +
      (date ? `&date=${date}` : ""));
  }

  getAllSurgeonsOnlyUser(
    hospital: string,
    date: string
  ): Observable<any> {    
    return this.httpClient.get<any>(
      urlEndPoints.profiles +
      "surgeonsOnlyUserBySpecialty" +
      "?hospital=" + hospital +
      (date ? `&date=${date}` : ""));
  }
}
