// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  sentrySDK: 'https://e6f3ae27482f4327913b0d32d4b8ccf8@o507706.ingest.sentry.io/5599182',
  apiEndPoint: 'https://smartop-back.azurewebsites.net/api/',
  socketEndPoint: 'https://smartop-back.azurewebsites.net/',
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
