import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { User } from '../models/user.model';
import { UserDal } from '../dal/user.dal';
import { StorageService } from 'src/app/core/services/storage.service';
import { CADRE_DE_BLOC, NURSE_TYPES, IADE, SURGEON, VACATAIRE } from '../const/glabals.const';
import { ANESTHETIST, ANESTHETIST_TYPES } from '../const/glabals.const';
import { Profile } from '../models/profile.model';
import { RESIDENCY, TITULAIRE } from './../const/glabals.const';
import { Subject } from 'rxjs';

@Injectable()
export class UserService {

  public changedHospitalsSelectedSubject : Subject<any> = new Subject<void>();
  public soloHospitalModeSubject : Subject<any> = new Subject<boolean>();
  public sideNav: Subject<void> = new Subject<void>();

  constructor(
    private userDal: UserDal,
    private storageService: StorageService) {

  }

  isIntern() {
    const profile = this.getCurrentUser().profile;
    return profile.seniority? profile.seniority == "interne" : false;
  }

  getAllUsers(): Observable<User[]> {
    return this.userDal.getAllUsers();
  }

  addUser(user: User): Observable<any> {
    return this.userDal.addUser(user);
  }

  modifyUser(user: User): Observable<User> {
    return this.userDal.modifyUser(user);
  }

  hardDeleteUser(user: User): Observable<User> {
    return this.userDal.hardDeleteUser(user);
  }

  archiveUser(idUser): Observable<User> {
    return this.userDal.archiveUser(idUser);
  }

  getUser(idUser): Observable<any> {
    return this.userDal.getUser(idUser);
  }

  getUserByProfileId(profileId): Observable<any> {
    return this.userDal.getUserByProfileId(profileId);
  }

  getUsersCounter(): Observable<number> {
    return this.userDal.getUsersCounter()
  }

  getCurrentUserHospitals() {
    let hospitals = this.storageService.getUser().profile.hospitals;

    hospitals = hospitals.filter((hospital) => {
        return !this.storageService.getUser().profile.desactivatedAt.includes(String(hospital._id));
    })
    return hospitals;
  }

  getSelectedHospitals() {
    return this.storageService.getData("selectedHospitalsId")
  }

  saveSelectedHospitals(hospitalsId: Array<string>) {
    this.storageService.saveData("selectedHospitalsId", hospitalsId);
  }

  isUserHasLowLevelAccess(): boolean {
    const levelAccess = this.storageService.getUser().levelOfAccess;
    return levelAccess <= 2
  }

  isCurrentUserHasMultipleHospitals(): boolean {
    // const hospitals = this.storageService.getUser().profile.hospitals;
    // return hospitals.length > 1;

    const hospitalsNumber = this.storageService.getUser().profile.hospitals.length;
    const desactivatedHospitalsNumber = this.storageService.getUser().profile.desactivatedAt.length ? this.storageService.getUser().profile.desactivatedAt.length : 0;
    const activeUserHospitalsNumber = hospitalsNumber - desactivatedHospitalsNumber;

    return activeUserHospitalsNumber > 1;
  }

  // isCurrentUserHasMultipleActiveHospitals(): boolean {
  //   const hospitalsNumber = this.storageService.getUser().profile.hospitals.length;
  //   const desactivatedHospitalsNumber = this.storageService.getUser().profile.desactivatedAt.length;
  //   const activeProfileHospitalsNumber = hospitalsNumber - desactivatedHospitalsNumber;

  //   return activeProfileHospitalsNumber > 1;
  // }

  isProfileHasMultipleHospitals(profile: Profile): boolean {
    // return profile.hospitals.length > 1;
    const hospitalsNumber = profile.hospitals.length;
    const desactivatedHospitalsNumber = profile.desactivatedAt.length;
    const activeProfileHospitalsNumber = hospitalsNumber - desactivatedHospitalsNumber;

    return activeProfileHospitalsNumber > 1;
  }

  isCurrentUserHasMultipleHospitalsSelected(): boolean {
    const selectedHospitals = this.storageService.getData("selectedHospitalsId");
    return selectedHospitals.length > 1;
  }

  isUserHasSPRO(): boolean {
    return this.isUserHasLowLevelAccess() || this.isIadRes()
  }

  getCurrentUser(): User {
    return this.storageService.getUser()
  }

  isAnesWithLowLevelAccess() {
    const user = this.storageService.getUser();
    if (user)
      return (user.levelOfAccess <= 2 && user.profile.position == ANESTHETIST)
  }

  isAnesthTitulaireWith2LevelAccess() {
    const user = this.storageService.getUser();
    const profile = user.profile
    if (user)
      return (user.levelOfAccess == 2 && profile.position == ANESTHETIST && profile.residency == TITULAIRE)
  }

  isAnesth() {
    const user = this.storageService.getUser();
    if (user)
      return this.isProfileAnesth(user.profile)
  }

  isProfileAnesth(profile: Profile) {
    if (profile)
      return ANESTHETIST_TYPES.includes(profile.position)
  }

  isAnestgWithHighLevel() {
    const user = this.storageService.getUser();
    if (user)
      return (user.levelOfAccess >= 3 && user.profile.position == ANESTHETIST)
  }

  isInfirmiere(): boolean {
    const user = this.storageService.getUser();
    if (user)
      return this.isProfileInfirmiere(user.profile)
  }

  isProfileInfirmiere(profile: Profile) {
    if (profile)
      return NURSE_TYPES.includes(profile.position)
  }

  isInfWithLowLevelAccess(): boolean {
    const user = this.storageService.getUser();
    if (user)
      return (user.levelOfAccess <= 2 && this.isInfirmiere())
  }

  isCadreBloc(): boolean {
    const currentUser = this.getCurrentUser()
    let pos
    if (currentUser)
      pos = currentUser.profile.position
    return pos === CADRE_DE_BLOC && currentUser.levelOfAccess >= 3
  }

  isSurgeon(): boolean {
    const currentUser = this.getCurrentUser()
    let pos
    if (currentUser)
      pos = currentUser.profile.position
    return pos === SURGEON && currentUser.levelOfAccess == 1
  }

  isCadreBlocWithMediumLevel(): boolean {
    const currentUser = this.getCurrentUser()
    let pos
    if (currentUser)
      pos = currentUser.profile.position
    return pos === CADRE_DE_BLOC && (currentUser.levelOfAccess == 3 || currentUser.levelOfAccess == 4)
  }

  isCadreBlocWithHighLevel(): boolean {
    const currentUser = this.getCurrentUser()
    let pos
    if (currentUser)
      pos = currentUser.profile.position
    return pos === CADRE_DE_BLOC && (currentUser.levelOfAccess == 5)
  }

  isUserWithHighLevel() {
    const currentUser = this.getCurrentUser()
    return currentUser.levelOfAccess === 5
  }

  isProfileCadreDeBloc(profile: Profile) {
    if (profile)
      return profile.position === CADRE_DE_BLOC
  }

  isIad(profile: Profile = this.getCurrentUser().profile): boolean {
    if (profile)
      return IADE.includes(profile.position)
  }

  isIadRes(): boolean {
    const user = this.storageService.getUser();
    if (user)
      return this.isIad(user.profile) && user.levelOfAccess >= 3
  }

  isVacataire(): boolean {
    const residency = this.storageService.getUser().profile.residency;
    return residency == RESIDENCY && this.isInfirmiere()
  }

  isSurgeonProfile(profile: Profile) {
    if (profile)
      return profile.position === SURGEON
  }

  getLevelAccess() {
    return this.storageService.getUser().levelOfAccess;
  }

  updateLevelOfAccess(levelAccess: number, userId: string): Observable<number> {
    return this.userDal.updateLevelOfAccess(levelAccess, userId);
  }

  isVacataire2() {
    const user = this.getCurrentUser()
    const profile = user.profile
    return user.levelOfAccess <= 2 && profile.residency == VACATAIRE
  }


  isAnesthResponsable() {
    const user = this.getCurrentUser();
    const profile = user.profile;
    const anesthPositions = ['anesthésiste', 'iade'];
    return user.levelOfAccess >= 3 && anesthPositions.includes(profile.position.toLowerCase());
  }

  isInternResponsible() {
    const user = this.getCurrentUser();

    if (user) {
      return user.levelOfAccess >= 3 && user.profile.position == ANESTHETIST && user.isInternResponsible;
    }
  }
}
