import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatCheckboxChange} from '@angular/material';
import {ErrorService} from '../../services/error.service';
import {NURSE, ANESTHETIST} from '../../const/glabals.const';
import { MessagingService } from '../../services/messaging.service';
import { Subscription } from 'rxjs';
import { Profile } from '../../models/profile.model';
import { OnDestroy } from '@angular/core';
import { escapeRegExp } from '../../utils/cross-functions';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HospitalService } from '../../services/hospital.service';

@Component({
  selector: 'app-channel-popup',
  templateUrl: './add-channel-popup.component.html',
  styleUrls: ['./add-channel-popup.component.scss']
})
export class AddChannelPopupComponent implements OnInit, OnDestroy {

  profiles: any[];
  ibodeProfiles: any[];
  anesthProfiles: any[];
  allProfiles : any[] ;
  submitButton : string;

  public profileListOptions = ['IBODE / IDE / AS', 'ANESTH / IADE', 'Tous'];
  public isLoading: boolean[] = [true, true];

  isAllSelected: boolean;
  isAllIbodeSelected: boolean;
  isAllAnesthSelected: boolean;

  disableName: boolean;

  isIbode: boolean;
  isAnesth: boolean;
  isAll: boolean;
  
  isAnesthLoading: boolean = true;
  isIbodeLoading: boolean = true;
  isAllLoading: boolean = true;

  private messagingAnesthProfilesSubscription : Subscription;
  private messagingIbodeProfilesSubscription : Subscription;
  private messagingAllProfilesSubscription : Subscription;

  public name : string = "";
  public wasOneToOne : boolean;
  public profileListName = "IBODE / IDE / AS";
  public searchValue: string;

  private checkDuplicateOneToOneSub : Subscription;

  public seniorities = [
    {
      label: "Sénior",
      value: "Senior",
      isChecked: true,
      isDisabled: false,
    },
    {
      label: "Junior",
      value: "Junior",
      isChecked: false,
      isDisabled: false,
    },
    {
      label: "Interne",
      value: "Interne",
      isChecked: false,
      isDisabled: false,
    }
  ]
  public formGroup: FormGroup;
  public haveSeniority:boolean = false;
  public selectedSeniorities : string[];
  public filteredProfiles : Profile[];
  public doesHospitalHaveAnesthDetailsOption: boolean;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddChannelPopupComponent>,
    private errorService: ErrorService,
    private messagingService : MessagingService,
    private hospitalService: HospitalService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.doesHospitalHaveAnesthDetailsOption = this.hospitalService.doesHospitalHaveAnesthDetailsOption();
  }

  ngOnInit() {
    this.makeForm();
    this.onSeniorityChange();
    this.name = this.data.name;
    if (this.data.selectedProfiles.length === 2)
      this.wasOneToOne = true;
    this.submitButton = this.data.mode === 'add' ? "Créer le canal" : "Mettre à jour le canal";
    this.getMessagingIbodeProfiles();
    this.getMessagingAnesthProfiles();
  }

  get loadingFinished(): boolean {
    return this.isLoading.every(a => !a);
  }

  getMessagingIbodeProfiles() {
    this.messagingIbodeProfilesSubscription = this.messagingService.getProfilesByPosition(NURSE,'',false,false).subscribe(res => {

      
      this.ibodeProfiles = res;
      this.isIbode = true;
      this.profiles = this.ibodeProfiles;
      this.formatProfiles(this.ibodeProfiles);
      this.isIbodeLoading = false;
      this.isLoading[0] = false;
      if (this.data.mode === 'edit' && this.loadingFinished) {
        this.changeProfile();
      }
    }, error => {
      this.isIbodeLoading = false;
      this.isLoading[0] = false;
      this.errorService.handleError(error)
    });
  }

  getMessagingAnesthProfiles() {
    this.messagingAnesthProfilesSubscription = this.messagingService.getProfilesByPosition(ANESTHETIST,'',false,false).subscribe(res => {
      this.anesthProfiles = res;
      this.formatProfiles(this.anesthProfiles);
      this.isAnesthLoading = false;
      this.isLoading[1] = false;
      if (this.data.mode === 'edit' && this.loadingFinished) {
        this.changeProfile();
      }
    }, error => {
      this.isLoading[1] = false;
      this.errorService.handleError(error)
    });
  }

  doFilter(value){
    value = escapeRegExp(value);
    if (this.profileListName === "IBODE / IDE / AS") {
      this.profiles = this.ibodeProfiles;
    }
    if (this.profileListName === "ANESTH / IADE") {
      this.profiles = this.anesthProfiles;
    }
    if (this.profileListName === "Tous") {
      this.profiles = [...this.ibodeProfiles , ...this.anesthProfiles];
    }
    this.profiles = [...this.profiles].filter(profile => {
      var re = new RegExp(value, 'gi');
      return profile.firstName.match(re) != null || profile.lastName.match(re) != null 
    })
    this.filteredProfiles = [...this.profiles].filter(profile => {
      var re = new RegExp(value, 'gi');
      return profile.firstName.match(re) != null || profile.lastName.match(re) != null 
    })
  }

  formatProfiles(profiles : Profile[]) : void{
    const selProfiles = this.data.selectedProfiles;
    profiles.forEach(el => {
      const index = selProfiles.findIndex(selProf => selProf._id == el._id);
      if (index != -1) {
        el.checked = true
      }
    });
  }

  capitalize(s : string) : string{
    let text = s.toLowerCase();
    return text.charAt(0).toUpperCase() + text.slice(1)
  }

  getProfileListType(selectedOption: string){
    this.profileListName = selectedOption;
    if (this.profileListName === "IBODE / IDE / AS") {
      this.isIbode = true;
      this.isAll = false;
      this.isAnesth = false;
      this.profiles = this.ibodeProfiles;
    }
    if (this.profileListName === "ANESTH / IADE") {
      this.isIbode = false;
      this.isAll = false;
      this.isAnesth = true;
      this.profiles = this.anesthProfiles;
      this.filteredProfiles = this.profiles;

    }
    if (this.profileListName === "Tous") {
      this.isIbode = false;
      this.isAll = true;
      this.isAnesth = false;
      this.profiles = [...this.ibodeProfiles , ...this.anesthProfiles];
    }
    this.doFilter(this.searchValue)
  }

  selectAllChange(checkbox: MatCheckboxChange) {
    if (this.profileListName === "IBODE / IDE / AS") {
      this.ibodeProfiles.forEach(profile => profile.checked = checkbox.checked);
    }
    if (this.profileListName === "ANESTH / IADE") {
      this.filteredProfiles.forEach(profile => profile.checked = checkbox.checked);
    }
    if (this.profileListName === "Tous") {
      [...this.ibodeProfiles, ...this.anesthProfiles].forEach(profile => profile.checked = checkbox.checked);
    }
    this.changeProfile();
  }

  save () {
    let ibodeProfiles = this.ibodeProfiles.filter(el => el.checked);
    let anesthProfiles = this.anesthProfiles.filter(el => el.checked);
    let profiles = [...ibodeProfiles , ...anesthProfiles];

    if (profiles.length === 1 ) {
      this.name = "Conversation 1 to 1";
    }
    const body = {
      profiles: profiles,
      name : this.name
    };

    let newChannel = {
      new : true ,
      body : body
    };

    if (profiles.length === 1) {
        this.checkDuplicateOneToOne(newChannel)
    } else {
      this.closeDialog(newChannel)
    }
  }

  checkDuplicateOneToOne(channel : any) {
    const profileId = channel.body.profiles[0]._id;
    this.checkDuplicateOneToOneSub = this.messagingService.checkExistingOneToOneChannel(profileId).subscribe(res => {
     if (res.length > 0) {
      channel = {
        new:false ,
        body : res[0]
      }
      this.closeDialog(channel)
     } else {
       channel.body.oneToOne =  channel.body.profiles[0];
      this.closeDialog(channel)
     }
    }, error => {
      this.errorService.handleError(error)
    });
  }

  closeDialog(channel : any){
    this.dialogRef.close(channel);
  }

  changeProfile(firstLoading?:boolean){
    let ibodeProfiles = [];
    let anesthProfiles = [];

    if (this.ibodeProfiles) {
      ibodeProfiles = this.ibodeProfiles.filter(el => el.checked);
    }

    if (this.anesthProfiles) {
      anesthProfiles = this.anesthProfiles.filter(el => el.checked);
    }
    
    let profiles = [...ibodeProfiles , ...anesthProfiles];

      if(profiles.length === 1) {
        this.disableName = true;
      } else {
        if (this.wasOneToOne) {
          this.name = "";
        }
        this.disableName = false;
      }
  }

  getSelectedIDS(profiles: any[]):string[] {
    const selectProfiles = profiles.filter(el => el.checked);
    return selectProfiles.map(el => el._id)
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(){
    if (this.messagingAnesthProfilesSubscription) {
      this.messagingAnesthProfilesSubscription.unsubscribe();
    }

    if (this.messagingIbodeProfilesSubscription) {
      this.messagingIbodeProfilesSubscription.unsubscribe();
    }

    if (this.messagingAllProfilesSubscription) {
      this.messagingAllProfilesSubscription.unsubscribe();
    }

    if (this.checkDuplicateOneToOneSub) {
      this.checkDuplicateOneToOneSub.unsubscribe();
    }
  }

  makeForm() {
    this.formGroup = this.formBuilder.group({
    seniority: [" ", Validators.required]
    });
  }

  onSeniorityChange() {
    this.formGroup.get("seniority").valueChanges.subscribe((val) => {
      this.haveSeniority =  val.length > 0
      this.selectedSeniorities=val;
      this.filterProfilesBySeniority();
    }); 
  }

  filterProfilesBySeniority() {
    this.selectedSeniorities.length > 0 ? this.filteredProfiles = this.profiles.filter(profile => this.selectedSeniorities.includes(profile.seniority+"")) : this.filteredProfiles=this.profiles;
  }

}
