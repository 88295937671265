import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MIN_PASSWORD_LENGTH, PASSWORD_STANDARDS, VALID_PASSWORD_LENGTH } from '../../const/glabals.const';
import {
    isStringHasLowwerLetter,
    isStringHasNumber,
    isStringHasSpecialLetter,
    isStringHasUpperLetter,
} from '../../utils/string-operations.functions';

@Component({
    selector: 'app-password-checker',
    templateUrl: './password-checker.component.html',
    styleUrls: ['./password-checker.component.scss'],
})
export class PasswordChecker implements OnInit {
    @Input('password') password: string;
    @Input('confirmPassword') confirmPassword: string;
    @Output() passwordFormEmetter = new EventEmitter<boolean>();

    get passwordStandards(): string[] {
        return PASSWORD_STANDARDS;
    }

    get isPasswordIdentical() : boolean {
        const enteredPassword: string = this.password;
        if (!enteredPassword.length) return false;
        return enteredPassword === this.confirmPassword
    }

    get isFormValid(): boolean {
        const enteredPassword: string = this.password;
        if (!enteredPassword.length) return false;
        return (
            enteredPassword.length >= VALID_PASSWORD_LENGTH &&
            this.isPasswordIdentical
        );
    }

    constructor() {}

    ngOnInit() {}

    isStandardChecked(passStandard) {
        const enteredPassword: string = this.password;
        this.passwordFormEmetter.emit(this.isFormValid);
        if (!enteredPassword.length) return false;
        switch (passStandard) {
            case this.passwordStandards[0]:
                return enteredPassword.length >= MIN_PASSWORD_LENGTH;
            case this.passwordStandards[1]:
                return isStringHasLowwerLetter(enteredPassword);
            case this.passwordStandards[2]:
                return isStringHasUpperLetter(enteredPassword);
            case this.passwordStandards[3]:
                return isStringHasNumber(enteredPassword);
            case this.passwordStandards[4]:
                return isStringHasSpecialLetter(enteredPassword);
            case this.passwordStandards[5]:
                return enteredPassword === this.confirmPassword;
        }
    }
}
