import { SwitchService } from './../../services/switch.service';
import { UtilisService } from './../../services/utilis.service';
import { ANESTHETIST, APPROVED, PROFILES_ON_PROGRAMS, PROFILES_ON_GARDE, ONHOLD } from './../../const/glabals.const';
import { UserService } from 'src/app/shared/services/user.service';
import { CalendarSwitch } from './../../models/calendarSwitch';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { isNurse, Profile } from '../../models/profile.model';
import { ProgramSwitch } from '../../models/programSwitch';
import { ToastService } from '../../services/toast.service';
import { getFirstHospitalSelectedData } from '../../utils/cross-functions';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-exchange-popup',
  templateUrl: './exchange-popup.component.html',
  styleUrls: ['./exchange-popup.component.scss']
})
export class ExchangePopupComponent implements OnInit {
  title: string;
  date: any;
  profile2: Profile
  seniority: any;
  searchType: string;
  calendar1: any;
  calendar2: any;
  comment:string;
  calendarType: string;
  public switchDay: boolean = true
  public isSending: boolean;
  public showComment: boolean = true
  public exchangeText :string = "Demander l'échange"

  public type: string;

  isInternResponsible : boolean;
  public searchProfileParam: any = ANESTHETIST

  private createCalendarSwitchSubscription: Subscription;
  private switchGardeExchangeSubscription: Subscription;

  constructor(
    private toastService: ToastService,
    private userService: UserService,
    private SwitchService: SwitchService,
    private UtilisService: UtilisService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ExchangePopupComponent>,
  ) { 
    this.dialogRef.backdropClick().subscribe(() => {
      this.close();
    })
    this.isInternResponsible = this.userService.isInternResponsible();
  }

  ngOnInit(): void {
    this.setInfo()

    if ((this.userService.getCurrentUser().profile.position == ANESTHETIST && this.userService.getCurrentUser().levelOfAccess >= 3)
          || this.userService.getCurrentUser().levelOfAccess >= 4) {
      this.showComment = false
      this.exchangeText = "Valider l'échange"
    }
  }

  setInfo() {
    this.type = this.data.exchangeType
    this.calendarType = this.data.programType
    if (this.data.exchangeType == 'garde') {
      if (this.data.profile && isNurse(this.data.profile))
        this.title = "Demande d\'échange d\'astreinte"
      else
        this.title = 'Demande d\'échange de garde'
      this.searchType = PROFILES_ON_GARDE
      this.calendar1 = this.data.calendar
    } else if (this.data.exchangeType == 'programme') {
      this.title = "Demande d'échange d'extraclinique"
      this.date = moment(this.data.date).format("DD/MM/YYYY")
      this.setSeniority();
      this.searchType = PROFILES_ON_PROGRAMS
    }
  }

  isGardeExchange() {
    return this.data.exchangeType == 'garde'
  }

  close() {
    this.dialogRef.close()
  }

  setSeniority(){
    if(this.data.profile.seniority === "Senior"){
        this.seniority = "senior";
    }else if(this.data.profile.seniority === "Interne"){
      this.seniority = "intern";
    }
  }



  loadProfile(event) {
    if (this.isGardeExchange()) {
      this.profile2 = event
      this.data.calendar2 = event
    } else {
      this.profile2 = event
      this.data.program2 = event
    }
  }

  save() {
    this.isSending = true;
    this.saveCalendarSwitch()
  }

  saveCalendarSwitch() {
    const calendarSwitch = this.generateCalendarSwitch()
    if ((this.userService.getCurrentUser().profile.position == ANESTHETIST && this.userService.getCurrentUser().levelOfAccess >= 3)
    || this.userService.getCurrentUser().levelOfAccess >= 4) {
      this.switchGardeExchange(calendarSwitch)
    } else {
      this.createCalendarSwitchRequest(calendarSwitch)
    }
  }

  createCalendarSwitchRequest(calendarSwitch) {
    calendarSwitch.type = this.calendarType
    if(this.createCalendarSwitchSubscription) this.createCalendarSwitchSubscription.unsubscribe();
    this.createCalendarSwitchSubscription = this.SwitchService.createCalendarRequest(calendarSwitch)
      .subscribe(res => {
        this.isSending = false;
        this.displayToast()
        this.dialogRef.close()
    })
  }

  saveComment() {
    
  }

  switchGardeExchange(calendarSwitch) {
    calendarSwitch.type = this.calendarType
    calendarSwitch.state = APPROVED;
    if(this.switchGardeExchangeSubscription) this.switchGardeExchangeSubscription.unsubscribe();
    this.switchGardeExchangeSubscription = this.SwitchService.createCalendarRequest(calendarSwitch).subscribe(res => {
      this.isSending = false;
      this.dialogRef.close(this.profile2)
      this.displayToast()
    })
    /*1this.SwitchService.updateRequest(calendarSwitch, "garde", this.calendarType).subscribe(res => {
      this.dialogRef.close(this.profile2)
      this.displayToast()
    })*/
  }

  displayToast() {
    this.toastService.infoToast('L\'échange a été prise en compte');
  }

  // NOT USED
  // saveProgramSwitch() {
  //   const programSwitch = this.generateProgramSwitch()
  //   if ( (this.userService.isUserHasLowLevelAccess() || (this.isInternResponsible && this.data.profile._id === this.userService.getCurrentUser().profile._id)) && (!(this.userService.getCurrentUser().levelOfAccess === 2 && this.userService.isIntern()))) {
  //     this.createProgramExchangeRequet(programSwitch)
  //   } else {
  //     this.switchPrograms(programSwitch)
  //   }
  // }

  // NOT USED
  // switchPrograms(programSwitch) {
  //   programSwitch.state = APPROVED
  //   this.SwitchService.updateRequest(programSwitch, "program", this.calendarType).subscribe(res => {
  //     this.dialogRef.close(this.profile2)
  //     this.displayToast()
  //   })
  // }

  // NOT USED
  // createProgramExchangeRequet(programsSwitch) {
  //   this.SwitchService.exchangePrograms(programsSwitch).subscribe(res => {
  //     this.displayToast()
  //     this.dialogRef.close()
  //   })
  // }

  // NOT USED
  // generateProgramSwitch(): ProgramSwitch {
  //   return {
  //     program1: this.data.calendar._id,
  //     program2: this.data.calendar2._id,
  //     seniority: this.seniority ,
  //     updatedBy: this.userService.getCurrentUser()._id,
  //     ...this.getCommonData()
  //   }
  // }

  generateCalendarSwitch(): CalendarSwitch {
    return {
      calendar1: this.data.calendar._id,
      calendar2: this.data.program2 ? this.data.program2._id : this.data.calendar2._id,
      type: this.data.exchangeType,
      switchDay: this.switchDay,
      ...this.getCommonData()
    }
  }

  getCommonData() {
    const user = this.userService.getCurrentUser()
    const hospital = getFirstHospitalSelectedData(this.userService.getCurrentUser(), this.userService.getSelectedHospitals())
    return {
      date: this.isGardeExchange() ? this.data.date.substring(0, 10) : this.data.date,
      hospital: hospital._id,
      state: ONHOLD,
      requestDescription: this.comment
    }
  }

  getTodayDate() {
    return this.UtilisService.getTodayDate()
  }

  ngOnDestroy() {
    if (this.createCalendarSwitchSubscription)
      this.createCalendarSwitchSubscription.unsubscribe()

    if (this.switchGardeExchangeSubscription)
      this.switchGardeExchangeSubscription.unsubscribe();
  }
}
