import {Component, Input, OnInit, Output, EventEmitter, SimpleChanges} from '@angular/core';
import {FormControl} from '@angular/forms';
import {DateAdapter} from '@angular/material';
import {DatePickerMondayFirst} from '../../utils/date-picker-monday-first';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [{ provide: DateAdapter, useClass: DatePickerMondayFirst }]
})
export class DatePickerComponent implements OnInit {
  @Input() formCtrl: FormControl;
  @Input() fromMon2Fri;
  @Input() day;
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() isOnlyMonday = false;
  @Input() isOnlyFriday = false;
  @Input() isTodayMinDate = false;
  @Input() isTwoWeeksMaxDate = false;
  @Input() maxDate: Date;
  @Input() isNextWeekMinDate = false;
  @Input() minDate : Date;

  @Output() dateChanged = new EventEmitter();

  public todayDate = new Date();
  public nextWeekDate = new Date();
  public twoWeeksDate = new Date();

  constructor() {}

  ngOnInit() {
    if (this.isNextWeekMinDate) {
      this.nextWeekDate.setDate(this.nextWeekDate.getDate() + 7);
    }
    if (this.isTwoWeeksMaxDate) {
      this.populateTwoWeeksDate();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.minDate && changes.minDate.currentValue) {
      if (changes.minDate.currentValue != changes.minDate.previousValue && changes.minDate.currentValue != this.todayDate) {
        this.nextWeekDate = changes.minDate.currentValue;  
      }
    }
    if (this.isTwoWeeksMaxDate) {
      this.populateTwoWeeksDate();
    }
  }

  onlyMon2Fri = (d: Date): boolean => {
    const day = d.getDay();
    return day >= 1 && day <= 5;
  };

  onlyMonday = (d: Date): boolean => {
    const day = d.getDay();
    return day === 1;
  };

  onlyFriday = (d: Date): boolean => {
    const day = d.getDay();
    // if (this.startDate) {
    //   return day === 0 && this.startDate.getTime() < d.getTime();
    // } else {
    return day === 0;
    // }
  };

  // Refactor this code later
  populateTwoWeeksDate() {
    let minDateTmp : Date = new Date();

    if (this.minDate) {
      minDateTmp = new Date(this.minDate);
    } else if (this.todayDate) {
      minDateTmp = new Date(this.todayDate);
    } else if (this.nextWeekDate) {
      minDateTmp = new Date(this.nextWeekDate);
    }

    this.twoWeeksDate.setDate(this.minDate.getDate() + 14);
    if (this.twoWeeksDate.getDate() < this.minDate.getDate()) {
      this.twoWeeksDate.setMonth(this.minDate.getMonth() + 1);
    } else {
      this.twoWeeksDate.setMonth(this.minDate.getMonth());
    }

    if (this.twoWeeksDate.getMonth() < this.minDate.getMonth()) {
      this.twoWeeksDate.setFullYear(this.minDate.getFullYear() + 1);
    } else {
      this.twoWeeksDate.setFullYear(this.minDate.getFullYear());
    }
  }

  changeDay(event) {
    this.dateChanged.emit(event);
  }

}
