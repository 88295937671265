import {Pipe, PipeTransform, ViewEncapsulation} from '@angular/core';
import {DatePipe} from '@angular/common';

@Pipe({
  name: 'formatMessageConfirmationPopup',

})
export class FormatMessageConfirmationPopupPipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {
  }


  transform(program: any, type: string, profileId?: string): any {
    let roomMessage1 = ""

    if (type == "surgeon") {
      return roomMessage1 + ` le ${this.datePipe.transform(program.date.substring(0, 10), 'dd/MM/yyyy')} de
      ${program.startTime.substring(11, 16)}  à ${program.endTime.substring(11, 16)}`

    } else if (type == "room") {
      return `-De ${program.startTime.substring(11, 16)}  à ${program.endTime.substring(11, 16)}`

    } else {
      let ret = '';

      for (let i = 0; program.data[i]; i++) {
        for (let j = 0; program.data[i].programs[j]; j++) {
          let roomMessage2 = ""
          const role = this.getRoleName(program.data[i].programs[j], profileId);
          if (!program.data[i].room || !program.data[i].room.roomNumber) {
            if (program.data[i].type.toLowerCase() == "consultation" && program.data[i].specialty.length) {
              roomMessage2 = `en consultation (`
              let notfirst = false
              program.data[i].specialty.forEach(element => {
                if (element.name.toLowerCase() != 'extraclinique') {
                  roomMessage2 +=  ((notfirst? ", ": '') + (element.name)) 
                  notfirst = true
                }
                else {
                  ret += `<p>-Le ${this.datePipe.transform(program.date.substring(0, 10), 'dd/MM/yyyy')} hors salle (extraclinique). </p>`
                }
              })
              roomMessage2 += ').'
            }
            else
              roomMessage2 = `hors salle (extraclinique).`
          }
          else {
            const specialty = program.data[i].programs[j].surgeon.specialties.find((specialty) => String(specialty.hospital) === (program.data[i].room.hospital._id ? String(program.data[i].room.hospital._id) : String(program.data[i].room.hospital)))
            roomMessage2 = `en salle ${program.data[i].room.roomNumber} avec ${program.data[i].programs[j].surgeon.firstName} ${program.data[i].programs[j].surgeon.lastName && program.data[i].programs[j].surgeon.lastName.charAt(0).toUpperCase() + program.data[i].programs[j].surgeon.lastName.slice(1).toLowerCase()} en ${ specialty.name }${ role ? ` en tant que ${role}.`: '.'}`
          }

            ret += `<p>-Le ${this.datePipe.transform(program.date.substring(0, 10), 'dd/MM/yyyy')} ` + roomMessage2 + ` </p>`;
        }
      }
      return ret;
    }
  }

  getRoleName(program, profileId: string): string {
    if (program.nurses) {
      const nurse = program.nurses.find((nurse) => String(nurse.profile._id) === String(profileId))
      if (nurse && nurse.role) {
        return nurse.role.name
      } else {
        return ""
      }
    }
  }

}
