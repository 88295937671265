import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';

import {urlEndPoints} from '../config/end-points';
// import {Hospital} from '../models/hospital.model';
import {Option} from '../models/option.model';

@Injectable()
export class OptionDal {

  constructor(private httpClient: HttpClient) {
  }

  updateOptionsToHospital(optionsIds: string[], hospitalId: string): Observable<any> {
    const url = `${urlEndPoints.options}${hospitalId}/updateOptions` ;
    return this.httpClient.post(url, {optionsIds});
  }

  removeOptionToHospital(hospitalId: string, optionName: string, softwareName?: string): Observable<any> {
    const url = `${urlEndPoints.options}${hospitalId}/removeOption/${optionName}/${softwareName}`;
    return this.httpClient.delete(url);
  }

  getAllOptions(): Observable<Option[]> {
    return this.httpClient.get<Option[]>(urlEndPoints.options);
  }

  getOptionsWithSoftwareList(): Observable<any> {
    return this.httpClient.get<Option[]>(urlEndPoints.options + '/withSoftwareList');
  }
}
