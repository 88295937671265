import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-image-picker',
  templateUrl: './image-picker.component.html',
  styleUrls: ['./image-picker.component.scss']
})
export class ImagePickerComponent implements OnInit {
  @Input() public text: string = 'Ajouter une photo';
  @Input() public imageSource: string;

  @Output() onFileChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  onFileChangeHandler(event): void {
    this.onFileChange.emit(event);
  }
}
