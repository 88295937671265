import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import {urlEndPoints} from '../config/end-points';

@Injectable()

export class RecurrenceDal {

  constructor(private httpClient: HttpClient) {
  }

  getProfileRecurrences(profileId: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.recurrences + profileId);
  }

  editProfileRecurrences(profileId: string, rules: any): Observable<any> {
    return this.httpClient.post<any>(urlEndPoints.recurrences + profileId, rules);
  }
}
