import {Component, Inject, OnInit, ChangeDetectorRef} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatCheckboxChange} from '@angular/material';
import { OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { RoomService } from 'src/app/shared/services/room.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ErrorService } from 'src/app/shared/services/error.service';
import { Room } from 'src/app/shared/models/room.model';

@Component({
  selector: 'app-select-rooms-popup',
  templateUrl: './select-rooms-popup.component.html',
  styleUrls: ['./select-rooms-popup.component.scss']
})
export class SelectRoomsPopupComponent implements OnInit, OnDestroy {

  public title: string;
  // public description: string;
  public type: string = 'close'; // Available default, close
  public confirmBtnTitle: string;
  public date: Date;
  private getAllRoomsSubscription: Subscription;
  private roomsInfo: any[] = [];
  public room: Room;
  public openType: string;
  public roomIsSelected: boolean = false;
  public deleteIfEmpty: boolean = false;
  public selectedRooms: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<SelectRoomsPopupComponent>,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    this.title = data.title;
    // this.description = data.description;
    this.confirmBtnTitle = data.confirmBtnTitle;
    this.roomsInfo = data.roomsInfo;
    this.date = data.date;
    this.deleteIfEmpty = this.lessThanTenDays() ? true : false;
    this.selectedRooms = this.roomsInfo.filter((roomInfo) => roomInfo.isDifferentSurgeonsBetweenSurgeonOpeningsAndDoctorAgendas).map(roomInfo => roomInfo.room._id);
  }

  ngOnInit() {
  }
  
  toggleDeleteIfEmpty(event: MatCheckboxChange): void {
    if (event.checked) {
      this.deleteIfEmpty = true;
    } else {
      this.deleteIfEmpty = false;

    }
    this.changeDetectorRef.detectChanges();
  }

  toggleAllRoomSelection(event: MatCheckboxChange): void {
    if (event.checked) {
        this.selectedRooms = this.roomsInfo.filter((roomInfo) => !roomInfo.doesDoctorAgendasHaveConflicts).map(roomInfo => roomInfo.room._id);
    } else {
      this.selectedRooms = [];
    }
    this.changeDetectorRef.detectChanges();
  }

  toggleRoomSelection(room: Room, event: MatCheckboxChange): void {
    if (event.checked) {
    this.selectedRooms.push(room._id);
    } else {
      this.selectedRooms = this.selectedRooms.filter(selectedRoomId => selectedRoomId !== room._id);
    }
  }

  lessThanTenDays() {
    const jobDate = new Date(this.date);
    const currentDate = new Date();
    const timeDifference: number = jobDate.getTime() - currentDate.getTime();
    const daysDifference = timeDifference / (1000 * 3600 * 24);
  
    return daysDifference < 10;
  }


  confirmRoom() {
    this.dialogRef.close({roomsToChange: this.selectedRooms, deleteIfEmpty: this.deleteIfEmpty});
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    if (this.getAllRoomsSubscription) {
      this.getAllRoomsSubscription.unsubscribe()
    }
  }
}
