import { OverlayRef } from '@angular/cdk/overlay';
import { Component, OnInit, OnDestroy, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { BufferProgram, nurseElement } from 'src/app/shared/models/buffer-program.model';
import { DoctorAgenda } from 'src/app/shared/models/doctor-agenda.model';
import { Profile } from 'src/app/shared/models/profile.model';
import { Room } from 'src/app/shared/models/room.model';
import { Specialty } from 'src/app/shared/models/specialty.model';
import { RoomService } from 'src/app/shared/services/room.service';
import { getHoursAndMinutes } from 'src/app/shared/utils/cross-functions';

@Component({
  selector: 'app-week-vue-popup',
  templateUrl: './week-vue-popup.component.html',
  styleUrls: ['./week-vue-popup.component.scss'],
  host: {
    '(document:click)': 'globalClick($event)',
  },
})
export class WeekVuePopupComponent implements OnInit, OnDestroy {

    // @Input() doctorAgendas: {doctoragendas: DoctorAgenda[], room: Room};
    @Input() programs: any;
    @Input() profiles: any;
    // @Input() specialty: Specialty;
    @Input() overlay: OverlayRef;
    @Input() clicHeight: number;
    @Input() openOnleft: boolean = false;
    @Input() typeOfData: string = '';

    @Output('closePopup') closePopup: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('pageRef', {static: false}) pageRef: ElementRef;
    @ViewChild('triangle', {static: false}) triangle: ElementRef;
  
    public startTime: string;
    public endTime: string;
    public programType: string;
    public iades: nurseElement[] = [];
    public paramedicals: nurseElement[] = [];
    public roomNumber: number;
    public loading: boolean;
    public margin: number;
    
    private initialClick: boolean = false;
    private agendasSubscription: any;

    constructor(private roomService: RoomService) {}

    ngOnInit(): void {}

    ngOnChanges() {
        // this.loading = true;
        // if (!this.program) {
        //     return ;
        // }
        // this.initTimes();
        // this.initProgramType();
        // if (!this.doctorAgendas && this.programType === 'Bloc') {
        //     this.fetchDA();
        // }
        // if (this.programType === 'Bloc') {
        //     this.roomNumber = (this.program.room as Room).roomNumber as any;
        // }
        
        // this.splitIadeAndNurses();
        // this.loading = false;
    }

    ngAfterViewChecked() {
        if (this.pageRef) {
            this.margin = this.clicHeight - this.pageRef.nativeElement.getBoundingClientRect().y;
            this.triangle.nativeElement.style.marginTop = ((this.margin - 10) * 1.25) + 'px';
        }

    }


    // splitIadeAndNurses() {
    //     if (!this.program.nurses) {
    //         return ;
    //     }
    //     this.program.nurses.forEach((nurse) => {

    //     if (nurse.role.name === 'IADE') {
    //         this.iades.push(nurse);
    //     } else {
    //         this.paramedicals.push(nurse);
    //     }

    //     })
    //     this.paramedicals.sort((a, b) => a.role.priority - b.role.priority);
    // }

    // initTimes() {
    //     this.startTime = getHoursAndMinutes(this.program.startTime);
    //     let separator = this.startTime.includes(':');

    //     if (separator) {
    //     this.startTime = this.startTime.replace(':', 'h')
    //     } else {
    //     this.startTime += 'h';
    //     }

    //     this.endTime = getHoursAndMinutes(this.program.endTime);
    //     separator = this.endTime.includes(':');

    //     if (separator) {
    //     this.endTime = this.endTime.replace(':', 'h')
    //     } else {
    //     this.endTime += 'h';
    //     }
    // }

    closeOverlay() {
        this.closePopup.emit(true);
        this.overlay.detach();
    }

    globalClick(event) {
        if (!this.initialClick) {
            this.initialClick = true;
            return ;
        }
        const xClick = event.x;
        const yClick = event.y;
        const page = this.pageRef.nativeElement.getBoundingClientRect();
        if (yClick < page.y ||
            yClick > page.y + page.height ||
            xClick < page.x ||
            xClick > page.x + page.width
        ) {
            this.closePopup.emit(true);
            this.overlay.detach();
        }
    }

    capitalize(str: string): string {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    ngOnDestroy() {
        if (this.agendasSubscription) {
            this.agendasSubscription.unsubscribe();
        }
    }
}
