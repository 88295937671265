import * as io from 'socket.io-client';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/core/services/storage.service';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';
import { Profile } from 'src/app/shared/models/profile.model';
import { User } from 'src/app/shared/models/user.model';
import { Channel } from 'src/app/shared/models/channel.model';
import { UserService } from 'src/app/shared/services/user.service';

@Injectable()

export class ChatService implements OnInit, OnDestroy {

    private url = environment.socketEndPoint;
    public profile : Profile;
    public user : User;

    public socketGlobal;
    public socketSendbox;
    public hospitalId;

    socket: any;
    
    public messagesSubject : BehaviorSubject<any> = new  BehaviorSubject<any>([]);
    public messages : Observable<any> = this.messagesSubject.asObservable();
    public channelsSubject : BehaviorSubject<any> = new  BehaviorSubject<any>([]);
    public channels : Observable<any> = this.channelsSubject.asObservable();
    public channelSubject : BehaviorSubject<any> = new  BehaviorSubject<any>({});
    public channel : Observable<any> = this.channelSubject.asObservable();
    public newInChannelSubject : Subject<any> = new  Subject<any>();
    public newInSendBoxSubject : Subject<any> = new  Subject<any>();

    public removedChannelSubject : Subject<any> = new  Subject<any>();
    public removedChannelInChannelSubject : Subject<any> = new  Subject<any>();

    public unseenMessagesSubject : Subject<any> = new  Subject<any>();
    public seenByAllMessageSubject : Subject<any> = new  Subject<any>();
    public isMessagingModeSubject : BehaviorSubject<any>  = new  BehaviorSubject<any>(false);
    public messageSeenNumSubject : BehaviorSubject<any>  = new  BehaviorSubject<any>(0);
    public inSendboxNewInChannelSubject : BehaviorSubject<any>  = new  BehaviorSubject<any>({});
    public inSendboxNewChannelSubject : BehaviorSubject<any>  = new  BehaviorSubject<any>({});
    public inGlobalNewChannelSubject : Subject<any>  = new  Subject<any>();
    public globalNewMessagesSubject : Subject<any>  = new  Subject<any>();

    constructor(
        private storageService : StorageService,
        private userService: UserService
    ) {}

    ngOnInit() {}

    connect() {
        const user = this.storageService.getUser();
        if (!user) {
            return ;
        }

        const userId = user.profile._id;
        this.hospitalId = this.userService.getSelectedHospitals()[0];
        
        this.socket = io(this.url, {
                        query: {
                        profile: userId,
                        hospital: this.hospitalId,
                        reconnection: false,
                        }
                    });
        if (this.socket) {
            this.getInChannelMessages();
            this.getInSendboxMessages();
            this.getGlobalNewMessages();
            this.getSeenByAllMessage();
            this.inSendboxNewInChannel();
            this.inGlobalNewChannel();
            this.inSendboxNewChannel();
            this.inChannelNewChannel()
            this.getUnseenMessages();
            this.inSendboxRemoveOfChannel();
            this.inChannelRemoveOfChannel();
        }
    }

    connectToSandbox() {
        this.emitChannel(this.hospitalId);
    }

    disconnectToSandbox() {
        this.emitUnchannel(this.hospitalId);
    }

    joinChannel(channelId :string, oldChannelId ? : string) : void{
        if (oldChannelId)
            this.emitUnchannel(oldChannelId)
        this.emitChannel(channelId)
    }

    emitChannel(channelId) {
        if (this.socket) {
            this.socket.emit('channel', { channelId: channelId });
        }
    }

    emitUnchannel(channelId) {
        this.socket.emit('unchannel', {channelId :channelId});
    }

    sendSeenMessages(messages){
        this.socket.emit('messageSeen', messages);
    }

    sendMessage(text: string, isOneToOne, firstName, lastName, channelId, channelName, messageNumber?: number) : void {
        let messageToSend = {message: text, isOneToOne: isOneToOne, channelId: channelId, messageNumber: messageNumber, firstName: firstName, lastName: lastName, channelName: channelName};
        this.socket.emit('onMessageSend', messageToSend);
    }

    getInChannelMessages(){
        this.socket.on('inChannelMessage', data => {
            if (data) {
                this.newInChannelSubject.next(data);
                this.sendSeenMessages([{ messageId: data.messageId }]);
            }
        });
    }

    getInSendboxMessages(){
        this.socket.on('inSendboxMessage', data => {
            if(data){
                this.newInSendBoxSubject.next(data)
            }
        });
    }

    getGlobalNewMessages(){
        this.socket.on('inGlobalMessage', messages => {
            this.globalNewMessagesSubject.next(messages.messageNb)
          });
    }

    getSeenByAllMessage(){
        this.socket.on('seenByAll', message => {
            this.seenByAllMessageSubject.next(message)
          });
    }

    inSendboxNewChannel() {
        this.socket.on('inSendboxNewChannel', data => {
            if (data) {
                this.inSendboxNewChannelSubject.next(data)
            }
        });
    }

    inGlobalNewChannel(){
        this.socket.on('inGlobalNewChannel', data => {
            if(data){
                this.inGlobalNewChannelSubject.next(data);
            }
        });
    }

    addChannel(profiles : string[] , name : string , channelNumber : number) : void{
        this.socket.emit('createChannel', {
            channel : {
                profileId : this.storageService.getUser().profile._id,
                hospitalId : (this.userService.getSelectedHospitals())[0],
                members : profiles || [],
                name : name || "",
            },
            profileId : this.storageService.getUser().profile._id,
            hospitalId : (this.userService.getSelectedHospitals())[0],
            channelNumber : channelNumber
          });
    }

    editChannel(channel : Channel) : void{
        let newChannel = this.generateChannel(channel);
        this.socket.emit('editToChannel', {
            channelId : newChannel._id,
            newChannel : newChannel
          });
    }

    inSendboxRemoveOfChannel(){
        this.socket.on('inSendboxRemovedOfChannel', data => {
            if (data) {
                this.removedChannelSubject.next(data);
            }
        });
    }

    inChannelRemoveOfChannel(){
        this.socket.on('inChannelRemovedOfChannel', data => {
            if (data) {
                this.removedChannelInChannelSubject.next(data);
            }
        });
    }

    inSendboxNewInChannel(){
        this.socket.on('inSendboxNewInChannel', data => {
            if (data) {
                this.inSendboxNewInChannelSubject.next(data)
            }
        });
    }

    inChannelNewChannel(){
        this.socket.on('inChannelNewChannel', data => {
            if (data) {
                this.inSendboxNewChannelSubject.next(data)
            }
        });
    }

    getUnseenMessages(){
        this.socket.on('notSeenMessage', messages => {
            this.unseenMessagesSubject.next(messages.totalNotSeen)
          });
    }

    generateChannel(channel : any) : any {
        let newChannel = {...channel};
        newChannel.members = newChannel.members.map(member => member._id);
        newChannel.lastMessage = newChannel.lastMessage._id ;
        return newChannel
    }

    setUser(){
        this.profile = this.storageService.getUser().profile;
        this.user = this.storageService.getUser();
    }

    disconnectSocket() {
        if (this.socket) {
            this.socket.disconnect();
            this.socket.close();
            this.socket = null;
        }
    }

    deconnectFromSocket(){
        this.disconnectToSandbox()
        this.disconnectSocket()
    }

    ngOnDestroy(){
        this.deconnectFromSocket();
    }

}
