import { defaultMongoDB, timestamps, updatedBy } from "../interfaces/shared.interfaces";
import { Hospital } from "./hospital.model";
import { Room } from "./room.model";
import { Specialty } from "./specialty.model";

export enum typesDailyNeeds {
    BLOC = "bloc",
    CONSULTATION = "consultation",
    AJUSTEMENT = "ajustement"
}

export interface dailyNeeds extends timestamps, updatedBy, defaultMongoDB {
    hospital: Hospital,
    room: Room,
    rooms: Room[],
    anesthNeeds: number,
    iadeNeeds: number,
    seniorNeeds: number,
    juniorNeeds: number,
    internNeeds: number,
    date: Date | string,
    type: typesDailyNeeds,
    specialty: Specialty,
    modified: boolean,
}
