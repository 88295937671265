import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { Profile } from '../../models/profile.model';
import { ErrorService } from 'src/app/shared/services/error.service';
import { SurgeonOpening } from 'src/app/shared/models/surgeonOpenings.model';
import { SurgeonOpeningsService } from 'src/app/shared/services/surgeon-openings.service';
import { SurgeonOpeningComponent } from '../surgeon-opening/surgeon-opening.component';
import { HeaderService } from 'src/app/core/services/header.service';
import { UserService } from '../../services/user.service';
import { StorageService } from 'src/app/core/services/storage.service';


@Component({
  selector: 'app-week-availabilites-of-surgeon',
  templateUrl: './week-availabilites-of-surgeon.component.html',
  styleUrls: ['./week-availabilites-of-surgeon.component.scss']
})
export class WeekAvailabilitesOfSurgeonComponent implements OnInit, OnDestroy, OnChanges {

  @Input() day: string;
  @Input() hoverDate: Date;
  @Input() selectedPeriod: Date[];
  @Input() enableSelection: boolean;
  @Input() weekDays: any[];
  @Input() profile: Profile;
  @Input() position: string;
  @Input() isNight: boolean;
  @Input() canModify: boolean;

  @Output() daySelected = new EventEmitter();
  @Output() dayHover = new EventEmitter();
  @Output() popupClosed = new EventEmitter();
  @Output() dataChange = new EventEmitter();

  @Input() isAccountView: boolean;

  shortDates;

  public weekSurgeonOpenings: SurgeonOpening[];
  public selectedDays: number[];

  private weekSurgeonOpeningSubscription: Subscription;
  private afterDialogCloseSubscription: Subscription;

  public isMultiHospitals: boolean = false

  constructor(
    private errorService: ErrorService,
    private dialog: MatDialog,
    private surgeonOpeningService: SurgeonOpeningsService,
    private headerService: HeaderService,
    private userService: UserService,
    private storageService: StorageService
    ) {
    this.selectedDays = [];
  }

  ngOnInit() {
    this.isMultiHospitals = this.userService.isCurrentUserHasMultipleHospitalsSelected()
    this.getProfileWeekAvailabilities();
    this.shortDates = this.getShortDates(this.weekDays);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.day && changes.day.previousValue) {
      this.refillProfilesWeekOpenings();
    }
    this.shortDates = this.getShortDates(this.weekDays);
  }

  getShortDate(date: string): string {
    let momentDate = moment(date);
    return momentDate.format("DD/MM");
  }

  getAvailability(i) {
    if (this.weekSurgeonOpenings[i].surgeonopening.length > 0) {
      const so = this.weekSurgeonOpenings[i].surgeonopening[0][0];
      if (so.opening) {
        return 'AVAILABLE'
      } else {
        return 'ABSENT'
      }
    } else if (this.weekSurgeonOpenings[i].surgeonopeninghistory.length > 0) {
      const so = this.weekSurgeonOpenings[i].surgeonopeninghistory[0][0];
      if (so.opening) {
        return 'AVAILABLE'
      } else {
        return 'ABSENT'
      }
    }
  }

  getShortDates(dates: string[]): string[] {
    let shortDates: string[] = [];
    for (let date of dates) {
      let shortDate = this.getShortDate(date);
      shortDates.push(shortDate);
    }
    return shortDates;
  }

  getProfileWeekAvailabilities(info?: any) {
    if (this.weekSurgeonOpeningSubscription) {
      this.weekSurgeonOpeningSubscription.unsubscribe();
    }

    this.weekSurgeonOpeningSubscription = this.surgeonOpeningService.getWeekOpenings(this.profile._id, moment(this.day).format('YYYY-MM-DD').toString()).subscribe((res: any) => {
      this.weekSurgeonOpenings = res;
      this.setSurgeonOpenings(res)
    }, error => this.errorService.handleError(error));
  }

  setSurgeonOpenings(sos: SurgeonOpening[]): void {
    this.weekSurgeonOpenings = [];
    for (var i = 0; i < 6; i++) {
      const index = sos.findIndex(el => el.day == (i + 2));
      if (index > -1) {
        this.weekSurgeonOpenings.push(sos[index])
      } else {
        this.weekSurgeonOpenings.push(null)
      }
    }

    //If there is day=1 so its sunday we need to place it in the last of array
    const index = sos.findIndex(el => el.day == 1);
    if (index > -1) {
      sos[index].day = 8
      this.weekSurgeonOpenings.push(sos[index])
    } else {
      this.weekSurgeonOpenings.push(null)
    }


  }

  refillProfilesWeekOpenings(): void {
    this.weekSurgeonOpenings = null;
    this.getProfileWeekAvailabilities();
  }

  openDialog(dayIndex: number): void {
    if (this.iSworkingDay(dayIndex)) {
      let date = this.weekDays[dayIndex];
      const dialogRef = this.dialog.open(SurgeonOpeningComponent, {
        width: '380px',
        data: {
          profile: this.profile,
          availability: this.getAvailability(dayIndex),
          date: date
        }
      });
      this.afterDialogCloseSubscription = dialogRef.afterClosed().subscribe((result) => {
        this.popupClosed.emit(result);
        if (result) {
          // To refresh opened rooms counter
          this.headerService.updateOpenedRoomsCounter.next(true);
          this.getProfileWeekAvailabilities(result);
          this.dataChange.emit({ dayIndex });
        }
      });
    }
  }

  iSworkingDay(i: number): boolean {
    if (!this.weekSurgeonOpenings[i]) {
      return false
    } else {
      return true
    }

  }

  isUserHasEdit(): boolean {
    if (this.userService.isInternResponsible()) {
      return false;
    }

    return true;
  }

  selectDay(index: number): void {
    if (this.isUserHasEdit() && this.iSworkingDay(index)) {
      if (this.enableSelection) {
        if (this.selectedDays.length === 1 && this.selectedDays.includes(index)) {
          this.openDialog(index);
          this.initSelection();
        } else {
          this.selectedDays.push(index);
          this.daySelected.emit(this.weekDays[index]);
        }
      } else {
        if (this.canModify) {
          this.openDialog(index);
        }
      }
    }
  }

  isSelected(index: number): boolean {
    return this.enableSelection && this.selectedDays.includes(index);
  }

  isInPeriod(index: number): boolean {
    if (this.enableSelection && this.selectedPeriod && this.selectedPeriod.length > 0) {
      const day = this.weekDays[index];
      const period = this.selectedPeriod.sort((a, b) => (a > b) ? 1 : -1);
      if (this.selectedPeriod.length === 2) {
        return (period[0] < day && period[1] > day);
      } else if (this.hoverDate) {
        return (period[0] < day && this.hoverDate > day) || (period[0] > day && this.hoverDate < day);
      }
    }
    return false;
  }

  initSelection(): void {
    this.selectedDays = [];
  }

  emitDayHover(index: number): void {
    if (this.enableSelection) {
      this.dayHover.emit(this.weekDays[index]);
    }
  }

  getServices(dayCalendars: any) {
    if (dayCalendars) {
      if (dayCalendars.hospital.name && dayCalendars.hospital.name.toString().length > 25) {
        return dayCalendars.hospital.name.toString().substring(0, 25) + '...';
    }
      return dayCalendars.hospital.name
    } else {
      return ""
    }
  }

  getDayClass(date) {
    const today = moment().format('DD MM YYYY');
    const day = moment(date).format('DD MM YYYY');
    if (today !== day) {
      return 'day-header';
    }
    return 'today-header';
  }


  ngOnDestroy(): void {
    if (this.weekSurgeonOpeningSubscription) {
      this.weekSurgeonOpeningSubscription.unsubscribe();
    }
    if (this.afterDialogCloseSubscription) {
      this.afterDialogCloseSubscription.unsubscribe();
    }
  }
}
