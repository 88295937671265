import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { pages } from '../../config/pages';
import { Profile } from '../../models/profile.model';
import { StatopService } from '../../services/statop.service';
import { UserService } from '../../services/user.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-profiles-list-popup',
	templateUrl: './profiles-list-popup.component.html',
	styleUrls: ['./profiles-list-popup.component.scss']
})
export class ProfilesListPopupComponent implements OnInit {
	public osComponentOptions = {
		className: "os-theme-dark custom-sidebar",
		nativeScrollbarsOverlaid: {
		  showNativeScrollbars: true
		},
		overflowBehavior: {
		  x: "hidden",
		},
		paddingAbsolute: true,
		scrollbars: {
		  autoHide: 'never',
		  clickScrolling: true
		},
	};
	public profiles: { 
		titulaire: Profile[], 
		vacataire: Profile[] 
	};
	public date: string;
	public reasonDisplayName: string;
	public reasonDisplayAvailability: string;
	public isInterResponsible: boolean;
	public isMultiHospital: boolean = false;
	private getProfilesByReasonAndDateSubscription: Subscription;

	constructor(
		private router: Router,
		@Inject(MAT_DIALOG_DATA) public data: { 
			date: string, 
			reasonId: string,
			reasonTitle: string,
			reasonAvailability: string,
			profilesType: string,
			hospitalId: any,
		},
		public dialogRef: MatDialogRef<ProfilesListPopupComponent>,
		private statopService: StatopService,
		private userService: UserService) { 
			this.isInterResponsible = this.userService.isInternResponsible();
	}

	ngOnInit(): void {
		this.isMultiHospital = this.userService.isCurrentUserHasMultipleHospitalsSelected()
		this.date = this.data.date;
		this.reasonDisplayName = this.data.reasonTitle ? `${this.data.reasonTitle[0].toUpperCase()}${this.data.reasonTitle.substr(1).toLowerCase()}` : this.data.reasonTitle;
		this.reasonDisplayAvailability = this.data.reasonAvailability === 'AVAILABLE' ? 'Présence' : 'Absence';
		let profilesType = this.data.profilesType;
		this.getProfilesByReasonAndDateSubscription = this.statopService.getProfilesByReasonAndDate(
			this.data.hospitalId,
			this.data.date,
			this.data.reasonId,
			profilesType
		).subscribe((profiles) => {
			const listOfProfiles: Profile[] = profiles.map((profile) => {
				profile.profile.canRedirectToProfile = this.canRedirectToProfile(profile.profile);
				return profile.profile;
			});
			listOfProfiles.sort((a, b) => {
				return `${a.firstName.toLowerCase()} ${a.lastName.toLowerCase()}`.localeCompare(`${b.firstName.toLowerCase()} ${b.lastName.toLowerCase()}`);
			});
			this.profiles = {
				titulaire: listOfProfiles.filter((profile) => profile.residency.toLowerCase() === 'titulaire'),
				vacataire: listOfProfiles.filter((profile) => profile.residency.toLowerCase() === 'vacataire')
			};
		});
	}

	canRedirectToProfile(profile: any): boolean {
		if (this.isInterResponsible) {
		  if (profile.seniority === 'Interne') {
			return true;
		  } else {
			return false;
		  }
		} else {
		  return true;
		}
	  }

	goToLink(): void {
		this.dialogRef.close();
		let page;
		if (this.data.profilesType === 'nurses') {
			page = 'nurses';
		} else {
			page = 'anesthesistes';
		}
		this.router.navigate([pages[page], {date: new Date(this.date)}]);
	}

	close(): void {
		this.dialogRef.close();
	}

	onProfileClick(profile: Profile & { canRedirectToProfile: boolean }): void {
		if (profile.canRedirectToProfile) {
			this.dialogRef.close();
			this.router.navigate([`${pages.profiles}/${profile._id}`]);
		}
	}

	ngOnDestroy() {
		if(this.getProfilesByReasonAndDateSubscription) {
			this.getProfilesByReasonAndDateSubscription.unsubscribe();
		}
	}
}
