import {Component, Input, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import { isNurse } from '../../models/profile.model';
import { CalendarRequestService } from '../../services/calendar-request.service';
import { ErrorService } from './../../services/error.service';
import { ProfileService } from './../../services/profile.service';
import { ANESTHETIST, BLOC, CONSULTATION, IADE } from 'src/app/shared/const/glabals.const';
import { UserService } from './../../services/user.service';
import { getFirstHospitalSelectedData, sortByDate } from '../../utils/cross-functions';
import * as moment from 'moment';
import { NURSE } from './../../const/glabals.const';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { StorageService } from 'src/app/core/services/storage.service';
import { HospitalService } from 'src/app/shared/services/hospital.service';
import { Formation } from '../../models/formation.model';
import { Paramedical } from '../../models/paramedical.model';
import { ParamedicalService } from '../../services/paramedical.service';

@Component({
  selector: 'app-popup-availalability-request-infos',
  templateUrl: './popup-availalability-request-infos.component.html',
  styleUrls: ['./popup-availalability-request-infos.component.scss']
})
export class PopupAvailalabilityRequestInfosComponent implements OnInit, OnDestroy {

  @Input() cr
  @Input() profile
  @Input() fromDate
  @Input() toDate
  @Input() searchProfileParam

  getRequestsHistorySubscription: any;
  requests: any;
  requestsCounter: any;
  getEffectifSubscription: Subscription;
  effectif: any;
  originalEffective;
  originalNeeds;
  effectifPerResidency: any;
  needs: any;
  morningDiffs: number[];
  afternoonDiffs: number[];
  diffs: number;
  dayDiffs: number[] = [];
  needsEffectifs: { effectifs: any; needs: any; difference: number; }[];
  needsEffectifsBloc: { effectifs: any; needs: any; difference: number; }[];
  needsEffectifsConsultation: { effectifs: any; needs: any; difference: number; }[];
  position: string;
  isAnesthesiste: boolean;
  isIade: boolean;
  isNurseProfile: boolean;
  dayDiffsAnesthesiste: any;
  dayDiffsIADE: number;
  anesthNeeds: any;
  isEffectifGarde: boolean;
  isMultiHospital: boolean;
  anesthOption: boolean;
  public formatedFromDate: string;
  public formatedToDate: string;
  public isEffectiveNeedsLoading: boolean = true;
  public isFormationsAndParamedicRulesLoading: boolean = true;
  public formations: Formation[];
  public paramedicRules: Paramedical[];
  private getFormationsAndParamedicRulesSubscription: Subscription;

  constructor(
    private userService: UserService,
    private profileService: ProfileService,
    private errorService: ErrorService,
    private calendarRequestService: CalendarRequestService,
    private storageService: StorageService,
    private hospitalService : HospitalService,
    private paramedicalService: ParamedicalService,
  ) { }

  ngOnInit() {
    this.isMultiHospital = this.userService.isCurrentUserHasMultipleHospitalsSelected()
    this.isEffectifGarde =  this.cr.reason && this.cr.reason.isGarde
    this.setPosition()
    this.getNumberOfRequests(this.cr.hoveredDate.substring(0, 10))
    this.getFormationsAndParamedicRules();
    this.getEffectif(this.cr.hoveredDate, this.cr.hoveredDate)
    this.formatedFromDate = this.formatDate(this.cr.fromDate.substring(0, 10));
    this.formatedToDate = this.formatDate(this.cr.toDate.substring(0, 10));
    this.anesthOption = this.hospitalService.doesHospitalHaveAnesthDetailsOption()
  }

  ngOnChanges(changes: SimpleChanges) {
    this.formatedFromDate = this.formatDate(this.cr.fromDate.substring(0, 10));
    this.formatedToDate = this.formatDate(this.cr.toDate.substring(0, 10));
  }

  getFormations(): Observable<Formation[]> {
   return this.paramedicalService.getFormations();
  }

  getParamedicRules(): Observable<Paramedical[]> {
    return this.paramedicalService.getParamedicals();
  }


  getFormationsAndParamedicRules(): void {
    this.isFormationsAndParamedicRulesLoading = true;

    if (this.getFormationsAndParamedicRulesSubscription) {
      this.getFormationsAndParamedicRulesSubscription.unsubscribe();
    }
    
    const getFormationsObservable = this.getFormations();
    const getParamedicRulesObservable = this.getParamedicRules();


    this.getFormationsAndParamedicRulesSubscription = forkJoin([getFormationsObservable, getParamedicRulesObservable]).subscribe((data) => {
      this.formations = data[0];
      this.paramedicRules = data[1];
      this.isFormationsAndParamedicRulesLoading = false;
    })
  }

  get isConsultaion() : boolean{
    return this.cr.reason && this.cr.reason.title === CONSULTATION
  }

  get isBloc() : boolean{
    return this.cr.reason && this.cr.reason.title === BLOC
  }


  setPosition() {
    const position = this.cr.profile.position
    
    if (position == ANESTHETIST) {
      this.position = ANESTHETIST
      this.isAnesthesiste = true
    } else if (this.userService.isIad(this.cr.profile)) {
      this.position = IADE[0]
      this.isIade = true
    } else {
      this.position = NURSE
      this.isNurseProfile = true
    }
  }

  formatDate(str) {
    const arr = str.split('-');
    return arr[2] + '-' + arr[1] + '-' + arr[0];
  }

  get isNurse(): boolean {
    return isNurse(this.cr.profile);
  }

  getEffectif(startDate, endDate) {
    if (!this.getEffectifSubscription) {
      this.getEffectifSubscription = this.profileService.getAverages(startDate.substring(0, 10), endDate.substring(0, 10),this.isEffectifGarde, this.cr.hospital._id).subscribe((result) => {
        this.effectif = result.effectif ? sortByDate(result.effectif) : [];
        this.originalEffective = result.effectif ? sortByDate(result.effectif) : [];
        this.anesthNeeds = result.anesthNeeds
        this.effectifPerResidency = result.residency ? sortByDate(result.residency) : [];
        this.needs = result.nurseNeeds ? sortByDate(result.nurseNeeds) : [];
        this.originalNeeds = result.nurseNeeds ? sortByDate(result.nurseNeeds) : [];
        this.isEffectiveNeedsLoading = false;

        if(this.isAnesthesiste || this.isIade){
          this.setNeedsEffectifs(result.anesthNeeds, this.formatEffectif(result.effectif, result.anesthNeeds))
        }else{
          this.fixEffectif();
          this.generateDifferences();
        }

      }, error => this.errorService.handleError(error));
    }
  }

  fixEffectif() {
    const effectifArray: any[] = [];
    for (const needItem of this.needs) {
      const eItem = this.effectif.find((effectifItem) => effectifItem.date === needItem.date);
      const item: any = {};
      item.morningNurseEffectif = this.getValueOrZero(eItem && eItem.morningNurseEffectif);
      item.afternoonNurseEffectif = this.getValueOrZero(eItem && eItem.afternoonNurseEffectif);
      item.nurseEffectifDay = this.min(item.morningNurseEffectif, item.afternoonNurseEffectif)
      item.anesthEffectif = this.getValueOrZero(eItem && eItem.anesth);
      item.anesth = this.getValueOrZero(eItem && eItem.anesth);
      item.iade = this.getValueOrZero(eItem && eItem.iade);
      item.date = this.getValueOrZero(needItem.date);
      item.day = this.getValueOrZero(needItem.day + 1);
      effectifArray.push(item);
    }
    this.effectif = effectifArray;
    this.needs[0].DayNurseNeed = this.max(this.needs[0].morningNurseNeed, this.needs[0].afternoonNurseNeed)
  }

  getValueOrZero(value: number) {
    return ((value) ? value : 0);
  }

  generateDifferences(): void {

    const morningDiffs: number[] = [];
    const afternoonDiffs: number[] = [];

    for (const i in this.needs) {
      if (this.effectif[i]) {
        morningDiffs.push(this.effectif[i].morningNurseEffectif - this.needs[i].morningNurseNeed);
        afternoonDiffs.push(this.effectif[i].afternoonNurseEffectif - this.needs[i].afternoonNurseNeed);
        this.dayDiffs.push(this.effectif[0].nurseEffectifDay - this.needs[0].DayNurseNeed)
      }
    }
  }

  formatEffectif(effectifs, needs) {
    const effectifArray: any[] = [];
    for (const needItem of needs) {
      const eItem = effectifs.find((effectifItem) => effectifItem.date === moment(needItem.date).format("YYYY-MM-DD"));
      effectifArray.push(eItem);
    }
    return effectifArray;
  }

  setNeedsEffectifs(needs: any[], effectifs: any[]) {
    const hospital = getFirstHospitalSelectedData(this.userService.getCurrentUser(), this.userService.getSelectedHospitals())
    let hospitalType = hospital.type;

    if (hospitalType === "hopital privé") {
      if (this.userService.isCadreBloc() && !this.userService.isInternResponsible()) {
          this.needsEffectifs = needs.map((_, i) => {
          const effectif = effectifs[i] ? effectifs[i].totalBloc + effectifs[i].totalConsultation : 0;
          const need = needs[i] ? needs[i].blocNeeds + needs[i].consultationNeeds : 0;
          return this.getEffectifNeeds(effectif, need)
        })
      } else if (this.userService.isInternResponsible() && this.anesthOption) {
        this.needsEffectifs = needs.map((_, i) => {
          const effectif = effectifs[i].detailsBloc ?  effectifs[i].totalBloc + effectifs[i].totalConsultation : 0;
          const need = needs[i] ? needs[i].blocNeeds + needs[i].consultationNeeds : 0;
          return this.getEffectifNeeds(effectif, need)
          })
      } else if (this.userService.isIadRes()) {
        this.needsEffectifs = needs.map((_, i) => {
          const effectif = effectifs[i] ? effectifs[i].iade : 0;
          const need = needs[i] ? needs[i].iadeNeeds : 0;
          return this.getEffectifNeeds(effectif, need)
        })
      } else {
        this.needsEffectifs = needs.map((_, i) => {
        const effectif = effectifs[i] ? effectifs[i].totalBloc + effectifs[i].totalConsultation : 0;
        const need = needs[i] ? needs[i].blocNeeds + needs[i].consultationNeeds: 0;
        return this.getEffectifNeeds(effectif, need)
        })
      }
    } else {
        if (this.isIade) {
          this.needsEffectifs = needs.map((_, i) => {
            const effectif = effectifs[i] ? effectifs[i].iade : 0;
            const need = needs[i] ? needs[i].iadeNeeds : 0;
            return this.getEffectifNeeds(effectif, need)
          })
        } else if(this.isBloc && this.userService.isInternResponsible() && this.anesthOption){
            this.needsEffectifs = needs.map((_, i) => {
              const effectif = effectifs[i].detailsBloc ?  effectifs[i].totalBloc + effectifs[i].totalConsultation : 0;
              const need = needs[i] ? needs[i].blocNeeds + needs[i].consultationNeeds : 0;
              return this.getEffectifNeeds(effectif, need)
            })
        } else if (this.isBloc && this.userService.isInternResponsible() === false) {
            this.needsEffectifs = needs.map((_, i) => {
              const effectif = effectifs[i] ? effectifs[i].anesthBloc : 0;
              const need = needs[i] ? needs[i].blocAnesthNeeds : 0;
              return this.getEffectifNeeds(effectif, need)
              })
        } else if(this.isConsultaion){
            this.needsEffectifs = needs.map((_, i) => {
            const effectif = effectifs[i] ? effectifs[i].totalConsultation : 0;
            const need = needs[i] ? needs[i].consultationNeeds : 0;
            return this.getEffectifNeeds(effectif, need)
            })
        } else{
            this.needsEffectifs = needs.map((_, i) => {
            const effectif = effectifs[i] ? effectifs[i].anesth : 0;
            const need = needs[i] ? needs[i].blocAnesthNeeds + needs[i].consultationNeeds : 0;
            return this.getEffectifNeeds(effectif, need)
            })
          }
    }
  }

  getEffectifNeeds(effectif, need) {
    return {
      effectifs: effectif,
      needs: need,
      difference: effectif - need
    }
  }


  max(number1, number2) {
    if (number1 > number2) return number1
    else return number2
  }

  min(number1, number2) {
    if (number1 < number2) return number1
    else return number2
  }

  getNumberOfRequests(date) {
    if(this.getRequestsHistorySubscription) this.getRequestsHistorySubscription.unsubscribe();
    this.getRequestsHistorySubscription = this.calendarRequestService.countActiveRequests(this.position, date, date).subscribe(res => {
      this.requestsCounter = res
    }, err => this.errorService.handleError(err))
  }

  ngOnDestroy() {
    if (this.getEffectifSubscription) this.getEffectifSubscription.unsubscribe();
    if (this.getFormationsAndParamedicRulesSubscription) this.getFormationsAndParamedicRulesSubscription.unsubscribe();
    if (this.getRequestsHistorySubscription) this.getRequestsHistorySubscription.unsubscribe();
  }
}
