import { DatePipe, TitleCasePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subscription } from 'rxjs';
import { Profile } from 'src/app/shared/models/profile.model';
import { Room } from 'src/app/shared/models/room.model';
import { DoctorAgendasService } from 'src/app/shared/services/doctor-agendas.service';
import { ErrorService } from 'src/app/shared/services/error.service';
import { PythonAPIService } from 'src/app/shared/services/pythonApi.service';
import { RoomService } from 'src/app/shared/services/room.service';
import { SurgeonOpeningsService } from 'src/app/shared/services/surgeon-openings.service';


@Component({
  selector: 'app-move-intervention-popup',
  templateUrl: './move-intervention-popup.component.html',
  styleUrls: ['./move-intervention-popup.component.scss']
})
export class MoveInterventionPopupComponent implements OnInit {
  public nextSurgeonOpeningsOfSurgeon: any[];

  public date: Date;
  public startTime: string;
  public endTime: string;
  public rooms: any[];
  public surgeon: Profile;

  public selectedIndex: number = 0;
  public surgeonOpeningsDates: string[] = [];
  public showDoctorAgendas: boolean = false;

  public formGroup: FormGroup;

  public showErrorMessage: boolean = false;
  public errorMessage: string = `La période demandée est déjà occupée`;

  public showNoSlotMessage: boolean = false;
  public noSlotMessage: string = `Aucun créneau de remplacement convenable ne peut vous être proposé`;

  private getSurgeonOpeningsOfSurgeonSubscription: Subscription;
  private checkIfPeriodIsAvailableSubscription: Subscription;
  public isSpinning: boolean = false;

  public roomsWithoutOverflow: any[] = [];
  public roomsWithOverflow: any[] = [];
  public estimatedDuration: number;

  public availableSlots: any[] = [];
  public estimatedInterventionDuration: any;
  AverageInterInterventionsTimeRoom: any;

  constructor(
    public dialogRef: MatDialogRef<MoveInterventionPopupComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    private surgeonOpeningsService: SurgeonOpeningsService,
    private errorService: ErrorService,
    private roomService: RoomService,
    private pythonApisService: PythonAPIService,
    private doctorAgendasService: DoctorAgendasService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private titleCasePipe: TitleCasePipe,
  ) {
    this.date = new Date(this.data.doctorAgenda.date);
    this.startTime = this.getStartTime();
    this.endTime = this.getEndTime();
    this.rooms = this.data.rooms;
    this.surgeon = this.data.doctorAgenda.surgeon;
    this.makeForm();
  }

  get formattedEndTime(): string {
    return `${this.endTime.split(':')[0]} h ${this.endTime.split(':')[1]}`
  }

  ngOnInit() {
    const surgeonId = String(this.data.doctorAgenda.surgeon._id);
    const endDate = this.getDateOfLastDayOfNextWeek(this.date);
    this.getSurgeonOpeningsOfSurgeonByInterval(surgeonId, this.date.toISOString(), endDate.toISOString());
    this.getEstimatedDuration();
  }

  makeForm(): void {
    this.formGroup = this.formBuilder.group({
      selectedRoom: [this.data.doctorAgenda.room, [Validators.required]],
      date: [{value: this.date, disabled: false}, [Validators.required]],
    });
  }

  getSurgeonOpeningsOfSurgeonByInterval(surgeonId: string, startDate: string, endDate: string): void {
    if (this.getSurgeonOpeningsOfSurgeonSubscription) {
      this.getSurgeonOpeningsOfSurgeonSubscription.unsubscribe();
    }

    this.getSurgeonOpeningsOfSurgeonSubscription = this.pythonApisService
      .getSurgeonOpeningsOfSurgeonByInterval(surgeonId, startDate, endDate)
      .subscribe((data) => {
        this.nextSurgeonOpeningsOfSurgeon = this.mergeFrontEndDataWithBackendData(data);
        this.initSurgeonOpeningsDates();
        this.createSlots();
      }, (error) => {
        this.errorService.handleError(error);
      });
  }

  getEstimatedDuration() {
    this.pythonApisService.getInterventionOverflowData(this.data.doctorAgenda._id).subscribe((data)=> {
      this.estimatedInterventionDuration = data;
    });
  }

  mergeFrontEndDataWithBackendData(backendData: any[]): any[] {
    for (const date of backendData) {
      if (new Date(date.date).getTime() === this.date.getTime()) {
        for (const room of date.rooms) {
          room.doctoragendas = this.rooms.find((elt) => String(elt.room._id) === String(room.room._id)).doctoragendas;
        }
      } else {
        for (const room of date.rooms) {
          const doctorAgendasOfDateAndRoom = this.data.frontEndDoctorAgendas.filter((elt) => String(elt.room) === String(room.room._id) && new Date(elt.date).getTime() === new Date(date.date).getTime());

          room.doctoragendas.push(...doctorAgendasOfDateAndRoom);          
        }
      }
    }
    
    return backendData;
  }

  modifyInformations(selectedIndex: number): void {
    this.date = this.availableSlots[selectedIndex - 1].Date;
    this.data.doctorAgenda.room = this.availableSlots[selectedIndex - 1].RoomId;
    this.startTime = null;
    setTimeout(() => {
      this.setStartTime(this.availableSlots[selectedIndex - 1].StartTime);
    })
    this.makeForm();

    for (let i = 0; i < this.nextSurgeonOpeningsOfSurgeon.length; i++) {
      for (let j = 0; j < this.nextSurgeonOpeningsOfSurgeon[i].rooms.length; j++) {
        let dateTmp1 = new Date(this.nextSurgeonOpeningsOfSurgeon[i].date);
        let dateTmp2 = new Date(this.date);
        if ((this.nextSurgeonOpeningsOfSurgeon[i].rooms[j].room._id === this.data.doctorAgenda.room)
                                            && (dateTmp1.toISOString() === dateTmp2.toISOString())) {
          this.selectedIndex = i;
          break;
        }
      }
    }
  }

  addOtherRoomsAvailability(currentRoom, date, vacStartTime, vacEndTime): any[] {
    let doctorAgendasDay: any[] = [];    
    const numRooms: number = this.roomsWithoutOverflow.length;
    const numRoomsCurrentRoom: number = currentRoom.room.length;

    for (let i = 0; i < numRoomsCurrentRoom; i++) {
      if (currentRoom.room[i].endTime >= vacStartTime && currentRoom.room[i].startTime <= vacEndTime) {
        doctorAgendasDay.push(currentRoom.room[i]);
      }
    }

    for (let i = 0; i < numRooms; i++) {
      const currentRoomTmp: any = this.roomsWithoutOverflow[i];

      if (currentRoomTmp.date === date && currentRoomTmp.room && currentRoomTmp.roomId !== currentRoom.roomId) {
        for (let j = 0; j < currentRoomTmp.room.length; j++) {
            /* select surgeon interventions in others rooms during the vacation time */
            if (currentRoomTmp.room[j].endTime >= vacStartTime && currentRoomTmp.room[j].startTime <= vacEndTime 
                                    && currentRoomTmp.room[j].surgeon._id === this.data.doctorAgenda.surgeon._id) {
              doctorAgendasDay.push(currentRoomTmp.room[j]);
            }
        }      
      }
    }
    return doctorAgendasDay.sort((a, b) => (a.startTime > b.startTime) ? 1 : (a.startTime < b.startTime) ? -1 : 0);
  }

  createOneAvailableRoom(dayOpeningsDate: any, room: any, overflow: boolean): void {
    let vacationHours: string;

    for (let i = 0; i < room.surgeonopening.length ; i++) {
      if (room.surgeonopening[i].surgeon._id === this.data.doctorAgenda.surgeon._id) {
        vacationHours = room.surgeonopening[i];
      }
    }
    
    let availableRoom = {
      date: dayOpeningsDate,
      room: room.doctoragendas, 
      roomId:room.room._id,
      roomNumber:room.room.roomNumber,
      vacationHours: vacationHours,
      overflow: overflow
    }
    this.roomsWithoutOverflow.push(availableRoom);
  }

  async createAvailableRooms(): Promise<any> {
    for (let i = 0; i < this.nextSurgeonOpeningsOfSurgeon.length; i++) {
      const dayOpenings = this.nextSurgeonOpeningsOfSurgeon[i];

      /* avoid current room */
      if (i === 0 && dayOpenings.rooms.length <= 1) {
        continue; 
      } 
      
      /* match available rooms (data) with surgeon rooms, and calculate the overflow adding the intervention */
      for (let j = 0; j < dayOpenings.rooms.length; j++) {
        const currentRoom = dayOpenings.rooms[j];
        const currentRoomOF = currentRoom.overflowInfos.overflowDuration;
        const AverageInterInterventionsTimeRoom = currentRoom.overflowInfos.AverageInterInterventionsTime;
        const sum = currentRoomOF + this.estimatedDuration + AverageInterInterventionsTimeRoom;
        this.AverageInterInterventionsTimeRoom = AverageInterInterventionsTimeRoom

        if (i === 0 && currentRoom.room._id === this.data.doctorAgenda.room) {
          continue;
        }

        if ( sum <= 0 || (currentRoomOF === null || currentRoomOF === undefined)) {
            this.createOneAvailableRoom(dayOpenings.date, dayOpenings.rooms[j], false);
        }
        else if (sum > 0 && sum <= 1800000) {
          this.createOneAvailableRoom(dayOpenings.date, dayOpenings.rooms[j], true);
        }
      } 
    }
  }

  findIntervals(vacStartTime: string, vacEndTime: string, AverageInterInterventionsTimeRoom:number, doctorAgendas: any[], index: number): number {
    if (doctorAgendas === undefined || doctorAgendas.length === 0) {
      return Date.parse(vacStartTime);
    }
    
    const avgInterInterventions= AverageInterInterventionsTimeRoom
    const currentId = this.data.doctorAgenda.surgeon._id;
    const doctoragendasDay: any[] = this.addOtherRoomsAvailability(this.roomsWithoutOverflow[index], this.roomsWithoutOverflow[index].date, vacStartTime, vacEndTime);

    if (Date.parse(doctoragendasDay[0].startTime) - Date.parse(vacStartTime) >= this.estimatedDuration) {
      return Date.parse(vacStartTime);
    }

    for (let i = 0; i < doctoragendasDay.length; i++) {
      if (doctoragendasDay[i].startTime >= vacStartTime && doctoragendasDay[i].endTime <= vacEndTime) {
        
        if (doctoragendasDay[i + 1] && Date.parse(doctoragendasDay[i + 1].startTime) - (Date.parse(doctoragendasDay[i].endTime) + avgInterInterventions) >= this.estimatedDuration) {
          return Date.parse(doctoragendasDay[i].endTime) + avgInterInterventions;
        }
      }
    }

    if (Date.parse(vacEndTime) - (Date.parse(doctoragendasDay[doctoragendasDay.length - 1].endTime) + avgInterInterventions) >= this.estimatedDuration) {
      return Date.parse(doctoragendasDay[doctoragendasDay.length - 1].endTime) + avgInterInterventions;
    }
  }

  async createSlots(): Promise<void> {
    const officialInterventionDuration = new Date(this.data.doctorAgenda.endTime).getTime() - new Date(this.data.doctorAgenda.startTime).getTime();

    this.estimatedDuration = this.estimatedInterventionDuration && officialInterventionDuration <= this.estimatedInterventionDuration.totalEstimatedDuration
                              ?  this.estimatedInterventionDuration.totalEstimatedDuration : officialInterventionDuration;

    await this.createAvailableRooms();

    const numRooms: number = this.roomsWithoutOverflow.length;

    for (let i = 0; i < numRooms; i++) {
        let newSlotStartTime: number;
        const currentRoom: any = this.roomsWithoutOverflow[i];

        newSlotStartTime = this.findIntervals(currentRoom.vacationHours.startTime, currentRoom.vacationHours.endTime,this.AverageInterInterventionsTimeRoom, currentRoom.room, i);

        if (newSlotStartTime !== undefined) {
          const newSlot = {
            Date: new Date(currentRoom.date),
            DateShort: this.finalFormatDate(currentRoom.date),
            RoomId: currentRoom.roomId,
            RoomNumber: currentRoom.roomNumber,
            Overflow: currentRoom.overflow,
            StartTime: this.modifyFormatTime(newSlotStartTime),
            StartTimeToDisplay: this.modifyFormatTimeHours(newSlotStartTime)
          }
          this.availableSlots.push(newSlot);
        } 
    }
    if (this.availableSlots.length === 0) {
      this.showNoSlotMessage = true;
    }
  }

  get getRoomsContainerWidth(): number {
    const numberOfRooms = this.nextSurgeonOpeningsOfSurgeon[this.selectedIndex].rooms.length;
    return (numberOfRooms * 384) + ((numberOfRooms - 1) * 10);
  }

  initSurgeonOpeningsDates(): void {
    this.surgeonOpeningsDates = this.nextSurgeonOpeningsOfSurgeon.map((elt) => {
      const date = new Date(elt.date);
      const dayName = this.titleCasePipe.transform(this.datePipe.transform(date, 'EEEE', 'UTC', 'fr-FR')).substring(0, 3);
      let str = `${dayName}. ${this.getDoubleDidgetValue(date.getDate())}/${this.getDoubleDidgetValue(date.getMonth() + 1)}`;
      return str;
    });
  }

  dateChangeHandler(event: any): void {
    this.selectedIndex = this.surgeonOpeningsDates.findIndex((e) => e == event);
  }

  getStartTime(): string {
    const d = new Date(this.data.doctorAgenda.startTime);
    d.setTime(d.getTime() + d.getTimezoneOffset() * 60 * 1000);
    return `${this.getDoubleDidgetValue(d.getHours())}:${this.getDoubleDidgetValue(d.getMinutes())}`;
  }

  modifyFormatTime(startTime: string | number): string {
    const d = new Date(startTime);
    d.setTime(d.getTime() + d.getTimezoneOffset() * 60 * 1000);
    return `${this.getDoubleDidgetValue(d.getHours())}:${this.getDoubleDidgetValue(d.getMinutes())}`;
  }

  modifyFormatTimeHours(startTime: string | number): string {
    const d = new Date(startTime);
    d.setTime(d.getTime() + d.getTimezoneOffset() * 60 * 1000);
    return `${this.getDoubleDidgetValue(d.getHours())} h ${this.getDoubleDidgetValue(d.getMinutes())}`;
  }

  getDoubleDidgetValue(value: number): string {
    return ('0' + value).slice(-2);
  }

  setStartTime(event): void {
    this.startTime = event;
    this.endTime = this.getEndTime();
  }

  getEndTime(): string {
    const interventionDuration = new Date(this.data.doctorAgenda.endTime).getTime() - new Date(this.data.doctorAgenda.startTime).getTime();
    const tmp = new Date(this.data.doctorAgenda.startTime);
    let date = new Date(Date.UTC(tmp.getFullYear(), tmp.getMonth(), tmp.getDate(), 0, 0, 0, 0));

    date.setUTCHours(Number(this.startTime.split(':')[0]));
    date.setUTCMinutes(Number(this.startTime.split(':')[1]));

    date = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000 + interventionDuration);
    return `${this.getDoubleDidgetValue(date.getHours())}:${this.getDoubleDidgetValue(date.getMinutes())}`;
  }

  surgeonOpeningClicked(dayIndex: number, roomIndex: number): void {
    this.formGroup.controls["date"].setValue(new Date(this.nextSurgeonOpeningsOfSurgeon[dayIndex].date));
    this.formGroup.controls["selectedRoom"].setValue(this.nextSurgeonOpeningsOfSurgeon[dayIndex].rooms[roomIndex].room._id);
  }

  isSelectedRoom(dayIndex: number, roomIndex: number): boolean {
    if (
        new Date(this.formGroup.controls["date"].value).getTime() === new Date(this.nextSurgeonOpeningsOfSurgeon[dayIndex].date).getTime()
        &&
        String(this.formGroup.controls["selectedRoom"].value) === String(this.nextSurgeonOpeningsOfSurgeon[dayIndex].rooms[roomIndex].room._id)
      ) {
      return true;
    }

    return false;
  }

  getDateAndTimeByDateAndTimeString(date: string, time: string): string {
    const tmp = new Date(date);
    let dateTmp = new Date(Date.UTC(tmp.getFullYear(), tmp.getMonth(), tmp.getDate(), 0, 0, 0, 0));

    dateTmp.setUTCHours(Number(time.split(':')[0]));
    dateTmp.setUTCMinutes(Number(time.split(':')[1]));

    return dateTmp.toISOString();
  }

  async moveIntervention(): Promise<void> {
    this.isSpinning = true;
    const startTime = this.startTime;
    const endTime = this.endTime;

    const tmp = new Date(this.formGroup.value.date);
    const date = new Date(Date.UTC(tmp.getFullYear(), tmp.getMonth(), tmp.getDate(), 0, 0, 0, 0));

    // Check if period is available
    const newIntervention: any = JSON.parse(JSON.stringify(this.data.doctorAgenda));
    
    newIntervention.room = this.formGroup.value.selectedRoom;
    newIntervention.date = new Date(date);
    newIntervention.startTime = new Date(date);
    newIntervention.endTime = new Date(date);


    newIntervention.startTime.setUTCHours(Number(startTime.split(':')[0]));
    newIntervention.startTime.setUTCMinutes(Number(startTime.split(':')[1]));

    newIntervention.endTime.setUTCHours(Number(endTime.split(':')[0]));

    newIntervention.endTime.setUTCMinutes(Number(endTime.split(':')[1]));
    
    const isPeriodAvailable = await this.checkIfPeriodIsAvailable(newIntervention);

    if (isPeriodAvailable) {
      this.dialogRef.close({status: 'refresh', doctorAgenda: newIntervention});
    } else {
      this.showErrorMessage = true;
    }
    
    this.isSpinning = false;
  }

  checkIfPeriodIsAvailable(newIntervention): Promise<boolean> {
    return new Promise((resolve, reject) => {
      // resolve(false);
      if (new Date(newIntervention.date).getTime() === new Date(this.data.doctorAgenda.date).getTime()) {
        // If new time is in the same day
        const destinationRoomDoctorAgendas = this.rooms
          .find((elt) => String(elt.room._id) === String(newIntervention.room)).doctoragendas;

        resolve(this.checkIfPeriodIsAvailableInArrayOfDoctorAgendas(destinationRoomDoctorAgendas, newIntervention));
      } else {
        // If new time is not the same day
        // Check if period available in frontData
        const destinationDayFrontEndDoctorAgendas = this.data.frontEndDoctorAgendas
          .filter((elt) => new Date(elt.date).getTime() === new Date(newIntervention.date).getTime());

        const isPeriodAvailable = this.checkIfPeriodIsAvailableInArrayOfDoctorAgendas(destinationDayFrontEndDoctorAgendas, newIntervention);

        if (!isPeriodAvailable) {
          // if period is not available we show error message
          resolve(false);
        } else {
          // else we check in the backend
          if (this.checkIfPeriodIsAvailableSubscription) {
            this.checkIfPeriodIsAvailableSubscription.unsubscribe();
          }

          this.checkIfPeriodIsAvailableSubscription = this.doctorAgendasService.checkIfPeriodIsAvailable(
            String(newIntervention.room),
            new Date(newIntervention.startTime).toISOString(),
            new Date(newIntervention.endTime).toISOString(),
          )
            .subscribe((response) => {
              resolve(response.isPeriodAvailable);
            }, (error) => {
              reject(error);
              console.error(error);
            });
        }
      }
    });
  }

  checkIfPeriodIsAvailableInArrayOfDoctorAgendas(doctorAgendas: any[], newIntervention: any): boolean {

    const startTime = new Date(newIntervention.startTime);
    const endTime = new Date(newIntervention.endTime);

    for (const da of doctorAgendas) {
      const DAStartTime = new Date(da.startTime);
      const DAEndTime = new Date(da.endTime);

      if (String(da._id) !== String(newIntervention._id)) {
        if (DAStartTime <= startTime && DAEndTime > startTime) {
          return false;
        } else if (DAStartTime < endTime && DAEndTime >= endTime) {
          return false;
        } else if (DAStartTime >= startTime && DAEndTime <= endTime) {
          return false;
        }
      }
    }

    return true;
  }

  close(): void {
    this.dialogRef.close(null);
  }
  
  getRoomStartTime(room:Room):number{
		const date:Date = new Date(this.date);
    const currentDay:number = date.getUTCDay()+1;
		const horaire:any = room.horaire.find( horaire=> horaire.day === currentDay);
		if(horaire){
			const roomStartDate:Date = new Date(horaire.start);
			return roomStartDate.getUTCHours()-1;
		}
		return 7;
	}

	getRoomEndTime(room:Room):number{
		const date:Date = new Date(this.date);
    const currentDay:number = date.getUTCDay()+1;
		const horaire:any = room.horaire.find( horaire=> horaire.day === currentDay);
		if(horaire){
			const roomEndDate:Date = new Date(horaire.end);
			const endHour:number = roomEndDate.getUTCHours()+1;
			return 6 < endHour && endHour < 23 ? endHour : 23;
		}
		return 20;
	}

  getDateOfLastDayOfNextWeek(date: Date): Date {
    switch (date.getDay()) {
      case 0:
        return new Date(date.getTime() + (5 * 24 * 60 * 60 * 1000));
      case 1:
        return new Date(date.getTime() + (11 * 24 * 60 * 60 * 1000));
      case 2:
        return new Date(date.getTime() + (10 * 24 * 60 * 60 * 1000));
      case 3:
        return new Date(date.getTime() + (9 * 24 * 60 * 60 * 1000));
      case 4:
        return new Date(date.getTime() + (8 * 24 * 60 * 60 * 1000));
      case 5:
        return new Date(date.getTime() + (7 * 24 * 60 * 60 * 1000));
      case 6:
        return new Date(date.getTime() + (6 * 24 * 60 * 60 * 1000));
    }
  }

  finalFormatDate(inputDate) {
    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
}
}