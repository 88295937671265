import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Subject, Subscription } from "rxjs";
import { diminutifLength } from "../../const/glabals.const";
import { Rating } from "../../models/rating.model";
import { Specialty } from "../../models/specialty.model";
import { HospitalService } from "../../services/hospital.service";
import { RankingService } from "../../services/ranking.service";
import { SpecialtyService } from "../../services/specialty.service";
import { UserService } from "../../services/user.service";
import { Role } from "../../models/role.model";
import { Profile } from '../../models/profile.model';
import { ProfileService } from '../../services/profile.service';
import { ErrorService } from '../../services/error.service';
import { Hospital } from "../../models/hospital.model";
import { SURGEON } from '../../const/glabals.const';
import { take, takeUntil } from "rxjs/operators";

const MSG_COEF_VALIDATION_ERROR =
  "Il faut que la somme des pourcentages soit égale à 100";
const MSG_NURSE_COUNT_ERROR = "Il vous faut définir tous les besoins";

@Component({
  selector: "app-add-edit-specialty",
  templateUrl: "./add-edit-specialty.component.html",
  styleUrls: ["./add-edit-specialty.component.scss"],
})
export class AddEditSpecialtyComponent implements OnInit, OnDestroy {
  public errorMessage: string;
  public openType: string;
  public specialty: Specialty;
  public sharedSpecialties: Specialty[];
  public formGroup: FormGroup;
  public distribution: {
    specialty: Specialty;
    coefficient: number;
    isActive: boolean;
  }[] = [];
  public hospitalSpecialties: Specialty[];
  public priorities: number[];
  public roles: any;
  public isSoftRestore: boolean;
  public triggerChangesSubject: Subject<void> = new Subject<void>();
  public chosenDiminutif: string;
  public diminutifMessage: string;
  private ratingServiceSubscription: Subscription;
  private newSpecialtySubscription: Subscription;
  public types = [
    {
      label: 'Bloc',
      value: 'bloc',
      isChecked: false,
      isDisabled: false
    },
    {
      label: 'Consultation',
      value: 'consultation',
      isChecked: false,
      isDisabled: false
    },
  ];

  public progressionElements = [
    {
      label: 'Circulante',
      value: 'circulante',
      isChecked: false,
      isDisabled: false
    },
    {
      label: 'Aide Opératoire',
      value: 'aide-op',
      isChecked: false,
      isDisabled: false
    },
  ];

  public emptyRatingSystem = [ { level: 1, timeMax: 0 } ]
  public defaultRatingSystems: Rating[] = [];
  public maxLevel = 7

  public osComponentOptions = {
    className: "os-theme-dark custom-sidebar",
    nativeScrollbarsOverlaid: {
      showNativeScrollbars: true,
    },
    overflowBehavior: {
      x: "hidden",
    },
    paddingAbsolute: true,
    scrollbars: {
      autoHide: "never",
      clickScrolling: true,
    },
  };

  public ratingSystemFormGroup: FormGroup;
  public originalRatingSystem: any;
  public formattedRatingSystem: {
    levels: number[];
    ratingCirculanteLevel: {
      level: number;
      timeMax: number;
    }[];
    ratingAideOpLevel: {
      level: number;
      timeMax: number;
    }[];
    ratingAideSoignanteLevel: {
      level: number;
      timeMax: number;
    }[];
  } = {
    levels: [],
    ratingCirculanteLevel: [],
    ratingAideOpLevel: [],
    ratingAideSoignanteLevel: [],
  };
  public isRatingSystemLoading: boolean = false;

  public localRatingSystem: Rating;

  public isLoading = true;
  public isSpinning: boolean = false;
  public showDiminutifErrorMessage = false;

  public hospitals: any[];

  public doesHospitalHaveAnesthDetailsOption: boolean;
  public doesHospitalHaveNurseDetailsOption: boolean;
  public doesHospitalHaveProgramsDataPipelineOption: boolean;

  private specialtiesSubscription: Subscription;
  private defaultRatingSystemsSubscription: Subscription;
  private getSharedSpecialtiesSubscription: Subscription;
  private changeSpecialtySubscription: Subscription;
  private updateSpecialtySubscription: Subscription;
  private createSpecialtyRatingSubscription: Subscription;
  private ratingSubscription: Subscription;
  private formChangeSubscription: Subscription;

  private profileCreationSubscription: Subscription;

  public diminutifLength = diminutifLength;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddEditSpecialtyComponent>,
    private specialtyService: SpecialtyService,
    private formBuilder: FormBuilder,
    private ratingService: RankingService,
    private hospitalService: HospitalService,
    private userService: UserService,
    private profileService: ProfileService,
    private errorService: ErrorService,
  ) {
    this.hospitals = userService.getSelectedHospitals();
    this.doesHospitalHaveAnesthDetailsOption = this.hospitalService.doesHospitalHaveAnesthDetailsOption();
    this.doesHospitalHaveNurseDetailsOption = this.hospitalService.doesHospitalHaveNurseDetailsOption();
    this.doesHospitalHaveProgramsDataPipelineOption = this.hospitalService.doesHospitalHaveProgramsDataPipelineOption();
    this.openType = this.data.openType;
    this.roles = this.data.roles
    if (this.openType === "EDIT") {
      this.specialty = this.data.specialty;
    }
    this.roles.forEach((role) => {
      if (this.openType === "EDIT") {
        const specialtyNeed: any = this.specialty.needs.find((need) => String(need.role._id) === String(role._id))
        if (specialtyNeed) {
          if (specialtyNeed.ratings && specialtyNeed.ratings.length > 0) {
            role.ratings = specialtyNeed.ratings
            if (specialtyNeed.ratings.isActive) {
              role.progression = true;
            } else {
              role.progression = false;
            }
          } else {
            role.progression = false;
          }
          role.selected = true;
          role.need = specialtyNeed.need;
          if (!role.progression)
          {
            let levels = [];
            for (let index = 1; index <= this.maxLevel; index++) {
              levels.push({
                level: index,
                timeMax: 0
              });
            }
            role.ratings = levels;
          }
        } else {
          role.progression = false;
          role.selected = false;
          role.need = 0;
          let levels = [];
          for (let index = 1; index <= this.maxLevel; index++) {
            levels.push({
              level: index,
              timeMax: 0
            });
          }
          role.ratings = levels;
        }
      } else {
        role.progression = false;
        role.selected = false;
        role.need = 0;
        let levels = [];
        for (let index = 1; index <= this.maxLevel; index++) {
          levels.push({
            level: index,
            timeMax: 0
          });
        }
        role.ratings = levels;
      }
    })
  }

  get getSelectedRoles() {
    return this.roles.filter((role) => role.selected)
  }

  get showRatingConfiguration(): boolean {
    return (
      this.specialty && this.doesHospitalHaveNurseDetailsOption &&
      this.specialty.name !== "Urgences"
    );
  }

  get showDistributionConfiguration(): boolean {
    return this.specialty && this.specialty.name === "Urgences";
  }

  get isSaveButtonDisabled(): boolean {
    if (this.specialty && this.specialty.name === "Urgences") {
      let sum = 0;
      this.distribution.forEach((dist) => {
        if (dist.isActive) {
          sum += dist.coefficient;
        }
      });
      if (sum !== 100) {
        this.errorMessage = MSG_COEF_VALIDATION_ERROR;
        return true;
      }
    }

    this.errorMessage = null;
    return false;
  }

  ngOnInit() {
    this.initHospitalSpecialties();
    this.setPriorities();
    this.createForm();
    if (this.openType === "ADD") {
      this.getSharedSpecialties();
    } else {
      if (this.specialty.name === "Urgences") {
        this.initDistribution();
      }
    }
    this.onFormChanges();
  }

  initDefaultRatings() {
    if (this.defaultRatingSystemsSubscription) {
      this.defaultRatingSystemsSubscription.unsubscribe();
    }

    this.defaultRatingSystemsSubscription = this.ratingService.getDefaultRatingsBySpecialtyName(this.specialty.name)
    .subscribe((data: Rating[]) => {
      this.defaultRatingSystems = data;
      this.roles.forEach(role => {
        this.defaultRatingSystems.forEach((defaultRatingSystem:any) => {
          if (String(role.name) === String(defaultRatingSystem.roleName)) {
              role.ratings = defaultRatingSystem.levels;
            }
        });
        if (!role.ratings || role.ratings.length === 0
              || !this.defaultRatingSystems || !this.defaultRatingSystems.length) {
          let levels = []
          for (let index = 1; index <= this.maxLevel; index++) {
            levels.push({
              level: index,
              timeMax: 0
            })
          }
          role.ratings = levels
        }
      });
      this.isRatingSystemLoading = false
    })
  }

  initHospitalSpecialties(): void {
    this.hospitalSpecialties = this.data.hospitalSpecialties.filter(
      (spe) => ["Urgences", "DEFAULT_"].indexOf(spe.name) === -1
    );
  }

  initDistribution(): void {
    // if we are here means this.specialty is Urgences
    this.distribution = [];
    this.hospitalSpecialties.forEach((spe) => {
      let found;
      if (this.specialty.distribution) {
        found = this.specialty.distribution.find(
          (s) => String(s.specialty._id) === String(spe._id)
        );
      }
      if (found) {
        this.distribution.push({
          specialty: spe,
          isActive: true,
          coefficient: found.coefficient * 100,
        });
      } else {
        this.distribution.push({
          specialty: spe,
          isActive: false,
          coefficient: 0,
        });
      }
    });
  }

  getSharedSpecialties(): void {
    if(this.getSharedSpecialtiesSubscription) this.getSharedSpecialtiesSubscription.unsubscribe();
    this.getSharedSpecialtiesSubscription = this.specialtyService
      .getSharedSpecialties()
      .subscribe((specialties: Specialty[]) => {
        this.sharedSpecialties = specialties;
        this.isLoading = false;
      });
  }

  createForm() {
    const formFields = {
      name: [this.specialty ? this.specialty.name : "", [Validators.required]],
      diminutif: [this.specialty && this.specialty.diminutif ? this.specialty.diminutif : "", [Validators.required]],
      priority: [
        this.specialty ? this.specialty.priority : null,
        [Validators.required],
      ],
      image: [this.specialty ? this.specialty.image : ""],
      type: [
        this.specialty ? this.specialty.type : [],
        [],
      ],
      defaultSurgeonCreation: [false],
    };
    
    this.formGroup = this.formBuilder.group(formFields);
  }

  setPriorities() {
    if(this.specialtiesSubscription) this.specialtiesSubscription.unsubscribe();
    this.specialtiesSubscription = this.specialtyService
      .getAllSpecialties(true)
      .subscribe((el) => {
        this.priorities = Array.from(Array(el.length + 1), (_, i) => i + 1);

        if (this.openType === "EDIT") {
          if (!this.priorities.includes(this.specialty.priority)) {
            this.priorities.push(this.specialty.priority);
          }
        }
      });
  }

  onSpecialtyChange(): void {
    if(this.changeSpecialtySubscription) this.changeSpecialtySubscription.unsubscribe();
    this.chosenDiminutif='';
    if (this.specialty) {
      this.changeSpecialtySubscription = this.specialtyService
        .isSpecialityExist(this.specialty.name)
        .subscribe((res) => {
          if (res && res.length > 0) {
            const objIndex = this.sharedSpecialties.findIndex((obj => obj.name == res[0].name));
            if (objIndex != -1) {
              this.sharedSpecialties[objIndex] = res[0];
              this.specialty = res[0]; 
            }
            this.isSoftRestore = true;
          } else {
            this.isSoftRestore = false;
          }
          this.initSpeciality();
        });
    }
  }

  initRating(data) {
    if (data) {
      this.originalRatingSystem = data;
      const maxLevelsCount = Math.max(
        data.ratingCirculanteLevel && data.ratingCirculanteLevel.length,
        data.ratingAideOpLevel && data.ratingAideOpLevel.length
      );

      let levels = Array.from(
        Array(maxLevelsCount || []),
        (element, index) => index + 1
      );
      levels.shift();
      this.formattedRatingSystem = {
        levels,
        ratingCirculanteLevel: data.ratingCirculanteLevel,
        ratingAideOpLevel: data.ratingAideOpLevel,
        ratingAideSoignanteLevel: data.ratingAideSoignanteLevel,
      };
    } else {
      this.formattedRatingSystem = {
        levels: [],
        ratingCirculanteLevel: [],
        ratingAideOpLevel: [],
        ratingAideSoignanteLevel: [],
      };
    }

    this.initProgressionFormCtrl();
    this.isRatingSystemLoading = false;
  }

  initProgressionFormCtrl(): void {
    const progressionValue = [];
    if (this.formattedRatingSystem.ratingCirculanteLevel && this.formattedRatingSystem.ratingCirculanteLevel.length > 1) {
      progressionValue.push('circulante');
    }

    if (this.formattedRatingSystem.ratingAideOpLevel && this.formattedRatingSystem.ratingAideOpLevel.length > 1) {
      progressionValue.push('aide-op');
    }

    this.formGroup.controls['progression'] && this.formGroup.controls['progression'].setValue(progressionValue);
    this.triggerChangesSubject.next();
  }

  initSpeciality(): void {
    this.formGroup.controls["name"].setValue(this.specialty.name);
    this.formGroup.controls["type"].setValue(this.specialty.type);
    this.formGroup.controls["defaultSurgeonCreation"].setValue(true);
    this.triggerChangesSubject.next();
    if (!this.priorities.includes(this.specialty.priority)) {
      this.priorities.push(this.specialty.priority);
    }
    this.formGroup.controls["priority"].setValue(this.specialty.priority);
    this.formGroup.controls["image"].setValue(this.specialty.image);

    this.roles.forEach(role => {
      const need = this.specialty.needs.find((need) => {
        return ((this.isSoftRestore ? String(role._id) : String(role.name)) === String(need.role));
      });
      if (need) {
        role.selected = true;
        role.need = need.need;
      } else {
        role.selected = false;
        role.need = 0;
      }
    });
    if (this.specialty.name === "Urgences") {
      this.initDistribution();
    } else if (this.isSoftRestore) {
      this.initDefaultRatings();
    } else {
      if(this.ratingSubscription) this.ratingSubscription.unsubscribe();
      (this.isRatingSystemLoading = true),
      this.ratingSubscription = this.ratingService
          .getRatingsBySpecialtyId(this.specialty._id)
          .subscribe(data => {
            const obj:any = data.find((rating:any) => String(rating.ratings[0].specialty._id) === String(this.specialty._id));
            if (obj) {
              obj.ratings.forEach(rating => {
                let speRole:any = this.specialty.needs.find((need) => String(need.role._id) === String(rating.role._id))
                speRole.ratings = rating.levels;
                speRole.ratings.isActive = rating.isActive
              });
            }

            this.initDefaultRatings();
          });
    }
  }

  save(): void {
    this.isSpinning = true;
    if (this.openType === "EDIT") {
      this.saveEditedSpecialty();
    } else {
      if (this.isSoftRestore) {  
        this.saveEditedSpecialty()
      } else {
        this.addNewSpecialty();
      }
    }
  }

  saveEditedSpecialty(): void {
    let spec;
    let ratingSystems = [];
    let selectedRoles = this.getSelectedRoles
    spec = {
      _id: this.specialty._id,
      name: this.specialty.name,
      diminutif: this.chosenDiminutif,
      content: this.specialty.content,
      image: this.specialty.image,
      type: this.formGroup.controls["type"].value,
      priority: this.formGroup.controls["priority"].value,
      needs: selectedRoles.map((role) => ({
        role: role._id,
        need: role.need
      }))
    };

    const createDefaultSurgeon = this.formGroup.controls["defaultSurgeonCreation"].value;

    if (this.isSoftRestore) {
      spec["deletedAt"] = null //soft restore
    }

    if (this.specialty.name === "Urgences") {
      spec.distribution = this.distribution
        .filter((dist) => dist.isActive)
        .map((dist) => {
          return {
            specialty: dist.specialty._id,
            coefficient: dist.coefficient / 100,
          };
        });
    } else {
      selectedRoles.forEach(role => {
        if (role.ratings && role.ratings.find((rating) => parseInt(rating.level) === 0)) {
          const index = role.ratings.findIndex((rating) => parseInt(rating.level) === 0)
          role.ratings.splice(index, 1)
        }
        let ratingSystem = {
          specialty: this.specialty._id,
          hospital: this.specialty.hospital,
          levels: role.ratings,
          isActive: role.progression,
          role: role._id
        }
        if (ratingSystem.levels) {
          delete ratingSystem.levels.isActive
        } else {
          ratingSystem.levels = []
        }
        ratingSystems.push(ratingSystem)
      });
    }

    this.updateSpecialtyAndRating(spec, ratingSystems, createDefaultSurgeon);
  }

  updateSpecialtyAndRating(specialty: Specialty, ratingSystems: any[], createDefaultSurgeon?: boolean) {
    const profile: Profile = {
      firstName: "Dr",
      lastName: specialty.name,
      surgeonNeeds: null,
      specialties: [specialty],
      residency: "Titulaire",
      startingDate: new Date(),
      position: SURGEON,
      hospitals: this.hospitals,
      phoneNumber: null,
      profilePic: undefined,
      isDefaultSpecialtySurgeon: true
    }
    if(this.updateSpecialtySubscription) this.updateSpecialtySubscription.unsubscribe();
    this.updateSpecialtySubscription = this.specialtyService.updateSpecialty(specialty).subscribe((res) => {
      if (specialty.name !== "Urgences") {
        if (ratingSystems) {
          // Update rating system
          this.ratingService
            .addUpdateRatingSystems(ratingSystems)
            .subscribe((res2) => {
              this.isSpinning = false;
              this.dialogRef.close("refresh");
            });
        } else {
          this.isSpinning = false;
          this.dialogRef.close("refresh");  
        }
      } else {
        this.isSpinning = false;
        this.dialogRef.close("refresh");
      }
      if (createDefaultSurgeon)
        this.createProfile(profile);
    });
  }

  getModifiedRatingAideOpLevel(): any[] {
    const ratings = [];
    const tempsRatings = [...this.formattedRatingSystem.ratingAideOpLevel];
    const lastIndex = tempsRatings.length - 1;
    tempsRatings.pop();
    tempsRatings.forEach((level) => {
      ratings.push({
        level: level.level,
        timeMax: Number(
          this.ratingSystemFormGroup.controls[`aide${level.level + 1}`].value
        ),
      });
    });
    ratings.push({
      level: this.formattedRatingSystem.ratingAideOpLevel[lastIndex].level,
      timeMax: Number(
        this.formattedRatingSystem.ratingAideOpLevel[lastIndex].timeMax
      ),
    });
    return ratings;
  }

  getModifiedRatingCirculanteLevel(): any[] {
    const ratings = [];
    const tempsRatings = [...this.formattedRatingSystem.ratingCirculanteLevel];
    const lastIndex = tempsRatings.length - 1;
    tempsRatings.pop();
    tempsRatings.forEach((level) => {
      ratings.push({
        level: level.level,
        timeMax: Number(
          this.ratingSystemFormGroup.controls[`circ${level.level + 1}`].value
        ),
      });
    });
    ratings.push({
      level: this.formattedRatingSystem.ratingCirculanteLevel[lastIndex].level,
      timeMax: Number(
        this.formattedRatingSystem.ratingCirculanteLevel[lastIndex].timeMax
      ),
    });
    return ratings;
  }

  addNewSpecialty(): void {
    let spec;
    let ratingSystems = [];
    let selectedRoles = this.getSelectedRoles

    const createDefaultSurgeon = this.formGroup.controls["defaultSurgeonCreation"].value;
    
    spec = {
      name: this.formGroup.controls["name"].value,
      diminutif: this.chosenDiminutif,
      content: this.formGroup.controls["name"].value,
      priority: this.formGroup.controls["priority"].value,
      type: this.formGroup.controls["type"].value,
      image: this.formGroup.controls["image"].value,
      needs: selectedRoles.map((role) => ({
        role: role._id,
        need: role.need
      }))
    };

    if (this.specialty.name === "Urgences") {
      spec.distribution = this.distribution
        .filter((dist) => dist.isActive)
        .map((dist) => {
          return {
            specialty: dist.specialty._id,
            coefficient: dist.coefficient / 100,
          };
        });
    } else {
      selectedRoles.forEach(role => {
        if (role.ratings.find((rating) => parseInt(rating.level) === 0)) {
          const index = role.ratings.findIndex((rating) => parseInt(rating.level) === 0)
          role.ratings.splice(index, 1)
        }
        let ratingSystem = {
          specialty: this.specialty._id,
          hospital: this.specialty.hospital,
          levels: role.ratings,
          isActive: role.progression,
          role: role._id
        }
        if (ratingSystem.levels) {
          delete ratingSystem.levels.isActive
        } else {
          ratingSystem.levels = []
        }
        ratingSystems.push(ratingSystem)
      });
    }

    this.createSpecialtyAndRating(spec, ratingSystems, createDefaultSurgeon);
  }

  createSpecialtyAndRating(specialty: Specialty, ratingSystems: any, createDefaultSurgeon: boolean) {
    const profile: Profile = {
      firstName: "Dr",
      lastName: specialty.name,
      surgeonNeeds: null,
      specialties: null,
      residency: "Titulaire",
      startingDate: new Date(),
      position: SURGEON,
      hospitals: this.hospitals,
      phoneNumber: null,
      profilePic: undefined,
      isDefaultSpecialtySurgeon: true
    }

    if(this.createSpecialtyRatingSubscription) this.createSpecialtyRatingSubscription.unsubscribe();
    this.createSpecialtyRatingSubscription = this.specialtyService.addSpecialty(specialty).subscribe((res) => {
      profile.specialties = [res];
      if (createDefaultSurgeon)
        this.createProfile(profile);
      if (specialty.name !== "Urgences") {
        if (ratingSystems) {
          if(this.ratingServiceSubscription) this.ratingServiceSubscription.unsubscribe();
          // Create rating system
          ratingSystems.forEach(ratingSystem => {
            ratingSystem.specialty = res._id
            ratingSystem.hospital = res.hospital
          });
          this.ratingServiceSubscription = this.ratingService
            .addUpdateRatingSystems(ratingSystems)
            .subscribe((res2) => {
              this.isSpinning = false;
              this.dialogRef.close("refresh");
            });
        } else {
          this.isSpinning = false;
          this.dialogRef.close("refresh");
        }
      } else {
        this.isSpinning = false;
        this.dialogRef.close("refresh");
      }
    });
    this.newSpecialtySubscription = this.specialtyService.isSpecialityExist(specialty.name)
        .subscribe((res) => {
        if (res && res.length > 0) {
            const objIndex = this.sharedSpecialties.findIndex((obj => obj.name == res[0].name));
            if (objIndex != -1) {
              this.sharedSpecialties[objIndex] = res[0];
              this.specialty = res[0]; 
            }
            this.isSoftRestore = true;
        } else {
            this.isSoftRestore = false;
          }
        this.initSpeciality();
    });
  }

  createProfile(profile: Profile): void {
    if(this.profileCreationSubscription) this.profileCreationSubscription.unsubscribe();
    this.profileCreationSubscription = this.profileService.createDefaultSpecialtySurgeon({profile: profile}, profile.specialties[0]._id).subscribe((result) => {
    }, error => this.errorService.handleError(error));
  }

  onFormChanges(){
    if(this.formChangeSubscription) this.formChangeSubscription.unsubscribe();
    this.formChangeSubscription = this.formGroup.get('diminutif').valueChanges
    .subscribe((value: string) => {
      //check if diminutif is exactly diminutifLength (3) letters long
      if (value && value.length === diminutifLength) {
        if (/^[a-zA-Z]+$/.test(value)) {       //check if diminutif contains only letters
          value != value.toUpperCase() ? this.formGroup.get('diminutif').setValue(value.toUpperCase()) : null // check if string is already in upperCase else set it to upperCase (conditin to prevent infinite loop)
          this.chosenDiminutif = value.toUpperCase();
          this.showDiminutifErrorMessage = false;
          this.diminutifMessage = '';
        } else {
          this.diminutifMessage ='ne doit contenir que des lettres'
          this.showDiminutifErrorMessage = true;
        }
      }

      //check if diminutif is > diminutifLength (3) letters long
      else if (value && value.length > diminutifLength) {
        this.formGroup.get('diminutif').setValue(value.substring(0, diminutifLength)); //substring to prevent user from adding 4th character
        this.chosenDiminutif = value.substring(0, diminutifLength).toUpperCase();
        if (!(/^[a-zA-Z]+$/.test(value))) {
          this.diminutifMessage ='ne doit contenir que des lettres'
          this.showDiminutifErrorMessage = true;
        }
      }

      //check if diminutif is < diminutifLength (3) letters long
      else if (value && value.length < diminutifLength && value.length !== 0) {
        if (/^[a-zA-Z]+$/.test(value)) {
          value != value.toUpperCase() ? this.formGroup.get('diminutif').setValue(value.toUpperCase()) : null
          this.chosenDiminutif = '';
          this.showDiminutifErrorMessage = true;
          this.diminutifMessage = 'doit être de longueur ' + diminutifLength;
        } else {
          this.diminutifMessage ='ne doit contenir que des lettres et doit être de longueur ' + diminutifLength;
          this.showDiminutifErrorMessage = true;
        }
       
      //check if diminutif is 0 letters long
      } else if (!value || (value && value.length) === 0) {
        this.chosenDiminutif = '';
        this.showDiminutifErrorMessage = false;
      }  
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    if (this.specialtiesSubscription) {
      this.specialtiesSubscription.unsubscribe();
    }

    if (this.profileCreationSubscription) {
      this.profileCreationSubscription.unsubscribe();
    }

    if (this.defaultRatingSystemsSubscription) {
      this.defaultRatingSystemsSubscription.unsubscribe();
    }

    if (this.changeSpecialtySubscription) {
      this.changeSpecialtySubscription.unsubscribe();
    }

    if (this.ratingServiceSubscription) {
      this.ratingServiceSubscription.unsubscribe();
    }

    if (this.ratingSubscription) {
      this.ratingSubscription.unsubscribe();
    }

    if (this.getSharedSpecialtiesSubscription) {
      this.getSharedSpecialtiesSubscription.unsubscribe();
    }

    if (this.updateSpecialtySubscription) {
      this.updateSpecialtySubscription.unsubscribe();
    }

    if (this.createSpecialtyRatingSubscription) {
      this.createSpecialtyRatingSubscription.unsubscribe();
    }

    if (this.newSpecialtySubscription) {
      this.newSpecialtySubscription.unsubscribe();
    }

    if (this.formChangeSubscription) {
      this.formChangeSubscription.unsubscribe();
    }
  }

}
