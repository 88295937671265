import {ApplicationRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, OnDestroy} from '@angular/core';
import {MatDialog, MatMenuTrigger} from '@angular/material';
import {AddVacationPopupComponent} from '../add-vacation-popup/add-vacation-popup.component';
import {setTypeAttributeArray, sortRoomsByPriority, toPascalCase} from '../../utils/cross-functions';
import {getCommonDayNumber} from '../../utils/duplication.checker';
import { UserService } from '../../services/user.service';
import { Room } from '../../models/room.model';
import { ToastService } from '../../services/toast.service';
import {PopupManagerService} from "../../services/popup-manager.service";
import { OverlayScrollbarsComponent } from 'overlayscrollbars-ngx';
import { InterventionPopupComponent } from '../intervention-popup/intervention-popup.component';
import { Subject } from 'rxjs';
import { DoctorAgenda } from '../../models/doctor-agenda.model';
import { InterventionDetailsPopupComponent } from '../intervention-details-popup/intervention-details-popup.component';
import * as moment from 'moment';
import { HoursPopupComponent } from './hours-popup/hours-popup.component';
import { MoveInterventionPopupComponent } from './move-intervention-popup/move-intervention-popup.component';
import { HospitalService } from '../../services/hospital.service';
import { Specialty } from '../../models/specialty.model';
import { SurgeonOpeningsService } from '../../services/surgeon-openings.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { HrsuiteService } from 'src/app/hrsuite/hrsuite.service';
import { Subscription } from "rxjs";
import { ProfileService } from '../../services/profile.service';
import { ErrorService } from '../../services/error.service';
import { SURGEON } from '../../const/glabals.const';

@Component({
  selector: 'app-feed-all-rooms-vue',
  templateUrl: './feed-all-rooms-vue.component.html',
  styleUrls: ['./feed-all-rooms-vue.component.scss']
})
export class FeedAllRoomsVueComponent implements OnInit, OnChanges, OnDestroy {
  public showData: boolean = false;
  @Input() roomsData: any[];
  @Input() roomsOpenings: any[];
  @Input() isConfigMode : boolean ;
  @Input() isOnlyHist : boolean ;
  @Input() isDragMode : boolean ;
  @Input() isConfigAnesth : boolean;
  @Input() selectedDay = 0;
  @Input() date: string;
  @Input() notifyAnesth : boolean ;
  @Input() showRoomsOverflow: boolean = false;
  @Input() showIsDifferentSurgeons: boolean = false;
  @Input() enableScrolling: boolean = true;
  @Input() isVacation: boolean = true;
  @Input() onDataChangeSubject: Subject<void>;
  @Input() newDoctorAgendas: any[] = [];
  @Input() updatedDoctorAgendas: any[] = [];
  @Input() perInterventionView : boolean;
  @Input() perVacationView : boolean ;
  @Input() tvoConfigurationView : boolean ;
  @Input() needConfigurationView : boolean = false;
  @Input() anestNeedConfigurationView : boolean = false;
  @Input() showMenu: boolean = false;
  @Input() isVacationModes: boolean = false;
  @Input() isEditVacationMode: boolean = false;
  @Input() canSurgeonSelect: boolean = false;
  @Input() showRealizedMode: boolean = false;

  @Output() OnItemRemove = new EventEmitter<any>();
  @Output() OnRoomClose = new EventEmitter<any>();
  @Output() updateFrontEffectifNeeds = new EventEmitter<any>();
  @Output() onChange = new EventEmitter<true>();
  @Output() onRoomClick = new EventEmitter<any>();
  @Output() OnAddVacationClose = new EventEmitter<any>();
  @Output() OnEditVacationClose = new EventEmitter<any>();
  @Output() onGroupRoom = new EventEmitter<any>();
  @Output() OnAddInterventionClose = new EventEmitter<any>()
  @Output() deletedIntervention = new EventEmitter<DoctorAgenda[]>()
  @Output() onDoctorAgendaUpdate = new EventEmitter<DoctorAgenda>();
  // @Output() onDestroy = new EventEmitter();

  @ViewChild('osComponentRef1', null) osComponentRef: OverlayScrollbarsComponent;

  // we create an object that contains coordinates for the right click menu (on right click on doctor agenda)
  public menuTopLeftPosition =  {x: '0', y: '0'} 
 
  // reference to the MatMenuTrigger in the DOM 
  @ViewChild(MatMenuTrigger, {static: true}) matMenuTrigger: MatMenuTrigger; 


  public osComponentOptions = {
    className : "os-theme-dark custom-sidebar",
    nativeScrollbarsOverlaid : {
      showNativeScrollbars : true
    },
    overflowBehavior : {
      x : "hidden",
  },
    paddingAbsolute: true,
    scrollbars: {
      autoHide: 'never',
      clickScrolling: true
    },
  };

  public doesHospitalHaveAnesthDetailsOption: boolean;
  public isCadreDeBloc: boolean;
  public isUserHasLowLevelAccess: boolean;
  public groupRooms : Room[];
  public isEditList : boolean[] ;
  public groupRoomOptions : Room[][];
  public isInternResponsible: boolean;
  public isIadeReslevel4: boolean;

  public rooms : Room[];
  public groupedRooms : any[];

  public nurseAnest = [0, 1, 2, 3, 4]
  public currentEnteredRoom:any;
  public roomIndex: number;
  typeAttributeArray: string[];
  typeAttribute: string;
  isIadeRes: boolean;
  isAneWithLowlLevel: boolean
  surgeonOpeningsService: any;
  private isSurgeon: boolean = false;

  private isMultiHospital: boolean = false;

  public surgeonProfiles: any = []
  private searchSurgeonsSubscription: Subscription;

  get isThereNewModifs(): boolean{
    return this.roomsData &&
      this.roomsData.filter( (roomAgenda:any) => roomAgenda.isModified).length > 0;
  }

  constructor(
    public dialog: MatDialog,
    private userService: UserService,
    private toastService: ToastService,
    private popupManagerService: PopupManagerService,
    private hospitalService: HospitalService,
    private SOService: SurgeonOpeningsService,
    private storageService: StorageService,
    private hrsuiteService: HrsuiteService,
    private profileService: ProfileService,
    private errorService: ErrorService
  ) {
    const date = new Date();
    date.setUTCHours(12);
    this.date = date.toISOString();
    this.isUserHasLowLevelAccess = this.userService.isUserHasLowLevelAccess()
    this.doesHospitalHaveAnesthDetailsOption = this.hospitalService.doesHospitalHaveAnesthDetailsOption();
    // this.doesHospitalHaveTransformDAIntoSOOption = this.hospitalService.doesHospitalHaveTransformDAIntoSOOption();
    
    
    this.isInternResponsible = this.userService.isInternResponsible();
    this.isIadeReslevel4 = this.userService.isIadRes() && this.userService.getLevelAccess() >= 4
  }

  ngOnInit() {
    this.isMultiHospital = this.userService.isCurrentUserHasMultipleHospitalsSelected()
    this.initialize();
    this.getSurgeonProfiles();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.roomsData && changes.roomsData.currentValue && !changes.roomsData.firstChange) {
      this.initialize();
    }
  }

  getSurgeonProfiles() {
    if (this.searchSurgeonsSubscription) {
      this.searchSurgeonsSubscription.unsubscribe();
    }
    const hospital = (this.userService.getSelectedHospitals())[0]
    this.searchSurgeonsSubscription = this.profileService.getProfilesByPositionFromHospital(hospital, SURGEON, null, null, '', false, true, false, undefined, false, false)
    .subscribe((results) => {
      this.surgeonProfiles = results.docs;
    }, error => this.errorService.handleError(error)); 
  }

  initialize(): void{
    this.showData = false;

    // Sort roomsData
    sortRoomsByPriority(this.roomsData, false);
    if (this.isConfigAnesth) {
      // Sort this.roomsOpenings
      const tmp = [];
      for (const item of this.roomsData) {
        const roomId = String(item.room._id);
        const found = this.roomsOpenings.find((elt) => String(elt.room._id) === roomId);
        tmp.push(found);
      }
      this.roomsOpenings = tmp;
    }

    this.isIadeRes = this.userService.isIadRes();
    this.isAneWithLowlLevel = this.userService.isAnesWithLowLevelAccess();
    this.isCadreDeBloc = this.userService.isCadreBloc();
    if (this.isConfigAnesth) {
      this.groupRooms = new Array(this.roomsData.length);
      this.isEditList = new Array(this.roomsData.length).fill(true);
      this.groupRoomOptions = new Array(this.roomsData.length).fill([]);
      this.rooms = this.roomsData.map(room => room.room);
      this.getGroupRoom();
    }
    this.typeAttributeArray =
      this.isConfigAnesth
      ? setTypeAttributeArray(this.roomsOpenings , this.isConfigMode)
      : setTypeAttributeArray(this.roomsData, this.isConfigMode);
    
    this.showData = true; 
    this.isSurgeon = this.userService.isSurgeon();
    if (this.showMenu && this.isSurgeon && !this.perInterventionView) {
      this.showMenu = false;
    }
  }

  getGroupRoom(){
    this.isEditList = this.isEditList.fill(true);
    this.groupedRooms = [];
    this.groupRooms = [];
    this.groupRoomOptions = this.groupRoomOptions.map((r , index) =>{
      let optionRooms = [];
      [...this.rooms].forEach(room => {
         if((String(this.roomsData[index].room._id) !== String(room._id)) && (String(this.roomsData[index].room.hospital._id) === String(room.hospital._id))){
           optionRooms = [...optionRooms , room];
         }

      })
      return optionRooms;
    });

    this.roomsData.map((room) => {
      if(room.rooms.length > 0){
        this.groupedRooms = [... this.groupedRooms , ...room.rooms.map(room => room._id)];
        this.groupRoomOptions = this.groupRoomOptions.map((roomOptions, index) => {
          let groupedRoomsIds = room.rooms.map(roomId => roomId._id);
          return roomOptions.filter(room => !groupedRoomsIds.includes(room._id) || groupedRoomsIds.includes(this.rooms[index]._id));
        });
        let groupedRoom = room.rooms.filter(groupRoom => groupRoom._id !== room.room._id)[0];
        const groupedRoomIndex = this.rooms.findIndex((r) => String(r._id) === String(groupedRoom._id));
        this.isEditList[groupedRoomIndex] = false;
        const noEditRoomIndex = this.rooms.findIndex(r => r._id === room.room._id);
        let noEditRoom = this.rooms[noEditRoomIndex];
        groupedRoom = this.rooms[groupedRoomIndex];
        this.groupRooms[noEditRoomIndex] = groupedRoom;
        this.groupRooms[groupedRoomIndex] = noEditRoom;
      }
    });
  }

  removeItem(daId): void {
    if (daId.updatedBy) { 
      this.OnItemRemove.emit({ daId : daId._id, tvoId: daId.tvoId, room: daId.room});
    }
    this.onChange.emit(true);
  }

  deleteIntervention(event) {
    this.deletedIntervention.emit(event);
  }

  onChangeHandler(): void {
    this.onChange.emit(true);
  }

  editVacationClick(doctorAgenda: DoctorAgenda) : void {
    this.isEditVacationMode = true;
    const doctor = toPascalCase(`${doctorAgenda.surgeon.firstName}  ${doctorAgenda.surgeon.lastName}`);
    const copyOfDA = JSON.parse(JSON.stringify(doctorAgenda));
    if (!this.isConfigMode) {
      this.selectedDay = (new Date(this.date)).getUTCDay() - 1;
    }

      const dialogRef = this.dialog.open(AddVacationPopupComponent, {
        width: "550px",
        minHeight: '710px',
        data: {
          isTVO: this.isOnlyHist,
          openType: 'EDIT',
          roomNumber: this.roomsData[this.roomIndex].room.roomNumber,
          rooms: this.roomsData,
          date: this.date,
          day: getCommonDayNumber(this.selectedDay),
          roomId: this.roomsData[this.roomIndex].room._id,
          notifyAnesth: this.notifyAnesth,
          isConfigMode: this.isConfigMode,
          hostRoom : this.roomsData[this.roomIndex],
          isVacationModes: this.isVacationModes,
          doctor,
          doctorAgenda: copyOfDA
        }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data && !data.isError) {
        this.OnEditVacationClose.emit({data, roomIndex: this.roomIndex});
        this.roomsData[this.roomIndex].isModified = true;
        // this.roomsData[this.roomIndex].isEdited = true;
        this.onChange.emit(true);
        if (!this.isOnlyHist) {
          this.updateRoomsData();
        } else {
          doctorAgenda.needs = data.needs;
          doctorAgenda.surgeon = data.surgeon;
          doctorAgenda.startTime = data.startTime;
          doctorAgenda.endTime = data.endTime;
        }
      } else if (data && data.isError){
        this.toastService.errorToast("la période demandée n'est pas valide");
      }
    });
  }

  addVacationClick(roomIndex: number): void {
    if (!this.isConfigMode) {
      this.selectedDay = (new Date(this.date)).getUTCDay() - 1;
    }
    const dialogRef = this.dialog.open(AddVacationPopupComponent, {
      width: "550px",
      minHeight: '710px',
      data: {
        roomNumber: this.roomsData[roomIndex].room.roomNumber,
        rooms: this.roomsData,
        manualSave: !this.isVacation,
        date: this.date, day: getCommonDayNumber(this.selectedDay),
        roomId: this.roomsData[roomIndex].room._id,
        notifyAnesth: this.notifyAnesth,
        isConfigMode: this.isConfigMode,
        hostRoom : this.roomsData[roomIndex],
        isVacationModes: this.isVacationModes
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data && !data.isError) {
        if (data.vacationMode === "choosePracticioner") {
          this.roomsData[roomIndex].isModified = true;
          this.OnAddVacationClose.emit({ data, roomIndex });
          // this.onChange.emit(true);
          this.updateRoomsData();
        } else {
          this.OnAddVacationClose.emit({ data, roomIndex });
        }
      }else if (data && data.isError){
        this.toastService.errorToast("la période demandée n'est pas valide");
      }
    });
  }

  _isUserHasReadOnly(): boolean {
    const isUserHasReadOnly = this.userService.isAnesWithLowLevelAccess() || this.isSurgeon;
    return isUserHasReadOnly;
  }

  addInterventionClick(roomIndex: number): void {
    if (!this.isConfigMode) {
      this.selectedDay = (new Date(this.date)).getUTCDay() - 1;
    }

    if (this._isUserHasReadOnly()) return;

    const dialogRef = this.dialog.open(InterventionPopupComponent, {
      width: "550px",
      data: {
        type: "ADD",
        rooms: this.roomsData,
        roomId: this.roomsData[roomIndex].room._id,
        room: this.roomsData[roomIndex].room,
        data: this.roomsData[roomIndex],
        date: this.date,
        showRealizedMode: this.showRealizedMode,
        // frontEndDoctorAgendas is used to verify if period is available in frontEnd for others days
        frontEndDoctorAgendas: [...this.newDoctorAgendas, ...this.updatedDoctorAgendas],
      },
    });

    dialogRef.afterClosed().subscribe(async (message) => {
      if (message === "refresh") {
        this.surgeonOpeningsService.fetchData(false, true, false, this.isSurgeon);
      }
      else if (message) {
        this.OnAddInterventionClose.emit(message);
      }
    });

      this.initialize();
  }

  needsChange(roomData : any){
    setTimeout(() => {
      if(roomData.rooms.length > 0){
        let groupedRoom = roomData.rooms.filter(groupRoom => groupRoom._id !== roomData.room._id)[0];
        let roomDataToChange = this.roomsData.find(room => groupedRoom._id === room.room._id);
        roomDataToChange.anesthNeeds = roomData.anesthNeeds;
        roomDataToChange.iadeNeeds = roomData.iadeNeeds;
        roomDataToChange.seniorNeeds = roomData.seniorNeeds;
        roomDataToChange.juniorNeeds = roomData.juniorNeeds;
        roomDataToChange.internNeeds = roomData.internNeeds;
      }
      this.onChange.emit(true);
    }, 100);
  }

  groupRoom(roomData ,  room : Room){
    if (room){
      let groupedRoom = this.roomsData.find(groupedRoom => groupedRoom.room._id === room._id);
      roomData.rooms = [];
      groupedRoom.rooms = [roomData.room , room]
      roomData.anesthNeeds = groupedRoom.anesthNeeds;
      roomData.iadeNeeds = groupedRoom.iadeNeeds;
      roomData.seniorNeeds = groupedRoom.seniorNeeds;
      roomData.juniorNeeds = groupedRoom.juniorNeeds;
      roomData.internNeeds = groupedRoom.internNeeds;
      this.getGroupRoom();
    }else{
      roomData.rooms = [];
      this.getGroupRoom()
    }
    this.onChange.emit(true);
  }

  doctorAgendaRightClicked(event: any, roomIndex: number) {
    if (this.showMenu) {
      this.roomIndex = roomIndex;
      // we record the mouse position in our object 
      const parentX = this.osComponentRef.osTarget().getBoundingClientRect().left;
      const parentY = this.osComponentRef.osTarget().getBoundingClientRect().top;

      this.menuTopLeftPosition.x = (event.event.clientX - parentX) + 'px'; 
      this.menuTopLeftPosition.y = (event.event.clientY - parentY) + 'px'; 

      // we open the menu 
      // we pass to the menu the information about our object 
      this.matMenuTrigger.menuData = { doctorAgenda: event.doctorAgenda }

      // we open the menu 
      this.matMenuTrigger.openMenu();
    }
  } 

  seeDoctorAgendaDetailsClicked(doctorAgenda: DoctorAgenda): void {
    const room = this.roomsData.find((elt) => String(elt.room._id) === String(doctorAgenda.room));

    if (room) {
      const dialogRef = this.dialog.open(InterventionDetailsPopupComponent, {
        width: '960px',
        data: {
          modifiedDoctorAgendas: doctorAgenda,
          room: room.room,
          rooms: this.roomsData,
          // frontEndDoctorAgendas is used to verify if period is available in frontEnd for others days
          frontEndDoctorAgendas: [...this.newDoctorAgendas, ...this.updatedDoctorAgendas],
          isCadreDeBloc: this.isCadreDeBloc,
          showRealizedMode: this.showRealizedMode
        },
      });
      dialogRef.afterClosed().subscribe((response) => {
        if (response && response.status === 'refresh') {
          this.onDoctorAgendaUpdate.emit(response.intervention);
        }
      });
    }
  }

  moveDoctorAgendaClicked(doctorAgenda: DoctorAgenda): void {
    const copyOfDA = JSON.parse(JSON.stringify(doctorAgenda));
    const dialogRef = this.dialog.open(MoveInterventionPopupComponent, {
      // height: '1020px',
      // panelClass: 'dialog-container-no-padding-right',
      data: {
        doctorAgenda: copyOfDA,
        rooms: this.roomsData,
        // frontEndDoctorAgendas is used to verify if period is available in frontEnd for others days
        frontEndDoctorAgendas: [...this.newDoctorAgendas, ...this.updatedDoctorAgendas],
      },


    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.onDoctorAgendaUpdate.emit(response.doctorAgenda);
      }
    });
  }

  Confirm(roomIndex: number,room?:Room): void {
    event.stopPropagation();
    this.OnRoomClose.emit({roomIndex : roomIndex ,room : room , notify : this.notifyAnesth});
  }

  getRoomStartTime(room:Room):number{
		const date:Date = new Date(this.date);
    const currentDay:number = this.isConfigMode
      ? this.selectedDay + 2
      : date.getUTCDay()+1;
		const horaire:any = room.horaire.find( horaire=> horaire.day === currentDay);
		if(horaire){
			const roomStartDate:Date = new Date(horaire.start);
			return roomStartDate.getUTCHours()-1;
		}
		return 7;
	}

	getRoomEndTime(room:Room):number{
		const date:Date = new Date(this.date);
    const currentDay:number = this.isConfigMode
      ? this.selectedDay + 2
      : date.getUTCDay()+1;
		const horaire:any = room.horaire.find( horaire=> horaire.day === currentDay);
		if(horaire){
			const roomEndDate:Date = new Date(horaire.end);
			const endHour:number = roomEndDate.getUTCHours()+1;
			return 6 < endHour && endHour < 23 ? endHour : 23;
		}
		return 20;
	}

  updateRoomsData(){
    this.onChange.emit(true);
    this.roomsData= [...this.roomsData];
    this.initialize();
    this.updateFrontEffectifNeeds.emit();
  }

  roomClick(roomIndex: number, room?:Room) {
    this.onRoomClick.emit({roomIndex,room});
  }

  mouseEnter(room:any) {
    this.currentEnteredRoom=room;
  }

  mouseLeave(room) {
    this.currentEnteredRoom=room = null;
  }

  doctorAgendaDropped(event: any): void {
    const { doctorAgenda, sourceRoom, destinationRoom } = event;

    if (doctorAgenda && sourceRoom && destinationRoom) {
      const dialogRef = this.dialog.open(HoursPopupComponent, {
        width: "450px",
        data: {
          interventionDuration: new Date(doctorAgenda.endTime).getTime() - new Date(doctorAgenda.startTime).getTime(),
          intervention: doctorAgenda,
          destinationRoom: destinationRoom
        },
      });

      dialogRef.afterClosed().subscribe((response) => {
        if (response && response.allowMoving) {
          const startTime = response.startTime;
          const endTime = response.endTime;

          doctorAgenda.startTime = startTime;
          doctorAgenda.endTime = endTime;
          doctorAgenda.room = destinationRoom.room._id;

          this.onDoctorAgendaUpdate.emit(doctorAgenda);
        } else {
          this.onDataChangeSubject.next();
        }
      })
    } else {
      this.onDataChangeSubject.next();
    }
  }

  closeMenu(): void {
    if (this.showMenu) {
      this.matMenuTrigger.closeMenu();
    }
  }

  //Out of context here (for the condition of adding "Salle" or not in the tooltip)
  isNumeric(value: any): boolean {
    return !isNaN(value - parseFloat(value));
  }

  onSurgeonChangeHandle(event: any, roomIndex: number) {
    this.OnEditVacationClose.emit({data: event, roomIndex: roomIndex});
    this.roomsData[roomIndex].isModified = true;
    this.onChange.emit(true);
    if (!this.isOnlyHist) {
      this.updateRoomsData();
    } else {
      event.da.needs = event.needs;
      event.da.surgeon = event.surgeon;
      event.da.startTime = event.startTime;
      event.da.endTime = event.endTime;
    }
  }

  ngOnDestroy(): void {
    // this.onDestroy.emit();
    // this.hrsuiteService.isChangeSurgeonRooms= true
    // this.hrsuiteService.isSavingSurgeonOpenings= true;
    if (this.searchSurgeonsSubscription) {
      this.searchSurgeonsSubscription.unsubscribe();
    }
  }
}
