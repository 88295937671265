import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-column-selector',
  templateUrl: './columns-selector.component.html',
  styleUrls: ['./columns-selector.component.scss']
})

export class ColumnSelectorModalComponent {
  @Input() columns: any[];
  @Output() applyChanges = new EventEmitter<any[]>();
  @Output() closeModal = new EventEmitter<void>();

  availableColumns: any[] = [];
  selectedColumns: any[] = [];

  availableSelected: any[] = [];
  selectedSelected: any[] = [];

  maxSelections: number = 6; 


  ngOnInit() {
    this.availableColumns = this.columns.filter(col => !col.selected);
    this.selectedColumns = this.columns.filter(col => col.selected);
  }

 
  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      if (event.container.data === this.selectedColumns && this.selectedColumns.length >= this.maxSelections) {
        return;
      }
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  onApply() {
    this.applyChanges.emit(this.selectedColumns);
  }

  onClose() {
    this.closeModal.emit();
  }
}