import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Subscription} from 'rxjs';
import {ErrorService} from '../../../shared/services/error.service';
import {InterventionService} from 'src/app/shared/services/intervention.service';
import {AllIntervention} from '../../models/all-intervention.model';
import {AllInterventionService} from '../../services/all-intervention.service';

@Component({
  selector: 'app-search-act-bar',
  templateUrl: './search-act-bar.component.html',
  styleUrls: ['./search-act-bar.component.scss']
})
export class SearchActBarComponent implements OnInit, OnDestroy, OnChanges {
  private searchActSubscription: Subscription;
  isLoading: boolean = false;

  spIntList: any;
  @Output() onActClick = new EventEmitter<any>();
  searchInput: string = '';
  actualPage = 1;
  hasNextPageChannel = true;

  @ViewChild('search', null) searchElement: ElementRef;

  public isShowSearchResults: boolean = false;
  public lastSearchValue: string = '';

  //iconType : 'image' / 'mat-icon'.
  @Input() iconType: string = 'mat-icon';
  //Icon : button icon : svg / mat-icon.
  @Input() icon: string = 'search';
  @Input() selectedAct: any | AllIntervention;
  @Input() placeholder: string = 'Rechercher un praticien...';
  @Input() hintMessage: string = '';
  @Input() label: string = '';
  @Input() surgeon: any = null;
  @Input() isSurgeon: boolean = false;
  @Input() hospitalId: string = undefined

  public osComponentOptions = {
    className: 'os-theme-dark custom-sidebar',
    nativeScrollbarsOverlaid: {
      showNativeScrollbars: false
    },
    overflowBehavior: {
      x: 'hidden',
    },
    paddingAbsolute: true,
    scrollbars: {
      autoHide: 'never',
    },
    callbacks: {
      onScroll: (e) => {
        this.onScroll(e, this);
      }
    }
  };

  constructor(
    private errorService: ErrorService,
    private allInterventionService: AllInterventionService,
    private ChangeDetectorRef: ChangeDetectorRef,
    private interventionService: InterventionService) {
  }

  ngOnInit() {
    this.onEnter('');
  }

  ngOnChanges(): void {
    this.onEnter('');
  }

  toggleSearchResults(event: any): void {
    event.stopPropagation();
    if (this.isShowSearchResults) {
      this.isShowSearchResults = !this.isShowSearchResults;
      this.searchElement.nativeElement.blur();
      this.hideInputText();
    } else {
      this.isShowSearchResults = !this.isShowSearchResults;
      this.searchElement.nativeElement.focus();
      this.showInputText();
    }
  }

  showSearchResults(event: any): void {
    if (event) {
      event.stopPropagation();
    }
    this.searchElement.nativeElement.focus();
    this.isShowSearchResults = true;
    this.searchElement.nativeElement.value = this.lastSearchValue;
  }

  hideInputText(): void {
    // we hide input text if we are not searching and we have a selected profile
    if (this.selectedAct) {
      this.searchElement.nativeElement.value = '';
    }
  }

  showInputText(): void {
    // We show input text if we are searching
    this.searchElement.nativeElement.value = this.lastSearchValue;
  }

  onScroll(e, thisComponent: SearchActBarComponent) {
    const tableViewHeight = e.target.offsetHeight;
    const tableScrollHeight = e.target.scrollHeight;
    const scrollLocation = e.target.scrollTop;
    const buffer = 50;
    const limit = tableScrollHeight - tableViewHeight - buffer;
    if (scrollLocation >= limit && thisComponent.hasNextPageChannel && !thisComponent.isLoading) {
      if (!this.isSurgeon) {
        thisComponent.searchPagination(true);
      } else {
        thisComponent.searchBySurgeonPagination(true);
      }
    }
  }

  onEnter(searchInput: string): void {
    this.lastSearchValue = searchInput;
    if (!this.isSurgeon) {
      this.searchPagination(false);
    } else {
      this.searchBySurgeonPagination(false);
    }
  }

  searchBySurgeonPagination(isPaginate) {
    if (!this.surgeon)
      return ;

    this.isLoading = true;
    if (this.searchActSubscription) {
      this.searchActSubscription.unsubscribe();
    }
    if (!isPaginate) {
      this.actualPage = 1;
    }
    this.searchActSubscription = this.interventionService.searchInterventionsFromHospital(this.hospitalId, '', this.surgeon.lastName, this.searchInput, this.actualPage, 10, true, false).subscribe((results) => {
      this.isLoading = false;
      if (isPaginate) {
        // @ts-ignore
        this.spIntList.hasNextPage = results.hasNextPage;
        // @ts-ignore
        this.spIntList.hasPrevPage = results.hasPrevPage;
        // @ts-ignore
        this.spIntList.limit = results.limit;
        // @ts-ignore
        this.spIntList.nextPage = results.nextPage;
        // @ts-ignore
        this.spIntList.page = results.page;
        // @ts-ignore
        this.spIntList.pagingCounter = results.pagingCounter;
        // @ts-ignore
        this.spIntList.prevPage = results.prevPage;
        // @ts-ignore
        this.spIntList.totalDocs = results.totalDocs;
        // @ts-ignore
        this.spIntList.totalPages = results.totalPages;
        // @ts-ignore
        this.spIntList.docs = [...this.spIntList.docs, ...results.docs];
        // this.ChangeDetectorRef.detectChanges();
      } else {
        this.spIntList = results;
      }
      this.hasNextPageChannel = results.hasNextPage;
      if (this.hasNextPageChannel) {
        this.actualPage++;
      }
    }, error => this.errorService.handleError(error));
  }

  searchPagination(isPaginate) {
    this.isLoading = true;
    if (this.searchActSubscription) {
      this.searchActSubscription.unsubscribe();
    }
    if (!isPaginate) {
      this.actualPage = 1;
    }
    this.searchActSubscription = this.allInterventionService.getAllInterventions(this.searchInput, 10, this.actualPage).subscribe((results) => {
      this.isLoading = false;
      if (isPaginate) {
        // @ts-ignore
        this.spIntList.hasNextPage = results.hasNextPage;
        // @ts-ignore
        this.spIntList.hasPrevPage = results.hasPrevPage;
        // @ts-ignore
        this.spIntList.limit = results.limit;
        // @ts-ignore
        this.spIntList.nextPage = results.nextPage;
        // @ts-ignore
        this.spIntList.page = results.page;
        // @ts-ignore
        this.spIntList.pagingCounter = results.pagingCounter;
        // @ts-ignore
        this.spIntList.prevPage = results.prevPage;
        // @ts-ignore
        this.spIntList.totalDocs = results.totalDocs;
        // @ts-ignore
        this.spIntList.totalPages = results.totalPages;
        // @ts-ignore
        this.spIntList.docs = [...this.spIntList.docs, ...results.docs];
        this.ChangeDetectorRef.detectChanges();
      } else {
        this.spIntList = results;
      }
      this.hasNextPageChannel = results.hasNextPage;
      if (this.hasNextPageChannel) {
        this.actualPage++;
      }
    }, error => this.errorService.handleError(error));
  }

  actClick(act: any): void {
    this.selectedAct = act;
    this.onActClick.emit(act);
    // this.spIntList = [];
    // this.searchInput = '';
  }

  @HostListener('window:click', ['$event'])
  click(event) {
    this.isShowSearchResults = false;
    this.hideInputText();
  }

  ngOnDestroy() {
    if (this.searchActSubscription) {
      this.searchActSubscription.unsubscribe();
    }
  }
}
