import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';

import {urlEndPoints} from '../config/end-points';
import {Material} from '../models/material.model';
import { map } from 'rxjs/operators';

@Injectable()
export class MaterialDal {

  constructor(private httpClient: HttpClient) {
  }

  searchMaterialByName(searchString: string, limit: number, page : number , pagination:boolean = true): any{
    let endPoint;
    endPoint = urlEndPoints.materials + 'search'  + '?page=' + page +'&limit=' + limit + '&name=' + searchString + '&pagination=' + pagination;
    return this.httpClient.get<any>(endPoint).pipe(map((materials: any) => {
      return materials;
    }));
  }

  getAllMaterials(): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.materials);
  }

  addMaterial(material: Material): Observable<Material> {
    return this.httpClient.post<Material>(urlEndPoints.materials, material);
  }

  modifyMaterial(material: Material): Observable<Material> {
    return this.httpClient.put<Material>(urlEndPoints.materials + material._id, material);
  }

  archiveMaterial(idMaterial): Observable<Material> {
    return this.httpClient.delete<Material>(urlEndPoints.materials + idMaterial);
  }
}
