import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatCheckboxChange} from '@angular/material';
import { OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-document-reader-popup',
  templateUrl: './document-reader-popup.component.html',
  styleUrls: ['./document-reader-popup.component.scss']
})
export class DocumentReaderPopup implements OnInit, OnDestroy {
  public isPDF : boolean;
  public fileURL;
  constructor(
    public dialogRef: MatDialogRef<DocumentReaderPopup>,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.isPDF = this.fileType === "pdf"
    this.fileURL = this.isPDF ? this.data.fileURL : "https://view.officeapps.live.com/op/embed.aspx?src=" + this.data.fileURL
  }

  get fileType(){
   return this.data.fileName.split('.').pop();
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(){
    
  }

}
