import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';

import {Material} from '../models/material.model';
import {MaterialDal} from '../dal/material.dal';

@Injectable()
export class MaterialService {

  constructor(private materialDal: MaterialDal) {
  }

  searchMaterialByName(searchString: string, limit: number, page : number , pagination:boolean = true): any{
    return this.materialDal.searchMaterialByName(searchString, limit, page , pagination);
  }

  getAllMaterials(): Observable<any> {
    return this.materialDal.getAllMaterials();
  }

  addMaterial(material: Material): Observable<Material> {
    return this.materialDal.addMaterial(material);
  }

  modifyMaterial(material: Material): Observable<Material> {
    return this.materialDal.modifyMaterial(material);
  }

  archiveMaterial(idMaterial): Observable<Material> {
    return this.materialDal.archiveMaterial(idMaterial);
  }

}
