import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button-close',
  templateUrl: './button-close.component.html',
  styleUrls: ['./button-close.component.scss']
})
export class ButtonCloseComponent implements OnInit {

  @Output() buttonClicked = new EventEmitter()

  constructor() { }

  ngOnInit() {
  }

  buttonClickedHandler() {
    this.buttonClicked.emit()
  }

}
