import { ANESTHETIST, CADRE_DE_BLOC, IADE, NURSE_TYPES, TITULAIRE, VACATAIRE, SURGEON } from '../const/glabals.const'

export const ANESTHETISTS = {
  type1: {
    position: ANESTHETIST,
    residency: TITULAIRE,
    levelOfAccess: 2
  },
  type2: {
    position: ANESTHETIST,
    residency: TITULAIRE,
    levelOfAccess: 3
  },
  type3: {
    position: ANESTHETIST,
    residency: TITULAIRE,
    levelOfAccess: 4
  },
  type4: {
    position: ANESTHETIST,
    residency: VACATAIRE,
    levelOfAccess: 2
  },
  type5: {
    position: ANESTHETIST,
    residency: VACATAIRE,
    levelOfAccess: 1
  },
  type6: {
    position: ANESTHETIST,
    residency: TITULAIRE,
    levelOfAccess: 1
  },
  type7: {
    position: ANESTHETIST,
    residency: TITULAIRE,
    levelOfAccess: 5
  },
  type8: {
    position: ANESTHETIST,
    residency: VACATAIRE,
    levelOfAccess: 2
  },
}

export const INFIRMIERES = {
  type1: {
    position: NURSE_TYPES,
    residency: TITULAIRE,
    levelOfAccess: 1
  },
  type2: {
    position: NURSE_TYPES,
    residency: VACATAIRE,
    levelOfAccess: 1
  },
  type3: {
    position: NURSE_TYPES,
    residency: TITULAIRE,
    levelOfAccess: 2
  },
  type4: {
    position: NURSE_TYPES,
    residency: VACATAIRE,
    levelOfAccess: 2
  },
}

export const _CADRE_DE_BLOC = {
  type1: {
    position: [CADRE_DE_BLOC],
    residency: TITULAIRE,
    levelOfAccess: 3
  },
  type2: {
    position: [CADRE_DE_BLOC],
    residency: TITULAIRE,
    levelOfAccess: 4
  },
  type3: {
    position: [CADRE_DE_BLOC],
    residency: TITULAIRE,
    levelOfAccess: 5
  },
}

export const IADE_RESPONSIBLE = {
  type1: {
    position: IADE[0],
    residency: TITULAIRE,
    levelOfAccess: 1
  },
  type2: {
    position: IADE[1],
    residency: TITULAIRE,
    levelOfAccess: 1
  },
  type3: {
    position: IADE[0],
    residency: VACATAIRE,
    levelOfAccess: 3
  },
  type4: {
    position: IADE[1],
    residency: VACATAIRE,
    levelOfAccess: 3
  },
  type5: {
    position: IADE[0],
    residency: VACATAIRE,
    levelOfAccess: 1
  },
  type6: {
    position: IADE[1],
    residency: VACATAIRE,
    levelOfAccess: 1
  },
  type7: {
    position: IADE[0],
    residency: TITULAIRE,
    levelOfAccess: 3
  },
  type8: {
    position: IADE[1],
    residency: TITULAIRE,
    levelOfAccess: 3
  },
  type9: {
    position: IADE[1],
    residency: TITULAIRE,
    levelOfAccess: 4
  },
  type10: {
    position: IADE[0],
    residency: TITULAIRE,
    levelOfAccess: 4
  }
}

export const SURGEONS = {
  type1: {
    position: [SURGEON],
    residency: TITULAIRE,
    levelOfAccess: 1
  }
}