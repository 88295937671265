import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatSeniority'
})
export class FormatSeniorityPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) {
      return value;
    }

    switch (value) {
      case 'Senior':
        return 'Sénior';
      case 'Junior':
        return 'Junior';
      case 'Interne':
        return 'Interne';
      default: 
        return value;
    }
  }

}
