import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-vimeo',
  templateUrl: './vimeo.component.html',
  styleUrls: ['./vimeo.component.scss']
})

export class VimeoComponent implements OnInit, OnChanges {

  @Input() videoSRC: string;
  @Input() isNearElement: boolean;

  public isVisited: boolean = false;
  public isLoading: boolean = true;
  public safeURL;

  constructor(
    private dom:DomSanitizer,
    private cdref: ChangeDetectorRef,
  ) { }


  ngOnInit() {
    this.safeURL = this.sanitizedVideoSRC();
  }

  videoLoaded(){
    this.isLoading = false
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.isNearElement){
      this.isVisited = true
      this.cdref.detectChanges();
    }
  }

  ngAfterViewChecked() {
    this.cdref.detectChanges();
  }

  sanitizedVideoSRC(): SafeResourceUrl{
    const url:string = this.videoSRC && this.videoSRC.length
      ? this.videoSRC + '?byline=false&title=false&portrait=false'
      : 'https://player.vimeo.com/video/?byline=false&title=false&portrait=false';

    return this.dom.bypassSecurityTrustResourceUrl(url);
  }

}
