import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DoctorAgenda } from '../../models/doctor-agenda.model';
import { Profile } from '../../models/profile.model';
import { toPascalCase } from '../../utils/cross-functions';

@Component({
  selector: 'app-doctor-agenda-element',
  templateUrl: './doctor-agenda-element.component.html',
  styleUrls: ['./doctor-agenda-element.component.scss']
})
export class DoctorAgendaElementComponent implements OnInit, OnChanges {
  @Input() doctorAgenda: DoctorAgenda | any;
  @Input() showHours: boolean;
  @Input() showEditButton: boolean;
  @Input() showDetails: boolean = true;
  @Input() showRealizedMode: boolean = true;
  @Output() onEditButtonClick: EventEmitter<void> = new EventEmitter();

  public profile: Profile;
  public hours: string;
  public surgeonImage: string;
  public surgeonName: string;
  public specialtyName: string;
  public specialtyImage: string;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.doctorAgenda) {
      this.profile = this.doctorAgenda.surgeon;

      if (this.profile && this.profile._id) {
        this.surgeonImage = this.profile.profilePic;
        this.surgeonName = this.getSurgeonName(this.profile.firstName + ' ' + this.profile.lastName);
        this.specialtyName = this.getSpecialtyName();
        this.specialtyImage = this.getSpecialtyImage();
      }
      
      if (this.showHours) {
        this.hours = this.getHoursToShow(this.doctorAgenda);
      } else {
        this.hours = undefined;
      }
    }
  }

  ngOnInit() {
  }

  getHoursToShow(agenda: DoctorAgenda | any): string {
    const startHours = this.getHHhMMFormat(agenda.startTime);
    const endHours = this.getHHhMMFormat(agenda.endTime);
    return `${startHours} - ${endHours}`;
  }

  getHHhMMFormat(dateTime: string | Date): string {
    const date: Date = new Date(dateTime);
    const hours = date.getUTCHours() < 10 ? '0' + date.getUTCHours() : date.getUTCHours();
    const minutes = date.getUTCMinutes() < 10 ? '0' + date.getUTCMinutes() : date.getUTCMinutes();
    return hours + ' h ' + minutes;
  }

  onEditButtonClickHandler(): void {
    this.onEditButtonClick.emit();
  }

  getSurgeonName(fullname: string): string {
    return toPascalCase(fullname);
  }

  getSpecialtyName(): string {
    if (!this.profile) {
      return;
    }
    if (this.profile.specialties && this.profile.specialties.length > 0) {
      const specialty = this.profile.specialties.find((specialty) => String(specialty.hospital) === String(this.doctorAgenda.hospital))
      if (specialty) {
        return specialty.name;
      } else {
        return null
      }
    } else {
      return null;
    }
  }

  getSpecialtyImage(): string {
    if (!this.profile) {
      return;
    }
    if (this.profile.specialties && this.profile.specialties.length > 0) {
      const specialty = this.profile.specialties.find((specialty) => String(specialty.hospital) === String(this.doctorAgenda.hospital))
      if (specialty) {
        return specialty.image;
      } else {
        return null
      }
    } else {
      return null;
    }
  }
}
