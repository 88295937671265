import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-radio-button-group',
  templateUrl: './radio-button-group.component.html',
  styleUrls: ['./radio-button-group.component.scss']
})
export class RadioButtonGroupComponent implements OnInit {
  @Input() formCtrl: FormControl = new FormControl();

  // layout : 'column' | 'row'
  @Input() layout: string = 'column';

  @Input() label: string = '';
  @Input() hintMessage: string = '';

  @Input() elements: { label: string, value: any, color?: string }[] = [];
  @Input() disabled: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
