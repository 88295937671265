import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import {urlEndPoints} from '../config/end-points';

@Injectable()

export class ExclusionDal {

  constructor(private httpClient: HttpClient) {
  }

  getExclusions(profileId: string): Observable<any[]> {
    return this.httpClient.get<any[]>(urlEndPoints.exclusions + `${profileId}`);
  }

  deleteExclusions(exclusionId: string) {
    return this.httpClient.delete<any>(urlEndPoints.exclusions + exclusionId);
  }

  addExclusions(exclusions: any[]) {
    return this.httpClient.post<any>(urlEndPoints.exclusions, exclusions);
  }
}
