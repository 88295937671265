import {Injectable} from '@angular/core';
import {AbstractControl} from '@angular/forms';


@Injectable()
export class PasswordValidator {

  constructor() {
  }

  static MatchPassword(abstractControl: AbstractControl) {
    const password = abstractControl.get('password').value;
    const confirmPassword = abstractControl.get('confirmPassword').value;
    if (password !== confirmPassword) {
      abstractControl.get('confirmPassword').setErrors({MatchPassword: true})
    } else {
      abstractControl.get('confirmPassword').setErrors(null);
    }
  }
}
