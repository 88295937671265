import { Component, Input, OnDestroy, OnInit, Inject, ChangeDetectorRef, SimpleChange} from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
@Component({
  selector: 'app-popup-import-real-programs',
  templateUrl: './popup-import-real-programs.component.html',
  styleUrls: ['./popup-import-real-programs.component.scss']
})
export class ImportRealProgramsPopupComponent implements OnInit {

  @Input() title: string = "";
  @Input() generateBtnTitle: string = "";
  isAllSelected: boolean;
  isAllRoomsSelected: boolean;
  public selectedDate: Date;
  public formGroup: FormGroup;
  public minDate = new Date();
  public maxDate = new Date();
  
  constructor(public dialogRef: MatDialogRef<ImportRealProgramsPopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public formBuilder: FormBuilder,
              private readonly changeDetectorRef: ChangeDetectorRef ) { 
    this.title = data.title;
    this.generateBtnTitle = data.generateBtnTitle;
    this.isAllSelected = true;
    this.selectedDate = data.date;
    this.maxDate = new Date();
    this.minDate = new Date(this.selectedDate);
    }

  ngOnInit() {
    this.makeForm();
  }

  ngOnChanges(changes: SimpleChange) {
    if (this.formGroup.get("startDate").value > this.formGroup.get("endDate").value) {
      this.formGroup.get("endDate").reset();
    }
  }

  makeForm(): void {
      this.formGroup = this.formBuilder.group({
        startDate: [this.selectedDate, [Validators.required]],
        endDate: [this.selectedDate, [Validators.required]],
      });

    this.formGroup.get("startDate").valueChanges.subscribe(x => {
      this.minDate = x;
    })
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  import() {
    this.dialogRef.close({startDate: this.formGroup.value.startDate, endDate: this.formGroup.value.endDate});
  }

  close() {
    this.dialogRef.close();
  }
}
