import {ActivatedRouteSnapshot, CanActivate, Router,} from "@angular/router";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

import {NavigationService} from './navigation.service';
import {AuthenticationService} from './authentication.service';
import {StorageService} from './storage.service';
import {pages} from '../../shared/config/pages';
import { ANESTHESIST_WITH_ACCENT, ANESTHETIST_TYPES, NURSE, NURSE_TYPES } from "src/app/shared/const/glabals.const";
import { BLOCK_MANAGER } from "src/app/shared/const/positions.const";
import { UserService } from "src/app/shared/services/user.service";
import { HospitalService } from "src/app/shared/services/hospital.service";
import { UtilisService } from "src/app/shared/services/utilis.service";

@Injectable()
export class AuthenticationGuard implements CanActivate {
  constructor(
    private authenticationService: AuthenticationService,
    private storageService: StorageService,
    private userService : UserService,
    private hospitalService: HospitalService,
    private navigationService: NavigationService,
    private utilisService: UtilisService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    const isAuthenticated = this.authenticationService.isAuthenticated();
    if (isAuthenticated) {
      const fullscreenActivated = this.storageService.getData("fullscreenActivated");
      if (fullscreenActivated) {
        this.authenticationService.logout();
        this.navigationService.navigateByUrl(pages.login).catch();
        this.storageService.saveData("fullscreenActivated", false);
        return false
      }
      if (route.data['neededFeatures']) {
        for (const neededFeature of route.data['neededFeatures']) {
          if (!this.hospitalService.checkIfHospitalHaveFeature(neededFeature)) {
            return false;
          }
        }
      }

      if (route.data['neededOptions']) {
        for (const neededOption of route.data['neededOptions']) {
          if (!this.hospitalService.checkIfHospitalHaveOption(neededOption)) {
            return false;
          }
        }
      }

      if (!route.data['levelOfAccess']) {
        return true;
      }
      const levelOfAccess = this.storageService.getUser().levelOfAccess;
      const position: string = this.storageService.getUser().profile.position;

      if (route.data['levelOfAccess'].indexOf(levelOfAccess) > -1) {
        if (route.data['positions'] && levelOfAccess !== 5) {
          if (route.data['positions'].indexOf(position) > -1) {
            if (route.data.denyAccessForInterneResponsible) {
              if (this.storageService.getUser().isInternResponsible) {
                return false;
              } else {
                return true;
              }
            }  else if (route.data.denyAccessForResIadelevel3) {
              if (this.userService.isIadRes() && this.userService.getLevelAccess() == 3 ){
                return false
              }
            } else {
              return true;
            }
          } else {
            this.navigationService.navigateByUrl(pages.accessDenied).catch();
          }

        } else if(route.data['requestParams']) {

          if (this.userService.isInfirmiere() && this.userService.isUserHasLowLevelAccess()) {
            this.navigationService.navigateByUrl(pages.accessDenied).catch();
            return false;
          }

          const requestParams = route.data['requestParams']
          const param = route.params.position;
          const positions = param == NURSE ? NURSE_TYPES.concat([BLOCK_MANAGER]) : param === ANESTHESIST_WITH_ACCENT ? ANESTHETIST_TYPES : [];

          if (requestParams.indexOf(param) > -1  && ( levelOfAccess === 5 || positions.indexOf(position) > -1)) {
            return true;
          } else {
            this.navigationService.navigateByUrl(pages.accessDenied).catch();
          }
        } else {
          return true;
        }
      } else {
        this.navigationService.navigateByUrl(pages.accessDenied).catch();
      }
    } else {
      this.storageService.saveData("fullscreenActivated", false);
      this.navigationService.navigateByUrl(pages.login).catch();
    }
  }
}
