import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Chart, registerables } from 'chart.js';
import { NURSE, SURGEON, ANESTHETIST } from 'src/app/shared/const/glabals.const';
import { Profile } from 'src/app/shared/models/profile.model';
import { themeStyling } from 'src/themes/common/principal-theme';

@Component({
  selector: 'app-inputs-showroom',
  templateUrl: './inputs-showroom.component.html',
  styleUrls: ['./inputs-showroom.component.scss']
})
export class InputsShowroomComponent implements OnInit {
  public formGroup: FormGroup;
  public date: Date = new Date('2022-02-01');
  public time: string = '10:10';
  public NURSE = NURSE;
  public SURGEON = SURGEON;
  public ANESTHETIST = ANESTHETIST;
  public selectedSurgeon: Profile;
  public color = '#33E700'
  public options1 = [
    {
      label: 'Choice 1',
      value: 'Choice 1'
    },
    {
      label: 'Choice 2',
      value: 'Choice 2'
    }
  ];

  public options2 = [
    {
      label: 'Lock',
      value: 'Lock',
      icon: 'lock'
    },
    {
      label: 'Unlock',
      value: 'Unlock',
      icon: 'lock_open'
    }
  ];

  public actifOption1 = 'Choice 1';
  public actifOption2 = 'Lock';
  public number: number = 16

  public residencies = [
    {
      label: 'Titulaire',
      value: 'titulaire',
    },
    {
      label: 'Vacataire',
      value: 'vacataire',
    },
    {
      label: 'Test',
      value: 'test',
    }
  ];

  public statuses = [
    {
      label: 'Titulaire',
      value: 'titulaire',
      isChecked: true,
    },
    {
      label: 'Vacataire',
      value: 'vacataire',
      isChecked: false,
    }
  ];

  public testNgModel = 'hello';

  public lastNameErrorMessagesObject = {
    required: `Le nom d'utilisateur est obligatoire`,
    minlength: 'Le nom doit contenir au minimum deux caractères'
  };

  public emailErrorMessagesObject = {
    required: `L'email d'utilisateur est obligatoire`,
    email: `L'email n'est pas valide`
  };

  public searchErrorMessagesObject = {
    minlength: 'Ce input doit contenir au minimum deux caractères'
  };

  constructor(private formBuilder: FormBuilder) { 
    Chart.register(...registerables);
    // Chart.register(ChartDataLabels);
    Chart.defaults.font.family = themeStyling.fontFamilyPrimary
  }

  ngOnInit() {
    this.makeForm();
    this.initChart();
  }

  initChart(): void {
    var myChart = new Chart("myChart", {
      type: 'bar',
      data: {
          labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
          datasets: [{
              label: '# of Votes',
              data: [12, 19, 3, 5, 2, 3],
              backgroundColor: [
                  'rgba(255, 99, 132, 0.2)',
                  'rgba(54, 162, 235, 0.2)',
                  'rgba(255, 206, 86, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(153, 102, 255, 0.2)',
                  'rgba(255, 159, 64, 0.2)'
              ],
              borderColor: [
                  'rgba(255, 99, 132, 1)',
                  'rgba(54, 162, 235, 1)',
                  'rgba(255, 206, 86, 1)',
                  'rgba(75, 192, 192, 1)',
                  'rgba(153, 102, 255, 1)',
                  'rgba(255, 159, 64, 1)'
              ],
              borderWidth: 1
          }]
      },
      options: {
          scales: {
              yAxes: [{
                  ticks: {
                      beginAtZero: true
                  }
              }]
          }
      }
  });
  }

  makeForm() {
    this.formGroup = this.formBuilder.group({
      lastName: [{ value: '', disabled: false }, [Validators.required, Validators.minLength(2)]],
      email: [{ value: '', disabled: false }, [Validators.required, Validators.minLength(2), Validators.email]],
      position: [{ value: 'Chirurgien', disabled: true }, []],
      search: [{ value: '', disabled: false }, [Validators.minLength(2)]],
    });
  }

  profileSelectedHandler(event: any): void {
    this.selectedSurgeon = event;
  }

  onValidate(): void {
  }

  onCancel(): void {
  }

  searchValueChanged(input: string): void {
  }
}
