import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatCheckboxChange} from '@angular/material';
import { OnDestroy } from '@angular/core';

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.scss']
})
export class ConfirmationPopupComponent implements OnInit, OnDestroy {

  public title: string;
  public description: string;
  public type: string = 'close'; // Available default, close
  public generateBtnTitle: string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.title = data.title;
    this.description = data.description;
    this.generateBtnTitle = data.generateBtnTitle;
  }

  ngOnInit() {
  }

  generate() {
    this.dialogRef.close("confirmation");
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
  }

}
