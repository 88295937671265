import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { MatDialogRef, MAT_DIALOG_DATA, MatCheckboxChange, MatRadioChange } from '@angular/material';
import { Subscription } from 'rxjs';
import { ErrorService } from '../../services/error.service';
import { RoomService } from '../../services/room.service';
import { ToastService } from '../../services/toast.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { HospitalService } from '../../services/hospital.service';
import { AutofillingService } from '../../services/autofilling.service';
import { PythonAPIService } from '../../services/pythonApi.service';
import { roomFinalData } from '../day-program-recap/day-program-interfaces';
import { IADE, NURSE_TYPES } from '../../const/glabals.const';

enum RoomFillingType {
  byRoom = "salle",
  byHalfDay = "demi-journee",
  byIntervention = "vacation"
}

@Component({
  selector: 'autofilling-popup',
  templateUrl: './autofilling-popup.component.html',
  styleUrls: ['./autofilling-popup.component.scss']
})
export class AutofillingComponent implements OnInit, OnDestroy{
  interval: any;
  public progress = 0;
  public isAutofilling: boolean;
  isDateValid = false;
  public formGroup: FormGroup;
  public profilesTypes: any[] = [];
  public profilesLabels: string[] = [];
  public selectedProfile: string = "";
  public displayedColumns: string[] = ['room', 'roomFillingType'];
  public autofillingTypes: { label: string, value: any, isChecked: boolean, isDisabled?: boolean }[] = [
    {
      label: 'Bloc',
      value: 'bloc',
      isChecked: true,
      isDisabled: false,
    },
    {
      label: 'Consultation',
      value: 'consultation',
      isChecked: true,
      isDisabled: false,
    }
  ];
  public selectedProfileType: string;
  public selectedAutofillingTypes: string[] = ['bloc', 'consultation'];
  public selectedRoomAutofillingType: string;
  public roomAutofillingTypes: {label: string, value: string}[] = [
    {
      label: 'par salle',
      value: 'salle'
    },
    {
      label: 'par demi-journée',
      value: 'demi-journee'
    },
    {
      label: 'par vacation',
      value: 'vacation'
    }
  ];
  public canKeepRooms: boolean = false;
  public roomsToKeep: any[] = [];
  public rooms: { _id: string, roomNumber: any, nurses: string[], anesths: string[], iades: string[], checked: boolean, checkedAutofilling: boolean, fillingType: RoomFillingType, fillingTypeDisabled: boolean}[] = [];
  public checkAll: boolean = true;
  
  private autofillingSub: Subscription;
  isError: boolean;

  roomFillingType = RoomFillingType;

  checkIfDateValid(){
    return this.formGroup.controls.startDate.value <= this.formGroup.controls.endDate.value;
  }

  constructor(
    private roomsService: RoomService,
    private errorService: ErrorService,
    public dialogRef: MatDialogRef<any>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data,
    private userService: UserService,
    private hospitalService: HospitalService,
    private pythonAPIService: PythonAPIService,
    private autofillingService: AutofillingService,
    private toastService: ToastService,
  ) {
    this.initRooms();
  }

  ngOnInit() {
    this.initProfilesTypes();
    if (this.profilesTypes[0]) {
      this.selectedProfileType = this.profilesTypes[0].value;
    }
    this.initProfilesLabels();
    this.selectedRoomAutofillingType = this.roomAutofillingTypes[0].value;
    this.makeForm();
    this.initListeners();
    this.isDateValid = this.checkIfDateValid()
    this.checkIfWeCanKeepRooms();
  }

  initRooms(): void {
    this.data.rooms.filter((room: roomFinalData) => !room.isEmpty).forEach((room: roomFinalData) => {
      this.rooms.push({
        _id: room.roomId,
        roomNumber: room.roomNumber,
        nurses: room.team.others.filter((card) => NURSE_TYPES.includes(card.profile.position) || IADE.includes(card.profile.position)).map((card) => card.profile._id),
        anesths: room.team.others.filter((card) => card.profile.position === "Anesthésiste").map((card) => card.profile._id),
        iades: room.team.others.filter((card) => IADE.includes(card.profile.position)).map((card) => card.profile._id),
        checked: false,
        checkedAutofilling: true,
        fillingType: RoomFillingType.byRoom,
        fillingTypeDisabled: false
      })
    })
  }


  initListeners(): void {
    this.formGroup.controls.startDate.valueChanges.subscribe((val) => {
      this.isDateValid = this.checkIfDateValid();
      this.checkIfWeCanKeepRooms();
    });

    this.formGroup.controls.endDate.valueChanges.subscribe((val) => {
      this.isDateValid = this.checkIfDateValid();
      this.checkIfWeCanKeepRooms();
    });

    this.formGroup.controls.profileType.valueChanges.subscribe((val) => {
      this.checkIfWeCanKeepRooms();
    });

    this.formGroup.controls.autofillingTypes.valueChanges.subscribe((val) => {
      this.checkIfWeCanKeepRooms();
    });
  }
  
  checkIfWeCanKeepRooms(): void {
    // we can ignore rooms if startDate equals endDate && (selectedProfileType === 'NURSE' || bloc is selected)
    const startDate = moment(this.formGroup.controls.startDate.value).format("YYYY-MM-DD").toString();
    const endDate  = moment(this.formGroup.controls.endDate.value).format("YYYY-MM-DD").toString();
    if (startDate === endDate) {
      if (this.formGroup.controls.profileType.value === 'NURSE' || this.formGroup.controls.autofillingTypes.value.includes('bloc')) {
        this.canKeepRooms = true;
      } else {
        this.canKeepRooms = false;
      }
    } else {
      this.canKeepRooms = false;
    }
  }

  makeForm(): void {
    this.formGroup = this.formBuilder.group({
      profileType: [this.selectedProfileType, [Validators.required]],
      autofillingTypes: [this.selectedAutofillingTypes],
      roomAutofillingType: [this.selectedRoomAutofillingType],
      startDate: [this.UTCDateToDate(new Date(this.data.date)), [Validators.required]],
      endDate: [this.UTCDateToDate(new Date(this.data.date)), [Validators.required]],
      keepRooms: [false, [Validators.required]],
    }, {
      validators: (formGroup: FormGroup) => {
        const isNurse = formGroup.get('profileType').value === 'NURSE';
        const autofillingTypes = formGroup.get('autofillingTypes');
        if (!isNurse && (!autofillingTypes.value || autofillingTypes.value.length === 0)) {
          autofillingTypes.setErrors({ required: true });
        } else {
          autofillingTypes.setErrors(null);
        }

        return null;
      }
    });
  }

  hospitalHasIADE() {
    const iadeRole = this.data.roleData.filter((role) => role.name === "IADE")[0]
    return iadeRole ? true : false;
  }

  initProfilesTypes(): void {
    // Cadre de bloc
    if (this.userService.isCadreBlocWithMediumLevel()) {
      // He can autofill only nurses
      this.profilesTypes = [
        {
          label: 'Infirmières',
          value: 'NURSE'
        }
      ];

      return;
    }

    // Admin
    if (this.userService.isCadreBlocWithHighLevel()) {
      if (this.hospitalService.doesHospitalHaveAnesthDetailsOption()) {
        this.profilesTypes = [
          {
            label: 'Séniors/Juniors',
            value: 'Senior/Junior'
          },
          {
            label: 'Internes',
            value: 'Interne'
          },
          {
            label: 'Infirmières',
            value: 'NURSE'
          }
        ];
      } else {
        this.profilesTypes = [
          {
            label: 'Infirmières',
            value: 'NURSE'
          }
        ];
      }

      if (this.hospitalHasIADE()) {
        this.profilesTypes.push({
          label: 'IADE',
          value: 'IADE'
        })
      }

      return;
    }

    // Responsable des anesthésistes
    if (this.userService.isAnestgWithHighLevel() && !this.userService.isInternResponsible()) {
      if (this.hospitalService.doesHospitalHaveAnesthDetailsOption()) {
        this.profilesTypes = [
          {
            label: 'Séniors/Juniors',
            value: 'Senior/Junior'
          },
          {
            label: 'Internes',
            value: 'Interne'
          }
        ];
      } else {
        this.profilesTypes = [];
      }

      if (this.hospitalHasIADE()) {
        this.profilesTypes.push({
          label: 'IADE',
          value: 'IADE'
        })
      }

      return;
    }

    // Responsable des IADEs
    if (this.userService.isIadRes() && this.hospitalHasIADE()) {
      this.profilesTypes = [
        {
          label: 'IADE',
          value: 'IADE'
        }
      ]
    }

    // Responsable des internes
    if (this.userService.isInternResponsible() && this.hospitalService.doesHospitalHaveAnesthDetailsOption()) {
      // He can autofill only internes
      this.profilesTypes = [
        {
          label: 'Internes',
          value: 'Interne'
        }
      ];
      
      return
    }
  }

  initProfilesLabels(): void {
    this.profilesTypes.map(profileType => {
      this.profilesLabels.push(profileType.label);
    })
    this.selectedProfile = this.profilesLabels[0];
  }

  handleOptionClick(selectedOption: string): void {
    this.selectedProfile = selectedOption;
    if(selectedOption === "Infirmières") {
      this.selectedProfileType = "NURSE";
      this.formGroup.get('profileType').setValue("NURSE");
    } else {
      this.selectedProfileType = this.selectedProfile;
      this.formGroup.get('profileType').setValue(selectedOption);
    }
  }

  get keepRooms() {
    return this.formGroup.controls["keepRooms"].value;
  }

  autofilling(){
    const startMoment = moment(this.formGroup.controls.startDate.value);
    const endMoment = moment(this.formGroup.controls.endDate.value);
    const days = endMoment.diff(startMoment, 'days');
  
    const startDate = startMoment.format("YYYY-MM-DD").toString();
    const endDate  = endMoment.format("YYYY-MM-DD").toString();

    this.isAutofilling = true;
    this.interval = setInterval(() => {
      this.progress += 1;
      if (this.progress >= 100) {
        clearInterval(this.interval);
      }
    }, 50 * (days + 1)); 

    if (this.formGroup.controls.profileType.value === 'NURSE') {
      this.startNursesAutofilling(startDate, endDate);
    } else if (this.formGroup.controls.profileType.value === 'IADE') {
      this.startIADEAutofilling(startDate, endDate);
    } else {
      const seniority = this.formGroup.controls.profileType.value === 'Senior/Junior' ? 'Senior' : 'Interne';
      const autofillingTypes = this.formGroup.controls.autofillingTypes.value;
      this.startAnesthsAutofilling(startDate, endDate, seniority, autofillingTypes);
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  async startAnesthsAutofilling(startDate, endDate, seniority, autofillingTypes: string[]) {
    this.isError = false;
    if (startDate && endDate) {
      if (this.autofillingSub) {
        this.autofillingSub.unsubscribe();
      }

      let roomsToKeep = [];
      let profilesToKeep = [];
  
      if (startDate === endDate && this.canKeepRooms && this.keepRooms) {
        this.rooms.forEach((r) => {
          if (r.checked) {
            roomsToKeep.push(r._id);
            profilesToKeep.push(...r.anesths);
          }
        });
      }
        this.autofillingSub = this.pythonAPIService.autoFillingByIntervalAnesth(startDate, endDate, seniority, autofillingTypes, roomsToKeep, profilesToKeep).subscribe((data) => {
        this.isAutofilling = false;
        this.dialogRef.close(data);
      }, (error) => {
        this.errorService.handleError(error);
        this.isAutofilling = false;
        this.toastService.errorToast('Erreur lors du remplissage du smartplanning');
        clearInterval(this.interval);
        this.progress = 0;
      })
    }
  }

  async startNursesAutofilling(startDate, endDate) {
    this.isError = false;
    let roomsToKeep = [];
    let profilesToKeep = [];
    let roomsConfiguration = {};

    if (startDate === endDate && this.canKeepRooms) {
      this.rooms.forEach((r) => {
        if (!r.checkedAutofilling || !r.fillingType) {
          roomsToKeep.push(r._id);
          profilesToKeep.push(...r.nurses);
        }
        else if (r.fillingType) {
          roomsConfiguration[r._id] = r.fillingType; 
        }
      });
    } else {
      this.data.rooms.forEach((r) => {
        roomsConfiguration[r.roomId] = this.formGroup.controls.roomAutofillingType.value;
      });
    }

    if (startDate && endDate) {
      if (this.autofillingSub) {
        this.autofillingSub.unsubscribe();
      }
      
      this.autofillingSub = this.pythonAPIService.autoFillingByInterval(startDate, endDate, roomsToKeep, profilesToKeep, roomsConfiguration).subscribe((data) => {
        this.isAutofilling = false;
        this.dialogRef.close(data);
      }, (error) => {
        this.errorService.handleError(error);
        this.isAutofilling = false;
        this.toastService.errorToast('Erreur lors du remplissage du smartplanning') ;
        clearInterval(this.interval);
        this.isError = true;
        this.progress = 0;
      })
    }
  }

  startIADEAutofilling(startDate, endDate) {
    this.isError = false;
    let roomsToKeep = [];
    let profilesToKeep = [];
    let roomsConfiguration = {};

    if (startDate === endDate && this.canKeepRooms && this.keepRooms) {
      this.rooms.forEach((r) => {
        if (r.checked) {
          roomsToKeep.push(r._id);
          profilesToKeep.push(...r.iades);
        }
        else {
          roomsConfiguration[r._id] = RoomFillingType.byRoom;
        }
      });
    } else {
      this.rooms.forEach((r) => {
        roomsConfiguration[r._id] = RoomFillingType.byRoom;
      });
    }

    if (startDate && endDate) {
      if (this.autofillingSub) {
        this.autofillingSub.unsubscribe();
      }
      
      this.autofillingSub = this.pythonAPIService.autoFillingByInterval(startDate, endDate, roomsToKeep, profilesToKeep, roomsConfiguration, this.formGroup.controls.profileType.value).subscribe((data) => {
        this.isAutofilling = false;
        this.dialogRef.close(data);
      }, (error) => {
        this.errorService.handleError(error);
        this.isAutofilling = false;
        this.toastService.errorToast('Erreur lors du remplissage du smartplanning') ;
        clearInterval(this.interval);
        this.isError = true;
        this.progress = 0;
      })
    }
  }

  checkRadioButton(event: MatCheckboxChange, roomNumber: number): void {
    const room = this.rooms.find((r) => r.roomNumber === roomNumber);
    if (room)
    {
      if (!event.checked)
        room.fillingTypeDisabled = true;
      else if (room.fillingTypeDisabled)
      {
        room.fillingTypeDisabled = false;
        if (!room.fillingType)
          room.fillingType = RoomFillingType.byRoom;
      }
    }
  }

  checkAllRooms(event: MatCheckboxChange): void {
    this.rooms.forEach((r) => {
      r.checkedAutofilling = event.checked;
      r.fillingTypeDisabled = !event.checked;
    });
  }

  selectForAllCheckedRooms(event: MatRadioChange): void {
    this.rooms.forEach((r) => {
      if (r.checkedAutofilling)
      {
        r.fillingType = event.value;
      }
    });
  }

  UTCDateToDate(date: Date): Date {
    const result = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
      date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
    return result;
  }

  ngOnDestroy(): void {
    if(this.autofillingSub){
      this.autofillingSub.unsubscribe();
      if(!this.isAutofilling)
        this.toastService.errorToast('Annulation');
    }
  }

}
