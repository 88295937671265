import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatTable } from '@angular/material';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { frenchDays } from '../../const/glabals.const';
import { Recurrence } from '../../models/recurrence.model';
import { Specialty } from '../../models/specialty.model';
import { WishRealTime } from '../../models/wish.model';
import { ErrorService } from '../../services/error.service';
import { ExclusionService } from '../../services/exclusion.service';
import { PopupManagerService } from '../../services/popup-manager.service';
import { RecurrencesService } from '../../services/recurrences.service';
import { ToastService } from '../../services/toast.service';
import { WishesService } from '../../services/wishes.service';
import { AddEditRecurrenceComponent } from './add-edit-recurrence/add-edit-recurrence.component';
import { AddWishExclusionPopupComponent } from './add-wish-exclusion-popup/add-wish-exclusion-popup.component';
import { EditWishPopupComponent } from './edit-wish-popup/edit-wish-popup.component';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-fiche-poste',
  templateUrl: './fiche-poste.component.html',
  styleUrls: ['./fiche-poste.component.scss']
})
export class FichePosteComponent implements OnInit, OnDestroy, OnChanges {
  @Input() profileId: string;
  @Input() canEdit: boolean = false;
  @Input() showReal: boolean = false;

  @Output() onSavingCompleted: EventEmitter<void> = new EventEmitter<void>();
  @Output() onChange: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('recurrencesTable', { static: true }) recurrencesTable: MatTable<any>;
  public days = frenchDays.slice(0, frenchDays.length - 2);
  public displayedRecurrencesColumns: string[] = [
    'dayOfWeek',
    'type',
    'specialty',
    'buttons'
  ];

  public extraSpecialty: Specialty;
  public globalWishes = {
    bloc: 0,
    consultation: 0,
    extra: 0
  };
  public wishes: WishRealTime[][] = [[], []];
  public exclusions: any[][] = [[], []];
  public isLoading: boolean[] = [true, true, true];
  public draft: WishRealTime[] = [null, null];
  public draftExclusion: any[][] = [[], []];
  public toDeleteExclusions: any[][] = [[], []];

  public blocSpecialties: Specialty[] = [];
  public consultationSpecialties: Specialty[] = [];
  public recurrences: Recurrence[] = [];

  private saveSubjectSubscription: Subscription;
  private getBlocWishesSubscription: Subscription;
  private getConsultationWishesSubscription: Subscription;
  private getExclusiosSubscription: Subscription;
  private getRecurrencesSubscription: Subscription;

  constructor(
    private wishesService: WishesService,
    private exclusionService: ExclusionService,
    private recurrencesService: RecurrencesService,
    private errorService: ErrorService,
    private matDialog: MatDialog,
    private toastService: ToastService,
    private userService: UserService,
    private popupManagerService: PopupManagerService,
  ) {

  }
  
  get totalWishes(): number {
    return this.wishes[0].reduce((a, b) => a += b.wish, 0)
      + this.wishes[1].reduce((a, b) => a += b.wish, 0)
  }

  get totalSpecificWish(): number {
    return this.wishes[0].reduce((a, b) => a += b.specificWish, 0)
      + this.wishes[1].reduce((a, b) => a += b.specificWish, 0)
  }

  get totalGlobal(): number {
    return this.globalWishes.bloc + this.globalWishes.consultation + this.globalWishes.extra;
  }

  get saveError(): number {
    let error = null;
    this.wishes.some((wish, i) => {
      const glob = i == 0 ? this.globalWishes.bloc : this.globalWishes.consultation;
      if (glob && wish.reduce((a, b) => a += b.specificWish, 0) != 100) {
        error = i + 1;
      };
    });
    return error;
  }

  get extra(): number {
    return this.globalWishes.extra;
  }

  get totalBloc(): number {
    const total = this.wishes[0].reduce((a, b) => a += b.wish, 0);
    return total;
  }

  get totalConsultation(): number {
    const total = this.wishes[1].reduce((a, b) => a += b.wish, 0);
    return total;
  }

  get loadingFinished(): boolean {
    return this.isLoading.every(a => !a);
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.fetchData();
  }

  fetchData(): void {
    this.wishes = [[], []];
    this.exclusions = [[], []];
    this.draftExclusion = [[], []]
    this.isLoading = [true, true, true];

    this.getWishes();
    this.getExclusions();
  }

  getRecurrences(): void {
    if (this.getRecurrencesSubscription) {
      this.getRecurrencesSubscription.unsubscribe();
    }

    this.getRecurrencesSubscription = this.recurrencesService.getProfileRecurrences(this.profileId).subscribe(recurrences => {
      this.recurrences = recurrences.map(recurrence => {
        if(recurrence.specialty._id === this.extraSpecialty._id){
          recurrence.type = "extraclinique";
          recurrence.specialty = null;
        }
        return recurrence;
      });

      this.sortRecurrencesByDayOfWeek();
    });
  }

  getWishes(): void {
    this.getBlocWishes();
    this.getConsultationWishes();
  }

  getExclusions(): void {
    if (this.getExclusiosSubscription) {
      this.getExclusiosSubscription.unsubscribe();
    }

    this.getExclusiosSubscription = this.exclusionService.getExclusions(this.profileId).subscribe(result => {
      this.exclusions[0] = result.filter(sp => sp.type === 'bloc');
      this.exclusions[1] = result.filter(sp => sp.type === 'consultation');
      this.isLoading[2] = false;
    }, error => this.errorService.handleError(error));
  }

  getBlocWishes(): void {
    if (this.getBlocWishesSubscription) {
      this.getBlocWishesSubscription.unsubscribe();
    }

    this.getBlocWishesSubscription = this.wishesService.getWishesByProfileAndType(this.profileId, 'bloc').subscribe(result => {
      result = result.filter(wish=>wish.specialty.name!=='Anesthésie-réanimation');
      const extra = result.find(spe => (<Specialty>spe.specialty).name.match(/extra/i));
      this.extraSpecialty = extra ? <Specialty>extra.specialty : null;
      this.globalWishes.extra = extra ? extra.wish : 0;
      this.wishes[0] = result.filter(sp => (<Specialty>sp.specialty)._id != extra.specialty._id);
      this.wishes[0] = this.wishes[0].map(a => {
        a.specificWish = this.totalBloc != 0 ? Math.floor(Number((a.wish * 100 / this.totalBloc)) * 100) / 100 : 0;
        return a;
      });
      this.globalWishes.bloc = this.totalBloc;
      this.draft[0] = { ...result.filter(wish => wish.wish == 0)[0] };
      this.isLoading[0] = false;
      this.blocSpecialties = this.wishes[0].map((s) => s.specialty);
      // We get recurrences here because we are sure that we fetched Extraclinique specialty
      this.getRecurrences();
    }, error => this.errorService.handleError(error));
  }

  filterWishes(array: any[]): any[] {
    return array.filter((elt) => elt.specificWish);
  }

  getConsultationWishes(): void {
    if (this.getConsultationWishesSubscription) {
      this.getConsultationWishesSubscription.unsubscribe();
    }

    this.getConsultationWishesSubscription = this.wishesService.getWishesByProfileAndType(this.profileId, 'consultation').subscribe(result => {
      result = result.filter(wish=>wish.specialty.name!=='Anesthésie-réanimation');
      this.wishes[1] = result;
      this.wishes[1] = this.wishes[1].map(a => {
        a.specificWish = this.totalConsultation != 0 ? Math.floor(Number((a.wish * 100 / this.totalConsultation)) * 100) / 100 : 0;
        return a;
      });
      this.globalWishes.consultation = this.totalConsultation;

      this.isLoading[1] = false;
      this.draft[1] = { ...result.filter(wish => wish.wish == 0)[0] };
      this.consultationSpecialties = this.wishes[1].map((s) => s.specialty);
    }, error => this.errorService.handleError(error));
  }

  splitWishesEqually(type: string): void {
    const arrayIndex = type === 'Bloc' ? 0 : 1;

    // Reset all the previous wishes of the corresponding type
    this.wishes[arrayIndex].forEach(wish => {
      wish.specificWish = 0;
      wish.wish = 0;
    });

    const unconfiguredSpecialties = this.getUnconfiguredSpecialties(this.wishes[arrayIndex], [...this.exclusions[arrayIndex], ...this.draftExclusion[arrayIndex]])
      .filter(s => s.specialty.name !== 'DEFAULT_' && s.specialty.name !== 'Bariatrique' && s.specialty.name !== 'Urgences' && s.specialty.type.includes(type.toLowerCase()));
  
    const totalWishes = type === 'Bloc' ? this.globalWishes.bloc : this.globalWishes.consultation;
  
    const equalWishPercentage = Math.floor(100 / unconfiguredSpecialties.length);
    const remainder = 100 - (equalWishPercentage * unconfiguredSpecialties.length);
  
    unconfiguredSpecialties.forEach((specialty, index) => {
      const adjustedWishPercentage = index < remainder ? equalWishPercentage + 1 : equalWishPercentage;
      const equalWish = (totalWishes * adjustedWishPercentage) / 100;
      const found = this.wishes[arrayIndex].find((e) => String(e.specialty._id) === String(specialty.specialty._id));
  
      if (found) {
        found.specificWish = adjustedWishPercentage;
        found.wish = equalWish;
      }
    });
  
    this.onChange.emit(); // Emit the changes
  }

  deleteWish(specialty: WishRealTime): void {
    this.onChange.emit();
    specialty.specificWish = 0;
    specialty.wish = 0
  }

  deleteExclusion(exclusion, index: number): void {
    this.onChange.emit();
    this.exclusions[index] = this.exclusions[index].filter(exclusionToDelete => exclusionToDelete._id != exclusion._id);
    this.toDeleteExclusions[index].push(exclusion);
  }

  deleteDraftExclusion(exclusion, index: number) {
    this.onChange.emit();
    this.draftExclusion[index] = this.draftExclusion[index].filter(exclusionToDelete => exclusionToDelete.specialty._id !== exclusion.specialty._id);
  }

  editGlobalWish(type: string): void {
    let fieldName;

    switch (type) {
      case 'Bloc':
        fieldName = 'bloc';
        break;
      case 'Consultation':
        fieldName = 'consultation';
        break;
      case 'Extraclinique':
        fieldName = 'extra';
        break;
    }

    const dialogRef = this.matDialog.open(EditWishPopupComponent, {
      width: '480px',
      data: {
        type: type,
        specialtyName: 'Total',
        inputValue: this.globalWishes[fieldName]
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result != null) {
        this.globalWishes[fieldName] = result;
        this.onChange.emit();
      }
    });
  }

  editSpecificWish(type: string, element: any): void {
    const dialogRef = this.matDialog.open(EditWishPopupComponent, {
      width: '480px',
      data: {
        type: type,
        specialtyName: element.specialty.name,
        inputValue: element.specificWish
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result != null) {
        element.specificWish = result;
        this.onChange.emit();
      }
    });
  }

  addWishOrExclusion(type: string): void {
    const arrayIndex = type === 'Bloc' ? 0 : 1;
    const unconfiguredSpecialties = this.getUnconfiguredSpecialties(this.wishes[arrayIndex], [...this.exclusions[arrayIndex], ...this.draftExclusion[arrayIndex]]);
    
    const dialogRef = this.matDialog.open(AddWishExclusionPopupComponent, {
      width: '480px',
      data: {
        type: type,
        unconfiguredSpecialties,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result != null) {
        this.onChange.emit();
        if (result.type === 'wish') {
          const found = this.wishes[arrayIndex].find((e) => String(e.specialty._id) === String(result.specialtyId));
          if (found) {
            found.specificWish = Number(result.specificWish);
          }
        } else {
          this.draftExclusion[arrayIndex].push(...result.specialties.map((s) => {
            return {
              specialty: s,
              type: type.toLowerCase(),
              hospital: this.userService.getSelectedHospitals()[0],
              profile: this.profileId,
              active: true
            }
          }))
        }
      }
    });
  }

  getUnconfiguredSpecialties(wishesArray: any[], exclusionsArray: any[]): any[] {
    const response = wishesArray.filter((elt) => {
      if (elt.specificWish == 0) {
        const exclusion = exclusionsArray.find((e) => String(e.specialty._id) === String(elt.specialty._id));

        if (exclusion) {
          return false;
        }

        return true;
      }
      return false;
    });    

    return response;
  }

  addRecurrence(): void {
    const dialogRef = this.matDialog.open(AddEditRecurrenceComponent, {
      width: '440px',
      data: {
        openType: 'ADD',
        blocSpecialties: this.blocSpecialties,
        consultationSpecialties: this.consultationSpecialties
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        result.profile = this.profileId;
        this.recurrences.push(result);
        this.sortRecurrencesByDayOfWeek();
        this.recurrencesTable.renderRows();
        this.onChange.emit();
      }
    });
  }

  editRecurrence(recurrence: Recurrence): void {
    const dialogRef = this.matDialog.open(AddEditRecurrenceComponent, {
      width: '440px',
      data: {
        openType: 'EDIT',
        recurrence: recurrence,
        blocSpecialties: this.blocSpecialties,
        consultationSpecialties: this.consultationSpecialties
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        recurrence.dayOfWeek = result.dayOfWeek;
        recurrence.type = result.type;
        recurrence.specialty = result.specialty;
        this.sortRecurrencesByDayOfWeek();
        this.recurrencesTable.renderRows();
        this.onChange.emit();
      }
    });
  }

  deleteRecurrence(index: number): void {
    this.recurrences.splice(index, 1);
    this.recurrencesTable.renderRows();
    this.onChange.emit();
  }

  save(): Promise<void> {
    return new Promise((resolve, reject) => {
      // Check if all values and percentages are valid
      if (!this.checkIfValuesAreValid()) {
        this.popupManagerService.openInfoPopup('Erreur', 'Veuillez vérifier les pourcentages des souhaits', 300);
        this.onSavingCompleted.next();
        this.onChange.emit();
        reject();
        return;
      }

      // Save
      const promises = [];
      promises.push(this.saveWishes());
      promises.push(this.saveExclusions());
      promises.push(this.saveRecurrences());
      

      // Trigger save completion
      Promise.all(promises).then(() => {
        this.toastService.infoToast("L'opération a été prise en charge");
        this.onSavingCompleted.next();
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  }

  sortRecurrencesByDayOfWeek(): void {
    this.recurrences = this.recurrences.sort((a, b) => {
      return a.dayOfWeek - b.dayOfWeek;
    })
  }

  saveRecurrences(): Promise<void> {
    return new Promise((resolve, reject) => {
      const payload = this.recurrences.map(recurrence => {
        const recurrenceItem = {
          profile: this.profileId,
          specialty: recurrence.specialty ? (<Specialty>recurrence.specialty)._id : null,
          type: recurrence.type,
          dayOfWeek: recurrence.dayOfWeek,
          active: true
        }
        if(recurrenceItem.type === "extraclinique") {
          recurrenceItem.type = 'bloc';
          recurrenceItem.specialty = this.extraSpecialty._id;
        }
        return recurrenceItem;
      });

      this.recurrencesService.editProfileRecurrences(this.profileId, payload).subscribe(profile => {
        this.isLoading[1] = false;
        resolve();
      }, error => {
        this.errorService.handleError(error);
        reject();
      });
    });
  }

  checkIfValuesAreValid(): boolean {
    if (this.totalGlobal != 100 || this.saveError) {
      return false;
    }
    return true;
  }

  saveExclusions(): Promise<void> {
    return new Promise((resolve, reject) => {
      const promises = [];
      promises.push(this.addExclusions());
      promises.push(this.deleteExclusions());

      Promise.all(promises).then(() => {
        resolve();
      }).catch((e) => {
        reject(e);
      })
    });
  }

  addExclusions(): Promise<void> {
    return new Promise((resolve, reject) => {
      const queries = [];
      queries.push(this.exclusionService.addExclusions(this.draftExclusion[0]));
  
      queries.push(this.exclusionService.addExclusions(this.draftExclusion[1]));

      if (queries.length > 0) {
        forkJoin(queries).subscribe((data: any) => {
          if (!data[0]) {
            data[0] = [];
          }

          if (!data[1]) {
            data[1] = [];
          }

          let newBlocExclusions = data[0].map(exclusion => {
            let draftExclusions = this.draftExclusion[0].filter(dExcluison => dExcluison.specialty._id === exclusion.specialty);
            let newExclusion = {
              _id: exclusion._id,
              ...draftExclusions[0]
            }
            return newExclusion;
          });
          this.exclusions[0].push(...newBlocExclusions);
          this.draftExclusion[0] = [];
  
          let newConsultationExclusions = data[1].map(exclusion => {
            let draftExclusions = this.draftExclusion[1].filter(dExcluison => dExcluison.specialty._id === exclusion.specialty);
            let newExclusion = {
              _id: exclusion._id,
              ...draftExclusions[0]
            }
            return newExclusion;
          });
          this.exclusions[1].push(...newConsultationExclusions);
          this.draftExclusion[1] = [];
          resolve();
        }, (error) => {
          reject(error);
        })
      } else {
        resolve();
      }
    });
  }

  deleteExclusions(): Promise<void> {
    return new Promise((resolve, reject) => {
      const promises = [];

      if (this.toDeleteExclusions[0].length !== 0) {
        this.toDeleteExclusions[0].forEach(exclusion => {
          promises.push(this.deleteExclusionById(exclusion._id));
        });
      }
  
      if (this.toDeleteExclusions[1].length !== 0) {
        this.toDeleteExclusions[1].forEach(exclusion => {
          promises.push(this.deleteExclusionById(exclusion._id));
        });
      }

      Promise.all(promises).then(() => {
        this.toDeleteExclusions[0] = [];
        this.toDeleteExclusions[1] = [];
        resolve();
      }).catch((error) => {
        reject(error);
      })
    });
  }

  deleteExclusionById(exclusionId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.exclusionService.deleteExclusions(exclusionId).subscribe(() => {
        resolve();
      }, (error) => {
        reject(error);
      });
    });
  }

  saveWishes(): Promise<void> {
    const arr1 = this.reduceArray(this.wishes[0], 'bloc');
    const arr2 = this.reduceArray(this.wishes[1], 'consultation');
    this.isLoading = [true, true];
    const wishes = arr1.concat(arr2);
    wishes.push({ specialty: this.extraSpecialty._id, type: 'bloc', timeAllowance: this.globalWishes.extra, profile: this.profileId });
    return this.editWishes(wishes);
  }

  reduceArray(wishes: WishRealTime[], type: string) {
    return wishes.reduce((array, wish) => {
      if (wish.specificWish) array.push({ specialty: wish.specialty._id, type: type, timeAllowance: wish.specificWish * (this.globalWishes[type] / 100), profile: this.profileId });
      return array;
    }, []);
  }

  editWishes(wishes): Promise<void> {
    return new Promise((resolve, reject) => {
      this.wishesService.editWishes(this.profileId, wishes).subscribe(profile => {
        this.isLoading[0] = false;
        resolve();
      }, error => {
        this.errorService.handleError(error)
        reject(error);
      });
    })
  }

  ngOnDestroy(): void {
    if (this.saveSubjectSubscription) {
      this.saveSubjectSubscription.unsubscribe();
    }

    if (this.getBlocWishesSubscription) {
      this.getBlocWishesSubscription.unsubscribe();
    }

    if (this.getConsultationWishesSubscription) {
      this.getConsultationWishesSubscription.unsubscribe();
    }

    if (this.getRecurrencesSubscription) {
      this.getRecurrencesSubscription.unsubscribe();
    }
  }
}
