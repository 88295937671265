import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Notifications } from '../models/notifications.model';
import { NotificationDal } from './../dal/notification.dal';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    constructor(private notificationDal: NotificationDal) {}

    getNotifications(profileId: string, page: number, limit: number, keyword?: string): Observable<Notifications> {
        return this.notificationDal.getNotifications(profileId, page, limit, keyword);
    }

    getNotificationsCounter(profileId: string): Observable<number> {
        return this.notificationDal.getNotificationsCounter(profileId);
    }

}
