import { Injectable } from '@angular/core';
import {ValidateRulesDal} from '../dal/validate-rules.dal';

@Injectable({
  providedIn: 'root',
})
export class ValidateRulesService {
  constructor(private validateRulesDal: ValidateRulesDal) {}

  getAllValidateRules() {
    return this.validateRulesDal.getAllValidateRules();
  }

  deleteValidateRule(validateRuleId) {
    return this.validateRulesDal.deleteValidateRule(validateRuleId);
  }

  addValidateRule(validateRules) {
    return this.validateRulesDal.addValidateRule(validateRules);
  }

  editValidateRule(validateRules) {
    return this.validateRulesDal.editValidateRule(validateRules);
  }

  getMostRecentValidateRules(position, seniority, residency, chosenDate, profileHospitalId) {
    return this.validateRulesDal.getMostRecentValidateRules(position, seniority, residency, chosenDate, profileHospitalId);
  }

}
