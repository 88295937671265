import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';

import {AllIntervention} from '../models/all-intervention.model';
import {AllInterventionDal} from '../dal/all-intervention.dal';
import { Subject } from 'rxjs';

@Injectable()
export class AllInterventionService {


  private newIntervention : AllIntervention ;
  interventionChanged = new Subject<AllIntervention>();

  constructor(private interventionDal: AllInterventionDal) {
  }

  getAllInterventions(filterName: string, limit: number, page: number): Observable<any> {
    return this.interventionDal.getAllInterventions(filterName, limit, page);
  }

  addIntervention(intervention: AllIntervention): Observable<AllIntervention> {
    return this.interventionDal.addIntervention(intervention);
  }
}
