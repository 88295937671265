import { isNurse, Profile } from 'src/app/shared/models/profile.model';
import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { IADE } from '../../const/glabals.const';

@Component({
  selector: 'app-person-to-exchange',
  templateUrl: './person-to-exchange.component.html',
  styleUrls: ['./person-to-exchange.component.scss']
})
export class PersonToExchangeComponent implements OnInit {
  @Input() data: any
  date: string;
  @Input() isProfile2: boolean;
  @Input() profile2: Profile;
  profile: Profile;
  program;
  type: any;
  public isIade = false
  period: string;
  specialtyName: any;
  public nurse = false;

  constructor() { }

  ngOnInit(): void {
    this.date = moment(this.data.date).format("DD/MM/YYYY")
    this.setData()
  }

  setData() {
    if (!this.isProfile2) {
      this.profile = this.data.profile

      this.nurse = isNurse(this.profile)

      let start = new Date(this.data.calendar.morningStartTime)
      let end = new Date(this.data.calendar.afternoonEndTime)
  
      if (end.getHours() <= 13)
        this.period = "Matin"
      else if (start.getHours() >= 13)
        this.period = "Après-midi"
      else
        this.period = "Journée"

      if (this.data.calendar.nightStartTime)
        this.period = "Nuit"

        this.isIade = IADE.includes(this.profile.position)

    } else {
      this.profile = this.profile2? this.profile2 : (this.data.calendar2? this.data.calendar2.profile : null)
      if (this.profile)  {
        this.nurse = isNurse(this.profile)
        this.isIade = IADE.includes(this.profile.position)
        this.program = null
        this.profile = this.profile2? this.profile2 : this.data.calendar2.profile
        this.date = (this.data.calendar2? this.data.calendar2.date : this.data.program2.date)
        if (this.date[1] == '/')
          this.date = "0" + this.date
        
        if (this.date[4] == '/')
          this.date = this.date.substring(0, 3) + '0' + this.date.substring(3)

        let start = new Date(this.data.calendar2? this.data.calendar2.startTime : this.data.program2.startTime)
        let end = new Date(this.data.calendar2? this.data.calendar2.endTime : this.data.program2.endTime)
    
        if (end.getHours() <= 13)
          this.period = "Matin"
        else if (start.getHours() >= 13)
          this.period = "Après-midi"
        else
          this.period = "Journée"

        if (this.data.calendar.nightStartTime)
          this.period = "Nuit"
      }
    }
  }


  isGardeExchange() {
    return this.data.exchangeType == 'garde'
  }


  ngOnChanges(changes: SimpleChanges) {
    this.setData()
  }

}
