import { Injectable } from '@angular/core';
import { SpecialtyDal } from '../dal/specialty.dal';
import { Observable } from 'rxjs';
import { Specialty } from '../models/specialty.model';

@Injectable({
  providedIn: 'root'
})
export class SpecialtyService {

  constructor(private specialtyDal: SpecialtyDal) { }

  // NOT USED
  // getNumberofSpecialties(): Observable<number> {
  //   return this.specialtyDal.getNumberofSpecialties();
  // }

  getAllSpecialties(includeUrgence: boolean = false): Observable<Specialty[]> {
    return this.specialtyDal.getAllSpecialties(includeUrgence);
  }

  getAllSpecialtiesFromHospital(includeUrgence: boolean = false, hospital: string): Observable<Specialty[]> {
    return this.specialtyDal.getAllSpecialtiesFromHospital(includeUrgence, hospital);
  }
  
  getSharedSpecialties(): Observable<Specialty[]> {
    return this.specialtyDal.getSharedSpecialties();
  }

  addSpecialty(specialty: Specialty): Observable<Specialty> {
    return this.specialtyDal.addSpecialty(specialty);
  }

  updateSpecialty(specialty: Specialty): Observable<Specialty> {
    return this.specialtyDal.updateSpecialty(specialty);
  }

  isSpecialityExist(name: string) {
    return this.specialtyDal.getIsSpecialityExist(name);
  }

  deleteSpecialty(specialtyId: number) {
    return this.specialtyDal.deleteSpecialty(specialtyId);
  }

  softDeleteSpecialty(specialtyId: number) {
    return this.specialtyDal.softDeleteSpecialty(specialtyId);
  }

  softRestoreSpecialty(specialtyId: string) {
    return this.specialtyDal.softRestoreSpecialty(specialtyId);
  }

  getAllSpecialtiesWithNameAndColor() {
    return this.specialtyDal.getAllSpecialtiesWithNameAndColor();
  }
}
