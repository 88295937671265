import { Component, Inject, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';

export interface DialogData {
  imageChangedEvent: any
}
@Component({
  selector: 'app-cropper-popup',
  templateUrl: './cropper-popup.component.html',
  styleUrls: ['./cropper-popup.component.scss']
})
export class CropperPopupComponent {
  public croppedImage: ImageCroppedEvent

  constructor(
    public dialogRef: MatDialogRef<CropperPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
  
  async imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event
  }

  onSave(): void {
    this.dialogRef.close(this.croppedImage);
  }
}
