import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExitDialogComponent } from '../components/exit-dialog/exit-dialog.component';
import { StorageService } from 'src/app/core/services/storage.service';


@Injectable()
export class PasswordGuard implements CanDeactivate<any> {
  constructor(private dialog: MatDialog, private storageService: StorageService) {}

  canDeactivate(component: any): Observable<boolean> {
    return this.dialog.open(ExitDialogComponent).afterClosed().pipe(map(result => {
      switch(result) {
        case 'close':
          return false
        case 'exit':
          return true
        default:
          return false
      }
    }));
  }
}
