import { Injectable } from '@angular/core';
import { CanActivate, CanDeactivate } from '@angular/router';
import { UserService } from 'src/app/shared/services/user.service';
import { MatDialog } from '@angular/material';
import { PopupSelectHospitalComponent } from 'src/app/shared/components/popup-select-hospital/popup-select-hospital.component';

@Injectable({
  providedIn: 'root'
})
export class MultipleHospitalsGuard implements CanActivate, CanDeactivate<any> {
  constructor(
    private userService : UserService,
    private dialog: MatDialog,
  ) {}

  async canActivate(): Promise<boolean> {
    if (this.userService.isCurrentUserHasMultipleHospitals()) {
      return new Promise((resolve, reject) => {
        const hospitals = this.userService.getSelectedHospitals();
        if (hospitals.length != 1) {
          const dialogRef = this.dialog.open(PopupSelectHospitalComponent, {
            width: "480px",
            height: "350px",
            data: {
              hospitalsSelected: hospitals.length === 0 ? false : true,
              compatible: true
            }
          });
          dialogRef.afterClosed().subscribe((res) => {
            if (res && res.success == true) {
              this.userService.changedHospitalsSelectedSubject.next();
              this.userService.soloHospitalModeSubject.next(true);
              return resolve(true);
            }
            else if(res && res.isClosePopup)
            {
              return resolve(false);
            }
            this.userService.soloHospitalModeSubject.next(false);
            return resolve(false);
          })
        } else {
          this.userService.soloHospitalModeSubject.next(true);
          return resolve(true);
        }
      })
    } else {
      return true;
    }
  }

  canDeactivate(): boolean {
    this.userService.soloHospitalModeSubject.next(false);
    return true;
  }
  
}
