import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urlEndPoints } from '../config/end-points';
import { Rule } from '../models/rule.model';

@Injectable()

export class RuleDal {

  constructor(private httpClient: HttpClient) {
  }

  getAllRules(){
    return this.httpClient.get<any>(urlEndPoints.rules);
  }

  addRule(rule: Rule){
    return this.httpClient.post<any>(urlEndPoints.rules , rule);
  }

  editRule(rule : Rule){
    return this.httpClient.put<any>(urlEndPoints.rules + rule._id , rule);
  }

  deleteRule(ruleID : string){
    return this.httpClient.delete<any>(urlEndPoints.rules + ruleID);
  }
}