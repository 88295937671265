import { MatPaginatorIntl } from '@angular/material/paginator';

export function getFrenshPaginatorIntl() {
    const paginatorIntl = new MatPaginatorIntl();

    paginatorIntl.itemsPerPageLabel = 'Éléments par page:';
    paginatorIntl.lastPageLabel = 'La dernière page';
    paginatorIntl.nextPageLabel = 'La page suivante';
    paginatorIntl.previousPageLabel = 'La page précédente';
    paginatorIntl.firstPageLabel = 'La première page';

    paginatorIntl.getRangeLabel = function (page, pageSize, length) {
        if (length === 0 || pageSize === 0) {
            return '0 sur ' + length;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ?
            Math.min(startIndex + pageSize, length) :
            startIndex + pageSize;
        return startIndex + 1 + ' - ' + endIndex + ' sur ' + length;
    };

    return paginatorIntl;
}