import { BufferProgramService } from "src/app/shared/services/buffer-program.service";
import { ExchangePopupComponent } from "./../exchange-popup/exchange-popup.component";
import { UserService } from "./../../services/user.service";
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";

import {
  ANESTHETIST,
  ANESTHETIST_TYPES,
  HOSPITAL_END_TIME,
  HOSPITAL_START_TIME,
  IADE,
  Iade,
  NURSE,
  NURSE_AFTERNOON_SHIFT_START_TIME,
  NURSE_MORNING_SHIFT_END_TIME,
  NURSE_TYPES,
  VACATAIRE,
} from "../../const/glabals.const";
import { CalendarService } from "src/app/shared/services/calendar.service";
import { Calendar, isGuard } from "../../models/calendar.model";
import { isNurse, Profile } from "../../models/profile.model";
import { ErrorService } from "../../services/error.service";
import * as moment from "moment";
import { DAYS } from "../../const/days.const";
import { cycleCalendar } from "../../models/cycleCalendar.model";
import { CycleService } from "../../services/cycle.service";
import { CalendarRequestService } from "../../services/calendar-request.service";
import { UtilisService } from "src/app/shared/services/utilis.service";
import { Reason } from "../../models/reason.model";
import { getHoursAndMinutes, RemoveAccents, toPascalCase } from "../../utils/cross-functions";
import { Rule } from "../../models/rule.model";
import { Hospital } from "../../models/hospital.model";
import { HospitalService } from "../../services/hospital.service";
import { ValidateRulesService } from "../../services/validate-rules.service";
import { StorageService } from "../../../core/services/storage.service";
import { PopupManagerService } from "../../services/popup-manager.service";
import { FormatMessageConfirmationPopupPipe } from "../../pipes/format-message-confirmation-popup.pipe";
import { HrsuiteService } from "src/app/hrsuite/hrsuite.service";
import { TimeAlert } from "../../models/timeAlerts.model";
import { HoursInputComponent } from "../hours-input/hours-input.component";
import { ReasonMap } from "../../models/reasonMap.model";
@Component({
  selector: "app-disponibility-dialog",
  templateUrl: "./disponibility-dialog.component.html",
  styleUrls: ["./disponibility-dialog.component.scss"],
})
export class DisponibilityDialogComponent
  implements OnInit, AfterContentChecked, OnDestroy
{
  @ViewChild('startTime', {static: false}) startTime: HoursInputComponent;
  @ViewChild('endTime', {static: false}) endTime: HoursInputComponent;

  public vacataire = VACATAIRE;
  public isMultiDaySelection: boolean = false;
  public isAbsence: boolean = false;
  public displayOtherReason: boolean = true;
  public profile: Profile;
  public dayCalendar: any;
  public isSending = false;
  public isDeleting = false;
  public isNight : boolean = false;
  public formGroup: FormGroup;
  public currentReasons: Reason[] = [];
  public absenceReasons: Reason[] = [];
  public presenceReasons: Reason[] = [];
  public canSwitch: boolean = false;
  public exchangeText: string = "Demander un échange";
  clicked: boolean = false;
  public residencies: any[] = [
    {
      label: "Présence",
      value: "AVAILABLE",
    },
    {
      label: "Absence",
      value: "ABSENT",
    },
  ];

  private periodToUpdate: Date[];
  private weekCalendars: Calendar[];
  private dayIndex: number;
  private date: Date;
  private createCalendarSubscription: Subscription;
  private updateCalendarSubscription: Subscription;
  private deleteCalendarSubscription: Subscription;
  private initalReason: Reason;
  isCycleConfig: any;
  cycleCalendar: any;
  updateCycleCalendarSubscription: Subscription;
  createCycleCalendarSubscription: Subscription;
  createCRSubscription: Subscription;
  getProgamsSubsc: Subscription;

  wasGuard: boolean;

  private checkWorkTimeSubscription: Subscription;
  private nextWeekCalendarsSubscription: Subscription;
  showComment: boolean;

  public preselectedReason: Reason;

  period: string;

  lvlOfAccess;
  hospital: Hospital;

  public isEditButtonDisabled: boolean = true;
  public isDeleteButtonDisabled: boolean = true;
  public editButtonTooltip: string;
  public deleteButtonTooltip: string;
  public switchButtonTooltip: string;
  // isAvailableRule == true if we have a rule that is blocking rh requests
  public isAvailableRule: boolean;
  public isAvailableRuleByHospital: any[] = [];
  public doesHospitalHaveAnesthDetailsOption: boolean;
  public timeAlert: TimeAlert;
  private overflowConfirmationStatus: boolean = false;
  
  public isMultiHospitals: boolean = false;
  public hospitals: any[] = [];
  public suggestedAbsenceReasonsMaps: ReasonMap[];
  public suggestedPresenceReasonsMaps: ReasonMap[];
  public selectedReasonMap: ReasonMap;

  constructor(
    public dialogRef: MatDialogRef<DisponibilityDialogComponent>,
    private userService: UserService,
    private utilisService: UtilisService,
    private calendarRequestService: CalendarRequestService,
    private BufferProgramService: BufferProgramService,
    private UserService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private errorService: ErrorService,
    private formBuilder: FormBuilder,
    private changeDetector: ChangeDetectorRef,
    private calendarService: CalendarService,
    private cycleService: CycleService,
    private hospitalService: HospitalService,
    private validateRulesService: ValidateRulesService,
    private storage: StorageService,
    private popupManagerService: PopupManagerService,
    private formatMessageConfirmationPopup: FormatMessageConfirmationPopupPipe,
    private hrsuiteService: HrsuiteService,
  ) {
    this.periodToUpdate = [];
    this.period = "DAY";
    this.doesHospitalHaveAnesthDetailsOption =
      this.hospitalService.doesHospitalHaveAnesthDetailsOption();
  }

  async ngOnInit() {
    this.profile = this.data.profile;

    const activeProfileHospitalsNumber = this.profile.hospitals.length - this.profile.desactivatedAt.length;
    this.isMultiHospitals = this.userService.isCurrentUserHasMultipleHospitalsSelected() && activeProfileHospitalsNumber > 1

    const selectedHospitalsId = this.userService.getSelectedHospitals()
    selectedHospitalsId.forEach(hospitalId => {
      const hospital = this.profile.hospitals.find((hospital) => String(hospital._id) == String(hospitalId))
      if (hospital && !this.profile.desactivatedAt.includes(hospital._id)) {
        this.hospitals.push({
          name: hospital.name,
          _id: hospital._id
        })
      }
    });

    // const hospitalReasons = this.data.reasons.find((hospital_) => String(hospital_._id) === String(this.hospitals[0]._id))
    // this.currentReasons = hospitalReasons.reasons

    if (this.data.isNight) {
      this.isNight = this.data.isNight;
    }
    // if (!this.data.isCycleConfig) {
    //   if (!await this.isAnotherServices()) {
    //     this.errorService.simpleErrorMsg("Ce profil a une disponibilité associée à un autre service ce jour là.")
    //     return this.dialogRef.close()
    //   }
    // }
    if (this.isNight)
      this.period = "NIGHT"
    this.lvlOfAccess = this.storage.getUser().levelOfAccess;

    this.hospital = this.profile.hospitals.find((hospital) => String(hospital._id) == String(this.hospitals[0]._id))
    await this.changeHospital(this.hospital);
    this.makeForm();
    if (this.dayCalendar) {
      const h = this.hospitals.find((hospital) => String(hospital._id) == String(this.dayCalendar.hospital._id));
      await this.changeHospital(h);
      this.formGroup.controls['hospitals'].setValue(h);
    }
    // I commented this line because i didn't understand its purpose
    // this.getProfileNextWeekAvailabilities();
    this.initVariables();
    this.initListeners();

    if (
      !this.isDateAlreadyPassed() &&
      this.dayCalendar &&
      this.dayCalendar.reason &&
      this.dayCalendar.reason.availability == "AVAILABLE"
    ) {
      this.canSwitch =
        this.dayCalendar &&
        this.dayCalendar.reason.title == "Extraclinique" &&
        this.dayCalendar.reason.period != "NIGHT" &&
        (this.profile._id == this.userService.getCurrentUser().profile._id ||
          (this.userService.getCurrentUser().levelOfAccess >= 3 &&
            this.userService.getCurrentUser().profile.position ==
              ANESTHETIST) ||
          this.userService.getCurrentUser().levelOfAccess >= 4);

      this.canSwitch =
        this.canSwitch ||
        (this.dayCalendar &&
          this.dayCalendar.reason.isGarde &&
          (this.profile._id == this.userService.getCurrentUser().profile._id ||
            (this.userService.getCurrentUser().levelOfAccess >= 3 &&
              this.userService.getCurrentUser().profile.position ==
                ANESTHETIST) ||
            this.userService.getCurrentUser().levelOfAccess >= 4));

      if (
        (this.userService.getCurrentUser().levelOfAccess >= 3 &&
          this.userService.getCurrentUser().profile.position == ANESTHETIST) ||
        this.userService.getCurrentUser().levelOfAccess >= 4
      )
        this.exchangeText = "Réaliser un échange";
    }

    if (this.data.reasonsMaps && this.data.reasonsMaps.length > 0) {

      let savedReasonMap = null;
      
      if (this.data.period) {
        savedReasonMap = null;
        
      } else if (!this.isCycleConfig && this.data.weekCalendars[this.data.dayIndex] && this.data.weekCalendars[this.data.dayIndex].reasonMap) {
        savedReasonMap = this.data.reasonsMaps.find((reasonMap) => reasonMap._id === this.data.weekCalendars[this.data.dayIndex].reasonMap)
        
      } else if (this.data.isCycleConfig && this.data.cycleCalendar && this.data.cycleCalendar.reasonMap) {
        savedReasonMap = this.data.reasonsMaps.find((reasonMap) => reasonMap._id === this.data.cycleCalendar.reasonMap);
      }

      this.suggestedAbsenceReasonsMaps = this.data.reasonsMaps.filter((reasonMap) => (this.absenceReasons.find((reason) => reason._id === reasonMap.reason)
              && reasonMap.positions && reasonMap.positions.includes(this.data.profile.position) && reasonMap.displayInAvailabilityPopUp)
              || (savedReasonMap && this.formGroup.get("reason").value.availability != "AVAILABLE" && reasonMap._id === savedReasonMap._id))

      this.suggestedPresenceReasonsMaps = this.data.reasonsMaps.filter((reasonMap) => (this.presenceReasons.find((reason) => reason._id === reasonMap.reason)
              && reasonMap.positions && reasonMap.positions.includes(this.data.profile.position) && reasonMap.displayInAvailabilityPopUp)
              || (savedReasonMap && this.formGroup.get("reason").value.availability === "AVAILABLE" && reasonMap._id === savedReasonMap._id))
      this.changePreselectedReasonMap(savedReasonMap, false);
    }
  }

  initListeners(): void {
    if (!this.isCycleConfig) {
      this.formGroup.controls["availability"].valueChanges.subscribe(
        (value) => {
          this.changeAvailability(value);
        }
      );
    }
  }

  // async isAnotherServices(): Promise<boolean> {
  //   let disponibility;
  //   let startDate;
  //   let endDate;

  //   if (this.data.date) {
  //     startDate = moment(new Date(this.data.date)).format("YYYY-MM-DD")
  //     endDate = moment(new Date(this.data.date)).format("YYYY-MM-DD")
  //   } else {
  //     startDate =  moment(new Date(this.data.period[0])).format("YYYY-MM-DD")
  //     endDate =  moment(new Date(this.data.period[1])).format("YYYY-MM-DD")
  //   }
  //   await new Promise<void>((resolve, reject) => { 
  //     this.calendarService.checkDisponibilityService(this.profile._id, this.isNight, startDate, endDate).subscribe( res => {
  //       disponibility = res.result
  //       resolve();
  //     })
  //   })
  //   return disponibility
  // }

  initVariables(): void {
    if (!this.isCycleConfig) {
      Promise.all([this.initIsAvailableRule()])
        .then((data) => {
          this.initIsButtonDisabledVariables();
        })
        .catch((error) => {
          console.error(error);
        })
    } else {
      this.isEditButtonDisabled = false;
      this.isDeleteButtonDisabled = false;
    }
  }

  initIsButtonDisabledVariables(): void {
    const isCadreBlocWithMediumLevel =
      this.userService.isCadreBlocWithMediumLevel();
    const isCadreBlocWithHighLevel =
      this.userService.isCadreBlocWithHighLevel();
    const isAnesthResponsible = this.userService.isAnesthResponsable();
    const isIADEResponsible = this.userService.isIadRes();

    if (NURSE_TYPES.includes(this.profile.position)) {
      // If the profile in question is a nurse
      if (isCadreBlocWithMediumLevel || isCadreBlocWithHighLevel) {
        this.isEditButtonDisabled = false;
        this.isDeleteButtonDisabled = false;
      } else {
        if (this.isDateAlreadyPassed()) {
          this.isEditButtonDisabled = true;
          this.isDeleteButtonDisabled = true;
          this.deleteButtonTooltip =
            "Vous ne pouvez pas supprimer un jour antérieur à aujourd'hui.";
          this.editButtonTooltip =
            "Vous ne pouvez pas modifier un jour antérieur à aujourd'hui.";
          this.switchButtonTooltip =
            "Vous ne pouvez pas échanger un jour antérieur à aujourd'hui.";
        } else {
          this.isEditButtonDisabled = false;
          if (!this.hospital.isBlocked && !this.isAvailableRule) {
            this.isDeleteButtonDisabled = false;
          } else {
            this.isDeleteButtonDisabled = true;
            this.deleteButtonTooltip =
              "Vous ne pouvez pas supprimer ce jour car une règle bloque celui-ci";
          }
        }
      }
    } else if (ANESTHETIST_TYPES.includes(this.profile.position)) {
      // If the profile in question is an anesth
      if (
        isCadreBlocWithHighLevel ||
        isAnesthResponsible ||
        isIADEResponsible
      ) {
        this.isEditButtonDisabled = false;
        this.isDeleteButtonDisabled = false;
      } else {
        if (this.isDateAlreadyPassed()) {
          this.isEditButtonDisabled = true;
          this.isDeleteButtonDisabled = true;
          this.deleteButtonTooltip =
            "Vous ne pouvez pas supprimer un jour antérieur à aujourd'hui.";
          this.editButtonTooltip =
            "Vous ne pouvez pas modifier un jour antérieur à aujourd'hui.";
        } else {
          this.isEditButtonDisabled = false;
          if (!this.hospital.isBlocked && !this.isAvailableRule) {
            this.isDeleteButtonDisabled = false;
          } else {
            this.isDeleteButtonDisabled = true;
            this.deleteButtonTooltip =
              "Vous ne pouvez pas supprimer ce jour car une règle bloque celui-ci";
          }
        }
      }
    }

    if (!this.isMultiDaySelection && !this.dayCalendar) {
      this.isDeleteButtonDisabled = true;
      this.deleteButtonTooltip =
        "Aucune disponibilité n'est encore définie pour cette date et ce profil";
    }
  }

  isDateAlreadyPassed(): boolean {
    const today = new Date();
    if (this.isMultiDaySelection) {
      return new Date(this.periodToUpdate[0]) < today;
    } else {
      return new Date(this.date) < today;
    }
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  // getProfileNextWeekAvailabilities() {
  //   if (this.nextWeekCalendarsSubscription) {
  //     this.nextWeekCalendarsSubscription.unsubscribe();
  //   }
  //   let nextDay = moment(this.date).clone().add(7, "d");
  //   this.nextWeekCalendarsSubscription = this.profileService
  //     .getProfileWeekAvailability(
  //       this.profile._id,
  //       moment(nextDay).toISOString()
  //     )
  //     .subscribe(
  //       (weekCalendars) => {
  //         this.nextWeekCalendars = weekCalendars;
  //       },
  //       (error) => this.errorService.handleError(error)
  //     );
  // }

  changePreselectedReasonMap(selectedReasonMap: ReasonMap, autoSelectHoursFromReasonMap: boolean = true) {
    if (!selectedReasonMap) {
      this.selectedReasonMap = null;
      return ;
    }
    if (this.customDescription) {
      this.customDescription.setValue(selectedReasonMap.label);
    }
    this.preselectedReason = this.data.reasons[0].reasons.find((reason) => reason._id === selectedReasonMap.reason);
    
    if (autoSelectHoursFromReasonMap  && selectedReasonMap.morningStartTime && selectedReasonMap.afternoonEndTime) {
      this.setStartTime(getHoursAndMinutes(selectedReasonMap.morningStartTime));
      this.setEndTime(getHoursAndMinutes(selectedReasonMap.afternoonEndTime));
      
      if (this.startTime) {
        this.startTime.setTime(getHoursAndMinutes(selectedReasonMap.morningStartTime))
      }
      if (this.endTime) {
        this.endTime.setTime(getHoursAndMinutes(selectedReasonMap.afternoonEndTime))
      }
    }
    
    this.selectedReasonMap = selectedReasonMap;
    this.formGroup.get("reason").setValue(this.preselectedReason)
  }

  setReasons() {
    let reasons = [];
    if (
      this.data.isCycleConfig ||
      (this.data.period && this.data.period.length === 2)
    ) {
      reasons = this.setCycleMultiselectReasons();
    } else {
      reasons = this.currentReasons
    }

    reasons = reasons.filter((reason) => !reason.disabled);

    this.absenceReasons = [...reasons].filter((reason) => {
      const RESIDENCIES = reason.residencies.map((residency) =>
        residency.toLowerCase()
      );
      const POSTES = this.getPostes(reason);
      const SENIORITY =
        reason.seniority && reason.seniority.length != 0
          ? reason.seniority
          : ["Senior", "Junior", "Interne"];
      return (
        reason.availability === "ABSENT" &&
        POSTES.includes(RemoveAccents(this.profile.position).toLowerCase()) &&
        RESIDENCIES.includes(this.profile.residency.toLowerCase()) &&
        (this.profile.position != ANESTHETIST ||
          SENIORITY.includes(this.profile.seniority) ||
          !this.profile.seniority) &&
        reason.period === this.period
      );
    });

    this.presenceReasons = [...reasons].filter((reason) => {
      const RESIDENCIES = reason.residencies.map((residency) =>
        residency.toLowerCase()
      );
      const POSTES = this.getPostes(reason);

      const SENIORITY =
      reason.seniority && reason.seniority.length != 0
          ? reason.seniority
          : ["Senior", "Junior", "Interne"];
      return (
        reason.availability === "AVAILABLE" &&
        POSTES.includes(RemoveAccents(this.profile.position).toLowerCase()) &&
        RESIDENCIES.includes(this.profile.residency.toLowerCase()) &&
        (this.profile.position != ANESTHETIST ||
          SENIORITY.includes(this.profile.seniority) ||
          !this.profile.seniority) &&
        reason.period === this.period
      );
    });
  }

  setCycleMultiselectReasons() {
    let reasons = [];
    if (this.data && this.currentReasons) {
      reasons = this.currentReasons.filter(r => {
        return !this.data.rules.find(rule => {
          const RESIDENCIES = rule.residencies.map((residency) => residency.toLowerCase());
          const POSTES = this.getPostes(rule);
          return (
            rule.reason._id === r._id &&
            POSTES.includes(
              RemoveAccents(this.profile.position).toLowerCase()
            ) &&
            RESIDENCIES.includes(this.profile.residency.toLowerCase())
          );
        });
      });
    }
    return reasons;
  }

  getPostes(reason: Reason | Rule) {
    let POSTES = [];
    reason.postes.map((res) => {
      if (res === ANESTHETIST) {
        POSTES = [...POSTES, ...[RemoveAccents(ANESTHETIST).toLowerCase()]];
      } else if (res === Iade) {
        POSTES = [...POSTES, ...[RemoveAccents(Iade).toLowerCase()]];
      } else if (res === NURSE) {
        POSTES = [
          ...POSTES,
          ...NURSE_TYPES.map((type) => RemoveAccents(type).toLowerCase()),
        ];
      } else {
        POSTES = [...POSTES, ...RemoveAccents(res).toLowerCase()];
      }
    });
    return POSTES;
  }

  makeForm(): void {
    this.isCycleConfig = this.data.isCycleConfig;
    if (this.isCycleConfig) {
      this.makeCycleCalendarForm();
    } else {
      this.makeCalendarForm();
      this.wasGuard = isGuard(this.dayCalendar);
    }
  }

  initIsAvailableRule(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (!this.isCycleConfig) {
          for (const hospital of this.hospitals) {
            this.validateRulesService
            .getMostRecentValidateRules(
              this.data.profile.position,
              this.data.profile.seniority,
              this.data.profile.residency,
              this.data.date ? this.data.date : this.data.period[0],
              hospital._id
            )
            .subscribe(
              (res) => {
                if (!res || res.length !== 0) {
                  this.isAvailableRuleByHospital.push( { hospitalId: hospital._id, isAvailableRule: true } );
                } else {
                  this.isAvailableRuleByHospital.push( { hospitalId: hospital._id, isAvailableRule: false } );
                }
                this.setValidationRule();
                resolve(true);
              },
              (err) => {
                reject(err);
              }
            );
          }
      } else {
        resolve(true);
      }
    });
  }

  setReasonForm() {
    let endTime: string;
    let startTime: string;
    let modified: boolean = false;
    var reason;
    if (!this.isMultiDaySelection) {
      if (
        this.data &&
        this.data.dayIndex != null &&
        this.data.dayIndex != undefined &&
        this.data.weekCalendars &&
        this.data.weekCalendars.length
      ) {
        if (
          this.data.weekCalendars[this.data.dayIndex] &&
          this.data.weekCalendars[this.data.dayIndex].reason
        ) {
          // searching for reason in absence / presence array to display it as the default reason
          // the data reason here is the reason already set
          this.data.weekCalendars[this.data.dayIndex].reason.availability ==
          "ABSENT"
            ? (reason = this.absenceReasons.find(
                (elet) =>
                  elet._id ==
                    this.data.weekCalendars[this.data.dayIndex].reason._id &&
                  elet.title ==
                    this.data.weekCalendars[this.data.dayIndex].reason.title
              ))
            : this.doesHospitalHaveAnesthDetailsOption &&
              this.data.weekCalendars[this.data.dayIndex].consultation
            ? (reason = this.presenceReasons.find(
                (elet) => elet.title.toLowerCase() == "consultation"
              ))
            : (reason = this.presenceReasons.find(
                (elet) =>
                  elet._id ==
                    this.data.weekCalendars[this.data.dayIndex].reason._id &&
                  elet.title ==
                    this.data.weekCalendars[this.data.dayIndex].reason.title
              ));
        } else {
          reason = this.isAbsence
            ? this.absenceReasons[0]
            : this.presenceReasons[0];
        }
      }
    } else {
      reason = this.isAbsence
        ? this.absenceReasons[0]
        : this.presenceReasons[0];
    }

    this.initalReason = reason;

    if (!this.initalReason) {
      let position = this.profile.position
      if (NURSE_TYPES.includes(this.profile.position))
        position = NURSE
      this.initalReason = this.currentReasons.find(a => { return a.postes.includes(position) })
    }

    if (this.initalReason && this.initalReason.availability == "ABSENT") {
      endTime = moment.utc(this.hospital.endTime).format("HH:mm");
      startTime = moment.utc(this.hospital.startTime).format("HH:mm");
      modified = true;
    }

    this.displayOtherReason = reason && reason.freeComment;

    if (this.formGroup) {
      this.formGroup.get("reason").setValue(this.initalReason)
      if (modified) {
        this.formGroup.get("endTime").setValue(endTime)
        this.formGroup.get("startTime").setValue(startTime)      
      }
    }
  }

  changeHospital(event?) {
    return new Promise<void>((resolve, reject) => {
      const hospital = event
      this.initHospital(hospital._id);
      const hospitalReasons = this.data.reasons.find((hospital_) => String(hospital_._id) === String(hospital._id))
      if (hospitalReasons) {
        this.currentReasons = hospitalReasons.reasons;
      } else {
        this.currentReasons = [];
      }
      this.setReasons();
      this.setReasonForm();
      this.setValidationRule();

      return resolve()
    })
  }

  setValidationRule() {
    for (const hospital of this.isAvailableRuleByHospital) {
      if (this.hospital._id === hospital.hospitalId) {
        this.isAvailableRule = hospital.isAvailableRule;
      }
    }
  }

  initHospital(hospitalId): void {
    this.hospital = this.profile.hospitals.find((hospital) => String(hospital._id) == String(hospitalId))
  }

  checkUpIfAuthorized(action) {
    if (action === "supprimer") {
      this.isDeleting = true;
      this.deleteCalendar();
      return;
    }
    if (action === "modifier") {
      this.isSending = true;
      this.save();
      return;
    }
  }

  makeCycleCalendarForm() {
    if (this.data.period && this.data.period.length === 2) {
      this.isMultiDaySelection = true;
      this.date = this.data.period[0];
      this.periodToUpdate = this.data.period;
    } else {
      this.cycleCalendar = this.data.cycleCalendar;
    }
    let endTime: string;
    let startTime: string;

    if (this.cycleCalendar) {
      startTime = moment
        .utc(this.cycleCalendar.morningStartTime)
        .format("HH:mm");
      endTime = moment.utc(this.cycleCalendar.afternoonEndTime).format("HH:mm");
    }
    else {
      endTime = moment.utc(this.hospital.endTime).format("HH:mm");
      startTime = moment.utc(this.hospital.startTime).format("HH:mm");
    }

    var reason;
    if (
      this.cycleCalendar &&
      this.cycleCalendar.reason &&
      this.cycleCalendar.reason.title
    ) {
      reason = this.presenceReasons.find(
        (elet) => elet.title == this.cycleCalendar.reason.title
      );
    } else {
      reason = this.presenceReasons[0];
    }

    this.initalReason = reason;
    this.formGroup = this.formBuilder.group({
      week: [
        { value: this.data.weekNumber, disabled: true },
        [Validators.required],
      ],
      day: [
        { value: DAYS[this.data.dayIndex], disabled: true },
        [Validators.required],
      ],
      reason: [reason, [Validators.required]],
      startDate: [
        {
          value: this.periodToUpdate[0]
            ? moment(this.periodToUpdate[0]).format("dddd")
            : "",
          disabled: true,
        },
        [Validators.required],
      ],
      endDate: [
        {
          value: this.periodToUpdate[1]
            ? moment(this.periodToUpdate[1]).format("dddd")
            : "",
          disabled: true,
        },
        [Validators.required],
      ],
      description: [
        { value: "BLOC", disabled: true },
        [Validators.required, Validators.minLength(2)],
      ],
      startTime: [
        startTime ? startTime : HOSPITAL_START_TIME,
        [
          Validators.required,
          Validators.pattern(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/),
        ],
      ],
      endTime: [
        endTime ? endTime : HOSPITAL_END_TIME,
        [
          Validators.required,
          Validators.pattern(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/),
        ],
      ],
      hospitals: [this.hospitals[0], [Validators.required]]
    });
  }

  saveCycleCalendar() {
    this.isSending = true;
    if (this.isMultiDaySelection) {
      const calendar = this.generateCycleCalendar();
      const indexes = this.generatePeriodCycleIndexes(
        this.periodToUpdate[0],
        this.periodToUpdate[1]
      );
      this.updatePeriodAvailabilityCycle(calendar, indexes);
    } else {
      if (this.cycleCalendar) {
        this.updateCycleCalendar();
      } else {
        this.createCycleCalendar();
      }
    }
  }

  checkWorkTime(fromDate, toDate, startTime, endTime, hospitalId): Promise<void> {
    return new Promise((resolve, reject) => {
      if (
        this.formGroup.value.availability === "ABSENT"    
      ) {
        this.overflowConfirmationStatus = true;
        resolve();
      } else {
        if (this.checkWorkTimeSubscription) {
          this.checkWorkTimeSubscription.unsubscribe();
        }
        this.checkWorkTimeSubscription = this.calendarService
          .checkWorkTime(this.profile._id, fromDate, toDate, startTime, endTime, hospitalId)
          .subscribe((result) => {
            if (result.result.overflow) {             

              const formattedFirstDate =  moment(result.result.firstDay).format('D MMMM YYYY');
              const formattedLastDate = moment(result.result.lastDay).format('D MMMM YYYY');
              
              let description =
                "Attention, en validant cette modification du calendrier de " +
                toPascalCase(this.profile.firstName) +
                " " +
                toPascalCase(this.profile.lastName) +
                `, son nombre d'heures cumulées travaillées dépasse les <b>${result.result.maxTime}h</b> sur la période du ${formattedFirstDate} au ${formattedLastDate}`;
              this.popupManagerService
                .openConfirmationPopup(
                  "Dépassement heures cumulées",
                  description,
                  450,
                  "primary",
                  "Valider"
                )
                .afterClosed()
                .subscribe((res) => {
                  if (res) {
                    this.overflowConfirmationStatus = true;
                  } else {
                    this.overflowConfirmationStatus = false;
                  }
                  resolve();
                });
            } else {
              this.overflowConfirmationStatus = true;
              resolve();
            }
          });
      }
    });
  }

  generatePeriodCycleIndexes(from, to) {
    const indexes = [];
    const fromIndex = moment(from).isoWeekday();
    const toIndex = moment(to).isoWeekday();

    for (let i = fromIndex; i <= toIndex; i++) {
      const index = i === 7 ? 1 : i + 1;
      indexes.push(index);
    }

    return indexes;
  }

  async updatePeriodAvailabilityCycle(
    calendar: cycleCalendar,
    indexes: number[]
  ): Promise<void> {
    /*
    let fTime = new Date(calendar.morningStartTime);
    fTime.setUTCHours(0, 0, 0, 0);
    let tTime = new Date(calendar.afternoonEndTime);
    tTime.setUTCHours(0, 0, 0, 0);
    let sTime = new Date(calendar.morningStartTime);
    let eTime = new Date(calendar.afternoonEndTime);
    await this.checkWorkTime(
      fTime.toISOString(),
      tTime.toISOString(),
      sTime.toISOString(),
      eTime.toISOString()
    );
    if (this.overflowConfirmationStatus) {
      this.overflowConfirmationStatus = false;
      this.updateCalendarSubscription = this.cycleService
        .updatePeriodAvailabilityCycle(calendar, indexes)
        .subscribe(
          () => {
            this.isSending = false;
            this.dialogRef.close("DONE");
          },
          (error) => {
            this.isSending = false;
            this.errorService.handleError(error);
          }
        );
    }
    */

    this.updateCalendarSubscription = this.cycleService
      .updatePeriodAvailabilityCycle(calendar, indexes)
      .subscribe(
        () => {
          this.isSending = false;
          this.dialogRef.close("DONE");
        },
        (error) => {
          this.isSending = false;
          this.errorService.handleError(error);
        }
      );
  }

  async updateCycleCalendar() {
    const calendar = this.generateCycleCalendar();
    this.disableClose();

    /*
    let fTime = new Date(calendar.morningStartTime);
    fTime.setUTCHours(0, 0, 0, 0);
    let tTime = new Date(calendar.afternoonEndTime);
    tTime.setUTCHours(0, 0, 0, 0);
    let sTime = new Date(calendar.morningStartTime);
    let eTime = new Date(calendar.afternoonEndTime);
    await this.checkWorkTime(
      fTime.toISOString(),
      tTime.toISOString(),
      sTime.toISOString(),
      eTime.toISOString()
    );
    if (this.overflowConfirmationStatus) {
      this.overflowConfirmationStatus = false;
      this.updateCycleCalendarSubscription = this.cycleService
        .updateCycleCalendar(calendar)
        .subscribe((res) => {
          this.isSending = false;
          this.enableClose();
          this.dialogRef.close(res);
        });
    } else {
      this.isSending = false;
      this.enableClose();
      this.dialogRef.close("DONE");
    }
    */

    this.updateCycleCalendarSubscription = this.cycleService
      .updateCycleCalendar(calendar)
      .subscribe((res) => {
        this.isSending = false;
        this.enableClose();
        this.dialogRef.close(res);
      });
  }

  createCycleCalendar() {
    const calendar = this.generateCycleCalendar();
    this.disableClose();
    this.createCycleCalendarSubscription = this.cycleService
      .createCycleCalendar(calendar)
      .subscribe(
        (res) => {
          this.isSending = false;
          this.enableClose();
          this.dialogRef.close(res);
        },
        (error) => {
          this.isSending = false;
          this.enableClose();
          this.errorService.handleError(error);
        }
      );
  }

  deleteCalendar() {
    this.isDeleting = true;
    if (this.isCycleConfig) {
      this.isDeleting = true;
      if (this.isMultiDaySelection) {
        const calendar = this.generateCycleCalendar();
        const indexes = this.generatePeriodCycleIndexes(
          this.periodToUpdate[0],
          this.periodToUpdate[1]
        );
        this.deleteCalendarSubscription = this.cycleService
          .deletePeriodCycle(calendar, indexes)
          .subscribe(
            (res) => {
              this.isDeleting = false;
              this.dialogRef.close(res);
            },
            (error) => {
              this.isDeleting = false;
              this.errorService.handleError(error);
            }
          );
      } else {
        this.deleteCycleCalendar();
      }
    } else {
      this.checkIfProfileInProgram("delete");
    }
  }

  deleteCycleCalendar() {
    this.disableClose();
    this.deleteCalendarSubscription = this.cycleService
      .deleteCalendar(this.cycleCalendar)
      .subscribe(
        (res) => {
          this.isDeleting = false;
          this.enableClose();
          this.dialogRef.close(res);
        },
        (error) => {
          this.isDeleting = false;
          this.enableClose();
          this.errorService.handleError(error);
        }
      );
  }

  checkIfProfileInProgram(type?: string) {
    let startDate, endDate;
    if (this.isMultiDaySelection) {
      startDate = moment(this.periodToUpdate[0]).toISOString();
      endDate = moment(this.periodToUpdate[1]).toISOString();
    } else {
      startDate = this.date;
      endDate = this.date;
    }

    if (this.isNight) {
      if (type === "update") {
        this.saveCR();
      } else if (type === "delete") {
        this.deleteNormalCalendar();
      }
    } else {
      this.getProgamsSubsc = this.BufferProgramService.getPrograms(
        startDate,
        endDate,
        this.profile._id
      ).subscribe((res: any[]) => {
        if (res.length == 0) {
          if (type === "update") {
            this.saveCR();
          } else if (type === "delete") {
            this.deleteNormalCalendar();
          }
        } else {
          this.openConfiPopup(res, type);
        }
      });
    }
  }

  saveCR() {
    this.data.view === "accountView"
      ? this.saveCalendarRequest()
      : this.saveCalendar();
  }

  openConfiPopup(programs, type?: string) {
    let description = "";
    if (this.lvlOfAccess <= 2) {
      description = `<p>Vous êtes déjà inscrit dans un programme:</p>`;
    } else {
      description = `<p>Cette personne est déjà inscrite dans un programme:</p>`;
    }

    for (let i = 0; programs[i]; i++) {
      const test = this.formatMessageConfirmationPopup.transform(
        programs[i],
        undefined,
        this.profile._id
      );
      if (test) {
        description += `<p>${test}</p>`;
      }
    }
    if (
      (this.isAvailableRule || this.hospital.isBlocked) &&
      this.lvlOfAccess <= 2
    ) {
      description += `<p>Etes vous sûr(e) de vouloir demander un changement de disponibilité ?</p>`;
      this.popupManagerService
        .openConfirmationPopup(
          "Modifier cette disponibilité ?",
          description,
          370,
          "danger",
          "Oui, demander"
        )
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.saveCR();
          } else {
            this.isSending = false;
            this.isDeleting = false;
          }
        });
    } else {
      if (type === "update") {
        description += `<p>Etes vous sûr(e) de vouloir modifier cette disponibilité ?</p>`;
        this.popupManagerService
          .openConfirmationPopup(
            "Modifier cette disponibilité ?",
            description,
            370,
            "danger",
            "Oui, modifier"
          )
          .afterClosed()
          .subscribe((result) => {
            if (result) {
              this.saveCR();
            } else {
              this.isSending = false;
              this.isDeleting = false;
            }
          });
      } else if (type === "delete") {
        description += `<p>Etes vous sûr(e) de vouloir supprimer cette disponibilité ?</p>`;
        this.popupManagerService
          .openConfirmationPopup(
            "Supprimer cette disponibilité ?",
            description,
            370,
            "danger",
            "Oui, supprimer"
          )
          .afterClosed()
          .subscribe((result) => {
            if (result) {
              this.deleteNormalCalendar();
            } else {
              this.isSending = false;
              this.isDeleting = false;
            }
          });
      }
    }
  }

  deleteNormalCalendar() {
    this.disableClose();
    if (this.isMultiDaySelection) {
      let fromDate = moment(this.periodToUpdate[0]).toISOString();
      let toDate = moment(this.periodToUpdate[1]).toISOString();
      this.deleteCalendarSubscription = this.calendarService
        .deleteMultipleCalendars(this.profile._id, fromDate, toDate)
        .subscribe(
          (res) => {
            this.enableClose();
            this.dialogRef.close("DONE");
            this.isDeleting = false;
          },
          (error) => {
            this.enableClose();
            this.errorService.handleError(error);
            this.isDeleting = false;
          }
        );
    } else {
      this.deleteCalendarSubscription = this.calendarService
        .removeDayavailability(this.dayCalendar._id)
        .subscribe(
          (res) => {
            this.enableClose();
            this.dialogRef.close(res);
            this.isDeleting = false;
          },
          (error) => {
            this.enableClose();
            this.errorService.handleError(error);
            this.isDeleting = false;
          }
        );
    }
  }

  generateCycleCalendar() {
    const calendar: cycleCalendar = {} as cycleCalendar;
    const startTime: string = this.formGroup.controls.startTime.value;
    const endTime: string = this.formGroup.controls.endTime.value;
    const date = new Date();
    if (this.cycleCalendar) {
      calendar._id = this.cycleCalendar._id;
    }
    calendar.day = this.data.dayIndex === 6 ? 1 : this.data.dayIndex + 2;
    calendar.week = this.data.weekNumber;
    calendar.morningStartTime = this.generateDate(startTime, date);
    calendar.morningEndTime = this.generateDate(
      NURSE_MORNING_SHIFT_END_TIME,
      date
    );
    calendar.afternoonEndTime = this.generateDate(endTime, date);
    calendar.afternoonStartTime = this.generateDate(
      NURSE_AFTERNOON_SHIFT_START_TIME,
      date
    );
    this.setNurseAccountTimes(calendar, date, startTime, endTime);
    calendar.profile = this.profile;
    calendar.hospital = this.hospital
    calendar.customDescription = "";
    calendar.isExtraclinique = this.description.value.title == "Extraclinique";
    calendar.reason = this.description.value._id;
    calendar.reasonMap = this.selectedReasonMap ? this.selectedReasonMap._id : null;
    calendar.nurseCycle = this.data.cycle._id;
    return calendar;
  }

  setAbsencesPresencesReasons() {
    if (this.isNurse()) {
      this.absenceReasons.splice(4, 1);
      this.presenceReasons.splice(2, 1);
    } else {
      this.presenceReasons.splice(-1, 1);
    }
  }

  makeCalendarForm() {
    this.showComment = this.userService.isUserHasLowLevelAccess();
    if (this.data.period && this.data.period.length === 2) {
      this.isMultiDaySelection = true;
      this.date = this.data.period[0];
      this.periodToUpdate = this.data.period;
      if (this.isNight) {
        this.periodToUpdate[0].setHours(0);
        this.periodToUpdate[0].setMinutes(0);

        this.periodToUpdate[1].setHours(0);
        this.periodToUpdate[1].setMinutes(0);

        this.periodToUpdate[0] = (this.hospital.nightStartTime ? new Date(this.hospital.nightStartTime) : this.periodToUpdate[0])
        this.periodToUpdate[1] = (this.hospital.nightEndTime ? new Date(this.hospital.nightEndTime) : this.periodToUpdate[1])
      
      }
    } else {
      this.dayIndex = this.data.dayIndex;
      this.weekCalendars = this.data.weekCalendars;
      if (this.weekCalendars) {
        this.dayCalendar = this.weekCalendars[this.dayIndex];
      }
    }
    let endTime: string;
    let startTime: string;
    let availability: string = "AVAILABLE";
    if (!this.isMultiDaySelection) {
      if (this.dayCalendar) {
        availability = this.setAvailability(this.dayCalendar);
        startTime = moment
          .utc(this.dayCalendar.morningStartTime)
          .format("HH:mm");
        endTime = moment.utc(this.dayCalendar.afternoonEndTime).format("HH:mm");

        this.date = this.dayCalendar.date;
        this.isAbsence = availability === "ABSENT";
      } else {
        this.date = this.data.date;
        endTime = moment.utc(this.hospital.endTime).format("HH:mm");
        startTime = moment.utc(this.hospital.startTime).format("HH:mm");
      }
    }
    else {
      endTime = moment.utc(this.hospital.endTime).format("HH:mm");
      startTime = moment.utc(this.hospital.startTime).format("HH:mm");
    }

    if (this.isNight)
      endTime = moment.utc(this.hospital.nightEndTime).format("HH:mm");
    if (this.isNight)
      startTime = moment.utc(this.hospital.nightStartTime).format("HH:mm");


    const date = this.getDate(this.date);
    var reason;
    if (!this.isMultiDaySelection) {
      if (
        this.data &&
        this.data.dayIndex != null &&
        this.data.dayIndex != undefined &&
        this.data.weekCalendars &&
        this.data.weekCalendars.length
      ) {
        if (
          this.data.weekCalendars[this.data.dayIndex] &&
          this.data.weekCalendars[this.data.dayIndex].reason
        ) {
          // searching for reason in absence / presence array to display it as the default reason
          // the data reason here is the reason already set
          this.data.weekCalendars[this.data.dayIndex].reason.availability ==
          "ABSENT"
            ? (reason = this.absenceReasons.find(
                (elet) =>
                  elet._id ==
                    this.data.weekCalendars[this.data.dayIndex].reason._id &&
                  elet.title ==
                    this.data.weekCalendars[this.data.dayIndex].reason.title
              ))
            : this.doesHospitalHaveAnesthDetailsOption &&
              this.data.weekCalendars[this.data.dayIndex].consultation
            ? (reason = this.presenceReasons.find(
                (elet) => elet.title.toLowerCase() == "consultation"
              ))
            : (reason = this.presenceReasons.find(
                (elet) =>
                  elet._id ==
                    this.data.weekCalendars[this.data.dayIndex].reason._id &&
                  elet.title ==
                    this.data.weekCalendars[this.data.dayIndex].reason.title
              ));
        } else {
          reason = this.isAbsence
            ? this.absenceReasons[0]
            : this.presenceReasons[0];
        }
      }
    } else {
      reason = this.isAbsence
        ? this.absenceReasons[0]
        : this.presenceReasons[0];
    }

    this.initalReason = reason;

    if (!this.initalReason) {
      let position = this.profile.position
      if (NURSE_TYPES.includes(this.profile.position))
        position = NURSE
      this.initalReason = this.currentReasons.find(a => { return a.postes.includes(position) })
    }

    if (this.initalReason.availability == "ABSENT") {
      endTime = moment.utc(this.hospital.endTime).format("HH:mm");
      startTime = moment.utc(this.hospital.startTime).format("HH:mm");
    }

    this.displayOtherReason = reason && reason.freeComment;
    
    this.formGroup = this.formBuilder.group({
      date: [{ value: date, disabled: true }, [Validators.required]],
      startDate: [
        { value: this.getDate(this.periodToUpdate[0]), disabled: true },
        [Validators.required],
      ],
      endDate: [
        { value: this.getDate(this.periodToUpdate[1]), disabled: true },
        [Validators.required],
      ],
      startTime: [
        startTime ? startTime : HOSPITAL_START_TIME,
        [
          Validators.required,
          Validators.pattern(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/),
        ],
      ],
      endTime: [
        endTime ? endTime : HOSPITAL_END_TIME,
        [
          Validators.required,
          Validators.pattern(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/),
        ],
      ],
      reason: [reason, [Validators.required]],
      availability: [availability, [Validators.required]],
      comment: [],
      customDescription: [
        this.dayCalendar && this.displayOtherReason
          ? this.dayCalendar.customDescription
          : "",
      ],
      hospitals: [this.hospitals[0], [Validators.required]]
    });
  }

  getDefaultReason(availabilty?: string): Reason {
    let reason;
    if (availabilty === "ABSENT") {
      reason = this.absenceReasons.length > 0 ? this.absenceReasons[0] : null;
    } else {
      reason = this.presenceReasons.length > 0 ? this.presenceReasons[0] : null;
    }
    return reason;
  }

  setDefaultReason(calendar: Calendar, availability ?: string) {
    let extraClinique = this.currentReasons.filter(ele => {return ele.title == "Extraclinique" && ele.availability == availability})
    if (this.data.weekCalendars) {
      let day = this.data.weekCalendars.filter((ele) => {
        return (
          ele &&
          new Date(ele.date).getTime() == new Date(this.data.date).getTime()
        );
      });

      if (
        calendar &&
        day &&
        day.length > 0 &&
        day[0].reason &&
        day[0].reason.title == "Extraclinique"
      ) {
        if (extraClinique.length > 0) {
          this.data.reason = extraClinique[0];
          return extraClinique[0];
        }
        return this.data.reason;
      }
    }
    const defaultReason = this.getDefaultReason(availability);
    return calendar &&
      calendar.reason &&
      (<Reason>calendar.reason).availability === availability
      ? this.setReason(calendar.reason)
      : defaultReason;
  }

  getDefaultReasonEmpty() {
    let position = this.profile.position;
    if (NURSE_TYPES.includes(this.profile.position)) position = NURSE;

    if (this.isNight) {
      if (position == NURSE) {
        let data = this.currentReasons.filter(ele => {return ele.postes.includes(position) && ele.title.toLowerCase() == "astreinte"})
        return data.length > 0? data[0]
          : this.currentReasons.filter(a => { return a.postes.includes(position) })[0]
      }
      else if (position == ANESTHETIST) {
        let data = this.currentReasons.filter(ele => {return ele.postes.includes(position) && ele.title.toLowerCase() == "garde"})
        return data.length > 0? data[0]
        : this.currentReasons.filter(a => { return a.postes.includes(position) })[0]
      }
      return this.currentReasons.filter(a => { return a.postes.includes(position) })[0]
    }
    else {
      if (position == NURSE) {
        let data = this.currentReasons.filter(ele => {return ele.postes.includes(position) && ele.title.toLowerCase() == "bloc"})
        return data.length > 0? data[0] : this.currentReasons.filter(a => { return a.postes.includes(position) })[0]
      }
      else if (IADE.includes(position)) {
        let data = this.currentReasons.filter(ele => {return ele.postes.includes(position) && ele.title.toLowerCase() == "bloc"})
        return data.length > 0? data[0] : this.currentReasons.filter(a => { return a.postes.includes(position) })[0]
      }
      let data = this.currentReasons.filter(ele => {return ele.postes.includes(position) && ele.title.toLowerCase() == "clinique"})
      return data.length > 0? data[0] : this.currentReasons.filter(a => { return a.postes.includes(position) })[0]
    }
  }

  setAvailability(calendar: Calendar) {
    let availability = "AVAILABLE";
    if (calendar && calendar.reason && (<Reason>calendar.reason).availability) {
      availability = this.dayCalendar.reason.availability.match(/abs/i)
        ? "ABSENT"
        : "AVAILABLE";
    }
    return availability;
  }

  setReason(reason) {
    if (reason._id) {
      return [...this.absenceReasons, ...this.presenceReasons].find(
        (r) => r._id === reason._id && r._id !== null
      );
    } else {
      return [...this.absenceReasons, ...this.presenceReasons].find(
        (r) => r.title === reason.title && r._id === null
      );
    }
  }

  calculateMinutes(time: string): number {
    return parseFloat(time.split(":")[0]) * 60 + parseFloat(time.split(":")[1]);
  }

  generateCalendar(
    startTime: string,
    endTime: string,
    availability?: string,
    reason?: Reason,
    reasonMap?: ReasonMap | string,
    customDescription?: string,
    date?: Date,
  ): Calendar {
    const calendar: Calendar = {} as Calendar;
    calendar.date = new Date(date || this.date);
    calendar.date.setUTCHours(0);
    if (!this.isNight) {
      calendar.morningStartTime = this.generateDate(startTime, date);
      calendar.afternoonEndTime = this.generateDate(endTime, date);  
    }
    else {
      calendar.nightStartTime = new Date(calendar.date)
      calendar.nightStartTime.setUTCHours(new Date(this.hospital.nightStartTime).getUTCHours(), new Date(this.hospital.nightStartTime).getUTCMinutes(), 0, 0)
      calendar.nightEndTime = new Date(calendar.date)
      calendar.nightEndTime.setUTCHours(new Date(this.hospital.nightEndTime).getUTCHours(), new Date(this.hospital.nightEndTime).getUTCMinutes(), 0, 0)
      calendar.nightEndTime = new Date(calendar.nightEndTime.getTime() + (24*60*60*1000))

      calendar.morningStartTime = null;
      calendar.morningEndTime = null;
      calendar.afternoonStartTime = null;
      calendar.afternoonEndTime = null;
    }
    if (this.isNurse()) {
      this.setNurseAccountTimes(calendar, date, startTime, endTime);
    }

    if (this.isAbsence) {
      if (this.isNight) {
        calendar.nightStartTime = new Date(calendar.date);
        calendar.nightStartTime.setUTCHours(0, 0, 0, 0);
        calendar.nightEndTime = new Date(calendar.date);
        calendar.nightEndTime.setDate(new Date(calendar.date).getDate() + 1);
        calendar.nightEndTime.setUTCHours(0, 0, 0, 0);
        calendar.morningStartTime = null;
        calendar.afternoonEndTime = null;
      } else {
        calendar.morningStartTime = new Date(calendar.date);
        calendar.morningStartTime.setUTCHours(0, 0, 0, 0);
        calendar.afternoonEndTime = new Date(calendar.date);
        calendar.afternoonEndTime.setUTCHours(0, 0, 0, 0);
        calendar.nightStartTime = null;
        calendar.nightEndTime = null;
      }
    }

    calendar.profile = this.profile;
    calendar.hospital = this.hospital
    calendar.customDescription = customDescription;
    calendar.availability = availability;
    calendar.reason = reason._id;
    calendar.isNight = this.isNight;
    calendar.reasonMap = reasonMap;
    return calendar;
  }

  generateDate(time: string, _date?: Date): Date {
    let date = new Date(_date || this.date);
    const splitTime = time.split(":");
    date.setUTCHours(+splitTime[0]);
    date.setUTCMinutes(+splitTime[1]);
    return date;
  }

  create(calendars: Calendar[]): void {
    this.disableClose();
    this.createCalendarSubscription = this.calendarService
      .createDayAvailability(
        calendars,
        this.formGroup.value.reason.title == "Extraclinique"
      )
      .subscribe(
        (res) => {
          this.enableClose();
          this.isSending = false;
          this.dialogRef.close({
            type: "CREATE",
            dayIndex: this.dayIndex,
            createdCalendar: res,
          });
        },
        (error) => {
          this.enableClose();
          this.isSending = false;
          this.errorService.handleError(error);
        }
      );
  }

  disableClose() {
    this.dialogRef.disableClose = true;
  }

  enableClose() {
    this.dialogRef.disableClose = false;
  }

  remove(): void {
    this.isDeleting = true;
    this.disableClose();
    this.calendarService.removeDayavailability(this.dayCalendar._id).subscribe(
      () => {
        this.enableClose();
        this.isDeleting = false;
        this.dialogRef.close({ type: "DELETE", dayIndex: this.dayIndex });
      },
      (error) => {
        this.enableClose();
        this.isDeleting = false;
        this.errorService.handleError(error);
      }
    );
  }

  updateCalendar(calendars: Calendar[]): void {
    this.disableClose();
    const isExtraclinique =
      this.formGroup.value.reason.title == "Extraclinique" &&
      this.formGroup.value.reason.availability == "AVAILABLE";
    this.updateCalendarSubscription = this.calendarService
      .changeDayAvailability(calendars, isExtraclinique)
      .subscribe(
        (res) => {
          this.enableClose();
          this.isSending = false;
          this.dialogRef.close({
            type: "UPDATE",
            dayIndex: this.dayIndex,
            updatedCalendar: res,
            refreshNextWeek: true,
          });
        },
        (error) => {
          this.enableClose();
          this.isSending = false;
          this.errorService.handleError(error);
        }
      );
  }

  save(): void {
    if (this.availability.value == "ABSENT") {
      this.checkIfProfileInProgram("update");
    } else {
      this.saveCR();
    }
  }

  async saveCalendarRequest() {
    const calendarRequest = this.generateCalendarRequest(); 
    if (
      calendarRequest.fromDate === calendarRequest.toDate &&
      !this.UserService.isUserHasLowLevelAccess()
    ) {
      this.isMultiDaySelection = false;
      this.saveCalendar();
    } else {
      let startTime;
      let endTime;
      if (!this.isNight) {
        startTime = new Date(calendarRequest.morningStartTime);
        endTime = new Date(calendarRequest.afternoonEndTime);
      } else {
        startTime = new Date(calendarRequest.nightStartTime);
        endTime = new Date(calendarRequest.nightEndTime);
      }

      await this.checkWorkTime(
        calendarRequest.fromDate,
        calendarRequest.toDate,
        startTime.toISOString(),
        endTime.toISOString(),
        calendarRequest.hospital._id
      );
      if (this.overflowConfirmationStatus) {
        this.overflowConfirmationStatus = false;
        this.disableClose();
        const isExtraclinique =
          this.formGroup.value.reason.title == "Extraclinique" &&
          this.formGroup.value.reason.availability == "AVAILABLE";
        this.createCRSubscription = this.calendarRequestService
          .createCalendarRequest(calendarRequest, isExtraclinique)
          .subscribe(
            (res) => {
              this.isSending = false;
              this.enableClose();
              this.dialogRef.close("DONE");
            },
            (err) => {
              this.isSending = false;
              this.errorService.handleError(err);
            }
          );
      } else {
        this.isSending = false;
        this.enableClose();
        this.dialogRef.close("DONE");
      }
    }
  }

  get availability() {
    return this.formGroup.controls["availability"];
  }

  get description() {
    return this.formGroup.controls["reason"];
  }

  get customDescription() {
    return this.formGroup.controls["customDescription"];
  }

  generateCalendarRequest(): any {
    const formValue = this.formGroup.value;
    const date = new Date(this.date);
    let calendarRequest: any = {
      fromDate: this.isMultiDaySelection
      ? moment(this.periodToUpdate[0]).toISOString()
      : moment(this.date).toISOString(),
      toDate: this.isMultiDaySelection
      ? moment(this.periodToUpdate[1]).toISOString()
      : moment(this.date).toISOString(),
      morningStartTime: this.generateDate(formValue.startTime, this.date),
      afternoonEndTime: this.generateDate(formValue.endTime, this.date),
      profile: this.profile ? this.profile._id : undefined,
      hospital: this.hospital,
      availability: formValue.availability,
      reason: formValue.reason ? formValue.reason._id : undefined,
      requestDescription: formValue.comment,
      isNight: this.isNight,
    };

    if (this.isNight) {
      calendarRequest.nightStartTime = new Date(calendarRequest.fromDate)
      calendarRequest.nightStartTime.setUTCHours(new Date(this.hospital.nightStartTime).getUTCHours(), new Date(this.hospital.nightStartTime).getUTCMinutes(), 0, 0)
      calendarRequest.nightEndTime = new Date(calendarRequest.fromDate)
      calendarRequest.nightEndTime.setUTCHours(new Date(this.hospital.nightEndTime).getUTCHours(), new Date(this.hospital.nightEndTime).getUTCMinutes(), 0, 0)
      calendarRequest.nightEndTime = new Date(calendarRequest.nightEndTime.getTime() + (24*60*60*1000))

      calendarRequest.morningStartTime = null;
      calendarRequest.morningEndTime = null;
      calendarRequest.afternoonStartTime = null;
      calendarRequest.afternoonEndTime = null;
    }

    if (this.description.value && this.description.value.freeComment)
      calendarRequest.customDescription = this.customDescription.value;
    else calendarRequest.customDescription = "";

    if (this.isNurse()) {
      this.setNurseAccountTimes(
        calendarRequest,
        date,
        formValue.startTime,
        formValue.endTime
      );
    }
    calendarRequest.reasonMap = this.selectedReasonMap ? this.selectedReasonMap._id : null;
    return calendarRequest;
  }

  setNurseAccountTimes(
    calendar: any,
    date: Date,
    startTime: string,
    endTime: string
  ) {
    if (this.isNight) return;
    calendar.morningEndTime = this.generateDate(
      NURSE_MORNING_SHIFT_END_TIME,
      date
    );
    calendar.afternoonStartTime = this.generateDate(
      NURSE_AFTERNOON_SHIFT_START_TIME,
      date
    );

    if (
      this.calculateMinutes(endTime) <=
      this.calculateMinutes(NURSE_MORNING_SHIFT_END_TIME)
    ) {
      calendar.morningEndTime = this.generateDate(endTime, date);
      calendar.afternoonStartTime = this.generateDate(endTime, date);
    } else if (
      this.calculateMinutes(startTime) >
      this.calculateMinutes(NURSE_AFTERNOON_SHIFT_START_TIME)
    ) {
      calendar.afternoonStartTime = this.generateDate(startTime, date);
      calendar.morningEndTime = this.generateDate(startTime, date);
    }
  }

  async saveCalendar() {
    const formValue = this.formGroup.value;
    if (formValue.reason) {   
      const calendar = this.generateCalendar(
        formValue.startTime,
        formValue.endTime,
        formValue.availability,
        formValue.reason,
        this.selectedReasonMap ? this.selectedReasonMap._id : null,
        formValue.customDescription,
      );

      let sTime: Date;
      let eTime: Date;
      let fTime: Date;
      let tTime: Date;
      if (!this.isNight) {
        sTime = new Date(calendar.morningStartTime);
        eTime = new Date(calendar.afternoonEndTime);
        fTime = new Date(calendar.morningStartTime);
        tTime = new Date(calendar.afternoonEndTime);
      } else {
        sTime = new Date(calendar.nightStartTime);
        eTime = new Date(calendar.nightEndTime);
        fTime = new Date(calendar.nightStartTime);
        tTime = new Date(calendar.nightEndTime);
      }
      fTime.setUTCHours(0, 0, 0, 0);
      tTime.setUTCHours(0, 0, 0, 0);
      if (!this.isCycleConfig) {
        await this.checkWorkTime(
          fTime.toISOString(),
          tTime.toISOString(),
          sTime.toISOString(),
          eTime.toISOString(),
          calendar.hospital._id
        );
      } else {
        this.overflowConfirmationStatus = true;
      }
      if (this.overflowConfirmationStatus) {
        this.overflowConfirmationStatus = false;
        let calendars = [calendar];
        if (this.isMultiDaySelection) {
          const isExtraclinique =
            formValue.reason.title == "Extraclinique" &&
            formValue.reason.availability == "AVAILABLE";
          this.updatePeriodAvailability(calendars, isExtraclinique);
        } else {
          if (this.dayCalendar) {
            calendars[0]._id = this.dayCalendar._id;
            if (!(this.description.value && this.description.value.freeComment))
              calendars[0].customDescription = "";
            this.updateCalendar(calendars);
          } else {
            this.updateCalendar(calendars);
          }
        }
      } else {
        this.enableClose();
        this.isSending = false;
        this.dialogRef.close();
      }
    }
  }

  getDate(isoDate: Date) {
    const date = new Date(isoDate);
    return date;
  }

  updatePeriodAvailability(
    calendars: Calendar[],
    isExtraclinique: boolean
  ): void {
    const startDay = moment(this.periodToUpdate[0]).toISOString();
    const endDay = moment(this.periodToUpdate[1]).toISOString();
    this.disableClose();
    this.updateCalendarSubscription = this.calendarService
      .updatePeriodAvailability(calendars, startDay, endDay, isExtraclinique)
      .subscribe(
        () => {
          this.enableClose();
          this.isSending = false;
          this.dialogRef.close("DONE");
        },
        (error) => {
          this.isSending = false;
          this.enableClose();
          this.errorService.handleError(error);
        }
      );
  }

  isNurse(): boolean {
    return isNurse(this.profile);
  }

  close(): void {
    this.dialogRef.close();
  }

  changeAvailability(availability: string): void {
    this.setReasons();
    this.isAbsence = availability === "ABSENT";

    if (this.isAbsence) {
      this.description.setValidators([Validators.required]);
      if (!this.isCycleConfig) {
        const description = this.formGroup.get("reason");
        description.patchValue(
          this.setDefaultReason(this.dayCalendar, "ABSENT")
        );
      }
    } else {
      this.description.clearValidators();
      if (!this.isCycleConfig) {
        const description = this.formGroup.get("reason");
        description.patchValue(
          this.setDefaultReason(this.dayCalendar, "AVAILABLE")
        );
      }
    }

    if (this.description && this.description.value)
      this.displayOtherReason = this.description.value.freeComment;
    this.description.updateValueAndValidity();

    if (this.data.reasonsMaps && this.data.reasonsMaps.length > 0) {
      let reasonMap = null;
      let reason = null;
      if (!this.data.period && this.data.reasons && this.data.reasons.length > 0
        && this.data.reasons[0].reasons && this.data.reasons[0].reasons.length > 0 && this.data.weekCalendars[this.data.dayIndex] && this.data.weekCalendars[this.data.dayIndex].reasonMap) {
          reasonMap = this.data.reasonsMaps.find((rm) => rm._id === this.data.weekCalendars[this.data.dayIndex].reasonMap);
          reason = this.data.reasons[0].reasons.find((reason) => reason._id === reasonMap.reason)
      }

      this.changePreselectedReasonMap(reasonMap && reason && reason.availability === availability ? reasonMap : null);
    }
  }

  switch() {
    var classpanel = ["white", "exchange-dialog"];
    if (this.isNight) classpanel = ["dark", "exchange-dialog"];

    const config = {
      width: "600px",
      panelClass: classpanel,
      data: {
        exchangeType: this.getExchangeType(),
        profile: this.profile,
        date: this.date,
        calendar: this.dayCalendar,
        programType: this.getExchangeType(),
      },
    };

    const ref = this.utilisService.openPopup(ExchangePopupComponent, config);
    ref.afterClosed().subscribe((profile2) => {
      if (profile2) {
        ref.close(profile2);
        this.hrsuiteService.anesthesistesMessages.next({
          profile1: { date: this.date, profile: this.profile },
          profile2,
          isExchange: true,
        });
        this.dialogRef.close({ ...profile2, isExchange: true });
      } else {
        this.dialogRef.close();
      }
    });
  }
  getExchangeType() {
    return this.initalReason.isGarde ? "garde" : "programme";
  }

  changeValue(event) {
    const REASON = event;
    this.displayOtherReason = REASON && REASON.freeComment;
    if (this.preselectedReason && event._id != this.preselectedReason._id) {
      this.selectedReasonMap = null;
    }
  }

  getStartTime(): string {
    return this.formGroup.controls.startTime.value;
  }

  getEndTime(): string {
    return this.formGroup.controls.endTime.value;
  }

  setStartTime(time: string): void {
    if (this.selectedReasonMap && !this.selectedReasonMap.mutableHours) {
      this.changePreselectedReasonMap(null);
    }
    this.formGroup.controls.startTime.patchValue(time);
  }

  setEndTime(time: string, ignoreMutableHours: boolean = false): void {
    if (this.selectedReasonMap && !this.selectedReasonMap.mutableHours && !ignoreMutableHours) {
      this.changePreselectedReasonMap(null);
    }
    this.formGroup.controls.endTime.patchValue(time);
  }

  ngOnDestroy(): void {
    if (this.nextWeekCalendarsSubscription) {
      this.nextWeekCalendarsSubscription.unsubscribe();
    }
    if (this.createCRSubscription) {
      this.createCRSubscription.unsubscribe();
    }
    if (this.createCalendarSubscription) {
      this.createCalendarSubscription.unsubscribe();
    }
    if (this.updateCalendarSubscription) {
      this.updateCalendarSubscription.unsubscribe();
    }
    if (this.deleteCalendarSubscription) {
      this.deleteCalendarSubscription.unsubscribe();
    }
    if (this.createCycleCalendarSubscription) {
      this.createCycleCalendarSubscription.unsubscribe();
    }
    if (this.updateCycleCalendarSubscription) {
      this.updateCycleCalendarSubscription.unsubscribe();
    }
    if (this.deleteCalendarSubscription) {
      this.deleteCalendarSubscription.unsubscribe();
    }
    if (this.getProgamsSubsc) {
      this.getProgamsSubsc.unsubscribe();
    }
  }
}
