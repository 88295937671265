import { AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Subscription } from 'rxjs';

import { ErrorService } from '../../services/error.service';
import { StorageService } from '../../../core/services/storage.service';
import { UserService } from '../../services/user.service';
import { PasswordValidator } from '../../utils/password.validator';
import { User } from '../../models/user.model';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, AfterContentChecked{

  @Input() isPopUp: boolean = true;
  @Output() onClose = new EventEmitter();

  public passwordErrorsObject = {
    MatchPassword: 'Les mots de passe ne sont pas identiques',
    required: 'Ce champ est obligatoire',
    minlength: 'Le mot de passe doit contenir au moins 4 caractères'
  };
  public passwordForm: FormGroup;
  public currentUser: User;
  public isFormValid: boolean;
  public isShowPassword: boolean;
  public isSending: boolean = false;
  
  private userSubscription: Subscription;

  constructor(private formBuilder: FormBuilder,
    private errorService: ErrorService,
    private storageService: StorageService,
    private cdr: ChangeDetectorRef,
    private userService: UserService) {
  }

  ngOnInit() {
    this.getCurrentUser();
    this.createPasswordForm();
  }

  getCurrentUser(): void {
    this.currentUser = this.storageService.getUser() as User;
  }

  createPasswordForm(): void {
    this.passwordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(4)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(4)]]
    }, {
      validator: PasswordValidator.MatchPassword
    });
  }

  changePassword(): void {
    if (this.passwordForm) {
      if (this.passwordForm.value.password === this.passwordForm.value.confirmPassword) {
        this.isSending = true;
        this.currentUser.alreadyConnected = true;
        const user: User = Object.assign({}, this.currentUser);
        user.password = this.passwordForm.value.password;
        user.profile = undefined;
        if(this.userSubscription) this.userSubscription.unsubscribe();
        this.userSubscription = this.userService.modifyUser(user).subscribe(() => {
          this.isSending = false;
          this.onClose.emit(true);
        }, error => {
          this.isSending = false;
          this.errorService.handleError(error)
        });
      }
    }
  }

  updateFormCheck(isFormValid: boolean): void {
    this.isFormValid = isFormValid;
  }

  ngAfterContentChecked() {
      this.cdr.detectChanges();
  }

  close(): void {
    this.onClose.emit(false);
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }
}
