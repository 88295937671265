import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {NavigationService} from '../../../core/services/navigation.service';
import {StorageService} from '../../../core/services/storage.service';
import {pages} from '../../config/pages';
import {Profile} from '../../models/profile.model';
import {User} from '../../models/user.model';
import {ErrorService} from '../../services/error.service';
import {ProfileService} from '../../services/profile.service';
import {UserService} from '../../services/user.service';
import {ToastService} from '../../services/toast.service';
import {MatDialog} from '@angular/material';
import {CropperPopupComponent} from './cropper-popup/cropper-popup.component';
import { ChatService } from 'src/app/core/services/chat.service';
import * as moment from 'moment';


const FILE_SIZE_MAX = 2400000
const ALLOWED_EXTENSIONS = ["png", "jpeg", "jpg"]
const ERROR_MSG_ON_FILE_SIZE = "La taille de l'image dépasse la capacité de 2,5 mo autorisée"
const ERROR_MSG_ON_EXTENSION = "Ce type d'image n'est pas autorisé. Veuillez choisir une image dans les formats suivants :JPG, PNG,JPEG)"

@Component({
  selector: 'app-edit-personal-data',
  templateUrl: './edit-personal-data.component.html',
  styleUrls: ['./edit-personal-data.component.scss']
})
export class EditPersonalDataComponent implements OnInit, OnDestroy {

  @Input() isPopUp: boolean = true;

  @Output() onClose = new EventEmitter();

  public isSending: boolean = false;
  public formGroup: FormGroup;
  public currentUser: User;
  public imageSource = 'assets/images/picture.svg';

  public residencies = [
    {
      label: 'Titulaire',
      value: 'Titulaire'
    },
    {
      label: 'Vacataire',
      value: 'Vacataire'
    }
  ];

  imageChangedEvent: any = '';
  croppedImage: any = '';

  public picture: any;
  private profileSubscription: Subscription;
  _isExtensionAllowed: boolean;
  _isFileSizeAllowed: boolean;

  constructor(private formBuilder: FormBuilder,
    private errorService: ErrorService,
    private profileService: ProfileService,
    private userService: UserService,
    private chatService: ChatService,
    private storageService: StorageService,
    private navigationService: NavigationService,
    private toastService: ToastService,
    public dialog: MatDialog) {
  }

  ngOnInit() {
    this.getCurrentUser();
    this.createForm();
  }

  getCurrentUser(): void {
    this.currentUser = this.storageService.getUser() as User;
  }

  toPascalCase(str: string): string {
    if (!str) {
      return str;
    }
    return str.replace(/(\w)(\w*)/g, function(g0,g1,g2){return g1.toUpperCase() + g2.toLowerCase();});
  }

  createForm() {
    const profile: Profile = this.currentUser.profile;
    if (profile.profilePic && profile.profilePic !== '') {
      this.picture = profile.profilePic;
      this.imageSource = this.picture;
    }
    

    this.formGroup = this.formBuilder.group({
      firstName: [this.toPascalCase(profile.firstName), [Validators.required, Validators.minLength(2)]],
      lastName: [this.toPascalCase(profile.lastName), [Validators.required, Validators.minLength(2)]],
      phoneNumber: [profile.phoneNumber],
      hospital: [{ value: profile.hospitals[0] ? profile.hospitals[0].hospitalName : '', disabled: true }],
      position: [{ value: profile.position, disabled: true }],
      status: [{ value: profile.residency, disabled: true }],
      seniority: [{ value: profile.seniority, disabled: true }],
      startingDate: [profile.startingDate],
    });
  }

  submitProfile() {
    if (this.formGroup.valid) {
      this.isSending = true;
      const profile: Profile = this.currentUser.profile;
      profile.phoneNumber = this.formGroup.value.phoneNumber;
      profile.firstName = this.formGroup.value.firstName;
      profile.lastName = this.formGroup.value.lastName;
      profile.startingDate = moment(this.formGroup.value.startingDate).format("YYYY-MM-DD");
      profile.hospitals = this.currentUser.profile.hospitals
      profile.profilePic = this.picture;
      this.currentUser.profile = profile;
      this.profileSubscription = this.profileService.updateUserProfile(this.currentUser.profile).subscribe((profile: any) => { // TODO make it Profile type
        // Update only the fields that are editable
        this.currentUser.profile.profilePic = profile.profilePic;
        this.currentUser.profile.firstName = profile.firstName;
        this.currentUser.profile.lastName = profile.lastName;
        this.currentUser.profile.phoneNumber = profile.phoneNumber;
        this.currentUser.profile.startingDate = profile.startingDate;
        this.isSending = false;
        this.storageService.storeUser(this.currentUser);
        if (this.isPopUp) {
          this.onClose.emit(true);
        } else {
          this.connectToSocket();
          this.navigateToHomePage();
        }
      }, error => {
        this.isSending = false;
        this.errorService.handleError(error)
      });
    }
  }

  connectToSocket(){
    this.chatService.setUser();
    this.chatService.connect();
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  saveImage() {
    this.imageChangedEvent = ""
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      this._isExtensionAllowed = this.isExtensionFileAllowed(file)
      this._isFileSizeAllowed = this.isFileSizeAllowed(file)
      if (this._isFileSizeAllowed && this._isExtensionAllowed) {
        this.openDialog(event)
      }
      else {
        !this._isExtensionAllowed ? this.toastService.errorToast(ERROR_MSG_ON_EXTENSION) : this.toastService.errorToast(ERROR_MSG_ON_FILE_SIZE)
      }
    }
  }

  openDialog(event): void {
    const dialogRef = this.dialog.open(CropperPopupComponent, {
      data: {
        imageChangedEvent: event
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.picture = result.base64;
        this.imageSource = result.base64;
      }
    });
  }

  isFileSizeAllowed(file : any) : boolean {
    return file.size < FILE_SIZE_MAX
  }

  isExtensionFileAllowed (file : any) : boolean {
    const extension = file.name.split('.')[1].toLowerCase();
    return ALLOWED_EXTENSIONS.includes(extension)
  }

  navigateToHomePage() {
    this.navigationService.navigateByUrl(pages.home).then(() => {
    }).catch(reason => {
      this.errorService.handleError(reason);
    });
  }

  close(): void {
    this.onClose.emit(false);
  }

  ngOnDestroy() {
    if (this.profileSubscription) {
      this.profileSubscription.unsubscribe();
    }
  }
}
