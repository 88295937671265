import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EventDal } from './event.dal.service';
import { _Event } from 'src/app/shared/models/event.model';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(
    private eventDAO: EventDal
  ) { }

  addDayEvents(body: _Event): Observable<any> {
    return this.eventDAO.addDayEvents(body);
  }

  updateDayEvents(body: _Event, eventId: number): Observable<any> {
    return this.eventDAO.updateDayEvents(body, eventId);
  }

  getDayEvents(fromDate: string, toDate: string, position) {
    return this.eventDAO.getDayEvents(fromDate, toDate, position);
  }
}
