import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WishDal } from '../dal/wish.dal';
import { Wish } from '../models/wish.model';

@Injectable({
  providedIn: 'root'
})
export class WishesService {

  constructor(
    private wishDal: WishDal,
  ) { }

  getWishesByProfileAndType(profileId: string, type: string): Observable<any[]> {
    return this.wishDal.getWishesByProfileAndType(profileId, type);
  }

  getWishesByProfileAndTypeFromHospital(profileId: string, type: string, hospitalId: string): Observable<any[]> {
    return this.wishDal.getWishesByProfileAndTypeFromHospital(profileId, type, hospitalId);
  }

  editWishes(profileId: string, wishes: any[]) {
    return this.wishDal.editWishes(profileId, wishes);
  }
}
