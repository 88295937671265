import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-objectif-status',
  templateUrl: './objectif-status.component.html',
  styleUrls: ['./objectif-status.component.scss']
})
export class ObjectifStatusComponent implements OnInit {
  @Input() status: string;
  @Input() isLoading: boolean;
  constructor() { }

  ngOnInit() {
  }


}
