import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';

import {urlEndPoints} from '../config/end-points';
import {Patient} from '../models/patient.model';

@Injectable()
export class PatientDal {

  constructor(private httpClient: HttpClient) {
  }

  getAllPatients(): Observable<Patient[]> {
    return this.httpClient.get<Patient[]>(urlEndPoints.patients);
  }

  addPatient(patient: Patient): Observable<Patient> {
    return this.httpClient.post<Patient>(urlEndPoints.patients, patient);
  }

  modifyPatient(patient: Patient): Observable<Patient> {
    return this.httpClient.put<Patient>(urlEndPoints.patients + patient._id, patient);
  }

  archivePatient(idPatient): Observable<Patient> {
    return this.httpClient.delete<Patient>(urlEndPoints.patients + idPatient);
  }
}
