import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { urlEndPoints } from '../config/end-points';
import { SurgeonOpening } from '../models/surgeonOpenings.model';
import { Profile } from '../models/profile.model';
import { SurgeonOpeningsDal } from '../dal/surgeon-openings.dal';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SurgeonOpeningsService {


  constructor(private surgeonOpeningsDal: SurgeonOpeningsDal) { }

  getAllSOByWeek(startDate: string, endDate: string): Observable<Profile[]> {
    return this.surgeonOpeningsDal.getAllSOByWeek(startDate, endDate);
  }

  getSurgeons(_id: string, date: string): Observable<Profile[]> {
    return this.surgeonOpeningsDal.getSurgeons(_id, date)
  }

  getSurRHRec(profileId: string, startDate: string, endDate: string): Observable<any> {
    return this.surgeonOpeningsDal.getSurRHRec(profileId, startDate, endDate)
  }

  getWeekOpenings(surgeonId: string, date: string): Observable<SurgeonOpening[]> {
    return this.surgeonOpeningsDal.getWeekOpenings(surgeonId, date)
  }

  updateManySO(profile: Profile, fromDate: string, toDate: string, body: any): Observable<SurgeonOpening[]> {
    return this.surgeonOpeningsDal.updateManySO(profile, fromDate, toDate, body);
  }

  getNeeds(date: string): Observable<any> {
    return this.surgeonOpeningsDal.getNeeds(date);
  }

  modifySurgeonOpeningNeed(surgeonOpeningId: string, needs: any): Observable<any[]> {
    return this.surgeonOpeningsDal.modifySurgeonOpeningNeed(surgeonOpeningId, needs)
  }

  checkExistence(startDate: string, endDate: string, concernedRooms): Observable<any[]> {
    return this.surgeonOpeningsDal.checkExistence(startDate, endDate, concernedRooms)
  }
}
