import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';
import { ANESTHETIST } from '../../const/glabals.const';
import { Reason } from '../../models/reason.model';
import { DefaultReasonService } from '../../services/defaultReason.service';
import { ErrorService } from '../../services/error.service';
import { HospitalService } from '../../services/hospital.service';
import { ReasonService } from '../../services/reason.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-reason-dialog',
  templateUrl: './add-reason-dialog.component.html',
  styleUrls: ['./add-reason-dialog.component.scss']
})
export class AddReasonDialogComponent implements OnInit , OnDestroy{
  public useDefaultReason: boolean = false;
  public isSending : boolean;
  public formGroup: FormGroup;
  public toggle : boolean;
  public backgroundColor : string;
  public disableColor : boolean;
  public showseniority: boolean = false;
  public seniorityValid: boolean = true;
  public isNight: boolean = false
  public showDayNight: boolean = true
  public chosenPriority: number;

  public temporaryColor: string;
  public defaultReasons: Reason[];
  public defaultReasonsLoading: boolean = true;
  public defaultReasonsLoaded: boolean[];

  private addReasonSubscription : Subscription;
  private editReasonSubscription : Subscription;
  private formGroupChangeSubscription : Subscription;
  private allDefaultReasonsSubscription: Subscription;

  public availability = [
    {
      label: 'Présence',
      value: 'AVAILABLE',
    },
    {
      label: 'Absence',
      value: 'ABSENT',
    }
  ];

  public postes = [
    {label: 'Anesthésiste', value: 'Anesthésiste', isChecked: false},
    {label: 'Iade', value: 'Iade', isChecked: false},
    {label: 'Infirmiere', value: 'Infirmiere', isChecked: false},
  ];

  public seniority = [
    {label: 'Sénior', value: 'Senior', isChecked: false, isDisabled: false},
    {label: 'Junior', value: 'Junior', isChecked: false, isDisabled: false},
    {label: 'Interne', value: 'Interne', isChecked: false, isDisabled: false},
  ];

  public residency = [
    {label: 'Titulaire', value: 'Titulaire', isChecked: false},
    {label: 'Vacataire', value: 'Vacataire', isChecked: false},
  ];

  public triggerChangesSubject: Subject<void> = new Subject<void>();
  public doesHospitalHaveAnesthDetailsOption: boolean;

  constructor(
    private hospitalService: HospitalService, 
    private defaultReasonService: DefaultReasonService, 
    private reasonService : ReasonService,  
    private formBuilder: FormBuilder, 
    private userService : UserService,
    private errorService : ErrorService, 
    private dialogRef: MatDialogRef<AddReasonDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {mode : string  , period : string , reason : Reason}) {
     this.temporaryColor =  this.data.reason ? this.data.reason.color : null;
     this.doesHospitalHaveAnesthDetailsOption = this.hospitalService.doesHospitalHaveAnesthDetailsOption();
  }

  ngOnInit() {
    this.isNight = this.data.period == "NIGHT";
    if (this.isNight) {
      this.availability = [
        {
          label: 'Présence',
          value: 'AVAILABLE',
        }
      ];
    }
    this.showDayNight = this.hospitalService.doesHospitalHaveDayNightOption()
    this.makeForm();

    this.setAbsenceColor();
    this.onAvailibilityChange();
    this.getDefaultReasons();

    this.formGroup.get('postes').valueChanges.subscribe((val) => {
      this.showSeniority(val)
    })
    this.formGroup.get('seniority').valueChanges.subscribe((val) => {
      this.actualiseSeniority(val)
    })

    this.formGroup.get('priority').valueChanges.subscribe((val) => {
      this.chosenPriority = val;
    })

    this.showSeniority(this.formGroup.value.postes)
    this.actualiseSeniority(this.formGroup.value.seniority) 
  }

  /*actualiseExtra(val) {
    if (val) {
      this.formGroup.value.postes = ['Anesthésiste']
      this.formGroup.get('postes').disable
    }
    else
      this.formGroup.get('postes').enable
  }*/

  close(): void {
    this.dialogRef.close(false);
  }

  showSeniority(val) {
    this.showseniority = val.includes(ANESTHETIST) && this.doesHospitalHaveAnesthDetailsOption;
    if (!this.formGroup.value.seniority)
      this.formGroup.value.seniority = []
    this.seniorityValid = !(this.showseniority && this.formGroup.value.seniority.length == 0)
  }

  actualiseSeniority(val) {
    if (!val)
      val = []
    this.seniorityValid = !(this.showseniority && val.length == 0)
  }

  onAvailibilityChange(){
    this.formGroupChangeSubscription = this.formGroup.controls['availability'].valueChanges.subscribe(value => {
      this.setAbsenceColor();
      this.setConserveDuringCycle();
    });
  }

  getDefaultReasons(){
    this.defaultReasonsLoading = true;
    this.allDefaultReasonsSubscription = this.defaultReasonService.getAllReasons().subscribe(res => {
      if  (this.showDayNight) {
        if (this.isNight) {
          this.defaultReasons = res.filter((reason) => reason.isGarde);
        } else {
          this.defaultReasons = res.filter((reason) => !reason.isGarde);
        }
      } else {
        this.defaultReasons = res;
      }
      this.defaultReasonsLoaded = Array(this.defaultReasons.length).fill(false);
      this.defaultReasonsLoading = false;
    }, error => this.errorService.handleError(error));
  }

  makeForm() {
    if (this.data.mode === 'edit') {
      this.backgroundColor = this.data.reason.color;
      this.formGroup = this.formBuilder.group({
        title: [ this.data.reason.title, [Validators.required]],
        availability: [ this.data.reason.availability, [Validators.required]],
        freeComment: [this.data.reason.freeComment, [Validators.required]],
        isGarde: [ this.data.reason.isGarde, [Validators.required]],
        postes: [ this.data.reason.postes, [Validators.required]],
        icon: [ this.data.reason.icon],
        seniority: [this.data.reason.seniority],
        residencies: [ this.data.reason.residencies, [Validators.required]],
        color: [this.data.reason.color  , [Validators.required]],
        disabled : [ !this.data.reason.disabled , [Validators.required]],
        conserveDuringCycle : [ this.data.reason.conserveDuringCycle , [Validators.required]],
        priority : [this.data.reason.priority ? this.data.reason.priority : 0, Validators.required],
      });
    } else {
      this.formGroup = this.formBuilder.group({
        title: [ "", [Validators.required]],
        availability: ['AVAILABLE', [Validators.required]],
        freeComment: [ false, [Validators.required]],
        isGarde: [ false, [Validators.required]],
        postes: [ [], [Validators.required]],
        icon: [],
        seniority: [[]],
        residencies: [ [], [Validators.required]],
        color: [ this.isNight? "#00FF00" : ""  , [Validators.required]],
        disabled : [ true , [Validators.required]],
        //extraClinique: [  false, [Validators.required]],
        conserveDuringCycle : [ false , [Validators.required]],
        priority : [0, Validators.required],
      });
    }
  }

  setColor(color) {
	  this.temporaryColor = color;
    this.formGroup.get('color').setValue(color);
  }

  setAbsenceColor() {
    if (this.formGroup.get('availability').value === "ABSENT") {
      this.backgroundColor = "#eb405e";
      this.disableColor = true;
      this.formGroup.get('color').setValue("#eb405e");
    } else {
      this.disableColor = false;
    }
  }

  setConserveDuringCycle() {
    if (this.formGroup.get('availability').value === "ABSENT") {
      this.formGroup.get('conserveDuringCycle').setValue(true);
    }
  }

  save() {
    let formValue = this.formGroup.value;
    formValue.disabled = !formValue.disabled

    if (this.isNight)
      formValue.availability = 'AVAILABLE'
    if (!this.isNight && this.showDayNight)
      formValue.isGarde = false

    if (this.data.mode === 'edit') {
      let reason = {
        _id : this.data.reason._id,
        ...formValue ,
        period : this.data.period,
        priority: this.chosenPriority
      }
      this.editReason(reason)
    } else {
      let reason = {
        ...formValue ,
        period : this.data.period,
        priority: this.chosenPriority
      }
      this.addReason(reason)
    }
  }

  addReason(reason) {
    this.isSending = true;
    if (!reason.postes.includes(ANESTHETIST) || !this.doesHospitalHaveAnesthDetailsOption) {
      reason.seniority = [];
    }

    this.addReasonSubscription = this.reasonService.addReason(reason).subscribe(res => {
      this.isSending = false;
      this.dialogRef.close(true);
    }, error => this.errorService.handleError(error));
  }

  editReason(reason) {
    this.isSending = true;
    if (!reason.postes.includes(ANESTHETIST) || !this.doesHospitalHaveAnesthDetailsOption) {
      reason.seniority = [];
    }

    this.editReasonSubscription = this.reasonService.editReason(reason).subscribe(res => {
      this.isSending = false;
      this.dialogRef.close(true);
    }, error => this.errorService.handleError(error));
  }

  loadDefaultReason(reason: Reason, index: number) {
    if (!reason || index == null) {
      return;
    }
    this.defaultReasonsLoaded = Array(this.defaultReasons.length).fill(false);
    this.defaultReasonsLoaded[index] = true;

    this.formGroup.patchValue({
      availability: reason.availability,
      title: reason.title,
      freeComment: reason.freeComment,
      isGarde: reason.isGarde,
      postes: reason.postes,
      seniority: reason.seniority,
      icon: reason.icon,
      residencies: reason.residencies,
      color: reason.color,
      disabled: !reason.disabled
    });

    this.triggerChangesSubject.next();

    this.temporaryColor = reason.color;
  }

  availabilityChange(event) {}

  ngOnDestroy() {
    if (this.addReasonSubscription) {
      this.addReasonSubscription.unsubscribe()
    }

    if (this.editReasonSubscription) {
      this.editReasonSubscription.unsubscribe()
    }

    if (this.formGroupChangeSubscription) {
      this.formGroupChangeSubscription.unsubscribe()
    }

    if (this.allDefaultReasonsSubscription) {
      this.allDefaultReasonsSubscription.unsubscribe()
    }
  }
}
