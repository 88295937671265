import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import { Room } from '../models/room.model';
import { RoomDal } from '../dal/room.dal';
import { roomWithDoctorAgendas, roomWithSOAndSOH } from '../interfaces/room.interfaces';
import { BehaviorSubject, Subject } from 'rxjs';
import { consultationExtracliniqueData, profileCard, roomFinalData } from '../components/day-program-recap/day-program-interfaces';
import { dailyNeeds } from '../models/anesthNeed.model';
import { BufferProgram } from '../models/buffer-program.model';

@Injectable()
export class RoomService {

  public reloadSmartPlanning: Subject<void> = new Subject<void>();
  public highlightedProfileSmartPlanning: BehaviorSubject<profileCard> = new BehaviorSubject<profileCard>(undefined);
  public fullScreenSmartPlanning: Subject<void> = new Subject<void>();
  public splitRoomSmartPlanning: Subject<{id: string, isBloc: boolean, mode: string}> = new Subject<{id: string, isBloc: boolean, mode: string}>();
  public emptyRoomSmartPlanning: Subject<{data: roomFinalData | consultationExtracliniqueData, isBloc: boolean, programIndex: number}> = new Subject<{data: roomFinalData | consultationExtracliniqueData, isBloc: boolean, programIndex: number}>();
  public updateEmptyRoomSmartPlanning: Subject<{data: roomFinalData | consultationExtracliniqueData, isBloc: boolean}> = new Subject<{data: roomFinalData | consultationExtracliniqueData, isBloc: boolean}>();
  public updateEmptyRoomOnSplitSmartPlanning: Subject<{data: roomFinalData | consultationExtracliniqueData, isBloc: boolean}> = new Subject<{data: roomFinalData | consultationExtracliniqueData, isBloc: boolean}>();
  public refreshAvailableProfilesSmartPlanning: Subject<{card: profileCard, removing: boolean}> = new Subject<{card: profileCard, removing: boolean}>();
  public removeCardSmartPlanning: Subject<{profile: profileCard, room: roomFinalData}> = new Subject<{profile: profileCard, room: roomFinalData}>();
  public updateRankingsSmartPlanning: Subject<{anesthsRankings: { seniorsJuniorsRanks: any[], internesRanks: any[] }, nursesRankings: any[]}> = new Subject<{anesthsRankings: { seniorsJuniorsRanks: any[], internesRanks: any[] }, nursesRankings: any[]}>();
  public reloadObjectifStatusSmartPlanning: Subject<void> = new Subject<void>();
  public loadedSmartPlanning: Subject<void> = new Subject<void>();
  public refreshChangesCard: Subject<void> = new Subject<void>();

  constructor(private roomDal: RoomDal) {
  }

  getNumberofRooms(hospitalId?: string): Observable<number> {
    return this.roomDal.getNumberofRooms(hospitalId);
  }

  getAllRooms(): Observable<Room[]> {
    return this.roomDal.getAllRooms();
  }

  getAllRoomsWithPrograms(jour : string, allRooms : boolean = true ): Observable<any[]> {
    return this.roomDal.getAllRoomsWithPrograms(jour , allRooms);
  }

  getHospitalRoomsDayDoctorAgendas(day: string, roomID?: string): Observable<roomWithDoctorAgendas[]> {
    return this.roomDal.getHospitalRoomsDayDoctorAgendas(day, roomID);
  }

  getHospitalRoomsDayRealPrograms(day: string): Observable<Room[]> {
    return this.roomDal.getHospitalRoomsDayRealPrograms(day);
  }

  getHospitalRoomsDaysSurgeonOpenings(day: string): Observable<any[]> {
    return this.roomDal.getHospitalRoomsDaysSurgeonOpenings(day);
  }
  getHospitalRoomsDaysSurgeonOpeningsFromTo(startDate: string, endDate: string): Observable<any[]> {
    return this.roomDal.getHospitalRoomsDaysSurgeonOpeningsFromTo(startDate,endDate);
  }

  getHospitalRoomsDaysSurgeonOpeningsClosedFromTo(startDate: string, endDate: string, concernedRooms: string[]): Observable<any[]> {
    return this.roomDal.getHospitalRoomsDaysSurgeonOpeningsClosedFromTo(startDate,endDate, concernedRooms);
  }

  getHospitalRoomsWeekDayConfg(day: number, week: number, tvo?: string): Observable<Room[]> {
    return this.roomDal.getHospitalRoomsWeekDayConfg(day, week, tvo);
  }
  
  updateHospitalRoomsWeekDayConfg(weekDay: number, week: number, surgeonOpenings: any[]): Observable<Room[]> {
    return this.roomDal.updateHospitalRoomsWeekDayConfg(weekDay, week, surgeonOpenings);
  }
  
  updateRoomSurgeonOpening(date: string, roomId: string, surgeonOpenings: any[] , notify? : boolean, cachePrograms?: BufferProgram[]): Observable<Room[]> {
    return this.roomDal.updateRoomSurgeonOpening(date, roomId, surgeonOpenings , notify, cachePrograms);
  }

  getAvailableRooms(day: string): Observable<Room[]> {
    return this.roomDal.getAvailableRooms(day);
  }

  deleteSO(openingId: string): Observable<any> {
    return this.roomDal.deleteSO(openingId);
  }

  deleteSOH(sohToDelete: any[]): Observable<any> {
    return this.roomDal.deleteSOH(sohToDelete);
  }

  updateSOH(sohToUpdate: any[]) : Observable<any> {
    return this.roomDal.updateSOH(sohToUpdate);
  }

  addRoom(room: Room): Observable<Room> {
    return this.roomDal.addRoom(room);
  }

  modifyRoom(room: Room): Observable<Room> {
    return this.roomDal.modifyRoom(room);
  }

  archiveRoom(idRoom: string): Observable<Room> {
    return this.roomDal.archiveRoom(idRoom);
  }

  softDeleteRoom(idRoom: string) {
    return this.roomDal.softDeleteRoom(idRoom);
  }

  getRoom(idRoom: string): Observable<Room> {
    return this.roomDal.getRoom(idRoom);
  }

  getHospitalRooms(): Observable<Room[]> {
    return this.roomDal.getHospitalRooms();
  }

  getHospitalRoomsWithSurgeonActivity(startDate: string, endDate: string, surgeonId: string): Observable<Room[]> {
    return this.roomDal.getHospitalRoomsWithSurgeonActivity(startDate, endDate, surgeonId);
  }

  getNursesRanking(date: string): Observable<any> {
    return this.roomDal.getNursesRanking(date);
  }

  getAnesthFixNeeds(day: number, week: number, tvo: string) : Observable<any[]>{
    return this.roomDal.getAnesthFixNeeds(day, week, tvo);
  }

  updateAnesthFixNeeds(day: number, week: number, tvo: string, needs: any[]): Observable<any[]> {
    return this.roomDal.updateAnesthFixNeeds(day, week , tvo, needs);
  }

  getAnesthDailyNeeds(weekDay: string) : Observable<dailyNeeds[]>{
    return this.roomDal.getAnesthDailyNeeds(weekDay);
  }

  updateAnesthDailyNeeds(weekDay: string, needs: any[]): Observable<any[]> {
    return this.roomDal.updateAnesthDailyNeeds(weekDay , needs);
  }

}
