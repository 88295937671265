import {BufferProgram} from '../models/buffer-program.model';
import {Room} from '../models/room.model';


export class ProgramReFormat {

  static programReFormating(buferprogram: BufferProgram): any {
    return {
      room: buferprogram.room._id,
      startTime: buferprogram.startTime,
      date: buferprogram.date,
      endTime: buferprogram.endTime,
      doctoragenda: buferprogram.doctoragenda ? buferprogram.doctoragenda._id : null,  // rajouter le cas null pour bufferprogramService.updateBufferProgram avec des elmt null (vider l'equipe speciale)
      surgeon: buferprogram.surgeon._id,
      nurses: buferprogram.nurses ? buferprogram.nurses : null,
      anesthesists: buferprogram.anesthesists ? buferprogram.anesthesists.map((p) => p._id ? p._id : p) : [], // rajouter le cas null pour bufferprogramService.updateBufferProgram avec des elmt null (vider l'equipe speciale)
      hospital: buferprogram.hospital
    };
  }

  static roomsTorealProgramsFormat(rooms: Room[] , urgenceProfile?): any {
    return rooms.map(room => {
      return room.programs.map(pro => {
        let hospitalId = room.hospital._id;
        let specialty;
        if (pro.surgeon && pro.surgeon.specialties) {
          specialty = pro.surgeon.specialties.find((specialty) => String(specialty.hospital) === String(hospitalId))
        } else {
          specialty = urgenceProfile.specialties.find((specialty) => String(specialty.hospital) === String(hospitalId))
        }
        return {
          hospital: hospitalId,
          date: pro.date,
          bufferprogram: pro._id,
          specialty: specialty ? specialty._id : undefined,
          room: room._id,
          surgeon: pro.surgeon && pro.surgeon._id ?  pro.surgeon._id : urgenceProfile ?  urgenceProfile._id  : undefined,
          anesthesists: pro.anesthesists.map((p) => p._id),
          nurses: pro.nurses.map((item) => { return { profile: item.profile._id, role: item.role._id } }),
          preAnesthesiaTime: pro.startTime,
          anesthesiaTime: pro.startTime,
          roomEntryTime: pro.startTime,
          startGestureTime: pro.startTime,
          endGestureTime: pro.endTime,
          bandageTime: pro.endTime,
          roomExitTime: pro.endTime,
          type: 'bloc',
          patient: null,
          intervention: null,
          risk: null,
        };
      });
    });
  }

  static roomsToBufferProgramsFormat(rooms: any[] , urgenceProfile?): any {
    return rooms.map(room => {
      if (room.isSplit) {
        if (room.splitByVacation) {
          return room.programs.map(pro => {
            return {
              _id: pro._id,
              hospital: room.room.hospital._id,
              doctoragenda: pro.doctoragenda ? pro.doctoragenda._id : null,
              surgeon: pro.surgeon && pro.surgeon._id ? pro.surgeon._id : urgenceProfile ? urgenceProfile._id : null,
              anesthesists: pro.team.others.filter((card) => card.profile.position === "Anesthésiste").map((card) => card.profile._id),
              nurses: pro.team ? pro.team.others.filter((card) => card.profile.position !== "Anesthésiste").map((card) => ({profile: card.profile._id, role: card.role._id})) : null,
              date: pro.date,
              startTime: pro.startTime,
              endTime: pro.endTime,
              type: 'bloc',
              room: room._id ? room._id : room.roomId ? room.roomId : room.room && room.room._id ? room.room._id : undefined,
            };
          });
        } else {
          let arr = []
          arr = arr.concat(room.morningPrograms.programs.map(pro => {
              return {
                _id: pro._id,
                hospital: room.room.hospital._id,
                doctoragenda: pro.doctoragenda ? pro.doctoragenda._id : null,
                surgeon: pro.surgeon && pro.surgeon._id ? pro.surgeon._id : urgenceProfile ? urgenceProfile._id : null,
                anesthesists: room.morningPrograms.team.others.filter((card) => card.profile.position === "Anesthésiste").map((card) => card.profile._id),
                nurses: room.morningPrograms.team ? room.morningPrograms.team.others.filter((card) => card.profile.position !== "Anesthésiste").map((card) => ({profile: card.profile._id, role: card.role._id})) : null,
                date: pro.date,
                startTime: pro.startTime,
                endTime: pro.endTime,
                type: 'bloc',
                room: room._id ? room._id : room.roomId ? room.roomId : room.room && room.room._id ? room.room._id : undefined,
              };
            })
          );

          arr = arr.concat(room.afternoonPrograms.programs.map(pro => {
              return {
                _id: pro._id,
                hospital: room.room.hospital._id,
                doctoragenda: pro.doctoragenda ? pro.doctoragenda._id : null,
                surgeon: pro.surgeon && pro.surgeon._id ? pro.surgeon._id : urgenceProfile ? urgenceProfile._id : null,
                anesthesists: room.afternoonPrograms.team.others.filter((card) => card.profile.position === "Anesthésiste").map((card) => card.profile._id),
                nurses: room.afternoonPrograms.team ? room.afternoonPrograms.team.others.filter((card) => card.profile.position !== "Anesthésiste").map((card) => ({profile: card.profile._id, role: card.role._id})) : null,
                date: pro.date,
                startTime: pro.startTime,
                endTime: pro.endTime,
                type: 'bloc',
                room: room._id ? room._id : room.roomId ? room.roomId : room.room && room.room._id ? room.room._id : undefined,
              };
            })
          );
          return arr;
        }
      } else {
        return room.programs.map(pro => {
          return {
            _id: pro._id,
            hospital: room.room.hospital._id,
            doctoragenda: pro.doctoragenda ? pro.doctoragenda._id : null,
            surgeon: pro.surgeon && pro.surgeon._id ? pro.surgeon._id : urgenceProfile ? urgenceProfile._id : null,
            anesthesists: pro.team ? pro.team.others.filter((card) => card.profile.position === "Anesthésiste").map((card) => card.profile._id) : room.team ? room.team.others.filter((card) => card.profile.position === "Anesthésiste").map((card) => card.profile._id) : null,
            nurses: pro.team ? pro.team.others.filter((card) => card.profile.position !== "Anesthésiste").map((card) => ({profile: card.profile._id, role: card.role._id})) : room.team ? room.team.others.filter((card) => card.profile.position !== "Anesthésiste").map((card) => ({profile: card.profile._id, role: card.role._id})) : null,
            date: pro.date,
            startTime: pro.startTime,
            endTime: pro.endTime,
            type: 'bloc',
            room: room._id ? room._id : room.roomId ? room.roomId : room.room && room.room._id ? room.room._id : undefined,
          };
        });
      }
    });
  }
}
