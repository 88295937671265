import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { element } from 'protractor';
import { Subscription } from 'rxjs';
import { anesthNeedsComponent } from 'src/app/hrsuite/surgeon/anesth-needs/anesth-needs.component';
import { ParamedicalService } from '../../services/paramedical.service';
import { Role } from '../../models/role.model';

@Component({
  selector: 'app-buffer-table',
  templateUrl: './buffer-table.component.html',
  styleUrls: ['./buffer-table.component.scss']
})
export class BufferTableComponent implements OnInit {

  @Input() Blocs;
  @Input() Consultations;
  @Input() Extracliniques;
  @Input() profile;
  @Input() allRoles: Role[];

  public data : any[];
  public isBloc = false;
  constructor() { }

  ngOnInit() {
    this.actualise()
  }

  ngOnChanges(changes: SimpleChanges) {
    this.actualise()
  }

  actualise() {
    this.data = []
    this.isBloc = this.Blocs.length != 0

    if (this.Blocs.length != 0) {
      this.Blocs.forEach(ele => {
        ele.type = 'Bloc'
      })
      this.data = this.Blocs
    }
    else {
      this.Extracliniques.forEach(ele => ele.type = 'Extraclinique')
      this.Consultations.forEach(ele => ele.type = 'Consultation')
      this.data = this.Consultations.concat(this.Extracliniques)
    }

    this.data.forEach(buff => {

      if (buff.surgeon && this.profile._id + '' == buff.surgeon._id + '') {
        buff.surgeon.highlight = true
      }
      else if (buff.surgeon) {
        buff.surgeon.highlight = false
      }

      const nurse = buff.nurses ? buff.nurses.find((nurse) => String(nurse.profile._id) === String(this.profile._id)) : undefined
      if (nurse) {
        nurse.highlight = true
      }

      buff.anesthesists.forEach(anesth => {
        if (anesth && this.profile._id + '' ==  anesth._id + '') {
          anesth.highlight = true
        }
        else if (anesth) {
          anesth.highlight = false
        }
      })
    })

    this.data.forEach(ele => {
      if (new Date(ele.startTime).getUTCHours() >= 12)
        ele.period = "Après-midi"
      else if (new Date(ele.endTime).getUTCHours() <= 13)
        ele.period = "Matin"
      else
        ele.period = "Journée"
    })
  }
}
