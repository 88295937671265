import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { urlEndPoints } from "../config/end-points";
import { TimeAlert } from "../models/timeAlerts.model";

@Injectable()
export class TimeAlertDal {
  constructor(private httpClient: HttpClient) {}

  getAllTimeAlerts() {
    return this.httpClient.get<any>(urlEndPoints.timeAlert);
  }

  createNewTimeAlert(timeAlert: TimeAlert) {
    return this.httpClient.post<any>(
      urlEndPoints.timeAlert + "create",
      timeAlert
    );
  }
  updateTimeAlert(timeAlertID: string, timeAlert: TimeAlert) {
    return this.httpClient.put<any>(
      urlEndPoints.timeAlert + "update/" + timeAlertID,
      timeAlert
    );
  }
  deleteTimeAlert(timeAlertID: string) {
    return this.httpClient.delete<any>(
      urlEndPoints.timeAlert + "delete/" + timeAlertID
    );
  }
}
