import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-popup-with-inputs-template',
  templateUrl: './popup-with-inputs-template.component.html',
  styleUrls: ['./popup-with-inputs-template.component.scss']
})
export class PopupWithInputsTemplateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
