import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
} from "@angular/material";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import * as moment from "moment";
import { Subject, Subscription } from "rxjs";
import { ANESTHETIST } from "../../const/glabals.const";
import { Profile } from "../../models/profile.model";
import { User } from "../../models/user.model";
import { ErrorService } from "../../services/error.service";
import { ProfileService } from "../../services/profile.service";
import { UserService } from "../../services/user.service";
import { VacationService } from "../../services/vacation.service";
import { MY_FORMATS } from "./data-format-vacation.const";
import { toPascalCase } from "../../utils/cross-functions";
import { isArray } from "util";
import { HospitalService } from "../../services/hospital.service";
import { SpecialtyService } from "../../services/specialty.service";
import { Specialty } from "../../models/specialty.model";
import { WishesService } from "../../services/wishes.service";
import { StorageService } from "src/app/core/services/storage.service";
import { take, takeUntil } from "rxjs/operators";

@Component({
  selector: "app-vacation-hrsuite-dialog",
  templateUrl: "./add-vacation-hrsuite-popup.component.html",
  styleUrls: ["./add-vacation-hrsuite-popup.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: "fr" },
  ],
})
export class addVacationHrsuitePopupComponent implements OnInit, OnDestroy {
  public toPascalCase = toPascalCase;
  public isSending: boolean;
  public day;
  public period: string;
  public currentUser: User;
  private addVacationSubscription: Subscription;
  public formGroup: FormGroup;
  public allProfiles: Profile[];
  public profiles: Profile[];
  private page: number = 1;
  private pageSize: number;
  public loadingProfiles: boolean;
  public isNurses: boolean;
  public typeInf : string='Aide Opératoire';
  public typeAnesth : string='Bloc';
  public specialties: any [];
  public anesthSpecialties: any[];
  public chosenSpecialty : string='Orthopédie membre inférieur';
  public specialtyProfiles :any[];
  public showSeniority: boolean = this.hospitalService.doesHospitalHaveAnesthDetailsOption();
  public haveSeniority: boolean = true;
  public resultProfiles: Profile[]=[];
  public resultProfiles2:any[]=[];
  public chosenSeniorities : string[]=['Senior','Junior','Interne'];
  public chosenResedencies : string[]=["Vacataire"];
  public chosenPositions: string[]=['IBODE','IDE'];
  public showRole : boolean = true;
  public tomorrow: Date;
  public isSpinning : Boolean = false;
  public isDisabled : Boolean = false;
  public isInternResponsible: boolean;
  public vacation: any;
  public roles: any[] = [];
  public nursesPosition: any[] = [];
  private getAnesthSpecialtiesSubscription: Subscription;
  private getWishesByProfileAndTypeFromHospitalSubscription: Subscription;
  private getPositionSubscription: Subscription;
  private getResidencySubscription: Subscription;
  private getSenioritySubscription: Subscription;
  private getTypeInfSubscription: Subscription;
  private getCompetenceFilterSubscription: Subscription;
  private getTypeAnesthSubscription: Subscription;
  private getCompetenceSubscription: Subscription;
  private selectProfilesSubscription: Subscription;
  
  public periodsRadioButtons = [
    {
      label: "Journée",
      value: "Journée",
    },
    {
      label: "Matin",
      value: "Matin",
    },
    {
      label: "Après-midi",
      value: "Après-midi",
    },
  ];
  

  public residencies = [
    {
      label: "Vacataire",
      value: "Vacataire",
      isChecked: true,
      isDisabled: false,
    },
    {
      label: "Titulaire",
      value: "Titulaire",
      isChecked: false,
      isDisabled: false,
    },
  ];

  public seniorities = [
    {
      label: "Sénior",
      value: "Senior",
      isChecked: true,
      isDisabled: false,
    },
    {
      label: "Junior",
      value: "Junior",
      isChecked: false,
      isDisabled: false,
    },
    {
      label: "Interne",
      value: "Interne",
      isChecked: false,
      isDisabled: false,
    }
  ]

  public typeInfOptions = [
    {
      label: "Aide Opératoire",
      value: "Aide Opératoire",
    },
    {
      label: "Circulante",
      value: "Circulante",
    }
  ];

  public typeAnesthOptions = [
    {
      label: "Bloc",
      value: "Bloc",
    },
    {
      label: "Consultation",
      value: "Consultation",
    }
  ];

  private getSpectialtiesSubscription: Subscription;
  private getProfilesSubscription: Subscription;
  private getProfilesBySpecSubscription: Subscription;

  public isMultiHospitals: boolean = false;
  public hospitals: any[] = []
  selectedHospitalsId: any;

  get needs() {
    return Array.from({ length: 5 }, (v, k) => k + 1);
  }

  constructor(
    private wishesService: WishesService,
    private hospitalService: HospitalService,
    private formBuilder: FormBuilder,
    private profileService: ProfileService,
    private userService: UserService,
    private vacationService: VacationService,
    private errorService: ErrorService,
    private specialtyService: SpecialtyService,
    private storageService: StorageService,
    private dialogRef: MatDialogRef<addVacationHrsuitePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.currentUser = this.userService.getCurrentUser();
    this.isInternResponsible = this.userService.isInternResponsible();
    this.day = new Date();

    if (this.isInternResponsible) {
      this.seniorities = [
        {
          label: "Interne",
          value: "Interne",
          isChecked: false,
          isDisabled: false,
        }
      ];
    }

    if (this.isNursePage()) {
      this.isNurses = true;
      this.data.position = this.data.position.filter((pos) => pos.name != 'IADE' && pos.name != 'Iade');
    }
  }

  ngOnInit() { 
    this.isMultiHospitals = this.userService.isCurrentUserHasMultipleHospitalsSelected()
    this.selectedHospitalsId = this.userService.getSelectedHospitals()
    this.currentUser = this.storageService.getUser()    
    this.selectedHospitalsId.forEach(hospitalId => {
      const hospital = this.currentUser.profile.hospitals.find((hospital) => String(hospital._id) == String(hospitalId))
      this.hospitals.push({
        name: hospital.name,
        _id: hospital._id
      })
    });
    if (this.userService.isIadRes()) {
      this.data.position = this.data.position.filter(position => position.name === "IADE");
      this.showSeniority = false
    }
    if (window.location.href.endsWith("Anesthesiste") && this.hospitalService.doesHospitalHaveAnesthDetailsOption()) {
      this.getAnesthSpecialties(this.hospitals[0]._id);    
      this.getProfilesBySpecialty('anesth', this.hospitals[0]._id);  
      this.makeAnestForm();
      this.showSeniority ?     (this.chosenPositions[0]="Anesthésiste") : undefined
    } else {
      this.getSpecialties(this.hospitals[0]._id).then((specialties)=>{
      this.specialties = specialties;
      });
      this.getProfilesBySpecialty('nurse', this.hospitals[0]._id);  
      this.makeForm();
      this.chosenPositions[0]="IBODE";
      this.chosenPositions[1]="IDE";
    }
    this.tomorrow = new Date();
    this.tomorrow.setDate(this.tomorrow.getDate() + 1);
    this.formGroup.get('day').setValue(this.tomorrow);
    const { position, residency } = this.getPositionResidency(
      this.data.position[0],
      ["Vacataire"]
    );
    this.getProfiles(position, this.hospitals[0]._id);
    this.onValueChanges();
      
    this.isAnesthesistePage()
    ?      this.showSeniority 
         ?     (this.chosenPositions[0]="Anesthésiste") : undefined
    :      (this.chosenPositions[0]="IBODE",this.chosenPositions[1]="IDE")
    this.createNursesPosition();
  }

  onChangeService() {
    if (window.location.href.endsWith("Anesthesiste") && this.hospitalService.doesHospitalHaveAnesthDetailsOption()) {
      this.getAnesthSpecialties(this.formGroup.get("hospitals").value._id);
      this.getProfilesBySpecialty('anesth', this.formGroup.get("hospitals").value._id);
    } else {
      this.getSpecialties(this.formGroup.get("hospitals").value._id).then((specialties)=>{
        this.specialties = specialties;
      });
      this.getProfilesBySpecialty('nurse', this.formGroup.get("hospitals").value._id);
    }
    const { position } = this.getPositionResidency(
      this.formGroup.get("position").value,
      this.formGroup.get("residency").value
    );
    this.getProfiles(position, this.formGroup.get("hospitals").value._id);
  }

  isAnesthesistePage() {
    return window.location.href.endsWith("Anesthesiste")
  }

  isNursePage() {
    return window.location.href.endsWith("Infirmiere")
  }

  close(): void {
    this.dialogRef.close(false);
  }

  formatPosition(position: string): string {
    if (this.isNursePage()) {
      return "Infirmiere"
    }
    if (position) {
      return position.split('/').join(',');
    }
    return position;
  }

  filterNurses(profiles) {
    let selectedFormations = this.formGroup.controls["position"].value;
  
    if (!Array.isArray(selectedFormations)) {
      selectedFormations = [selectedFormations];
    }

    profiles = profiles.filter((profile => {
      return selectedFormations.includes(profile.position)
    }));
    profiles = this.filterOnResidency(profiles);

    return profiles;
  }

  filterOnResidency(profiles) {
    let selectedResidencies = this.formGroup.controls["residency"].value;

    if (!Array.isArray(selectedResidencies)) {
      selectedResidencies = [selectedResidencies];
    }

    profiles = profiles.filter((profile) => {
      return selectedResidencies.includes(profile.residency);
    });

    return profiles;
  }

  getProfiles(position, hospitalId) {
    this.loadingProfiles = true;
    if(this.getProfilesSubscription) this.getProfilesSubscription.unsubscribe();
    this.getProfilesSubscription = this.profileService    
      .getProfilesWithUserByPositionResidencyFromHospital(
        hospitalId,
        this.formatPosition(position),
        this.page,
        this.pageSize,
        "",
        undefined,
        true,
        false,
      )
      .subscribe(
        (res) => {
          this.allProfiles = res.docs;
          this.allProfiles.forEach(profile => {
            if (!profile.haveUser) {
              profile.checked = false;
            } else {
              profile.checked = true;
            } 
          });

          if (this.isNursePage()) {
            this.profiles = this.filterNurses(this.allProfiles);
          } else {
            this.profiles = this.filterOnResidency(this.allProfiles);
          }

          this.updateProfiles(this.chosenResedencies, this.chosenSeniorities);
          this.loadingProfiles = false;
        },
        (error) => {
          this.errorService.handleError(error);
        }
      );
  }

  getAnesthSpecialties(hospitalId){
    var blocSpecialties = []
    var consultationSpecialties = [];
    if(this.getAnesthSpecialtiesSubscription) this.getAnesthSpecialtiesSubscription.unsubscribe();
    if(this.getWishesByProfileAndTypeFromHospitalSubscription) 
      this.getWishesByProfileAndTypeFromHospitalSubscription.unsubscribe();
    this.getAnesthSpecialtiesSubscription = this.wishesService.getWishesByProfileAndTypeFromHospital(this.currentUser.profile._id, 'bloc', hospitalId)
    .subscribe(result => {
      result = result.filter(s => s.specialty.name !== "DEFAULT_" &&
      s.specialty.name !== 'Bariatrique' &&
      s.specialty.name !== 'Urgences' && 
      s.specialty.name!=='Anesthésie-réanimation' &&
      !s.specialty.name.match(/extra/i));

      blocSpecialties = result.map((obj)=>obj.specialty);
      this.getWishesByProfileAndTypeFromHospitalSubscription = this.wishesService.getWishesByProfileAndTypeFromHospital(this.currentUser.profile._id,'consultation', hospitalId)
      .subscribe(result => {
        result = result.filter(s => s.specialty.name !== "DEFAULT_" &&
        s.specialty.name !== 'Bariatrique' &&
        s.specialty.name !== 'Urgences' && 
        s.specialty.name!=='Anesthésie-réanimation' &&
        !s.specialty.name.match(/extra/i));

        consultationSpecialties = result.map((obj)=>obj.specialty);
        this.anesthSpecialties=[...(blocSpecialties || []), ...(consultationSpecialties || [])].reduce((acc, curr) => {
          if (!acc.some(item => item._id === curr._id)) {
            acc.push(curr);
          }
          return acc;
        }, []);        
      });
    });
  }

  // get profiles from specialty using new Route: /api/profiles/ratedBySpecialty/:position
  getProfilesBySpecialty(position:string, hospitalId){
    this.getProfilesBySpecSubscription = this.profileService.getProfilesBySpecialtyFromHospital(position, hospitalId)
    .subscribe(
      (res)=>{
        this.specialtyProfiles = res;
        if (res[0] && res[0].specialty && res[0].specialty._id)
          this.chosenSpecialty = res[0].specialty._id;
        this.formGroup.get('competence').setValue(this.chosenSpecialty)
        this.resultProfiles2 = res;
        this.isAnesthesistePage() ? this.updateProfilesOnCompetence(this.chosenSpecialty,this.typeAnesth) : this.updateProfilesOnCompetence(this.chosenSpecialty,this.typeInf)
      }
    )
  }

  getSelectedIDS(profiles: any[]): string[] {
    const selectProfiles = profiles.filter((el) => el.checked);
    return selectProfiles.map((el) => el._id);
  }

  getPositionResidency(position, residency) {
    let newPosition = "";
    let newResidency;
    if (this.isNurses) {
      if (isArray(position)) {
        switch (position.length) {
          case 1:
            newPosition = position[0];
            break;
          case 2:
            newPosition = 'IBODE / IDE / AS';
            break;
          default:
            newPosition = 'IBODE / IDE / AS';
        }
      } else {
        if (position == "IBODE / IDE" || position == "IBODE" || position == "AS") {
          newPosition = position;
        } else {
          newPosition = 'IBODE / IDE / AS';
        }
      }
    } else {
      switch (position) {
        case "Anesthésiste":
          newPosition = "anesth";
          break;
        case "IADE":
          newPosition = "Iade";
          break;
      }
    }

    if (residency.length === 1) {
      newResidency = residency[0];
    } else {
      newResidency = null;
    }

    return {
      position: newPosition,
      residency: newResidency,
    };
  }

  onValueChanges(): void {
   if(this.getPositionSubscription) this.getPositionSubscription.unsubscribe();
   if(this.getResidencySubscription) this.getResidencySubscription.unsubscribe();
   if(this.getCompetenceSubscription) this.getCompetenceSubscription.unsubscribe();
   if(this.selectProfilesSubscription) this.selectProfilesSubscription.unsubscribe();

   this.getPositionSubscription = this.formGroup.get("position").valueChanges.subscribe((val) => {
      if (val.includes('IADE')) {
        this.setCompetenceFilter(false);
      }
      this.chosenPositions = val;
      this.showSeniority = (val == ANESTHETIST && this.hospitalService.doesHospitalHaveAnesthDetailsOption())
      this.haveSeniority = (val != ANESTHETIST || !this.hospitalService.doesHospitalHaveAnesthDetailsOption() || (this.formGroup.value.seniority && this.formGroup.value.seniority.length > 0) )
      if (this.competenceFilter) {
        this.isNurses  
        ?     this.updateProfilesOnCompetence(this.chosenSpecialty,this.typeInf)
        :     this.updateProfilesOnCompetence(this.chosenSpecialty,this.typeAnesth)
      } else {
        if (this.isNursePage() && this.allProfiles)
          this.profiles = this.filterNurses(this.allProfiles);
        else if (this.allProfiles)
          this.profiles = this.filterOnResidency(this.allProfiles);
        this.updateProfiles(this.chosenResedencies, this.chosenSeniorities);
      }      
      this.formGroup.get("selectAllProfiles").setValue(false);
    });

    this.getResidencySubscription = this.formGroup.get("residency").valueChanges.subscribe((val) => {
      this.chosenResedencies = val;
      if (this.competenceFilter){ 
        this.isNurses  
        ?     this.updateProfilesOnCompetence(this.chosenSpecialty,this.typeInf)
        :     this.updateProfilesOnCompetence(this.chosenSpecialty,this.typeAnesth)
      } else
      {
          if (this.isNursePage() && this.allProfiles)
            this.profiles = this.filterNurses(this.allProfiles);
          else if (this.allProfiles)
            this.profiles = this.filterOnResidency(this.allProfiles);
          this.updateProfiles(this.chosenResedencies, this.chosenSeniorities);
      }

      this.formGroup.get("selectAllProfiles").setValue(false);
    });

    if (this.isAnesthesistePage() && this.hospitalService.doesHospitalHaveAnesthDetailsOption()) {
      if(this.getSenioritySubscription) this.getSenioritySubscription.unsubscribe();
      this.getSenioritySubscription = this.formGroup.get("seniority").valueChanges
      .subscribe((val) => {
        this.haveSeniority = (this.formGroup.value.position != ANESTHETIST || !this.hospitalService.doesHospitalHaveAnesthDetailsOption() || val.length > 0) 
        this.chosenSeniorities=val;
        if (this.competenceFilter) {
         this.updateProfilesOnCompetence(this.chosenSpecialty,this.typeAnesth)
        } else
          this.updateProfiles(this.chosenResedencies,this.chosenSeniorities);
        this.formGroup.get("selectAllProfiles").setValue(false);
      }); 
    }
    if (this.isNurses) {
      if(this.getTypeInfSubscription) this.getTypeInfSubscription.unsubscribe();
      if(this.getCompetenceFilterSubscription) this.getCompetenceFilterSubscription.unsubscribe();

      this.getTypeInfSubscription = this.formGroup.get('typeInf').valueChanges
      .subscribe((val)=>{
        this.formGroup.get("selectAllProfiles").setValue(false);
        this.typeInf=val;
        this.updateProfilesOnCompetence(this.chosenSpecialty,this.typeInf)
      });

      this.getCompetenceFilterSubscription = this.formGroup.get("competenceFilter").valueChanges
      .subscribe((val)=>{
        this.formGroup.get("selectAllProfiles").setValue(false);
        val ?
        this.updateProfilesOnCompetence(this.chosenSpecialty,this.typeInf)
        : undefined
      });
    }
  
    if (this.isAnesthesistePage() && this.showSeniority) {
      if(this.getTypeAnesthSubscription) this.getTypeAnesthSubscription.unsubscribe();
      this.getTypeAnesthSubscription = this.formGroup.get('typeAnesth').valueChanges
      .subscribe((val)=>{
        this.typeAnesth=val;
        this.formGroup.get("selectAllProfiles").setValue(false);
        this.updateProfilesOnCompetence(this.chosenSpecialty,this.typeAnesth)
      });
      if (this.showSeniority) {
        if(this.getCompetenceFilterSubscription) this.getCompetenceFilterSubscription.unsubscribe();
        this.formGroup.get("competenceFilter").valueChanges.subscribe((val)=>{
          this.formGroup.get("selectAllProfiles").setValue(false);
          val ?
          this.updateProfilesOnCompetence(this.chosenSpecialty,this.typeAnesth)
          : undefined
        })
      }
    }
   
    this.getCompetenceSubscription = this.formGroup.get("competence").valueChanges
    .subscribe((val) => {
      this.formGroup.get("selectAllProfiles").setValue(false);
      this.chosenSpecialty = val;
      this.isNurses ? (this.isNursesNeeded(val),this.updateProfilesOnCompetence(val,this.typeInf))     
      : this.isAnesthNeeded(val),this.updateProfilesOnCompetence(val,this.typeAnesth)     
      });
    
    this.selectProfilesSubscription = this.formGroup.get("selectAllProfiles").valueChanges
    .subscribe((val) => {
      val
        ? this.selectAll(this.competenceFilter ? this.resultProfiles2 : this.resultProfiles, true)
        : this.selectAll(this.competenceFilter ? this.resultProfiles2 : this.resultProfiles, false)
    });
  }

  makeForm() {
    if (!this.isMultiHospitals) {
      this.formGroup = this.formBuilder.group({
        period: ["Journée", [Validators.required]],
        position: [this.data.position[0].name, [Validators.required]],
        residency: [
          ["Vacataire"],
          [Validators.required, Validators.minLength(1)],
        ],
        needs: [1, [Validators.required]],
        day: [this.day, [Validators.required]],
        targeted: [false, [Validators.required]],
        competenceFilter : [false, [Validators.required]],
        typeInf :["Aide Opératoire",[Validators.required]],
        competence : [this.chosenSpecialty,Validators.required],
        selectAllProfiles: [false],
        hospitals: [this.selectedHospitalsId[0], [Validators.required]]

      });
    } else {
      this.formGroup = this.formBuilder.group({
        period: ["Journée", [Validators.required]],
        position: [this.data.position[0].name, [Validators.required]],
        residency: [
          ["Vacataire"],
          [Validators.required, Validators.minLength(1)],
        ],
        needs: [1, [Validators.required]],
        day: [this.day, [Validators.required]],
        targeted: [false, [Validators.required]],
        competenceFilter : [false, [Validators.required]],
        typeInf :["Aide Opératoire",[Validators.required]],
        competence : [this.chosenSpecialty,Validators.required],
        selectAllProfiles: [false],
        hospitals: [this.hospitals[0], [Validators.required]]
      });
      
    }
  }

  makeAnestForm() {
    if (!this.isMultiHospitals) {
      this.formGroup = this.formBuilder.group({
        period: ["Journée", [Validators.required]],
        position: [this.data.position[0], [Validators.required]],
        residency: [
          ["Vacataire"],
          [Validators.required, Validators.minLength(1)],
        ],
        seniority: [this.isInternResponsible ? ['Interne'] : ["Junior", "Senior", "Interne"]],
        needs: [1, [Validators.required]], 
        day: [this.day, [Validators.required]],
        targeted: [false, [Validators.required]],
        competenceFilter : [false, [Validators.required]],
        typeAnesth :["Bloc",[Validators.required]],
        competence : [this.chosenSpecialty,Validators.required],
        selectAllProfiles: [false],
        hospitals: [this.selectedHospitalsId[0], [Validators.required]],
      });
    } else {
      this.formGroup = this.formBuilder.group({
        period: ["Journée", [Validators.required]],
        position: [this.data.position[0], [Validators.required]],
        residency: [
          ["Vacataire"],
          [Validators.required, Validators.minLength(1)],
        ],
        seniority: [this.isInternResponsible ? ['Interne'] : ["Junior", "Senior", "Interne"]],
        needs: [1, [Validators.required]], 
        day: [this.day, [Validators.required]],
        targeted: [false, [Validators.required]],
        competenceFilter : [false, [Validators.required]],
        typeAnesth :["Bloc",[Validators.required]],
        competence : [this.chosenSpecialty,Validators.required],
        selectAllProfiles: [false],
        hospitals: [this.hospitals[0], [Validators.required]]
      });
    }
  }

  get targeted() {
    return this.formGroup.controls["targeted"].value;
  }

  setTargeted(value:boolean) {
    this.formGroup.get('targeted').patchValue(value)  
  }
  
  get competenceFilter() {
    return this.formGroup.controls["competenceFilter"].value;
  }

  setCompetenceFilter(value:boolean) {
    this.formGroup.get('competenceFilter').patchValue(value);
  }

  getResidencyFromForm(): string {
    const residencies = this.formGroup.value.residency;
    if (residencies.length === 1) {
      return residencies[0];
    } else {
      return "Titulaire et Vacataire";
    }
  }

  getPositionFromForm(): string {
    const position = this.formGroup.controls["position"].value;
    if (this.isNurses) {
      return undefined;
    }
    return position;
  }

  getFormationsFromForm(): string[] {
    if (this.isNurses) {
      let selectedFormations = this.formGroup.controls["position"].value;
      if (!Array.isArray(selectedFormations)) {
        selectedFormations = [selectedFormations];
      }
      const result = selectedFormations.map(((form) => {
          const formation = this.data.position.find(f => f.name === form);
          return formation._id;
      }))
      return result;
    }
    return undefined;
  }
  
  setVacation() {
    let formValue = this.formGroup.value;
    let roleId;
    if (this.competenceFilter) {
      this.roles.forEach((role) => {
        if (role.name === this.typeInf) {
          roleId = role._id;
        }
      });
    }

    if (formValue.position == 'IADE') 
        {
          formValue.seniority = null;
        }
      
      this.vacation = 
      {
        hospital: this.isMultiHospitals ? formValue.hospitals._id : this.selectedHospitalsId[0],
        needs: formValue.needs,
        voluntaries: [],
        chosenOnes: [],
        seniority: formValue.seniority,
        dateofcreation: moment(this.day).format("YYYY-MM-DD"),
        period: formValue.period,
        date: moment(formValue.day).format("YYYY-MM-DD"),
        position: this.getPositionFromForm(),
        formations: this.getFormationsFromForm(),
        residency: this.getResidencyFromForm(),
        informations:{
          specialty : this.competenceFilter ? [this.chosenSpecialty] : undefined,
          type : this.isNurses ? roleId : this.typeAnesth,
        },
        state: "OPENED",

        individuals: formValue.targeted
          ? !this.competenceFilter
              ? this.getSelectedIDS(this.resultProfiles)
              : this.getSelectedIDS(this.resultProfiles2)
          : null,
      }
      
    return {
      vacation : this.vacation,
      message: {
        text: "(Vacation supprimée)",
      },
    };
  }

  get position() {
    return this.formGroup.controls["position"].value;
  }

  save() {
    this.isSpinning = true;
    this.isDisabled = true;
    this.isSending = true;
    const vacation = this.setVacation();

    if(this.addVacationSubscription) this.addVacationSubscription.unsubscribe();
    this.addVacationSubscription = this.vacationService
      .addVacation(vacation)
      .subscribe(
        (result) => {
          this.isSpinning = false;
          this.isDisabled = false;

          this.isSending = false;
          this.dialogRef.close(true);
        },
        (error) => this.errorService.handleError(error)
      );
  }

  // getSpecialties : to get all specialties from specialtyService
  getSpecialties(hospitalId) {
    return new Promise<Specialty[]>((resolve,reject)=>{
      if(this.getSpectialtiesSubscription) this.getSpectialtiesSubscription.unsubscribe();
      this.getSpectialtiesSubscription = this.specialtyService.getAllSpecialtiesFromHospital(false, hospitalId).subscribe((specialties) => {
        if (specialties && specialties.length > 0) {
          specialties = specialties.filter(s => s.name !== "DEFAULT_");
          this.chosenSpecialty = specialties[0]._id;
          resolve(specialties);
        }
      }, error => {
        this.errorService.handleError(error)
        reject(error);
      });
    
    }) 
  }

  // to sort profiles in ascending order
  sortProfiles(profiles :Profile[]){
    if(!profiles) return;
    profiles = profiles.sort((a,b)=>
    {
      if (a.firstName.localeCompare(b.firstName) !== 0) { //if 2 firstNames don't have the same sort
        return a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase());
      } else {                                            // else compare by lastname
        return a.lastName.toLowerCase().localeCompare(b.lastName.toLowerCase());
      }
    });
  }

  // fired after any change in residencies, seniority or nurses positions
  updateProfiles(residency:string[],seniority:string[]){
    if (residency.length == 0) {
      this.resultProfiles=[];
      return;
    }
    if (this.chosenPositions.length==0) {
      this.resultProfiles=[];
      return;
    }
    if (this.isAnesthesistePage() && seniority.length == 0) {
      this.resultProfiles=[];
      return;
    }
    if (this.isAnesthesistePage()) {
      this.chosenPositions.includes('IADE') || !this.showSeniority
      ?     this.resultProfiles = this.profiles.filter(profile => { return residency.includes(String(profile.residency))})
      :     this.resultProfiles = this.profiles.filter(profile => { return residency.includes(String(profile.residency)) && seniority.includes(String(profile.seniority))});
    } else {
      this.resultProfiles = this.profiles.filter(profile => { return residency.includes(String(profile.residency))});
    }
    this.sortProfiles(this.resultProfiles);
  } 

  //fired on every change when competences filter is checked
  updateProfilesOnCompetence(spec:string,type:string){
    // check if spec and type contain values
    if (!spec|| !type) {
      this.resultProfiles2=[];
      return;
    }
    //check if a position / residency is chosen
    if (this.chosenPositions.length == 0 || this.chosenResedencies.length == 0) {
      this.resultProfiles2=[];
      return;
    }

    let roleId;
    this.roles.forEach((role) => {
      if (role.name === type) {
        roleId = role._id;
      }
    });

    if (this.isAnesthesistePage()) {
      if (this.chosenSeniorities.length == 0) {
        this.resultProfiles2=[];
        return;
      }

      if (this.chosenPositions.includes("IADE")) {
        this.resultProfiles2 = this.profiles.filter(profile => { 
          return profile.specialties && profile.specialties.find(specialty => String(specialty) === this.chosenSpecialty)
        });
      } else {
        let temp =[];
        temp = this.specialtyProfiles.filter(profile => { return profile && profile.specialty && profile.specialty._id ? profile.specialty._id == spec : false});
        type == 'Bloc'
        ?     this.resultProfiles2 = temp.map((obj)=>obj.bloc)[0] 
        :     this.resultProfiles2 = temp.map((obj)=>obj.consultation)[0];
        
        let temp2=[];
        this.resultProfiles2 ? temp2 = JSON.parse(JSON.stringify(this.resultProfiles2)):undefined
        
        //filter based on seniority and residency
        this.resultProfiles2 = temp2.filter(profile=>{return this.chosenSeniorities.includes(String(profile.seniority)) && this.chosenResedencies.includes(String(profile.residency))})
      }
    } else {
      let temp =[];
      temp = this.specialtyProfiles.filter(profile => { return profile.specialty && profile.specialty._id == spec});

      if (temp.length > 0) {
        Object.keys(temp[0]).forEach((roleIdList)=> {
          if (roleId === roleIdList) {
            this.resultProfiles2 = temp[0][roleIdList]
          }
        })
      }
      let temp2=[];
      this.resultProfiles2 ? temp2 = JSON.parse(JSON.stringify(this.resultProfiles2)) : undefined
      //filter based on position and residency
      this.resultProfiles2 = temp2.filter(profile=>{return this.chosenPositions.includes(String(profile.position)) && this.chosenResedencies.includes(String(profile.residency))})
    }
  }

  getSpecialtyId(spec:string,arr:any[]) {
    const result = arr
    .map(profile => {
      if (String(profile.specialty.name) === String(spec)) {
        return profile.specialty._id;
      }
    })
    .filter(specId => specId !== undefined);
    return result
  }

  getSpecialtyNameFromId(id:any){
    let chosenspe = this.specialtyProfiles.find(obj=>obj.specialty._id==id)
    if (chosenspe && chosenspe.specialty)
      return chosenspe.specialty.name;
  }

  isNursesNeeded(spec:any) {
    let specialty = this.specialties.find(obj=>obj._id==spec);
    if (specialty) {
      this.typeInfOptions= [];

      specialty.needs.forEach((role) => {
        if (role.role.name !== "IADE") {
          this.roles.push(role.role);
  
          this.typeInfOptions.push({
              label: role.role.name,
              value: role.role.name
          });
  
          //Avoid selected role to be based on previous specialty
          this.formGroup.get('typeInf').setValue(this.typeInfOptions[0].value)
        }
      });
    }
  }

  isAnesthNeeded(spec:any) {
    if (this.anesthSpecialties) {
      let specialty = this.anesthSpecialties.find(obj=>obj._id==spec);
      if (specialty) {
        if (specialty.type.length<=0) {
          this.showRole=false;
          this.typeInfOptions = [];
          return;
        }
        if (specialty.type.length>=2) {
          this.showRole=true;
          this.typeAnesthOptions = [
            {
              label: "Bloc",
              value: "Bloc",
            },
            {
              label: "Consultation",
              value: "Consultation",
            }
          ];
          return;
        }
        if (specialty.type.includes('bloc')) {
          this.showRole=true;
          this.formGroup.get('typeAnesth').setValue('Bloc')
  
          this.typeAnesthOptions = [
            {
              label: "Bloc",
              value: "Bloc",
            }
          ];
          return;
        }
        if (specialty.type.includes('consultation')) {
          this.showRole=true;
          this.formGroup.get('typeAnesth').setValue('Consultation')
          this.typeAnesthOptions = [
            {
              label: "Consultation",
              value: "Consultation",
            }
          ];
          return;
        }
      }
    }
  }

  selectAll(profiles: any[], check:boolean) {
    profiles.map((el) => el.checked=check);
  }

  createNursesPosition() {
    this.nursesPosition = this.data.position.map((position, index) => {
      return {
        label: position.name,
        value: position.name,
        isChecked: index === 0,
      };
    })
  }

  ngOnDestroy() {
    if (this.addVacationSubscription)
      this.addVacationSubscription.unsubscribe();
    
    if (this.getProfilesBySpecSubscription)
      this.getProfilesBySpecSubscription.unsubscribe();

    if (this.getSpectialtiesSubscription)
      this.getSpectialtiesSubscription.unsubscribe();

    if (this.getProfilesSubscription)
      this.getProfilesSubscription.unsubscribe();

    if (this.getAnesthSpecialtiesSubscription) 
      this.getAnesthSpecialtiesSubscription.unsubscribe();

    if (this.getWishesByProfileAndTypeFromHospitalSubscription)
      this.getWishesByProfileAndTypeFromHospitalSubscription.unsubscribe();

    if (this.getPositionSubscription)
      this.getPositionSubscription.unsubscribe();

    if (this.getResidencySubscription)
      this.getResidencySubscription.unsubscribe();

    if (this.getSenioritySubscription)
      this.getSenioritySubscription.unsubscribe();

    if (this.getTypeInfSubscription)
      this.getTypeInfSubscription.unsubscribe();

    if (this.getCompetenceFilterSubscription)
      this.getCompetenceFilterSubscription.unsubscribe();

    if (this.getTypeAnesthSubscription)
      this.getTypeAnesthSubscription.unsubscribe();

    if (this.getCompetenceSubscription)
      this.getCompetenceSubscription.unsubscribe();

    if (this.selectProfilesSubscription)
      this.selectProfilesSubscription.unsubscribe();
  }

}