import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-refuse-reason-dialog',
  templateUrl: './refuse-reason-dialog.component.html',
  styleUrls: ['./refuse-reason-dialog.component.scss']
})
export class RefuseReasonDialogComponent implements OnInit {
  public reason: string = '';

  constructor(
    public dialogRef: MatDialogRef<RefuseReasonDialogComponent>,
  ) { }

  ngOnInit() {
  }

  sendData() {
    this.dialogRef.close(this.reason);
  }

  close(): void {
    this.dialogRef.close();
  }

}
