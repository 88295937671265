import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DefaultPopupComponent} from '../components/default-popup/default-popup.component';
import {CONFIRMATION_MESSAGE, CONFIRMATION_MESSAGE_VALIDATE, OPPOSITE_ZOOM_VALUE} from '../const/glabals.const';
import { StorageService } from 'src/app/core/services/storage.service';

@Injectable()
export class PopupManagerService {
  constructor(
    private matDialog: MatDialog,
    private storageService: StorageService) {
  }

  openPopupByType(popupTitle: string, popupDescription: string, buttonsArray: any, popupWidth: number, popupType: string) {
    const tmp = popupWidth ? (popupWidth < 320 ? 320 * OPPOSITE_ZOOM_VALUE : (popupWidth > 640 ? 640 * OPPOSITE_ZOOM_VALUE : popupWidth * OPPOSITE_ZOOM_VALUE)) : 320 * OPPOSITE_ZOOM_VALUE;

    return this.matDialog.open(DefaultPopupComponent, {
      width: tmp.toString() + 'px',
      data: {
        title: popupTitle,
        description: popupDescription,
        type: popupType ? popupType : 'close',
        buttons: buttonsArray
      },
    });
  }

  openConfirmationPopup(title: string, description: string, width: number, colorType: string, textButton: string) {
    const buttons = [
      {value: false, name: 'Annuler', type: 'text', color: 'primary'},
      {value: true, name: textButton, type: 'primary', color: colorType}
    ];
    return this.openPopupByType(title, description, buttons, width, 'default');
  }

  openUnsavedChangesPopup(isHome) {
    const title = `${isHome ? 'Valider' : 'Sauvegarder'} les modifications ?`;
    const description = isHome ? CONFIRMATION_MESSAGE_VALIDATE : CONFIRMATION_MESSAGE;
    
    const isDisconnecting = this.storageService.getData("isDisconnecting")
    
    let buttons = [];

    if (isDisconnecting) {
      buttons = [
        {value: 'continue', name: isHome ? 'Ne pas valider' : 'Ne pas sauvegarder', type: 'text', color: 'danger'},
        {value: 'save', name: isHome ? 'Valider' : 'Sauvegarder', type: 'primary', color: 'primary'}
      ];
    } else {
      buttons = [
        {value: 'continue', name: isHome ? 'Ne pas valider' : 'Ne pas sauvegarder', type: 'text', color: 'danger'},
        {value: 'cancel', name: 'Annuler', type: 'lite', color: 'primary'},
        {value: 'save', name: isHome ? 'Valider' : 'Sauvegarder', type: 'primary', color: 'primary'}
      ];
    }
    return this.openPopupByType(title, description, buttons, 520, 'default');
  }

  openInfoPopup(title: string, description: string, width: number) {
    return this.openPopupByType(title, description, null, width, 'close');
  }
}
