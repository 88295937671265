import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-slider-input',
  templateUrl: './slider-input.component.html',
  styleUrls: ['./slider-input.component.scss']
})
export class SliderInputComponent implements OnInit {
  @Input() formCtrl: FormControl;
  @Input() label: string = '';
  @Input() tooltip: string;
  @Input() hintMessage: string = '';
  @Input() disabled: boolean;
  @Input() min: number = 0;
  @Input() max: number = 100;
  @Input() step: number = 1;

  constructor() { }

  ngOnInit() {
    
  }

  getIsError() {
    if (this.formCtrl && this.formCtrl.touched && this.formCtrl.errors) {
      return true;
    }

    return false;
  }


  formatLabel(value: number): string {
    return `${value}%`;
  }
}
