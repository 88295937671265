import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {urlEndPoints} from '../config/end-points';

@Injectable()

export class ValidateRulesDal {

  constructor(private httpClient: HttpClient) {
  }

  formatDate(date, hour) {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), hour, 0, 0, 0)).toISOString();
  }


  getMostRecentValidateRules(position, seniority, residency, chosenDate, profileHospitalId) {
    const test = this.formatDate(new Date(chosenDate), 0);
    if (seniority)
      return this.httpClient.get<any>(urlEndPoints.validateRules + 'getMostRecentValidateRules?position=' + position + '&residency=' + residency + '&seniority=' + seniority + '&chosenDate=' + test + '&profileHospitalId=' + profileHospitalId);
    else
    return this.httpClient.get<any>(urlEndPoints.validateRules + 'getMostRecentValidateRules?position=' + position + '&residency=' + residency + '&chosenDate=' + test + '&profileHospitalId=' + profileHospitalId);
  }

  getAllValidateRules() {
    return this.httpClient.get<any>(urlEndPoints.validateRules);
  }

  deleteValidateRule(validateRuleId) {
    return this.httpClient.delete<any>(urlEndPoints.validateRules + validateRuleId);
  }

  addValidateRule(validateRules) {
    return this.httpClient.post<any>(urlEndPoints.validateRules, validateRules);
  }

  editValidateRule(validateRules) {
    return this.httpClient.put<any>(urlEndPoints.validateRules + validateRules._id, validateRules);
  }

}
