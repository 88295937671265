import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { Reason } from '../../models/reason.model';

@Component({
  selector: 'app-availability-legends',
  templateUrl: './availability-legends.component.html',
  styleUrls: ['./availability-legends.component.scss']
})
export class AvailabilityLegendsComponent implements OnInit, OnChanges {
  @Input() isSurgeon: boolean;
  @Input() isNurse: boolean;
  @Input() isCyclePlanning: boolean = false;
  @Input() reasons: Reason[] = [];
  public presenceReasonsWithoutIcons: Reason[] = [];
  public reasonsWithIcons: Reason[] = [];
  public filteredReasons : Reason[] = [];
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.filterReasons();
  }

  filterReasons(): void {
    if (!this.reasons) {
      return;
    }
    
    this.presenceReasonsWithoutIcons = this.reasons.filter((r) => {
      if (!r.icon && r.availability === 'AVAILABLE') {
        if (this.isNurse) {
          if (r.postes.includes('Infirmiere')) {
            return true;
          }
          return false;
        } else if (!this.isSurgeon) {
          // Anesth
          if (r.postes.includes('Anesthésiste') || r.postes.includes('Iade')) {
            return true;
          }
          return false;
        }
        return false;
      } else {
        return false;
      } 
    });

    this.reasonsWithIcons = this.reasons.filter((r) => {
      if (r.icon) {
        if (this.isNurse) {
          if (r.postes.includes('Infirmiere')) {
            return true;
          }
          return false;
        } else if (!this.isSurgeon) {
          // Anesth
          if (r.postes.includes('Anesthésiste') || r.postes.includes('Iade')) {
            return true;
          }
          return false;
        }
        return false;
      } else {
        return false;
      } 
    });

    if (this.isCyclePlanning) {
      this.reasonsWithIcons = this.reasonsWithIcons.filter(r => r.title === 'CONSULTATION');
    }

    this.filterReasonsArray();
  }

  ngOnInit() {
  }

  filterReasonsArray(){
    this.filteredReasons =[...this.presenceReasonsWithoutIcons,...this.reasonsWithIcons].filter(obj => !obj.isGarde);
  }

}
