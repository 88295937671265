import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { urlEndPoints } from '../config/end-points';
import { UserConfiguration } from '../models/userConfiguration.model';
import { newUserConfiguration } from '../interfaces/userConfiguration.interfaces';

@Injectable()
export class UserConfigurationDal {
    constructor(private httpClient: HttpClient) {}

    getUserConfigurations(): Observable<UserConfiguration> {
        return this.httpClient.get<UserConfiguration>(`${urlEndPoints.userConfigurations}`);
    }

    modifyUserConfigurations(newUserConfiguration: newUserConfiguration): Observable<UserConfiguration> {
        return this.httpClient.patch<UserConfiguration>(`${urlEndPoints.userConfigurations}`, {newUserConfiguration});
    }
}
