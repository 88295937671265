import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { _Event } from 'src/app/shared/models/event.model';
import { urlEndPoints } from 'src/app/shared/config/end-points';

@Injectable({
  providedIn: 'root'
})
export class EventDal {

  constructor(
    private httpClient: HttpClient
  ) { }

  addDayEvents(body: _Event): Observable<any> {
    return this.httpClient.post<_Event>(`${urlEndPoints.events}`, body);
  }

  updateDayEvents(body: _Event, eventId: number): Observable<any> {
    return this.httpClient.put<_Event>(`${urlEndPoints.events}/${eventId}`, body);
  }

  getDayEvents(fromDate: string, toDate: string, position: string) {
    return this.httpClient.get<any>(`${urlEndPoints.events}?fromDate=${fromDate}&toDate=${toDate}&position=${position}`);
  }
}
