import {Profile} from "./profile.model";
import {Hospital} from "./hospital.model";
import {CalendarRequest} from './calendarRequest';
import { Reason } from "./reason.model";
import { timestamps, updatedBy } from "../interfaces/shared.interfaces";
import { ReasonMap } from "./reasonMap.model";

export interface Calendar extends updatedBy, timestamps {
  _id?: string,
  date: Date,
  morningStartTime: Date,
  morningEndTime: Date,
  afternoonStartTime: Date,
  afternoonEndTime: Date,
  nightStartTime: Date,
  nightEndTime: Date,
  profile: Profile,
  hospital: Hospital,
  availability: string,
  requestOnHold?: CalendarRequest
  fromCyle: boolean,
  fromCalendarsDataPipeline: boolean,
  description?: string,
  customDescription?: string,
  reason: Reason | string,
  reasonMap?: ReasonMap | string,
  isNight: boolean
}

export function mapCalendarsToAvailableAnesthetists(calendars: Calendar[]): Profile[] {
  calendars = calendars.filter((value) => !isAbsent(value) && !isConsultation(value));
  calendars = calendars.sort(((a, b) => {
    if ((isGuard(a) && isGuard(b)) || (!isGuard(a) && !isGuard(b))) {
      if (a.profile.position.localeCompare(b.profile.position) !== 0) {
        return a.profile.position.localeCompare(b.profile.position);
      } else {
        return a.profile.lastName.localeCompare(b.profile.lastName);
      }
    } else {
      return (isGuard(a) && !isGuard(b)) ? -1 : 1;
    }
  }));
  return calendars.map(an => {
    return {
      ...an.profile,
      isGarde: isGuard(an)
    };
  });
}

export function postCalendars(calendars: Calendar[], isExtraclinique: boolean): any[] {
  const result = [];
  if (calendars) {
    for (const calendar of calendars) {
      const _calendar = {
        date: calendar.date,
        morningStartTime: calendar.morningStartTime,
        morningEndTime: calendar.morningEndTime,
        afternoonStartTime: calendar.afternoonStartTime,
        afternoonEndTime: calendar.afternoonEndTime,
        nightStartTime: calendar.nightStartTime,
        nightEndTime: calendar.nightEndTime,
        profile: calendar.profile._id,
        hospital: calendar.hospital._id,
        reason: calendar.reason,
        reasonMap: calendar.reasonMap,
        customDescription: calendar.customDescription,
        isExtra: isExtraclinique,
      };
      if (calendar._id) {
        _calendar['_id'] = calendar._id;
      }
      result.push(_calendar);
    }
  }
  return result;
}

export function defaultDescription(calendar: Calendar, absenceReasons: string[], presenceReasons: string[]): string {
  if (isAbsent(calendar)) {
    return absenceReasons.includes((<Reason>calendar.reason).title) ? (<Reason>calendar.reason).title : '';
  } else {
    return presenceReasons.includes((<Reason>calendar.reason).title) ?(<Reason>calendar.reason).title : 'BLOC';
  }
}

export function isGuard(calendar: Calendar) {
  return  calendar && calendar.reason && (<Reason>calendar.reason).availability && ((<Reason>calendar.reason).availability.match(/available/i) || calendar.availability === '') && (<Reason>calendar.reason).isGarde
}

export function isFormation(calendar: Calendar) {
  return calendar && calendar.reason && (<Reason>calendar.reason).availability && (<Reason>calendar.reason).availability === 'ABSENT' && (<Reason>calendar.reason).title === 'TRAINING'
}

export function isCongress(calendar: Calendar) {
  return calendar && calendar.reason && (<Reason>calendar.reason).availability &&(<Reason>calendar.reason).availability === 'ABSENT' && (<Reason>calendar.reason).title === 'CONGRESS'
}

export function isConsultation(calendar: Calendar) {
  return  calendar && calendar.reason && (<Reason>calendar.reason).availability && (<Reason>calendar.reason).availability === 'AVAILABLE' && (<Reason>calendar.reason).title === 'CONSULTATION'
}

export function isAbsent(calendar: Calendar) {
  return !calendar || !calendar.reason || calendar.reason && (<Reason>calendar.reason).availability === "" || (calendar && calendar.reason && (<Reason>calendar.reason).availability === 'ABSENT');
}

export function isAvailable(calendar: Calendar) {
  return calendar && calendar.reason && (<Reason>calendar.reason).availability === 'AVAILABLE';
}

export function isVacation(calendar: Calendar) {
  return calendar && calendar.reason && (<Reason>calendar.reason).availability === 'AVAILABLE' && (<Reason>calendar.reason).title === 'Vacation';
}

export function isPartiallyAvailable(calendar: Calendar, hospital? :Hospital) {
  if(!calendar) return false;
  const startDate: Date = new Date(calendar.morningStartTime);
  const endDate: Date = new Date(calendar.afternoonEndTime);
  const startDateHospital = new Date(hospital.startTime).getUTCHours();
  const endDateHospital = new Date(hospital.endTime).getUTCHours();
  return calendar && calendar.reason && (<Reason>calendar.reason).availability === 'AVAILABLE' && (startDate.getUTCHours() > startDateHospital || endDate.getUTCHours() < endDateHospital);
}

export enum Availibility {
  absent = 'ABSENT', partial = 'PARTIAL_AVAILABILITY', available = 'AVAILABLE'
}
