import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  SimpleChanges
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { HospitalService } from "src/app/shared/services/hospital.service";
import { BufferProgramService } from "src/app/shared/services/buffer-program.service";
import { ErrorService } from "src/app/shared/services/error.service";
import { UserService } from "src/app/shared/services/user.service";

@Component({
  selector: "app-config-popup",
  templateUrl: "./config-popup.component.html",
  styleUrls: ["./config-popup.component.scss"],
})
export class ConfigPopupComponent implements OnInit, OnDestroy, AfterViewInit {
  
  public nbColumns = 2;
  public isHome = false;
  public sortByName = false;

  constructor(
    private hospitalService: HospitalService,
    private bufferProgramService: BufferProgramService,
    private userService: UserService,
    private errorService: ErrorService,
    public dialogRef: MatDialogRef<ConfigPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.nbColumns = this.data.nbColumns;
    this.isHome = this.data.isHome;
    if ('sortByName' in this.data) {
      this.sortByName = this.data.sortByName;
    }
  }

  async ngOnInit() {
  }

  async ngOnChanges(changes: SimpleChanges) {
  }

  async ngAfterViewInit() { }

  close(): void {
    this.dialogRef.close();
  }

  save() {
    this.dialogRef.close({nbColumns: this.nbColumns, sortByName: this.sortByName});
  }

  ngOnDestroy(): void {
  }
}