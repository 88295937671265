import { Injectable } from '@angular/core';
import {IbodeGraphsDal} from '../dal/ibode-graphs.dal';
import { Observable } from 'rxjs';


@Injectable()
export class IbodeGraphsService {

  constructor(private ibodeGraphsDal: IbodeGraphsDal) { }


  // First Row

  getPositionsPie(isIadeResp: boolean = false): Observable<any> {
    return this.ibodeGraphsDal.getPositionsPie(isIadeResp);
  }
  
  // NOT USED
  // getLastTwo(): Observable<any> {
  //   return this.ibodeGraphsDal.getLastTwo();
  // }

  getLastThree(isIadeResp: boolean = false): Observable<any> {
    return this.ibodeGraphsDal.getLastThree(isIadeResp);
  }

  getTwoWeeksTeam(startDate: string, endDate: string): Observable<any> {
    return this.ibodeGraphsDal.getTwoWeeksTeam(startDate, endDate);
  }

  // Second Row
  // getAvgAideOpLevelPerSpecialty(specialtyId: string , startDay: string, endDay:string, residency: string): Observable<any> {
  //   return this.ibodeGraphsDal.getAvgAideOpLevelPerSpecialty(specialtyId, startDay, endDay, residency);
  // }
  
  // getAvgCirculanteLevelPerSpecialty(specialtyId: string, startDay: string, endDay:string, residency: string): Observable<any> {
  //   return this.ibodeGraphsDal.getAvgCirculanteLevelPerSpecialty(specialtyId , startDay, endDay, residency);
  // }

  getAvgLevelPerSpecialtyPerRole(isIadeResp: boolean = false, startDay: string, endDay:string, residency: string): Observable<any> {
    return this.ibodeGraphsDal.getAvgLevelPerSpecialtyPerRole(isIadeResp, startDay, endDay, residency);
  }
  // Third Row
  // getWaterFallAOData(startDay: string, endDay:string, residency: string): Observable<any> {
  //   return this.ibodeGraphsDal.getWaterFallAOData(startDay, endDay, residency);
  // }

  // getWaterFallCircData(startDay: string, endDay:string, residency: string): Observable<any> {
  //   return this.ibodeGraphsDal.getWaterFallCircData(startDay, endDay, residency);
  // }

  getWaterFallPerRoleData(isIadeResp: boolean = false, startDay: string, endDay:string, residency: string): Observable<any> {
    return this.ibodeGraphsDal.getWaterFallPerRoleData(isIadeResp, startDay, endDay, residency);
  }

  // Forth Row
  // getBubbleAOData(specialtyId: string, residency: string): Observable<any> {
  //   return this.ibodeGraphsDal.getBubbleAOData(specialtyId, residency);
  // }

  // getBubbleCircData(specialtyId: string, residency: string): Observable<any> {
  //   return this.ibodeGraphsDal.getBubbleCircData(specialtyId, residency);
  // }

  getBubbleDataPerRole(isIadeResp: boolean = false,  residency: string): Observable<any> {
    return this.ibodeGraphsDal.getBubbleDataPerRole(isIadeResp, residency);
  }

  // Fifth Row

  // getFinaleGeneralStatsAO(specialtyId: string, residency: string): Observable<any> {
  //   return this.ibodeGraphsDal.getFinaleGeneralStatsAO(specialtyId, residency);
  // }
  
  // getFinaleGeneralStatsCirc(specialtyId: string, residency: string): Observable<any> {
  //   return this.ibodeGraphsDal.getFinaleGeneralStatsCirc(specialtyId, residency);
  // }

  getFinaleGeneralStats(isIadeResp: boolean = false, residency: string): Observable<any> {
    return this.ibodeGraphsDal.getFinaleGeneralStats(isIadeResp, residency);
  }

  /////////////////////////
  ////// One Onf //////////
  /////////////////////////

  // First Row

  // getOneInfBarAOChart(nurseId: string): Observable<any> {
  //   return this.ibodeGraphsDal.getOneInfBarAOChart(nurseId);
  // }

  // getOneInfBarCircChart(nurseId: string): Observable<any> {
  //   return this.ibodeGraphsDal.getOneInfBarCircChart(nurseId);
  // }

  getOneInfBarChart(nurseId: string): Observable<any> {
    return this.ibodeGraphsDal.getOneInfBarChart(nurseId);
  }

  // Second Row


  // getNurseLevelEvolutionAO(nurseId: string, specialtyId: string, startDay: string, EndDay: string): Observable<any> {
  //   return this.ibodeGraphsDal.getNurseLevelEvolutionAO(nurseId, specialtyId, startDay, EndDay);
  // }
  // getNurseLevelEvolutionCirc(nurseId: string, specialtyId: string, startDay: string, EndDay: string): Observable<any> {
  //   return this.ibodeGraphsDal.getNurseLevelEvolutionCirc(nurseId, specialtyId, startDay, EndDay);
  // }

  getNurseLevelEvolution(nurseId: string, startDay: string, EndDay: string): Observable<any> {
    return this.ibodeGraphsDal.getNurseLevelEvolution(nurseId, startDay, EndDay);
  }

  getNurseEvolution(nurseId: string, startDay: string, EndDay: string): Observable<any> {
    return this.ibodeGraphsDal.getNurseEvolution(nurseId, startDay, EndDay);
  }

  // Third Row

  getSpecialtyIntervention(nurseId: string, startDay: string, EndDay: string): Observable<any> {
    return this.ibodeGraphsDal.getSpecialtyIntervention(nurseId, startDay, EndDay);
  }

  getNurseRankings(nurseId: string, startDay: string, EndDay: string): Observable<any> {
    return this.ibodeGraphsDal.getNurseRankings(nurseId, startDay, EndDay);
  }
  // getDoughnutAOData(nurseId: string, startDay: string, EndDay: string): Observable<any> {
  //   return this.ibodeGraphsDal.getDoughnutAOData(nurseId, startDay, EndDay);
  // }
  // getDoughnutCircData(nurseId: string, startDay: string, EndDay: string): Observable<any> {
  //   return this.ibodeGraphsDal.getDoughnutCircData(nurseId, startDay, EndDay);
  // }

  getDoughnutData(nurseId: string, startDay: string, EndDay: string): Observable<any> {
    return this.ibodeGraphsDal.getDoughnutData(nurseId, startDay, EndDay);
  }

  // Specialties Progress
  getSpecialtiesProgress(nurseId: string): Observable<any> {
    return this.ibodeGraphsDal.getSpecialtiesProgress(nurseId);
  }
  getOneSpecialtyProgress(nurseId: string, specialtyId: string): Observable<any> {
    return this.ibodeGraphsDal.getOneSpecialtyProgress(nurseId, specialtyId);
  }
}
