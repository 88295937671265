import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatHourMin'
})
export class FormatHourMinPipe implements PipeTransform {

  transform(time: any): any {
    return this.getTime(time)
  }

  getTime(date): string {
    const d = new Date(date);
    d.setTime(d.getTime() + d.getTimezoneOffset() * 60 * 1000);
    return `${this.getDoubleDidgetValue(d.getHours())}:${this.getDoubleDidgetValue(d.getMinutes())}`;
  }

  getDoubleDidgetValue(value: number): string {
    return ('0' + value).slice(-2);
  }
}
