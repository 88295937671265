import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {isAnesthetistResp, Profile} from '../../models/profile.model';
import {User} from '../../models/user.model';
import {ANESTHETIST, Iade, NURSE} from '../../const/glabals.const';
import {MAT_DATE_FORMATS, MatDialog} from '@angular/material';
import {ToastService} from '../../services/toast.service';
import {CropperPopupComponent} from '../edit-personal-data/cropper-popup/cropper-popup.component';
import {MY_FORMATS} from './data-format.const';
import {transformedDate} from '../../utils/cross-functions';
import { StorageService } from 'src/app/core/services/storage.service';
import { HospitalService } from '../../services/hospital.service';
import { UserService } from '../../services/user.service';
import { ImportProfileDialogComponent } from '../import-profile-dialog/import-profile-dialog.component';
import { Subject, Subscription } from 'rxjs';
import { ParamedicalService } from '../../services/paramedical.service';
import { takeUntil } from 'rxjs/operators';

const FILE_SIZE_MAX = 2400000;  //24mo
const ALLOWED_EXTENSIONS = ['png', 'jpeg', 'jpg'];
const ERROR_MSG_ON_FILE_SIZE = 'La taille de l\'image dépasse la capacité de 2,5 mo autorisée';
const ERROR_MSG_ON_EXTENSION = 'Ce type d\'image n\'est pas autorisé. Veuillez choisir une image dans les formats suivants :JPG, PNG,JPEG)';

@Component({
  selector: 'app-add-profile',
  templateUrl: './add-profile.component.html',
  styleUrls: ['./add-profile.component.scss'],
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class AddProfileComponent implements OnInit, OnDestroy {
  @Input() type: string;
  @Input() openType: string;
  @Input() profile?: Profile;
  @Input() activeSkills: boolean;
  @Output() onProfileChange = new EventEmitter<Profile>();
  @Output() close = new EventEmitter();
  @Output() editSkills = new EventEmitter();

  public INFIRMIER: string = NURSE;
  public formGroup: FormGroup;
  public currentUser: User;
  public imageSource: string;
  public showSeniority: boolean;
  public realFirstName: string = '';
  postes: string[];
  public picture: string;
  residencies = [
    {
      label: 'Vacataire',
      value: 'Vacataire'
    },
    {
      label: 'Titulaire',
      value: 'Titulaire'
    }
  ];
  public seniorities = []
  _isExtensionAllowed: boolean;
  _isFileSizeAllowed: boolean;
  public isInternResponsible: boolean;
  public isIadeRes: boolean;

  public isMultiHospitals: boolean = false;
  public hospitals: any[] = []
  public isImportedProfile: boolean = false;
  public importedProfile: Profile;

  private getFormationDataSubscription: Subscription;
  isLoading: boolean = true;

  constructor(
    private hospitalService: HospitalService,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private storageService: StorageService,
    private userService: UserService,
    public dialog: MatDialog,
    private paramedicalService: ParamedicalService
    ) {
  }

  ngOnInit() {
    this.isMultiHospitals = this.userService.isCurrentUserHasMultipleHospitalsSelected()
    this.currentUser = this.storageService.getUser()
    if (this.isMultiHospitals) {
      const selectedHospitalsId = this.userService.getSelectedHospitals()
      selectedHospitalsId.forEach(hospitalId => {
        const hospital = this.currentUser.profile.hospitals.find((hospital) => String(hospital._id) == String(hospitalId))
        this.hospitals.push({
          name: hospital.name,
          _id: hospital._id
        })
      });
    }
    this.showSeniority = false;
    this.isInternResponsible = this.userService.isInternResponsible();
    this.isIadeRes = this.userService.isIadRes();

    if (this.type === NURSE) {
      if (this.getFormationDataSubscription) {
        this.getFormationDataSubscription.unsubscribe();
      }

      this.getFormationDataSubscription = this.paramedicalService.getFormations()
      .subscribe((formations) => {
        this.postes = formations.filter((formation) => String(formation.name) !== "Iade").map((formation) => formation.name)
        this.postes = [...new Set(this.postes)];
        this.continueInit();
      })
    } else {
      this.postes = [Iade];
      if (isAnesthetistResp(this.currentUser) || (this.type === ANESTHETIST)) {
        if (this.isInternResponsible) {
          this.postes = [ANESTHETIST];
        } else if (!this.isIadeRes) {
          this.postes.push(ANESTHETIST);
        }
      }
      this.continueInit();
    }
    this.isLoading = false;
  }

  continueInit() {
    this.createForm();
    if (this.hospitalService.doesHospitalHaveAnesthDetailsOption()) {
      if (this.profile && this.profile.position && this.profile.position=='Anesthésiste') {
        !this.isInternResponsible
        ?     this.seniorities=[{
                label: "Sénior",
                value: "Senior",
              },
              {
                label: "Junior",
                value: "Junior",
              },
              {
                label: "Interne",
                value: "Interne",
              }]

        :     this.seniorities=[
                {
                  label: "Interne",
                  value: "Interne",
                }
              ]   
        this.formGroup.get('seniority').setValue(this.profile.seniority);
      }
      this.formGroup.get("type").valueChanges.subscribe((val) => {
        this.showSeniority = (val == ANESTHETIST)
        this.showSeniority 
        ? (
          this.seniorities=[{
            label: "Sénior",
            value: "Senior",
          },
          {
            label: "Junior",
            value: "Junior",
          },
          {
            label: "Interne",
            value: "Interne",
          }],
          this.formGroup.get('seniority').setValue('Senior')
        )
        : undefined
      });
    }
  }

  reverse(s: string): string {
    return s.split('-').reverse().join('-');
  }

  createForm() {
    const profile: Profile = (this.profile ? this.profile : {});
    let staDate: Date;
    if (this.profile) {
      const reversedDate = this.profile.startingDate;
      staDate = new Date(reversedDate);
    }
    if (profile.profilePic && profile.profilePic !== '') {
      this.picture = profile.profilePic;
      this.imageSource = this.picture;
    }
    let type = this.getType(profile);

    this.showSeniority = (type == ANESTHETIST && this.hospitalService.doesHospitalHaveAnesthDetailsOption())
    if (!this.isMultiHospitals) {
      this.formGroup = this.formBuilder.group({
        firstName: [profile.firstName, [Validators.required, Validators.minLength(2)]],
        lastName: [profile.lastName, [Validators.required, Validators.minLength(2)]],
        phoneNumber: [profile.phoneNumber],
        seniority: [this.isInternResponsible ? 'Interne' : profile.seniority],
        type: [type, [Validators.required]],
        residency: [profile.residency, [Validators.required]],
        startingDate: [staDate],
      });
    } else {
      this.formGroup = this.formBuilder.group({
        firstName: [profile.firstName, [Validators.required, Validators.minLength(2)]],
        lastName: [profile.lastName, [Validators.required, Validators.minLength(2)]],
        phoneNumber: [profile.phoneNumber],
        seniority: [this.isInternResponsible ? 'Interne' : profile.seniority],
        type: [type, [Validators.required]],
        residency: [profile.residency, [Validators.required]],
        startingDate: [staDate],
        hospitals: [this.hospitals[0], [Validators.required]]
      });
    }
  }

  getType(profile){
    if (profile.position) {
      if (profile.position === "IADE") {
        return Iade
      }
      return profile.position
    } else {
      if (this.postes.length > 0) {
        return this.postes[0]
      }
      return ""
    }
  }

  resetHospitals() {
    this.hospitals = []
    const selectedHospitalsId = this.userService.getSelectedHospitals()
    selectedHospitalsId.forEach(hospitalId => {
      const hospital = this.currentUser.profile.hospitals.find((hospital) => String(hospital._id) == String(hospitalId))
      this.hospitals.push({
        name: hospital.name,
        _id: hospital._id
      })
    });
  }

  importProfile() {
    const dialogRef = this.dialog.open(ImportProfileDialogComponent, {
      width: "400px",
      height: "520px",
      data: {
        profileType: this.type
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res && res.status) {
        this.resetHospitals();
        res.profile.hospitals.forEach(hospital => {
          this.hospitals = this.hospitals.filter((hospital_) => String(hospital_._id) !== String(hospital._id))
        });
        this.formGroup.patchValue({
          firstName: res.profile.firstName,
          lastName: res.profile.lastName,
          phoneNumber: res.profile.phoneNumber,
          seniority: res.profile.seniority ? res.profile.seniority : '',
          type: res.profile.position,
          residency: res.profile.residency,
          startingDate: res.profile.startingDate,
          hospitals: this.hospitals[0]
        })
        this.isImportedProfile = true;
        this.importedProfile = res.profile;
        if (!this.importedProfile.profilePic)
        {
          this.importedProfile.profilePic = 'assets/images/icons/nurse.svg';
        }
      }
    })
  }

  onValueChange() {}

  submitProfile() {
    if (this.formGroup.valid) {
      let hospital
      const profile: any = (this.profile ? this.profile : {});
      if (this.isImportedProfile) {
        profile._id = this.importedProfile._id
      }
      if (this.openType === 'ADD') {
        if (this.isMultiHospitals) {
          const selectedHospital = this.formGroup.get('hospitals').value
          const userHospitals = this.currentUser.profile.hospitals
          hospital = userHospitals.find((hospital) => String(hospital._id) === String(selectedHospital._id))
          profile.hospitals = [hospital._id]
        } else {
          const selectedHospital = this.userService.getSelectedHospitals()
          hospital = selectedHospital[0]
          profile.hospitals = [hospital]
        } 
      } else {
        profile.hospitals = this.profile.hospitals.map((h) => h._id);
      }
      profile.position = this.formGroup.get('type').value;
      profile.residency = this.formGroup.get('residency').value;
      profile.phoneNumber = this.formGroup.get('phoneNumber').value;
      profile.firstName = (this.formGroup.get('firstName').value) ? this.formGroup.get('firstName').value : 'Dr';
      profile.lastName = this.formGroup.get('lastName').value;
      if (profile.position == ANESTHETIST && this.hospitalService.doesHospitalHaveAnesthDetailsOption())
        profile.seniority = this.formGroup.get('seniority').value;
      else
        profile.seniority = null;
      profile.startingDate = transformedDate(this.formGroup.get('startingDate').value);
      profile.profilePic = this.picture;
      this.onProfileChange.emit(profile);
    }
  }

  goToEditSkills(): void {
    if (this.formGroup.valid) {
      const profile: Profile = (this.profile ? this.profile : {});
      profile.position = this.formGroup.value.type;
      profile.residency = this.formGroup.value.residency;
      profile.phoneNumber = this.formGroup.value.phoneNumber;
      profile.firstName = (this.formGroup.value.firstName) ? this.formGroup.value.firstName : 'Dr';
      profile.lastName = this.formGroup.value.lastName;
      profile.startingDate = transformedDate(this.formGroup.value.startingDate);
      profile.profilePic = this.picture;

      this.editSkills.emit(profile);
    }
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      this._isExtensionAllowed = this.isExtensionFileAllowed(file);
      this._isFileSizeAllowed = this.isFileSizeAllowed(file);
      if (this._isFileSizeAllowed && this._isExtensionAllowed) {
        this.openDialog(event);
      } else {
        !this._isExtensionAllowed ? this.toastService.errorToast(ERROR_MSG_ON_EXTENSION) : this.toastService.errorToast(ERROR_MSG_ON_FILE_SIZE);
      }
    }
  }

  openDialog(event): void {
    const dialogRef = this.dialog.open(CropperPopupComponent, {
      data: {
        imageChangedEvent: event
      },
    });


    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.picture = result.base64;
        this.imageSource = result.base64;
      }
    });
  }

  isFileSizeAllowed(file) {
    return file.size < FILE_SIZE_MAX;
  }

  isExtensionFileAllowed(file) {
    const extension = file.name.split('.')[1].toLowerCase();
    return ALLOWED_EXTENSIONS.includes(extension);
  }

  closeDialog(): void {
    this.close.emit();
  }

  ngOnDestroy(): void {
    if (this.getFormationDataSubscription) {
      this.getFormationDataSubscription.unsubscribe();
    }
  }
}
