import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Profile } from '../../models/profile.model';
import { Location } from '@angular/common';
import {SURGEON, NURSE_TYPES, ANESTHETIST} from '../../const/glabals.const';

@Component({
  selector: 'app-garde-table',
  templateUrl: './garde-table.component.html',
  styleUrls: ['./garde-table.component.scss']
})
export class GardeTableComponent implements OnInit {

  @Input() Gardes: any
  @Input() profile: Profile

  constructor() { }

  ngOnInit() {
    this.actualise()
  }

  ngOnChanges(changes: SimpleChanges) {
    this.actualise()
  }

  actualise() {
    if (!this.Gardes || !this.Gardes[0] || !this.Gardes[0].object || !this.Gardes[0].object.length)
      return

    this.Gardes = this.Gardes[0]

    this.Gardes.object = this.Gardes.object.sort((a, b) => {
      a.reason.postes = a.reason.postes.sort((x, y) => {
        return x.toLowerCase().localeCompare(y.toLowerCase())
      })
      b.reason.postes = b.reason.postes.sort((x, y) => {
        return x.toLowerCase().localeCompare(y.toLowerCase())
      })

      return a.reason.postes[0].toLowerCase() !== b.reason.postes[0].toLowerCase()?
          a.reason.postes[0].toLowerCase().localeCompare(b.reason.postes[0].toLowerCase())
          : a.reason.title.localeCompare(b.reason.title)
    })

    this.Gardes.object.forEach(cal => {
      cal.profile.forEach(profile => {
        profile.highlight = (profile._id + '' == this.profile._id + '')
      })

      cal.profile = cal.profile.sort((a, b) => {
        if (!a.seniority && a.position === ANESTHETIST)
          a.seniority = 'Senior'
        if (!b.seniority && a.position === ANESTHETIST)
          b.seniority = 'Senior'

        if (a.residency && b.residency && a.residency != b.residency) {
          return a.residency.localeCompare(b.residency)
        }
        if (a.seniority != b.seniority) {
          return a.seniority == 'Senior' ? -1 : b.seniority == 'Senior' ? 1 : a.seniority.localeCompare(b.seniority) * -1
        }
        return (a.firstName + " " + a.lastName).localeCompare(b.firstName + " " + b.lastName)
      })
    })


    this.Gardes.object.forEach(cal => {
      let i = 0;
      let j = 0;
      cal.profileList = [[]]
      cal.profile.forEach(ele => {
        if (i > 4) {
          i = 0
          j ++
          cal.profileList.push([])
        }
        cal.profileList[j].push(ele)
        i++
      })
    })
  }
}
