import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import * as moment from 'moment';
import {Subscription} from 'rxjs';
import {Channel} from '../../models/channel.model';
import { Message } from '../../models/message.model';
import {Profile} from '../../models/profile.model';
import {ErrorService} from '../../services/error.service';
import {UserService} from '../../services/user.service';
import {VacationService} from '../../services/vacation.service';
import { ParamedicalService } from "../../services/paramedical.service";
import { Room } from '../../models/room.model';
import { Specialty } from '../../models/specialty.model';


@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit, OnDestroy {
  @Input() message : Message ;
  @Input() isSeen : boolean ;

  @Input() date: string = "";
  @Input() receiver : string;
  @Input() channel : Channel;
  @Input() hospital : any;
  @Input() bgColorChange : boolean;
  @Input() lastMessageAuthor: any;
  @Input() messageIdOnClick: String;
  @Input() profileInfo: string | Profile;
  suiteMessage: string = '';
  specialtyName: string = '';
  roomNumber: string = '';
  type: string = '';
  isAnesth : boolean = false;
  specialtyTargeted : boolean = false;
  specialtiesNames: string[] = [];
  isSurgeonVacation: boolean = false;
  startTime: string = '';
  endTime: string = '';

  public lastAuthor: any;
  public text : string = "";
  public profile : Profile;
  public vacation : any;
  public disableButtuns: boolean

  isVacation : boolean;
  vacationMessage : string;
  isVolunteer : boolean;
  isUnavailable : boolean;
  isVacationOpen : boolean;
  isSending : boolean;
  isUserHasLowLevelAccess : boolean;
  isSameAuthor;
  public authorName : string;
  public picture : string;
  private updateVolunteersSubscription : Subscription;
  private rolesSubscription : Subscription;

  constructor( private vacationService : VacationService,private userService : UserService ,
               private errorService : ErrorService, private paramedicalService: ParamedicalService)
  {
    this.picture = "/assets/images/icons/nurse.svg";
    this.isUserHasLowLevelAccess = this.userService.isUserHasLowLevelAccess()
  }

  ngOnInit(){
    this.lastAuthor = this.lastMessageAuthor;
    this.vacation = this.message.vacation;
    this.text = this.message.text;
    if (this.message && this.message.vacation) {
      this.getProfileType(this.message.vacation.position);
      if (this.message.vacation.position == "CHIRURGIEN") {
        this.isSurgeonVacation = true;
        if (this.message.vacation.informations && this.message.vacation.informations.room) {
          this.roomNumber = this.message.vacation.informations.room.roomNumber
        }
        if (this.message.vacation.informations && this.message.vacation.informations.startTime) {
          const sTime:string = this.message.vacation.informations.startTime
          this.startTime = sTime.substring(sTime.indexOf("T") + 1, sTime.indexOf("T") + 1 + 5)
        }
        if (this.message.vacation.informations && this.message.vacation.informations.endTime) {
          const eTime:string = this.message.vacation.informations.endTime
          this.endTime = eTime.substring(eTime.indexOf("T") + 1, eTime.indexOf("T") + 1 + 5)
        }
      }
    }

    if (this.message.vacation && this.message.vacation.informations && this.message.vacation.informations.specialties && this.message.vacation.informations.specialties.length > 0) {
      this.specialtyTargeted = true;
      this.message.vacation.informations.specialties.forEach(
        (specialty) =>{
          this.specialtiesNames.push(specialty.name)
        } 
      )
      // this.type = this.message.vacation.informations.type;
      
      this.rolesSubscription = this.paramedicalService.getRoles().subscribe((result) => {
        result.forEach((role) => {
          if (role._id === this.message.vacation.informations.type) {
            this.type = role.name;
          }
        })
      });
    }
    this.profile = <Profile>this.message.author;
    this.isVacation = this.vacation ? true : false;



    if (this.channel && !this.vacation) {
      if(this.profile && this.profile.profilePic){
        this.picture = this.profile.profilePic;
      }
    }
    if(this.vacation){
      this.setVacationMessage(this.vacation)
    }
    this.isSameAuthor = this.getIsSameAuthor();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.isSameAuthor = this.getIsSameAuthor();
    // this.disableButtuns = !this.isUserHasLowLevelAccess || this.isSameAuthor
    this.disableButtuns = !this.isUserHasLowLevelAccess;
  }

  getIsSameAuthor() {
    if (this.lastAuthor === null)
      return true;
    if (this.profile && this.lastAuthor && this.profile._id !== this.lastAuthor._id)
      return true;
    if (this.message._id === this.messageIdOnClick)
      return true;
    return false;
  }

  setVacationMessage(vacation){
    if(vacation.state === "OPENED"){
      this.isVacationOpen = true;
      let voluntariesIds = vacation.voluntaries.map(voluntary => voluntary.profile);
  
      if (voluntariesIds.includes(this.receiver) ){
        this.vacationMessage = "Vous vous êtes déjà proposée. Souhaitez-vous vous désister ?";
        this.isVolunteer = true;
        this.isUnavailable = false;
        return;
      } else if (vacation.unavailables) {
        let unavailablesIds = vacation.unavailables.map(voluntary => voluntary.profile);
        if (unavailablesIds.includes(this.receiver)) {
          this.vacationMessage = "Vous avez indiqué ne pas être disponible , vous changez d'avis et vous souhaitez vous proposer pour la remplir ?" ;
          this.isVolunteer = false;
          this.isUnavailable = true;
          return;
        }
        this.isUnavailable = false;
      }
      this.vacationMessage = "Souhaitez-vous vous proposer pour la remplir ?" ;
      this.isVolunteer = false;
    } else {
      this.isVacationOpen = false ;
      if (vacation.chosenOnes && vacation.chosenOnes.length && vacation.chosenOnes.includes(this.receiver)){
        this.vacationMessage = "Vous avez été sélectionné pour cette vacation."
      } else {
        this.vacationMessage = "Désolé, cette vacation a été pourvue."
      }
    }
  }

  updateVolunteers(event) {
    event.stopPropagation();
    this.isSending = true;
    let vacation = this.vacation;
    let volunteer = {
      profile : this.receiver,
      dateOfAnswer : moment(new Date()).format("YYYY-MM-DD, HH:mm:ss")
    }
    if (this.isVolunteer) vacation.voluntaries = vacation.voluntaries.filter(volunteer => volunteer.profile !== this.receiver);
    if(!this.isVolunteer) vacation.voluntaries = [...vacation.voluntaries , volunteer];
    if(this.updateVolunteersSubscription) this.updateVolunteersSubscription.unsubscribe();
    this.updateVolunteersSubscription = this.vacationService.updateVolunteers(vacation).subscribe(res => {
      this.vacationMessage = this.isVolunteer ? "Souhaitez-vous vous proposer pour la remplir ?" : "Vous vous êtes déjà proposée. Souhaitez-vous vous désister ?";
      this.isVolunteer = !this.isVolunteer;
      this.isSending = false;
    }, err => this.errorService.handleError(err))
  }

  
  updateNotVolunteers(event) {

    event.stopPropagation();
    this.isSending = true;
    let vacation = this.vacation;
    let unavailable = {
      profile : this.receiver,
      dateOfAnswer : moment(new Date()).format("YYYY-MM-DD, HH:mm:ss")
    }
    if(this.updateVolunteersSubscription) this.updateVolunteersSubscription.unsubscribe();
    if (this.isUnavailable) {
      vacation.unavailables = vacation.unavailables.filter(unavailable => unavailable.profile !== this.receiver);
      vacation.voluntaries = [...vacation.voluntaries , unavailable];
      this.updateVolunteersSubscription = this.vacationService.updateVolunteers(vacation).subscribe(res => {
        this.vacationMessage = "Vous vous êtes déjà proposée. Souhaitez-vous vous désister ?"
        this.isVolunteer = true;
        this.isUnavailable = !this.isUnavailable;
        this.isSending = false;
      }, err => this.errorService.handleError(err))
      return;
    }
    if (!this.isUnavailable) vacation.unavailables = [...vacation.unavailables , unavailable];
    this.updateVolunteersSubscription = this.vacationService.updateVolunteers(vacation).subscribe(res => {
      this.vacationMessage = this.isUnavailable ? "Souhaitez-vous vous proposer pour la remplir ?" : "Vous avez indiqué ne pas être disponible , vous changez d'avis et vous souhaitez vous proposer pour la remplir ?";
      this.isUnavailable = !this.isUnavailable;
      this.isSending = false;
    }, err => this.errorService.handleError(err))
  }

  getProfileType(position:string){
    if(position=='Anesthésiste' || position=='IADE') this.isAnesth = true;
    else this.isAnesth = false;
  }

  ngOnDestroy(){
    if(this.rolesSubscription) this.rolesSubscription.unsubscribe();
    if(this.updateVolunteersSubscription) this.updateVolunteersSubscription.unsubscribe();
  }
}