import { OverlayRef } from '@angular/cdk/overlay';
import { Component, OnInit, OnDestroy, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { IADE } from 'src/app/shared/const/glabals.const';
import { BufferProgram, nurseElement } from 'src/app/shared/models/buffer-program.model';
import { DoctorAgenda } from 'src/app/shared/models/doctor-agenda.model';
import { Profile } from 'src/app/shared/models/profile.model';
import { Room } from 'src/app/shared/models/room.model';
import { Specialty } from 'src/app/shared/models/specialty.model';
import { DoctorAgendasService } from 'src/app/shared/services/doctor-agendas.service';
import { ProgramCommentaryService } from 'src/app/shared/services/program-commentary.service';
import { RoomService } from 'src/app/shared/services/room.service';
import { UserService } from 'src/app/shared/services/user.service';
import { getHoursAndMinutes } from 'src/app/shared/utils/cross-functions';

@Component({
  selector: 'app-my-program-popup',
  templateUrl: './my-program-popup.component.html',
  styleUrls: ['./my-program-popup.component.scss'],
  host: {
    '(document:click)': 'globalClick($event)',
  },
})
export class MyProgramPopupComponent implements OnInit, OnDestroy {

    @Input() doctorAgendas: {doctoragendas: DoctorAgenda[], room: Room};
    @Input() program: (BufferProgram & {isGarde?: boolean});
    @Input() specialty: Specialty;
    @Input() overlay: OverlayRef;
    @Input() clicHeight: number;
    @Input() openOnleft: boolean = false;
    @Input() popupPeriods: String[] = null;

    @Output('closePopup') closePopup: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('pageRef', {static: false}) pageRef: ElementRef;
    @ViewChild('triangle', {static: false}) triangle: ElementRef;
  
    public startTime: string;
    public endTime: string;
    public programType: string;
    public iades: nurseElement[] = [];
    public paramedicals: nurseElement[] = [];
    public roomNumber: number;
    public loading: boolean;
    public margin: number;
    public comments: any[] = [];
    
    private initialClick: boolean = false;
    private agendasSubscription: Subscription;
    private programCommentarySubscription: Subscription;


    constructor(private roomService: RoomService,private programCommentaryService: ProgramCommentaryService, private userService: UserService, 
    ) {}

    ngOnInit(): void {
    }

    ngOnChanges() {
        this.loading = true;
        if (!this.program) {
            return ;
        }
        this.initTimes();
        this.initProgramType();
        if (!this.doctorAgendas && this.programType === 'Bloc') {
            this.fetchDA();
        }
        if (this.programType === 'Bloc' && (!this.program.specialty || this.program.specialty.name != 'Extraclinique')) {
            this.roomNumber = (this.program.room as Room).roomNumber as any;
        }
        this.getProgramCommentaryByRoom()
        this.splitIadeAndNurses();
        this.loading = false;
    }

    ngAfterViewChecked() {
        if (this.pageRef) {
            this.margin = this.clicHeight - this.pageRef.nativeElement.getBoundingClientRect().y;
            this.triangle.nativeElement.style.marginTop = ((this.margin - 10) * 1.25) + 'px';
        }
    }

    fetchDA() {
        if (this.agendasSubscription) {
            this.agendasSubscription.unsubscribe();
        }
        this.agendasSubscription = this.roomService.getHospitalRoomsDayDoctorAgendas(new Date(this.program.date).toISOString(), this.program.room._id).subscribe(
        (res) => {
            const roomAgendas = (res as any).find((roomAgenda) => roomAgenda.room._id === (this.program.room as Room)._id);
            this.doctorAgendas = roomAgendas;
        }
        );
    }

    splitIadeAndNurses() {
        if (!this.program.nurses) {
            return ;
        }
        this.program.nurses.forEach((nurse) => {

        if (nurse.role && nurse.role.name === 'IADE') {
            this.iades.push(nurse);
        } else {
            this.paramedicals.push(nurse);
        }

        })
        if (this.program.type) { // si ce n.est pas un programme de nuit
            this.paramedicals.sort((a, b) => a.role.priority - b.role.priority);
        }
    }

    initTimes() {
        this.startTime = getHoursAndMinutes(this.program.startTime);
        let separator = this.startTime.includes(':');

        if (separator) {
        this.startTime = this.startTime.replace(':', 'h')
        } else {
        this.startTime += 'h';
        }

        this.endTime = getHoursAndMinutes(this.program.endTime);
        separator = this.endTime.includes(':');

        if (separator) {
            this.endTime = this.endTime.replace(':', 'h')
        } else {
            this.endTime += 'h';
        }
    }

    initProgramType() {
        if (!this.program.type) {
            this.programType = null;
            return ;
        }
        this.programType = this.program.type[0].toUpperCase() + this.program.type.substring(1);
        if (this.programType === 'Bloc' && (this.program.specialty && this.program.specialty.name === 'Extraclinique')) {
            this.programType = 'Extraclinique';
        }
    }

    closeOverlay() {
        this.closePopup.emit(true);
        this.overlay.detach();
    }

    globalClick(event) {
        if (!this.initialClick) {
            this.initialClick = true;
            return ;
        }
        const xClick = event.x;
        const yClick = event.y;
        const page = this.pageRef.nativeElement.getBoundingClientRect();
        if (yClick < page.y ||
            yClick > page.y + page.height ||
            xClick < page.x ||
            xClick > page.x + page.width
        ) {
            this.closePopup.emit(true);
            this.overlay.detach();
        }
    }

    getProgramCommentaryByRoom() {

        const profilePosition = this.userService.getCurrentUser().profile.position
        const levelOfAccess = this.userService.getCurrentUser().levelOfAccess;
        const position = IADE.includes(profilePosition) ? "Iade" : profilePosition == "Anesthésiste" ? "Anesthésiste" : profilePosition === "Cadre de bloc" && levelOfAccess == 5 ? "CadreSup" : "Infirmiere"
        const roomId = this.program.room._id
        const date = new Date(this.program.date).toISOString();
        return new Promise<void>((resolve, reject) => {
        if (this.programCommentarySubscription) {
            this.programCommentarySubscription.unsubscribe();
            }
          this.programCommentaryService.getProgramCommentaryByRoom(date,roomId,position).subscribe(
            (res) => {
              if (res && res.length !== 0) {
                res.forEach(element => {
                    this.comments.push({
                        comment:element.comment,
                        updatedBy:element.profile.firstName.charAt(0).toUpperCase() + element.profile.firstName.slice(1).toLowerCase() + ' ' + element.profile.lastName.toUpperCase()
                    })
                });
              }
              resolve();
            }
          );
        })

      }

      ngOnDestroy() {
        if (this.agendasSubscription) {
            this.agendasSubscription.unsubscribe();
        }
        if (this.programCommentarySubscription) {
            this.programCommentarySubscription.unsubscribe();
          }
    }

}
