import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';

import {Intervention} from '../models/intervention.model';
import {InterventionDal} from '../dal/intervention.dal';
import { Subject } from 'rxjs';

@Injectable()
export class InterventionService {


  private newIntervention : Intervention ;
  interventionChanged = new Subject<Intervention>();

  constructor(private interventionDal: InterventionDal) {
  }

  searchInterventionsBySurg(searchString: string):Observable<any> {
    return this.interventionDal.searchInterventionsBySurg(searchString);
  }

  searchInterventions(searchOrder:number= -1, specialty?: string, name?: string, acte?:string, page?:number, limit?: number , pagination?: boolean, onlyWithFiles?: boolean): any{
    return this.interventionDal.searchInterventions(searchOrder, specialty, name, acte, page, limit,pagination, onlyWithFiles);
  }

  searchInterventionsFromHospital(hospital: string, specialty?: string, name?: string, acte?:string, page?:number, limit?: number , pagination?: boolean, onlyWithFiles?: boolean): any{
    return this.interventionDal.searchInterventionsFromHospital(hospital, specialty, name, acte, page, limit,pagination, onlyWithFiles);
  }

  getAllInterventions(act?: string, surgeon?: string, limit?: number, page?: number ): Observable<any> {
    return this.interventionDal.getAllInterventions(act, surgeon, limit, page);
  }

  addIntervention(intervention: Intervention): Observable<Intervention> {
    return this.interventionDal.addIntervention(intervention);
  }

  modifyIntervention(intervention: Intervention): Observable<Intervention> {
    return this.interventionDal.modifyIntervention(intervention);
  }

  archiveIntervention(idIntervention: string): Observable<Intervention> {
    return this.interventionDal.archiveIntervention(idIntervention);
  }

  getIntervention(idIntervention: string): Observable<Intervention> {
    return this.interventionDal.getIntervention(idIntervention);
  }

  getNewIntervention(){
    return this.newIntervention ;
  }

  setNewIntervention(newIntervention : Intervention){
    this.newIntervention = newIntervention;
    this.interventionChanged.next(this.newIntervention);
  }

  setInterventionFile(interventionId, file, sourceInterventionId) {
    return this.interventionDal.setInterventionFile(interventionId, file, sourceInterventionId);
  }
}
