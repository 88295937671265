import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { urlEndPoints } from '../config/end-points';
import { cycle } from '../models/cycle.model';
import { cycleCalendar } from '../models/cycleCalendar.model';

@Injectable({
  providedIn: 'root'
})
export class CycleDalService {

  constructor(private httpClient: HttpClient) { }


  getEffectif(week: number, cycleId: number): Observable<any> {
    return this.httpClient.get<any>(`${urlEndPoints.cycle}effectifOfWeek?nurseCycleId=${cycleId}&week=${week}`);
  }

  deleteCycle(cycle: cycle): Observable<any> {
    return this.httpClient.delete<cycle>(`${urlEndPoints.cycle}${cycle._id}`);
  }

  deleteCalendar(calendar: cycleCalendar): Observable<any> {

    return this.httpClient.delete<cycle>(`${urlEndPoints.cycle}cycleCalendar/${calendar._id}`);
  }

  updateCycleCalendar(calendar: cycleCalendar): Observable<any> {
    return this.httpClient.put<cycle>(`${urlEndPoints.cycle}cycleCalendar/${calendar._id}`, calendar);
  }

  createCycleCalendar(calendar: cycleCalendar): Observable<any> {
    return this.httpClient.post<cycle>(`${urlEndPoints.cycle}cycleCalendar`, calendar);
  }

  getCalendarsOfCycle(_id: string, cycle: cycle, weekNumbers: number): Observable<any> {
    return this.httpClient.get<any>(`${urlEndPoints.cycle}byProfile/${_id}?nurseCycle=${cycle._id}&week=${weekNumbers}`);
  }

  getCycles(position? : string): Observable<any> {
    let endPoint = `${urlEndPoints.cycle}by/`;
    if(position) endPoint = endPoint + position;
    return this.httpClient.get<any>(endPoint);
  }

  generateCycle(startDate: any, endDate: any, cycle: cycle, keepOldCalendars: boolean): Observable<any> {
    return this.httpClient.get<any>(`${urlEndPoints.cycle}validate/${cycle._id}?fromDate=${startDate}&toDate=${endDate}&keepOldCalendars=${keepOldCalendars}`);
  }
  generateProfiledCalendars(startDate: any, endDate: any, cycle: cycle, keepOldCalendars: boolean, profilesIds: string[], startingWeekNumber?: number): Observable<any> {
    return this.httpClient.post<any>(`${urlEndPoints.cycle}validate/${cycle._id}?fromDate=${startDate}&toDate=${endDate}&keepOldCalendars=${keepOldCalendars}&startingWeekNumber=${startingWeekNumber || ''}`, profilesIds);
  }

  checkConflicts(fromDate: string, toDate: string, cycle: cycle): Observable<any> {
    return this.httpClient.get<any>(`${urlEndPoints.cycle}cyclesCoflits/${cycle._id}?fromDate=${fromDate}&toDate=${toDate}`);
  }

  createCycle(cycle: cycle, cycleSourceId: string): Observable<cycle> {
    return this.httpClient.post<cycle>(`${urlEndPoints.cycle}?copyFrom=${cycleSourceId}`, cycle);
  }

  editCycle(cycle: cycle): Observable<any> {
    const ids = cycle.profiles.map(el => el._id)
    return this.httpClient.post<cycle>(`${urlEndPoints.cycle}/editCycleProfiles/${cycle._id}`, ids);
  }

  updatePeriodAvailabilityCycle(
    calendar: cycleCalendar,
    indexes: number[],
  ): Observable<any> {
    const body = {
      calendar,indexes
    }
    return this.httpClient.post<cycleCalendar>(
      urlEndPoints.cycle + 'cycleCalendar/updatePeriod',
      body
    );
  }

  deletePeriodCycle(
    calendar: cycleCalendar,
    indexes: number[],
  ): Observable<any> {
    const body = {
      calendar,indexes
    }
    return this.httpClient.post<cycleCalendar>(
      urlEndPoints.cycle + 'cycleCalendar/deletePeriod',
      body
    );
  }

}
