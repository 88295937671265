import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { UserService } from '../../services/user.service';
import { StorageService } from 'src/app/core/services/storage.service';

@Component({
  selector: 'app-popup-select-hospital',
  templateUrl: './popup-select-hospital.component.html',
  styleUrls: ['./popup-select-hospital.component.scss']
})
export class PopupSelectHospitalComponent implements OnInit {
  private isMultiHospitals: boolean = false;
  private hospitals: any[] = [];
  private selectedHospital: any;
  private hospitalsSelected: boolean = true;
  private compatible: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<PopupSelectHospitalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService
  ) {
    dialogRef.disableClose = true;
    this.hospitalsSelected = data.hospitalsSelected
    this.compatible = data.compatible;
  }

  ngOnInit() {
    this.isMultiHospitals = this.userService.isCurrentUserHasMultipleHospitals();
    if (this.isMultiHospitals) {
      this.getUserHospitals();
    }
  }

  getUserHospitals() {
    const userHospitals = [...this.userService.getCurrentUserHospitals()];
    userHospitals.forEach(hospital => {
      this.hospitals.push({
        name: hospital.name,
        id: hospital._id
      })
    });
  }

  onChangeHospital(event) {
    this.userService.saveSelectedHospitals([event.id])
    this.dialogRef.close({success: true});
  }

  close(): void {
    this.dialogRef.close({ isClosePopup: true });
  }
}
