import {Component, Inject, OnInit} from '@angular/core';
import {ProfileService} from '../../services/profile.service';
import {MAT_DIALOG_DATA, MatDialogRef, MatCheckboxChange} from '@angular/material';
import {ErrorService} from '../../services/error.service';
import {ANESTHETIST, NURSE} from '../../const/glabals.const';
import { Profile } from '../../models/profile.model';
import { HospitalService } from '../../services/hospital.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-profiles-picker-dialog',
  templateUrl: './profiles-picker-dialog.component.html',
  styleUrls: ['./profiles-picker-dialog.component.scss']
})
export class ProfilesPickerDialogComponent implements OnInit {
  profiles: any[];
  allProfiles: Profile[];

  isLoading: boolean = true;
  getProfilesSubscription: any;
  private page: number = 1;
  private pageSize: number = 7;
  public searchValue: string;

  iadeProfiles: Profile[];
  anesthProfiles: Profile[];
  seniorProfiles: Profile[];
  juniorProfiles: Profile[];
  internProfiles: Profile[];
  public profileListName = "Anesthésiste";
  public profileListOptions = ['Anesthésiste', 'IADE'];
  public profilesSeniorities = ['Séniors', 'Juniors', 'Internes'];
  public profilesSeniority;
  public type: string;
  public doesHospitalHaveAnesthDetailsOption: boolean;
  public isInternResponsible: boolean;

  constructor(
    private profileService: ProfileService,
    public dialogRef: MatDialogRef<ProfilesPickerDialogComponent>,
    private errorService: ErrorService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private hospitalService: HospitalService,
    private userService: UserService,
  ) {
    this.doesHospitalHaveAnesthDetailsOption = this.hospitalService.doesHospitalHaveAnesthDetailsOption();
    this.isInternResponsible = this.userService.isInternResponsible();
    if (this.isInternResponsible) {
      this.profilesSeniority = 'Internes';
    } else {
      this.profilesSeniority = 'Séniors';
    }
  }

  ngOnInit() {
    this.type = this.data.type
    this.getProfiles()
  }

  getProfiles() {
    if(this.getProfilesSubscription) this.getProfilesSubscription.unsubscribe();
    this.getProfilesSubscription = this.profileService.getProfilesByPosition(this.data.type, this.page, this.pageSize, '', true, true, true).subscribe(res => {
      this.allProfiles = res.docs;
      this.formatProfiles();
      if(this.type === ANESTHETIST){
        this.anesthProfiles = [...this.allProfiles.filter(p => p.position === ANESTHETIST)];
        if (this.doesHospitalHaveAnesthDetailsOption) {
          this.seniorProfiles = [...this.anesthProfiles.filter(p => p.seniority === 'Senior')];
          this.juniorProfiles = [...this.anesthProfiles.filter(p => p.seniority === 'Junior')];
          this.internProfiles = [...this.anesthProfiles.filter(p => p.seniority === 'Interne')];
        }
        this.iadeProfiles = [...this.allProfiles.filter(p => p.position.toLowerCase() === "iade")];
        this.getProfileListType(null);
      }else{
        this.profiles = res.docs;
      }
      this.isLoading = false
    }, error => {
      this.isLoading = false;
      this.errorService.handleError(error)
    });
  }

  formatProfiles() {
    const selProfiles = this.data.selectedProfiles;
    this.allProfiles.forEach(el => {
      const index = selProfiles.findIndex(selProf => selProf._id == el._id);
      if (index != -1) {
        el.checked = true
      }
    });
  }

  save () {
    const body = {
      profiles: this.allProfiles.filter(el => el.checked)
    };
    this.dialogRef.close(body);
  }

  getSelectedIDS(profiles: any[]):string[] {
    const selectProfiles = profiles.filter(el => el.checked);
    return selectProfiles.map(el => el._id)
  }

  doFilter(value) {
    let profilesToFilter = [];
    if(this.type === ANESTHETIST){
      let tmp;

      if (this.profileListName === ANESTHETIST && this.doesHospitalHaveAnesthDetailsOption) {
        tmp = this.profilesSeniority;
      } else {
        tmp = this.profileListName;
      }

      switch (tmp) {
        case ANESTHETIST:
          profilesToFilter = [...this.anesthProfiles];
          break;
        case 'Séniors':
          profilesToFilter = [...this.seniorProfiles];
          break;
        case 'Juniors':
          profilesToFilter = [...this.juniorProfiles];
          break;
        case 'Internes':
          profilesToFilter = [...this.internProfiles];
          break;
        default:
          profilesToFilter = [...this.iadeProfiles]
          break;
      }
    }else{
      profilesToFilter = [...this.allProfiles]
    }
    this.profiles = profilesToFilter.filter(profile => {
      var re = new RegExp(value, 'gi');
      return profile.firstName.match(re) != null || profile.lastName.match(re) != null
    })
  }

  selectAllChange(checkbox: MatCheckboxChange) {
      this.profiles.forEach(profile => profile.checked = checkbox.checked);
  }

  capitalize(s : string) : string{
    let text = s.toLowerCase();
    return text.charAt(0).toUpperCase() + text.slice(1)
  }

  getProfileListType(event){
    // event : 'Anesthésiste' | 'Séniors' | 'Juniors' | 'Internes' | 'IADE' | null
    let tmp;
    if (event) {
      tmp = event;

      if ([ANESTHETIST, 'IADE'].includes(event)) {
        this.profileListName = event;
      } else {
        this.profilesSeniority = event;
      }
    } else {
      tmp = this.profileListName;
    }

    if (this.profileListName === ANESTHETIST && this.doesHospitalHaveAnesthDetailsOption) {
      tmp = this.profilesSeniority;
    }

    switch (tmp) {
      case ANESTHETIST:
        this.profiles = [...this.anesthProfiles];
        break;
      case 'Séniors':
        this.profiles = [...this.seniorProfiles];
        break;
      case 'Juniors':
        this.profiles = [...this.juniorProfiles];
        break;
      case 'Internes':
        this.profiles = [...this.internProfiles];
        break;
      default:
        this.profiles = [...this.iadeProfiles]
        break;
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    if (this.getProfilesSubscription) this.getProfilesSubscription.unsubscribe();
  }

}
