import {Component, Input, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import { isNurse, Profile } from '../../models/profile.model';
import { CalendarRequestService } from '../../services/calendar-request.service';
import { ErrorService } from './../../services/error.service';
import { ProfileService } from './../../services/profile.service';
import { ANESTHETIST, BLOC, CONSULTATION, IADE } from 'src/app/shared/const/glabals.const';
import { UserService } from './../../services/user.service';
import { sortByDate } from '../../utils/cross-functions';
import * as moment from 'moment';
import { NURSE } from './../../const/glabals.const';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-popup-switch-request-infos',
  templateUrl: './popup-switch-request-infos.component.html',
  styleUrls: ['./popup-switch-request-infos.component.scss']
})
export class PopupSwitchRequestInfosComponent implements OnInit {
    @Input() cr

    public profile1: Profile = null
    public profile2: Profile = null
    public date1: string = ""
    public date2: string = ""
    public period1: string = ""
    public period2: string = ""
    public type: string = "Astreinte"
  
    constructor(
      private userService: UserService,
      private profileService: ProfileService,
      private errorService: ErrorService,
      private calendarRequestService: CalendarRequestService
    ) { }
  
    ngOnInit() {

      this.profile1 = this.cr.calendar1.profile
      this.profile2 = this.cr.calendar2.profile

      this.date1 = moment(this.cr.calendar1.date).format("DD/MM/YYYY")
      this.date2 = moment(this.cr.calendar2.date).format("DD/MM/YYYY")

      let start = new Date(this.cr.calendar1.morningStartTime)
      let end = new Date(this.cr.calendar1.afternoonEndTime)
  
      if (this.cr.type == 'garde')
        this.period2 = null
      else if (end.getHours() <= 13)
        this.period1 = "Matin"
      else if (start.getHours() >= 13)
        this.period1 = "Après-midi"
      else
        this.period1 = "Journée"

      start = new Date(this.cr.calendar2.morningStartTime)
      end = new Date(this.cr.calendar2.afternoonEndTime)
  
      if (this.cr.type == 'garde')
        this.period2 = null
      else if (end.getHours() <= 13)
        this.period2 = "Matin"
      else if (start.getHours() >= 13)
        this.period2 = "Après-midi"
      else
        this.period2 = "Journée"
      if (this.profile1.position == ANESTHETIST)
        this.type = this.cr.type == 'garde' ? 'Garde' : 'Extraclinique'
      if (IADE.includes(this.profile1.position))
        this.type = 'Garde IADE'
    }
  }
  