import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { urlEndPoints } from '../config/end-points';
import { Indicator } from '../models/indicator.model';

@Injectable()
export class IndicatorDal {
  constructor(private httpClient: HttpClient) {
  }
  getIndicatorByName(indicatorName: string): Observable<Indicator> {
    const encodedIndicatorName = encodeURIComponent(indicatorName);
    return this.httpClient.get<Indicator>(urlEndPoints.indicator + encodedIndicatorName);
  }

  getAllIndicators(): Observable<Indicator[]> {
    return this.httpClient.get<Indicator[]>(urlEndPoints.indicator);
  }
}
