import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Subject } from "rxjs/internal/Subject";

@Injectable({providedIn : 'root'})
export class HrsuiteService {
    public requestCalendarsFromHospitalSubject: Subject<void> = new Subject();

    public profilesUpdateSubject : Subject<any> = new  Subject<string>();
    public toggleEventsSubject : Subject<boolean> = new  Subject<boolean>();
    public toggleNightSubject : Subject<boolean> = new  Subject<boolean>();
    public reloadActiveRequestsCounterSubject : Subject<void> = new  Subject<void>();

    public isSurgeonOpeningsRoomsSubject : Subject<boolean> = new  Subject<boolean>();
    public isChangeSurgeonRoomsSubject : Subject<boolean> = new  Subject<boolean>();
    public saveRoomsSubject : Subject<boolean> = new  Subject<boolean>();
    public isSavingSurgeonOpenings: boolean = false;
    public isChangeSurgeonRooms: boolean = false;
    public refreshRoomsSubject : Subject<boolean> = new  Subject<boolean>();

    public requestDatesSubject : BehaviorSubject<any> = new  BehaviorSubject<any>(null);
    
    public isSurgeonOpeningsRoomDetailsSubject : Subject<boolean> = new  Subject<boolean>();
    public addInterventionSubject : Subject<boolean> = new  Subject<boolean>();
    public closeRoomSubject : Subject<boolean> = new  Subject<boolean>();
    public isClosingRoom: boolean = false;
    public isAnesthNeedsPageSubject : Subject<boolean> = new  Subject<boolean>();
    public isChangeAnesthNeedsSubject : Subject<boolean> = new  Subject<boolean>();
    public saveAnesthNeedsSubject : Subject<boolean> = new  Subject<boolean>();
    public isSavingAnesthNeeds: boolean = false;
    public refreshAnesthNeedsSubject : Subject<boolean> = new  Subject<boolean>();

    public isAnesthResponsibleRoomDetailsSubject : Subject<boolean> = new  Subject<boolean>();

    public isPerInterventionsPageSubject: Subject<boolean> = new Subject<boolean>();

    public anesthesistesMessages: Subject<any> = new Subject<any>();
}
