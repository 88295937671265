import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urlEndPoints } from '../config/end-points';
import { Reason } from '../models/reason.model';

@Injectable()

export class DefaultReasonDal {

  constructor(private httpClient: HttpClient) {
  }

  getAllReasons(){
    return this.httpClient.get<any>(urlEndPoints.defaultReasons);
  }

  addReason(reason){
    return this.httpClient.post<any>(urlEndPoints.defaultReasons , reason);
  }

  editReason(reason : Reason){
    return this.httpClient.put<any>(urlEndPoints.defaultReasons + reason._id , reason);
  }

}