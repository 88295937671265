import {Injectable} from '@angular/core';
import {StatopDal} from '../dal/statop.dal';
import {Observable} from 'rxjs';
import { activityData } from '../interfaces/statop.interfaces';

@Injectable({
  providedIn: 'root'
})
export class StatopService {

  constructor(private statopDal: StatopDal) {
  }
  //////////////////
  // General      //
  //////////////////
  // First Row

  getActivityData(fromDate: string, toDate: string, profilesType: string, hospitalId: string, surgeonId: string, roomId: string, specialtyId: string, limit: string): Observable<activityData> {
    return this.statopDal.getActivityData(fromDate, toDate, profilesType, hospitalId, surgeonId, roomId, specialtyId, limit);
  }

  getEfficacityData(fromDate: string, toDate: string): Observable<any> {
    return this.statopDal.getEfficacityData(fromDate, toDate);
  }

  getRoomAnalysisData(fromDate: string, toDate: string, roomId: string): Observable<any> {
    return this.statopDal.getRoomAnalysisData(fromDate, toDate, roomId);
  }

  getSpecialtyAnalysisData(fromDate: string, toDate: string, specialtyId: string): Observable<any> {
    return this.statopDal.getSpecialtyAnalysisData(fromDate, toDate, specialtyId);
  }

  getSlowRequestsData(fromDate: string, toDate: string, roomId: string, specialtyId: string): Observable<any> {
    return this.statopDal.getSlowRequestsData(fromDate, toDate, roomId, specialtyId);
  }
  

  getStatOpInterventionsTotal(startDate: string, endDate: string): Observable<any> {
    return this.statopDal.getStatOpInterventionsTotal(startDate, endDate);
  }

  getStatOpInterventionsProgress(startDate: string, endDate: string): Observable<any> {
    return this.statopDal.getStatOpInterventionsProgress(startDate, endDate);
  }

  getStatOpInterventionsPerDay(startDate: string, endDate: string): Observable<any> {
    return this.statopDal.getStatOpInterventionsPerDay(startDate, endDate);
  }

  // Second Row

  getStatOpInterventionsPerSpecialty(startDate: string, endDate: string): Observable<any> {
    return this.statopDal.getStatOpInterventionsPerSpecialty(startDate, endDate);
  }

  getStatOpInterventionsTopSpecialty(startDate: string, endDate: string, limit: string): Observable<any> {
    return this.statopDal.getStatOpInterventionsTopSpecialty(startDate, endDate, limit);
  }

  // Third Row

  getStatOpInterventionsPerSpecialtySurgeon(startDate: string, endDate: string): Observable<any> {
    return this.statopDal.getInterventionsPerSpecialtySurgeon(startDate, endDate);
  }

  getEffectivenessRate(startDate: string, endDate: string): Observable<any> {
    return this.statopDal.getEffectivenessRate(startDate, endDate);
  }

  getRoomData(startDate: string, endDate: string, roomId: string): Observable<any> {
    return this.statopDal.getRoomData(startDate, endDate, roomId);
  }

  getRoomTop(startDate: string, endDate: string, roomId: string): Observable<any> {
    return this.statopDal.getRoomTop(startDate, endDate, roomId);
  }

  getSpecialtyTop(startDate: string, endDate: string, specialtyId: string): Observable<any> {
    return this.statopDal.getSpecialtyTop(startDate, endDate, specialtyId);  
  }

  getRatesHeader(startDate: string, endDate: string, roomId: string): Observable<any> {
    return this.statopDal.getRatesHeader(startDate, endDate, roomId);
  }

  getRatesHeaderSpecialty(startDate: string, endDate: string, specialtyId: string): Observable<any> {
    return this.statopDal.getRatesHeaderSpecialty(startDate, endDate, specialtyId);
  }
  
  getRoomEffectivenessRatePerDay(startDate: string, endDate: string, roomId: string): Observable<any> {
    return this.statopDal.getRoomEffectivenessRatePerDay(startDate, endDate, roomId);
  }

  getRoomEffectivenessRate(startDate: string, endDate: string, roomId: string): Observable<any> {
    return this.statopDal.getRoomEffectivenessRate(startDate, endDate, roomId);
  }

  getRoomsBubblesData(startDate: string, endDate: string): Observable<any> {
    return this.statopDal.getRoomsBubblesData(startDate, endDate);
  }

  getStatOpBoxPlot(startDate: string, endDate: string): Observable<any> {
    return this.statopDal.getStatOpBoxPlot(startDate, endDate);
  }

  //////////////////
  // One Surgeon  //
  //////////////////

  getOneSurgeonFirstGeneralData(startDate: string, endDate: string, surgeonId: string, hospital?: string): Observable<any> {
    return this.statopDal.getOneSurgeonFirstGeneralData(startDate, endDate, surgeonId, hospital);
  }

  // Fifth Row Row

  getOneSurgeonStatOpInterventionsTotal(startDate: string, endDate: string, surgeonId: string, roomId?: string): Observable<any> {
    return this.statopDal.getOneSurgeonStatOpInterventionsTotal(startDate, endDate, surgeonId, roomId);
  }

  getOneSurgeonStatOpInterventionsProgress(startDate: string, endDate: string, surgeonId: string): Observable<any> {
    return this.statopDal.getOneSurgeonStatOpInterventionsProgress(startDate, endDate, surgeonId);
  }

  getOneSurgeonStatOpInterventionsPerDay(startDate: string, endDate: string, surgeonId: string): Observable<any> {
    return this.statopDal.getOneSurgeonStatOpInterventionsPerDay(startDate, endDate, surgeonId);
  }

  getOneSurgeonStatOpInterventionsTotalPerRoom(startDate: string, endDate: string, surgeonId: string, roomId: string): Observable<any> {
    return this.statopDal.getOneSurgeonStatOpInterventionsTotalPerRoom(startDate, endDate, surgeonId, roomId);
  }
  // Sixth Row

  getOneSurgeonEffectivenessRate(startDate: string, endDate: string, surgeonId: string): Observable<any> {
    return this.statopDal.getOneSurgeonEffectivenessRate(startDate, endDate, surgeonId);
  }

  getOneSurgeonEffectivenessRatePerDay(startDate: string, endDate: string, surgeonId: string): Observable<any> {
    return this.statopDal.getOneSurgeonEffectivenessRatePerDay(startDate, endDate, surgeonId);
  }
  getSurgeonsRanking(startDate: string, endDate: string , hospitalId: any, hasToValidate?: boolean): Observable<any> {
    return this.statopDal.getSurgeonsRanking(startDate, endDate, hospitalId, hasToValidate);
  }
  getAdditionRate(fromDate: string, toDate: string, surgeonId: string): Observable<any> {
    return this.statopDal.getAdditionRate(fromDate, toDate, surgeonId);
  }
  getOvertakingRate(fromDate: string, toDate: string, surgeonId: string): Observable<any> {
    return this.statopDal.getOvertakingRate(fromDate, toDate, surgeonId);
  }


  getCountsPerReasons(hospitalId: any ,startDate: string, endDate: string, profilesType: string): Observable<any> {
    return this.statopDal.getCountsPerReasons(hospitalId, startDate, endDate, profilesType);
  }

  getProfilesByReasonAndDate(hospitalId, date: string, reasonId: string, profilesType: string): Observable<any> {
		return this.statopDal.getProfilesByReasonAndDate(hospitalId, date, reasonId, profilesType);
  }

  getStatOpEffectifPerSeniority(startDate: string, endDate: string): Observable<any> {
    return this.statopDal.getStatOpEffectifPerSeniority(startDate, endDate);
  }

  getStatOpEffectifTotal(startDate: string, endDate: string): Observable<any> {
    return this.statopDal.getStatOpEffectifTotal(startDate, endDate);    
  }

  getStatOpResidencySeniorityDistribution(startDate: string, endDate: string): Observable<any> {
    return this.statopDal.getStatOpResidencySeniorityDistribution(startDate, endDate);
  }

  getStatOpNeedsPerSpecialty(startDate: string, endDate: string): Observable<any> {
    return this.statopDal.getStatOpNeedsPerSpecialty(startDate, endDate);
  }

  getStatOpAvailability(startDate: string, endDate: string): Observable<any> {
    return this.statopDal.getStatOpAvailability(startDate, endDate);
  }

  getTopThreeNumberOfGuards(startDate: string, endDate: string): Observable<any> {
    return this.statopDal.getTopThreeNumberOfGuards(startDate, endDate);
  }

  getStatOpAbsencePerReason(startDate: string, endDate: string , profile?:string): Observable<any> {
    return this.statopDal.getStatOpAbsencePerReason(startDate, endDate ,profile);
  }

  getStatOpExtracliniqueProfiles(startDate: string, endDate: string): Observable<any> {
    return this.statopDal.getStatOpExtracliniqueProfiles(startDate, endDate);
  }

  getStatOpGeneral(startDate: string, endDate: string, type: string, specialty:string): Observable<any> {
    return this.statopDal.getStatOpGeneral(startDate, endDate, type , specialty);
  }

  getStatOpDistributionPerType(startDate: string, endDate: string , profileId: string): Observable<any> {
    return this.statopDal.getStatOpDistributionPerType(startDate, endDate ,profileId);
  }

  getStatOpNumberOfGuards(startDate: string, endDate: string, profileId :string): Observable<any> {
    return this.statopDal.getStatOpNumberOfGuards(startDate, endDate, profileId);
  }

  getStatOpGuardDsitribution(startDate: string, endDate: string, profileId: string): Observable<any> {
    return this.statopDal.getStatOpGuardDsitribution(startDate, endDate ,profileId);
  }

  getStatOpWeekendGuardDsitribution(startDate: string, endDate: string, profileId: string): Observable<any> {
    return this.statopDal.getStatOpWeekendGuardDsitribution(startDate, endDate ,profileId);
  }
  getRatesVariationPerDate(fromDate:string,toDate:string) : Observable<any> {
    return this.statopDal.getRatesVariationPerDate(fromDate,toDate);
  }
  getRatesPerDayOfTheWeek(fromDate:string,toDate:string,room?:string) : Observable<any> {
    return this.statopDal.getRatesPerDayOfTheWeek(fromDate,toDate,room);
  }
  getGlobalRates(fromDate:string,toDate:string,room?:string) : Observable<any> {
    return this.statopDal.getGlobalRates(fromDate,toDate,room);
  }
  getSurgeonsOverflowRate(startDate: string, endDate: string , hasToValidate?: boolean): Observable<any> {
    return this.statopDal.getSurgeonsOverflowRate(startDate, endDate, hasToValidate);
  }
  getRatesPerDateAndRoom(startDate: string, endDate: string , hospitalId): Observable<any> {
    return this.statopDal.getRatesPerDateAndRoom(startDate, endDate, hospitalId);
  }

/* ************************ */

  // TVO
  getTVO(startDate: string, endDate: string): Observable<any> {
    return this.statopDal.getTVO(startDate, endDate);
  }

  //not used anymore
  getTVOBySurgeon(startDate: string, endDate: string, surgeonId: string): Observable<any> {
    return this.statopDal.getTVO(startDate, endDate, surgeonId, undefined);
  }

  getTVOByRoom(startDate: string, endDate: string, roomId: string): Observable<any> {
    return this.statopDal.getTVO(startDate, endDate, undefined, roomId);
  }

  getTVOBySpecialty(startDate: string, endDate: string, specialtyId: string): Observable<any> {
    return this.statopDal.getTVO(startDate, endDate, undefined, undefined, specialtyId);
  }

  getTVOBySurgeonByRoom(startDate: string, endDate: string, surgeonId: string, roomId?: string): Observable<any> {
    return this.statopDal.getTVO(startDate, endDate, surgeonId, roomId);
  }

  // Total TVO
  getTotalTVOBySurgeon(startDate: string, endDate: string, surgeonId: string): Observable<any> {
    return this.statopDal.getTotalTVO(startDate, endDate, surgeonId, undefined);
  }

  getTotalTVOBySurgeonByRoom(startDate: string, endDate: string, surgeonId: string, roomId: string): Observable<any> {
    return this.statopDal.getTotalTVO(startDate, endDate, surgeonId, roomId);
  }

  //TROS + TROV
  getTROS(startDate: string, endDate: string, withoutUrgences?: boolean): Observable<any> {
    return this.statopDal.getTROS(startDate, endDate, undefined, undefined, undefined, withoutUrgences);
  }

  getTROSBySurgeon(startDate: string, endDate: string, surgeonId: string, withoutUrgences?: boolean): Observable<any> {
    return this.statopDal.getTROS(startDate, endDate, surgeonId, undefined, undefined, withoutUrgences);
  }

  getTROSByRoom(startDate: string, endDate: string, roomId: string, withoutUrgences?: boolean): Observable<any> {
    return this.statopDal.getTROS(startDate, endDate, undefined, roomId, undefined, withoutUrgences);
  }
  
  getTROSBySpecialty(startDate: string, endDate: string, specialtyID: string, withoutUrgences?: boolean): Observable<any> {
    return this.statopDal.getTROS(startDate, endDate, undefined, undefined, specialtyID, withoutUrgences);
  }

  getTROSBySurgeonByRoom(startDate: string, endDate: string, surgeonId: string, roomId?: string, withoutUrgences?: boolean) {
    return this.statopDal.getTROS(startDate, endDate, surgeonId, roomId, undefined, withoutUrgences);
  }

  // getTROSWithouUrgences(startDate: string, endDate: string, withoutUrgences: boolean): Observable<any> {
  //   return this.statopDal.getTROS(startDate, endDate, undefined, undefined, undefined, withoutUrgences);
  // }

  // getTROSBySurgeonWithouUrgences(startDate: string, endDate: string, surgeonId: string): Observable<any> {
  //   return this.statopDal.getTROS(startDate, endDate, surgeonId, undefined, undefined, true);
  // }

  // getTROSByRoomWithouUrgences(startDate: string, endDate: string, roomId: string): Observable<any> {
  //   return this.statopDal.getTROS(startDate, endDate, undefined, roomId, undefined, true);
  // }

  // getTROSBySpecialtyWithouUrgences(startDate: string, endDate: string, specialtyID: string): Observable<any> {
  //   return this.statopDal.getTROS(startDate, endDate, undefined, undefined, specialtyID, true);
  // }

  // late Start
  getAverageLateStartRate(startDate: string, endDate: string): Observable<any> {
    return this.statopDal.getAverageLateStartRate(startDate, endDate);
  }

  getAverageLateStartRateByRoom(startDate: string, endDate: string, roomId: string): Observable<any> {
    return this.statopDal.getAverageLateStartRate(startDate, endDate, undefined, roomId);
  }

  getAverageLateStartRateBySurgeon(startDate: string, endDate: string, surgeonId: string): Observable<any> {
    return this.statopDal.getAverageLateStartRate(startDate, endDate, surgeonId, undefined);
  }

  getAverageLateStartRateBySpecialty(startDate: string, endDate: string, specialtyId: string): Observable<any> {
    return this.statopDal.getAverageLateStartRate(startDate, endDate, undefined, undefined, specialtyId);
  }

  getAverageLateStartRateBySurgeonByRoom(startDate: string, endDate: string, surgeonId: string, roomId: string): Observable<any> {
    return this.statopDal.getAverageLateStartRate(startDate, endDate, surgeonId, roomId);
  }

  // earlyEnd
  getAverageEarlyEndRate(startDate: string, endDate: string): Observable<any> {
    return this.statopDal.getAverageEarlyEndRate(startDate, endDate);
  }

  getAverageEarlyEndRateByRoom(startDate: string, endDate: string, roomId: string): Observable<any> {
    return this.statopDal.getAverageEarlyEndRate(startDate, endDate, undefined, roomId);
  }

  getAverageEarlyEndRateBySurgeon(startDate: string, endDate: string, surgeonId: string): Observable<any> {
    return this.statopDal.getAverageEarlyEndRate(startDate, endDate, surgeonId, undefined);
  }

  getAverageEarlyEndRateBySpecialty(startDate: string, endDate: string, specialtyId: string): Observable<any> {
    return this.statopDal.getAverageEarlyEndRate(startDate, endDate, undefined,undefined, specialtyId);
  }
  
  getAverageEarlyEndRateBySurgeonByRoom(startDate: string, endDate: string, surgeonId: string, roomId: string): Observable<any> {
    return this.statopDal.getAverageEarlyEndRate(startDate, endDate, surgeonId, roomId);
  }

  // isAmbu
  getIsAmbuRate(startDate: string, endDate: string): Observable<any> {
    return this.statopDal.getIsAmbuRate(startDate, endDate);
  }

  getIsAmbuRateByRoom(startDate: string, endDate: string, roomId: string): Observable<any> {
    return this.statopDal.getIsAmbuRate(startDate, endDate, undefined, roomId);
  }
  
  getIsAmbuRateBySurgeon(startDate: string, endDate: string, surgeonId: string): Observable<any> {
    return this.statopDal.getIsAmbuRate(startDate, endDate, surgeonId, undefined);
  }

  getIsAmbuRateBySpecialty(startDate: string, endDate: string, specialtyId: string): Observable<any> {
    return this.statopDal.getIsAmbuRate(startDate, endDate, undefined, undefined, specialtyId);
  }

  getIsAmbuRateBySurgeonByRoom(startDate: string, endDate: string, surgeonId: string, roomId: string): Observable<any> {
    return this.statopDal.getIsAmbuRate(startDate, endDate, surgeonId, roomId);
  }

  // isUrgences
  getIsUrgencesRate(startDate: string, endDate: string): Observable<any> {
    return this.statopDal.getIsUrgencesRate(startDate, endDate);
  }

  getIsUrgencesRateByRoom(startDate: string, endDate: string, roomId: string): Observable<any> {
    return this.statopDal.getIsUrgencesRate(startDate, endDate, undefined, roomId);
  }
  
  getIsUrgencesRateBySurgeon(startDate: string, endDate: string, surgeonId: string): Observable<any> {
    return this.statopDal.getIsUrgencesRate(startDate, endDate, surgeonId, undefined);
  }

  getIsUrgencesRateBySpecialty(startDate: string, endDate: string, specialtyId: string): Observable<any> {
    return this.statopDal.getIsUrgencesRate(startDate, endDate, undefined, undefined, specialtyId);
  }

  getIsUrgencesRateBySurgeonByRoom(startDate: string, endDate: string, surgeonId: string, roomId: string): Observable<any> {
    return this.statopDal.getIsUrgencesRate(startDate, endDate, surgeonId, roomId);
  }

  // interInterventions
  getTimeBetweenInterventions(startDate: string, endDate: string): Observable<any> {
    return this.statopDal.getTimeBetweenInterventions(startDate, endDate);
  }

  getTimeBetweenInterventionsByRoom(startDate: string, endDate: string, roomId: string): Observable<any> {
    return this.statopDal.getTimeBetweenInterventions(startDate, endDate, undefined, roomId);
  }

  getTimeBetweenInterventionsBySurgeon(startDate: string, endDate: string, surgeonId: string): Observable<any> {
    return this.statopDal.getTimeBetweenInterventions(startDate, endDate, surgeonId, undefined);
  }

  getTimeBetweenInterventionsBySurgeonByRoom(startDate: string, endDate: string, surgeonId: string, roomId: string): Observable<any> {
    return this.statopDal.getTimeBetweenInterventions(startDate, endDate, surgeonId, roomId);
  }

  getTimeBetweenInterventionsBySpecialty(startDate: string, endDate: string, specialtyId: string): Observable<any> {
    return this.statopDal.getTimeBetweenInterventions(startDate, endDate, undefined, undefined, specialtyId);
  }

  // Cleaning time
  getCleaningTime(startDate: string, endDate: string): Observable<any> {
    return this.statopDal.getCleaningTime(startDate, endDate);
  }

  getCleaningTimeBySurgeonByRoom(startDate: string, endDate: string, surgeonId: string, roomId?: string): Observable<any> {
    return this.statopDal.getCleaningTime(startDate, endDate, surgeonId, roomId);
  }

  getCleaningTimeByRoom(startDate: string, endDate: string, roomId: string): Observable<any> {
    return this.statopDal.getCleaningTime(startDate, endDate, undefined, roomId);
  }

  getCleaningTimeBySpecialty(startDate: string, endDate: string, specialtyId: string): Observable<any> {
    return this.statopDal.getCleaningTime(startDate, endDate, undefined, undefined, specialtyId);
  }


  getRoomHours(startDate: string, endDate: string, roomId: string): Observable<any> {
    return this.statopDal.getRoomHours(startDate, endDate, roomId);
  }
}
