import * as moment from "moment";
import { themeStylingColors } from "src/themes/common/principal-theme";
import { NURSE_TYPES, OVERFLOW_THRESHOLD, SURGEON, TOPICS } from "../const/glabals.const";
import { Profile } from "../models/profile.model";
import { Room } from "../models/room.model";
import { Hospital } from "../models/hospital.model";
import { profileCard } from "../components/day-program-recap/day-program-interfaces";
import { Formation } from "../models/formation.model";
import { Effective } from "../components/effective-needs/interfaces";
import { DoctorAgenda } from "../models/doctor-agenda.model";

export const POINTER_CURSOR = {
  grabbing : 'grabbing',
  grab : 'grab',
  rowResize : 'row-resize',
  default : 'default'
}

export function sortByDate(array: any[]): any[] {
  return array.sort((a, b): any => {
    return new Date(a.date).getTime() - new Date(b.date).getTime();
  });
}
export function setTypeAttributeArray(
  roomsData: any[],
  isConfigMode: boolean
): string[] {
  const attrArray: string[] = [];
  for (const roomData of roomsData) {
    if (isConfigMode) {
      attrArray.push("surgeonopeninghistory");
    } else {
      if (roomData.surgeonopening && roomData.surgeonopening.length > 0) {
        attrArray.push("surgeonopening");
      } else {
        attrArray.push("surgeonopeninghistory");
      }
    }
  }
  return attrArray;
}
export function idStringGenerator() {
  var S4 = function() {
     return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
  };
  return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
}

export function getSpecialtyFontColor(specialtyName) {
  switch (specialtyName) {
    case "Orthopédie membre supérieur":
      return "white";
    case "Orthopédie membre inférieur":
      return "white";
    case "Vasculaire-thoracique":
      return "black";
    case "Digestif":
      return "black";
    case "Urologie":
      return "black";
    case "Orl":
      return "black";
    case "Rythmologie":
      return "white";
    case "Endoscopie":
      return "black";
    case "Stomatologie":
      return "black";
    case "Esthétique":
      return "white";
    case "Ophtalmologie":
      return "black";
    case "Gynéco-obstétrique":
      return "black";
    case "Pneumologie":
      return "black";
    case "Bariatrique":
      return "black";
    default:
      return "black";
  }
}

export function getStructuredDate(stringDate: string | number): string {
  const date: Date = new Date(stringDate);
  return (
    date.getUTCDate() +
    "/" +
    (date.getUTCMonth() + 1) +
    "/" +
    date.getUTCFullYear()
  );
}

export function RemoveAccents(str: any): string {
  const accents =
    "ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž";
  const accentsOut =
    "AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
  str = str.split("");
  const strLen = str.length;
  let i;
  let x;
  for (i = 0; i < strLen; i++) {
    if ((x = accents.indexOf(str[i])) !== -1) {
      str[i] = accentsOut[x];
    }
  }
  return str.join("");
}
export function setCursorPointer(cursorType:string){
  document.body.style.cursor = cursorType;
}
export function transformText(text: string, isNurse: boolean): string {
  const {
    CALENDAR,
    VACATIONS,
    SMART_BRIEF,
    PARCOURS_IBODE_REQUEST,
    PARCOURS_IBODE_RECAP,
    PROGRAM,
    MESSAGERIE,
    STATOP
  } = TOPICS;

  switch (text) {
    case MESSAGERIE:
      return "Messagerie";
    case CALENDAR:
      return "Calendriers";
    case VACATIONS:
      return "Calendriers";
    case SMART_BRIEF:
      return "Smart brief";
    case PARCOURS_IBODE_REQUEST:
      return "Parcours de Compétences";
    case PARCOURS_IBODE_RECAP :
      return "Parcours de Compétences";
    case PROGRAM:
      return "SmartPlanning";
    case STATOP:
      return "StatOp";
    case "AVAILABLE2":
      return "Disponible";
    case "ECHANGE_DE_JOUR":
      return "Echange de jour";
    case "ABSENT":
      return "Absence";
    case "BLOC":
      return "Bloc";
    case "AVAILABLE":
      return "Présence";
    case "PARTIAL_AVAILABILITY":
      return "Présence partielle";
    case "CONSULTATION":
      return "Consultation";
    case "CONGRESS":
      return "Congrès";
    case "TRAINING":
      return "Formation";
    case "SICKNESS":
      return "Maladie";
    case "VACATION":
      return "Vacation";
    case "GUARD":
      return isNurse ? "Astreinte" : "Garde";
    case "CP":
      return "Congé payé";
    case "GUARD_REST":
      return isNurse ? "Repos d'astreinte" : "Repos de garde";
    case "OTHER":
      return "Autre";

    case "ONHOLD":
      return "En attente";
    case "APPROVED":
      return "Approuvée";
    case "REJECTED":
      return "Rejetée";
    case "OPEN":
      return "Ouverte"

    default:
      return text;
  }
}


export function getTimeFromValue(value: number): string {
  return (
    Math.floor(value) + "h" + ((value - Math.floor(value)) * 60).toFixed(0)
  );
}
export function getPercenteValue(value: number): string {
  return (value * 100).toFixed(1).split(".").join(",");
}
export function reversedPercent(value: string): number {
  if (!value.includes(",")) {
    return parseInt(value);
  }
  return parseFloat(value.split(",").join("."));
}
export function transformedDate(stringDate: string): Date {
  return moment(stringDate)
    .clone()
    .utc()
    .add({ hour: 12 })
    .set({ hour: 12 })
    .toDate();
}

export function capitalize(s: string) {
  if(!s || !s.length) return '';
  let text = s.toLowerCase();
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function geFloatNumber(number: number, numberAfter: number) : number {
  if (number === null || number === undefined) {
    return number;
  }
  let num = 0;
  if (number.toString().includes(".")) {
    num = parseFloat(number.toFixed(numberAfter))
  }else{
    return num = number;
  }  
  return num
}

export function onAvatarError(profile : Profile): string {
  let picture: string;
  if (NURSE_TYPES.includes(profile.position)) {
    picture = 'assets/images/icons/nurse.svg';
  } else {
    switch (profile.position) {
      case 'Chirurgien':
        picture = 'assets/images/icons/surgeon.svg';
        break;
      case 'Anesthésiste':
        picture = 'assets/images/icons/f-doctor.svg';
        break;
      case 'Iade':
        picture = 'assets/images/icons/f-doctor.svg';
        break;
      case 'IADE':
        picture = 'assets/images/icons/f-doctor.svg';
        break;
      case 'Cadre de bloc':
        picture = 'assets/images/icons/surgeon.svg';
        break;
      default:
        picture = 'assets/images/icons/surgeon.svg';
    }
  }
  return picture;
}

export function escapeRegExp(string: string): string {
  if (string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }
  return string;
}

export function toPascalCase(str: string): string {
  if (!str) {
    return str;
  }
  return str.replace(/(\w)(\w*)/g, function(g0,g1,g2){return g1.toUpperCase() + g2.toLowerCase();});
}

export function toFixed(number: number, numberOfNumbersAfterDecimialPoint: number): string {
  const multiplier = Math.pow(10, numberOfNumbersAfterDecimialPoint);
  const result = Math.round(number * multiplier) / multiplier;
  return `${result}`;
}

export function sortByRoomsByRoomNumber(array: any[], isItArrayOfRooms: boolean): void {
  // This function sort rooms with numbers before rooms with strings

  if (!array) {
    return;
  }

  array = array.sort((a, b) => {
    const item1 = isItArrayOfRooms ? a : a.room;
    const item2 = isItArrayOfRooms ? b : b.room;

    let comparingRoomNumberResult;

    if (isNaN(Number(item1.roomNumber)) && isNaN(Number(item2.roomNumber))) {
      // roomNumber of both elements is a string
      comparingRoomNumberResult = String(item1.roomNumber).localeCompare(String(item2.roomNumber));
    } else if (!isNaN(Number(item1.roomNumber)) && !isNaN(Number(item2.roomNumber))) {
      // roomNumber of both elements is a number
      comparingRoomNumberResult = Number(item1.roomNumber) - Number(item2.roomNumber);
    } else {
      if (isNaN(Number(item1.roomNumber))) {
        comparingRoomNumberResult = 1;
      } else {
        comparingRoomNumberResult = -1;
      }
    }
    
          
    if (comparingRoomNumberResult == 0) {
      return String(item1._id).localeCompare(String(item2._id));
    }

    return comparingRoomNumberResult;
  });
}

export function dateWithTime(date: any, time: any): Date {
  date = new Date(date);
  time = new Date(time);
  let hour = time.getUTCHours(),
    minutes = time.getUTCMinutes(),
    secondes = time.getUTCSeconds();
  date = new Date(date).setUTCHours(hour);
  date = new Date(date).setUTCMinutes(minutes);
  date = new Date(date).setUTCSeconds(secondes);
  if (date) return date;
  else return null;
}

export function getRandomColor(index: number) {
  const colors = [
    '#8EA9DB',
    '#B4C6E7',
    '#D9E1F2',
    '#03C0C1',
    '#808080',
    '#BFBFBF',
    '#00D8D8',
    '#007F80',
    '#03FDFC',
    '#009C9D',
    '#A6A6A6',
    '#F2F2F2',
    '#D9D9D9',
  ];
  
  return colors[index];
}
export function initIsDifferentSurgeonsBetweenSurgeonOpeningsAndDoctorAgendas(roomsAllData:any[],roomsSurgeonOpeningsAllData: any[],roomId: string = null): void {
    // init isDifferentSurgeonsBetweenSurgeonOpeningsAndDoctorAgendas for all rooms
    roomsAllData.forEach((elt) => {
      const surgeonOpening = roomsSurgeonOpeningsAllData.find((elt2) => String(elt2.room._id) === String(elt.room._id));

      const displayAlert = displayDifferenceAlert(surgeonOpening, elt);
      const doesDoctorAgendasHaveConflicts = checkIfDoctorAgendasHaveConflicts(elt.doctoragendas);

      surgeonOpening.isDifferentSurgeonsBetweenSurgeonOpeningsAndDoctorAgendas = displayAlert //delete?
      elt.isDifferentSurgeonsBetweenSurgeonOpeningsAndDoctorAgendas = displayAlert;

      surgeonOpening.doesDoctorAgendasHaveConflicts = doesDoctorAgendasHaveConflicts; //delete?
      elt.doesDoctorAgendasHaveConflicts = doesDoctorAgendasHaveConflicts;
    });
 
}

function checkIfDoctorAgendasHaveConflicts(doctorAgendas: DoctorAgenda[]): boolean {
  doctorAgendas = doctorAgendas.sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime());

  for (let i = 0; i < doctorAgendas.length - 1; i++) {
    if (doctorAgendas[i].endTime > doctorAgendas[i + 1].startTime) {
      return true;
    }
  }

  return false;
}

function displayDifferenceAlert(sOdata, dAdata) {
  const selectedHospital = dAdata.room.hospital._id
  const surgeonOpeningsRecords = sOdata.surgeonopening.filter((SO) => SO.opening);
  const doctorAgendasRecords = dAdata.doctoragendas;

  // We don't have any doctor agendas but have so => we show the alert
  // We don't have any doctor agendas and don't have any so => we don't show the alert
  if (!doctorAgendasRecords.length && surgeonOpeningsRecords.length) {
    return true;
  } else if (!doctorAgendasRecords.length) {
    return false;
  }

  // We have doctor agendas and we don't have any surgeon opening => we show the alert
  if (!surgeonOpeningsRecords.length) {
    return true;
  }

  // We have doctor agendas and we have surgoen openings, we need to check for each doctor agenda that we have a correspond surgeon opening
  for (const daRecord of doctorAgendasRecords) {
    const doctorAgendaSpecialty = daRecord && daRecord.surgeon.specialties && daRecord.surgeon.specialties
      .filter((specialty) => specialty.hospital === selectedHospital)[0];

    let foundACorrespondingSurgeonOpening = false;
    
    for (const soRecord of surgeonOpeningsRecords) {
      // Check if it is a surgeon opening with the same surgeon
      if (daRecord.surgeon._id === soRecord.surgeon._id) {
        foundACorrespondingSurgeonOpening = true;
        break;
      }

      // Check if it is a surgeon opening with Urgences specialty
      const surgeonOpeningSpecialty = soRecord.surgeon.specialties && soRecord.surgeon.specialties
        .filter((specialty) => specialty.hospital === selectedHospital)[0];

      // If we have Urgences, we don't need to check for any doctor agenda, we don't show the alert
      if (surgeonOpeningSpecialty.name === "Urgences") {
        return false;
      }

      // Check if it is a surgeon opening with a generic surgeon that have the same specialty
      if (soRecord.surgeon.isDefaultSpecialtySurgeon && doctorAgendaSpecialty._id === surgeonOpeningSpecialty._id) {
        foundACorrespondingSurgeonOpening = true;
        break;
      }
    }

    if (!foundACorrespondingSurgeonOpening) {
      return true;
    }
  }

  // If we didn't quit before means every doctor agenda have a correspond surgeon opening
  return false;
}

export function sortRoomsByPriority(rooms, isItArrayOfRooms: boolean){
  if (!rooms) {
    return;
  }

  rooms.sort((a, b) => {
    const itemA = isItArrayOfRooms ? a : a.room;
    const itemB = isItArrayOfRooms ? b : b.room;
    if ((!itemA.priority || itemA.priority === 0) && (!itemB.priority || itemB.priority === 0)) {
      return itemA._id.localeCompare(itemB._id);
      // return 0;
    } else if (!itemA.priority || itemA.priority === 0) {
      return 1;
    } else if (!itemB.priority || itemB.priority === 0 ) {
      return -1;
    } else {
      const itemAHospitalId = itemA.hospital._id ? itemA.hospital._id : itemA.hospital;
      const itemBHospitalId = itemB.hospital._id ? itemB.hospital._id : itemB.hospital;

      const hospitalOrder = itemAHospitalId.localeCompare(itemBHospitalId);
      if (hospitalOrder === 0) {
        const priorityOrder = itemA.priority - itemB.priority;
        if (priorityOrder === 0) {
          return itemA._id.localeCompare(itemB._id);
        } else {
          return priorityOrder;
        }
      } else {
        return hospitalOrder;
      }
    }
  });

}

export function getSurgeonSpecialtiesNameBySelectedHospitals( profile, selectedHospitalsId: string[]) {
  let specialtiesName = []
  if (profile && profile.position === SURGEON && profile.specialties && profile.specialties.length > 0) {
    selectedHospitalsId.forEach(hospitalId => {
      if (Array.isArray(profile.specialties)) { 
      profile.specialties.forEach(specialty => {
        if (specialty) {
          if (String(specialty.hospital) === String(hospitalId)) {
            specialtiesName.push(specialty.name);
          }
        }
      })
    } else {
      specialtiesName.push(profile.specialties.name);
    }
    });
  }
  return specialtiesName;
}

export function getSurgeonSpecialtiesBySelectedHospitals(profile: Profile, selectedHospitalsId: string[]) {
  let specialties = []
  if (profile.position === SURGEON) {
    selectedHospitalsId.forEach(hospitalId => {
      profile.specialties.forEach(specialty => {
        if (String(specialty.hospital) === String(hospitalId)) {
          specialties.push(specialty)
        }
      });
    });
  }
  return specialties;
}

export function getHospitalsBySelectedHospitals(profile: Profile, selectedHospitalsId: string[]) {
  let hospitals = []
  selectedHospitalsId.forEach(hospitalId => {
    profile.hospitals.forEach(hospital => {
      if (String(hospital._id) === String(hospitalId)) {
        hospitals.push(hospital)
      }
    });
  });
  return hospitals;
}

export function getHospitalsNameBySelectedHospitals(profile: Profile, selectedHospitalsId: string[]) {
  let hospitalsName = []
  selectedHospitalsId.forEach(hospitalId => {
    
    if (profile.desactivatedAt && profile.desactivatedAt.some(objectId => String(objectId) === hospitalId)) {
      return ;
    }

    profile.hospitals.forEach(hospital => {

      if (String(hospital._id) === String(hospitalId)) {
        hospitalsName.push(hospital.name)
      }
    });
  });
  return hospitalsName;
}

export function setOverflowDatasColors(input: any): void {
    if (input.overflowDuration != null) {
        if (Math.abs(input.overflowDuration) > OVERFLOW_THRESHOLD) {
          if (input.overflowDuration < 0) {
            input.overflowText = 'faible';
            input.overflowColor = 'green'
          } else {
            input.overflowText = 'élevée';
            input.overflowColor = 'red'
          }
        } else {
          input.overflowText = 'modérée';
          input.overflowColor = 'orange'
        }
      }
}

export function getRateColor(overflowRate: number): string {
    const ORANGE_RATE_THRESHOLD = 5;
    
    if (overflowRate > ORANGE_RATE_THRESHOLD) {
      return 'red';
    } else if (overflowRate > 0) {
      return 'orange';
    }  else {
      return 'green';
    }
  }

export function calculateOverflowRate(openedDuration: number, overflowDuration: number, precision: number): number {
    if (openedDuration > 0) {
      if (overflowDuration > 0) {
        return Number(((overflowDuration / openedDuration) * 100).toFixed(precision));
      } else {
        return 0;
      }
    } else {
      return 100;
    }
}

export function organizeDataWithDayOfWeekForFrontend(array): any[] {
  let response = Array(7);
  /** 
   * 1 --> 6 dimanche
   * 2 --> 0 lundi
   * 3 --> 1 mardi
   * 4 --> 2 mercredi
   * 5 --> 3 jeudi
   * 6 --> 4 vendredi
   * 7 --> 5 samedi
  */
  array.forEach((elt) => {
    const index = (((elt.dayOfWeek - 2) % 7) + 7) % 7;
    response[index] = elt;
  });

  return response;
}

export function formatHoursToHoursAndMinutes(initialData): any {
  let objectToReturn: any = {
    hours: 0,
    minutes: 0
  };

  if (initialData === 0) {
    objectToReturn.hours = 0;
    objectToReturn.minutes = 0;
  } else {
    objectToReturn.hours = Math.floor(initialData);
    objectToReturn.minutes = Math.round((initialData - objectToReturn.hours) * 60);
  }
  return objectToReturn;
}

export function getFirstHospitalSelectedData(user, selectedHospitals): Hospital {
  if (user && user.profile && user.profile.hospitals && selectedHospitals) {
    const userHospitals = user.profile.hospitals
    const hospital = userHospitals.find((hospital) => String(hospital._id) === String(selectedHospitals[0]))
    return hospital
  }

  return null
}

export function getSelectedHospitalData(user, hospitalId) {
  const userHospitals = user.profile.hospitals
  const hospital = userHospitals.find((hospital) => String(hospital._id) === String(hospitalId))
  return hospital
}

export function getWorkForce(formations: Formation[], nurses: profileCard[], middleTime: string): Effective {
  const workforce: Effective = { morningNurseEffectif: [], afternoonNurseEffectif: [] };

  formations.forEach(formation => addFormationToGroups(formation, workforce, formations));
  nurses.forEach(nurse => addNurseToGroups(nurse, workforce, middleTime));
  
  return workforce;
}

function addFormationToGroups(formation: Formation, workforce: Effective, formations: Formation[]) {
  if (formation.groupedWith.length != 0 && isAlreadyAdded(formation, workforce))
    return;

  let formationsTab = formation.groupedWith.length == 0 ? [formation] : [formation, formation.groupedWith[0]]

  workforce.morningNurseEffectif.push({ formations: formationsTab, morningNurses: 0 });
  workforce.afternoonNurseEffectif.push({ formations: formationsTab, afternoonNurses: 0 });
}

function addNurseToGroups(nurse: profileCard, workforce: Effective, middleTime: string) {
  for (let index = 0; index < workforce.morningNurseEffectif.length; index++) {
    if (workforce.morningNurseEffectif[index].formations.find(formation => formation.name == nurse.profile.position)) {
      if (getHoursAndMinutes(nurse.time.startTime) < getHoursAndMinutes(middleTime))
        workforce.morningNurseEffectif[index].morningNurses++;
      if (getHoursAndMinutes(nurse.time.endTime) > getHoursAndMinutes(middleTime))
        workforce.afternoonNurseEffectif[index].afternoonNurses++;
      return;
    }
  }
}

function isAlreadyAdded(newFormation: Formation, workforce: Effective): boolean {
  return workforce.morningNurseEffectif.find((workforce) => workforce.formations[0].groupedWith.length != 0
    && workforce.formations[1].name == newFormation.name)
    != undefined;
}

export function getHoursAndMinutes(date: string | number | Date): string {
  let d = new Date(date);
  d.setTime(d.getTime() + d.getTimezoneOffset() * 60 * 1000);

  return `${d.getHours() <= 9 ? "0" + d.getHours() : d.getHours()}:${d.getMinutes() <= 9 ? "0" + d.getMinutes() : d.getMinutes()}`;
}

/**
 * 
 * @param elem The string to calc the max size
 * @param style The style of elem (ex: Inter italic 500 11.5)
 * @param elementId The id of the element which will contain the string
 * @param errorMargin The error margin for the element width to add to the calc width 
 * @param replacement The replacement string in case of width higher than offsetWidth
 * @returns Return the replacement if one, or the maxWidthElem or the elem
*/
export function getMaxSizedString(elem: string, style: string, elementId: string, errorMargin: number = 0, replacement?: string) {
  const pageElem = document.getElementById(elementId)

  if (pageElem) {
    const elemWidth = getTextWidth(elem, style)
    return elemWidth + errorMargin > pageElem.offsetWidth ? replacement ? replacement : getMaxWidthElem(pageElem.offsetWidth, elem, style, errorMargin) : elem
  } else {
    return elem;
  }
}

function getTextWidth(text: string, font: string) {
  const canvas = document.createElement("canvas")
  const context = canvas.getContext("2d");
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
}

function getMaxWidthElem(offsetWidth: number, elem: string, style: string, errorMargin: number) {
  let copyElem = (' ' + elem).slice(1);
  let elemWidth = getTextWidth(copyElem, style)
  let maxIteration = 30
  let nbIterations = 0

  while ((elemWidth + errorMargin > offsetWidth) && (nbIterations < maxIteration)) {
    copyElem = copyElem.substring(0, copyElem.length - 2)
    copyElem = copyElem + "."
    elemWidth = getTextWidth(copyElem, style)
    nbIterations++;
  }

  if (copyElem.length < 3) {
    return elem.substring(0, 3) + "."
  }

  return copyElem
}

export function cloneWithoutPromise(data: any) { // BUG: Known bug with objects containing objects
  let clone;
  if (Object.prototype.toString.call(data) == '[object Array]') {
    clone = []
    data.forEach(data_ => {
      const clonedObject = {}
      for (const [key, value] of Object.entries(data_)) {
        if (value instanceof Object) { 
          clonedObject[key] = cloneWithoutPromise(value);
        } else if (value instanceof Promise) {
          if ((value as any).isFulfilled) {
            clonedObject[key] = value;
          } else {
            clonedObject[key] = null;
          }
        } else {
          clonedObject[key] = value;
        }
      };
      clone.push(clonedObject)
    });
  } else if (data instanceof Object) {
    clone = {}
    for (const [key, value] of Object.entries(data)) {
      if (value instanceof Object) { 
        clone[key] = cloneWithoutPromise(value);
      } else if (value instanceof Promise) {
        if ((value as any).isFulfilled) {
          clone[key] = value;
        } else {
          clone[key] = null;
        }
      } else {
        clone[key] = value;
      }
    };
  }
  return clone
}

export function getRoomHoursBySelectedDay(room: Room, date: Date) {
  const time = {startTime: new Date(), endTime: new Date()}

  const tmp = room.horaire.find((h) => h.day === (date.getDay() + 1))
  if (tmp) {
    time.startTime = new Date(tmp.start)
    time.endTime = new Date(tmp.end)
  }

  return time;
}

export function getBrightness(color) {
  // Remove the hash if it's there
  color = color.replace("#", "");

  // Convert hex to RGB
  let r = parseInt(color.substr(0, 2), 16);
  let g = parseInt(color.substr(2, 2), 16);
  let b = parseInt(color.substr(4, 2), 16);

  // Calculate brightness
  // This uses the luminance formula: (0.299 * R) + (0.587 * G) + (0.114 * B)
  return (0.299 * r + 0.587 * g + 0.114 * b);
}

export function formatDateTime(dateString: string): string {
  const date = new Date(dateString)
  const dayOfWeek = moment(date).format('dddd');
  const dayOfMonth = moment(date).format('DD');
  const month = moment(date).format('MMMM');
  const time = moment(date).format('HH:mm'); // Add time formatting

  return `${dayOfWeek.slice(0, 1).toUpperCase()}${dayOfWeek.slice(1)} ${dayOfMonth} ${month} ${time}`;
}