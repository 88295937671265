import {Injectable} from '@angular/core';
import {DoctorAgendaDal} from '../dal/doctor-agendas.dal';
import {Observable} from 'rxjs';
import {Room} from '../models/room.model';
import {Period} from '../models/period.model';
import {DoctorAgenda} from '../models/doctor-agenda.model';

@Injectable({
  providedIn: 'root'
})
export class DoctorAgendasService {

  constructor(private doctorAgendaDal: DoctorAgendaDal) {
  }

  getAllDoctorAgendas(roomId?: string, day?: string, period?: Period): Observable<Room> {
    return this.doctorAgendaDal.getAllDoctorAgendas(roomId, day, period);
  }

  addIntervention(interventionBody: DoctorAgenda): Observable<DoctorAgenda> {
    return this.doctorAgendaDal.addIntervention(interventionBody);
  }

  updateIntervention(doctorAgendaId: string, interventionBody: DoctorAgenda): Observable<DoctorAgenda> {
    return this.doctorAgendaDal.updateIntervention(doctorAgendaId, interventionBody);
  }

  deleteOneDoctorAgenda(doctorAgendaId: string): Observable<any> {
    return this.doctorAgendaDal.deleteOneDoctorAgenda(doctorAgendaId);
  }

  deleteRoomDayDoctorAgenda(roomId: string, date: string): Observable<any> {
    return this.doctorAgendaDal.deleteRoomDayDoctorAgenda(roomId, date);
  }

  checkIfPeriodIsAvailable(roomId: string, startTime: string, endTime: string): Observable<any> {
    return this.doctorAgendaDal.checkIfPeriodIsAvailable(roomId, startTime, endTime);
  }

  addManyIntervention(interventionsBody) : Observable<DoctorAgenda[]> {
    return this.doctorAgendaDal.addManyIntervention(interventionsBody);
  }

  deleteManyIntervention(interventionsIdBody) : Observable<any> {
    return this.doctorAgendaDal.deleteManyIntervention(interventionsIdBody);
  }

  updateManyIntervention(interventionsBody) : Observable<DoctorAgenda[]> {
    return this.doctorAgendaDal.updateManyIntervention(interventionsBody);
  }

  SOFromDataPipeline(date: string, roomsId: any[], cacheData?: any, deleteIfEmpty?: boolean) : Observable<any>{
    return this.doctorAgendaDal.SOFromDataPipeline(date, roomsId, cacheData, deleteIfEmpty); 
  }

  getFromDataExtraction(fromDate: string, toDate: string) {
    return this.doctorAgendaDal.getFromDataExtraction(fromDate, toDate);
  }

  getLastUpdateDateDA(date: string , hospital: string) {
    return this.doctorAgendaDal.getLastUpdateDateDA(date , hospital);
  }

}
