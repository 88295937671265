import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import { OPPOSITE_ZOOM_VALUE } from '../../const/glabals.const';
import { HospitalService } from '../../services/hospital.service';
import {geFloatNumber, RemoveAccents, toFixed} from '../../utils/cross-functions';

@Component({
  selector: 'app-surgeons-distribution-tooltip',
  templateUrl: './surgeons-distribution-tooltip.component.html',
  styleUrls: ['./surgeons-distribution-tooltip.component.scss']
})
export class SurgeonsDistributionTooltipComponent implements OnInit {
  @Input() infos: any[];
  @Input() interventionsCount: number;
  @Input() specialtyName: string;
  @Input() left: number;
  @Input() right: number;
  @Input() top: number;
  @Input() bottom: number;
  @Input() type: string;
  @Input() imgLink: string = '';
  @Input() showSpecialty: Boolean = true;
  @Input() isEffectif: Boolean = false;
  @Input() unit: string = 'heure';
  @Input() tooltipPosition: string = 'right';

  public doesHospitalHaveProgramsDataPipelineOption: boolean;
  sortedInfos;
  public percentage: string;

  public toFixed = toFixed;

  getSortedInfos(): any[]{
    return this.infos.sort(
      (itemA,itemB)=>{
        if(this.doesHospitalHaveProgramsDataPipelineOption)
            return itemB.blockHours - itemA.blockHours;
        else
            return itemB.interventions - itemA.interventions;
      });
}

  constructor(private hospitalService: HospitalService) {
    this.doesHospitalHaveProgramsDataPipelineOption = this.hospitalService.doesHospitalHaveProgramsDataPipelineOption();
  }

  ngOnInit() {
    if (this.isEffectif)
      return


    this.top = this.top * OPPOSITE_ZOOM_VALUE;
    this.right = this.right * OPPOSITE_ZOOM_VALUE;
    this.bottom = this.bottom * OPPOSITE_ZOOM_VALUE;
    if (this.tooltipPosition == "left")
      this.left = (this.left - 260) * OPPOSITE_ZOOM_VALUE;
    else
      this.left = this.left * OPPOSITE_ZOOM_VALUE;

    if(this.infos[0] && this.infos[0].interventions){
      this.infos[0].interventions = Number(toFixed(this.infos[0].interventions,1));
    }
    if(this.infos[0] && this.infos[0].nbOfIntervention){
      this.infos[0].nbOfIntervention = Math.round(this.infos[0].nbOfIntervention)
    }
    this.sortedInfos = this.getSortedInfos();
  }

  computeInterventionsPercentage(item) {
    if (this.type === 'stacked') {
      if (this.doesHospitalHaveProgramsDataPipelineOption) {
        return toFixed((item.nbOfIntervention / this.interventionsCount) * 100, 1)
      } else {
        return toFixed((item.interventions / this.interventionsCount) * 100, 1)
      }
    } else {
      if (this.doesHospitalHaveProgramsDataPipelineOption) {
        if (this.unit.toLowerCase() === 'heure') {
          return toFixed((item.blockHours / this.interventionsCount) * 100, 1)
        } else {
          return toFixed((item.interventions / this.interventionsCount) * 100, 1)
        }
      } else {
        return toFixed((item.blockHours / this.interventionsCount) * 100, 1)
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.sortedInfos = this.getSortedInfos();
  }

}
