import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Profile } from '../../models/profile.model';
import { User } from '../../models/user.model';
import { SURGEON } from '../../const/glabals.const';
import { MAT_DATE_FORMATS, MatDialog } from '@angular/material';
import * as moment from 'moment';
import { ToastService } from '../../services/toast.service';
import { CropperPopupComponent } from '../edit-personal-data/cropper-popup/cropper-popup.component';
import { SpecialtyService } from '../../services/specialty.service';
import { ErrorService } from '../../services/error.service';
import { Specialty } from '../../models/specialty.model';
import { HttpClient } from '@angular/common/http';
import { MY_FORMATS } from '../add-profile/data-format.const';
import { transformedDate } from '../../utils/cross-functions';
import { StorageService } from 'src/app/core/services/storage.service';
import { ImportProfileDialogComponent } from '../import-profile-dialog/import-profile-dialog.component';
import { UserService } from '../../services/user.service';
import { ProfileService } from '../../services/profile.service';
import { Role } from '../../models/role.model';
import { ParamedicalService } from '../../services/paramedical.service';
import { Subscription } from 'rxjs';

const FILE_SIZE_MAX = 2400000;  //24mo
const ALLOWED_EXTENSIONS = ['png', 'jpeg', 'jpg'];
const ERROR_MSG_ON_FILE_SIZE = 'La taille de l\'image dépasse la capacité de 2,5 mo autorisée';
const ERROR_MSG_ON_EXTENSION = 'Ce type d\'image n\'est pas autorisé. Veuillez choisir une image dans les formats suivants :JPG, PNG,JPEG)';

@Component({
  selector: 'app-add-surgeon',
  templateUrl: './add-surgeon.component.html',
  styleUrls: ['./add-surgeon.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class AddSurgeonComponent implements OnInit {
  @Input() type: string;
  @Input() openType: string;
  @Input() profile?: Profile;
  @Output() onProfileChange = new EventEmitter<{ profile: Profile, specialty: string }>();
  @Output() close = new EventEmitter();
  @Output() editSkills = new EventEmitter();
  isUrgenceSpecialtySelected: boolean = false;

  public formGroup: FormGroup;
  public currentUser: User;
  public imageSource;
  public realFirstName: string = '';
  public picture: string;
  public originalSpecialty: string;
  residencies = [
    {
      label: 'Vacataire',
      value: 'Vacataire'
    },
    {
      label: 'Titulaire',
      value: 'Titulaire'
    }
  ];

  _isExtensionAllowed: boolean;
  _isFileSizeAllowed: boolean;
  specialties: any[];
  isUgenceLastSelected: boolean;

  roles: any[] = [];

  public isMultiHospitals: boolean = false;
  public hospitals: any[] = []
  public isImportedProfile: boolean = false;
  public importedProfile: Profile;
  public currentNeeds: any[] = [];
  public needs: any[] = []
  public surgeonNeedsByHospital = {};
  private getRolesSubscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    public dialog: MatDialog,
    private errorService: ErrorService,
    private specialtyService: SpecialtyService,
    public profileService: ProfileService,
    private http: HttpClient,
    private storageService: StorageService,
    private userService: UserService,
    private paramedicalService: ParamedicalService
  ) {}

  async ngOnInit() {
    this.isMultiHospitals = this.userService.isCurrentUserHasMultipleHospitalsSelected()
    this.currentUser = this.storageService.getUser()
    const selectedHospitalsId = this.userService.getSelectedHospitals()
    selectedHospitalsId.forEach(hospitalId => {
      const hospital = this.currentUser.profile.hospitals.find((hospital) => String(hospital._id) == String(hospitalId))
      if (this.openType === 'ADD' || this.profile.hospitals.find((h) => String(h._id) === String(hospital._id))) {
        this.hospitals.push({
          name: hospital.name,
          _id: hospital._id
        }) 
      }
    });
    this.originalSpecialty = this.getSpecialtyId(false)
    await this.getSpecialties(this.hospitals[0]._id);
    await this.getRoles();
    await this.createForm();
  }

  getRoles() {
    return new Promise<void>((resolve, reject) => {
      if (this.getRolesSubscription) {
        this.getRolesSubscription.unsubscribe();
      }

      this.getRolesSubscription = this.paramedicalService.getRoles().subscribe((res) => {
        this.roles = res.filter((role) => role.name !== "IADE");
        resolve();
      }, (error) => {
        this.errorService.handleError(error)
        reject();
      })
    })
  }

  reverse(s: string): string {
    if (typeof s === 'string') {
      return s.split('-').reverse().join('-');
    }
  }

  async createForm() {
    const profile: Profile = (this.profile ? this.profile : {});
    let staDate: Date;
    if (this.profile) {
      const reversedDate = this.profile.startingDate;
      staDate = new Date(reversedDate);
    }
    if (profile.profilePic && profile.profilePic !== '') {
      this.picture = profile.profilePic;
      this.imageSource = this.picture;
    }
    if (!this.isMultiHospitals) {
      this.formGroup = this.formBuilder.group({
        firstName: [profile.firstName, [Validators.required, Validators.minLength(2)]],
        lastName: [profile.lastName, [Validators.required, Validators.minLength(2)]],
        phoneNumber: [profile.phoneNumber],
        residency: [profile.residency, [Validators.required]],
        specialty: [this.getSpecialtyId(true), [Validators.required]],
        surgeonNeeds: [this.isSurgeonNeeds(true)],
        defaultSpecialtySurgeon: [profile.isDefaultSpecialtySurgeon ? true : false],
        startingDate: [staDate],
      });
    } else {
      this.formGroup = this.formBuilder.group({
        firstName: [profile.firstName, [Validators.required, Validators.minLength(2)]],
        lastName: [profile.lastName, [Validators.required, Validators.minLength(2)]],
        phoneNumber: [profile.phoneNumber],
        residency: [profile.residency, [Validators.required]],
        specialty: [this.getSpecialtyId(true), [Validators.required]],
        surgeonNeeds: [this.isSurgeonNeeds(true)],
        startingDate: [staDate],
        defaultSpecialtySurgeon: [profile.isDefaultSpecialtySurgeon ? true : false],
        hospitals: [this.hospitals[0], [Validators.required]]
      });
    }
    await this.specialtyChange((this.getSpecialtyId(false)))
  }

  isSurgeonNeeds(isFirstTime: boolean): boolean {
    if (this.openType === 'ADD') {
      return false;
    }

    return this.getSurgeonNeedsOfSelectedHospital(isFirstTime).length > 0;
  }

  getSurgeonNeedsOfSelectedHospital(isFirstTime: boolean): { role: Role, need: Number }[] {
    const profile: Profile = (this.profile ? this.profile : {});

    let hospitalId;

    if (this.isMultiHospitals) {
      if (isFirstTime) {
        hospitalId = String(this.hospitals[0]._id);
      } else {
        hospitalId = this.formGroup.get('hospitals').value._id;
      }
    } else {
      hospitalId = String(this.hospitals[0]._id);
    }

    if (!profile.surgeonNeeds) {
      return [];
    }

    return profile.surgeonNeeds.filter((item) => String(item.role.hospital) === String(hospitalId) && String(item.role.name) !== "IADE");
  }

  async resetHospitals() {
    this.hospitals = []
    const selectedHospitalsId = this.userService.getSelectedHospitals()
    selectedHospitalsId.forEach(hospitalId => {
      const hospital = this.currentUser.profile.hospitals.find((hospital) => String(hospital._id) == String(hospitalId))
      this.hospitals.push({
        name: hospital.name,
        _id: hospital._id
      })
    });
    // await this.getSpecialties(this.hospitals[0]._id);
  }

  importProfile() {
    const dialogRef = this.dialog.open(ImportProfileDialogComponent, {
      width: "400px",
      height: "520px",
      data: {
        profileType: SURGEON
      },
    });
    dialogRef.afterClosed().subscribe(async (res) => {
      if (res && res.status) {
        this.resetHospitals();
        res.profile.hospitals.forEach(hospital => {
          this.hospitals = this.hospitals.filter((hospital_) => String(hospital_._id) !== String(hospital._id))
        });

        if (res.profile.surgeonNeeds &&
          res.profile.surgeonNeeds.length > 0 &&
          Object.keys(res.profile.surgeonNeeds[0]).length > 0) {
          this.needs = res.profile.surgeonNeeds.map((need) => ({
            role: need.role._id,
            need: need.need
          }))
        }

        this.formGroup.patchValue({
          firstName: res.profile.firstName,
          lastName: res.profile.lastName,
          phoneNumber: res.profile.phoneNumber,
          residency: res.profile.residency,
          surgeonNeeds: false,
          seniority: res.profile.seniority ? res.profile.seniority : '',
          type: res.profile.position,
          startingDate: res.profile.startingDate,
          hospitals: this.hospitals[0]
        })
        await this.specialtyChange((this.getSpecialtyId(false)))
        this.isImportedProfile = true;
        this.importedProfile = res.profile;
        if (!this.importedProfile.profilePic)
        {
          this.importedProfile.profilePic = 'assets/images/icons/surgeon.svg';
        }
      }
    })
  }

  getSpecialtyId(isFirstTime: boolean): string {
    if (this.openType === 'ADD') {
      return this.specialties && this.specialties[0] ? this.specialties[0]._id : '';
    } else {
      let hospitalId;
      if (isFirstTime) {
        hospitalId = String(this.hospitals[0]._id);
      } else {
        if (this.isMultiHospitals) {
          hospitalId = this.formGroup.get('hospitals').value._id;
        } else {
          hospitalId = String(this.hospitals[0]._id);
        }
      }

      const specialtyOfProfileInSelectedHospital = this.profile && this.profile.specialties && Array.isArray(this.profile.specialties) ? this.profile.specialties.find((s) => s && s.hospital && String(s.hospital) === hospitalId) : null;
      return specialtyOfProfileInSelectedHospital ? specialtyOfProfileInSelectedHospital._id : null;
    }
  }

  getSpecialtyNeeds() {
    if (this.openType == 'ADD') {
      return [];
    } else {
      if (this.profile.surgeonNeeds) {
        return this.profile.surgeonNeeds;
      } else {
        const specialty = this.getSelectedSpecialty(this.getSpecialtyId(true));
        return specialty.needs;
      }
    }
  }

  async surgeonNeedsChange(event) {
    if (this.openType === "ADD") {
      if (this.currentNeeds && this.currentNeeds.length > 0) {
        for (let index = 0; index <= this.currentNeeds.length; index++) {
          this.currentNeeds.pop() 
        }
      }

      if (this.profile && this.profile.surgeonNeeds && this.profile.surgeonNeeds.length > 0) {
        for (let index = 0; index <= this.profile.surgeonNeeds.length; index++) {
          this.profile.surgeonNeeds.pop() 
        }
      }
    }
    if (!event.checked) {
      let hospitalId;

      if (this.isMultiHospitals) {
        hospitalId = this.formGroup.get('hospitals').value._id;
      } else {
        hospitalId = String(this.hospitals[0]._id);
      }
      this.profile.surgeonNeeds = this.profile.surgeonNeeds.filter((item) => String(item.role.hospital) !== String(hospitalId) && String(item.role.name) !== "IADE")
      this.currentNeeds = this.profile.surgeonNeeds;
    } else {
      if (this.isSurgeonNeeds(false)) {
        this.currentNeeds = this.getSurgeonNeedsOfSelectedHospital(false);
      } else {
        const specialty = this.getSelectedSpecialty(this.getSpecialtyId(false));
        if (!this.profile.surgeonNeeds) {
          this.profile.surgeonNeeds = JSON.parse(JSON.stringify(specialty.needs.filter((need) => String(need.role.name) !== "IADE")))
        } else {
          this.profile.surgeonNeeds = this.profile.surgeonNeeds.concat(JSON.parse(JSON.stringify(specialty.needs.filter((need) => String(need.role.name) !== "IADE"))))
        }
        this.currentNeeds = this.profile.surgeonNeeds;

      }
    }
    await this.setMissingRoles();
  }

  async hospitalChange(event) {
    if (this.isUrgenceSpecialtySelected) {
      this.imageSource = undefined,
      this.formGroup.get('lastName').setValue('')
      this.isUrgenceSpecialtySelected = false;
    }
    await this.getSpecialties(event._id)
    this.formGroup.get("specialty").setValue(this.getSpecialtyId(false));
    // Update form.surgeoNeeds and this.surgeonNeeds
    this.formGroup.get("surgeonNeeds").setValue(this.isSurgeonNeeds(false));

    if (this.openType == 'ADD') {
      if (this.currentNeeds && this.currentNeeds.length > 0) {
        for (let index = 0; index <= this.currentNeeds.length; index++) {
          this.currentNeeds.pop() 
        }
      }

      if (this.profile && this.profile.surgeonNeeds && this.profile.surgeonNeeds.length > 0) {
        for (let index = 0; index <= this.profile.surgeonNeeds.length; index++) {
          this.profile.surgeonNeeds.pop() 
        }
      }
    }
    if (this.isSurgeonNeeds(false)) {
      this.currentNeeds = this.getSurgeonNeedsOfSelectedHospital(false);
    } else {
      const specialty = this.getSelectedSpecialty(this.getSpecialtyId(false));
      if (!this.profile.surgeonNeeds) {
        this.profile.surgeonNeeds = JSON.parse(JSON.stringify(specialty.needs.filter((need) => String(need.role.name) !== "IADE")))
      } else {
        this.profile.surgeonNeeds = this.profile.surgeonNeeds.concat(JSON.parse(JSON.stringify(specialty.needs.filter((need) => String(need.role.name) !== "IADE"))))
      }
      this.currentNeeds = this.profile.surgeonNeeds;
    }
    await this.setMissingRoles();
  }

  setMissingRoles() {
    return new Promise<void>((resolve, reject) => {
      let hospitalId;

      if (this.isMultiHospitals) {
        if (!this.formGroup.get('hospitals').value) {
          hospitalId = String(this.hospitals[0]._id);
        } else {
          hospitalId = this.formGroup.get('hospitals').value._id;
        }
      } else {
        hospitalId = String(this.hospitals[0]._id);
      }
      this.roles.forEach(role => {
        if (String(role.hospital) === String(hospitalId)) {
          if (!this.currentNeeds.find((need) => String(need.role._id) === String(role._id))) {
            this.currentNeeds.push({
              need: 0,
              role
            })
          }
        }
      });

      resolve();
    })
  }

  submitProfile() {
    if (this.formGroup.valid) {
      let hospital
      if (this.isMultiHospitals) {
        const selectedHospital = this.formGroup.get('hospitals').value
        const userHospitals = this.currentUser.profile.hospitals
        hospital = userHospitals.find((hospital) => String(hospital._id) === String(selectedHospital._id))
      } else {
        const selectedHospital = this.userService.getSelectedHospitals()
        hospital = selectedHospital[0]
      }
      const profile: any = (this.profile ? this.profile : {});
      if (this.isImportedProfile) {
        profile._id = this.importedProfile._id;
      }
      profile.lastName = this.formGroup.value.lastName;
      if (this.openType === 'ADD') {
        profile.specialties = []
        profile.specialties.push(this.formGroup.value.specialty);
      } else {
        profile.specialties = this.profile.specialties.map((s) => String(s._id));
      }
      profile.position = SURGEON;
      profile.profilePic = this.picture;
      if (this.openType === 'ADD') {
        if (this.isMultiHospitals) {
          profile.hospitals = [hospital._id];
        } else {
          profile.hospitals = [hospital];
        }
      } else {
        profile.hospitals = this.profile.hospitals.map((h) => h._id);
      }
      if (!this.isUrgenceSpecialtySelected) {
        profile.residency = this.formGroup.value.residency;
        profile.phoneNumber = this.formGroup.value.phoneNumber;
        profile.firstName = (this.formGroup.value.firstName) ? this.formGroup.value.firstName : 'Dr';
        profile.startingDate = transformedDate(this.formGroup.value.startingDate);
        if (this.formGroup.get("surgeonNeeds").value)
          profile.surgeonNeeds = this.profile.surgeonNeeds.filter((need) => {
            if (Number(need.need) > 0) {
              return {
                role: need.role._id,
                need: need.need
              }
            }
          });
        else
          profile.surgeonNeeds = [];
        profile.surgeonNeeds = profile.surgeonNeeds.length === 0 ? null: profile.surgeonNeeds
      } else {
        profile.firstName = '';
        profile.startingDate = moment().toDate();
      }
      
      profile.isDefaultSpecialtySurgeon = this.formGroup.value.defaultSpecialtySurgeon;
      this.onProfileChange.emit({ profile, specialty: this.originalSpecialty });
    }
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      this._isExtensionAllowed = this.isExtensionFileAllowed(file);
      this._isFileSizeAllowed = this.isFileSizeAllowed(file);
      if (this._isFileSizeAllowed && this._isExtensionAllowed) {
        this.openDialog(event);
      } else {
        this._isExtensionAllowed ?
          this.toastService.errorToast(ERROR_MSG_ON_FILE_SIZE) : this.toastService.errorToast(ERROR_MSG_ON_EXTENSION);
      }
    }
  }

  openDialog(event): void {
    const dialogRef = this.dialog.open(CropperPopupComponent, {
      data: {
        imageChangedEvent: event
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.picture = result.base64;
        this.imageSource = result.base64;
      }
    });
  }

  isFileSizeAllowed(file): boolean {
    return file.size < FILE_SIZE_MAX;
  }

  isExtensionFileAllowed(file): boolean {
    const extension = file.name.split('.')[1].toLowerCase();
    return ALLOWED_EXTENSIONS.includes(extension);
  }

  closeDialog(): void {
    this.close.emit();
  }

  getSpecialties(hospitalId: string) {
    return new Promise<void>((resolve, reject) => {
      this.specialtyService.getAllSpecialtiesFromHospital(true, hospitalId).subscribe((results) => {
        this.specialties = results.filter((value) => {
          return value.name != 'Extraclinique' && value.name != 'Anesthésie-réanimation'
        });
        this.sortSpecialties();
        resolve();
      }, error => {
        this.errorService.handleError(error);
        reject();
      });
    })
  }

  sortSpecialties(): void {
    this.specialties = this.specialties.sort((a, b) => a.priority - b.priority);
  }

  getSelectedSpecialty(specialtyId): Specialty {
    for (const specialty of this.specialties) {
      if (specialtyId === specialty._id) {
        return specialty;
      }
    }
    return undefined;
  }

  toggleNonUrgenceFields(hide: boolean): void {
    if (!this.isImportedProfile) {
      this.isUrgenceSpecialtySelected = hide;
      if (hide) {
        this.isUgenceLastSelected = true;
        this.formGroup.patchValue({ firstName: '  ', lastName: 'Urgences', mail: 'urgence@smartop.io' });
        this.formGroup.controls.phoneNumber.disable();
        this.formGroup.controls.firstName.disable();
        // this.formGroup.controls.mail.disable();
        this.formGroup.controls.residency.disable();
      } else {
        if (this.isUgenceLastSelected) {
          this.formGroup.patchValue({ firstName: '', lastName: '', mail: '' });
          this.unsetImage();
          this.isUgenceLastSelected = false;
        }
        this.formGroup.controls.phoneNumber.enable();
        this.formGroup.controls.firstName.enable();
        // this.formGroup.controls.mail.enable();
        this.formGroup.controls.residency.enable();
      }
    }
  }

  unsetImage(): void {
    this.imageSource = '';
    this.picture = null;
  }

  setUrgenceImage(): void {
    this.http.get('assets/icons/specialties/urgences.png', { responseType: 'blob' })
      .subscribe(res => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data: string = reader.result.toString();
          this.imageSource = base64data;
          this.picture = base64data;
        };
        reader.readAsDataURL(res);
      });
  }

  async specialtyChange(sp: string): Promise<void> {
    const selectedSpecialty: Specialty = this.getSelectedSpecialty(sp);
    if (selectedSpecialty) {
      if (selectedSpecialty.name === 'Urgences') {
        this.toggleNonUrgenceFields(true);
        this.setUrgenceImage();
      } else {
        this.toggleNonUrgenceFields(false);
        if (this.openType == 'ADD') {
          if (this.currentNeeds && this.currentNeeds.length > 0) {
            for (let index = 0; index <= this.currentNeeds.length; index++) {
              this.currentNeeds.pop() 
            }
          }
    
          if (this.profile && this.profile.surgeonNeeds && this.profile.surgeonNeeds.length > 0) {
            for (let index = 0; index <= this.profile.surgeonNeeds.length; index++) {
              this.profile.surgeonNeeds.pop() 
            }
          }
        }
        if (this.isSurgeonNeeds(false)) {
          this.currentNeeds = this.getSurgeonNeedsOfSelectedHospital(false);
        } else {
          if (!this.profile.surgeonNeeds) {
            this.profile.surgeonNeeds = selectedSpecialty.needs.filter((need) => String(need.role.name) !== "IADE")
          } else {
            this.profile.surgeonNeeds = this.profile.surgeonNeeds.concat(selectedSpecialty.needs.filter((need) => String(need.role.name) !== "IADE"))
          }
          this.currentNeeds = this.profile.surgeonNeeds;
        }
        if (this.openType === 'EDIT') {
          this.profile.specialties = this.profile.specialties.filter((s) => String(s.hospital) !== String(selectedSpecialty.hospital));
          this.profile.specialties.push(selectedSpecialty);
        }
      }
    }
    await this.setMissingRoles()
  }
}
