import { Component, OnDestroy, Inject, ChangeDetectorRef } from "@angular/core";
import { MatCalendar, MAT_DATE_FORMATS, MatDateFormats, DateAdapter } from "@angular/material";
import * as moment from "moment";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DatePickerShowWeekService } from "../date-picker-show-week.service";
import { DatePickerMondayFirst } from "src/app/shared/utils/date-picker-monday-first";

@Component({
    selector: 'show-week-count-header',
    templateUrl: './show-week-count-header.component.html',
    styleUrls: ['./show-week-count-header.component.scss'],
    providers: [{ provide: DateAdapter, useClass: DatePickerMondayFirst }]
})
export class ShowWeekCountHeaderComponent<D> implements OnDestroy {
    private _destroyed = new Subject<void>();

    constructor(
      private _calendar: MatCalendar<D>,
      private _dateAdapter: DateAdapter<D>,
      @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
      private datePickerShowWeekService: DatePickerShowWeekService,
      cdr: ChangeDetectorRef,
    ) {
      _calendar.stateChanges.pipe(takeUntil(this._destroyed)).subscribe(() => cdr.markForCheck());
    }

    serviceSubscription: Subscription;

    ngOnInit() {
        this.serviceSubscription = this.datePickerShowWeekService.calendarSubject.subscribe((selectedDate) => {
            this._calendar.activeDate = moment(selectedDate).startOf('month').toDate() as unknown as D
            this.appendWeekNumbers()
        })
    }
  
    ngOnDestroy() {
      this._destroyed.next();
      this._destroyed.complete();
      this.serviceSubscription && this.serviceSubscription.unsubscribe();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.appendWeekNumbers();
        }, 0);

    }
  
    get disabled() {
        return this._calendar.currentView !== 'month';
    }

    get periodLabel() {
        let label: string = '';

        switch (this._calendar.currentView) {
            case 'year':
                label = moment(this._calendar.activeDate).format('YYYY').toLocaleUpperCase();
                return label;
                break;
            case 'month':
                label = this._dateAdapter
                    .format(this._calendar.activeDate, this._dateFormats.display.monthYearLabel)
                    .toLocaleUpperCase();
                return label;
                break;
        }
        return label;
    }
  
    previousClicked(mode: 'month' | 'year') {
        this._calendar.activeDate =
            mode === 'month'
            ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1)
            : this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);

        if (this._calendar.currentView === 'month') {
            setTimeout(() => {
                this.appendWeekNumbers();
            }, 0);
        }
    }
  
    nextClicked(mode: 'month' | 'year') {
        this._calendar.activeDate =
            mode === 'month'
            ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1)
            : this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);

        if (this._calendar.currentView === 'month') {
            setTimeout(() => {
                this.appendWeekNumbers();
            }, 0);
        }
    }

    clickLabel() {
        this.removeWeekNoContainer()
        switch (this._calendar.currentView) {
            case 'year':
                this._calendar._goToDateInView(this._calendar.activeDate, 'multi-year');
                break;
            case 'month':
                this._calendar._goToDateInView(this._calendar.activeDate, 'year');
                break;
            case 'multi-year':
                this._calendar._goToDateInView(this._calendar.activeDate, 'month');
                setTimeout(() => {
                    this.appendWeekNumbers();
                }, 0);
                break;
        }
        
    }    

    private appendWeekNumbers() {
        const firstDay = moment(this._calendar.activeDate).startOf('month').format('YYYY-MM-DD')
        const endDay = moment(this._calendar.activeDate).endOf('month').format('YYYY-MM-DD')
        const monthRange = (firstDate, lastDate) => {
            let date = firstDate;
            const dates = [moment(date).format('YYYY-MM-DD')];
            while (moment(date).add(1, 'day').isSameOrBefore(lastDate)) {
                date = moment(date).add(1, 'day');
                dates.push(date.format('YYYY-MM-DD'));
            }

            return dates;
        }
        const weeks = [];
        const days = Array.from(monthRange(firstDay, endDay));
        days.forEach(day => {
        
        // ISO week
            if (!weeks.includes(moment(day).isoWeek())) {
                weeks.push(moment(day).isoWeek());
            }
        })

        setTimeout(() => {
            this.drawWeekContainer(weeks);
        }, 0)

    }

    private drawWeekContainer(weeks) {
        const container = document.getElementsByTagName('mat-datepicker-content')[0] as HTMLDivElement;
        container.style.display = "flex";
        const calendarTr = document.querySelector('.mat-calendar-body tr');
        
        this.removeWeekNoContainer()

        const weeksNumberEl = document.createElement('div');
        weeksNumberEl.style.width = "7%";
        weeksNumberEl.id = "weekNumberContent";
        weeksNumberEl.style.borderRight = "solid 1px #eeeeee";
        weeksNumberEl.style.color = "rgba(0, 0, 0, 0.87)";
        weeksNumberEl.style.backgroundColor = "#eeeeee";
        
        const columnTitle = document.createElement("p");
        columnTitle.style.textAlign = "center";
        columnTitle.style.fontWeight = "700";
        columnTitle.style.height = "20px";
        columnTitle.innerText = "n°";

        const numbersTableEl = document.createElement('table');
        numbersTableEl.id = "weekNumberTable";
        numbersTableEl.style.tableLayout = "fixed";
        numbersTableEl.style.fontSize = "12px";
        weeksNumberEl.style.paddingTop = "10.5%";

        if (calendarTr.children.length === 1) {
            numbersTableEl.style.transform = "translateY(19.8%)";
        }

        weeksNumberEl.append(columnTitle);
        weeksNumberEl.appendChild(numbersTableEl);
        container.prepend(weeksNumberEl);

        for (let i = 0; i < weeks.length; i++) {
            // const widthAndheight = document.defaultView.getComputedStyle(calendarTr);
            const trEl = document.createElement('tr');
            trEl.style.textAlign = "center";
            trEl.style.fontWeight = "700";
            trEl.style.height = "38.2px";
            const tdEl = document.createElement('td');
            tdEl.style.width = "1vw";
            tdEl.innerText = weeks[i];
            trEl.appendChild(tdEl);
            numbersTableEl.appendChild(trEl);
        }
    }

    private removeWeekNoContainer() {
        const container = document.getElementsByTagName('mat-datepicker-content')[0] as HTMLDivElement;

        if (document.getElementById("weekNumberContent")) {
            container.removeChild(document.getElementById("weekNumberContent"))
        }
    }
}