export function removeAccents(str: any): string {
    const accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
    const accentsOut = 'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz';
    str = str.split('');
    const strLen = str.length;
    let i;
    let x;
    for (i = 0; i < strLen; i++) {
        x = accents.indexOf(str[i]);
        if (x !== -1) {
            str[i] = accentsOut[x];
        }
    }
    return str.join('');
}

export function getPercenteValue(value: number): string {
    return (value * 100).toFixed(1).split('.').join(',');
}

export function reversedPercent(value: string): number {
    if (value) {
        if (!value.includes(',')) {
            return parseInt(value, null);
        }
        return parseFloat(value.split(',').join('.'));
    } else {
        return 0;
    }
}

export function removeHTMLTags(text: string): string {
    if (!text) {
        return '';
    }
    text = text.replace(/<\/?[^>]+(>|$)/g, '');
    if (text.length > 30) {
        text = text.slice(0, 31);
        return text + '...';
    }
    return text;
}

export function isStringHasLowwerLetter(txt: string): boolean {
    return /[a-z]/.test(txt);
}

export function isStringHasUpperLetter(txt: string): boolean {
    return /[A-Z]/.test(txt);
}

export function isStringHasNumber(txt: string): boolean {
    return /\d/.test(txt);
}

export function isStringHasSpecialLetter(txt: string): boolean {
    return /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(txt);
}
