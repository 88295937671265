import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';

import {urlEndPoints} from '../config/end-points';

@Injectable()
export class IbodeGraphsDal {

  constructor(private httpClient: HttpClient) {}



  // First Row
  getPositionsPie(isIadeResp: boolean): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.ibodeGraphs + 'positionsPie' + "?isIadeResp=" + isIadeResp );
  }

  // NOT USED
  // getLastTwo(): Observable<any> {
  //   return this.httpClient.get<any>(urlEndPoints.ibodeGraphs + 'lastTwo' );
  // }

  getLastThree(isIadeResp: boolean): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.ibodeGraphs + 'lastThree?isIadeResp=' + isIadeResp);
  }

  getTwoWeeksTeam(startDate: string, endDate: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.calendars + 'effectifByDay?fromDate=' + startDate + '&toDate=' + endDate );
  }

  /////////////////////////////////
  // Second Row

  // getAvgAideOpLevelPerSpecialty(specilatyId: string,startDay: string, endDay:string, residency: string): Observable<any> {
  //   return this.httpClient.get<any>(urlEndPoints.ibodeGraphs + 'avgAideOpLevelPerSpecialty' + '?specialtyId=' + specilatyId + '&toDate=' + endDay + '&fromDate=' + startDay + `&residency=${residency}`);
  // }

  // getAvgCirculanteLevelPerSpecialty(specilatyId: string ,startDay: string, endDay:string, residency: string): Observable<any> {
  //   return this.httpClient.get<any>(urlEndPoints.ibodeGraphs + 'avgCirculanteLevelPerSpecialty' + '?specialtyId=' + specilatyId + '&toDate=' + endDay + '&fromDate=' + startDay + `&residency=${residency}`);
  // }

  getAvgLevelPerSpecialtyPerRole(isIadeResp: boolean, startDay: string, endDay:string, residency: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.ibodeGraphs + 'avgLevelPerSpecialtyPerRole' + '?isIadeResp=' + isIadeResp + '&toDate=' + endDay + '&fromDate=' + startDay + `&residency=${residency}`);
  }
  /////////////////////////////////
  // Third Row
  // getWaterFallAOData(startDay: string, endDay:string, residency: string): Observable<any> {
  //   return this.httpClient.get<any>(urlEndPoints.ibodeGraphs + 'waterfallAideOp' + '?toDate=' + endDay + '&fromDate=' + startDay + `&residency=${residency}`);
  // }

  // getWaterFallCircData(startDay: string, endDay:string, residency: string): Observable<any> {
  //   return this.httpClient.get<any>(urlEndPoints.ibodeGraphs + 'waterfallCirculante' + '?toDate=' + endDay + '&fromDate=' + startDay + `&residency=${residency}`);
  // }

  getWaterFallPerRoleData(isIadeResp: boolean, startDay: string, endDay:string, residency: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.ibodeGraphs + 'waterfallPerRole' + '?isIadeResp=' + isIadeResp + '&toDate=' + endDay + '&fromDate=' + startDay + `&residency=${residency}`);
  }
  /////////////////////////////////
  // Forth Row
  // getBubbleAOData(specialtyId: string, residency: string): Observable<any> {
  //   return this.httpClient.get<any>(urlEndPoints.ibodeGraphs + 'effectifBubblesAideOp' + '?specialtyId=' + specialtyId + `&residency=${residency}` );
  // }

  // getBubbleCircData(specialtyId: string, residency: string): Observable<any> {
  //   return this.httpClient.get<any>(urlEndPoints.ibodeGraphs + 'effectifBubblesCirculante' + '?specialtyId=' + specialtyId + `&residency=${residency}`);
  // }

  getBubbleDataPerRole(isIadeResp: boolean, residency: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.ibodeGraphs + 'effectifBubblesPerRole?isIadeResp=' + isIadeResp + `&residency=${residency}`);
  }
  /////////////////////////////////
  // Fifth Row
  // getFinaleGeneralStatsAO(specialtyId: string, residency: string): Observable<any> {
  //   return this.httpClient.get<any>(urlEndPoints.ibodeGraphs + 'effectifPerAvgSpecialtyAideOp?specialtyId=' + specialtyId + `&residency=${residency}`);
  // }

  // getFinaleGeneralStatsCirc(specialtyId: string, residency: string): Observable<any> {
  //   return this.httpClient.get<any>(urlEndPoints.ibodeGraphs + 'effectifPerAvgSpecialtyCir?specialtyId=' + specialtyId + `&residency=${residency}`);
  // }

  getFinaleGeneralStats(isIadeResp: boolean, residency: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.ibodeGraphs + 'effectifPerAvgSpecialty?isIadeResp=' + isIadeResp + `&residency=${residency}`);
  }

  /////////////////////////
  ////// One Onf //////////
  /////////////////////////

  // First Row

  // getOneInfBarAOChart(nurseId: string): Observable<any> {
  //   return this.httpClient.get<any>(urlEndPoints.ibodeGraphs + 'barchartSpecialityLevelOp?nurseId=' + nurseId);
  // }

  // getOneInfBarCircChart(nurseId: string): Observable<any> {
  //   return this.httpClient.get<any>(urlEndPoints.ibodeGraphs + 'barchartSpecialityLevelCir?nurseId=' + nurseId);
  // }

  getOneInfBarChart(nurseId: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.ibodeGraphs + 'barchartSpecialityLevel?nurseId=' + nurseId);
  }

  // Second Row
  // getNurseLevelEvolutionAO(nurseId: string, specialtyId: string, startDay: string, EndDay: string): Observable<any> {
  //   return this.httpClient.get<any>(urlEndPoints.ibodeGraphs + 'nurseLevelEvolutionAideOp?nurseId=' + nurseId + '&specialtyId=' + specialtyId + '&toDate=' + EndDay + '&fromDate=' + startDay);
  // }
  // getNurseLevelEvolutionCirc(nurseId: string, specialtyId: string, startDay: string, EndDay: string): Observable<any> {
  //   return this.httpClient.get<any>(urlEndPoints.ibodeGraphs + 'nurseLevelEvolutionCir?nurseId=' + nurseId + '&specialtyId=' + specialtyId + '&toDate=' + EndDay + '&fromDate=' + startDay);
  // }

  getNurseLevelEvolution(nurseId: string, startDay: string, EndDay: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.ibodeGraphs + 'nurseLevelEvolution?nurseId=' + nurseId + '&toDate=' + EndDay + '&fromDate=' + startDay);
  }

  getNurseEvolution(nurseId: string, startDay: string, EndDay: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.ibodeGraphs + 'nurseEvolution?nurseId=' + nurseId + '&toDate=' + EndDay + '&fromDate=' + startDay);
  }

  // Third Row

  getSpecialtyIntervention(nurseId: string, startDay: string, EndDay: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.ibodeGraphs + 'nurseHeader?nurseId=' + nurseId + '&toDate=' + EndDay + '&fromDate=' + startDay);
  }

  getNurseRankings(nurseId: string, startDay: string, EndDay: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.ibodeGraphs + 'nurseRankings?nurseId=' + nurseId + '&toDate=' + EndDay + '&fromDate=' + startDay);
  }
  // getDoughnutAOData(nurseId: string, startDay: string, EndDay: string): Observable<any> {
  //   return this.httpClient.get<any>(urlEndPoints.ibodeGraphs + 'piechartSpecialityInterventionAide?nurseId=' + nurseId + '&toDate=' + EndDay + '&fromDate=' + startDay);
  // }
  // getDoughnutCircData(nurseId: string, startDay: string, EndDay: string): Observable<any> {
  //   return this.httpClient.get<any>(urlEndPoints.ibodeGraphs + 'piechartSpecialityInterventionCir?nurseId=' + nurseId + '&toDate=' + EndDay + '&fromDate=' + startDay);
  // }

  getDoughnutData(nurseId: string, startDay: string, EndDay: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.ibodeGraphs + 'piechartSpecialityIntervention?nurseId=' + nurseId + '&toDate=' + EndDay + '&fromDate=' + startDay);
  }

  // Specialties Progress

  getSpecialtiesProgress(nurseId: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.ibodeGraphs + 'leftTimeProgression?nurseId=' + nurseId);
  }
  getOneSpecialtyProgress(nurseId: string, specialtyId: string): Observable<any> {
    return this.httpClient
      .get<any>(urlEndPoints.ibodeGraphs
        + 'leftTimeProgressionBySpecialty?nurseId=' + nurseId + '&specialtyId=' + specialtyId);
  }


}
