import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';

import {urlEndPoints} from '../config/end-points';
import {Room} from '../models/room.model';
import { anesthsRanks, nursesRanking } from '../interfaces/pythonAPI.interfaces';
@Injectable()
export class PythonAPIDal {

  constructor(private httpClient: HttpClient) {
  }

  autoFillingByInterval(startDate: string, endDate: string, roomsToKeep: any[], profilesToKeep: any[], roomsConfiguration: {}, autofillingType?: string): Observable<any[]> {
    return this.httpClient.post<Room[]>(`${urlEndPoints.pythonAPI}autoFillingByInterval?startDate=${startDate}&endDate=${endDate}`, { roomsToKeep, profilesToKeep, roomsConfiguration, autofillingType });
  }

  autoFillingByIntervalAnesth(startDate: string, endDate: string, seniority: any, autofillingTypes:any, roomsToKeep: any[], profilesToKeep: any[], isSplitTeams: boolean): Observable<any[]> {
    return this.httpClient.post<Room[]>(`${urlEndPoints.pythonAPI}autoFillingByIntervalAnesth?startDate=${startDate}&endDate=${endDate}`, { roomsToKeep, seniority, profilesToKeep, isSplitTeams, autofillingTypes });
  }

  getNursesRanking(date: string): Observable<nursesRanking[]> {
    return this.httpClient.get<nursesRanking[]>(`${urlEndPoints.pythonAPI}ranks?date=${date}`);
  }

  getAnesthsRanking(date: string): Observable<anesthsRanks> {
    return this.httpClient.get<anesthsRanks>(`${urlEndPoints.pythonAPI}ranksAnesth?date=${date}`);
  }

  getRoomsOverflowData(date: string, displayReal: boolean = false): Observable<any[]> {
    return this.httpClient.get<any[]>(`${urlEndPoints.pythonAPI}roomsOverflow?date=${date}&displayReal=${displayReal}`);
  }

  getInterventionOverflowData(doctorAgendasId: string): Observable<any[]> {
    return this.httpClient.get<any[]>(urlEndPoints.pythonAPI+ 'interventionOverflow?&doctorAgendasId=' + doctorAgendasId);
  }

  getRoomsOverflowOfUnsavedData(date: string, roomsWithDoctorAgendas: any): Observable<any[]> {
    return this.httpClient.post<any[]>(urlEndPoints.pythonAPI + 'roomsOverflowOfUnsavedData?date=' + date, roomsWithDoctorAgendas);
  }

  getSurgeonOpeningsOfSurgeonByInterval(surgeonId: string, startDate: string, endDate: string): Observable<any[]> {
    return this.httpClient.get<any>(`${urlEndPoints.pythonAPI}bySurgeonAndInterval/${surgeonId}?startDate=${startDate}&endDate=${endDate}`);
  }
}
