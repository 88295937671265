import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';

import {urlEndPoints} from '../config/end-points';
import {Hospital} from '../models/hospital.model';

@Injectable()
export class HospitalDal {

  constructor(private httpClient: HttpClient) {
  }

  editIsBlockedOneHospital(hospitalId: String, isBlocked: boolean): Observable<Hospital> {
    return this.httpClient.put<any>(urlEndPoints.hospitals + 'editIsBlockedOneHospital/' + hospitalId , { isBlocked });
  }

  getHospital(hospitalId?: string): Observable<Hospital> {
    return this.httpClient.get<Hospital>(urlEndPoints.hospitals + (hospitalId ? hospitalId : ""));
  }

  getAllHospitals(): Observable<Hospital[]> {
    return this.httpClient.get<Hospital[]>(urlEndPoints.hospitals);
  }

  addHospital(hospital: Hospital): Observable<Hospital> {
    return this.httpClient.post<Hospital>(urlEndPoints.hospitals, hospital);
  }

  modifyHospital(hospital: Hospital): Observable<Hospital> {
    return this.httpClient.put<Hospital>(urlEndPoints.hospitals + hospital._id, hospital);
  }

  archiveHospital(idHospital): Observable<Hospital> {
    return this.httpClient.delete<Hospital>(urlEndPoints.hospitals + idHospital);
  }

  updateNursesAutofillingSettings(args: { hospitalId: string, nursesAutofillingSettings: any }): Observable<Hospital> {
    return this.httpClient.put<Hospital>(`${urlEndPoints.hospitals}updateNursesAutofillingSettings/${args.hospitalId}`, args.nursesAutofillingSettings);
  }

  updateAnesthsAutofillingSettings(args: { hospitalId: string, anesthsAutofillingSettings: any }): Observable<Hospital> {
    return this.httpClient.put<Hospital>(`${urlEndPoints.hospitals}updateAnesthsAutofillingSettings/${args.hospitalId}`, args.anesthsAutofillingSettings);
  }

  checkCompatibility(): Observable<any> {
    return this.httpClient.get<any>(`${urlEndPoints.hospitals}checkCompatibility`);
  }


  editCycleHospital(hospitalId: String, data: Object): Observable<any> {
    return this.httpClient.put<any>(urlEndPoints.hospitals + 'editCycleHospital/' + hospitalId ,  {data});

    //return this.httpClient.put<any>(`${urlEndPoints.hospitals}editCycleHospital/${hospitalId}/${cycle}`);
  }
  
}
