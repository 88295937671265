import { Hospital } from './hospital.model';
import { Specialty } from './specialty.model';
import { ANESTHETIST, ANESTHETIST_TYPES, NURSE_TYPES, VACATAIRE } from '../const/glabals.const';
import { Calendar } from './calendar.model';
import { TITULAIRE } from './../const/glabals.const';
import { User } from './user.model';
import { Role } from './role.model';

export interface Profile {
  status?: string;
  seniority?: string;
  _id?: string;
  position?: string;
  residency?: string;
  phoneNumber?: string;
  firstName?: string;
  lastName?: string;
  haveUser?: boolean;
  startingDate?: any;
  hospitals?: Hospital[];
  specialties?: Specialty[];
  surgeonNeeds?: {
    role: Role
    need: Number
  }[],
  profilePic?: string;
  isDefaultSpecialtySurgeon?: boolean;

  deletedAt?: string[],
  desactivatedAt?: string[],

  isGarde?: boolean;
  transitoryMeasures?: boolean;
  checked?: boolean;
  calendar?: Calendar,
  commentProfile?: {
    hospital: Hospital;
    content: string;
    updatedBy: Profile;
    updatedAt: Date;
  }[];
  mail?: string;
}

export function isAnesthetist(profile: Profile): boolean {
  return ANESTHETIST_TYPES.includes(profile.position);
}

export function isAnesthetistResp(user: User): boolean {
  return user && user.profile.position===ANESTHETIST && user.levelOfAccess >=3;
}


export function isNurse(profile: Profile): boolean {
  return NURSE_TYPES.includes(profile.position);
}

export function isVacataire(profile: Profile): boolean {
  return profile.residency == VACATAIRE
}