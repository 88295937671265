import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as isString from 'lodash/isString';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

import { urlEndPoints } from '../../shared/config/end-points';
import { User } from '../../shared/models/user.model';
import { Token } from '../models/token.model';
import { StorageService } from './storage.service';

@Injectable()
export class AuthenticationService {

  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService
  ) {}

  isAuthenticated(): boolean {
    const token = this.storageService.getToken();
    return isString(this.storageService.getAccessToken()) && token && (new Date(token.expiredAt) > new Date());
  }

  logIn(user: User): Observable<any> {
    return this.httpClient.post(urlEndPoints.login, user)
      .pipe(map((result: any) => {
        const token: Token = {
          access_token: result.token,
          expiredAt: result.expiredAt,
        };
        this.storageService.storeToken(token);
        let user: User = result.doc as User;
        user.profile = result.doc.profile;
        this.storageService.saveData("selectedHospitalsId", [user.profile.hospitals[0]._id])
        // for (const hospital of user.profile.hospitals) {
        //   if (!this.storageService.getUser().profile.desactivatedAt.includes(String(hospital._id))) {
        //     this.storageService.saveData("selectedHospitalsId", [hospital._id]);
        //     break;
        //   }
        // }

        this.storageService.storeUser(result.doc as User);
        this.storageService.saveData("reverseOrderName", result.reverseOrderName);
      }));
  }

  logout(): void {
    this.storageService.clearObjectFromLocalStorage("isDisconnecting")
    this.storageService.clearUser();
    this.storageService.clearToken();
    this.storageService.clearDate();
    this.storageService.clearSelectedHospitals();
  }

  requestReset(body): Observable<any> {
    return Observable.create(observer => {
      observer.next(body);
      observer.complete();
    });
  }

  ValidPasswordToken(body): Observable<any> {
    return this.httpClient.post(`${urlEndPoints.validPasswordToken}`, body);
  }

  resetPassword(body: any): Observable<any> {
    return this.httpClient.post(urlEndPoints.sendEmail, body)
  }

  changePassword(body: any): Observable<any> {
    return this.httpClient.post(urlEndPoints.changePassword, body)
  }

}
