import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss']
})
export class ToggleSwitchComponent implements OnInit {
  // label: The text to show;
  // icon: The mat-icon to show;
  // value: The value that will be send when the option is clicked
  @Input() options : { label: string, icon?: string, value: any }[] = [];
  @Input() actifOption : any;
  @Input() label : string;
  @Input() equalColumnsWidth: boolean = false;
  @Input() isDayNight: boolean = false;
  @Input() addVacationMode: boolean = false;

  @Output() onOptionClick = new EventEmitter<string>();

  constructor(){}

  ngOnInit(): void {
  }

  optionClickHandler(option : string){
      this.onOptionClick.emit(option);
  }

  get isOff(): boolean {
    if (this.actifOption === this.options[0].value) {
      return true;
    }
    return false;
  }

  get isOn(): boolean {
    if (this.actifOption === this.options[1].value) {
      return true;
    }
    return false;
  }
}
