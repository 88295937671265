import { Injectable } from "@angular/core";
import { LevelUpRequestDAL } from "../dal/levelUpRequest.dal";
import { LevelUpRequest, LevelUpRequestsPaginated } from "../models/levelUpRequest.model";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class LevelUpRequestService {
    constructor(private levelUpRequestDAL: LevelUpRequestDAL) {

    }

    getLevelUpRequests(page?: number, limit?: number): Observable<LevelUpRequest[] | LevelUpRequestsPaginated> {
        return this.levelUpRequestDAL.getLevelUpRequests(page, limit);
    }
    
    validateOrRejectLevelUpRequest(levelUpRequestId: String, action: string): Observable<void> {
        return this.levelUpRequestDAL.validateOrRejectLevelUpRequest(levelUpRequestId, action);
    }
}