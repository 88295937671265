
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ShareCalendarRequestService {

  private message = new BehaviorSubject(null);
  sharedMessage = this.message.asObservable();

  constructor() { }

  nextMessage(calendarRequest) {
    this.message.next(calendarRequest)
  }

}


