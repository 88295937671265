import { Injectable } from '@angular/core';
import { ReasonMapDal } from '../dal/reasonMap.dal';
import { Observable } from 'rxjs';
import { ReasonMap } from '../models/reasonMap.model';

@Injectable()

export class ReasonMapService {

  constructor(private reasonMapDal: ReasonMapDal) {}

  getSuggestedReasonsMaps(includeNotSuggested = false) : Observable<ReasonMap[]> {
    return this.reasonMapDal.getSuggestedReasonsMaps(includeNotSuggested);
  }
}