import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-read-in-popup',
  templateUrl: './read-in-popup.component.html',
  styleUrls: ['./read-in-popup.component.scss']
})
export class ReadInPopupComponent implements OnInit {

  public title: string;
  public content: string;
  public header: string;

  constructor(
    public dialogRef: MatDialogRef<ReadInPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
    this.header = data.header;
    this.title = data.title;
    this.content = data.content.split("\n");
  }

  close(): void {
    this.dialogRef.close();
  }
  
  ngOnInit() {
  }

}
