import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Profile } from '../../models/profile.model';
import { StorageService } from 'src/app/core/services/storage.service';
import { getSurgeonSpecialtiesNameBySelectedHospitals } from '../../utils/cross-functions';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-specialties-hover',
  templateUrl: './specialties-hover.component.html',
  styleUrls: ['./specialties-hover.component.scss']
})
export class SpecialtiesHoverComponent implements OnChanges {
  @Input() profile: Profile
  @Input() isProfile: boolean = false;
  @Input() tinyMode: boolean = false;
  @Input() isStatopView: boolean = false;

  public isMultiHospital: boolean = false;
  public isMultiSpecialties: boolean = false;
  public isHovered: boolean = false;
  public specialtiesName: string[] = [];
  public fullSpecialtiesName: string = ""
  public isTooLong: boolean = false

  constructor(
    private storageService: StorageService,
    private userService: UserService
  ){}

  ngOnChanges() {
    if (this.profile) {
      const selectedHospitalsId = this.userService.getSelectedHospitals();
      this.isMultiHospital = selectedHospitalsId.length >= 2;
      this.specialtiesName = [];
      this.fullSpecialtiesName = ""
      this.specialtiesName = getSurgeonSpecialtiesNameBySelectedHospitals(this.profile, selectedHospitalsId)
      this.specialtiesName.forEach(name => {
        if (this.fullSpecialtiesName.length > 0) {
          this.fullSpecialtiesName += ", "
        }
        this.fullSpecialtiesName += name
      });
      if (!this.isProfile && !this.tinyMode) {
        this.isTooLong = this.fullSpecialtiesName.length > 23
      } else if (this.tinyMode) {
        this.isTooLong = this.fullSpecialtiesName.length > 28
      } else {
        this.isTooLong = this.fullSpecialtiesName.length > 18
      }
      this.isMultiSpecialties = this.specialtiesName.length >= 2
    }
  }

  onMouseOver() {
    this.isHovered = true;
    setTimeout(() => {
      this.isHovered = false;
    }, 3000);
  }

  onMouseOut() {
    this.isHovered = false;
  }
}
