import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';

import {Patient} from '../models/patient.model';
import {PatientDal} from '../dal/patient.dal';

@Injectable()
export class PatientService {

  constructor(private patientDal: PatientDal) {
  }

  getAllPatients(): Observable<Patient[]> {
    return this.patientDal.getAllPatients();
  }

  addPatient(patient: Patient): Observable<Patient> {
    return this.patientDal.addPatient(patient);
  }

  modifyPatient(patient: Patient): Observable<Patient> {
    return this.patientDal.modifyPatient(patient);
  }

  archivePatient(idPatient): Observable<Patient> {
    return this.patientDal.archivePatient(idPatient);
  }

}
