import {Injectable} from '@angular/core';
import {SkillDal} from '../dal/skill.dal';
import {RoleLevel, Skill} from '../models/skill.model';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SkillService {

  constructor(private skillDal: SkillDal) {
  }

  getSkillsOfNurse(nurseId: string): Observable<Skill[]> {
    return this.skillDal.getSkillsOfNurse(nurseId);
  }


  getManySkillOfNurses(nursesIds: string[], specialesIds: string[]): Observable<Skill[]> {
    return this.skillDal.getManySkillOfNurses(nursesIds, specialesIds);
  }

  getNurseRating(profilId: string, hospitalId: string, date: string, vacationsId: string[]) : Observable<RoleLevel[]> {
    return this.skillDal.getNurseRating(profilId, hospitalId, date, vacationsId);
  }

  // NOT USED
  // getManySimulatedSkillOfNurses(date: string, nursesIds: string[], specialesIds: string[]): Observable<Skill[]> {
  //   return this.skillDal.getManySimulatedSkillOfNurses(date, nursesIds, specialesIds);
  // }

  // getAllLevelUpRequests(): Observable<Skill[]> {
  //   return this.skillDal.getAllLevelUpRequests();
  // }
}
