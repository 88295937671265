import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';

import {urlEndPoints} from '../config/end-points';
import { Profile } from '../models/profile.model';


@Injectable()
export class MessagingDal {

  constructor(private httpClient: HttpClient) {
  }

  getProfilesByPosition(position: string, residency? : string , pagination? : boolean , withoutPic?: boolean): Observable<Profile[]> {
    let endPoint;
    endPoint = urlEndPoints.messaging + "/members" + "?position=" + position + "&residency=" + (residency ? residency : "") + "&pagination=" + (pagination ? pagination : false) + "&withoutPic=" + (withoutPic ? withoutPic : false);
    return this.httpClient.get<any>(endPoint);
  }

  getChannels(page: number, limit : number,search? : string){
    let endPoint;
    endPoint = urlEndPoints.channels + "/channels" + "?page=" + page + "&limit=" + limit;
    if(search != "") endPoint = endPoint + "&search=" + search
    return this.httpClient.get<any>(endPoint);
  }

  getChannel(channelId : string){
    const endPoint = urlEndPoints.channels + channelId
    return this.httpClient.get<any>(endPoint);
  }

  getChannelByTwoProfileId(profileId : string){
    const endPoint = urlEndPoints.channels + 'getChannelByTwoProfileId/' + profileId;
    return this.httpClient.get<any>(endPoint);
  }

  getChannelMessages(channelID : string ,page: number, limit : number){
    let endPoint;
    endPoint = urlEndPoints.channels + "/messages/" + channelID + "?page=" + page + "&limit=" + limit;
    return this.httpClient.get<any>(endPoint);
  }

  getChannelMembers(channelID : string){
    let endPoint;
    endPoint = urlEndPoints.channels + "/members/" + channelID;
    return this.httpClient.get<any>(endPoint);
  }

  getMessageSeenBy(messageID : string){
    let endPoint;
    endPoint = urlEndPoints.channels + "/seenby/" + messageID;
    return this.httpClient.get<any>(endPoint);
  }

  getSeenByOpeningChannel(channelID : string){
    let endPoint;
    endPoint = urlEndPoints.channels + "/sendseenby/" + channelID;
    return this.httpClient.get<any>(endPoint);
  }

  checkExistingOneToOneChannel(profileID : string){
    let endPoint;
    endPoint = urlEndPoints.channels + "/checkOneToOne/" + profileID;
    return this.httpClient.get<any>(endPoint);
  }

}
