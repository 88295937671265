import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReasonDal } from '../dal/reason.dal';
import { Reason } from '../models/reason.model';

@Injectable()

export class ReasonService {

  constructor(private reasonDal: ReasonDal) {
  }

  getAllReasons(hospitalId?: string){
    return this.reasonDal.getAllReasons(hospitalId);
  }

  getReason(isNight, hospital?: string) {
    if (isNight)
      return this.reasonDal.getNightReasons(hospital)
    return this.reasonDal.getDayReasons(hospital)
  }

  getReasonsWithoutRule(){
    return this.reasonDal.getReasonsWithoutRule();
  }

  addReason(reason){
    return this.reasonDal.addReason(reason);
  }

  editReason(reason : Reason){
    return this.reasonDal.editReason(reason);
  }

}