import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarRef, SimpleSnackBar} from '@angular/material/snack-bar';

import {errorMessages} from '../utils/sm-error-messages';

@Injectable()
export class ToastService {

  private ERROR_DURATION: number = 3000;
  private INFO_DURATION: number = 2000;

  constructor(private snackBar: MatSnackBar) {
  }

  displayToast(message: string, duration: number, isError?: boolean): MatSnackBarRef<SimpleSnackBar> {
    return this.snackBar.open(message, 'Fermer', {
      duration: duration,
      panelClass: isError ? 'toast-error' : 'toast-info'
    });
  }

  errorToast(codeOrMessage: string, time?: number): MatSnackBarRef<SimpleSnackBar> {
    if (codeOrMessage in errorMessages) {
      codeOrMessage = errorMessages[codeOrMessage];
    }
    return this.displayToast(codeOrMessage, time ? time : this.ERROR_DURATION, true);
  }

  infoToast(message: string): MatSnackBarRef<SimpleSnackBar> {
    return this.displayToast(message, this.INFO_DURATION);
  }

  creationToast(message: string): MatSnackBarRef<SimpleSnackBar> {
    return this.snackBar.open(message, 'Fermer', {
      duration: this.INFO_DURATION,
      panelClass: 'creation-toast'
    });
  }

}
