import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  transform(text: string, limit: number, replacementString = '.'): any {
    return this.truncate(text, limit, replacementString);
  }

  truncate(text: string, limit: number, replacementString: string) {
    if (!text) {
        return text;
    }

    if (text.length > limit) {
        return `${text.slice(0, limit)}${replacementString}`;
    }

    return text;
  }

}
