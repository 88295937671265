import { environment } from "../../../environments/environment";

export const urlEndPoints = {
  login: `${environment.apiEndPoint}users/login/`,
  token: `${environment.apiEndPoint}token/`,
  hospitals: `${environment.apiEndPoint}hospitals/`,
  options: `${environment.apiEndPoint}options/`,
  materials: `${environment.apiEndPoint}materials/`,
  patients: `${environment.apiEndPoint}patients/`,
  pythonAPI: `${environment.apiEndPoint}python/`,
  rooms: `${environment.apiEndPoint}rooms/`,
  users: `${environment.apiEndPoint}users/`,
  profiles: `${environment.apiEndPoint}profiles/`,
  doctorAgendas: `${environment.apiEndPoint}doctoragendas/`,
  bufferPrograms: `${environment.apiEndPoint}bufferprograms/`,
  calendars: `${environment.apiEndPoint}calendars/`,
  realPrograms: `${environment.apiEndPoint}realprograms/`,
  ibodeGraphs: `${environment.apiEndPoint}ibodeGraph/`,
  specialtyRank: `${environment.apiEndPoint}ratingsystems`,
  rating: `${environment.apiEndPoint}ratingsystems/get/specialties`,
  defaultRating: `${environment.apiEndPoint}ratingsystems/default`,
  ratingByName: `${environment.apiEndPoint}ratingsystems/get/specialtie`,
  skill: `${environment.apiEndPoint}skills`,
  specialty: `${environment.apiEndPoint}specialties`,
  skills: `${environment.apiEndPoint}skillHistory/`,
  statop: `${environment.apiEndPoint}statop/`,
  sendEmail: `${environment.apiEndPoint}users/send-email`,
  validPasswordToken: `${environment.apiEndPoint}users/valid-password-token`,
  resetPassword: `${environment.apiEndPoint}users/reset-password/`,
  changePassword: `${environment.apiEndPoint}users/change-password/`,
  interventions: `${environment.apiEndPoint}interventions/`,
  allInterventions: `${environment.apiEndPoint}allinterventions/`,
  openings: `${environment.apiEndPoint}openings/`,
  cycle: `${environment.apiEndPoint}cycles/`,
  switch: `${environment.apiEndPoint}switchs/`,
  switchGardes: `${environment.apiEndPoint}switchs/calendars/`,
  ProgramSwitchs: `${environment.apiEndPoint}switchs/programs/`,
  Switchs: `${environment.apiEndPoint}switch/`,
  events: `${environment.apiEndPoint}events/`,
  calendarRequest: `${environment.apiEndPoint}requests`,
  messaging: `${environment.apiEndPoint}messagerie/`,
  anesthNeeds: `${environment.apiEndPoint}anesthNeeds/`,
  vacations: `${environment.apiEndPoint}vacations/`,
  channels: `${environment.apiEndPoint}channels/`,
  notifications: `${environment.apiEndPoint}notifications/`,
  reasons: `${environment.apiEndPoint}reasons/`,
  defaultReasons: `${environment.apiEndPoint}defaultReasons/`,
  rules: `${environment.apiEndPoint}rules/`,
  validateRules: `${environment.apiEndPoint}validaterules/`,
  programCommentary: `${environment.apiEndPoint}programcommentary/`,
  cycleTVO: `${environment.apiEndPoint}cycleTVO/`,
  wishes: `${environment.apiEndPoint}wishes/`,
  exclusions: `${environment.apiEndPoint}exclusions/`,
  recurrences: `${environment.apiEndPoint}recurrences/`,
  autofilling: `${environment.apiEndPoint}autofilling/`,
  realtimes: `${environment.apiEndPoint}realtimes/`,
  timeAlert: `${environment.apiEndPoint}timeAlerts/`,
  indicator: `${environment.apiEndPoint}indicators/`,
  paramedicals: `${environment.apiEndPoint}paramedicals/`,
  levelUpRequests: `${environment.apiEndPoint}levelUpRequests/`,
  userConfigurations: `${environment.apiEndPoint}userConfigurations/`,
  reasonsMaps: `${environment.apiEndPoint}reasonsmaps/`,
};
