import { AfterContentChecked, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';


import { CalendarService } from 'src/app/shared/services/calendar.service';
import { Profile } from '../../models/profile.model';
import { ErrorService } from '../../services/error.service';
import * as moment from 'moment';
import { SurgeonOpening, SO } from 'src/app/shared/models/surgeonOpenings.model';
import { SurgeonOpeningsService } from 'src/app/shared/services/surgeon-openings.service';
import * as _moment from 'moment';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { BufferProgramService } from './../../services/buffer-program.service';
import { SurgeonVerificationService } from './../../../surgeon-verification.service';
import { BufferProgram } from './../../models/buffer-program.model';
import {FormatMessageConfirmationPopupPipe} from "../../pipes/format-message-confirmation-popup.pipe";
import {PopupManagerService} from "../../services/popup-manager.service";
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-surgeon-opening',
  templateUrl: './surgeon-opening.component.html',
  styleUrls: ['./surgeon-opening.component.scss']
})
export class SurgeonOpeningComponent implements OnInit {

  public isMultiDaySelection: boolean = false;
  public isAbsence: boolean = false;
  public profile: Profile;
  public isSending = false;
  public isDeleting = false;
  public formGroup: FormGroup;
  public isLoading: boolean = false;

  private periodToUpdate: Date[];
  private date: Date;
  daySurgeonOpening: SurgeonOpening;
  private updateSOSubscription: Subscription;
  updateSOSSubscription: Subscription;
  getProgamsSubsc: any;

  public disponibility = [
    {label: 'Présence', value: 'AVAILABLE'},
    {label: 'Absence', value: 'ABSENT'},
  ];

  public isMultiHospitals: boolean = false;
  public hospitals: any[] = []

  constructor(
    private surgeonVerificationService: SurgeonVerificationService,
    private dialog: MatDialog,
    private bufferProgramService: BufferProgramService,
    public dialogRef: MatDialogRef<SurgeonOpening>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private errorService: ErrorService,
    private formBuilder: FormBuilder,
    private changeDetector: ChangeDetectorRef,
    private surgeonOpeningsService: SurgeonOpeningsService,
    private translatePipe: TranslatePipe,
    private formatMessageConfirmationPopup: FormatMessageConfirmationPopupPipe,
    private popupManagerService: PopupManagerService,
    private userService: UserService
  ) {
    this.periodToUpdate = [];
  }

  ngOnInit() {
    this.profile = this.data.profile;
    this.date = this.data.date;

    this.isMultiHospitals = this.userService.isCurrentUserHasMultipleHospitalsSelected() && this.profile.hospitals.length >= 2
    const selectedHospitalsId = this.userService.getSelectedHospitals()
    selectedHospitalsId.forEach(hospitalId => {
      const hospital = this.profile.hospitals.find((hospital) => String(hospital._id) == String(hospitalId))
      if (hospital) {
        this.hospitals.push({
          name: hospital.name,
          _id: hospital._id
        })
      }
    });

    this.makeForm();
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  makeForm(): void {
    let availability: string = this.data.availability
    if (this.data.period) {
      this.periodToUpdate = this.data.period;
      this.isMultiDaySelection = true;
      availability = 'AVAILABLE'
      this.isAbsence = false;
    }
    else {
      this.isMultiDaySelection = false;
      availability = this.data.availability;
      this.isAbsence = (availability === 'ABSENT');
    }
    this.date = this.data.date;

    this.formGroup = this.formBuilder.group({
      date: [{ value: new Date(this.date), disabled: true }, [Validators.required]],
      startDate: [{ value: new Date(this.periodToUpdate[0]), disabled: true }, [Validators.required]],
      endDate: [{ value: new Date(this.periodToUpdate[1]), disabled: true }, [Validators.required]],
      description: ['', []],
      availability: [availability, [Validators.required]],
      hospitals: [this.hospitals[0], [Validators.required]]
    });
  }

  generateDate(time: string, _date?: Date): Date {
    let date = new Date(_date || this.date);
    const splitTime = time.split(':');
    date.setUTCHours(+splitTime[0]);
    date.setUTCMinutes(+splitTime[1]);
    return date;
  }

  submit(): void {
    this.isLoading = true;
    this.isSending = true;
    if (this.formGroup.controls['availability'].value === 'ABSENT') {
      const [fromDate, toDate] = this.getDates()
      //checking if profile has programs on day x
      this.bufferProgramService.getSurgeonPrograms(fromDate, toDate, this.profile._id).
        subscribe(res => {
          res.existPrograms > 0 ? this.openConfirmatioPopup(res.programs) : this.update()
        })
    } else {
      this.update()
    }
  }

  openConfirmatioPopup(programs: BufferProgram[]) {
    let description = `<p>Ce praticien est déja associé à un ou plusieurs programmes:</p>`;

    for (let i = 0; programs[i]; i++) {
      const programText = this.formatMessageConfirmationPopup.transform(programs[i], 'surgeon', this.profile._id);
      if (programText) {
        description += `<p>${programText}</p>`;
      }
    }
    description += `<p>Êtes vous sûr(e) de vouloir supprimer cette disponibilité et fermer la ou les vacations précédentes ?</p>`;

    this.popupManagerService.openConfirmationPopup('Supprimer cette disponibilité ?', description, 370, 'danger', 'Oui, supprimer').afterClosed().subscribe(res => {
      res ? this.update() : null
      this.isLoading = false;
      this.isSending = false;
    })
  }

  update() {
    if (this.isMultiDaySelection) {
      this.updateSurOpes()
    } else {
      this.updateSurOpe()
    }
    this.isSending = false;
  }

  getDates() {
    if (this.isMultiDaySelection) {
      const fromDate = moment(this.periodToUpdate[0]).format('YYYY-MM-DD').toString()
      const toDate = moment(this.periodToUpdate[1]).format('YYYY-MM-DD').toString()
      return [fromDate, toDate]
    } else {
      const date = moment(this.data.date).format('YYYY-MM-DD').toString()
      return [date, date]
    }
  }

  updateSurOpes() {
    const [fromDate, toDate] = this.getDates()
    const body = {
      opening: this.formGroup.controls['availability'].value === 'AVAILABLE',
      hospital: (this.formGroup.get('hospitals').value)._id
    }
    //the purpose of this line is to update multiple surgeon openings
    this.updateSOSSubscription = this.surgeonOpeningsService.updateManySO(this.profile, fromDate, toDate, body).subscribe(res => {
      this.dialogRef.close('DONE');
      this.isLoading = false;
    },
      error => {
        this.isLoading = false;
        this.errorService.handleError(error)
      })
  }

  updateSurOpe() {
    const body = {
      opening: this.formGroup.controls['availability'].value === 'AVAILABLE',
      hospital: (this.formGroup.get('hospitals').value)._id
    }
    //the purpose of this line is to update one surgeon opening
    const [fromDate, toDate] = this.getDates()
    this.updateSOSubscription = this.surgeonOpeningsService.updateManySO(this.profile, fromDate, toDate, body).subscribe(
      res => {
        this.dialogRef.close({ availability: this.formGroup.controls['availability'].value })
        this.isLoading = false;
      },
      error => {
        this.errorService.handleError(error)
        this.isLoading = false;
      })
  }

  getDate(isoDate: Date) {
    const date = new Date(isoDate);
    return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
  }

  close(): void {
    this.dialogRef.close();
  }

  changeAvailability(): void {
    const availability = this.formGroup.get('availability').value;

    this.isAbsence = (availability === 'ABSENT');
    const description = this.formGroup.get('description');
    description.patchValue('');
  }

  ngOnDestroy(): void {
    if (this.updateSOSSubscription) {
      this.updateSOSSubscription.unsubscribe
    }
    if (this.updateSOSubscription) {
      this.updateSOSubscription.unsubscribe
    }
  }
}
