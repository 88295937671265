import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {Moment} from 'moment';

export class CustomDateAdapter extends MomentDateAdapter {
  previousDate;

  parse(value: any, parseFormat: string | string[]): Moment | null {
    return super.parse(value, parseFormat);
  }

  format(date: Moment, displayFormat: string): string {
    const endOfWeek: Moment = date.endOf('isoWeek');
    return super.format(endOfWeek, this.getWeekDateFormat(date));
  }

  getWeekDateFormat(date: Moment) {
    const cloneDate = date.clone();
    const startOfWeek: string = cloneDate.startOf('isoWeek').format('DD');
    const endOfWeek: string = cloneDate.endOf('isoWeek').format('DD');
    return startOfWeek + '-' + endOfWeek + ' MMM';
  }

}
