import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
    selector : "app-header",
    templateUrl : "./app-header.component.html",
    styleUrls: ["./app-header.component.scss"]
})

export class HeaderComponent implements OnInit{

    @Input() pages : string[] = [];
    @Input() actifPage : string = "";

    @Output() onPageClick = new EventEmitter<string>();

    constructor(){}

    ngOnInit(): void {
    }

    pageClickHandler(page : string){
        this.onPageClick.emit(page);
    }
}