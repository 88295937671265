import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Formation } from '../../models/formation.model';
import { Hospital } from '../../models/hospital.model';
import { Data, DayDetails, Effective, LastUpdateDate, Need, NightData, NightDetails, NightEffective, TotalCombined, TotalSeparated } from './interfaces';
import { UserService } from '../../services/user.service';
import { Paramedical } from '../../models/paramedical.model';
import { User } from '../../models/user.model';
import { Role } from '../../models/role.model';
import { MatDialog } from '@angular/material';
import { EffectiveNeedsDetailsPopupComponent } from './effective-needs-details-popup/effective-needs-details-popup.component';
import * as moment from 'moment';
import { Reason } from '../../models/reason.model';
moment.locale('fr');


const PERIOD = {
  DAY: 'DAY',
  NIGHT: 'NIGHT'
}

@Component({
  selector: 'app-effective-needs',
  templateUrl: './effective-needs.component.html',
  styleUrls: ['./effective-needs.component.scss']
})
export class EffectiveNeedsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() date: Date;
  @Input() effective: Effective | NightEffective;
  @Input() lastUpdateDate : LastUpdateDate;
  @Input() needs: Need[];
  @Input() formations: Formation[];
  @Input() paramedicRules: Paramedical[];
  @Input() separateMorningAfternoon: boolean = true;
  @Input() onlyMorning: boolean = false;
  @Input() onlyAfternoon: boolean = false;
  @Input() period: string = PERIOD.DAY;
  @Input() isFetching: boolean = true;
  @Input() hospitals: string[];

  @Input() openDetailsPopupSubject: Subject<void>;
  @Input() showDifference: boolean = false;
  @Input() showStatusIcon: boolean = true;
  @Input() showInformationsButton: boolean = false;
  @Input() layout: string = 'row'; // 'row' | 'column'
  @Input() showStatusIconOnly: boolean = false;
  @Input() clickOnTextToOpenDetails: boolean = false;
  @Input() isCommissionRegulationLayout: boolean = false;
  @Input() isSmartPlanningDetailsLayout: boolean = false;
  @Input() planningDayStatus: string;
  @Input() isSmartPlanningSideColumnLayout: boolean = false;

  @Input() isTVO: boolean = false;
  @Input() dayOfTheWeek: string;

  @Output() onObjectifStatusChange: EventEmitter<string> = new EventEmitter<string>();

  // Day data
  public details: DayDetails[];
  public total: TotalCombined | TotalSeparated;
  public dayObjectifStatus: string;

  // Night data
  public nightDetails: NightDetails[];
  public nightTotal: number;

  private currentUser: User;
  private concernedHospitals: string[];
  public isLoading: boolean = true;

  private openDetailsPopupSubscription: Subscription;

  constructor(
    private userService: UserService,
    private dialog: MatDialog  
  ) {
    this.currentUser = this.userService.getCurrentUser();
  }

  ngOnInit() {
    this.subscribeToOpenDetailsPopupSubject();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isFetching) {
      this.isLoading = true;
    }

    const isDayPeriod = this.period === PERIOD.DAY;
    const isNightPeriod = this.period === PERIOD.NIGHT;

    const needsInitialization = (isDayPeriod && this.formations && this.paramedicRules && !this.details) || (isNightPeriod && !this.nightDetails);
    let initializedJustNow = false;

    if (needsInitialization) {
      this.initConcernedHospitals();
      this.constructCoreStructureOfDetails();
      initializedJustNow = true;
    }

    const shouldConstructEffective = changes.effective || (this.effective && initializedJustNow)

    if (shouldConstructEffective) {
      if (isDayPeriod && this.details) {
        EffectiveNeedsComponent.constructEffectiveOfDetailsDay(this.details, (this.effective as Effective));
      }

      if (isNightPeriod && this.nightDetails) {
        EffectiveNeedsComponent.constructEffectiveOfDetailsNight(this.nightDetails, (this.effective as NightEffective));
      }
    }

    const shouldConstructNeeds = changes.needs || (this.needs && initializedJustNow)

    if (shouldConstructNeeds) {
      if (isDayPeriod && this.details) {
        EffectiveNeedsComponent.constructNeedsOfDetailsDay(this.details, this.needs);
      }
    }

    if ((this.details || this.nightDetails) && (shouldConstructEffective || shouldConstructNeeds)) {
      if (isDayPeriod && this.details) {
        this.constructDayTotal();
        this.dayObjectifStatus = EffectiveNeedsComponent.calculateObjectifStatus(this.details);
        this.onObjectifStatusChange.emit(this.dayObjectifStatus);
      }

      if (isNightPeriod) {
        this.constructNightTotal();
      }
    }

    if (!this.isFetching && ((this.details && this.total) || (this.nightDetails && this.nightTotal != null))) {
      this.isLoading = false;
    }
  }

  static calculateObjectifStatus(details: DayDetails[]): string {
    let effectiveIsLessThanNeedsInTotal;

    for (const item of details) {
      let effectiveIsLessThanNeedsForThisService;

      for (const item2 of item.data) {
        if (item2.morning.effective < item2.morning.needs || item2.afternoon.effective < item2.afternoon.needs) {
          item2.objectifStatus = 'ERROR';
          effectiveIsLessThanNeedsForThisService = true;
        } else {
          item2.objectifStatus = 'OK';
        }
      }

      if (effectiveIsLessThanNeedsForThisService) {
        item.objectifStatus = 'ERROR';
        effectiveIsLessThanNeedsInTotal = true;
      } else {
        item.objectifStatus = 'OK';
      }

    }

    if (effectiveIsLessThanNeedsInTotal) {
      return 'ERROR';
    } else {
      return 'OK';
    }
  }

  initConcernedHospitals(): void {
    if (this.hospitals) {
      this.concernedHospitals = this.hospitals;
    } else {
      this.concernedHospitals = this.userService.getSelectedHospitals();
    }
  }

  constructCoreStructureOfDetails(): void {
    let details: DayDetails[] | NightDetails[] = [];

    for (const h of this.concernedHospitals) {
      const found = this.currentUser.profile.hospitals.find((item) => String(item._id) === String(h));
      details.push({
        hospital: found,
        data: []
      });
    }

    if (this.period === PERIOD.DAY) {
      EffectiveNeedsComponent.constructCoreStructureOfDetailsDay(details, this.formations, this.paramedicRules);
      this.details = details;
    } else {
      this.nightDetails = (details as NightDetails[]);
    }
  }

  static constructCoreStructureOfDetailsDay(details: DayDetails[], formations: Formation[], paramedicRules: Paramedical[]): void {
    const treatedFormations = {};

    for (const formation of formations) {
      if (!treatedFormations[String(formation._id)] && formation.name.toLowerCase() !== 'iade') {
        // We didn't treat this formation yet

        // Construct gropuedFormations
        const groupedFormations: Formation[] = [formation, ...formation.groupedWith];

        // Mark as treated
        let skipGroup;
        for (const f of groupedFormations) {
          if (f.name.toLowerCase() === 'iade') {
            skipGroup = true;
          }
          
          treatedFormations[String(f._id)] = true;  
        }

        if (skipGroup) {
          continue;
        }

        // Construct title
        const title = groupedFormations.reduce((previous, current) => { 
          if (previous.length > 0) {
            return `${previous} / ${current.name}`;
          }

          return `${current.name}`;
        }, '');

        // Construct concernedRoles
        const concernedRoles = EffectiveNeedsComponent.getConcernedRoles(groupedFormations, paramedicRules);

        const found = details.find((item) => String(item.hospital._id) === String(formation.hospital));

        if (found) {
          found.data.push(
            {
              groupedFormations,
              title,
              concernedRoles
            }
          );
        }
      }
    }
  }

  static getConcernedRoles(formations: Formation[], paramedicRules: Paramedical[]): Role[] {
    const roles: Role[] = [];
    const addedRoles = {};

    for (const formation of formations) {
      const paramedicRule = paramedicRules.find((item) => String(item.formation._id) === String(formation._id));

      if (paramedicRule) {
        for (const role of paramedicRule.roles) {
          if (!addedRoles[String(role.roleId._id)]) {
            addedRoles[String(role.roleId._id)] = true;
            roles.push(role.roleId);
          }
        }
      }
    }

    return roles;
  }

  static constructEffectiveOfDetailsDay(details: DayDetails[], effective: Effective): void {
    // Init
    for (const item of details) {
      // Init total effective by service
      if (item.morning) {
        item.morning.effective = 0;
      } else {
        item.morning = {
          effective: 0
        }
      }

      if (item.afternoon) {
        item.afternoon.effective = 0;
      } else {
        item.afternoon = {
          effective: 0
        }
      }

      // Init effective of grouped formations
      for (const data of item.data) {
        if (data.morning) {
          data.morning.effective = 0;
        } else {
          data.morning = {
            effective: 0
          };
        }

        if (data.afternoon) {
          data.afternoon.effective = 0;
        } else {
          data.afternoon = {
            effective: 0
          };
        }
      }
    }

    if (!effective) {
      return;
    }

    if (Array.isArray(effective.morningNurseEffectif)) {
      // Loop through this.effective.morningNurseEffectif
      for (const item of effective.morningNurseEffectif) {
        // Add to total by service
        const hospital = details.find((item2) => String(item2.hospital._id) === String(item.formations[0].hospital));
        if (hospital) {
          hospital.morning.effective += item.morningNurses;
        }

        // Add to total by group of formation
        const data: Data = EffectiveNeedsComponent.findCorrespondingGroupInDetails(details, item.formations);

        if (data) {
          data.morning.effective += item.morningNurses;
        }
      }
    }

    if (Array.isArray(effective.afternoonNurseEffectif)) {
      // Loop through this.effective.afternoonNurseEffectif
      for (const item of effective.afternoonNurseEffectif) {
        // Add to total by service
        const hospital = details.find((item2) => String(item2.hospital._id) === String(item.formations[0].hospital));
        if (hospital) {
          hospital.afternoon.effective += item.afternoonNurses;
        }

        // Add to total by group of formation
        const data: Data = EffectiveNeedsComponent.findCorrespondingGroupInDetails(details, item.formations);

        if (data) {
          data.afternoon.effective += item.afternoonNurses;
        }
      }
    }
  }

  static findCorrespondingGroupInDetails(details: DayDetails[], formations: Formation[]): Data {
    if (!formations || formations.length === 0) {
      return undefined;
    }

    const hospital = details.find((item) => String(item.hospital._id) === String(formations[0].hospital));

    if (hospital) {
      const data = hospital.data.find((item) => {
        if (item.groupedFormations.length !== formations.length) {
          return false;
        }

        for (const f of formations) {
          const found = item.groupedFormations.find((item2) => String(item2._id) === String(f._id));
          
          if (!found) {
            return false;
          }
        }

        return true;
      });

      return data;
    }
  }

  static findCorrespondingGroupInNightEffective(nightEffective: NightEffective, formations: Formation[]): { reason: Reason, count: number }[] {
    if (!formations || formations.length === 0) {
      return undefined;
    }

    const data = nightEffective.nurses.find((item) => {
      if (item.formations.length !== formations.length) {
        return false;
      }

      for (const f of formations) {
        const found = item.formations.find((item2) => String(item2._id) === String(f._id));
        
        if (!found) {
          return false;
        }
      }

      return true;
    });

    return data.details;
  }

  static constructEffectiveOfDetailsNight(details: NightDetails[], effective: NightEffective): void {
    if (!effective) {
      return;
    }

    const formationsByHospital = {};

    const reasonsByHospital = {};

    for (const item of effective.nurses) {
      const hospitalOfFormations = String(item.formations[0].hospital);

      if (!formationsByHospital[hospitalOfFormations]) {
        formationsByHospital[hospitalOfFormations] = [];
      }

      if (!reasonsByHospital[hospitalOfFormations]) {
        reasonsByHospital[hospitalOfFormations] = [];
      }

      // We can't have duplicates in formations
      formationsByHospital[hospitalOfFormations].push(item.formations);

      // Check if we already have the reason
      for (const item2 of item.details) {
        const found = reasonsByHospital[hospitalOfFormations].find((el) => String(el._id) === String(item2.reason._id));

        if (!found) {
          reasonsByHospital[hospitalOfFormations].push(item2.reason);
        }
      }
    }

    // Construct nightDetails
    for (const item of details) {
      const hospitalId = String(item.hospital._id);
      const gropuedFormationsOfHospital = formationsByHospital[hospitalId] || [];
      const reasonsOfHospital = reasonsByHospital[hospitalId] || [];

      const data: NightData[] = [];

      for (const groupedFormations of gropuedFormationsOfHospital) {
        const title = groupedFormations.reduce((previous, current) => { 
          if (previous.length > 0) {
            return `${previous} / ${current.name}`;
          }

          return `${current.name}`;
        }, '');

        const item2: NightData = {
          groupedFormations,
          title,
          details: []
        };

        const groupedFormationsDetails = EffectiveNeedsComponent.findCorrespondingGroupInNightEffective(effective, groupedFormations)

        for (const reason of reasonsOfHospital) {
          item2.details.push({
            reason,
            effective: EffectiveNeedsComponent.findEffectiveInDetailsByReason(groupedFormationsDetails, reason)
          });
        }

        data.push(item2);
      }

      item.data = data;
    }
  }

  static findEffectiveInDetailsByReason(details: { reason: Reason, count: number }[], reason: Reason): number {
    const found = details.find((el) => String(el.reason._id) === String(reason._id));

    return found ? found.count : 0;
  }

  static constructNeedsOfDetailsDay(details: DayDetails[], needs: Need[]): void {
    // Init
    for (const item of details) {
      // Init total needs by service
      if (item.morning) {
        item.morning.needs = 0;
      } else {
        item.morning = {
          needs: 0
        }
      }

      if (item.afternoon) {
        item.afternoon.needs = 0;
      } else {
        item.afternoon = {
          needs: 0
        }
      }

      // Init needs of grouped formations
      for (const data of item.data) {
        if (data.morning) {
          data.morning.needs = 0;
        } else {
          data.morning = {
            needs: 0
          };
        }

        if (data.afternoon) {
          data.afternoon.needs = 0;
        } else {
          data.afternoon = {
            needs: 0
          };
        }
      }
    }

    if (!needs) {
      return;
    }

    // Loop through this.needs
    for (const item of needs) {
      if (item && item.role) {
        const hospitalData = details.find((item2) => String(item2.hospital._id) === String(item.role.hospital));
  
        if (hospitalData) {
          // Add needs to total by service
          hospitalData.morning.needs += item.morningNursesNeeds;
          hospitalData.afternoon.needs += item.afternoonNursesNeeds;
  
          // Add needs to grouped formations
          for (const item3 of hospitalData.data) {
            const isRoleConcerned = item3.concernedRoles.some((role) => String(role._id) === String(item.role._id));
  
            if (isRoleConcerned) {
              item3.morning.needs += item.morningNursesNeeds;
              item3.afternoon.needs += item.afternoonNursesNeeds;
            }
          }
        }
      }
    }
  }

  static constructNeedsOfDetailsNight(details: NightDetails[], needs: Need[]): void {

  }

  constructDayTotal(): void {
    let total: TotalCombined | TotalSeparated;

    const tmpTotal: TotalSeparated = {
      morning: {
        effective: 0,
        needs: 0
      },
      afternoon: {
        effective: 0,
        needs: 0
      }
    };

    // Calculate effective
    if (this.effective) {
      if (Array.isArray((this.effective as Effective).morningNurseEffectif)) {
        for (const item of (this.effective as Effective).morningNurseEffectif) {
          tmpTotal.morning.effective += item.morningNurses;
        }
      }
  
      if (Array.isArray((this.effective as Effective).afternoonNurseEffectif)) {
        for (const item of (this.effective as Effective).afternoonNurseEffectif) {
          tmpTotal.afternoon.effective += item.afternoonNurses;
        }
      }
    }

    // Calculate needs
    if (this.needs) {
      for (const item of this.needs) {
        tmpTotal.morning.needs += item.morningNursesNeeds;
        tmpTotal.afternoon.needs += item.afternoonNursesNeeds;
      }
    }

    if (this.separateMorningAfternoon) {
      total = tmpTotal;
    } else {
      total = {
        effective: Math.min(tmpTotal.morning.effective, tmpTotal.afternoon.effective),
        needs: Math.max(tmpTotal.morning.needs, tmpTotal.afternoon.needs)
      }
    }

    if (this.isSmartPlanningDetailsLayout && !this.separateMorningAfternoon) {
      total = {
        effective: Math.max(tmpTotal.morning.effective, tmpTotal.afternoon.effective),
        needs: Math.max(tmpTotal.morning.needs, tmpTotal.afternoon.needs)
      }
    }

    this.total = total;
  }

  constructNightTotal(): void {
    let total: number = 0;

    if (this.effective) {
      for (const item of (this.effective as NightEffective).nurses) {
        total += item.count;
      }
    }

    this.nightTotal = total;
  }

  openDetailsPopup(): void {
    const subTitle = this.isTVO ? this.dayOfTheWeek : this.formatDate(this.date);
    const title = this.period === 'DAY' ? (this.isTVO ? 'Besoins' : 'Effectif/Besoin') : 'Effectif de nuit';
    const showDiff = this.showDifference;
    let lastUpdateDate = null;
    if(this.lastUpdateDate){
       lastUpdateDate = this.lastUpdateDate.LastUpdateDate ? this.formatDateTime(new Date(this.lastUpdateDate.LastUpdateDate)) : this.lastUpdateDate.LastUpdateDate
    }
    const onlyNeeds = this.isTVO ? true : false;
    this.dialog.open(EffectiveNeedsDetailsPopupComponent, {
      data: {
        subTitle,
        title,
        lastUpdateDate,
        showDifference: showDiff,
        onlyNeeds: onlyNeeds,
        onlyMorning: this.onlyMorning,
        onlyAfternoon: this.onlyAfternoon,
        period: this.period,
        details: this.period === PERIOD.DAY ? this.details : this.nightDetails
      }
    })
  }

  formatDate(date: Date): string {
    const dayOfWeek = moment(date).format('dddd');
    const dayOfMonth = moment(date).format('DD');
    const month = moment(date).format('MMMM');

    return `${dayOfWeek.slice(0, 1).toUpperCase()}${dayOfWeek.slice(1)} ${dayOfMonth} ${month}`;
  }

  formatDateTime(date: Date): string {
    const dayOfWeek = moment(date).format('dddd');
    const dayOfMonth = moment(date).format('DD');
    const month = moment(date).format('MMMM');
    const time = moment(date).format('HH:mm'); // Add time formatting
  
    return `${dayOfWeek.slice(0, 1).toUpperCase()}${dayOfWeek.slice(1)} ${dayOfMonth} ${month} ${time}`;
  }
  getDifference(arg: { effective: number, needs: number }): number {
    return arg.effective - arg.needs;
  }

  getFxLayoutAlign(): string {
    if (this.layout === 'column') {
      return 'start center';
    }

    return 'start start';
  }

  subscribeToOpenDetailsPopupSubject(): void {
    if (this.openDetailsPopupSubscription) {
      this.openDetailsPopupSubscription.unsubscribe();
    }

    if (this.openDetailsPopupSubject) {
      this.openDetailsPopupSubscription = this.openDetailsPopupSubject.subscribe(() => {
        this.openDetailsPopup();
      })
    }
  }

  unsubscribeFromOpenDetailsPopupSubject(): void {
    if (this.openDetailsPopupSubscription) {
      this.openDetailsPopupSubscription.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeFromOpenDetailsPopupSubject();
  }
}
