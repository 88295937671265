import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserConfigurationDal } from '../dal/userConfiguration.dal';
import { UserConfiguration } from '../models/userConfiguration.model';
import { newUserConfiguration } from '../interfaces/userConfiguration.interfaces';

@Injectable({
    providedIn: 'root',
})
export class UserConfigurationService {
    constructor(private userConfigurationDal: UserConfigurationDal) {}

    getUserConfigurations(): Observable<UserConfiguration> {
        return this.userConfigurationDal.getUserConfigurations()
    }

    modifyUserConfigurations(newUserConfiguration: newUserConfiguration): Observable<UserConfiguration> {
        return this.userConfigurationDal.modifyUserConfigurations(newUserConfiguration)
    }

}
