import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';

import {urlEndPoints} from '../config/end-points';
import {Intervention} from '../models/intervention.model';
import { map } from 'rxjs/operators';

@Injectable()
export class InterventionDal {

  constructor(private httpClient: HttpClient) {
  }

  searchInterventionsBySurg(searchString: string): any{
    return this.httpClient.get<any>(urlEndPoints.interventions + 'searchSurgeonIntervention/' + searchString)
  }

  searchInterventions(searchOrder: number = -1, specialty?: string, name?: string, acte?:string, page?:number, limit?: number , pagination?: boolean, onlyWithFiles?: boolean): any{
    let endpoint;
    endpoint = urlEndPoints.interventions + 'searchSurgeonIntervention' + '?page='+page +  '&limit=' +limit +  '&name=' + name + '&acte=' + acte +  '&specialty=' + specialty + '&pagination=' + pagination + '&onlyWithFiles=' + onlyWithFiles + '&searchOrder=' + searchOrder;
    return this.httpClient.get<any>(endpoint);
  }

  searchInterventionsFromHospital(hospital: string, specialty?: string, name?: string, acte?:string, page?:number, limit?: number , pagination?: boolean, onlyWithFiles?: boolean): any{
    let endpoint;
    endpoint = urlEndPoints.interventions + 'searchSurgeonIntervention' + '?hospital=' + hospital + '&page='+page +  '&limit=' +limit +  '&name=' + name + '&acte=' + acte +  '&specialty=' + specialty + '&pagination=' + pagination + '&onlyWithFiles=' + onlyWithFiles;
    return this.httpClient.get<any>(endpoint);
  }

  getAllInterventions(act?: string, surgeon?: string, limit?: number, page?: number): Observable<any> {
    return this.httpClient.get<any[]>(urlEndPoints.interventions + (act ? '?act=' + act : '') + (surgeon ? (act ? '&' : '?') + 'surgeon=' + surgeon : '') + (limit && page ? (act || surgeon ? '&' : '?') + 'limit=' + limit + '&page=' + page : ''));
  }

  getIntervention(idIntervention: string): Observable<Intervention> {
    return this.httpClient.get<Intervention>(urlEndPoints.interventions + idIntervention);
  }

  addIntervention(intervention: Intervention): Observable<Intervention> {
    return this.httpClient.post<Intervention>(urlEndPoints.interventions, intervention);
  }

  modifyIntervention(intervention: Intervention): Observable<Intervention> {
    return this.httpClient.put<Intervention>(urlEndPoints.interventions + intervention._id, intervention);
  }

  archiveIntervention(idIntervention): Observable<Intervention> {
    return this.httpClient.delete<Intervention>(urlEndPoints.interventions + idIntervention);
  }

  setInterventionFile(interventionId, file, sourceInterventionId): Observable<any> {
    if (file) {
      return this.httpClient.post<any>(`${urlEndPoints.interventions}upload/${interventionId}` , file);
    } else {
      return this.httpClient.post<any>(`${urlEndPoints.interventions}upload/${interventionId}?sourceInterventionId=${sourceInterventionId}`, {});
    }
  }
}
