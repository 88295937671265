import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { NotificationService } from "./../../services/notification.service";
import { UserService } from "src/app/shared/services/user.service";
import { Profile } from "../../models/profile.model";
import { TOPICS } from "./../../const/glabals.const";
import { MatDialogRef } from "@angular/material";
import { StorageService } from "src/app/core/services/storage.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-notifications-feed",
  templateUrl: "./notifications-feed.component.html",
  styleUrls: ["./notifications-feed.component.scss"],
})
export class NotificationsFeedComponent implements OnInit {
  static instance: NotificationsFeedComponent;
  profile: Profile;
  notifications = [];
  pageSize: number;
  page = 1;
  isLoading: boolean = true;
  scrollLoading: boolean;
  hasNextPage: any;
  displayedColumns: string[] = ["Notifications"];

  osComponentOptions = {
    className: "os-theme-dark custom-sidebar",
    nativeScrollbarsOverlaid: {
      showNativeScrollbars: true,
    },
    callbacks: {
      onScroll: this.onScroll,
    },
    overflowBehavior: {
      x: "hidden",
    },

    paddingAbsolute: true,
    scrollbars: {
      autoHide: "never",
      clickScrolling: true,
    },
  };

  public isMultiHospitals: boolean = false;
  public searchKeyword: string = '';

  private getNotificationsSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<NotificationsFeedComponent>,
    private notificationService: NotificationService,
    private userService: UserService,
    private ChangeDetectorRef: ChangeDetectorRef,
    private storageService: StorageService
  ) {
    NotificationsFeedComponent.instance = this;
  }

  ngOnInit() {
    this.isMultiHospitals = this.userService.isCurrentUserHasMultipleHospitalsSelected()
    this.pageSize = Math.round(window.innerHeight / 100) + 7;
    this.getCurrentProfile();
    this.getNotifications();
  }

  getCurrentProfile() {
    this.profile = this.userService.getCurrentUser().profile;
  }

  getNotifications() {
    if(this.getNotificationsSubscription){
      this.getNotificationsSubscription.unsubscribe();
    }
    this.getNotificationsSubscription = this.notificationService
      .getNotifications(this.profile._id, this.page, this.pageSize, this.searchKeyword)
      .subscribe((res: any) => {
        this.notifications = this.notifications.concat(res.notifications);
        if(!res || !res.notifications || !res.notifications.length)
          this.notifications = []       
        this.addDateToSmartBriefBody(this.notifications)
        this.getIcons();
        this.isLoading = false;
        this.scrollLoading = false;
        this.hasNextPage = res.hasNextPage;
        this.page++;
        if (!(this.ChangeDetectorRef as any).destroyed) {
          this.ChangeDetectorRef.detectChanges();
        }
      });

  }

  getIcons() {
      this.notifications = this.notifications.map((notification) => {
        if (notification.topic == "update-store") {
          notification.topic = "update";
        }
        return {
          ...notification,
          icon: `${this.getIcon(notification.topic)}`,
        };
    });
    
  }


  getIcon(topic: string) {
    const {
      CALENDAR,
      VACATIONS,
      SMART_BRIEF,
      PARCOURS_IBODE_REQUEST,
      PARCOURS_IBODE_RECAP,
      PROGRAM,
      STATOP,
      MESSAGERIE,
      UPDATE_STORE,
    } = TOPICS;
    if (topic == CALENDAR || topic == VACATIONS) {
      return "calendar_today";
    } else if (topic == SMART_BRIEF) {
      return "phone_iphone";
    } else if (topic == PARCOURS_IBODE_REQUEST) {
      return "route";
    } else if (topic == PARCOURS_IBODE_RECAP) {
      return "route";
    } else if (topic == PROGRAM) {
      return "auto_fix_high";
    } else if (topic == STATOP) {
      return "insights";
    } else if (topic == MESSAGERIE) {
      return "chat_bubble";
    } else if (topic == UPDATE_STORE || topic == "update") {
      return "system_update";
    }
    return "notifications";
  }

  onScroll(event): void {
    const tableViewHeight = event.target.offsetHeight;
    const tableScrollHeight = event.target.scrollHeight;
    const scrollLocation = event.target.scrollTop;
    const buffer = 300;
    const limit = tableScrollHeight - tableViewHeight - buffer;
    if (
      scrollLocation > limit &&
      NotificationsFeedComponent.instance.hasNextPage &&
      !NotificationsFeedComponent.instance.isLoading
    ) {
      NotificationsFeedComponent.instance.isLoading = true;
      NotificationsFeedComponent.instance.getNotifications();
    }
  }

  search = (value: string) => {
    if(value !== '' && value.trim().length !== 0){
      this.notifications = [];
      this.page = 1;
      this.searchKeyword = value;
      this.getNotifications();
    }else if(value == ''){
      this.page = 1;
      this.notifications = [];
      this.searchKeyword = '';
      this.getNotifications();
    }
  };

  addDateToSmartBriefBody(array){
    const {SMART_BRIEF} = TOPICS;
    array = array.map((elet) =>{
      if (elet.topic == SMART_BRIEF){
        let bodyArr = elet.body.split("aujourd'hui");
        let dateString = (new Date(elet.createdAt)).toLocaleDateString('en-GB');
        elet.body = bodyArr[0] + "aujourd'hui (" + dateString + ')' + (bodyArr[1] ? bodyArr[1] : '');
        return elet;
      }
    });
  }


  close(): void {
    this.dialogRef.close();
  }
}
