import { pages } from 'src/app/shared/config/pages';
import { ANESTHESIST_WITH_ACCENT, NURSE, OPTIONS } from '../const/glabals.const';
import { ANESTHETISTS, IADE_RESPONSIBLE, INFIRMIERES, _CADRE_DE_BLOC, SURGEONS } from './profile-types';

export const MENU = [
    {
        items: [
            {
                pageTitle: 'Accueil',
                link: pages.pendingRequestsHome,
                icon:  {
                    default : 'home',
                    lightTheme : 'home.svg'
                },
                whoCanSee: [
                    ANESTHETISTS.type1,
                    ANESTHETISTS.type2,
                    ANESTHETISTS.type3,
                    ANESTHETISTS.type4,
                    ANESTHETISTS.type5,
                    ANESTHETISTS.type6,
                    ANESTHETISTS.type7,
                    ANESTHETISTS.type8,
                    INFIRMIERES.type1,
                    INFIRMIERES.type2,
                    INFIRMIERES.type3,
                    INFIRMIERES.type4,
                    _CADRE_DE_BLOC.type1,
                    _CADRE_DE_BLOC.type2,
                    _CADRE_DE_BLOC.type3,
                    IADE_RESPONSIBLE.type1,
                    IADE_RESPONSIBLE.type2,
                    IADE_RESPONSIBLE.type3,
                    IADE_RESPONSIBLE.type4,
                    IADE_RESPONSIBLE.type5,
                    IADE_RESPONSIBLE.type6,
                    IADE_RESPONSIBLE.type7,
                    IADE_RESPONSIBLE.type8,
                    IADE_RESPONSIBLE.type10,
                    IADE_RESPONSIBLE.type9,
                    SURGEONS.type1
                ]
            }
        ]
    },
    {
        items: [
            {
                pageTitle: 'Calendriers',
                link: pages.hrsuiteNurse,
                icon:  {
                    default : 'calendar_today',
                    lightTheme : 'calendar2.svg'
                },
                whoCanSee: [_CADRE_DE_BLOC.type1, _CADRE_DE_BLOC.type2, _CADRE_DE_BLOC.type3],
            },
            {
                pageTitle: 'Calendriers',
                link: pages.hrsuiteAnesthesiste,
                icon:  {
                    default : 'calendar_today',
                    lightTheme : 'calendar2.svg'
                },
                whoCanSee: [ANESTHETISTS.type1,ANESTHETISTS.type2, ANESTHETISTS.type3, IADE_RESPONSIBLE.type3, IADE_RESPONSIBLE.type4, IADE_RESPONSIBLE.type8, IADE_RESPONSIBLE.type7, ANESTHETISTS.type7,IADE_RESPONSIBLE.type10, IADE_RESPONSIBLE.type9,]
            },
            {
                pageTitle: 'Calendriers',
                link: pages.indivdualCalendar,
                icon:  {
                    default : 'calendar_today',
                    lightTheme : 'calendar2.svg'
                },
                whoCanSee: [INFIRMIERES.type1, INFIRMIERES.type2, INFIRMIERES.type3, INFIRMIERES.type4, ANESTHETISTS.type7, ANESTHETISTS.type6, ANESTHETISTS.type5, ANESTHETISTS.type1, ANESTHETISTS.type4,IADE_RESPONSIBLE.type10, IADE_RESPONSIBLE.type9, IADE_RESPONSIBLE.type1, IADE_RESPONSIBLE.type2, IADE_RESPONSIBLE.type5, IADE_RESPONSIBLE.type6, SURGEONS.type1]
            }
        ],
        subMenus: [
            {
                title: 'Calendriers',
                items: [
                    //liste des collaborateurs nurses
                    {
                        pageTitle: 'Liste des Ibode / Ide / AS',
                        icon:  {
                            default : 'list',
                            lightTheme : 'list.svg'
                        },
                        link: pages.nurses,
                        whoCanSee: [
                            _CADRE_DE_BLOC.type1,
                            _CADRE_DE_BLOC.type2,
                            _CADRE_DE_BLOC.type3,
                            ANESTHETISTS.type7
                        ]
                    },
                    //liste des collaborateurs anesthesistes
                    {
                        pageTitle: 'Liste des Anesthésistes / Iade',
                        icon:  {
                            default : 'list',
                            lightTheme : 'list.svg'
                        },
                        link: pages.anesthesistes,
                        whoCanSee: [
                            ANESTHETISTS.type1,
                            ANESTHETISTS.type2,
                            ANESTHETISTS.type3,
                            ANESTHETISTS.type4,
                            ANESTHETISTS.type7,                            
                            _CADRE_DE_BLOC.type3
                        ]
                    },
                    //liste des collaborateurs Iade
                    {
                        pageTitle: 'Liste des Iade',
                        icon:  {
                            default : 'list',
                            lightTheme : 'list.svg'
                        },
                        link: pages.anesthesistes,
                        whoCanSee: [
                            IADE_RESPONSIBLE.type7,
                            IADE_RESPONSIBLE.type8,
                            IADE_RESPONSIBLE.type3,
                            IADE_RESPONSIBLE.type4,
                            IADE_RESPONSIBLE.type10,
                            IADE_RESPONSIBLE.type9,
                        ]
                    },
                    {
                        pageTitle: 'Calendrier individuel',
                        icon:  {
                            default : 'date_range',
                            lightTheme : 'calendar.svg'
                        },
                        link: pages.indivdualCalendar,
                        whoCanSee: [
                            ANESTHETISTS.type1,
                            ANESTHETISTS.type4,
                            ANESTHETISTS.type2,
                            ANESTHETISTS.type3,
                            IADE_RESPONSIBLE.type7,
                            IADE_RESPONSIBLE.type8,
                            IADE_RESPONSIBLE.type3,
                            IADE_RESPONSIBLE.type4,
                            IADE_RESPONSIBLE.type10,
                            IADE_RESPONSIBLE.type9,
                            _CADRE_DE_BLOC.type1,
                            _CADRE_DE_BLOC.type2,
                            _CADRE_DE_BLOC.type3,
                            ANESTHETISTS.type7,
                            SURGEONS.type1
                        ]
                    },
                    //Demande RH infirmiere
                    {
                        pageTitle: 'Demande RH',
                        icon:  {
                            default : 'notifications_paused',
                            lightTheme : 'rhRequests.svg'
                        },
                        link: `${pages.demandeRhValidation}/${NURSE}`,
                        whoCanSee: [
                            _CADRE_DE_BLOC.type1,
                            _CADRE_DE_BLOC.type2,
                            _CADRE_DE_BLOC.type3
                        ]
                    },
                    //Demande RH anesthesiste
                    {
                        pageTitle: 'Demande RH',
                        icon:  {
                            default : 'notifications_paused',
                            lightTheme : 'rhRequests.svg'
                        },
                        link: `${pages.demandeRhValidation}/${ANESTHESIST_WITH_ACCENT}`,
                        whoCanSee: [
                            ANESTHETISTS.type2,
                            ANESTHETISTS.type3,
                            IADE_RESPONSIBLE.type7,
                            IADE_RESPONSIBLE.type8,
                            IADE_RESPONSIBLE.type3,
                            IADE_RESPONSIBLE.type4,
                            IADE_RESPONSIBLE.type10,
                            IADE_RESPONSIBLE.type9,
                            ANESTHETISTS.type7
                        ]
                    },
                    {
                        pageTitle: 'Commission de régulation',
                        icon:  {
                            default : 'event_note',
                            lightTheme : 'validation.svg'
                        },
                        link: pages.commissionRegulation,
                        whoCanSee: [
                            ANESTHETISTS.type2,
                            ANESTHETISTS.type3,
                            _CADRE_DE_BLOC.type1,
                            _CADRE_DE_BLOC.type2,
                            _CADRE_DE_BLOC.type3,
                            ANESTHETISTS.type7
                        ]
                    }
                ]
            },
            {
                title: "Chirurgien",
                items: [
                    {
                        pageTitle: 'Liste des chirurgiens',
                        icon:  {
                            default : 'perm_identity',
                            lightTheme : 'bed.svg'
                        },
                        link: pages.parPraticiens,
                        whoCanSee: [
                            _CADRE_DE_BLOC.type1,
                            _CADRE_DE_BLOC.type2,
                            ANESTHETISTS.type2,
                            ANESTHETISTS.type3,
                            _CADRE_DE_BLOC.type3,
                            ANESTHETISTS.type7,
                            IADE_RESPONSIBLE.type7,
                            IADE_RESPONSIBLE.type8,
                            IADE_RESPONSIBLE.type3,
                            IADE_RESPONSIBLE.type4,
                            IADE_RESPONSIBLE.type10,
                            IADE_RESPONSIBLE.type9,
                        ]
                    },
                    {                        
                        pageTitle: 'Par vacations',
                        icon:  {
                            default : 'location_city',
                            lightTheme : 'surgeon.svg'
                        },
                        link: pages.parSalles,
                        whoCanSee: [
                            _CADRE_DE_BLOC.type1,
                            _CADRE_DE_BLOC.type2,
                            _CADRE_DE_BLOC.type3,
                            SURGEONS.type1, 
                            IADE_RESPONSIBLE.type10,
                            IADE_RESPONSIBLE.type9,                            
                        ]
                    },
                    {
                        pageTitle: 'Par vacations',
                        icon:  {
                            default : 'location_city',
                            lightTheme : 'surgeon.svg'
                        },
                        link: pages.parSallesBesoins,
                        whoCanSee: [
                            ANESTHETISTS.type2,
                            ANESTHETISTS.type3,
                            ANESTHETISTS.type7,
                            IADE_RESPONSIBLE.type7,
                            IADE_RESPONSIBLE.type8,
                            IADE_RESPONSIBLE.type3,
                            IADE_RESPONSIBLE.type4,
                            IADE_RESPONSIBLE.type10,
                            IADE_RESPONSIBLE.type9,
                        ]
                    },
                    {
                        pageTitle: 'Par besoins',
                        icon:  {
                            default : 'location_city',
                            lightTheme : 'surgeon.svg'
                        },
                        link: pages.parSallesBesoins,
                        whoCanSee: [
                            _CADRE_DE_BLOC.type3,
                        ]
                    },
                    {
                        pageTitle: 'Par interventions',
                        link: pages.parInterventions,
                        whoCanSee: [
                            _CADRE_DE_BLOC.type1,
                            _CADRE_DE_BLOC.type2,
                            ANESTHETISTS.type2,
                            ANESTHETISTS.type3,
                            _CADRE_DE_BLOC.type3,
                            ANESTHETISTS.type7,
                            SURGEONS.type1,
                            IADE_RESPONSIBLE.type7,
                            IADE_RESPONSIBLE.type8,
                            IADE_RESPONSIBLE.type3,
                            IADE_RESPONSIBLE.type4,
                            IADE_RESPONSIBLE.type10,
                            IADE_RESPONSIBLE.type9,
                        ]
                    },
                ]
            }]
    },
    {
        items: [
            {
                pageTitle: 'SmartPlanning',
                link: pages.programRecap,
                icon:  {
                    default : 'auto_fix_high',
                    lightTheme : 'operating.svg'
                },
                whoCanSee: [
                    ANESTHETISTS.type1, ANESTHETISTS.type2, ANESTHETISTS.type3, ANESTHETISTS.type4,ANESTHETISTS.type7,
                    _CADRE_DE_BLOC.type1, _CADRE_DE_BLOC.type2, _CADRE_DE_BLOC.type3,
                    IADE_RESPONSIBLE.type3, IADE_RESPONSIBLE.type4,IADE_RESPONSIBLE.type7, IADE_RESPONSIBLE.type8, IADE_RESPONSIBLE.type9, IADE_RESPONSIBLE.type10,
                ]
            }, 
            {
                pageTitle: 'Mon Planning',
                link: pages.myProgram,
                icon:  {
                    default : 'auto_fix_high',
                    lightTheme : 'operating.svg'
                },
                whoCanSee: [
                    ANESTHETISTS.type5, ANESTHETISTS.type6,
                    INFIRMIERES.type1, INFIRMIERES.type2, INFIRMIERES.type3, INFIRMIERES.type4,
                    IADE_RESPONSIBLE.type1, IADE_RESPONSIBLE.type2, IADE_RESPONSIBLE.type5, IADE_RESPONSIBLE.type6,
                ]
            }

        ],
        subMenus: [
            {
                title: 'SmartPlanning',
                items: [
                    {
                        pageTitle: 'Vue quotidienne',
                        icon:  {
                            default : 'view_agenda',
                            lightTheme : 'bed.svg'
                        },
                        link: pages.programRecap,
                        whoCanSee: [
                            ANESTHETISTS.type1, ANESTHETISTS.type2, ANESTHETISTS.type3, ANESTHETISTS.type4, ANESTHETISTS.type7,
                            _CADRE_DE_BLOC.type1, _CADRE_DE_BLOC.type2, _CADRE_DE_BLOC.type3,
                            IADE_RESPONSIBLE.type3, IADE_RESPONSIBLE.type4, IADE_RESPONSIBLE.type7, IADE_RESPONSIBLE.type8, IADE_RESPONSIBLE.type10, IADE_RESPONSIBLE.type9,                 ]
                    },
                    // {
                    //     pageTitle: 'Outil par salle',
                    //     icon:  {
                    //         default : 'location_city',
                    //         lightTheme : 'profit-report.svg'
                    //     },
                    //     link: pages.smartPlanning,
                    //     whoCanSee: [ANESTHETISTS.type1, ANESTHETISTS.type2, ANESTHETISTS.type3, _CADRE_DE_BLOC.type1, _CADRE_DE_BLOC.type2, _CADRE_DE_BLOC.type3,
                    //     ANESTHETISTS.type7]
                    // },
                    {
                        pageTitle: 'Vue hebdomadaire',
                        icon:  {
                            default : 'calendar_month',
                            lightTheme : 'week.svg'
                        },
                        link: pages.weekVue,
                        whoCanSee: [
                            ANESTHETISTS.type2, ANESTHETISTS.type3, ANESTHETISTS.type7,
                            _CADRE_DE_BLOC.type1, _CADRE_DE_BLOC.type2, _CADRE_DE_BLOC.type3,
                            IADE_RESPONSIBLE.type3, IADE_RESPONSIBLE.type4, IADE_RESPONSIBLE.type7, IADE_RESPONSIBLE.type8, IADE_RESPONSIBLE.type10, IADE_RESPONSIBLE.type9,
                        ]
                    },
                    {
                        pageTitle: 'Planning individuel',
                        icon:  {
                            default : 'person',
                            lightTheme : 'SmartPlanning.svg'
                          },
                          link: pages.myProgram,
                          whoCanSee: [
                            ANESTHETISTS.type1,
                            ANESTHETISTS.type2,
                            ANESTHETISTS.type3,
                            ANESTHETISTS.type4,
                            ANESTHETISTS.type7,
                            _CADRE_DE_BLOC.type1, _CADRE_DE_BLOC.type2, _CADRE_DE_BLOC.type3,
                            IADE_RESPONSIBLE.type3, IADE_RESPONSIBLE.type4, IADE_RESPONSIBLE.type7, IADE_RESPONSIBLE.type8,                             IADE_RESPONSIBLE.type10,
                            IADE_RESPONSIBLE.type9,
                            /*INFIRMIERES.type1, INFIRMIERES.type2, INFIRMIERES.type3, INFIRMIERES.type4,
                            _CADRE_DE_BLOC.type1, _CADRE_DE_BLOC.type2, _CADRE_DE_BLOC.type3,
                            IADE_RESPONSIBLE.type1, IADE_RESPONSIBLE.type2, IADE_RESPONSIBLE.type3, IADE_RESPONSIBLE.type4, IADE_RESPONSIBLE.type5, IADE_RESPONSIBLE.type6, IADE_RESPONSIBLE.type7, IADE_RESPONSIBLE.type8,*/
                          ],
                    },
                ]
            }
        ]
    },
    {
        items: [
            {
                pageTitle: 'Parcours de Compétences',
                link: pages.parcoursIbode,
                icon:  {
                    default : 'route',
                    lightTheme : 'cloud-storage.svg'
                },
                whoCanSee: [
                    _CADRE_DE_BLOC.type1,
                    _CADRE_DE_BLOC.type2,
                    _CADRE_DE_BLOC.type3,
                    ANESTHETISTS.type7,
                    INFIRMIERES.type3,
                    INFIRMIERES.type1,
                    IADE_RESPONSIBLE.type3,
                    IADE_RESPONSIBLE.type4,
                    IADE_RESPONSIBLE.type7,
                    IADE_RESPONSIBLE.type8,
                    IADE_RESPONSIBLE.type9,
                    IADE_RESPONSIBLE.type10,
                ]
            },
            {
                pageTitle: 'Parcours de Compétences',
                link: pages.parcoursIbodeCurrent,
                icon:  {
                    default : 'route',
                    lightTheme : 'cloud-storage.svg'
                },
                whoCanSee: [
                    INFIRMIERES.type1,
                    IADE_RESPONSIBLE.type1,
                    IADE_RESPONSIBLE.type2,
                    IADE_RESPONSIBLE.type5,
                    IADE_RESPONSIBLE.type6
                ]
            },
            {
                pageTitle: 'Livret des interventions',
                link: pages.intervention,
                icon:  {
                    default : 'route',
                    lightTheme : 'cloud-storage.svg'
                },
                whoCanSee: [INFIRMIERES.type2, INFIRMIERES.type4]
            }
        ],
        subMenus: [
            {
                title: 'Parcours de Compétences',
                items: [
                    {
                        pageTitle: 'Compétences collectives',
                        icon:  {
                            default : 'group',
                            lightTheme : 'group.svg'
                        },
                        link: pages.parcoursIbode,
                        whoCanSee: [
                            ANESTHETISTS.type2,
                            ANESTHETISTS.type3,
                            _CADRE_DE_BLOC.type1,
                            _CADRE_DE_BLOC.type2,
                            _CADRE_DE_BLOC.type3,
                            ANESTHETISTS.type7,
                            IADE_RESPONSIBLE.type3,
                            IADE_RESPONSIBLE.type4,
                            IADE_RESPONSIBLE.type7,
                            IADE_RESPONSIBLE.type8,
                            IADE_RESPONSIBLE.type9,
                            IADE_RESPONSIBLE.type10,
                        ]
                    },
                    {
                        pageTitle: 'Compétences individuelles',
                        icon:  {
                            default : 'account_box',
                            lightTheme : 'individual.svg'
                        },
                        link: pages.comptenceIndividuelle,
                        whoCanSee: [
                            _CADRE_DE_BLOC.type1,
                            _CADRE_DE_BLOC.type2,
                            _CADRE_DE_BLOC.type3,
                            ANESTHETISTS.type7,
                            INFIRMIERES.type3,
                            INFIRMIERES.type1,
                            IADE_RESPONSIBLE.type1,
                            IADE_RESPONSIBLE.type2,
                            IADE_RESPONSIBLE.type3,
                            IADE_RESPONSIBLE.type4,
                            IADE_RESPONSIBLE.type5,
                            IADE_RESPONSIBLE.type6,
                            IADE_RESPONSIBLE.type7,
                            IADE_RESPONSIBLE.type8,
                            IADE_RESPONSIBLE.type9,
                            IADE_RESPONSIBLE.type10,
                        ]
                    },

                    {
                        pageTitle: 'Livret des interventions',
                        icon:  {
                            default : 'book',
                            lightTheme : 'book.svg'
                        },
                        link: pages.intervention,
                        whoCanSee: [
                            INFIRMIERES.type1,
                            INFIRMIERES.type3,
                            _CADRE_DE_BLOC.type1,
                            _CADRE_DE_BLOC.type2,
                            _CADRE_DE_BLOC.type3,
                            ANESTHETISTS.type7,
                            IADE_RESPONSIBLE.type3,
                            IADE_RESPONSIBLE.type4,
                            IADE_RESPONSIBLE.type7,
                            IADE_RESPONSIBLE.type8,
                            IADE_RESPONSIBLE.type9,
                            IADE_RESPONSIBLE.type10,
                        ]
                    }
                ]
            }
        ]
    },
    {
        items: [
            {
                pageTitle: 'StatOp',
                link: pages.statOp,
                icon:  {
                    default : 'insights',
                    lightTheme : 'speedometer.svg'
                },
                whoCanSee: [
                    {
                        ...ANESTHETISTS.type2,
                        denyAccessForInterneResponsible: true
                    },
                    ANESTHETISTS.type3, 
                    _CADRE_DE_BLOC.type1, 
                    _CADRE_DE_BLOC.type2, 
                    _CADRE_DE_BLOC.type3, 
                    ANESTHETISTS.type7
                ]
            },
            {
                pageTitle: 'StatOp',
                link: pages.statopAnesthesisteGeneral,
                icon:  {
                    default : 'insights',
                    lightTheme : 'speedometer.svg'
                },
                whoCanSee: [
                    ANESTHETISTS.type2,
                ]
            },
            {
                pageTitle: 'StatOp',
                link: pages.statopAnesthesisteParAnesthesiste,
                icon:  {
                    default : 'insights',
                    lightTheme : 'speedometer.svg'
                },
                neededOptions: [OPTIONS.ANESTH_DETAILS],
                whoCanSee: [ANESTHETISTS.type1, ANESTHETISTS.type8]
            }
        ],
        subMenus: [
            {
                title: 'StatOp',
                items: [
                    {
                        pageTitle: 'Vue générale du bloc',
                        icon:  {
                            default : 'data_usage',
                            lightTheme : 'bed.svg'
                        },
                        link: pages.statOp,
                        whoCanSee: [
                            {
                                ...ANESTHETISTS.type2,
                                denyAccessForInterneResponsible: true
                            }, 
                            ANESTHETISTS.type3, 
                            ANESTHETISTS.type7,
                            _CADRE_DE_BLOC.type1, 
                            _CADRE_DE_BLOC.type2, 
                            _CADRE_DE_BLOC.type3,
                        ]
                    },
                    {
                        pageTitle: 'Par praticiens',
                        icon:  {
                            default : 'perm_identity',
                            lightTheme : 'surgeon.svg'
                        },
                        link: pages.statopBlocParPraticien,
                        whoCanSee: [
                            {
                                ...ANESTHETISTS.type2,
                                denyAccessForInterneResponsible: true
                            }, 
                            ANESTHETISTS.type3, 
                            ANESTHETISTS.type7,
                            _CADRE_DE_BLOC.type1, 
                            _CADRE_DE_BLOC.type2, 
                            _CADRE_DE_BLOC.type3,
                        ]
                    },
                    {
                        pageTitle: 'Statistiques générales des anesthésistes',
                        link: pages.statopAnesthesisteGeneral,
                        whoCanSee: [
                            ANESTHETISTS.type2,
                            ANESTHETISTS.type3,
                            _CADRE_DE_BLOC.type1,
                            _CADRE_DE_BLOC.type2,
                            _CADRE_DE_BLOC.type3,
                            ANESTHETISTS.type7],
                        neededOptions: [OPTIONS.ANESTH_DETAILS]
                    },
                    {
                        pageTitle: 'Par anesthésiste',
                        link: pages.statopAnesthesisteParAnesthesiste,
                        whoCanSee: [
                            ANESTHETISTS.type2, 
                            ANESTHETISTS.type3,
                            _CADRE_DE_BLOC.type1,
                            _CADRE_DE_BLOC.type2,
                            _CADRE_DE_BLOC.type3,
                            ANESTHETISTS.type7,
                            ANESTHETISTS.type1,
                            ANESTHETISTS.type8],
                        neededOptions: [OPTIONS.ANESTH_DETAILS]
                    }
                ]
            }
        ]
    },
    {
        items: [
            {
                pageTitle: 'Configuration',
                link: pages.configuration,
                icon:  {
                    default : 'settings',
                    lightTheme : 'gears.svg'
                },
                whoCanSee: [
                    ANESTHETISTS.type2, 
                    ANESTHETISTS.type3, 
                    _CADRE_DE_BLOC.type1,
                    _CADRE_DE_BLOC.type2,
                    _CADRE_DE_BLOC.type3, 
                    ANESTHETISTS.type7,                            
                    IADE_RESPONSIBLE.type7,
                    IADE_RESPONSIBLE.type8,
                    IADE_RESPONSIBLE.type3,
                    IADE_RESPONSIBLE.type4,
                    IADE_RESPONSIBLE.type10,
                    IADE_RESPONSIBLE.type9,
                ]
            },
            {
                pageTitle: 'Configuration',
                link: '/configuration/fiche-poste',
                icon:  {
                    default : 'settings',
                    lightTheme : 'gears.svg'
                },
                neededOptions: [OPTIONS.ANESTH_DETAILS],
                whoCanSee: [ANESTHETISTS.type1, ANESTHETISTS.type8]
            }
        ],
        subMenus: [
            {
                title: 'Configuration',
                items: [
                    {
                        pageTitle: 'Général',
                        icon:  {
                            default : 'settings_applications',
                            lightTheme : 'settings2.svg'
                        },
                        link: '/configuration/general',
                        whoCanSee: [
                        ANESTHETISTS.type2, 
                        ANESTHETISTS.type3, 
                        _CADRE_DE_BLOC.type1, 
                        _CADRE_DE_BLOC.type2, 
                        _CADRE_DE_BLOC.type3,
                        ANESTHETISTS.type7,
                        IADE_RESPONSIBLE.type7,
                        IADE_RESPONSIBLE.type8,
                        IADE_RESPONSIBLE.type3,
                        IADE_RESPONSIBLE.type4,
                        IADE_RESPONSIBLE.type10,
                        IADE_RESPONSIBLE.type9,                          
]
                    },
                    {
                        pageTitle: 'Structure',
                        icon:  {
                            default : '',
                            lightTheme : ''
                        },
                        link: '/configuration/structure',
                        whoCanSee: [
                            {
                                ...ANESTHETISTS.type2,
                                denyAccessForInterneResponsible: true
                            }, 
                            ANESTHETISTS.type3, 
                            ANESTHETISTS.type7,
                            _CADRE_DE_BLOC.type1, 
                            _CADRE_DE_BLOC.type2, 
                            _CADRE_DE_BLOC.type3,
                            IADE_RESPONSIBLE.type10,
                            IADE_RESPONSIBLE.type9
                        ]
                    },
                    {
                        pageTitle: 'RH',
                        icon:  {
                            default : '',
                            lightTheme : ''
                        },
                        link: '/configuration/rh',
                        whoCanSee: [
                            {
                                ...ANESTHETISTS.type2,
                                denyAccessForInterneResponsible: true
                            }, 
                            ANESTHETISTS.type3, 
                            ANESTHETISTS.type7,
                            _CADRE_DE_BLOC.type1,
                            _CADRE_DE_BLOC.type2, 
                            _CADRE_DE_BLOC.type3,
                        ]
                    },
                    {
                        pageTitle: 'Besoins',
                        icon:  {
                            default : 'group',
                            lightTheme : 'group.svg'
                        },
                        link: '/configuration/besoins',
                        whoCanSee: [
                            ANESTHETISTS.type2, 
                            ANESTHETISTS.type3, 
                            _CADRE_DE_BLOC.type3,
                            ANESTHETISTS.type7,
                            IADE_RESPONSIBLE.type7,
                            IADE_RESPONSIBLE.type8,
                            IADE_RESPONSIBLE.type3,
                            IADE_RESPONSIBLE.type4,
                            IADE_RESPONSIBLE.type10,
                            IADE_RESPONSIBLE.type9,
                        ]
                    },
                    {
                        pageTitle: 'TVO',
                        icon:  {
                            default : 'dvr',
                            lightTheme : 'booking.svg'
                        },
                        link: pages.tvo,
                        whoCanSee: [
                            _CADRE_DE_BLOC.type1, 
                            _CADRE_DE_BLOC.type2, 
                            _CADRE_DE_BLOC.type3, 
                            IADE_RESPONSIBLE.type10,
                            IADE_RESPONSIBLE.type9,
                            ANESTHETISTS.type7
                        ]
                    },
                    {
                        pageTitle: 'Cycle planning',
                        icon:  {
                            default : 'loop',
                            lightTheme : 'looping-arrows.svg'
                        },
                        link: '/configuration/cycle-planning',
                        whoCanSee: [
                            _CADRE_DE_BLOC.type1, 
                            _CADRE_DE_BLOC.type2,
                            _CADRE_DE_BLOC.type3,
                            ANESTHETISTS.type7 , 
                            ANESTHETISTS.type2, 
                            ANESTHETISTS.type3,                             
                            IADE_RESPONSIBLE.type7,
                            IADE_RESPONSIBLE.type8,
                            IADE_RESPONSIBLE.type3,
                            IADE_RESPONSIBLE.type4,
                            IADE_RESPONSIBLE.type10,
                            IADE_RESPONSIBLE.type9,
                        ]
                    },
                    {
                        pageTitle: 'Fiche de poste',
                        link: '/configuration/fiche-poste',
                        neededOptions: [OPTIONS.ANESTH_DETAILS],
                        whoCanSee: [
                        _CADRE_DE_BLOC.type3,
                        ANESTHETISTS.type7 , 
                        ANESTHETISTS.type2, 
                        ANESTHETISTS.type3, 
                        ANESTHETISTS.type1, 
                        ANESTHETISTS.type8                            
]
                    },
                    // {
                    //     pageTitle: 'Préférences',
                    //     link: '/configuration/preferences',
                    //     whoCanSee: [
                    //         _CADRE_DE_BLOC.type1, 
                    //         _CADRE_DE_BLOC.type2,
                    //         _CADRE_DE_BLOC.type3,
                    //     ]
                    // },
                ]
            }
        ]
    },
    // {
    //     items: [
    //         {
    //             pageTitle: 'Messagerie',
    //             link: pages.messaging,
    //             icon:  {
    //                 default : 'chat.png',
    //                 lightTheme : 'chat.svg'
    //             },
    //             whoCanSee: 'ALL'
    //         }
    //     ]
    // },
    // {
    //     items: [
    //         {
    //             pageTitle: 'Notifications',
    //             link: '#',
    //             icon:  {
    //                 default : 'notifications_active',
    //                 lightTheme : 'notification-bell.svg'
    //             },
    //             whoCanSee: 'ALL'
    //         }
    //     ]
    // },
    // {
    //     items: [
    //         {
    //             pageTitle: 'Mon compte',
    //             link: pages.profile,
    //             icon:  {
    //                 default : 'account_circle',
    //                 lightTheme : 'account.svg'
    //             },
    //             whoCanSee: 'ALL'
    //         }
    //     ]
    // },
    // {
    //     items: [
    //         {
    //             pageTitle: 'Tutoriel',
    //             link: pages.about,
    //             icon:  {
    //                 default : 'info',
    //                 lightTheme : 'information.svg'
    //             },
    //             whoCanSee: 'ALL'
    //         }
    //     ]
    // }
];
