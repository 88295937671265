import { Component, OnInit } from '@angular/core';
import { UtilisService } from 'src/app/shared/services/utilis.service';
import { AnnuraireComponent } from './../annuraire/annuraire.component';

@Component({
  selector: 'app-annuraire-icon',
  templateUrl: './annuraire-icon.component.html',
  styleUrls: ['./annuraire-icon.component.scss']
})
export class AnnuraireIconComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit(): void {
  }

}
