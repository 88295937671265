import {Room} from '../models/room.model';
import {FORBIDDEN_SPECIALTIES} from '../const/glabals.const';
import {BufferProgram} from '../models/buffer-program.model';

export function checkDuplication(room: Room, attribute: string, index: number) {
  if (room) {
    const programs = room.programs || room.agendas;
    if (attribute && index > 0 && programs.length > index) {
      switch (attribute) {
        case 'anesthetist':
          // return !(programs[index - 1].anesthesiste && (programs[index].anesthesiste._id === programs[index - 1].anesthesiste._id));
          if ((<BufferProgram>programs[index - 1]).anesthesists && (<BufferProgram>programs[index]).anesthesists && (<BufferProgram>programs[index - 1]).anesthesists.length === (<BufferProgram>programs[index]).anesthesists.length) {
            for (const p1 of (<BufferProgram>programs[index - 1]).anesthesists) {
              const found = (<BufferProgram>programs[index]).anesthesists.find((p2) => String(p1._id) === String(p2._id));
  
              if (!found) {
                return true;
              }
            }
  
            for (const p1 of (<BufferProgram>programs[index]).anesthesists) {
              const found = (<BufferProgram>programs[index - 1]).anesthesists.find((p2) => String(p1._id) === String(p2._id));
  
              if (!found) {
                return true;
              }
            }
  
            return false;
          }
          return true;
        case 'nurses':
          if (!programs[index].nurses || !programs[index - 1].nurses) {
            return true;
          }
          return false;
        default:
          return true;
      }
    }
    return true;
  } else {
    return false;
  }
}

export function isForbiddenSpecialty(specialtyName: string) {
  return FORBIDDEN_SPECIALTIES.includes(specialtyName);
}
export function getCommonDayNumber(dayNumber: number) {
  return (dayNumber + 1) % 7 + 1;
}
