import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
  selector: "app-confirm-dialog",
  templateUrl: "./feed-room-dialog.component.html",
  styleUrls: ["./feed-room-dialog.component.scss"],
})
export class FeedRoomDialogComponent implements OnInit {
  public roomNumber: string;
  public room: any;
  public cssClass: string = "success";
  public isPopup: Boolean;
  public isOneRoom = true;
  public isoDate: string;

  constructor(
    private dialogRef: MatDialogRef<FeedRoomDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.isPopup = this.data.isPopup;
    this.room = this.data.room;
    this.isoDate = this.data.date;

    if (this.data.cssClass) {
      this.cssClass = this.data.cssClass;
    } else if (this.data.isBlueButton) {
      this.cssClass = "info";
    }
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  accept(): void {
      this.dialogRef.close(true);
  }
}
