import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Observable, Subscription} from 'rxjs';
import {ErrorService} from '../../services/error.service';
import {ValidateRulesService} from '../../services/validate-rules.service';
import {ValidateRule} from '../../models/validate-rule.model';
import * as moment from "moment";
import {DatePipe} from "@angular/common";
import { ANESTHETIST } from '../../const/glabals.const';
import { HospitalService } from '../../services/hospital.service';
import { ParamedicalService } from '../../services/paramedical.service';
import { Formation } from '../../models/formation.model';

@Component({
  selector: 'app-reason-dialog',
  templateUrl: './add-validate-rules-dialog.component.html',
  styleUrls: ['./add-validate-rules-dialog.component.scss']
})
export class AddValidateRulesDialogComponent implements OnInit, OnDestroy {

  public isSending: boolean;
  public formGroup: FormGroup;

  public showseniority: boolean = false;
  public seniorityValid: boolean = true;

  private formGroupChangeSubscription: Subscription;
  private editValidateRuleSubscription: Subscription;
  private addValidateRuleSubscription: Subscription;
  private formations: Formation[];
  public isLoading: boolean = true;
  public postes = [
    // {label: 'IBODE', value: 'IBODE', isChecked: false},
    // {label: 'IDE', value: 'IDE', isChecked: false},
    // {label: 'AS', value: 'AS', isChecked: false},
    {label: 'Anesthésiste', value: 'Anesthésiste', isChecked: false},
    {label: 'IADE', value: 'IADE', isChecked: false},
  ];

  public seniority = [
    {label: 'Sénior', value: 'Senior', isChecked: false, isDisabled: false},
    {label: 'Junior', value: 'Junior', isChecked: false, isDisabled: false},
    {label: 'Interne', value: 'Interne', isChecked: false, isDisabled: false},
  ];

  public residency = [
    {label: 'Titulaire', value: 'titulaire', isChecked: false},
    {label: 'Vacataire', value: 'vacataire', isChecked: false},
  ];

  public reccurence = [
    {
      label: 'Mensuelle',
      value: 'month',
    },
    {
      label: 'Annuelle',
      value: 'year',
    },
    {
      label: 'Aucune',
      value: 'none',
    }
  ];

  constructor(
    private hospitalService: HospitalService,
    private validateRulesService: ValidateRulesService, private formBuilder: FormBuilder, private errorService: ErrorService, private dialogRef: MatDialogRef<AddValidateRulesDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { mode: string, validateRule: ValidateRule }, 
              private paramedicalService: ParamedicalService) {
  }

  ngOnInit() {
    this.data
    this.getFormations().subscribe((formations: Formation[]) => {
      this.formations = formations;

      const nursesPostes = this.constructNursesPostesAndExcludeIade();
      this.postes.unshift(...nursesPostes);

      if (this.data.validateRule) {
        this.postes.filter(element => {
          if (this.data.validateRule.position.includes(element.value))
            element.isChecked = true;
        });
        this.residency.filter(element => {
          if (this.data.validateRule.residency.includes(element.value))
            element.isChecked = true;
        });
      }

      this.makeForm();

      this.formGroup.get('position').valueChanges.subscribe((val) => {
        this.showSeniority(val)
      })
      this.formGroup.get('seniority').valueChanges.subscribe((val) => {
        this.actualiseSeniority(val)
      })

      this.showSeniority(this.formGroup.value.postes)
      this.actualiseSeniority(this.formGroup.value.seniority) 
      this.isLoading = false;
    });    
  }

  constructNursesPostesAndExcludeIade(): any[] {
    const postes = [];

    for (const formation of this.formations) {
      if (formation.name.toLowerCase() !== 'iade') {
        postes.push({
          label: formation.name,
          value: formation.name,
          isChecked: false
        });
      }
    }

    return postes;
  }

  getFormations(): Observable<Formation[]> {
    return this.paramedicalService.getFormations();
  }

  close(): void {
    this.dialogRef.close(false);
  }

  showSeniority(val) {
    if (!val)
      return
    this.showseniority = val.includes(ANESTHETIST) && this.hospitalService.doesHospitalHaveAnesthDetailsOption()
    if (!this.formGroup.value.seniority)
      this.formGroup.value.seniority = []
    this.seniorityValid = !(this.showseniority && this.formGroup.value.seniority.length == 0)
  }

  actualiseSeniority(val) {
    if (!val)
      val = []
    this.seniorityValid = !(this.showseniority && val.length == 0)
  }

  makeForm() {
    this.formGroup = this.formBuilder.group({
      isCycling: [this.data.mode === 'edit' ? this.data.validateRule.isCycling : false, [Validators.required]],
      reccurence: [this.data.mode === 'edit' ? (this.data.validateRule.reccurence ? this.data.validateRule.reccurence : 'none') : 'none', [Validators.required]],
      startDateOfTheRule: [this.data.mode === 'edit' ? this.data.validateRule.startDateOfTheRule : '', [Validators.required]],
      endDateOfTheRule: [this.data.mode === 'edit' ? moment(this.data.validateRule.endDateOfTheRule).subtract(2, 'hours').format() : '', [Validators.required]],
      startDurationInterval: [this.data.mode === 'edit' ? this.data.validateRule.startDurationInterval : '', [Validators.required]],
      endDurationInterval: [this.data.mode === 'edit' ? moment(this.data.validateRule.endDurationInterval).subtract(2, 'hours').format() : '', [Validators.required]],
      // closedAt contains the date in which we desactivated this rule, in the front closedAt means isActif
      closedAt: [this.data.mode === 'edit' ? (!this.data.validateRule.closedAt) : true, [Validators.required]],
      position: [this.data.mode === 'edit' ? this.data.validateRule.position : '', [Validators.required]],
      seniority: [this.data.mode === 'edit' ? this.data.validateRule.seniority : []],
      residency: [this.data.mode === 'edit' ? this.data.validateRule.residency : '', [Validators.required]],
    });
  }

  formatDate(date, hour) {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), hour, 0, 0, 0)).toISOString();
  }

  save() {
    const formValue = this.formGroup.value;
    if (this.data.mode === 'edit') {
      const validateRule = {
        _id: this.data.validateRule._id,
        ...formValue,
      };
      validateRule.closedAt = !validateRule.closedAt ? moment().format() : null;
      validateRule.reccurence = validateRule.reccurence === 'none' ? null : validateRule.reccurence;
      validateRule.startDateOfTheRule = this.formatDate(new Date(validateRule.startDateOfTheRule), 0);
      validateRule.endDateOfTheRule = this.formatDate(new Date(validateRule.endDateOfTheRule), 23);
      validateRule.startDurationInterval = this.formatDate(new Date(validateRule.startDurationInterval), 0);
      validateRule.endDurationInterval = this.formatDate(new Date(validateRule.endDurationInterval), 23);

      this.editValidateRule(validateRule);

    } else {
      const validateRule = {
        ...formValue
      };
      validateRule.closedAt = !validateRule.closedAt ? moment().format() : null;
      validateRule.reccurence = validateRule.reccurence === 'none' ? null : validateRule.reccurence;
      validateRule.startDateOfTheRule = this.formatDate(validateRule.startDateOfTheRule, 0);
      validateRule.endDateOfTheRule = this.formatDate(validateRule.endDateOfTheRule, 23);
      validateRule.startDurationInterval = this.formatDate(validateRule.startDurationInterval, 0);
      validateRule.endDurationInterval = this.formatDate(validateRule.endDurationInterval, 23);


      this.addValidateRule(validateRule);
    }
  }

  addValidateRule(validateRule) {
    this.isSending = true;
    if (!validateRule.position.includes(ANESTHETIST))
      validateRule.seniority = []
    this.addValidateRuleSubscription = this.validateRulesService.addValidateRule(validateRule).subscribe(res => {
      this.isSending = false;
      this.dialogRef.close(true);
    }, error => this.errorService.handleError(error));
  }

  editValidateRule(validateRule) {
    this.isSending = true;
    this.editValidateRuleSubscription = this.validateRulesService.editValidateRule(validateRule).subscribe(res => {
      this.isSending = false;
      this.dialogRef.close(true);
    }, error => this.errorService.handleError(error));
  }

  ngOnDestroy() {
    if (this.formGroupChangeSubscription) {
      this.formGroupChangeSubscription.unsubscribe();
    }
    if (this.editValidateRuleSubscription) {
      this.editValidateRuleSubscription.unsubscribe();
    }
    if (this.addValidateRuleSubscription) {
      this.addValidateRuleSubscription.unsubscribe();
    }
  }
}
