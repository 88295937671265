import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultReasonDal } from '../dal/defaultReason.dal';
import { Reason } from '../models/reason.model';

@Injectable()

export class DefaultReasonService {

  constructor(private defaultReasonDal: DefaultReasonDal) {
  }

  getAllReasons(){
    return this.defaultReasonDal.getAllReasons();
  }

  addReason(reason){
    return this.defaultReasonDal.addReason(reason);
  }

  editReason(reason : Reason){
    return this.defaultReasonDal.editReason(reason);
  }

}