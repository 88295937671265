import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-drag-drop',
  templateUrl: './drag-drop.component.html',
  styleUrls: ['./drag-drop.component.scss']
})
export class DragDropComponent implements OnInit {
  public items = ['Item 1', 'Item 2', 'Item 3', 'Item 4'];
  
  constructor() { }

  ngOnInit() {
  }

}
