import { Injectable } from "@angular/core";
import { Room } from "../models/room.model";
import { BufferProgram } from "../models/buffer-program.model";
import { ToastService } from "./toast.service";
import {
  NURSE_TYPES,
  CONFIRMATION_MESSAGE,
  components,
  DATE_WEEK,
  DATE_DAY,
  IADE,
  ANESTHETIST_TYPES,
  DATE_FROM,
  DATE_TO,
} from "../const/glabals.const";
import { map } from "rxjs/operators";
import { MatDialog } from "@angular/material";
import { isMoment } from "moment";
import * as moment from "moment";
import { ProfileService } from "src/app/shared/services/profile.service";
import { UserService } from "./user.service";
import { ErrorService } from "./error.service";
import {PopupManagerService} from "./popup-manager.service";
import { roomBufferPrograms } from "../interfaces/buffer-program.interfaces";
import { getFirstHospitalSelectedData } from "../utils/cross-functions";
import { Subject } from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class UtilisService {

  private sidenavToggleSubject = new Subject<void>();

  sidenavToggle$ = this.sidenavToggleSubject.asObservable();

  toggleSidenav() {
    setTimeout(() => {
      this.sidenavToggleSubject.next();
    }, 1); 
  }

  getTodayDate() {
    var d = new Date(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }
  public isFullscreen: boolean = false;
  private DEFAULT_MSG = "L'opération a été prise en compte";
  constructor(
    private userService: UserService,
    private profileService: ProfileService,
    private toastService: ToastService,
    private dialog: MatDialog,
    private errorService: ErrorService,
    private popupManagerService: PopupManagerService
  ) {}

  moveFirstItemToLastPosition(array: any[]) {
    const firstItem = array[0];
    array.splice(0, 1);
    array.push(firstItem);
  }

  reverse(forwardString: string): string {
    return forwardString.split("-").reverse().join("-");
  }

  eAccentToE(str: string) {
    if (!str) return '';
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  getMorningPrograms(room: Room): BufferProgram[] {
    const selectedHospital = getFirstHospitalSelectedData(this.userService.getCurrentUser(), this.userService.getSelectedHospitals())
    const hospitalStartTimeString = this.getStartTime(selectedHospital.startTime);
    const hospitalStartTimeNumber = parseInt(hospitalStartTimeString.replace(":", ""), 10);

    const hospitalMiddleTimeString = this.getStartTime(selectedHospital.middleTime);
    const hospitalMiddleTimeNumber = parseInt(hospitalMiddleTimeString.replace(":", ""), 10);
    return room.programs.filter((program) => {
      const startTimeString = this.getStartTime(program.startTime);
      const startTimeNumber = parseInt(startTimeString.replace(":", ""), 10);

      const endTimeString = this.getStartTime(program.endTime);
      const endTimeNumber = parseInt(endTimeString.replace(":", ""), 10);
      return startTimeNumber >= hospitalStartTimeNumber && startTimeNumber <= hospitalMiddleTimeNumber && endTimeNumber <= hospitalMiddleTimeNumber;
    });
  }

  getDayPrograms(room: Room): BufferProgram[] {
    const selectedHospital = getFirstHospitalSelectedData(this.userService.getCurrentUser(), this.userService.getSelectedHospitals())
    
    const hospitalMiddleTimeString = this.getStartTime(selectedHospital.middleTime);
    const hospitalMiddleTimeNumber = parseInt(hospitalMiddleTimeString.replace(":", ""), 10);
    return room.programs.filter((program) => {
      const startTimeString = this.getStartTime(program.startTime);
      const startTimeNumber = parseInt(startTimeString.replace(":", ""), 10);

      const endTimeString = this.getStartTime(program.endTime);
      const endTimeNumber = parseInt(endTimeString.replace(":", ""), 10);
      return startTimeNumber < hospitalMiddleTimeNumber && endTimeNumber > hospitalMiddleTimeNumber;
    });
  }

  getAfternoonPrograms(room: Room): BufferProgram[] {
    const selectedHospital = getFirstHospitalSelectedData(this.userService.getCurrentUser(), this.userService.getSelectedHospitals())
    
    const hospitalEndTimeString = this.getStartTime(selectedHospital.endTime);
    const hospitalEndTimeNumber = parseInt(hospitalEndTimeString.replace(":", ""), 10);

    const hospitalMiddleTimeString = this.getStartTime(selectedHospital.middleTime);
    const hospitalMiddleTimeNumber = parseInt(hospitalMiddleTimeString.replace(":", ""), 10);
    return room.programs.filter((program) => {
      const startTimeString = this.getStartTime(program.startTime);
      const startTimeNumber = parseInt(startTimeString.replace(":", ""), 10);

      const endTimeString = this.getStartTime(program.endTime);
      const endTimeNumber = parseInt(endTimeString.replace(":", ""), 10);
      return startTimeNumber >= hospitalMiddleTimeNumber && startTimeNumber <= hospitalEndTimeNumber && endTimeNumber <= hospitalEndTimeNumber;
    });
  }

  getStartTime(date: any): string {
    let d = new Date(date);
    d.setTime(d.getTime() + d.getTimezoneOffset() * 60 * 1000);
    return `${d.getHours()}:${d.getMinutes() === 0 ? "00" : d.getMinutes()}`;
  }

  isBPsHaveSameTeam(room: roomBufferPrograms, so?: boolean): boolean {
    if (!room.programs) return false;
    if (room.programs.length === 1) {
      return true;
    }
    //return false;
    // if (room.programs[0].surgeon._id == room.programs[1].surgeon._id) {
    //   return false;
    // }
    return (
      this.isBPsHaveSameProfil(room, "nurses", so) &&
      this.isBPsHaveSameProfil(room, "anesthetist")
    );
  }

  isBPsHaveSameProfil(room: any, prop: string, so?: boolean): boolean {
    let result = true;
    let leastOne = false;

    if (so) {
      room.surgeonOpenings.forEach((p) => {
        result = !p[prop] && result;
      });
      if (result) return result;
    } else {
      room.programs.forEach((p) => {
        result = !p[prop] && result;
      });
      if (result) return result;
    }

    result = true;
    if (prop === 'nurses') {
      if (so) {
        const nurses = room.surgeonOpenings[0].nurses;
        room.surgeonOpenings.forEach((p) => {
          if (p.nurses.length != nurses.length) {
            result = false;
          }
          p.nurses.forEach(nurse => {
            if (nurse && nurse.profile) {
              const p2 = nurses.find((nurse_) => String(nurse_.profile._id) === String(nurse.profile._id))
              if (p2 && p2.role) {
                result = p2 && String(p2.role) === String(nurse.role) && result;
                leastOne = p2 && String(p2.role) !== String(nurse.role)
              } else {
                result = false;
              }
            } else {
              result = false;
            }
          });
        });
      } else {
        const nurses = room.programs[0].nurses;
        room.programs.forEach((p) => {
          if (p.nurses.length != nurses.length) {
            result = false;
          }

          p.nurses.forEach(nurse => {
            if (nurse && nurse.profile) {
              const p2 = nurses.find((nurse_) => String(nurse_.profile._id) === String(nurse.profile._id))
              if (p2 && p2.role && nurse.role) {
                result = p2 && String(p2.role._id) === String(nurse.role._id) && result;
                leastOne = p2 && String(p2.role) !== String(nurse.role)
              } else {
                result = false;
              }
            } else {
              result = false;
            }
          });
        });
      }
    } else {
      const profil = room.programs[0][prop];
      room.programs.forEach((p) => {
        if (p[prop] && profil) {
          result = p[prop]._id == profil._id && result;
        } else {
          result = false;
        }
      });
    }

    return leastOne ? !leastOne : result;
  }

  displaySuccessToast() {
    this.toastService.infoToast(this.DEFAULT_MSG);
  }

  openPopup(component, config): any {
    const dialogRef = this.dialog.open(component, config);
    return dialogRef;
  }

  getRequestsByPosition(allRequests: any[], position: string): any[] {
    let pos = position == "ANE" ? ANESTHETIST_TYPES : NURSE_TYPES;
    if (this.userService.isIadRes()) pos = IADE;
    return allRequests.filter((el) => pos.includes(el.profile.position));
  }

  openUnSavedChangesPopup() {
    return this.popupManagerService.openUnsavedChangesPopup(false).afterClosed().pipe(
      map((result) => {
        switch (result) {
          case "save":
            return false;
          case "cancel":
            return "cancel";
          case "continue":
            return true;
          default:
            return 'cancel';
        }
      })
    );
  }

  saveDate(component: any, day: any) {
    const compName = component.constructor.name;
    let formattedDate = day;
    if (day) {
      if (isMoment(day)) {
        formattedDate = day.format("YYYY-MM-DD");
      } else {
        const d = new Date(day);
        formattedDate = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
      }
    } else {
      const d = new Date();
        formattedDate = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    }
    localStorage.setItem(this.getKey(compName), formattedDate);
  }

  getDate(component: string) {
    const savedDate = this.getSavedDate(this.getKey(component));
    if (components.RECAP_COMPONENT == component) return savedDate;

    return savedDate ? savedDate : this.getCurrentDate();
  }

  getCurrentDate() {
    const momentDate: moment.Moment = moment.utc();
    return momentDate.clone();
  }

  getKey(component: string) {
    if (
      component == components.INFIRMIER_COMPONENT ||
      component == components.SURGEON_COMPONENT ||
      component == components.ANE_COMPONENT ||
      component == components.SP_COMPONENT
    )
      return DATE_WEEK;
    else return DATE_DAY;
  }

  getSavedDate(varName) {
    const savedDate = localStorage.getItem(varName);
    if (savedDate && savedDate != "undefined" && savedDate != "Invalid date") {
      return moment.utc(new Date(localStorage.getItem(varName)));
    }
    return null;
  }

  openConfirmationPopup(dialog, component: any, message: string, config?) {
    const dialogRef = this.dialog.open(component, {
      width: "400px",
      height: config.height ? config.height : "150px",
      panelClass: config.panelClass,
      data: {
        cssClass: "danger",
        message: message,
        title: config.title,
      },
    });
    return dialogRef;
  }

  getOnlyIadeRequests(requests: any): any {
    return requests.filter((request) => {
      return this.profileService.isProfileIade(request.profile);
    });
  }

  DateToString(date: Date): string {
    var mm = date.getMonth() + 1; // getMonth() is zero-based
    var dd = date.getDate();

    return [
      date.getFullYear(),
      (mm > 9 ? "" : "0") + mm,
      (dd > 9 ? "" : "0") + dd,
    ].join("-");
  }

  getDayName(date) {
    var days = [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
    ];
    var d = new Date(date);
    return days[d.getDay()];
  }

  formatDate(date) {
    return this.reverse(date).replace(/-/g, "/");
  }

  getNexDay(x) {
    var now = new Date();
    now.setDate(now.getDate() + ((x + (7 - now.getDay())) % 7));
    return now;
  }

  addDays(theDate, days) {
    return new Date(theDate.getTime() + days * 24 * 60 * 60 * 1000);
  }

  getDateFromTo() {
    const savedFromDate = this.getSavedDate(DATE_FROM);
    const savedToDate = this.getSavedDate(DATE_TO);
    const defaultFromDate = this.getCurrentDate().add(1, "d");
    const defaultToDate = this.getCurrentDate().add(8, "d");
    return savedFromDate && savedToDate
      ? { fromDate: savedFromDate, toDate: savedToDate }
      : { fromDate: defaultFromDate, toDate: defaultToDate };
  }

  saveFromToDate(fromDate: any, toDate) {
    let formattedFromDate = moment(fromDate).format("YYYY-MM-DD");
    let formattedToDate = moment(toDate).format("YYYY-MM-DD");
    localStorage.setItem(DATE_FROM, formattedFromDate);
    localStorage.setItem(DATE_TO, formattedToDate);
  }

  formatDate2(date, type?) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    if (type) return [day, month, year].join("-");
    return [year, month, day].join("-");
  }

  getDayWithoutTime(d) {
    return new Date(d.getFullYear(), d.getMonth(), d.getDate());
  }

  getMonday(d) {
    d = this.getDayWithoutTime(new Date(d));
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  getSunday(d) {
    d = this.getDayWithoutTime(new Date(d));
    var day = d.getDay(),
      diff = d.getDate() + (day == 0 ? 0 : 7 - day); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  addNDaysToDate(dateArgument, Days) {
    var date = new Date(dateArgument);
    date.setDate(date.getDate() + Days);
    return date;
  }

  isDateSmallThanToday(date) {
    var ToDate = new Date();
    ToDate.setHours(0, 0, 0, 0);
    if (new Date(date).getTime() < ToDate.getTime()) {
      return true;
    }
    return false;
  }

  isDatesEqual(date1, date2) {
    var d1 = new Date(date1);
    var d2 = new Date(date2);
    return d1.getTime() === d2.getTime();
  }

  getLastWeek() {
    var beforeOneWeek = new Date(
        new Date().getTime() - 60 * 60 * 24 * 7 * 1000
      ),
      day = beforeOneWeek.getDay(),
      diffToMonday = beforeOneWeek.getDate() - day + (day === 0 ? -6 : 1),
      lastMonday = new Date(beforeOneWeek.setDate(diffToMonday)),
      lastSunday = new Date(beforeOneWeek.setDate(diffToMonday + 6));
    return [lastMonday, lastSunday];
  }

  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
      };
      img.onerror = error => {
        reject(error);
      };
      img.src = url;
    });
  }

}

export function getFormatLTS(dateTime : string | Date): string{
  const date: Date = new Date(dateTime);
  let hours = date.getUTCHours() < 10 ? '0'+ date.getUTCHours() : date.getUTCHours();
  let minutes = date.getUTCMinutes() < 10 ? '0'+ date.getUTCMinutes() : date.getUTCMinutes();
  return hours + ':' + minutes
}
