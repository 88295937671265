import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';

import {Hospital} from '../models/hospital.model';
import {Option} from '../models/option.model';
import {OptionDal} from '../dal/option.dal';
import { UserService } from './user.service';
import { OPTIONS } from '../const/glabals.const';
import { getFirstHospitalSelectedData } from '../utils/cross-functions';

@Injectable()
export class OptionService {

  constructor(private optionDal: OptionDal, private userService: UserService) {
  }

  removeOptionToHospitalSelected(optionName?: string, softwareName?: string): Observable<any> {
    const currentUser = this.userService.getCurrentUser();
    const hospital = getFirstHospitalSelectedData(currentUser, this.userService.getSelectedHospitals());
    return this.optionDal.removeOptionToHospital(hospital._id, optionName, softwareName);
  }

  updateOptionsToHospital(optionIds: string[], hospitalId: string): Observable<any> {
    // const currentUser = this.userService.getCurrentUser();
    // const hospital = getFirstHospitalSelectedData(currentUser, this.userService.getSelectedHospitals());
    return this.optionDal.updateOptionsToHospital(optionIds, hospitalId);
  }

  getAllOptions(): Observable<Option[]> {
    return this.optionDal.getAllOptions();
  }

  getOptionsWithSoftwareList(): Observable<any> {
    return this.optionDal.getOptionsWithSoftwareList();
  }
}
