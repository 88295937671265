import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import * as moment from "moment";
import { ANESTHETIST } from "src/app/shared/const/glabals.const";
import { BufferProgram } from "src/app/shared/models/buffer-program.model";
import { Profile } from "src/app/shared/models/profile.model";
import { Room } from "src/app/shared/models/room.model";
import { Specialty } from "src/app/shared/models/specialty.model";
import { SO } from "src/app/shared/models/surgeonOpenings.model";
import { FormatSeniorityPipe } from "src/app/shared/pipes/format-seniority.pipe";
import { ExportService } from "src/app/shared/services/export.service";
import { HospitalService } from "src/app/shared/services/hospital.service";
import { UserService } from "src/app/shared/services/user.service";
import { getFormatLTS, UtilisService } from "src/app/shared/services/utilis.service";
import { capitalize } from "src/app/shared/utils/cross-functions";
import { themeStyling, themeStylingColors } from "src/themes/common/principal-theme";

@Injectable()
export class PrintService {

  public CONSULTATION_STR = 'CONSULTATION'
  public BLOC_STR = 'BLOC'
  public EXTRACLINIQUE_STR = 'EXTRACLINIQUE'

  public programsCount: number = 0;
  
  public pageBreak: boolean = false;
  public doesHospitalHaveAnesthDetailsOption: boolean;

  constructor(
    private UtilisService: UtilisService,
    private datePipe: DatePipe,
    private exportService: ExportService,
    private userService: UserService,
    private hospitalService: HospitalService,
    private formatSeniorityPipe: FormatSeniorityPipe,
  ) { 
    this.doesHospitalHaveAnesthDetailsOption = this.hospitalService.doesHospitalHaveAnesthDetailsOption();
  }

  buildDocument(fileName, content,logo:any):any{
    return {
      pageSize: 'A2',
      pageOrientation: 'landscape',
      pageMargins: [40, 130, 40, 40],
      info: {
        title: fileName,
        author: 'SmartOp',
        subject: 'Planning'
      },
      header: function(currentPage, pageCount) {
        return [
          {
            image: logo,
            width: 150,
            margin: [30, 10, 0, 10],
            alignment: 'left',
          },
        ];
      },
      content,
      defaultStyle: {
        fontSize: 12,
        font: 'Inter'
      },
      styles: {
        futuraBold: {
          font: 'Inter',
          bold: true,
          alignment: 'center'
        },
        tableStyle : {
          margin: [40, 40, 0, 750]
        },
        smallText: {
          font: 'Inter',
          fontSize: 10,
          italic: true,
          alignment: 'left'
        },
        dayHeader: {
          font : 'Inter',
          fontSize: 22,
          color : '#173753',
          alignment: 'center'
        },
        interventionHeader: {
          font : 'Inter',
          fontSize: 19,
          color : '#173753',
          alignment: 'left',
          decoration: 'underline',
          marginTop: 20
        },
        tableHeader: {
          font : 'Inter',
          fontSize: 18,
          color : '#173753',
          alignment: 'left',
        },
        roomTitle: {
          font: 'Inter',
          color : themeStylingColors.color_room_number,
          fontSize: 18,
          bold: true,
          alignment: 'left',
          margin: [0, 10, 0, 0]
        },
        teamTitle: {
          font: 'Inter',
          color : '#173753',
          fontSize: 15,
          alignment: 'left',
          decoration: 'underline',
          margin: [0, 10, 0, 0]
        },
        roomSubTitle:{
          fontSize: 12,
          color : '#66727c',
          font: 'Inter',
          margin: [0, 0, 0, 10]
        },
        profileText: {
          font: 'Inter',
          fontSize: 15,
          color : '#173753',
          margin: [60, 10, 0, 0]
        },
        profileFooterText: {
          font: 'Inter',
          fontSize: 15,
          color : '#173753',
          margin: [40, 65, 0, 0]
        },
        profileSubText:{
          fontSize: 11,
          color : '#66727c',
          font: 'Inter',
          margin: [0, 0, 0, 0]
        },
        profileSubTextTime:{
          fontSize: 11,
          color : '#66727c',
          font: 'Inter',
          bold : true,
          margin: [0, 0, 0, 0]
        },
        profileSubTextRole:{
          fontSize: 11,
          color : '#66727c',
          font: 'Inter',
          bold : true,
          margin: [0, 0, 0, 0]
        },
        footerTitle: {
          font: 'Inter',
          color : themeStylingColors.color_room_number,
          fontSize: 18,
          bold: true,
          alignment: 'left',
          margin: [30, 65, 0, 30]
        },
        footerBG : {
          fillColor : '#dddddd',
        },
        comment: {
          font: 'Inter',
          fontSize: 17,
          color : '#173753',
          margin:[30,15,0,0]
        },
        image : {
          margin : [20,5,20,5],
          width: 50,
          height: 50
        },
        specInfos:{
          fontSize: 13,
          color : '#66727c',
          font: 'Inter',
          margin: [60, 0, 0, 10]
        },
      }
    };
  }

  buildPageHeader(date:Date,isBloc:boolean): any{
    if(isBloc){
      return {
        alignment: 'center',
        text:
          'Programme du '+
          this.UtilisService.getDayName(date)+
          ' - '+
          moment(date).format('DD/MM/YYYY')
        ,style: 'dayHeader'
      }
    }else{
      return {
        alignment: 'center',
        text:
          'Programme du '+
          this.UtilisService.getDayName(date)+
          ' - '+
          moment(date).format('DD/MM/YYYY')
        ,style: 'dayHeader',
        pageBreak: 'before'
      }
    }
      

   
  }

  buildInterventionTypeHeader(type:string): any{
    return {
      alignment: 'center',
      text:
        type,
      style: 'interventionHeader'
    }
  }

  public async constructContent(allDataToExport: {extracliniqueObject,consultationsObjects, mergedRooms, outOfRoomsNurses: Profile[], outOfRoomsIades: Profile[],comment, date}[]): Promise<any> {
    const content = [];
    for (let index = 0; index < allDataToExport.length; index++) {
      const dataToExport = allDataToExport[index];
      content.push(this.buildPageHeader(dataToExport.date,true));

      //First Table - Bloc
      await content.push(await this.buildTable(dataToExport.mergedRooms, dataToExport.outOfRoomsNurses,dataToExport.comment,this.BLOC_STR));
      await content.push(await this.buildTableFooter(dataToExport.outOfRoomsNurses,dataToExport.outOfRoomsIades, dataToExport.comment));
      await content.push(await this.buildTableFooterComment(dataToExport.comment));


      if(this.hospitalService.doesHospitalHaveAnesthDetailsOption()){
        
        //Second Table - Consultation
        content.push(this.buildPageHeader(dataToExport.date,false))
        await content.push((await this.buildTable(this.getConsultationExtracliniqueFullPrgs(dataToExport.consultationsObjects),null,dataToExport.comment,this.CONSULTATION_STR)));


        //Second Table - Extraclinique
        content.push(this.buildPageHeader(dataToExport.date,false))
        await content.push(await this.buildTable(this.getConsultationExtracliniqueFullPrgs(dataToExport.extracliniqueObject),null,dataToExport.comment,this.EXTRACLINIQUE_STR));
      }
      
    }
    return content;
  }

  public async exportOnePlanning(content: any, date: Date): Promise<any> {
    // Export document
    const fileName = this.userService.getCurrentUser().profile.hospitals[0].hospitalName +
      '-planning-du-' +
      this.datePipe.transform(date, 'dd-MM-yyyy');
      const logo = await this.UtilisService.getBase64ImageFromURL('assets/images/logos/logo_planning.png')
    let docDefinition = this.buildDocument(fileName, content,logo)
    this.exportService.exportPDF(docDefinition, fileName);
  }

  public async exportManyPlanningsIntoOneFile(content: any, startDate: Date, endDate: Date): Promise<any> {
    // Export document
    const logo = await this.UtilisService.getBase64ImageFromURL('assets/images/logos/logo_planning.png')

    const fileName = this.userService.getCurrentUser().profile.hospitals[0].hospitalName +
      '-plannings-du-' +
      this.datePipe.transform(startDate, 'dd-MM-yyyy') + '-au-' + this.datePipe.transform(endDate, 'dd-MM-yyyy');
    let docDefinition = this.buildDocument(fileName, content,logo)
    this.exportService.exportPDF(docDefinition, fileName);
  }

  getTabHeader():any[]{
    let header = [];

    header.push(
      {alignment: 'left', text: '', style: 'tableHeader'},
      {alignment: 'left', text: 'Chirurgien(s)', style: 'tableHeader'},
    );

    header.push(
      { alignment: 'left', text: 'Anesthésiste', style: 'tableHeader' },
    );

    header.push({
      stack: [
        {alignment: 'left', text: 'Paramédical', style: 'tableHeader'}
      ]
    });
    
    return header;
  }

  getConsultationExtracliniqueTabHeader(type:string):any[]{
    if(type === this.CONSULTATION_STR)
    {
      let header = [];
    header.push(
      {alignment: 'left', text: 'Spécialités', style: 'tableHeader'},
      {alignment: 'left', text: 'Journée', style: 'tableHeader'},
      {alignment: 'left', text: 'Matin', style: 'tableHeader'},
      {alignment: 'left', text: 'Après-midi', style: 'tableHeader'}
    );  
    return header;
    }
    let header = [];
    header.push(
      {alignment: 'left', text: '', style: 'tableHeader'},
      {alignment: 'left', text: 'Journée', style: 'tableHeader'},
      {alignment: 'left', text: 'Matin', style: 'tableHeader'},
      {alignment: 'left', text: 'Après-midi', style: 'tableHeader'}
    );  
    return header;
  }

  async buildBody(allPrograms:any[], outOfRoomsNurses:Profile[],comment:string){
    const body = [];
    body.push(this.getTabHeader());
    for (let index = 0; index < allPrograms.length; index++) {
      const prog = allPrograms[index];
      if(prog.programs)
        await this.buildProgramRow(prog.roomNumber,prog.programs).then( progRow=>body.push(progRow));
      else
        await this.buildSORow(prog.roomNumber,prog.surgeonOpenings).then( soRow=> body.push(soRow));
    }

    return body;
  }

  async buildConsultationExtracliniqueBody(allPrograms:any[],type:string){
    const body = [];
    body.push(this.getConsultationExtracliniqueTabHeader(type));
    for (let index = 0; index < allPrograms.length; index++) {
      const prog = allPrograms[index];
      if(prog)
        await this.buildConsultationExtracliniqueProgramRow(prog,type).then( progRow=>body.push(progRow)); 
    }
    return body;
  }

  async buildTable(data :any[], outOfRoomsNurses:Profile[], comment:string, type:string){
    let widths: number[];
    let widths2: number[];

    if (this.hospitalService.doesHospitalHaveAnesthDetailsOption()) {
      widths = [200, 250, 250, 750];
      widths2 = [375, 375, 375, 375, 0, 0];
    } else {
      widths = [250, 300, 300, 600];
    }

    switch(type){
      case this.BLOC_STR:
        return [
          //return header (BLOC , CONSULTATION....)
          {
            alignment: 'center',
            text:
              type,
            style: 'interventionHeader'
          },
          //return body content
          {
            style: 'tableStyle',
            table: {
              headerRows: 1,
              widths,
              body: await this.buildBody(data, outOfRoomsNurses,comment),
            },
            layout: 'lightHorizontalLines',
          }
        ];
      case this.CONSULTATION_STR :
        return [
          {
            alignment: 'center',
            text:
              type,
            style: 'interventionHeader'
          },
          {
            style: 'tableStyle',
                table: {
                widths: widths2,
                headerRows: 1,
                body: await this.buildConsultationExtracliniqueBody(data,this.CONSULTATION_STR),
                alignment: "left"
                },
                layout: 'lightHorizontalLines',
        }
        ];
      case this.EXTRACLINIQUE_STR:
        return [
          {
            alignment: 'center',
            text:
              type,
            style: 'interventionHeader',
          },
          {
              style: 'tableStyle',
                  table: {
                  widths: widths2,
                  headerRows: 1,
                  body: await this.buildConsultationExtracliniqueBody(data,this.EXTRACLINIQUE_STR),
                  alignment: "left"
                  },
                  layout: 'lightHorizontalLines'
          },  
        ];
    }
    
  }

  async buildTableFooter(outOfRoomsNurses:Profile[],outOfRoomsIades:Profile[], comment:string): Promise<any>{
    const rowTab = [];
    
    const rowHeader : any = [{
      text: 'Hors salle ',
      style: 'footerTitle',
      pageBreak: 'before'
    }]

    rowTab.push(rowHeader);


    const profilesCol1 = [];
    const profilesCol2 = [];
    const profilesCol3 = [];
    const profilesCol4 = [];
    const profilesCol5 = [];

    let numberOfColumns;

    if (this.hospitalService.doesHospitalHaveAnesthDetailsOption()) {
      numberOfColumns = 5;
    } else {
      numberOfColumns = 4;
    }

    const outOfRoomsNursesIades = outOfRoomsNurses.concat(outOfRoomsIades);
    this.sortOutOfRoomsByPositionAndHours(outOfRoomsNursesIades);
    
    for (let index = 0; index < outOfRoomsNursesIades.length; index= index + numberOfColumns) {
      await this.buildFooterOneProfile(outOfRoomsNursesIades[index],index).then( footerOneProfile=> profilesCol1.push(footerOneProfile));
      if(outOfRoomsNursesIades[index+1])
        await this.buildFooterOneProfile(outOfRoomsNursesIades[index+1],index).then( footerOneProfile=> profilesCol2.push(footerOneProfile));
      if(outOfRoomsNursesIades[index+2])
        await this.buildFooterOneProfile(outOfRoomsNursesIades[index+2],index).then( footerOneProfile=> profilesCol3.push(footerOneProfile));
      if(outOfRoomsNursesIades[index+3])
        await this.buildFooterOneProfile(outOfRoomsNursesIades[index+3],index).then( footerOneProfile=> profilesCol4.push(footerOneProfile));

      if (numberOfColumns === 5) {
        await this.buildFooterOneProfile(outOfRoomsNursesIades[index + 4],index).then( footerOneProfile=> profilesCol5.push(footerOneProfile));
      }
    }

    rowTab.push(profilesCol1,profilesCol2,profilesCol3,profilesCol4);

    if (numberOfColumns === 5) {
      rowTab.push(profilesCol5);
    }

   return {
      columns: rowTab.map(item => ({
        width: '*',
        stack: [item]
      })),
      layout: 'stack'
    }
  }

  sortOutOfRoomsByPositionAndHours(outOfRoomsNursesIades) {
    const compareProfiles = (profileA, profileB) => {
      const groupOrder = { IBODE: 1, IDE: 1, AS: 1, Iade: 2 };
      const groupA = groupOrder[profileA.position];
      const groupB = groupOrder[profileB.position];
      
      if (groupA !== groupB) {
        return groupA - groupB;
      }
  
      if (groupA === 1) {
        const startTimeA = profileA.startTime;
        const startTimeB = profileB.startTime;
        if (startTimeA !== startTimeB) {
          return startTimeA.localeCompare(startTimeB);
        }
  
        const firstNameA = profileA.firstName;
        const firstNameB = profileB.firstName;
        return firstNameA.localeCompare(firstNameB);
      } else {
        const startTimeA = profileA.startTime;
        const startTimeB = profileB.startTime;
        if (startTimeA !== startTimeB) {
          return startTimeA.localeCompare(startTimeB);
        }
  
        const firstNameA = profileA.firstName;
        const firstNameB = profileB.firstName;
        return firstNameA.localeCompare(firstNameB);
      }
    };
    outOfRoomsNursesIades.sort(compareProfiles);
  }

  async buildTableFooterComment(comment:string): Promise<any>{
    const rowTab = [];
    
    const rowHeader : any = [{
      text: 'Commentaire ',
      style: 'footerTitle',
      margin: [30,15,0,0],
      width:200
    }]


    rowTab.push({text: comment, style: 'comment', width:500});
    let result = rowHeader;
    result.push({
      columns: rowTab.map(item => ({
        stack: [item]
      })),
      layout: 'stack',
    });

   return result
  }


  async buildProgramRow(roomNumber:string,roomPrograms:BufferProgram[]){
    const firstProgram = roomPrograms[0];
    const rowTab = [];

    // room pageBreak solution___________________________
    this.programsCount += roomPrograms.length;
    if((roomPrograms.length < 5 && this.programsCount > 5)){
      // this.pageBreak = true;
      this.programsCount = roomPrograms.length;
    }else{
      this.pageBreak = false;
    }

    rowTab.push(this.buildRoomTeamsTitle(roomNumber, roomPrograms.length));

    await this.buildSurgeons(roomPrograms).then(
      surgons=> {
        rowTab.push([surgons])
      });

    if (this.hospitalService.doesHospitalHaveAnesthDetailsOption()) {
      await this.buildAnesths(roomPrograms).then(
        anesth => {
          rowTab.push([anesth]);
      });
      
    } else {
      rowTab.push(await this.buildOneProfile(firstProgram.anesthesists[0],firstProgram))
    }
    await this.buildParamedical(roomPrograms).then(
      nurses => {
        rowTab.push([nurses]);
    });
    return rowTab;
  }

  async buildConsultationExtracliniqueProgramRow(prog:any,type:string){
    const rowTab = [];
    if(type === this.CONSULTATION_STR){
      // for consultation
      await this.buildOneSpecialty(prog,this.CONSULTATION_STR).then( oneProfile=>rowTab.push(oneProfile));

    }else{ // for extraclinique
      await this.buildOneSpecialty(prog,this.EXTRACLINIQUE_STR).then( oneProfile=>rowTab.push(oneProfile));

    }
    await this.ConsultationExtraclinique(prog.dayPrograms).then( anesth=>rowTab.push([anesth])); // show dayPrograms 'Journée'
    await this.ConsultationExtraclinique(prog.morningPrograms).then( anesth=>rowTab.push([anesth])); // show morningPrograms 'Matin'
    await this.ConsultationExtraclinique(prog.afternoonPrograms).then( anesth=>rowTab.push([anesth])); // show afternoonPrograms 'Après-midi'

    return rowTab;
  }


  async buildSORow(roomNumber:string,roomSO:SO[]): Promise<any>{
    const rowTab = [];
    rowTab.push(this.buildRoomTeamsTitle(roomNumber,1));
    await this.buildSurgeons(roomSO).then( surgons=>rowTab.push([surgons]));
    await this.buildOneProfile().then( oneProfile=>rowTab.push(oneProfile));
    await this.buildOneProfile().then( oneProfile=>rowTab.push(oneProfile));
    return rowTab;
  }

  buildRoomTeamsTitle(roomNumber: string, programsNbr:number): any[]{
    const titlesTab = [];
    titlesTab.push(
      {fillColor : 'red', text: 'Salle '+ roomNumber+':', style: 'roomTitle'}
    );
    const allTeams = [];
    for (let index = 0; index < programsNbr; index++) {
       for(let i = 0 ; i<2; i++){
        if(i==0 && programsNbr > 1)
          index !=0
          ?
            titlesTab.push(
              {text: 'Equipe '+ (index+1), style: 'teamTitle',margin: [0, 50, 0, 0]
            }
            )
          :
            titlesTab.push(
              {text: 'Equipe '+ (index+1), style: 'teamTitle'
            }
            );
        else
        titlesTab.push(
          {fillColor : 'red', text: ' ', style: 'roomTitle'}
        );
        titlesTab.push(
          {text:' ', style: 'profileSubText', margin: [60, 0, 0, 0]},
          {text: ' ', style: 'profileSubTextTime'}
        );
        
          allTeams.push(titlesTab);
          continue;

         }
    }
    
  
    return titlesTab;
  }

  async buildSurgeons(roomPrograms:any[]): Promise<any>{
    const allProfiles = [];
    for (let index = 0; index < roomPrograms.length; index++) {
      const profilesTab = [];
      // if(this.pageBreak)
      //   profilesTab.push({text:'', pageBreak: this.pageBreak ? 'before' : ''})
      const program = roomPrograms[index];
      let profile1 = false;
      for(let i = 0 ; i<2; i++){
        const profile = program.surgeon;
        if(profile && i==0){
          profile1 = true;
          const lastName = capitalize(profile.firstName).length ? capitalize(profile.firstName) : ''
          
          profilesTab.push([
            index == 0
              ? {text: lastName + ' ' + capitalize(profile.lastName), style: 'profileText', margin: [0, 43, 0, 0]}
              : index == 1
              ? {text: lastName + ' ' + capitalize(profile.lastName), style: 'profileText', margin: [0, -8, 0, 0], pageBreak : 'avoid'}
              : {text: lastName + ' ' + capitalize(profile.lastName), style: 'profileText', margin: [0, 35, 0, 0], pageBreak : 'avoid'}
          ]);
          

        if(this.isProgramNotEmpty(program)){
          profilesTab.push(
            {text: profile.position, style: 'profileSubText', margin: [0, 0, 0, 0], pageBreak : 'avoid'},
            {text: this.getFromToTime(program,this.BLOC_STR), style: 'profileSubTextTime', pageBreak : 'avoid'}
          );
        }else {
          profilesTab.push(
            {text: profile.position, style: 'profileSubText', pageBreak : 'avoid'}
          );
        }

          allProfiles.push(profilesTab);
          continue;
        }else{
          if(profile1){
            profilesTab.push([
              index > 0
                ? {text: ' ', style: 'profileText', margin: [0, 5, 0, 0], pageBreak : 'avoid'}
                : {text: ' ', style: 'profileText', margin: [0, 5, 0, 0], pageBreak : 'avoid'}
              ]);
          }else{
            profilesTab.push([
              index > 0
                ? {text: ' ', style: 'profileText', margin: [0, 5, 0, 0], pageBreak : 'avoid'}
                : {text: ' ', style: 'profileText', margin: [0, 5, 0, 13], pageBreak: this.pageBreak ? 'before' : ''}
              ]);
          }
       

        if(this.isProgramNotEmpty(program)){
          profilesTab.push(
            {text: ' ', style: 'profileSubText', margin: [0, 0, 0, 0]},
            {text: ' ', style: 'profileSubTextTime', margin: [0, 0, 0, 9]}
            );
        }
        allProfiles.push(profilesTab);
        }
    }
    }
    
    return allProfiles;
  }
  async buildParamedical(roomPrograms:any[]): Promise<any>{
    const allProfiles = [];
    for (let index = 0; index < roomPrograms.length; index++) {
      const profilesTab = [];
      // if(this.pageBreak)
      //   profilesTab.push({text:'', pageBreak: this.pageBreak ? 'before' : ''})
      const program = roomPrograms[index];
      for (let index = 0; index < program.nurses.length; index++) {
        const nurse = program.nurses[index];
        
        const lastName = capitalize(nurse.profile.firstName).length ? capitalize(nurse.profile.firstName) : ''
          
          profilesTab.push([
            {text: lastName + ' ' + capitalize(nurse.profile.lastName), style: 'profileText', margin: [0, 35, 0, 0], pageBreak : 'avoid'}
          ]);
          
          
          if(this.isProgramNotEmpty(program)){
            profilesTab.push(
              {text: nurse.profile.position, style: 'profileSubText', margin: [0, 0, 0, 0], pageBreak : 'avoid'},
              {text: this.getFromToTime(program,this.BLOC_STR), margin: [0, 0, 0, 0], style: 'profileSubTextTime', pageBreak : 'avoid'},
              {text: nurse.role.name, style: 'profileSubTextRole', margin: [0, 0, 0, 0], pageBreak : 'avoid'}
            );
          }else {
            profilesTab.push(
              {text: nurse.profile.position, style: 'profileSubText', margin: [0, 0, 0, 0], pageBreak : 'avoid'},
              {text: nurse.role.name, style: 'profileSubTextRole', margin: [0, 0, 0, 0], pageBreak : 'avoid'}
            );
          }

          allProfiles.push(profilesTab);
      }
    }
    return allProfiles;
  }

  async buildAnesths(roomPrograms:any[]): Promise<any>{ 
    const allProfiles = [];
    for (let index = 0; index < roomPrograms.length; index++) {
      const profilesTab = [];
      // if(this.pageBreak)
      //   profilesTab.push({text:'', pageBreak: this.pageBreak ? 'before' : ''})
      const program = roomPrograms[index];
      const seniorJunior = program.anesthesists.find((a) => ['Senior', 'Junior'].includes(a.seniority));
      const interne = program.anesthesists.find((a) => a.seniority === 'Interne');
      let profile1 = false;
      for(let i = 0; i < 2; i++){
        const profile = i == 0 
        ? (seniorJunior ? seniorJunior : undefined)
        : (interne  ? interne : undefined)
        if(profile){
          const lastName = capitalize(profile.firstName).length ? capitalize(profile.firstName) : ''
          if(interne && seniorJunior){
            profilesTab.push([
              index == 0 
              ? (i==0
               ? {text: lastName + ' ' + capitalize(profile.lastName), style: 'profileText', margin: [0, 43, 0, 0]}
               : {text: lastName + ' ' + capitalize(profile.lastName), style: 'profileText', margin: [0, 5, 0, 0], pageBreak : 'avoid'}
               )
              : index == 1 
              ?(i==0
                ? (program.anesthesists.length > 1
                    ? {text: lastName + ' ' + capitalize(profile.lastName), style: 'profileText', margin: [0, -10, 0, 0], pageBreak : 'avoid'}
                    : {text: lastName + ' ' + capitalize(profile.lastName), style: 'profileText', margin: [0, -20, 0, 0], pageBreak : 'avoid'}
                )
                : {text: lastName + ' ' + capitalize(profile.lastName), style: 'profileText', margin: [0, 5, 0, 0], pageBreak : 'avoid'}
               )  
              : (i==0
                ?  {text: lastName + ' ' + capitalize(profile.lastName), style: 'profileText', margin: [0, 40, 0, 0], pageBreak : 'avoid'}
                : {text: lastName + ' ' + capitalize(profile.lastName), style: 'profileText', margin: [0, 0, 0, 0], pageBreak : 'avoid'}
               )  
            ]);
          }else{
            profilesTab.push([
              index == 0 
              ? (i==0
               ? {text: lastName + ' ' + capitalize(profile.lastName), style: 'profileText', margin: [0, 42, 0, 0], pageBreak: this.pageBreak ? 'before' : ''}
               : {text: lastName + ' ' + capitalize(profile.lastName), style: 'profileText', margin: [0, -25, 0, 0], pageBreak : 'avoid'}
               )
              : index == 1 
              ?(i==0
                ? (program.anesthesists.length > 1
                    ? {text: lastName + ' ' + capitalize(profile.lastName), style: 'profileText', margin: [0, -25, 0, 0], pageBreak : 'avoid'}
                    : {text: lastName + ' ' + capitalize(profile.lastName), style: 'profileText', margin: [0, -20, 0, 0], pageBreak : 'avoid'}
                )
                : {text: lastName + ' ' + capitalize(profile.lastName), style: 'profileText', margin: [0, 5, 0, 0], pageBreak : 'avoid'}
               )  
              : (i==0
                ?  {text: lastName + ' ' + capitalize(profile.lastName), style: 'profileText', margin: [0, 20, 0, 0], pageBreak : 'avoid'}
                : {text: lastName + ' ' + capitalize(profile.lastName), style: 'profileText', margin: [0, 0, 0, 0], pageBreak : 'avoid'}
               )  
            ]);
          }
  
        if(this.isProgramNotEmpty(program)){
          if(interne && seniorJunior){
            profilesTab.push(
            {text:( profile.seniority ? profile.seniority : '' ), style: 'profileSubText', margin: [0, 0, 0, 0]},
            i == 0
            ? {text: this.getFromToTime(program,this.BLOC_STR), style: 'profileSubTextTime', margin: [0, 0, 0, 5]}
            : {text: this.getFromToTime(program,this.BLOC_STR), style: 'profileSubTextTime', margin: [0, 0, 0, 5]}
          );
          }else{
            profilesTab.push(
              {text:( profile.seniority ? profile.seniority : '' ), style: 'profileSubText', margin: [0, 0, 0, 0]},
              i == 0
              ? {text: this.getFromToTime(program,this.BLOC_STR), style: 'profileSubTextTime', margin: [0, 0, 0, 5]}
              : {text: this.getFromToTime(program,this.BLOC_STR), style: 'profileSubTextTime', margin: [0, 0, 0, 45]}
            );
          }
          
        }else {
          profilesTab.push(
            {text: ( profile.residency ? (profile.residency + ' - ') : '' ) + profile.position, style: 'profileSubText'}
          );
        }
  
          allProfiles.push(profilesTab);
          continue;
        }else{
          profilesTab.push([
            index == 0 
            ? (i==0
             ? {text:'', style: 'profileText', margin: [0, 40, 0, 0]}
             : {text:'', style: 'profileText', margin: [0, 27, 0, 0]}
             )
            : index == 1 
            ?(i==0
              ? (program.anesthesists.length > 1
                  ? {text:'', style: 'profileText', margin: [0, -25, 0, 0]}
                  : {text:'', style: 'profileText', margin: [0, -20, 0, 0]}
              )
              : {text:'', style: 'profileText', margin: [0, 27, 0, 0]}
             )  
            : (i==0
              ?  {text:'', style: 'profileText', margin: [0, 20, 0, 0]}
              : {text:'', style: 'profileText', margin: [0, 0, 0, 0]}
             )  
          ]);
    
          if(this.isProgramNotEmpty(program)){
            profilesTab.push(
              {text:' ', style: 'profileSubText', margin: [0, 0, 0, 0]},
              {text: ' ', style: 'profileSubTextTime', margin: [0, 0, 0, 0]}
            );
          }
          allProfiles.push(profilesTab);
        }
      }
    
    }
    return allProfiles;
  }

  async buildIades(roomPrograms:any[]): Promise<any>{ 
    const allProfiles = [];
    for (let index = 0; index < roomPrograms.length; index++) {
      const profilesTab = [];
      // if(this.pageBreak)
      //   profilesTab.push({text:'', pageBreak: this.pageBreak ? 'before' : ''})
      const program = roomPrograms[index];
      let profile1 = false;
      for(let i=0; i<2; i++){
        const profile = program.anesthesists.find((a) => a.position.toLowerCase() === 'iade');
        if(profile && i==0){
          profile1 = true;
          const lastName = capitalize(profile.firstName).length ? capitalize(profile.firstName) : ''
          
          profilesTab.push([
            index == 0
              ? {text: lastName + ' ' + capitalize(profile.lastName), style: 'profileText', margin: [0, 43, 0, 0]}
              : index == 1
              ? {text: lastName + ' ' + capitalize(profile.lastName), style: 'profileText', margin: [0, -8, 0, 0]}
              : {text: lastName + ' ' + capitalize(profile.lastName), style: 'profileText', margin: [0, 35, 0, 0]}
          ]);
          

        if(this.isProgramNotEmpty(program)){
          profilesTab.push(
            {text: profile.position, style: 'profileSubText', margin: [0, 0, 0, 0]},
            {text: this.getFromToTime(program,this.BLOC_STR), style: 'profileSubTextTime'}
          );
        }else {
          profilesTab.push(
            {text: profile.position, style: 'profileSubText'}
          );
        }

          allProfiles.push(profilesTab);
          continue;
        }else{
          if(profile1){
            profilesTab.push([
              index > 0
                ? {text: ' ', style: 'profileText', margin: [0, 5, 0, 0]}
                : {text: ' ', style: 'profileText', margin: [0, 5, 0, 0]}
              ]);
          }else{
            profilesTab.push([
              index > 0
                ? {text: ' ', style: 'profileText', margin: [0, 5, 0, 0]}
                : {text: ' ', style: 'profileText', margin: [0, 5, 0, 13], pageBreak: this.pageBreak ? 'before' : ''}
              ]);
          }
       

        if(this.isProgramNotEmpty(program)){
          profilesTab.push(
            {text: ' ', style: 'profileSubText', margin: [0, 0, 0, 0]},
            {text: ' ', style: 'profileSubTextTime', margin: [0, 0, 0, 9]}
            );
        }
        allProfiles.push(profilesTab);
        }
      }
    }
    return allProfiles;
  }

  async buildOneProfile(profile?:Profile,program?:BufferProgram){
    const oneProfileTab = [];
    if(!profile)
      return oneProfileTab;

    const content = [];

    let positionOrSeniority;

    if (profile.position === ANESTHETIST && this.hospitalService.doesHospitalHaveAnesthDetailsOption()) {
      positionOrSeniority = this.formatSeniorityPipe.transform(profile.seniority);
    } else {
      positionOrSeniority = profile.position;
    }

    oneProfileTab.push({text: capitalize(profile.firstName) + ' ' + capitalize(profile.lastName), style: 'profileText' , margin: [60, 43, 0, 0]});
    oneProfileTab.push({text: positionOrSeniority + ( profile.residency ? ( ' - ' +profile.residency ) : '' ), style: 'profileSubText', margin: [60, 0, 0, 0]});
    if(profile.position !== ANESTHETIST && profile.calendar)
      oneProfileTab.push({text: this.getOneprofileFromToTime(profile), style: 'profileSubTextTime' , margin: [60, 0, 0, 10]});

      return oneProfileTab;
  }

  async buildFooterOneProfile(profile?:any, first?: number): Promise<any>{
    const oneProfileTab = [];
    const content = [];
    if(!profile)
      return oneProfileTab;
      if(first == 0){
        oneProfileTab.push({
          text: capitalize(profile.firstName) + ' ' + capitalize(profile.lastName)
          , style: 'profileFooterText'
          , fillColor: '#f6f6f6',
          pageBreak:'before'
        });
      }else{
        oneProfileTab.push({
          text: capitalize(profile.firstName) + ' ' + capitalize(profile.lastName)
          , style: 'profileFooterText'
          , fillColor: '#f6f6f6',
        });
      }
      
    const startEndTime= getFormatLTS(profile.startTime) + ' '+ getFormatLTS(profile.endTime);
    oneProfileTab.push({
      text: profile.position + ( profile.residency ? ( ' - ' +profile.residency) : '' )
      , style: 'profileSubText'
      , margin: [40, 0, 0, 0]
      , fillColor: '#f6f6f6'
    });
    oneProfileTab.push({
      text: startEndTime
      , style: 'profileSubTextTime'
      , margin: [40, 0, 0, 10]
      , fillColor: '#f6f6f6'
    });
      return oneProfileTab;
  }

  async ConsultationExtraclinique(prog:any): Promise<any>{
    if(!prog) return [];
   
    const allProfiles = [];
    for (let index = 0; index < prog.length; index++) {

      const profilesTab = [];
      const program = prog[index];
      const profile = program.anesth;
      const lastName = capitalize(profile.firstName).length ? capitalize(profile.firstName) : ''
      profilesTab.push([
        index > 0
          ? {text: lastName + ' ' + capitalize(profile.lastName), style: 'profileText', margin: [0, 5, 0, 0]}
          : {text: lastName + ' ' + capitalize(profile.lastName), style: 'profileText', margin: [0, 5, 0, 0]}
      ]);

      if(program && program.anesth){
        this.hospitalService.doesHospitalHaveAnesthDetailsOption()
        ?
          profilesTab.push(
            {text:( profile.seniority ? (profile.seniority + ' - ') : '' )+ profile.residency, style: 'profileSubText', margin: [0, 0, 0, 0]},
            {text: this.getFromToTime(program,this.CONSULTATION_STR), style: 'profileSubTextTime', margin: [0, 0, 0, 0]}
          )
        :
          profilesTab.push(
            {text:( profile.residency ? (profile.residency + ' - ') : '' )+ profile.residency, style: 'profileSubText', margin: [0, 0, 0, 0]},
            {text: this.getFromToTime(program,this.CONSULTATION_STR), style: 'profileSubTextTime', margin: [0, 0, 0, 0]}
          )
      }else {
        this.hospitalService.doesHospitalHaveAnesthDetailsOption()
        ?
          profilesTab.push(
            {text: ( profile.seniority ? (profile.seniority + ' - ') : '' ) + profile.residency, style: 'profileSubText', margin: [0, 0, 0, 0]}
          )
        :
          profilesTab.push(
            {text: ( profile.residency ? (profile.residency + ' - ') : '' ) + profile.residency, style: 'profileSubText', margin: [0, 0, 0, 0]}
          )
      }
        allProfiles.push(profilesTab);
        continue;     
      
    }
  
    return allProfiles;
  }


  async buildOneSpecialty(prog:any,type:string){
    const oneSpecialtyTab = [];
    if(!prog || !prog.specialty)
      return oneSpecialtyTab;
    if(type == "CONSULTATION"){
      oneSpecialtyTab.push([{fillColor : 'red', text:  prog.specialty.name, style: 'roomTitle'}]);
    }
    else { // else for 'extraclinique'
      oneSpecialtyTab.push([{fillColor : 'red', text:  'Extraclinique', style: 'roomTitle'}]);
    }

    return oneSpecialtyTab;

  }
  // Unused ?
  // getSpecialty(profile): Specialty{
  //   if (!profile) {
  //     return null
  //   }
  //   return profile.specialty
  //     ? profile.specialty
  //     : profile.specialty1
  //       ? profile.specialty1
  //       : profile.specialty2
  //         ? profile.specialty2
  //         : null;
  // }
  
  getPosition(profile): Specialty{
    if (!profile) {
      return null
    }
    return profile.position
  }
  getFromToTime(program:any,type:string): string{

    switch(type){
      case this.CONSULTATION_STR: {
        return (
          getFormatLTS(program.currentStartTime) +
          ' '+
          getFormatLTS(program.currentEndTime)
        );
      }
      case this.BLOC_STR: {
        return (
          getFormatLTS(program.startTime) +
          ' '+
          getFormatLTS(program.endTime)
        );
      }
    }
    
  }

  getOneprofileFromToTime(profile): string{
    return (
      getFormatLTS(profile.calendar.morningStartTime) +
      ' '+
      getFormatLTS(profile.calendar.afternoonEndTime)
    )
  }

  isProgramNotEmpty(program: BufferProgram): boolean{
    return (
      program.anesthesists && program.anesthesists.length > 0 ||
      program.nurses && program.nurses.length > 0
    );
  }

  toPascalCase(str: string): string {
    if (!str) {
      return str;
    }
    return str.replace(/(\w)(\w*)/g, function(g0,g1,g2){return g1.toUpperCase() + g2.toLowerCase();});
  }

  private constructOneColumnHeaderFromDate(dateArg: Date): any[] {
    const date = new Date(dateArg);
    let oneDay = [
      {
        text: this.toPascalCase(this.datePipe.transform(date, 'EEEE')),
        style: ['days']
      },
      {
        text: this.datePipe.transform(date, 'dd LLLL'),
        style: ['dates']
      }
    ];

    return oneDay;
  }

  private constructWeekExportDatesHeader(dates: Date[]): any {
    const datesHeader = [];
    dates.forEach((date) => {
      datesHeader.push(this.constructOneColumnHeaderFromDate(date));
    });

    // Add empty column at the beginning
    datesHeader.unshift([]);

    return datesHeader;
  }

  private constructWeekExportDocumentTitle(startDate: string, endDate: string): any {
    let header = {
      text: `Programme du ${startDate} au ${endDate}`,
      style: ['documentTitle']
    };

    return header;
  }

  private constructWeekExportNamesCell(data: {_id: string, surgeonLastName: string, position?: string, startTime, endTime, team, nurses, anesthetists, outOfRoomStaff?, firstName?, lastName?}[]): any {
    let namesCell = [];
    data.forEach((obj) => {

      if (obj.team) {
        namesCell.push({ text: obj.team, style: ['team'] });
      }
      if (!obj.outOfRoomStaff && obj.startTime && obj.endTime) {
        namesCell.push({ text: `${obj.surgeonLastName} : ${obj.startTime} - ${obj.endTime}${obj.position ? obj.position : ''}`, style: 'last_name_and_time' });
      }
      if (obj.outOfRoomStaff) {
        namesCell.push({
          text: [
            { text: obj.firstName + ' ', color: '#555555' , style: ['team']},
            { text: obj.lastName, color: '#555555' , style: ['team']},
            { text: '\n' + obj.startTime + ' - ' + obj.endTime, color: '#555555' },
            { text: '\n' + obj.outOfRoomStaff.position + ' - ' + obj.outOfRoomStaff.residency, color: '#555555' },
          ],
          style: 'last_name_and_time'
        });
      }
        
      
      if (!this.doesHospitalHaveAnesthDetailsOption)
      {
        if (Array.isArray(obj.anesthetists)) {
          obj.anesthetists.forEach((anest) => {
            if (anest.position === 'Anesthésiste') {
              namesCell.push({
                text: [
                  { text: 'Anesth : ', color: '#111' },
                  { text: anest.lastName, color: '#333' }
                ],
                style: 'nurse_anesthetist'
              });
            }
          });
        }
      }
      else
      {
        if (Array.isArray(obj.anesthetists)) {
          obj.anesthetists.forEach((anest) => {
            if (anest.position === 'Anesthésiste' && (anest.seniority === 'Senior' || anest.seniority === 'Junior')) {
              namesCell.push({
                text: [
                  { text: anest.seniority + " : ", color: '#111' },
                  { text: anest.lastName, color: '#333' }
                ],
                style: 'nurse_anesthetist'
              });
            }
          });
        }

      if (Array.isArray(obj.anesthetists)) {
        obj.anesthetists.forEach((anest) => {
          if (anest.position === 'Anesthésiste' && anest.seniority === 'Interne') {
            namesCell.push({
              text: [
                { text: anest.seniority + " : ", color: '#111' },
                { text: anest.lastName, color: '#333' }
              ],
              style: 'nurse_anesthetist'
            });
          }
        });
      }
    }

      if (Array.isArray(obj.anesthetists)) {
        obj.anesthetists.forEach((anest) => {
          if (anest.position === 'Iade') {
            namesCell.push({
              text: [
                { text: 'Iade : ', color: '#111' },
                { text: anest.firstName + ' ', color: '#333' },
                { text: anest.lastName, color: '#333' }
              ],
              style: 'nurse_anesthetist'
            });
          }
        });
      }
  
      if (obj.nurses) {
        obj.nurses.forEach(nurse => {
          namesCell.push({
            text: [
              { text: nurse.role.name + ' : ', color: '#111' },
              { text: nurse.profile.firstName + ' ', color: '#333' },
              { text: nurse.profile.lastName, color: '#333' }
            ],
            style: 'nurse_anesthetist'
          });
        });
      }

       namesCell.push({ text: ' ', style: 'smallText' });
    });
    return namesCell;
  }

  private constructWeekExportRoomCell(room: Room, numberOfElements: number): any {
    var roomName = `Salle ${room.roomNumber}`;
    if (typeof room.roomNumber === 'string' && room.roomNumber.startsWith("Hors Salle"))
    {
      roomName = room.roomNumber;
    }
    let roomCell = [{
      text: roomName,
      style: 'roomTitle'
    }];
    if (numberOfElements > 2) {
      for (let i = 0; i < Math.floor(numberOfElements / 2); i += 1) {
        roomCell.push({ text: ' ', style: 'smallText' });
        roomCell.unshift({ text: ' ', style: 'smallText' });
      }
    }
    return roomCell;
  }

  getWeekExportRowMaxNumberOfNames(data: {_id: string, surgeonLastName: string}[][]): number {
    let max = 1;

    data.forEach((obj) => {
      if (obj.length > max) {
        max = obj.length;
      }
    })

    return max;
  }

  private constructWeekExportOneRow(data: { room: Room, dataByDates: { _id: string, surgeonLastName: string, position?: string, startTime, endTime, team, nurses, anesthetists, outOfRoomStaff?, firstName?, lastName? }[][] }): any {
    let row = [];
  
    row.push(this.constructWeekExportRoomCell(data.room, 1));
    data.dataByDates.forEach((obj: { _id: string, surgeonLastName: string, position?: string, startTime, endTime, team, nurses, anesthetists, outOfRoomStaff?, firstName?, lastName? }[] | { date?: string, array?: any[] }) => {
      if (Array.isArray(obj)) {
        row.push(this.constructWeekExportNamesCell(obj));
      } else if (obj.date && obj.array) {
        row.push(this.constructWeekExportNamesCell(obj.array));
      }
    });
  
    return row;
  }
  

  public async constructWeekContent(data: any[], dates: Date[], startDate: string, endDate: string): Promise<any> {
    const content = [];
    // Logo
    const logo = {
      image: await this.UtilisService.getBase64ImageFromURL('assets/images/logos/logo_planning.png'),
      width: 150,
      alignment: 'left',
    };

    // Title
    const documentTitle = this.constructWeekExportDocumentTitle(startDate, endDate);

    // First row
    const firstRow = {
      alignment: 'left',
      columns: [
        logo,
        [
          '    ',
          documentTitle
        ]
      ],
      columnGap: 400
    };

    // Empty row to add space
    const emptyRow = ' ';

    // Thirs row
    const daysHeader = this.constructWeekExportDatesHeader(dates);
    
    const threshold = 17;
    let programs = [];
    
    data.forEach((dataByRoom) => {
      var maxLengthDeep = dataByRoom.maxLength.length;
      var NumberOfPages = Math.ceil(maxLengthDeep / threshold)
      var copyOfDataByRoomPages = Array.from({ length: NumberOfPages }, () => JSON.parse(JSON.stringify(dataByRoom)));
    
      if (dataByRoom.maxLength.length > threshold) {
        for (let dayIndex = 0; dayIndex < dataByRoom.dataByDates.length; dayIndex++) {
          var dayData = dataByRoom.dataByDates[dayIndex].array;
          const dayMaxLength = maxLengthDeep;
    
          if (dayMaxLength > threshold) {
            for (let pageIndex = 0; pageIndex < NumberOfPages; pageIndex++) {
              const startSlice = pageIndex * threshold;
              const endSlice = (pageIndex + 1) * threshold;
              copyOfDataByRoomPages[pageIndex].dataByDates[dayIndex].array = dayData.slice(startSlice, endSlice);
            }
          }
        }

        for (var i = 0; i < copyOfDataByRoomPages.length; i++)
        {
          var copyOfDataByRoomPage = copyOfDataByRoomPages[i];
          const IndexOfPage = i+1;
          copyOfDataByRoomPage.room.roomNumber = copyOfDataByRoomPage.room.roomNumber + "\n Partie " + IndexOfPage;
        }
        programs.push(...copyOfDataByRoomPages.map((copy) => this.constructWeekExportOneRow(copy)));
      } else {
        programs.push(this.constructWeekExportOneRow(dataByRoom));
      }
    });


    let table = {
      layout: 'lightHorizontalLines',
      table: {
        headerRows: 1,
        widths: [120, '*', '*', '*', '*', '*'],
        body: [daysHeader, ...programs],
        dontBreakRows: true
      }
    };
    
    content.push(firstRow, emptyRow, table);

    return content;
  }

  getMaxArrayLength(arrays) {
  if (!Array.isArray(arrays) || arrays.length === 0) {
    return 0; // Retourne 0 si l'entrée n'est pas un tableau ou si c'est un tableau vide
  }

  let maxLength = arrays[0].array.length;

  for (let i = 1; i < arrays.length; i++) {
    const currentLength = arrays[i].array.length;

    if (currentLength > maxLength) {
      maxLength = currentLength;
    }
  }

  return maxLength;
}

public async exportWeekPlanning(data: any, startDate: string, endDate: string, dates: Date[]): Promise<any> {
  const fileName = `${this.userService.getCurrentUser().profile.hospitals[0].hospitalName}_plannings_du_${startDate}_au_${endDate}`;
  let content = await this.constructWeekContent(data, dates, startDate, endDate);
  let docDefinition = {
    
    pageSize: 'A2',
    pageOrientation: 'landscape',
    pageMargins: [40, 20, 40, 20],
    info: {
      title: fileName,
      author: 'SmartOp',
      subject: 'Planning'
    },
    content,
    defaultStyle: {
      fontSize: 13,
      font: themeStyling.fontFamilyPrimary,
    },
    styles: {
      teamInfo: {
        background: '#f7f7f7',
        fontSize: 13,
        color: '#333',
        borderRadius: 5,
        border: '1px solid #85bae6',
        margin: [0, 8, 0, 0]
      },
      team: {
        bold: true,
        color: '#333'
      },
      last_name_and_time: {
        color: '#d43900',
        fontSize: 13,
        margin: [0, 5, 0, 0]
      },
      nurse_anesthetist: {
        color: '#333',
        fontSize: 13
      },
      nurse_anesthetist_position: {
        fontSize: 13,
        color: '#111'
      },
      smallText: {
        fontSize: 8
      }
    }
  };
  this.exportService.exportPDF(docDefinition, fileName);
}

  formatHours(date: Date): string {
    const d = new Date(date);
    let hours = `${d.getHours()}`;
    let minutes = `${d.getMinutes()}`;

    if (hours.length < 2) {
      hours = `0${hours}`;
    }

    if (minutes.length < 2) {
      minutes = `0${minutes}`;
    }

    return `${hours} h ${minutes}`;
  }
  getConsultationExtracliniqueFullPrgs(data:any){
    return data.filter((elet)=>elet.dayPrograms.length > 0 || elet.morningPrograms.length > 0 || elet.afternoonPrograms.length > 0)    
  }
}
