import { Component, OnInit, Inject } from '@angular/core';
import { Profile } from '../../models/profile.model';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-import-profile-dialog',
  templateUrl: './import-profile-dialog.component.html',
  styleUrls: ['./import-profile-dialog.component.scss']
})
export class ImportProfileDialogComponent implements OnInit {
  public profileType: string = "";
  public profile: Profile;
  public label = ""
  public isLoading: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<ImportProfileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.profileType = data.profileType
  }

  ngOnInit() {
    if (this.profileType === "Anesthésiste") {
      this.label = "Anesthésiste/IADE"
    } else if (this.profileType === "Chirurgien") {
      this.label = "Chirurgien"
    } else {
      this.label = "Ibode/Ide/AS"
    }
  }

  profileChange(profile: Profile) {
    this.profile = profile;
  }

  close() {
    this.dialogRef.close({status: false});
  }

  import() {
    this.dialogRef.close({status: true, profile: this.profile});
  }

  onChildLoadingChange(isLoading: boolean) {
    this.isLoading = isLoading;
  }
}
