import { Component, Inject, OnDestroy, OnInit, Input } from '@angular/core';
import * as moment from 'moment';
import { MatDialogRef, MAT_DIALOG_DATA, MatCheckboxChange, MatRadioChange } from '@angular/material';
import { Subscription } from 'rxjs';
import { ErrorService } from '../../services/error.service';
import { RoomService } from '../../services/room.service';
import { ToastService } from '../../services/toast.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { HospitalService } from '../../services/hospital.service';
import { AutofillingService } from '../../services/autofilling.service';
import { PythonAPIService } from '../../services/pythonApi.service';
import { roomFinalData } from '../day-program-recap/day-program-interfaces';
import { IADE, NURSE_TYPES } from '../../const/glabals.const';

@Component({
  selector: 'weekly-program-configuration-popup',
  templateUrl: './weekly-program-configuration-popup.component.html',
  styleUrls: ['./weekly-program-configuration-popup.component.scss']
})
export class WeeklyProgramConfigurationPopupComponent implements OnInit, OnDestroy{
  // @Input() title: string = "";
  // @Input() generateBtnTitle: string = "";
  outOfRoom: boolean;
  consult: boolean;
  gardes: boolean;
  nurses: boolean;
  anesth: boolean;
  public seniors: boolean;
  public juniors: boolean;
  public interns: boolean;
  iades: boolean;
  // levelOfAccess: boolean;
  // isIadeRes: boolean;
  // isAnesthResp: boolean;
  title: String;
  btnTitle: String;
  nbRooms: number

  public anesthDetailsOption: boolean;
  
  constructor(
    public dialogRef: MatDialogRef<WeeklyProgramConfigurationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private hospitalService: HospitalService
  ) {
    this.seniors = this.data.seniors;
    this.juniors = this.data.juniors;
    this.interns = this.data.interns;
    this.anesthDetailsOption = this.hospitalService.doesHospitalHaveAnesthDetailsOption()
    this.outOfRoom = this.data.outOfRoom;
    this.consult = this.data.consult;
    this.gardes = this.data.gardes;
    this.nurses = this.data.nurses;
    this.anesth = this.data.anesth;
    this.iades = this.data.iades;
    this.title = this.data.title;
    this.btnTitle = this.data.btnTitle;
  }

  ngOnInit() {
  }

  changeOutOfRoom(result: MatCheckboxChange) {
    this.outOfRoom = result.checked;
  }
  
  changeConsult(result: MatCheckboxChange) {
    this.consult = result.checked;
  }

  changeGardes(result: MatCheckboxChange) {
    this.gardes = result.checked;
  }

  changeNurses(result: MatCheckboxChange) {
    this.nurses = result.checked;

  }
  changeAnesth(result: MatCheckboxChange) {
    this.anesth = result.checked;
  }

  changeIade(result: MatCheckboxChange) {
    this.iades = result.checked;
  }
  
  import() {
    this.dialogRef.close({outOfRoom: this.outOfRoom, consult: this.consult, gardes: this.gardes, nurses: this.nurses, anesth: this.anesth, iades: this.iades, seniors: this.seniors, juniors: this.juniors, interns: this.interns});
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
  }
}
