export const pages = {
  root: '/',
  defaultRoute: '/authentication/sign-in',
  login: '/authentication/sign-in',
  signIn: '/authentication/sign-up',
  resetRequest: '/authentication/request-reset-password',
  resetPassword: '/reset-password/:token',
  firstLogin: '/authentication/first',
  home: '/home',
  smartPlanning: '/smart-planning',
  programRecap: '/smart-planning/recap',
  programNurseRecap: '/smart-planning/nurse-recap',
  weekVue: '/smart-planning/week-vue',
  myProgram: '/smart-planning/my-program',
  pendingRequests: 'pending-requests',
  pendingRequestsHome: 'pending-requests/home',
  fullscreenHome: '/pending-requests/home/fullscreen',
  
  hrSuite: '/hrsuite/',
  hrsuiteNurse: '/hrsuite/Infirmiere',
  hrsuiteAnesthesiste: 'hrsuite/Anesthesiste',
  indivdualCalendar: '/profiles/current',
  parcoursIbode: '/parcours-ibode/competence',
  parcoursIbodeCurrent: '/parcours-ibode/competence/competence-individuelle/current',
  parcoursIbodeIndividual: '/parcours-ibode/competence/competence-individuelle',
  intervention: '/parcours-ibode/contenu',
  materials: '/parcours-ibode/contenu/materiels',
  individualParcours: '/parcours-ibode/contenu',
  statOp: '/statop',
  profile: '/profile',
  profiles: '/profiles',
  about: '/about',
  accessDenied: '/access-denied',
  configuration: '/configuration/general',
  besoins: '/configuration/besoins',
  tvo: '/configuration/tvo',
  anesthesistes: '/hrsuite/Anesthesiste',
  demandeRhAnesthesiste: '/hrsuite/demande-rh-anesthesiste',
  cyclePlanning: '/configuration/cycle-planning',
  nurses: '/hrsuite/Infirmiere',
  demandeRhInfirmiere: '/hrsuite/demande-rh-infirmiere',
  messaging: '/messagerie',
  commissionRegulation: "/hrsuite/commission-regulation",
  parSalles: '/hrsuite/Chirurgien/par-salles',
  parPraticiens: '/hrsuite/Chirurgien/par-praticiens',
  parSallesBesoins: '/hrsuite/Chirurgien/besoins',
  comptenceIndividuelle: '/parcours-ibode/competence/competence-individuelle',
  demandeRh: '/hrsuite/demande-rh',
  demandeRhHistory: '/hrsuite/demande-rh/history',
  demandeRhValidation: '/hrsuite/demande-rh/validation',
  demandeRhHistoryValidation: '/hrsuite/demande-rh/history/validation',
  demandeRhVacation: '/hrsuite/demande-rh/vacataire-validation',
  demandeRhHistoryVacation: '/hrsuite/demande-rh/history/vacataire-validation',
  demandeRhSwitch: '/hrsuite/demande-rh/switch',
  demandeRhHistorySwitch: '/hrsuite/demande-rh/history/switch',

  configurationStructure: '/configuration/structure',
  configurationRH: '/configuration/rh',
  parInterventions: '/hrsuite/Chirurgien/par-interventions',
  jobDescription: '/configuration/fiche-poste',
  configurationPreference: '/configuration/preference',
  statopBloc: '/statop/bloc',
  statopBlocGeneral: '/statop/bloc/general',
  statopBlocParPraticien: '/statop/bloc/par-praticien',
  statopAnesthesiste: '/statop/anesthesiste',
  statopAnesthesisteGeneral: '/statop/anesthesiste/general',
  statopAnesthesisteParAnesthesiste: '/statop/anesthesiste/par-anesthesiste',
};
