import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import 'hammerjs';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import * as moment from 'moment';
import * as Sentry from "@sentry/angular";

if (environment.production) {
  enableProdMode();
}
moment.locale('fr');

if (environment.production) {
  Sentry.init({
    dsn: "https://e6f3ae27482f4327913b0d32d4b8ccf8@o507706.ingest.sentry.io/5599182",
    environment: 'Prod',
    integrations: [new Sentry.Integrations.TryCatch({
      XMLHttpRequest: false,
    })],
  });
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
