import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CalendarRequest } from '../models/calendarRequest';
import { Observable } from 'rxjs';
import { urlEndPoints } from '../config/end-points';

@Injectable({
  providedIn: 'root'
})
export class CalendarRequestService {

  constructor(private httpClient: HttpClient) {
  }

  isPeriodeContainCR(periode: any[], profileId): Observable<any> {
    return this.httpClient.get<CalendarRequest>(`${urlEndPoints.calendarRequest}/checkIfRequestAllowed?profileId=${profileId}&fromDate=${periode[0].substring(0, 10)}&toDate=${periode[1].substring(0, 10)}`);
  }

  deleteCR(request: any) {
    return this.httpClient.delete<CalendarRequest>(`${urlEndPoints.calendarRequest}/${request._id}`, request);
  }

  updateRequest(request: any): Observable<any> {
    return this.httpClient.put<CalendarRequest>(`${urlEndPoints.calendarRequest}`, request);
  }

  getActiveRequests(position: string): Observable<any> {
    return this.httpClient.get<any>(`${urlEndPoints.calendarRequest}/activeRequests?position=${position}`);
  }

  getActiveRequestsCounter(position: string, period: string): Observable<any> {
    return this.httpClient.get<any>(`${urlEndPoints.calendarRequest}/activeRequestsCounter?position=${position}&period=${period}`);
  }

  getActiveRequestsFromTo(position: string, startDate?: string, endDate?: string, page?:number, pageSize?:number): Observable<any> {
    const query = '&fromDate=' + startDate + '&toDate=' + endDate + '&page=' + (page ? page : 1) + '&limit=' + pageSize
    return this.httpClient.get<any>(`${urlEndPoints.calendarRequest}/activeRequestsFromTo?position=${position}` + query);
  }

  getPaginateActiveRequestsFromTo(position: string, fromDate:string, toDate: string, isNight: boolean, page?: number, limit?: number, isPaginate: boolean = true, searchValue: string = null): Observable<any> {
    return this.httpClient.get<CalendarRequest[]>(
      `${urlEndPoints.calendarRequest}/activeRequests?position=` +
      position +
      '&page=' +
      (page ? page : 1) +
      '&limit=' +
      limit +
      '&isPaginate=' +
      isPaginate +
      '&fromDate=' +
      fromDate +
      '&toDate=' +
      toDate +
      (searchValue ? '&searchValue=' + searchValue : '') +
      '&isNight=' +
      isNight
  );
}

  countActiveRequests(position: string, startDate?: string, endDate?: string): Observable<any> {
    const query = '&fromDate=' + startDate + '&toDate=' + endDate
    return this.httpClient.get<any>(`${urlEndPoints.calendarRequest}/countActiveRequests?position=${position}` + query);
  }

  getAllRequetsHistory(position: string) {
    return this.httpClient.get<any>(`${urlEndPoints.calendarRequest}/activeRequests?position=${position}&allHistory=true`);
  }

  getAllRequetsHistoryFromTo(position: string, startDate?: string, endDate?: string, page?:number,pageSize?:number) {
    const query = '&fromDate=' + startDate + '&toDate=' + endDate +'&page=' + (page ? page : 1) + '&limit=' + pageSize
    return this.httpClient.get<any>(`${urlEndPoints.calendarRequest}/activeRequestsFromTo?position=${position}&allHistory=true` + query);
  }

  getPaginateRequestsHistoryFromTo(position: string, fromDate:string, toDate: string, isNight: boolean, page?: number, limit?: number, isPaginate: boolean = true, searchValue: string = null): Observable<any> {
    return this.httpClient.get<CalendarRequest[]>(
      `${urlEndPoints.calendarRequest}/activeRequests` +
      '?position=' + position +
      '&page=' + (page ? page : 1) +
      '&limit=' + limit +
      '&isPaginate=' + isPaginate +
      '&fromDate=' + fromDate +
      '&toDate=' + toDate +
      '&allHistory=true' +
      (searchValue ? '&searchValue=' + searchValue : '') +
      '&isNight=' + isNight
    );
  }

  getRequestsHistory(profileId, page?: number, limit?: number, showOnHold: boolean = false): Observable<any> {
    return this.httpClient.get<any>(`${urlEndPoints.calendarRequest}/history?profile=${profileId ? profileId : ""}` + '&page=' + (page ? page : 1) + '&limit=' + limit + '&showOnHold=' + showOnHold);
  }


  createCalendarRequest(calendarRequest: CalendarRequest, isExtra: boolean): Observable<CalendarRequest> {
    return this.httpClient.post<CalendarRequest>(urlEndPoints.calendarRequest, {request: calendarRequest, isExtra: isExtra});
  }

  updateManyRequests(data: any): Observable<any> {
    return this.httpClient.put<CalendarRequest>(`${urlEndPoints.calendarRequest}/many`, data);
  }

}