import { themeStylingColors } from "src/themes/common/principal-theme";

// To change zoom
export const ZOOM_VALUE = 0.8;
export const OPPOSITE_ZOOM_VALUE = 1.25;
export const frenchDays = [
  "Lundi",
  "Mardi",
  "Mercredi",
  "Jeudi",
  "Vendredi",
  "Samedi",
  "Dimanche",
];
export const shortFrenchDays = ["Lun", "Mar", "Mer", "Jeu", "Ven"];
export const shortFrenchDaysComplete = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"];
export const NURSE = "Infirmiere";
export const DEMANDE_RH = "demande-rh";
export const SWITCH = "switch"
export const DEMANDE_RH_INFIRMIERE = "demande-rh-infirmiere";
export const DEMANDE_RH_ANESTHESISTE = "demande-rh-anesthesiste";
export const SURGEON = "Chirurgien";
export const NURSE_TYPES = ["Infirmiere", "IDE", "IBODE", 'IDE SSPI', 'IDE Perfusionniste', 'AS', 'AS Endoscopie', 'Manipulateur', 'Autres','Eleve'];
export const NOT_NURSES = ['Anesthésiste', 'Iade', 'IADE','Chirurgien',"Cadre de bloc"];
export const ANESTHETIST = "Anesthésiste";
export const ONLY_ANESTH = 'anesth';
export const Iade = "Iade";
export const ANESTHESIST_WITH_ACCENT = "Anesthesiste";
export const PAR_SALLES = "par-salles";
export const STATOP_PAR_PRATICIENS = "par-praticiens";
export const PAR_PRATICIENS = "par-praticiens";
export const COMMISSION_REGULATION = "commission-regulation";
export const VACATAIRE = "Vacataire";
export const TITULAIRE = "Titulaire";
export const IADE = ["Iade", "IADE"];
export const ANESTHETIST_TYPES = [ANESTHETIST, ...IADE];
export const SENIOR = 'Senior';
export const JUNIOR = 'Junior';
export const INTERN = 'Interne';
export const FORBIDDEN_SPECIALTIES = ["Bariatrique"];
export const NURSE_MORNING_SHIFT_END_TIME = "14:00";
export const NURSE_AFTERNOON_SHIFT_START_TIME = "14:00";
export const HOSPITAL_END_TIME = "19:00";
export const HOSPITAL_START_TIME = "08:00";
export const WEIGHT_EXP = 30;
export const WEIGHT_OPPORTUNITY = 180;
export const WEIGHT_LIFT = 80;
export const WEIGHT_CENTER = 60;
export const INTERVENTION_START_TIME = "08:30";
export const INTERVENTION_END_TIME = "19:00";
export const CYCLEDURATIONS = [...Array(15).keys()].map((i) => i + 1);
export const ALL_DAY = "ALL_DAY";
export const MORNING = "MORNING";
export const AFTERNOON = "AFTERNOON";
export const URGENCES_SCORE_DISTRI = [
  {
    name: "Orthopédie membre supérieur",
    _id: "5d690bef1f3172089468769c",
    facteur: 45,
  },
  {
    name: "Orthopédie membre inférieur",
    _id: "5d690bef1f3172089468769d",
    facteur: 5,
  },
  {
    name: "Digestif",
    _id: "5d690bef1f317208946876a2",
    facteur: 30,
  },
  {
    name: "Orologie",
    _id: "5d690bef1f317208946876a5",
    facteur: 10,
  },
  {
    name: "Vasculaire-thoracique",
    _id: "5d690bef1f317208946876a6",
    facteur: 5,
  },
  {
    name: "Gynéco-obstétrique",
    _id: "5d690bef1f317208946876a4",
    facteur: 5,
  },
];

export const ONHOLD = "ONHOLD";
export const APPROVED = "APPROVED";
export const REJECTED = "REJECTED";
export const PROFILES_ON_GARDE = 'profilesOnGarde'
export const PROFILES_ON_PROGRAMS = 'profilesOnPrograms'
// export const CONFIRMATION_MESSAGE = `Des modifications ont été apportées au cours de votre utilisation de l'interface. Vous pouvez sauvegarder ces changements, ignorer ces changements ou continuer votre édition.`;
export const CONFIRMATION_MESSAGE = `Vous êtes sur le point de quitter la page et vous avez des modifications en cours qui ne sont pas sauvegardées. En quittant sans sauvegarder, vous perdrez toute vos modifications`;
export const CONFIRMATION_MESSAGE_VALIDATE = "Vous avez commencé une action de validation des programmes. Vous pouvez valider ces modifications, les ignorer, ou continuer votre édition.";

export const components = {
  INFIRMIER_COMPONENT: "InfirmierComponent",
  SURGEON_COMPONENT: "SurgeonOpeningsComponent",
  ANE_COMPONENT: "AnesthesisteComponent",
  ANE_NEEDS_COMPONENT: "anesthNeedsComponent",
  SP_COMPONENT: "SmartPlanningComponent",
  RECAP_COMPONENT: "DayProgramRecapComponent",
  SURGEON_ROOM_COMPONENT: "SurgeonComponent",
};

export const DATE_WEEK = "DATE_WEEK";
export const DATE_DAY = "DATE_DAY";
export const DATE_FROM = "DATE_FROM";
export const DATE_TO = "DATE_TO";

export const COMMISSION_REGULATION_TITLE = "Commission de régulation";

export const CADRE_DE_BLOC = "Cadre de bloc";
export const RESPONSABLE_DE_BLOC = "Responsable de bloc";

export const RESIDENCY = "Vacataire";

export const levelOfAccess = [1, 2, 3, 4, 5];

// export const BUFFER_POSITION_ANESTHESISTS = "anesthesists";
// export const BUFFER_POSITION_NURSES = "nurses";

// export const ROOM_WITH_ANESTHESISTS = "roomWithAnesthesists";
// export const ROOM_WITH_NURSES = "roomWithNurses";
export const DELETED_PROGRAMS = "deletedPrograms";

export const TOPICS = {
    CALENDAR: 'calendar',
    VACATIONS : 'vacations',
    SMART_BRIEF: 'smart-brief',
    PARCOURS_IBODE_REQUEST: 'parcours-ibode-request',
    PARCOURS_IBODE_RECAP :'nurse-course',
    PROGRAM: 'program',
    MESSAGERIE : 'messagerie',
    STATOP: 'statop',
    UPDATE_STORE: "update-store"
}

export const BLOC ="BLOC";
export const CONSULTATION = "CONSULTATION";
export const MIN_PASSWORD_LENGTH: number = 12;

export const VALID_PASSWORD_LENGTH: number = 4;

export const PASSWORD_STANDARDS: string[] = [
    'Doit contenir au moins 12 caractères',
    'Doit contenir une lettre minuscule',
    'Doit contenir une lettre majuscule',
    'Doit contenir un chiffre',
    'Doit contenir un caractère spécial',
];

export const PROGRAM_CARD_BACKGROUND_COLOR_1 = themeStylingColors.primary_light;
export const PROGRAM_CARD_BACKGROUND_COLOR_2 = themeStylingColors.main_background_color;

export const FEATURES = {
  CALENDRIERS: 'CALENDRIERS',
  SMARTPLANNING: 'SMARTPLANNING',
  PARCOURS_IBODE: 'PARCOURS_IBODE',
  STATOP: 'STATOP',
};

export const OPTIONS = {
  DAY_NIGHT: 'DAY_NIGHT',
  ANESTH_DETAILS: 'ANESTH_DETAILS',
  PROGRAMS_DATA_PIPELINE: 'PROGRAMS_DATA_PIPELINE',
  VACATIONS_DATA_PIPELINE: 'VACATIONS_DATA_PIPELINE',
  NURSE_DETAILS: 'NURSE_DETAILS',
  CALENDARS_DATA_PIPELINE: 'CALENDARS_DATA_PIPELINE',
  TRANSFORM_DA_INTO_SO: 'TRANSFORM_DA_INTO_SO'
};

export const WEIGHT = {
  SENIOR: 1,
  JUNIOR: 1,
  INTERN: 0,
}

export const diminutifLength = 3;

export const OVERFLOW_THRESHOLD = 30 * 60 * 1000;