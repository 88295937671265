import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Specialty } from 'src/app/shared/models/specialty.model';

@Component({
  selector: 'app-add-wish-exclusion-popup',
  templateUrl: './add-wish-exclusion-popup.component.html',
  styleUrls: ['./add-wish-exclusion-popup.component.scss']
})
export class AddWishExclusionPopupComponent implements OnInit {
  public type: string = '';
  public formGroup: FormGroup;
  public types: any[] = [
    {
      label: 'Souhait',
      value: 'wish'
    },
    {
      label: 'Exclusion',
      value: 'exclusion'
    }
  ]
  public unconfiguredSpecialties: (Specialty & { isChecked?: boolean })[] = [];

  constructor(
    private dialogRef: MatDialogRef<AddWishExclusionPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
  ) {
    this.type = this.data.type;
    this.unconfiguredSpecialties = this.data.unconfiguredSpecialties
      .map((elt) => elt.specialty)
      .filter((specialty) => {
        return specialty.name !== 'DEFAULT_' &&
          specialty.name !== 'Bariatrique' &&
          specialty.name !== 'Urgences' &&
          specialty.name !== 'Anesthésie-réanimation'
      });
    this.createForm();
  }

  ngOnInit() {
  }

  selectAllChanged(input: any): void {
    if (input.checked) {
      this.unconfiguredSpecialties.forEach((s) => s.isChecked = true);
    } else {
      this.unconfiguredSpecialties.forEach((s) => s.isChecked = false);
    }
  }

  createForm() {
    const formFields = {
      type: ['wish', [Validators.required]],
      specialty: [this.unconfiguredSpecialties[0] ? this.unconfiguredSpecialties[0]._id : null],
      wish: [0],
    };
    
    this.formGroup = this.formBuilder.group(formFields);
  }

  isFormValid(): boolean {
    if (this.formGroup.get('type').value === 'wish') {
      if (this.formGroup.get('specialty').value && this.formGroup.get('wish').value) {
        return true;
      } else {
        return false;
      }
    } else {
      if (this.unconfiguredSpecialties.filter((s) => s.isChecked).length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  save(): void {
    let result;

    if (this.formGroup.get('type').value === 'wish') {
      result = {
        type: 'wish',
        specialtyId: this.formGroup.get('specialty').value,
        specificWish: this.formGroup.get('wish').value
      }
    } else {
      result = {
        type: 'exclusion',
        specialties: this.unconfiguredSpecialties.filter((s) => s.isChecked),
      }
    }

    this.dialogRef.close(result);
  }

  close(): void {
    this.dialogRef.close();
  }
}
