import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Profile} from '../../models/profile.model';
import {ProfileService} from '../../services/profile.service';
import {Skill} from '../../models/skill.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ANESTHETIST, NURSE} from '../../const/glabals.const';
import {Subscription} from 'rxjs';
import {ErrorService} from '../../services/error.service';
import { UtilisService } from 'src/app/shared/services/utilis.service';

@Component({
  selector: 'app-add-profile-dialog',
  templateUrl: './add-profile-dialog.component.html',
  styleUrls: ['./add-profile-dialog.component.scss']
})
export class AddProfileDialogComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  step = 0;
  skills: Skill[];

  private profileCreationSubscription: Subscription;
  private editProfilSubscription: Subscription;

  constructor(
    private profileService: ProfileService,
    private UtilisService: UtilisService,
    public dialogRef: MatDialogRef<AddProfileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private errorService: ErrorService
  ) {}

  ngOnInit() {
    if (this.data.onlySkills) {
      this.step = 1;
    }
  }

  profileChange(profile: Profile): void {
    this.data.profile = JSON.parse(JSON.stringify(profile));
    if (this.data.type === NURSE || (this.data.type === ANESTHETIST && this.data.profile.position === "Iade") && this.data.openType !== 'EDIT') {
      this.step = 1;
    } else {
      this.step = 2;
      this.isLoading = true;
      if (this.data.openType === 'EDIT') {
        this.editProfile();
      } else {
        this.profileCreationSubscription = this.profileService.createProfileWithSkills({profile: this.data.profile}).subscribe((result) => {
          this.dialogRef.close(result);
        }, error => this.errorService.handleError(error));
      }
    }
  }

  editSkillsClicked(profile: any): void {
    this.data.profile = profile;
    this.step = 1;
  }

  editProfile(): void {
    this.profileCreationSubscription = this.profileService.editProfile(this.data.profile).subscribe((result) => {
      this.UtilisService.displaySuccessToast()
      this.dialogRef.close(result);
    }, error => this.errorService.handleError(error));
  }

  SkillsValidation(skills) {
    if (this.data.openType === 'EDIT') {
      this.generateSkillsBody(skills);
      this.step = 2;
      this.isLoading = true;
      this.editProfileWithSkills();
    } else {
      this.generateSkillsBody(skills);
      this.step = 2;
      this.isLoading = true;
      this.createProfileWithSkills();
    }
  }

  reverse(s: string): string {
    return s.split('-').reverse().join('-');
  }

  editProfileWithSkills() {
    this.editProfilSubscription = this.profileService.editProfileWithSkills({
      profile: this.data.profile,
      skills: this.skills
    }).subscribe((result) => {
      this.UtilisService.displaySuccessToast()
      this.dialogRef.close(result);
    }, error => this.errorService.handleError(error));
  }

  createProfileWithSkills() {
    this.profileCreationSubscription = this.profileService.createProfileWithSkills({
      profile: this.data.profile,
      skills: this.skills
    }).subscribe((result) => {
      this.dialogRef.close(result);
    }, error => this.errorService.handleError(error));
  }

  generateSkillsBody(unstructuredSkills: any[]) {
    const skills: Skill[] = [];
    for (const unstructuredSkill of unstructuredSkills) {
      for (const rating of unstructuredSkill.ratings) {
        const skill: any = {};
        skill.specialty = unstructuredSkill.specialtyId;
        skill.role = rating.role._id
        skill.level = rating.nurseLevel;
        skill.timeIndicator = rating.timeIndicator;
        skills.push(skill);
      }
    }
    this.skills = skills;
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    if (this.profileCreationSubscription) {
      this.profileCreationSubscription.unsubscribe();
    }

    if (this.editProfilSubscription) {
      this.editProfilSubscription.unsubscribe();
    }
  }

}
