import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Paramedical } from '../models/paramedical.model';
import { ParamedicalDal } from './../dal/paramedical.dal';
import { Role } from '../models/role.model';
import { Formation } from '../models/formation.model';
import { AllRole } from '../models/allRole.model';
import { AllFormation } from '../models/allFormation.model';

@Injectable({
    providedIn: 'root',
})
export class ParamedicalService {
    constructor(private paramedicalDal: ParamedicalDal) {}

    getAllRoles(): Observable<AllRole[]> {
        return this.paramedicalDal.getAllRoles();
    }

    getAllFormations(): Observable<AllFormation[]> {
        return this.paramedicalDal.getAllFormations();
    }

    getRoles(): Observable<Role[]> {
        return this.paramedicalDal.getRoles();
    }

    getRolesById(rolesIds: string[]): Observable<Role[]> {
        return this.paramedicalDal.getRolesById(rolesIds);
    }

    addRole(name: string, priority: number): Observable<Role> {
        return this.paramedicalDal.addRole(name, priority);
    }

    modifyRole(id: string, priority: number): Observable<Role> {
        return this.paramedicalDal.modifyRole(id, priority);
    }

    deleteRole(id: string): Observable<any> {
        return this.paramedicalDal.deleteRole(id);
    }

    getFormations(): Observable<Formation[]> {
        return this.paramedicalDal.getFormations();
    }

    addFormation(name: string, groupedWith: string[]): Observable<Formation> {
        return this.paramedicalDal.addFormation(name, groupedWith);
    }

    modifyFormation(id: string, groupedWith: string[]): Observable<Role> {
        return this.paramedicalDal.modifyFormation(id, groupedWith);
    }

    deleteFormation(id: string): Observable<any> {
        return this.paramedicalDal.deleteFormation(id);
    }

    getParamedicals(): Observable<Paramedical[]> {
        return this.paramedicalDal.getParamedicals();
    }

    addParamedical(formation: string, roles: string[], ratingSystem: boolean): Observable<Paramedical> {
        return this.paramedicalDal.addParamedical(formation, roles, ratingSystem);
    }

    modifyParamedical(id: string, formation: string, roles: string[], ratingSystem: boolean): Observable<Role> {
        return this.paramedicalDal.modifyParamedical(id, formation, roles, ratingSystem);
    }

    deleteParamedical(id: string): Observable<any> {
        return this.paramedicalDal.deleteParamedical(id);
    }

}
