import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { RoomService } from "../../../services/room.service";
import { UserService } from "../../../services/user.service";
import { User } from "../../../models/user.model";
import { Profile } from "src/app/shared/models/profile.model";
import { ANESTHETIST, IADE, NURSE_TYPES, SURGEON } from "src/app/shared/const/glabals.const";
import { Role } from "src/app/shared/models/role.model";
import { Paramedical } from "src/app/shared/models/paramedical.model";
import { getBrightness, getFirstHospitalSelectedData, getHoursAndMinutes, getMaxSizedString } from "src/app/shared/utils/cross-functions";
import { TimeRange, profileCard, roomFinalData } from "../day-program-interfaces";
import { SpecialtyColorService } from "src/app/specialty-color.service";
import { Subscription } from "rxjs";
import { AnesthWeekPopupComponent } from "../anesth-week-popup/anesth-week-popup.component";
import { RoleLevel } from "src/app/shared/models/skill.model";
import { pages } from "src/app/shared/config/pages";
import * as moment from "moment";
import { NavigationService } from "src/app/core/services/navigation.service";
import { PopupManagerService } from "src/app/shared/services/popup-manager.service";
import { StorageService } from 'src/app/core/services/storage.service';


@Component({
  selector: "app-team-cards",
  templateUrl: "./team-cards.component.html",
  styleUrls: ["./team-cards.component.scss"],
})
export class TeamCardsComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() profile: Profile;
  @Input() roles: Role[];
  @Input() role: Role;
  @Input() isHome: boolean = false;
  @Input() paramedicals: Paramedical[];
  @Input() startTime: string | Date;
  @Input() endTime: string | Date;
  @Input() originalTime: {startTime: Date, endTime: Date};
  @Input() availableList: boolean = false;
  @Input() hoverable: boolean = false;
  @Input() movable: boolean = false;
  @Input() card: profileCard;
  @Input() readOnly: boolean = false;
  @Input() roomId: string = "";
  @Input() nbAssignments: number = 0;
  @Input() isBloc: boolean = true;
  @Input() isConsult: boolean = false;
  @Input() day: Date;
  @Input() maxLevel: number = 7;
  @Input() isPartTime: boolean = false;
  @Input() anesthSelector: boolean = false;
  @Input() timeRanges: TimeRange[] = [TimeRange.day, TimeRange.morning, TimeRange.afternoon];
  @Input() timeRange: TimeRange;
  @Input() scoresPromise: Promise<RoleLevel[]>;
  @Input() redirectOnClickOnProfile: boolean = false;
  @Input() isBP: boolean = false;
  @Input() hospitalId: string = ""
  @Input() room: roomFinalData
  @Input() isFillingSurgeon: boolean = false;
  @Input() reasonMapColors: Map<string, string>; // key : profileID   value : color in hexadecimal
  @Input() showRoles: boolean = true;

  @Output() onChangeRole = new EventEmitter<Role>();
  @Output() onChangeRange = new EventEmitter<TimeRange>();
  @Output() menuOpened = new EventEmitter<MouseEvent>();

  public picture: string;
  public fullName: string = "";
  public lastNameTitlecase: string = "";
  public specialty: string = "";
  public specialtyColor: string = "white";
  public availableRoles: Role[] = [];
  public canEditRoles: boolean = false;
  public layoutAlign: string = 'default default';
  public showRemoveBtn: boolean = false;
  
  public isLoading: boolean = true;

  public highlightedCard: boolean = false;
  
  public currentUser: User;
  public levelOfAccess: number = 1;
  public isRespIade: boolean = false;
  public isRespAnesth: boolean = false;
  public isInternRes: boolean = false;
  public isCadreBlocWithHighLevel: boolean = false;
  public isCadreBlocWithMediumLevel: boolean = false;
  
  private getIsSpecialtyColorInitSubscription: Subscription;
  private highlightedProfileSmartPlanningSubscription: Subscription;
  private detectChangesSubscription: Subscription;
  
  public level: number = 3.5;
  public starCount = this.level / this.maxLevel * 3
  public scores: RoleLevel[] = [];
  public ratingReady = false;

  public selectorAvailable = true;
  public color: string = '#FFFFFF';
  public textColor: string= '#000000';
  
  get date() {
    return moment(this.day).format("YYYY-MM-DD").toString();
  }

  get isNurse(): boolean {
    if (!this.isFillingSurgeon && this.profile.position && NURSE_TYPES.includes(this.profile.position)) {
      return true;
    }
    return false;
  }
  
  get isAnesthetist(): boolean {
    if (!this.isFillingSurgeon && this.profile.position && this.profile.position === "Anesthésiste") {
      return true;
    }
    return false;
  }

  get isIntern(): boolean {
    if (!this.isFillingSurgeon && this.profile.seniority === "Interne") {
      return true;
    }
    return false;
  }
  
  get isIade(): boolean {
    if (!this.isFillingSurgeon && this.profile.position && IADE.includes(this.profile.position)) {
      return true;
    }
    return false;
  }
  
  get isUrgences(): boolean {
    if (!this.isFillingSurgeon) {
      if ((this.profile.specialties && this.profile.specialties[0] && this.profile.specialties[0].name && this.profile.specialties[0].name === 'Urgences') || this.profile.lastName === "Urgences") {
        return true
      }
    }
    return false;
  }
  
  get isSurgeon(): boolean {
    if (this.isFillingSurgeon || this.profile.position && SURGEON.includes(this.profile.position)) {
      return true;
    }
    return false;
  }
  
  get canRemoveCard(): boolean {
    if (this.isRespIade && !IADE.includes(this.profile.position)) {
      return false;
    }
    return true;
  }

  get fullNameNotSurgeon(): any {
    const reverseNameOrder = this.storageService.getData("reverseOrderName")
    let firstName = this.profile.firstName || '';
    let lastName = this.profile.lastName || '';
    
    return (reverseNameOrder === true && firstName !== "Dr") ? `${lastName} ${firstName}` : `${firstName} ${lastName}`;
  }

  get displayReasonMapBackground(): boolean {
    return (!this.card || !this.card.invalidTime) && !this.isPartTime
  }
  
  constructor(
    private roomsService: RoomService,
    private userService: UserService,
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private specialtyColorService: SpecialtyColorService,
    private navigationService: NavigationService,
    private popupManagerService: PopupManagerService,
    private storageService: StorageService,
    ) {
    this.currentUser = this.userService.getCurrentUser();
    this.levelOfAccess = this.currentUser.levelOfAccess;
    this.isCadreBlocWithHighLevel = this.userService.isCadreBlocWithHighLevel();
    this.isCadreBlocWithMediumLevel = this.userService.isCadreBlocWithMediumLevel();
    this.isRespIade = this.userService.isIadRes();
    this.isRespAnesth = this.userService.isAnesthResponsable()
    this.isInternRes = this.userService.isInternResponsible()

    this.subscribeSpecialtyColor();
    this.subscribeRefreshChanges();
    
    if (this.isCadreBlocWithHighLevel || this.isCadreBlocWithMediumLevel) {
      this.canEditRoles = true;
    }
  }

  subscribeRefreshChanges() {
    if (this.detectChangesSubscription) {
      this.detectChangesSubscription.unsubscribe();
    }

    this.detectChangesSubscription = this.roomsService.refreshChangesCard.subscribe(() => {
      if (this.isNurse) {
        if (this.card.isIntersectingRoom) {
          this.specialtyColor = "#ff000052"
        } else {
          this.specialtyColor = "white"
        }
      }
      if (!this.isFillingSurgeon) {
        this.picture = this.profile.profilePic
        if (this.isSurgeon) {
          setTimeout(() => {
            this.getSpecialty();
          });
        }
        this.initNurseRating();
      }
    })
  }

  subscribeHighlightedProfile() {
    if (this.highlightedProfileSmartPlanningSubscription) {
      this.highlightedProfileSmartPlanningSubscription.unsubscribe();
    }

    this.highlightedProfileSmartPlanningSubscription = this.roomsService.highlightedProfileSmartPlanning.subscribe((card) => {
      if (card) {
        if (card.profile._id === this.profile._id) {
          this.highlightedCard = true;
        } else {
          this.highlightedCard = false;
        }
      } else {
        this.highlightedCard = false;
      }
    })
  }

  setHighlightedProfile() {
    if (!this.highlightedCard) {
      this.roomsService.highlightedProfileSmartPlanning.next(this.card);
    } else {
      this.roomsService.highlightedProfileSmartPlanning.next(undefined);
    }
  }

  subscribeSpecialtyColor() {
    if (this.getIsSpecialtyColorInitSubscription) {
      this.getIsSpecialtyColorInitSubscription.unsubscribe();
    }

    this.getIsSpecialtyColorInitSubscription = this.specialtyColorService.specialtyColorsIsInit.subscribe(() => {
      if (this.isSurgeon) {
        const selectedHospital = this.userService.getSelectedHospitals()[0]
        const specialty = this.profile.specialties.find((specialty) => String(specialty.hospital) === String(selectedHospital))
        
        this.specialtyColor = this.specialtyColorService.getColorForSpecialty(specialty.name) + '6d'
      } 
      if (this.isNurse) {
        if (this.card.isIntersectingRoom) {
          this.specialtyColor = "#ff000052"
        } else {
          this.specialtyColor = "white"
        }
      }
    })
  }

  redirectOnClick() {
    if (this.isHome) {
      if (!this.redirectOnClickOnProfile || (["Iade", "IADE"].includes(this.profile.position) && this.levelOfAccess === 4)) {
        return;
      }
  
      if (this.isSurgeon) {
        if (this.userService.getCurrentUser().profile.position === ANESTHETIST) {
          this.navigationService.navigateTo(
            pages.parSallesBesoins,
            null,
            { date: this.date },
            false
          );
        } else {
          this.navigationService.navigateTo(
            pages.parSalles,
            null,
            { date: this.date },
            false
          );
        }
      } else {
        this.navigationService.navigateTo(
          pages.profiles + "/" + this.profile._id,
          null,
          null,
          false
        );
      }
    } else {
      if (this.isNurse || this.isIade) {
        this.setHighlightedProfile();
      }
    }
  }

  getSeniority(seniority) {
    switch (seniority) {
      case 'Senior':
        return "Sénior"
      case 'Junior':
        return "Junior"
      case 'Interne':
        return "Interne"
      default:
        return "Undefined"
    }
  }

  openMenu(event: MouseEvent) {
    if (this.isSurgeon || this.isAnesthetist) {
      return;
    }

    event.preventDefault();
    this.menuOpened.emit(event);
  }

  async ngOnInit() {
    if (this.isNurse) {
      if (this.card.isIntersectingRoom) {
        this.specialtyColor = "#ff000052"
      } else {
        this.specialtyColor = "white"
      }
    }
    if (!this.isFillingSurgeon) {
      this.picture = this.profile.profilePic
  
      // Set profile Roles
      if ((this.isNurse || this.isIade) && !this.isHome) {
        this.setProfileRoles();
      }
  
      if (this.isSurgeon) {
        this.layoutAlign = 'center center'
      }
      if (this.anesthSelector) {
        const hospitalMiddleTime = getFirstHospitalSelectedData(this.userService.getCurrentUser(), this.userService.getSelectedHospitals()).middleTime;
        const formatedMiddleTime = getHoursAndMinutes(hospitalMiddleTime);
        if (getHoursAndMinutes(this.originalTime.startTime) >= formatedMiddleTime
        || getHoursAndMinutes(this.originalTime.endTime) <= formatedMiddleTime)
        this.selectorAvailable = false;
        if (this.timeRange == null) {
          if (getHoursAndMinutes(this.startTime) >= formatedMiddleTime)
            this.onRangeChange(TimeRange.afternoon);
          else if (getHoursAndMinutes(this.endTime) <= formatedMiddleTime)
            this.onRangeChange(TimeRange.morning);
          else
            this.onRangeChange(TimeRange.day);
        }
      }
      this.subscribeHighlightedProfile();
    }
    if (this.reasonMapColors && this.profile) {
      this.color = this.reasonMapColors.get(this.profile._id)
      
      if (this.color && getBrightness(this.color) < 128) {
        this.textColor = '#FFFFFF';
      }
    }
    this.isLoading = false;
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (this.isNurse) {
      if (this.card.isIntersectingRoom) {
        this.specialtyColor = "#ff000052"
      } else {
        this.specialtyColor = "white"
      }
    }
    if (!this.isFillingSurgeon) {
      if (changes) {
        if (changes.profile) {
          this.picture = this.profile.profilePic
          if (this.isSurgeon) {
            setTimeout(() => {
              this.getSpecialty();
            });
          }
        }
      }
      this.initNurseRating();
    }
  }

  ngAfterViewInit(): void {
    if (this.isNurse) {
      if (this.card.isIntersectingRoom) {
        this.specialtyColor = "#ff000052"
      } else {
        this.specialtyColor = "white"
      }
    }
    if (!this.isFillingSurgeon) {
      if (this.isSurgeon) {
        this.getSpecialty();
      }
    }
  }

  removeNurseFromProgram(event: Event) {
    event.stopPropagation();
    if (this.isBP) {
      this.popupManagerService
        .openConfirmationPopup(
          'Retirer le soignant ?',
          `Etes vous sur(e)s de vouloir retirer le soignant de cette salle ?`,
          370,
          "danger",
          "Oui, retirer"
        )
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.roomsService.removeCardSmartPlanning.next({profile: this.card, room: this.room})
          }
        });
    } else {
      this.roomsService.removeCardSmartPlanning.next({profile: this.card, room: this.room})
    }
  }

  toTitleCase(str: string) {
    const str2 = str.toLowerCase().split(' ');
    for (var i = 0; i < str2.length; i++) {
      str2[i] = str2[i].charAt(0).toUpperCase() + str2[i].slice(1); 
    }
    return str2.join(' ');
  }

  getSpecialty() {
    this.lastNameTitlecase = ""
    this.specialty = ""
    this.fullName = ""
    const specialty = this.profile.specialties.find((specialty) => String(specialty.hospital) === String(this.hospitalId))
    
    if (this.isUrgences || (!this.isUrgences && specialty)) {
      this.specialty = getMaxSizedString(this.isUrgences ? "Urgences" : specialty.name, !this.isHome ? "Inter italic 500 11.25" : "Inter italic 500 13.75", "page-card-" + this.profile._id + this.roomId, !this.isHome ? 80 : 120, this.isUrgences ? "URG" : specialty.diminutif)
      this.fullName = getMaxSizedString(this.isUrgences ? "Urgences" : this.profile.lastName, this.isHome ? "Inter 700 12.5" : "Inter 500 17.5", "page-card-" + this.profile._id + this.roomId, !this.isHome ? 95 : 125)
      this.lastNameTitlecase = this.toTitleCase(this.profile.lastName)
      this.specialtyColor = this.specialtyColorService.getColorForSpecialty(this.isUrgences ? "Urgences" : specialty.name) + '6d'
      if (!this.changeDetectorRef['destroyed']) {
        this.changeDetectorRef.detectChanges();
      }
    }
  }

  formatUTCHours(date: Date, surgeon: boolean = false): string {
    const d = new Date(date);
    let hours = `${d.getUTCHours()}`;
    let minutes = `${d.getUTCMinutes()}`;

    if (hours.length < 2) {
      hours = `0${hours}`;
    }

    if (minutes.length < 2) {
      minutes = `0${minutes}`;
    }

    if (surgeon) {
      if (d.getUTCMinutes() > 0) {
        return `${hours}:${minutes}`;
      } else {
        return `${hours}`;
      }
    } else {
      return `${hours}h${minutes}`;
    }
  }

  compareRole(a: Role, b: Role) {
    if (a && b) {
      return String(a._id) === String(b._id)
    }
    else {
      return false;
    }
  }

  compareTimeRanges(a: TimeRange, b: TimeRange) {
    if (a && b) {
      return a === b;
    }
    else {
      return false;
    }
  }

  onRoleChange(event: Role) {
    this.onChangeRole.emit(event);
  }

  onRangeChange(event: TimeRange) {
    const hospital = getFirstHospitalSelectedData(this.userService.getCurrentUser(), this.userService.getSelectedHospitals())
    this.onChangeRange.emit(event);
    this.timeRange = event;
    if (event === TimeRange.afternoon) {
      this.startTime = getHoursAndMinutes(this.startTime) <= getHoursAndMinutes(hospital.middleTime) ? new Date(hospital.middleTime) : new Date(this.startTime); 
      this.endTime = new Date(this.originalTime.endTime);
    } else if (event === TimeRange.morning) {
      this.endTime = getHoursAndMinutes(this.endTime) >= getHoursAndMinutes(hospital.middleTime) ? new Date(hospital.middleTime) : new Date(this.endTime); 
      this.startTime = new Date(this.originalTime.startTime);
    } else {
      this.startTime = new Date(this.originalTime.startTime);
      this.endTime = new Date(this.originalTime.endTime);
    }
  }

  openAnesthWeek(event: Event) {
    if (this.isSurgeon || !this.isAnesthetist || !this.availableList) {
      return;
    }
    event.stopPropagation();

    this.dialog.open(AnesthWeekPopupComponent, {
      width: "1500px",
      height: "1000px",
      panelClass: 'popup-class-background',
      data: {
        profile: this.profile,
        day: this.day,
        roleData: this.roles
      }
    })
  }

  async initNurseRating() {
    if (this.role && this.scoresPromise != null) {

      if (!this.ratingReady) {
        this.scores = await this.scoresPromise;
      }

      let scoreRole = null
      if (this.scores && Array.isArray(this.scores)) {
        scoreRole = this.scores.find((val) => val.roleId === this.role._id);
      }

      if (scoreRole) {
        this.level = scoreRole.score;
      } else {
        this.level = 0;
      }
      
      this.starCount = this.level / this.maxLevel * 3;
      this.ratingReady = true;
    }
  }

  setProfileRoles() {
    const paramedical = this.paramedicals ? this.paramedicals.find((paramedical) => String(paramedical.formation.name) === String(this.profile.position)) : null
    if (!paramedical) {
      if (this.roles) {
        this.availableRoles = this.roles
        if (!this.role) {
          this.onChangeRole.emit(this.availableRoles[0]);
        }
      }
    } else {
      let roles: {roleId: Role, priority: Number}[] = paramedical.roles.sort(function(a, b) {
        if (Number(a.priority) < Number(b.priority)) {
          return -1
        }
        if (Number(a.priority) > Number(b.priority)) {
          return 1
        }
        return 0
      })
      let rolesIds = roles.map((role) => role.roleId)
      this.availableRoles = rolesIds;
      if (!this.role) {
        this.onChangeRole.emit(this.availableRoles[0]);
      }
    }
  }

  onAvatarError(): void {
    let picture: string;
    if (NURSE_TYPES.includes(this.profile.position)) {
      picture = 'assets/images/icons/nurse.svg';
    } else {
      switch (this.profile.position) {
        case 'Chirurgien':
          picture = 'assets/images/icons/surgeon.svg';
          break;
        case 'Anesthésiste':
          picture = 'assets/images/icons/f-doctor.svg';
          break;
        case 'Iade':
          picture = 'assets/images/icons/f-doctor.svg';
          break;
        case 'IADE':
          picture = 'assets/images/icons/f-doctor.svg';
          break;
        case 'Cadre de bloc':
          picture = 'assets/images/icons/surgeon.svg';
          break;
        default:
          picture = 'assets/images/icons/surgeon.svg';
          break;
      }
    }
    this.picture = picture;
  }

  ngOnDestroy(): void {
    if (this.getIsSpecialtyColorInitSubscription) {
      this.getIsSpecialtyColorInitSubscription.unsubscribe();
    }

    if (this.highlightedProfileSmartPlanningSubscription) {
      this.highlightedProfileSmartPlanningSubscription.unsubscribe();
    }
  }
}
