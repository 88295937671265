import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { ErrorService } from './shared/services/error.service';
import { SpecialtyService } from './shared/services/specialty.service';
import { Subject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpecialtyColorService {
  public specialtyColors: Map<string, string> = new Map<string, string>();
  public specialtyColorsIsInit: Subject<void> = new Subject<void>();
  private initializeSubscription: Subscription;

  constructor(
    public errorService: ErrorService,
    public specialitiesService: SpecialtyService,
  ) {}

  getColorForSpecialty(specialtyName: string): string | undefined {
    const color = this.specialtyColors.get(specialtyName);
  
    return color ? color : '#9E9E9E';
  }

  initialize() {
    if(this.initializeSubscription) this.initializeSubscription.unsubscribe();
    this.initializeSubscription = this.specialitiesService.getAllSpecialtiesWithNameAndColor()
      .subscribe(
        (specialties) => {
          specialties.forEach((item: any) => {
            this.specialtyColors.set(item.name, item.backgroundColor);
          });
          this.specialtyColorsIsInit.next();
        },
        (error) => this.errorService.handleError(error)
      );
  }

  ngOnDestroy() {
    if(this.initializeSubscription) this.initializeSubscription.unsubscribe();
  }
}

