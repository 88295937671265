import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Profile} from '../../models/profile.model';

@Component({
  selector: 'app-da-delete-select',
  templateUrl: './da-delete-select.component.html',
  styleUrls: ['./da-delete-select.component.scss']
})
export class DaDeleteSelectComponent implements OnInit {
  public das: Profile[];
  public selectedDAIndex: number;

  constructor(public dialogRef: MatDialogRef<DaDeleteSelectComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    if (this.data) {
      this.das = this.data;
    }
  }

  selectProfile(daIndex: number): void {
    this.selectedDAIndex = daIndex;
  }

  submit() {
    this.dialogRef.close(this.selectedDAIndex);
  }

}
