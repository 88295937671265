import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-hours-input',
  templateUrl: './hours-input.component.html',
  styleUrls: ['./hours-input.component.scss']
})
export class HoursInputComponent implements OnInit {
  @Input() label: string = '';
  @Input() hintMessage: string = '';
  @Input() defaultTime: string = 'hh:mm';
  @Input() format;
  @Input() controlOnly;
  @Input() minutesGap;
  @Input() buttonAlign: string = 'right';
  @Input() disabled: boolean = false;

  @Output() timeChanged = new EventEmitter()

  @ViewChild('timePicker', {static: false}) timePicker;

  constructor() { }

  ngOnInit() {
  }

  setTime(time: string) {
    this.timePicker.updateTime(time);
  }

  timeChangedHandler(event) {
    this.timeChanged.emit(event)
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.defaultTime && this.timePicker) {
      this.setTime(changes.defaultTime.currentValue);
      this.timeChanged.emit(changes.defaultTime.currentValue);
    }
  }
}
