
import { Component, OnInit, OnDestroy, Input, SimpleChanges, ViewChild, ElementRef, ViewChildren, HostListener, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { BufferProgramService } from 'src/app/shared/services/buffer-program.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { User } from 'src/app/shared/models/user.model';
import {Overlay, OverlayConfig, OverlayModule, OverlayRef} from '@angular/cdk/overlay';

import { NavigationService } from 'src/app/core/services/navigation.service';

import { UserService } from 'src/app/shared/services/user.service';
import { HospitalService } from 'src/app/shared/services/hospital.service';
import { ParamedicalService } from 'src/app/shared/services/paramedical.service';
import { BufferProgram, nurseElement } from 'src/app/shared/models/buffer-program.model';
import { Moment } from 'moment';

import { Profile } from 'src/app/shared/models/profile.model';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { getHoursAndMinutes, getMaxSizedText } from 'src/app/shared/utils/cross-functions';
import { Reason } from 'src/app/shared/models/reason.model';
import { MyAccountComponent } from 'src/app/my-account/my-account.component';
import { CdkPortal } from '@angular/cdk/portal';
import { Specialty } from 'src/app/shared/models/specialty.model';
import { ANESTHETIST, INTERN, NOT_NURSES, NURSE_TYPES, SENIOR, TITULAIRE } from 'src/app/shared/const/glabals.const';
import { overflow } from 'html2canvas/dist/types/css/property-descriptors/overflow';
import { Calendar } from 'src/app/shared/models/calendar.model';

interface dayPrograms {
    programs: (BufferProgram & {pauseHeight?: number, programHeight?: number, roleName?: string, surgeonSpecialty?: Specialty, slotColor?: string})[][]; //[roomIndex][programIndex]
    profiles: Profile[][][];
    overflows: number[][];
    activPrograms: (BufferProgram & {pauseHeight?: number, programHeight?: number})[]; // programs[index]
    date: Date;
    index: number;
    roomNames: string[];
}
@Component({
  selector: 'app-my-program-monthly',
  templateUrl: './my-program-monthly.component.html',
  styleUrls: ['./my-program-monthly.component.scss']
})
export class MyProgramMonthlyComponent implements OnInit, OnDestroy {
 
    @Input() programs: {date: string,consultation: BufferProgram[],bloc: BufferProgram[],extraclinique: BufferProgram[],night: Calendar[]}[] = null;
    @Input() dates: Date[] = [];
    @Input() firstDayOfMonth: Date;
    
    @Input() profile: Profile = null;

    @ViewChild('hoursColumn', {static: false}) pageChild: ElementRef;
    @ViewChild('page', {static: false}) page: ElementRef;

    public hours: string[] = [];
    public dayNumbers: number[] = [];
    public dayNames: string[] = ['LUNDI', 'MARDI', 'MERCREDI', 'JEUDI', 'VENDREDI', 'SAMEDI', 'DIMANCHE'];
    public monthNames: string[] = ["Jan", "Fev", "Mar", "Avr", "Mai", "Juin", "Juil", "Aou", "Sep", "Oct", "Nov", "Dec"];

    public dayPrograms: (BufferProgram & {pauseHeight?: number, programHeight?: number, roleName?: string, surgeonSpecialty?: Specialty, slotColor?: string})[][] = [[],[],[],[],[],[],[]]; //[DayIndex][RoomIndex][ProgramIndex]
    public finalWeekData: dayPrograms[] = []; //[DayIndex][RoomIndex][ProgramIndex]
    public dayGuards: any[][] = [[],[],[],[],[],[],[]];
    public hourHeight: number = 0;
    public popupProgram: (BufferProgram & {pauseHeight?: number, programHeight?: number, roleName?: string, surgeonSpecialty?: Specialty, slotColor?: string})
    public popupSpecialty: Specialty
    public programProfiles: Profile[][];
    public selectedDay: { program: (BufferProgram & {pauseHeight?: number, programHeight?: number, roleName?: string, surgeonSpecialty?: Specialty, slotColor?: string}), guards: any[]} = {
            program: undefined,
            guards: undefined
    }; //[roomIndex][programIndex]
    public profiles: Profile[][][];
    public selectedProfiles: Profile[][][];
    public maxPictureToDisplay: number = 5;
    public consultPeriods: Map<string, string[]> = new Map<string, string[]>(); // key -> program id / value -> period array
    public doesHospitalHaveDayNightOption: boolean;

    public loading = true;
    public programsSubscription: Subscription;
    public minStartHour: number;
    public maxEndHour: number;
    public clicHeight: number;

    private  overlayRef!: OverlayRef;
    @ViewChild(CdkPortal, {static: false}) public contentTemplate!: CdkPortal;
    public isEndOfWeek: boolean = true;
    public extraHeight: number = 0;
    public popupPeriods: string[];
    public doesHospitalHaveAnesthDetailsOption: boolean;
    public roomTagsAvailable: boolean = false;
    public allPrograms: BufferProgram[];

    public indexDates: number;
    public middleTime: string;
    public firstDayMonth: Date; 

    constructor(
        private hospitalService: HospitalService,
        private bufferProgramService: BufferProgramService,
        private overlay: Overlay,
        private userService: UserService,
        private cdref: ChangeDetectorRef

    ) {
        this.doesHospitalHaveDayNightOption = this.hospitalService.doesHospitalHaveDayNightOption();
        this.doesHospitalHaveAnesthDetailsOption = this.hospitalService.doesHospitalHaveAnesthDetailsOption();
        
        this.indexDates = this.dates.length;

        if (!this.dates || this.dates.length === 0) {
            this.dates.push(new Date('2024-06-17'));
            this.dates.push(new Date('2024-06-18'));
            this.dates.push(new Date('2024-06-19'));
            this.dates.push(new Date('2024-06-20'));
            this.dates.push(new Date('2024-06-21'));
            this.dates.push(new Date('2024-06-22'));
            this.dates.push(new Date('2024-06-23'));
        }

        for (let i = 7; i < 20; i++) {
            this.hours.push(i.toString() + ':00');
        }

        
    }
    
    async ngOnInit() {
        for (let date of this.dates) {
            this.dayNumbers.push(date.getDate());
        }

        await this.getmiddleTime();
    }

    selectDay(date) {
        if (!this.selectedDay) {
            this.selectedDay = {
                program: undefined,
                guards: undefined
            };
        }

        this.selectedDay.program = this.getProgramsForDay(date) !== undefined ? this.getProgramsForDay(date)[0] : undefined;
        this.selectedDay.guards = this.getGuardsForDay(date) !== undefined ? this.getGuardsForDay(date) : undefined;
    }

    isSelectedDay(date) {
        if (this.selectedDay && this.selectedDay.program) {
            const finalDate = moment(date).format('YYYY-MM-DD');
            const selectedDate = moment(this.selectedDay.program.date).format('YYYY-MM-DD');
            return String(finalDate) === String(selectedDate); 
        } 
        else if (this.selectedDay && this.selectedDay.guards) {
            const finalDate = moment(date).format('YYYY-MM-DD');
            const selectedDate = moment(this.selectedDay.guards[0].date).format('YYYY-MM-DD');
            return String(finalDate) === String(selectedDate); 
        }

        return false;
    }

    getProgramsForDay(date: any): any[] {
        const momentDate = moment(date);
        const dateString = momentDate.format('YYYY-MM-DD');
      
        if (this.finalWeekData.length > 0) {
            const result = this.finalWeekData.filter(dayProgram => {
                const programDateString = moment(dayProgram.date).format('YYYY-MM-DD');

                return String(programDateString) === String(dateString);
            });
            return result;
        }
        return ;
    }

    getGuardsForDay(date: any): any[] {
        if (this.dayGuards.length > 0) {
            const momentDate = moment(date);
            const dateString = momentDate.format('YYYY-MM-DD');

            const result = this.dayGuards.filter(dayguard => {
                if (dayguard.length > 0) {
                    const programDateString = moment(dayguard[0].date).format('YYYY-MM-DD');

                    return String(programDateString) === String(dateString);
                }
            });
            return result[0];
        }
        return ;
    }

    getDayName(date: Date): string {
        const options: Intl.DateTimeFormatOptions = { weekday: 'long' };
        return new Intl.DateTimeFormat('en-US', options).format(new Date(date));
    }

    get gridData(): (Date | null)[] {
        if (!this.dates || this.dates.length === 0) {
            return [];
        }
        return this.dates;
    }

    isMonthChanged(): boolean {
        return;
    }

    formatTime(dateString: string): string {
        return moment.utc(dateString).format('HH[h]mm');
    }


    ngAfterViewInit() {
        this.defineMaxPictures();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.loading = true;
        this.selectedDay = null;

        this.dayNumbers = [];
        for (let date of this.dates) {
            this.dayNumbers.push(date.getDate());
        }
        this.fetchPrograms();
    }

    initProgramsAndPausesHeights() {
        for (let dayIndex = 0; dayIndex < this.finalWeekData.length; dayIndex++) {
            for (let roomIndex = 0; roomIndex < this.finalWeekData[dayIndex].programs.length; roomIndex++) {
                for (let programIndex = 0; programIndex < this.finalWeekData[dayIndex].programs[roomIndex].length; programIndex++) {

                    const program = this.finalWeekData[dayIndex].programs[roomIndex][programIndex];

                    program.pauseHeight = this.getPauseHeight(dayIndex, roomIndex, programIndex, program);
                    program.programHeight = this.getProgramHeight(program);
                    if (program.type === 'bloc' && (!program.specialty || program.specialty.name != 'Extraclinique')) {
                        program.surgeonSpecialty = program.surgeon.specialties.find((spe) => spe.hospital === program.hospital as any as String)
                    }
                    if (program.nurses) {
                        const nurse = program.nurses.find((nurse) => nurse.profile._id === this.profile._id);
                        if (nurse) {
                            program.roleName = nurse.role.name;
                        }
                    }
                }
            }
        }
    }


    shouldDisplay(element: string, program: BufferProgram) {
        const programDuration = (new Date(program.endTime).getTime() - new Date(program.startTime).getTime()) / 1000.0 / 3600.0;
        const programHeight = this.getMargin(programDuration);
        const isNurse = NURSE_TYPES.includes(this.profile.position);

        if (element === 'team-pictures'&& 
            ((programHeight <= 180 && !isNurse) || (programHeight <= 218 && isNurse))) {
                return false;
        }
        if (element === 'room-number' && 
            ((programHeight <= 140 && !isNurse) || (programHeight <= 173 && isNurse))) {
                return false;
        }
        if (element === 'program-hours' && 
            ((programHeight <= 80 && !isNurse) || (programHeight <= 111 && isNurse))) {
                return false;
        }
        if (element === 'surgeon-name' && 
            ((programHeight <= 120 && !isNurse) || (programHeight <= 156 && isNurse))) {
                return false;
        }
        if (element === 'role-tag' && 
            programHeight <= 81) {
                return false;
        }
        return true;
    }


    // Overlay

    openOverlay(program: BufferProgram, event: PointerEvent) {
        if (this.overlayRef) {
            this.overlayRef.detach()
            if (program === this.popupProgram) {
                this.popupProgram = null;
                return ;
            }
        }
        this.popupProgram = program;
        if (this.popupProgram.type === 'consultation' || (this.popupProgram.specialty && this.popupProgram.specialty.name === 'Extraclinique')) {
            this.popupSpecialty = this.popupProgram.specialty ? this.popupProgram.specialty : {name: 'Consultation'} as Specialty;
            this.popupPeriods = this.consultPeriods.get(program._id);
        } else if (this.popupProgram.surgeon && this.popupProgram.surgeon.specialties && this.popupProgram.surgeon.specialties.length > 0) {
            this.popupSpecialty = this.popupProgram.surgeonSpecialty;
        } else {
            this.popupSpecialty = null
        }
        this.clicHeight = event.y;
        let config;
        const popupWidth = program && program.type === 'bloc' && (!program.specialty || program.specialty.name != 'Extraclinique') ? 820 : 500;
            config = new OverlayConfig({
                positionStrategy: this.overlay.position().global().centerVertically().left(((event.x * 1.25) - popupWidth)  + 'px')
        });        

        this.overlayRef = this.overlay.create(config);
        this.overlayRef.attach(this.contentTemplate);
    }

    openGuardOverlay(guards: any, event: PointerEvent) {
        const anesthesistsIades = guards.filter((calendar) => NOT_NURSES.includes(calendar.profile.position)).map((night) => night.profile);
        const nurses = guards.filter((calendar) => !NOT_NURSES.includes(calendar.profile.position)).map((night) => {
                            return {profile: night.profile, role: null}
                        })
        const program = {
            anesthesists:  this.sortAnesthesists(anesthesistsIades, this.doesHospitalHaveAnesthDetailsOption),
            nurses:this.sortNurses(nurses) ,
            date: guards[0].date,
            type: null,
            startTime: guards[0].nightStartTime,
            endTime: guards[0].nightEndTime,
        };
        this.popupSpecialty = {name: guards[0].reason.title} as Specialty;

        if (this.overlayRef) {
            this.overlayRef.detach()
            if (this.popupProgram && program.date === this.popupProgram.date && program.type === this.popupProgram.type) {
                this.popupProgram = null;
                return ;
            }
        }

        this.popupProgram = (program as any);
        
        this.clicHeight = event.y;
        let config;
        const popupWidth = 500;
            config = new OverlayConfig({
                positionStrategy: this.overlay.position().global().bottom().left(((event.x * 1.25) - popupWidth)  + 'px')
        });        
        this.overlayRef = this.overlay.create(config);
        this.overlayRef.attach(this.contentTemplate);
    }

    sortAnesthesists(profiles: Profile[], compareSeniority: boolean) {
        const senorityValue = {
            Senior: 0,
            Junior: 1,
            Interne: 2,
        }

        profiles.sort((a, b) => {
            if (a.position === ANESTHETIST && b.position != ANESTHETIST) {
                return -1;
            }
            if (a.position != ANESTHETIST && b.position === ANESTHETIST) {
                return 1;
            }
            if (a.residency === TITULAIRE && b.residency != TITULAIRE) {
                return -1;
            }
            if (a.residency != TITULAIRE && b.residency === TITULAIRE) {
                return 1;
            }
            if (a.seniority && b.seniority && compareSeniority) {
                const result = senorityValue[a.seniority] - senorityValue[b.seniority];
                
                if (result != 0) {
                    return result;
                }
            }
            return (a.firstName + ' ' + a.lastName).localeCompare(b.firstName + ' ' + b.lastName)
        })
        return profiles;
    }

    sortNurses(nurses: nurseElement[]) {
        nurses.sort((a, b) => {
            if (a.profile.residency === TITULAIRE && b.profile.residency != TITULAIRE) {
                return -1;
            }
            if (a.profile.residency != TITULAIRE && b.profile.residency === TITULAIRE) {
                return 1;
            } 
            if (a.role && b.role) {
                const result = a.role.priority - b.role.priority;
                
                if (result != 0) {
                    return result;
                }
            }
            return (a.profile.firstName + ' ' + a.profile.lastName).localeCompare(b.profile.firstName + ' ' + b.profile.lastName)
        })
        return nurses;
    }



    fetchPrograms() {
        if (this.programsSubscription) {
            this.programsSubscription.unsubscribe();
        }

        this.programsSubscription = this.bufferProgramService.getDayPrograms(moment(this.dates[0]).format('YYYY-MM-DD'), moment(this.dates[this.dates.length - 1]).format('YYYY-MM-DD'), this.profile._id).subscribe(
            (res) => {
                this.programs = res;
                this.dispatchPrograms();
                this.dayPrograms = this.dayPrograms.map((day) => this.groupExtraConsultPrograms(day))
                this.dayPrograms.forEach((dayPrograms) => dayPrograms.sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime()));

                const startHours: number[] = this.allPrograms.map((prog) => new Date(prog.startTime).getUTCHours());
                const endHours: number[] = this.allPrograms.map((prog) => new Date(prog.endTime).getUTCHours());

                this.minStartHour = Math.min(...startHours) - 1;
                this.maxEndHour = Math.max(...endHours) + 1;

                if (this.minStartHour && this.maxEndHour && this.minStartHour != this.maxEndHour && this.minStartHour != Infinity) {
                    this.hours = [];
                    for (let i = this.minStartHour; i <= this.maxEndHour; i++) {
                        this.hours.push(i.toString() + ':00');
                    }
                }
               
                this.splitOverlappingPrograms();
                this.calculatePicturesToDisplayAndOverflows();
                this.cdref.detectChanges();

                if (this.pageChild) {
                    this.hourHeight = this.pageChild.nativeElement.clientHeight / this.hours.length;
                }

                this.initProgramsAndPausesHeights();
                this.finalWeekData = this.finalWeekData.filter(el => el.activPrograms.length > 0);
                this.getDaySlot();
                this.dayGuards = this.dayGuards.filter(el => el.length > 0);
                this.loading = false;
            }
        );
    }

    calculatePicturesToDisplayAndOverflows() {
        this.finalWeekData.forEach((day) => {
            day.programs.forEach((room, roomIndex) => {
                day.profiles.push([]);
                day.overflows.push([]);
                room.forEach((program, progIndex) => {
                    day.profiles[roomIndex].push([]);
                    day.overflows[roomIndex].push(0);

                    if (program.surgeon && program.surgeon._id) {
                        day.profiles[roomIndex][progIndex].push(program.surgeon)
                    }
                    if (program.anesthesists && program.anesthesists.length > 0) {
                        day.profiles[roomIndex][progIndex].push(...program.anesthesists)

                    }
                    if (program.nurses && program.nurses.length > 0) {
                        day.profiles[roomIndex][progIndex].push(...program.nurses.map(nurse => nurse.profile));
                    }
                    if (day.profiles[roomIndex][progIndex].length > this.maxPictureToDisplay) {
                        day.overflows[roomIndex][progIndex] = day.profiles[roomIndex][progIndex].length - (this.maxPictureToDisplay - 1);
                        day.profiles[roomIndex][progIndex].splice(this.maxPictureToDisplay - 1);
                    }
                })
            })
           
        });
    }

    defineMaxPictures() {
        if (this.pageChild) {
            if (this.pageChild.nativeElement.clientWidth < 200) {
                this.maxPictureToDisplay = 2;
            } else {
                this.maxPictureToDisplay = 5;
            }
        }
    }

    groupExtraConsultPrograms(programs: BufferProgram[]) : BufferProgram[] {
        const ret: BufferProgram[] = [];
        programs.forEach(program => {
            if (program.type === 'bloc' && (!program.specialty || program.specialty.name != 'Extraclinique')) {
                ret.push(program);
                return;
            }
            const foundProg = ret.find((retProg) => (retProg.specialty && program.specialty && retProg.specialty._id === program.specialty._id) || (!program.specialty && !retProg.specialty));

            let middleTime = getHoursAndMinutes(this.userService.getCurrentUserHospitals().find((h) => h._id === this.userService.getSelectedHospitals()[0]).middleTime)
            let period = 'Journée'

            if (getHoursAndMinutes(program.startTime) === middleTime) {
                period = 'Après-midi'
            } else if (getHoursAndMinutes(program.endTime) === middleTime) {
                period = 'Matin'
            }

            if (foundProg) {
                this.consultPeriods.get(foundProg._id).push(period);
                foundProg.anesthesists.push(program.anesthesists[0])
            } else {
                this.consultPeriods.set(program._id, [period])
                ret.push(program);
            }
        });
        
        ret.forEach((prog) => prog.anesthesists = this.sortAnesthesists(prog.anesthesists, this.doesHospitalHaveAnesthDetailsOption))

        return ret;
    }


    defaultPicture(profile: Profile): string {
        let picture: string;
          if (NURSE_TYPES.includes(profile.position)) {
            picture = 'assets/images/icons/nurse.svg';
          } else {
            switch (profile.position) {
              case 'Chirurgien':
                picture = 'assets/images/icons/surgeon.svg';
                break;
              case 'Anesthésiste':
                picture = 'assets/images/icons/f-doctor.svg';
                break;
              case 'Iade':
                picture = 'assets/images/icons/f-doctor.svg';
                break;
              case 'IADE':
                picture = 'assets/images/icons/f-doctor.svg';
                break;
              case 'Cadre de bloc':
                picture = 'assets/images/icons/surgeon.svg';
                break;
            }
          }
          return picture;
        }

    getProgramHours(program: BufferProgram) {
        return getHoursAndMinutes(program.startTime) + ' - ' + getHoursAndMinutes(program.endTime)
    }

    chipClick(index, roomIndex) {
        this.finalWeekData[index].index = roomIndex;
    }

    getProgramHeight(program: BufferProgram, extraMargin = false) {
        const programDuration = (new Date(program.endTime).getTime() - new Date(program.startTime).getTime()) / 1000.0 / 3600.0;
        // const extraMarginValue = this.roomTagsAvailable ? 5 : -2;
        const extraMarginValue = 35;
        let height = this.getMargin(programDuration) + (extraMargin ? extraMarginValue : 0);
        height = height -(this.extraHeight > 0 ? this.extraHeight : 0);
        this.extraHeight = 0;
        return height;
    }

    getPauseHeight(dayIndex: number, roomIndex: number, programIndex: number, program: BufferProgram) {
        if (programIndex === 0) {
            const dayStart = new Date(this.dates[dayIndex]);
            dayStart.setUTCHours(this.minStartHour,0, 0, 0);
            return this.getProgramHeight({startTime: dayStart, endTime: program.startTime} as BufferProgram, true)
        }
        else {
            const minimalHeight = 3; // minimal margin between 2 programs
            let height = this.getProgramHeight({startTime: this.finalWeekData[dayIndex].programs[roomIndex][programIndex - 1].endTime, endTime: program.startTime} as BufferProgram);
            this.extraHeight = minimalHeight - height;
            return this.extraHeight > 0 ? height + this.extraHeight : height;
        }   
    }


    getMargin(duration: number) {
        return (duration * this.hourHeight)
    }

    closePopup() {
        this.popupProgram = null;
    }

    dispatchPrograms() {
        this.allPrograms = [];
        this.dayPrograms = Array.from({ length: this.dates.length }, () => []);
        this.dayGuards = Array.from({ length: this.dates.length }, () => []);

        this.programs.forEach(element => {
            this.allPrograms.push(...[].concat(element.bloc, element.consultation, element.extraclinique));
            const index = this.dates.findIndex((date) => date.getTime() === new Date(element.date).getTime());

            if (index != -1) {
                this.dayPrograms[index].push(...[].concat(element.bloc, element.consultation, element.extraclinique))

                element.night.forEach((night) => {
                    let nightData = {
                        reason: night.reason,
                        profile: night.profile,
                        nightStartTime: night.nightStartTime ? new Date(night.nightStartTime) : null,
                        nightEndTime: night.nightEndTime ? new Date(night.nightEndTime) : null,
                        periodString: null,
                        date: night.date
                    };

                    if (!nightData.nightEndTime || !nightData.nightStartTime) {
                        const hospital = this.userService.getCurrentUserHospitals().find((hosp) => hosp._id === (night.hospital as unknown as string));
                        nightData.nightStartTime = new Date(hospital.nightStartTime ? hospital.nightStartTime : hospital.endTime);
                        nightData.nightEndTime = new Date(hospital.nightEndTime ? hospital.nightEndTime : hospital.startTime);
                    }

                    nightData.periodString = getHoursAndMinutes(nightData.nightStartTime) + ' - ' + getHoursAndMinutes(nightData.nightEndTime);

                    this.dayGuards[index].push(nightData);
                })
            }
        });
    }

    splitGuardsByDay(guards: any) {
        this.dayGuards = Array.from({ length: this.dates.length }, () => []);

        guards.forEach((guard) => {
            if (guard.length > 0) {
                const index = this.dayNumbers.findIndex((date) => date === new Date(guard[0].date).getDate());

                if (index != -1) {
                    const profileReason: {reason: Reason, profile: Profile[], nightStartTime?: string, nightEndTime?: string, periodString?: string} = guard[0].object.find((profReas) => profReas.profile.find((profile) => profile._id === this.profile._id));

                    if (profileReason) {
                        if (profileReason.nightStartTime && profileReason.nightEndTime) {
                            profileReason.periodString = getHoursAndMinutes(profileReason.nightStartTime) + ' - ' + getHoursAndMinutes(profileReason.nightEndTime);
                        }

                        this.dayGuards[index].push(profileReason);
                    }
                }
            }
        })
    }

    splitOverlappingPrograms() {
        this.roomTagsAvailable = false;
        this.finalWeekData = [];
        this.dayPrograms.forEach((dayPrograms, dayIndex) => {
            if (this.doesProgramsOverlap(dayPrograms)) {
                this.finalWeekData.push(this.splitProgramsByRoom(dayPrograms, dayIndex))
                this.roomTagsAvailable = true;
            } else {
                this.finalWeekData.push({
                    programs: [dayPrograms],
                    profiles: [],
                    overflows: [],
                    activPrograms: dayPrograms,
                    date: this.dates[dayIndex],
                    index: 0,
                    roomNames: ['Toutes salles'],
                })
            }
        })
    }

    splitProgramsByRoom(dayPrograms, dayIndex) {
        const tmp = {
            programs: [],
            profiles: [],
            overflows: [],
            activPrograms: dayPrograms,
            date: this.dates[dayIndex],
            index: 0,
            roomNames: [],
        };

        const extraclinique = dayPrograms.filter((program) => program.type === 'bloc' && (program.specialty && program.specialty.name === 'Extraclinique'));
        const consultations = dayPrograms.filter((program) => program.type === 'consultation');
        const blocs = dayPrograms.filter((program) => program.type === 'bloc' &&(!program.specialty || program.specialty.name != 'Extraclinique'));
        blocs.sort((a, b) => a.room.priority - b.room.priority);
        let roomID = undefined;
        let roomIndex = -1;
        blocs.forEach((prog) => {
            if (prog.room._id != roomID) {
                roomIndex++;
                roomID = prog.room._id;
                tmp.roomNames.push('Salle ' + prog.room.roomNumber);
                tmp.programs.push([])
            }
            tmp.programs[roomIndex].push(prog);
        })

        if (consultations.length > 0) {
            tmp.programs.push(consultations);
            tmp.roomNames.push('Consultation');
        }
        if (extraclinique.length > 0) {
            tmp.programs.push(extraclinique);
            tmp.roomNames.push('Extraclinique')
        }
        tmp.activPrograms = tmp.programs[0];
        return tmp;
    }

    doesProgramsOverlap(programs: BufferProgram[]) {
        for (const prog of programs) {
            for (const prog2 of programs) {
                if (prog != prog2 && this.doesOverlap(prog, prog2)) {
                    return true;
                }
            }
        }
        return false;
    }

    doesOverlap(prog: BufferProgram, prog2: BufferProgram) : boolean {
        if (prog.startTime > prog2.startTime && prog.startTime < prog2.endTime) {
            return true;
        } else if (prog.endTime > prog2.startTime && prog.endTime < prog2.endTime) {
            return true;
        } else if (prog.startTime === prog2.startTime || prog.endTime === prog2.endTime) {
            return true;
        }
        return false;
    }


    async getmiddleTime() {
        const selectedHospital = this.userService.getSelectedHospitals();
        const hospital = await this.hospitalService.getHospital(selectedHospital).toPromise();
        this.middleTime = this.getHourToDisplay(hospital[0].middleTime);
    }

    getHourToDisplay(time) {
        const utcTime = new Date(time);
        const hours = utcTime.getUTCHours().toString();
        const minutes = utcTime.getUTCMinutes().toString();
        return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
    }

    getDaySlot() {
        this.finalWeekData.forEach((day) => {
          day.programs.forEach((room) => {
            room.forEach((intervention) => {
                const startTime = this.getHourToDisplay(intervention.startTime)
                const endTime = this.getHourToDisplay(intervention.endTime)
                if (endTime <= this.middleTime) {
                    intervention.slotColor = "#809fe7";
                } 
                else if (startTime >= this.middleTime) {
                    intervention.slotColor = "#70b5cd";
                } else if (startTime < this.middleTime && endTime > this.middleTime) {
                    intervention.slotColor = "#84d3fc";
                }
            })
          })
        })
    }

    getSurgeonName(surgeonName): string {
        const className = "time-block";
        return getMaxSizedText(surgeonName.toLowerCase(), "17px Inter medium", className, 85);
    }

    getRoomName(roomName): string {
        const className = "time-block";
        return getMaxSizedText(roomName.toLowerCase(), "17px Inter medium", className, 100);
    }

    getDateMonthName(date) {
        return this.monthNames[date.getMonth()];  
    }

    isSameMonth(date1: Date | null, date2: Date | null): boolean {
        if (!date1 || !date2) return false;
        return moment(date1).isSame(date2, 'month');
    }

    capitalize(str: string): string {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

        
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.loading = true;
        this.hourHeight = this.pageChild.nativeElement.clientHeight / this.hours.length;
        this.initProgramsAndPausesHeights();

        this.defineMaxPictures();
        this.calculatePicturesToDisplayAndOverflows();

        this.loading = false;
    }

    ngOnDestroy() {
        if (this.programsSubscription) {
            this.programsSubscription.unsubscribe();
        }
        if (this.overlayRef) {
            this.overlayRef.detach();
        }
    }
}
