import { Pipe, PipeTransform } from '@angular/core';
import { Specialty } from '../models/specialty.model';

@Pipe({
  name: 'filterSpecialties'
})
export class FilterSpecialties implements PipeTransform {

  transform(specialties: Specialty[], type?: string): Specialty[] {
    return specialties.filter(spec => !type || spec.type.includes(type.toLowerCase()));
  }

}
