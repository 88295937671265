import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthenticationGuard } from './core/services/authentication.guard';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { ANESTHETIST, IADE, components } from './shared/const/glabals.const';
import { BLOCK_MANAGER } from './shared/const/positions.const';
import { ThemingComponent } from './theming/theming.component';
import { InputsShowroomComponent } from './theming/inputs-showroom/inputs-showroom.component';
import { DragDropComponent } from './theming/drag-drop/drag-drop.component';
import { MultipleHospitalsGuard } from './core/services/multiple-hospitals.guard';

const routes: Routes = [
  {
    path: 'authentication',
    loadChildren: './authentication/authentication.module#AuthenticationModule',
  },
  {
    path: 'access-denied',
    component: AccessDeniedComponent,
  },
  {
    path: 'theming',
    component: ThemingComponent,
  },
  {
    path: 'theming/forms',
    component: InputsShowroomComponent
  },
  {
    path: 'theming/drag',
    component: DragDropComponent
  },
  {
    path: '',
    redirectTo: '/pending-requests/home',
    pathMatch: 'full',
    canActivate: [AuthenticationGuard],
    data: { levelOfAccess: [1, 2, 3, 4, 5] }
  },
  {
    path: 'home',
    redirectTo: '/pending-requests/home',
    pathMatch: 'full',
    canActivate: [AuthenticationGuard],
    data: { levelOfAccess: [1, 2, 3, 4, 5] }
  },
  {
    path: 'app-home',
    loadChildren: './home/home.module#HomeModule',
    canActivate: [AuthenticationGuard],
    data: { levelOfAccess: [1, 2, 3, 4, 5] }
  },
  {
    path: 'hrsuite',
    loadChildren: './hrsuite/hrsuite.module#HrsuiteModule',
    canActivate: [AuthenticationGuard],
    data: { levelOfAccess: [1, 2, 3, 4, 5] }
  },
  {
    path: 'profiles',
    loadChildren: './account/account.module#AccountModule',
    canActivate: [AuthenticationGuard],
    data: { levelOfAccess: [1, 2, 3, 4, 5] }
  },
  {
    path: 'profile',
    loadChildren: './my-account/my-account.module#MyAccountModule',
    canActivate: [AuthenticationGuard],
    data: { levelOfAccess: [1, 2, 3, 4, 5] }
  },
  {
    path: 'smart-planning',
    loadChildren: './smart-planning/smart-planning.module#SmartPlanningModule',
    canActivate: [AuthenticationGuard],
    data: { levelOfAccess: [1, 2, 3, 4, 5] }
  },
  {
    path: 'statop',
    loadChildren: './statop/statop.module#StatopModule',
    canActivate: [AuthenticationGuard],
    data: { 
      levelOfAccess: [1, 2, 3, 4, 5],
      positions: [BLOCK_MANAGER, ANESTHETIST]
    }
  },
  {
    path: 'parcours-ibode',
    loadChildren: './parcours-ibode/parcours-ibode.module#ParcoursIbodeModule',
    canActivate: [AuthenticationGuard],
    data: { levelOfAccess: [1, 2, 3, 4, 5] }
  },
  {
    path: 'about',
    loadChildren: './about/about.module#AboutModule',
    canActivate: [AuthenticationGuard],
    data: { levelOfAccess: [1, 2, 3, 4, 5] }
  },
  {
    path: 'configuration',
    loadChildren: './configuration/configuration.module#ConfigurationModule',
    canActivate: [AuthenticationGuard],
    data: { levelOfAccess: [1, 2, 3, 4, 5], positions: [ANESTHETIST, "Iade", "IADE", BLOCK_MANAGER] }
  },
  {
    path: 'messagerie',
    loadChildren: './messaging/messaging.module#MessagingModule',
    canActivate: [AuthenticationGuard, MultipleHospitalsGuard],
    canDeactivate: [MultipleHospitalsGuard],
    data: { levelOfAccess: [1, 2, 3, 4, 5] }
  },
  {
    path: 'pending-requests',
    loadChildren: () => import('./pending-request/pending-request.module').then(m => m.PendingRequestModule),
    canActivate: [AuthenticationGuard],
    data: { levelOfAccess: [1, 2, 3, 4, 5] }
  },
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
