import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatName'
})
export class FormatNamePipe implements PipeTransform {

  transform(data, firstName, lastName): any {
    return `${firstName} ${lastName}`;
  }

}
