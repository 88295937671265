import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoutingStateService {

  private history = [];
  private loadRoutingSubscription: Subscription;

  constructor(
    private router: Router
  ) {}

  public loadRouting(): void {
    if (this.loadRoutingSubscription) this.loadRoutingSubscription.unsubscribe();
    this.loadRoutingSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({urlAfterRedirects}: NavigationEnd) => {
        this.history = [...this.history, urlAfterRedirects];
      });
  }

  public getHistory(): string[] {
    return this.history;
  }

  public getPreviousUrl(): string {
    return this.history[this.history.length - 2] || '/';
  }

  ngOnDestroy() {
    if (this.loadRoutingSubscription) this.loadRoutingSubscription.unsubscribe();
  }
}
