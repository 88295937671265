import {
  AfterViewInit,
  ApplicationRef,
  Component, ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges, ViewChild,
} from '@angular/core';
import {MatDialog} from '@angular/material';
import {fromEvent, interval, Observable, Subject, Subscription, timer,} from 'rxjs';
import {debounce, debounceTime} from 'rxjs/operators';
import {InterventionPopupComponent} from '../intervention-popup/intervention-popup.component';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';
import {DoctorAgendasService} from '../../services/doctor-agendas.service';
import {
  getSpecialtyFontColor,
  idStringGenerator,
  POINTER_CURSOR,
  setCursorPointer,
  toPascalCase,
} from '../../utils/cross-functions';
import {DoctorAgenda, RoomAgenda} from '../../models/doctor-agenda.model';
import {Profile} from '../../models/profile.model';
import {UserService} from '../../services/user.service';
import {Room} from '../../models/room.model';
import {BufferProgramService} from '../../services/buffer-program.service';
import {SurgeonVerificationService} from './../../../surgeon-verification.service';
import {DatePipe} from '@angular/common';
import {StorageService} from 'src/app/core/services/storage.service';
import {UtilisService} from '../../services/utilis.service';

import * as moment from 'moment';
import {ToastService} from '../../services/toast.service';
import {Agenda} from '../../models/programs-front-model';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {ErrorService} from '../../services/error.service';
import {PopupManagerService} from "../../services/popup-manager.service";
import {FormatMessageConfirmationPopupPipe} from "../../pipes/format-message-confirmation-popup.pipe";
import {InterventionDetailsPopupComponent} from "../intervention-details-popup/intervention-details-popup.component";
import { OverlayScrollbarsComponent } from 'overlayscrollbars-ngx';
import { isThisTypeNode } from 'typescript';
import { FlexAlignStyleBuilder } from '@angular/flex-layout';
import { isArray } from 'util';
import { SpecialtyColorService } from 'src/app/specialty-color.service';
import { HrsuiteService } from 'src/app/hrsuite/hrsuite.service';
import { getCommonDayNumber } from '../../utils/duplication.checker';
import { NeedsPopupComponent } from '../needs-popup/needs-popup.component';
import { Role } from '../../models/role.model';

@Component({
  selector: 'app-room-doctor-agenda',
  templateUrl: './room-doctor-agenda.component.html',
  styleUrls: ['./room-doctor-agenda.component.scss'],
})
export class RoomDoctorAgendaComponent implements OnInit, OnChanges, OnDestroy {
  @Input() scrollComponent: OverlayScrollbarsComponent;
  @Input() doctorAllAgendas: DoctorAgenda[];
  @Input() doctorAgendas: DoctorAgenda[];
  @Input() room: Room;
  @Input() date: string;
  @Input() from?: number;
  @Input() roomNumber?: number;
  @Input() isNeedsConfig?: boolean;
  @Input() isPopup?: boolean;
  @Input() isDisablePopup?: boolean;
  @Input() isOneRoom?: boolean;
  @Input() to?: number;
  @Input() fullMode = true;
  @Input() isSmartPanning = false;
  @Input() isDoctorAgenda = true;
  @Input() isConfigMode = false;
  @Input() isShowIntervetions;
  @Input() isOnlyHist: boolean;
  @Input() isDragMode: boolean;
  @Input() notifyAnesth: boolean;
  @Input() currentEnteredRoom: any;
  @Input() isVacation: boolean = true;
  @Input() showDetails: boolean = true;
  @Input() onDataChangeSubject: Subject<void>;
  @Input() readOnlyMode: boolean = false;
  @Input() allSurgeonProfiles: any;
  @Input() canSurgeonSelect: boolean = false;
  @Input() showRealizedMode: boolean = false;
  @Input() smartPlanningDetailsLayout: boolean = false;

  private onDataChangeSubjectSubscription: Subscription;

  @Output() refresh = new EventEmitter<string>();
  @Output() configDelete = new EventEmitter<any>();
  @Output() updateRoomsData = new EventEmitter<any>();
  @Output() closePopup = new EventEmitter<boolean>();
  @Output() onChange = new EventEmitter<void>();
  @Output() deletedIntervention = new EventEmitter<DoctorAgenda[]>();
  @Output() onRightClickOnDoctorAgenda = new EventEmitter<any>();
  @Output() onDoctorAgendaDrop = new EventEmitter<{ doctorAgenda: any, sourceRoom: any, destinationRoom: any }>();
  @Output() onSurgeonChange = new EventEmitter<{ doctorAgenda: any}>();

  public isCadreDeBloc: boolean;

  todo = [];

  public tooltip: { surgeonName: string, surgeonOpeningIndexInSameRoom: number } = null;

  public osComponentOptions = {
    className : "os-theme-dark custom-sidebar",
    nativeScrollbarsOverlaid : {
      showNativeScrollbars : true
    },
    overflowBehavior : {
      x : "hidden",
  },
    paddingAbsolute: true,
    scrollbars: {
      autoHide: 'never',
      clickScrolling: true
    },
  };

  MULT_PX_TO_SIZE = 3.9718;
  onClickY;
  lastPosition;
  plusOrMinus = 'UNKNOWN';
  nbPxToChange = 0;
  dasToUpdate = null;
  indexJ = -1;
  indexI = -1;

  isResizing = false;
  isDragging = false;
  isAgendaPressing = false;
  canDrag = false;

  fullNumberOfHours = 14;
  public hours;
  modifiedDoctorAgendas: any[];
  startHour: number;
  private endHour: number;
  isArray: Function = Array.isArray;
  isHover = false;
  private removeDoctorAgendaSubscription: Subscription;
  isAneWithLowlLevel: boolean;
  public showContent = true;
  hasEnterDelete: any;
  draggedEndTime: Date;
  draggedStartTime: Date;
  stopScrolling: boolean;
  isNearToScroll: any;
  isScrolling: boolean;
  agendaBeforeResize: any;
  marginBetweenMouseAndTopElement: number;
  popupSubscription: Subscription;

  public mouseMoveEventsSubscription: Subscription;

  public nbCirculantes: number = 0;
  public nbAideOpe: number = 0;
  public nbAideSoignante: number = 0;

  public onSurgeonSelect: boolean = false;

  
  // Changeable
  get isDisableAllEvents(): boolean {
    if (this.readOnlyMode || this.onSurgeonSelect) {
      return true;
    }
    if (this.modifiedDoctorAgendas) 
    {
      for (let index = 0; index < this.modifiedDoctorAgendas.length; index++) {
        const element: any = this.modifiedDoctorAgendas[index];
        if (this.isArray(element) && element.length > 1) {
          return true;
        }
      }
      return !this.isDragMode || this.isAneWithLowlLevel || this.readOnlyMode  || this.onSurgeonSelect;
    }
  }

  get isThereNewModifs(): boolean {
    return this.doctorAllAgendas &&
      this.doctorAllAgendas.filter((roomAgenda: any) => roomAgenda.isModified).length > 0;
  }

  get getDraggingHours(): string {
    const d1 = new Date(this.draggedStartTime);
    const d2 = new Date(this.draggedEndTime);
    return this.draggedStartTime && this.draggedEndTime && (
      (d1.getUTCHours()) + 'h' + (d1.getUTCMinutes() ? d1.getUTCMinutes() : '00')
      + ' - ' +
      (d2.getUTCHours()) + 'h' + (d2.getUTCMinutes() ? d2.getUTCMinutes() : '00')
    );
  }

  get isFullMode(): boolean {
    return this.fullMode && this.isShowIntervetions;
  }

  get getRoomHours(): number 
  {
    const agendasLength: number = this.doctorAgendas.length ? this.doctorAgendas.length : 2;
    const hours: string[] = new Array((agendasLength * 6) + 2).fill('0');
    const date: Date = new Date();
    date.setUTCHours(Number(this.from));
    date.setUTCMinutes(0);
    hours[0] = this.getFormatLTS(date);
    return this.isFullMode ? hours : this.hours;
  }

  get fxLayoutAlign(): string {
    if (this.isVacation) {
      return 'center end';
    } else {
      return 'center start';
    }
  }

  formatHour(hour: string): string {
    if (hour) {
      const elements = hour.split(':');
      return `${elements[0]} h ${elements[1]}`;
    }
    return hour;
  }

  roomSize = undefined;

  constructor(
    public dialog: MatDialog,
    private storageService: StorageService,
    private datePipe: DatePipe,
    private surgeonVerificationService: SurgeonVerificationService,
    private bufferProgramService: BufferProgramService,
    private userService: UserService,
    private doctorAgendasService: DoctorAgendasService,
    private utilisService: UtilisService,
    private toastService: ToastService,
    private popupManagerService: PopupManagerService,
    private formatMessageConfirmationPopup: FormatMessageConfirmationPopupPipe,
    private specialtyColorService: SpecialtyColorService,
    private hrsuiteService: HrsuiteService,
    private renderer: Renderer2
  ) {
    if (this.isShowIntervetions) {
      this.readOnlyMode = false;
    }
    this.setIntialTime();
  }


  ngOnInit() {
    // If showInterventions, we cannot readOnly so we can get intervention details
    if (this.isShowIntervetions) {
      this.readOnlyMode = false;
    }
    if (this.readOnlyMode) {
      this.isDragMode = false;
      this.isPopup = false;
      this.isDisablePopup = true;
      this.isConfigMode = false;
      this.canDrag = false;
    }
    this.isCadreDeBloc = this.userService.isCadreBloc();
    this.isAneWithLowlLevel = this.userService.isAnesWithLowLevelAccess();
    this.initOnDataChangeSubjectListener();

    if (this.isDragMode) {
      this.listenToWindowMouseUpEvents();
    }
  }

  listenToWindowMouseUpEvents(): void {
    this.renderer.listen('window', 'mouseup', (event) => {
      if (event.button !== 0 || this.readOnlyMode || this.onSurgeonSelect) {
        return;
      }
  
      if (!this.hasEnterDelete) {
        if (this.indexI !== -1 && this.indexJ !== -1 && !this.isResizing) {
          if (this.isVacation) {
            const draggedAgenda: Agenda = this.modifiedDoctorAgendas[this.indexI][this.indexJ];
            const hostRoomParams: { hostRoomID: string; hour: string } = this.hasEnterARoom(event.clientX, event.clientY);
            const hostRoomID: string = hostRoomParams ? hostRoomParams.hostRoomID : '';
            const startHour: string = hostRoomParams ? hostRoomParams.hour : undefined;
            const draggedRoomAgenda: any = this.doctorAllAgendas.find(agenda => agenda.room._id === draggedAgenda.room);
            const hostRoomAgenda: any = this.doctorAllAgendas.find(agenda => agenda.room._id === hostRoomID);
  
            if (!this.checkIfAgendaCompatibleWithRoom(hostRoomAgenda, draggedAgenda, startHour)) {
              this.toastService.errorToast('Échange non autorisé');
              this.ngOnChanges(null);
            } else {
              /* ------------------------------ DELETE FROM DRAGGED ROOM ------------------------------ */
              // For the case of sameRoom and 1 element in openSurg
              // let openSurgWasFull: boolean = false;
              const surgeonOpeningHistory =
                draggedRoomAgenda.surgeonopeninghistory
                  .find(agenda => (draggedAgenda._id === agenda._id && agenda.opening));
              if (surgeonOpeningHistory) {
                draggedRoomAgenda.surgeonopeninghistory.forEach(histAgenda => {
                  const agenda = {...histAgenda};
                  if (agenda._id === draggedAgenda._id) {
                    agenda.opening = false;
                    agenda.description = 'closed opening';
                  }
                  agenda._id = idStringGenerator();
                  draggedRoomAgenda.surgeonopening.push(agenda);
                });
              } else {
                if (draggedRoomAgenda.surgeonopening.length === 1) {
                  draggedRoomAgenda.surgeonopening.forEach(
                    agenda => {
                      if (draggedAgenda._id === agenda._id) {
                        agenda.opening = false;
                        agenda.description = 'closed opening';
                        agenda._id = idStringGenerator();
                      }
                    }
                  );
                } else {
                  draggedRoomAgenda.surgeonopening = draggedRoomAgenda.surgeonopening.filter(agenda => draggedAgenda._id !== agenda._id);
                }
              }
    
              draggedRoomAgenda.isModified = true;
              hostRoomAgenda.isModified = true;
              draggedAgenda.room = hostRoomAgenda.room._id;
              const hostOpeningsHist = [];
              /* ------------------------------ ADJUST HOST ROOM ------------------------------ */
    
              if (hostRoomAgenda.surgeonopening.length === 0 && hostRoomAgenda.room._id !== draggedRoomAgenda.room._id) {
                // IN HISTORY
                // To store Opening history to openingSurgeon, to keep visible agendas
                hostRoomAgenda.surgeonopeninghistory
                  .forEach(agenda => {
                    if (agenda._id !== draggedAgenda._id && agenda.opening) {
                      const agendaToADD = {...agenda};
                      agendaToADD._id = idStringGenerator();
                      hostOpeningsHist.push(agendaToADD);
                    }
                    agenda.opening = false;
                  });
              }
    
              // store to OS
              const hostAgendaOpeningSurgeon = hostRoomAgenda.surgeonopening.find(agenda => agenda._id === draggedAgenda._id);
              if (hostAgendaOpeningSurgeon) {
                hostAgendaOpeningSurgeon.opening = true;
              } else {
                const AgendaToAdd = {...draggedAgenda};
                AgendaToAdd._id = idStringGenerator();
                hostRoomAgenda.surgeonopening.push(AgendaToAdd);
                hostRoomAgenda.surgeonopening = [...hostRoomAgenda.surgeonopening, ...hostOpeningsHist];
              }
              // this.savePrograms();
              this.updateRoomsData.emit();
            }
          } else {
            const draggedAgenda: Agenda = this.modifiedDoctorAgendas[this.indexI][this.indexJ];
            const hostRoomParams: { hostRoomID: string; hour: string } = this.hasEnterARoom(event.clientX, event.clientY);
            const hostRoomID: string = hostRoomParams ? hostRoomParams.hostRoomID : '';
            const draggedRoomAgenda: any = this.doctorAllAgendas.find(agenda => agenda.room._id === draggedAgenda.room);
            const hostRoomAgenda: any = this.doctorAllAgendas.find(agenda => agenda.room._id === hostRoomID);
  
            // Fire event
            this.onDoctorAgendaDrop.emit({ doctorAgenda: draggedAgenda, sourceRoom: draggedRoomAgenda, destinationRoom: hostRoomAgenda });
          }
        }
      }
  
      if (this.isResizing) {
        const hostRoom = this.getHostRoomByAgendaOpening(this.modifiedDoctorAgendas[this.indexI][this.indexJ]._id);
        if (this.isSurgeonOpenHistAgenda(hostRoom)) {
          const updatedAgenda = {...this.modifiedDoctorAgendas[this.indexI][this.indexJ]};
          hostRoom.surgeonopening.push({...updatedAgenda, ...{deleteID: true}});
          hostRoom.surgeonopeninghistory.forEach(agenda => {
            if (agenda._id === updatedAgenda._id) {
              agenda.opening = false;
            }
            if (agenda.opening) {
              hostRoom.surgeonopening.push({...agenda});
              agenda.opening = false;
            }
          });
        }
        hostRoom.isModified = true;
        // this.savePrograms();
        this.updateRoomsData.emit();
      }
  
      this.dasToUpdate = null;
      this.agendaBeforeResize = null;
      this.indexI = -1;
      this.indexJ = -1;
      this.isResizing = false;
      this.isDragging = false;
      this.draggedStartTime = null;
      this.draggedEndTime = null;
      this.isAgendaPressing = false;
      const scrollContainer: HTMLElement = document.getElementById('room-scrollable-container');
      this.enableScroll(scrollContainer);
      this.canDrag = false;
      this.isScrolling = false;
      this.unsubscribeFromMouseMoveEvents();
    })
  }

  subscribeToMouseMoveEvents(): void {
    this.unsubscribeFromMouseMoveEvents();
    this.mouseMoveEventsSubscription = fromEvent(document, 'mousemove')
      .subscribe((e: MouseEvent) => {
        this.onMouseMove(e);
      });
  }

  unsubscribeFromMouseMoveEvents(): void {
    if (this.mouseMoveEventsSubscription) {
      this.mouseMoveEventsSubscription.unsubscribe();
    }
  }

  // we don't hide hours when out of roomHours anymore. Delete function?
  // isShowHour(hour: string): boolean {
  //   return !(Number(hour.split(':')[0]) === Number(this.from) && Number(hour.split(':')[1]) === 0);
  // }

  onSurgeonSelectHandle(status: boolean) {
    this.onSurgeonSelect = status;
  }

  // TODO: the correct type of profile is Profile, i changed it to any to bypass building errors (we need to fix this ASAP)
  onSurgeonSelectedHandle(profile: any, da: any) {
    let data: any = {}
    let needs = da.needs;
    if (
      profile.surgeonNeeds && (da.needs.nurseAideOp !== profile.surgeonNeeds.nurseAideOp ||
        da.needs.nurseAideSoignante !== profile.surgeonNeeds.nurseAideSoignante ||
        da.needs.nurseCirculante !== profile.surgeonNeeds.nurseCirculante)
    ) {
      needs = profile.surgeonNeeds
    }
    if (da.tvoId) {
      data = {
        startTime: da.startTime,
        endTime: da.endTime,
        _id: Date.now().toString(),
        day: da.day,
        isModified: true,
        isEdited: false,
        opening: true,
        room: da.room,
        surgeon: profile,
        notify: false,
        surgeonOpeningId: da._id,
        needs,
        vacationMode: 'choosePracticioner',
        hospital: da.hospital,
        da: da,
        date: da.date
      }
      const constructedVacation = {data};
  
      this.onSurgeonChange.emit(constructedVacation.data);
    } else {
      data = {
        startTime: da.startTime,
        endTime: da.endTime,
        _id: Date.now().toString(),
        day: getCommonDayNumber((new Date(da.date)).getUTCDay() - 1),
        isModified: true,
        isEdited: false,
        opening: true,
        room: da.room,
        surgeon: profile,
        notify: true,
        surgeonOpeningId: da._id,
        needs,
        vacationMode: 'choosePracticioner',
        hospital: da.hospital,
        da: da,
        date: da.date
      }
      this.bufferProgramService
      .getRoomPrograms(da.startTime.substring(0, 10), da.room)
      .subscribe((res) => {
        if (res.existPrograms) {
          // We have a buffer program that is linked to the previous surgeon
          var description = "";
          const programs = res.programs;
          if (res.existPrograms) {
            description = `<p>Cette vacation est déjà associée à un programme le ${this.datePipe.transform(da.date.substring(0, 10), 'dd/MM/yyyy')}</p><p>Êtes vous sûr(e) de vouloir éditer cette vacation ?</p>`;
          }
          this.popupManagerService.openConfirmationPopup('Editer cette vacation ?', description, 370, 'danger', 'Oui, éditer').afterClosed()
            .subscribe(res => {
              if (res) {
                // The user confirmed the changement
                const constructedVacation = {data};
                constructedVacation.data.programsToSave = programs;
  
                this.hrsuiteService.isChangeAnesthNeedsSubject.next(true)
                this.onSurgeonChange.emit(constructedVacation.data);
              }
            });
        } else {
          // There is no buffer programs related to the previous surgeon
          const constructedVacation = {data};
  
          this.hrsuiteService.isChangeAnesthNeedsSubject.next(true)
          this.onSurgeonChange.emit(constructedVacation.data);
        }
    });
    }
  }

  initOnDataChangeSubjectListener(): void {
    if (this.onDataChangeSubjectSubscription) {
      this.onDataChangeSubjectSubscription.unsubscribe();
    }

    if (this.onDataChangeSubject) {
      this.onDataChangeSubjectSubscription = this.onDataChangeSubject.subscribe(() => {
        this.refreshData();
      });
    }
  }

  calcMinSize() {
    let tmp = -1;

    this.doctorAgendas.forEach(e => {
      const d1 = moment(e.startTime);
      const d2 = moment(e.endTime);
      const res = d2.diff(d1, 'minutes');

      if (tmp === -1) {
        tmp = res;
      }
      if (tmp > res) {
        tmp = res;
      }
    });

    let toSubstract = tmp <= 7 ? 11 + tmp : 10;

    if (tmp > 20) {
      toSubstract *= (tmp < 35) ? 1.5 : 4;
    }
    if (tmp < 44) {
      this.roomSize = Math.round((1580 / tmp)) - (toSubstract);
    } else {
      this.roomSize = undefined;
    }
  }

  preventDefault(e) {
    e.preventDefault();
  }

  preventDefaultForScrollKeys(e) {
    const keys = {37: 1, 38: 1, 39: 1, 40: 1};
    if (keys[e.keyCode]) {
      this.preventDefault(e);
      return false;
    }
  }

  onKeyPressed(event, das, i, j) {
    if (!this.readOnlyMode || !this.onSurgeonSelect) {
      this.dasToUpdate = das;
      this.indexI = i;
      this.indexJ = j;
      this.onClickY = event.y;
      this.isResizing = true;
      this.isDragging = false;
      das.isResizing = true;
      this.isAgendaPressing = false;
      this.canDrag = true;
      this.subscribeToMouseMoveEvents();
    }
  }

  getAgendaByRoomId(id): any {
    return {...this.doctorAllAgendas.find(agenda => agenda.room._id === id)};
  }

  canInjectAgendaInARoom(startDate: Date, draggdTimePeriod: number, hostAllAgendas: Agenda[]) {
    const endhour = this.getFormatLTS(this.getAgendaEndTimeByPeriod(startDate.toISOString(), draggdTimePeriod));
    const starthour = this.getFormatLTS(startDate);
    hostAllAgendas = hostAllAgendas.filter(agenda => agenda.opening);

    for (let index = 0; index < hostAllAgendas.length; index++) {
      const hostAgenda: any = hostAllAgendas[index];
      const hostStartTime: string = this.getFormatLTS(hostAgenda.startTime);
      const hostEndTime: string = this.getFormatLTS(hostAgenda.endTime);
      if (
        (starthour < hostStartTime && hostStartTime < endhour) ||
        (starthour < hostEndTime && hostEndTime < endhour) ||
        (hostStartTime < starthour && starthour < hostEndTime) ||
        (hostStartTime < endhour && endhour < hostEndTime) ||
        (starthour === hostStartTime && endhour === hostEndTime)
      ) {
        return false;
      }
    }
    return true;
  }

  canResizeAgendaInARoom(startDate: Date, endDate: Date, hostAllAgendas: Agenda[], nextStartTime?: string) {
    const endhour = this.getFormatLTS(endDate);
    const starthour = this.getFormatLTS(startDate);
    const nextAgendahour = this.getFormatLTS(nextStartTime);
    hostAllAgendas = hostAllAgendas.filter(agenda => agenda.opening);

    for (let index = 0; index < hostAllAgendas.length; index++) {
      const hostAgenda: any = hostAllAgendas[index];
      const hostStartTime: string = this.getFormatLTS(hostAgenda.startTime);
      const hostEndTime: string = this.getFormatLTS(hostAgenda.endTime);

      if (
        (starthour < hostStartTime && hostStartTime < endhour) ||
        (starthour < hostEndTime && hostEndTime < endhour) ||
        (hostStartTime < starthour && starthour < hostEndTime) ||
        (hostStartTime < endhour && endhour < hostEndTime) ||
        (nextStartTime && endhour > nextAgendahour) ||
        (starthour === hostStartTime && endhour === hostEndTime)
      ) {
        return false;
      }
    }
    return true;
  }

  roomHasParallel(roomAgenda: RoomAgenda): boolean {
    const hostAllAgendas =
      roomAgenda.surgeonopening.length
        ? roomAgenda.surgeonopening.filter((agenda) => agenda.opening)
        : roomAgenda.surgeonopeninghistory.filter((agenda) => agenda.opening);
    for (let index = 0; index < hostAllAgendas.length; index++) {
      const currentAgenda: DoctorAgenda = hostAllAgendas[index];
      const currentStartDate: Date = new Date(currentAgenda.startTime);
      const parallelAgenda =
        hostAllAgendas
          .filter(agenda => agenda._id !== currentAgenda._id)
          .find(agenda => {
            const startDate: Date = new Date(agenda.startTime);
            return (
              startDate.getUTCHours() === currentStartDate.getUTCHours() &&
              startDate.getUTCMinutes() === currentStartDate.getUTCMinutes()
            );
          });
      if (parallelAgenda) {
        return true;
      }
    }
    return false;
  }

  checkIfAgendaCompatibleWithRoom(hostRoomAgenda: any, draggedAgenda: any, starthour: string) {
    let isAvailable = false;
    if (!hostRoomAgenda || this.roomHasParallel(hostRoomAgenda)) {
      return false;
    }
    const draggedStartTime: string = this.getFormatLTS(draggedAgenda.startTime);
    const draggedEndTime: string = this.getFormatLTS(draggedAgenda.endTime);
    const draggdTimePeriod: number = this.getSplittedTimePeriode(draggedStartTime, draggedEndTime);
    const hostRoomID = hostRoomAgenda.room._id;

    // Check room hours
    let startDate = new Date(draggedAgenda.startTime);
    startDate.setUTCHours(Number(starthour.split(':')[0]), Number(starthour.split(':')[1]));
    const endhour = this.getFormatLTS(this.getAgendaEndTimeByPeriod(startDate.toISOString(), draggdTimePeriod));

    let tmpRoomStartHour = this.getRoomSTART_HOUR(hostRoomID);
    let tmpRoomEndHour = this.getRoomEND_HOUR(hostRoomID);
    const vacationStartHour = Number(starthour.split(':')[0]) + (Number(starthour.split(':')[1])/60);
    const vacationEndHour = Number(endhour.split(':')[0]) + (Number(endhour.split(':')[1])/60);
    const roomStartHour = Number(tmpRoomStartHour.split(':')[0]) + 1 + (Number(tmpRoomStartHour.split(':')[1])/60);
    const roomEndHour = Number(tmpRoomEndHour.split(':')[0]) - 1 + (Number(tmpRoomEndHour.split(':')[1])/60);

    if (vacationStartHour < roomStartHour || vacationEndHour > roomEndHour) {
      return false;
    }

    let hostAllAgendas =
      hostRoomAgenda.surgeonopening
      && hostRoomAgenda.surgeonopening.length
        ? [...hostRoomAgenda.surgeonopening.filter(agenda => agenda.opening && agenda._id !== draggedAgenda._id)]
        : [...hostRoomAgenda.surgeonopeninghistory.filter(agenda => agenda.opening && agenda._id !== draggedAgenda._id)];
    hostAllAgendas = hostAllAgendas.sort((a, b) => {
      if (a.startTime > b.startTime) {
        return 1;
      }
      if (a.startTime < b.startTime) {
        return -1;
      }
      return 0;
    });
    let agendaIndex;
    let availablePeriod;
    if (starthour) {
      let startDate = new Date(draggedAgenda.startTime);
      startDate.setUTCHours(Number(starthour.split(':')[0]), Number(starthour.split(':')[1]));
      let isNoConflicts = this.canInjectAgendaInARoom(startDate, draggdTimePeriod, hostAllAgendas);
      availablePeriod = this.getSplittedTimePeriode(starthour, this.getRoomEND_HOUR(hostRoomID));

      // To reset startTime of agenda time and re-check
      if (availablePeriod < draggdTimePeriod) {
        startDate = new Date(draggedAgenda.startTime);
        starthour = this.getFormatLTS(startDate);
        isNoConflicts = this.canInjectAgendaInARoom(startDate, draggdTimePeriod, hostAllAgendas);
        availablePeriod = this.getSplittedTimePeriode(starthour, this.getRoomEND_HOUR(hostRoomID));
        this.toastService.errorToast('la période demandée n\'est pas valide');
      }

      if (availablePeriod >= draggdTimePeriod && isNoConflicts) {
        draggedAgenda.startTime = startDate.toISOString();

        draggedAgenda.endTime = this.getAgendaEndTimeByPeriod(startDate.toISOString(), parseFloat(draggdTimePeriod.toFixed(2))).toISOString();
        return true;
      }
    }
    for (agendaIndex = 0; agendaIndex < hostAllAgendas.length - 1; agendaIndex++) {
      const hostAgenda = hostAllAgendas[agendaIndex];
      const nextHostAgenda = hostAllAgendas[agendaIndex + 1];
      const hostEndTime: string = this.getFormatLTS(hostAgenda.endTime);
      const nextHostStartTime: string = this.getFormatLTS(nextHostAgenda.startTime);
      availablePeriod = this.getSplittedTimePeriode(hostEndTime, nextHostStartTime);

      if (availablePeriod >= draggdTimePeriod) {
        this.setAgendaTime(draggedAgenda, hostAgenda.endTime, draggdTimePeriod, hostRoomID);
        isAvailable = true;
      }
    }

    if (hostAllAgendas.length) {
      availablePeriod = this.getSplittedTimePeriode(this.getRoomSTART_HOUR(hostRoomID), this.getFormatLTS(hostAllAgendas[0].startTim));
      if (availablePeriod >= draggdTimePeriod && !isAvailable) {
        this.setAgendaTime(draggedAgenda, this.getRoomSTART_HOUR(hostRoomID), draggdTimePeriod, hostRoomID);
        isAvailable = true;
      }
      availablePeriod = this.getSplittedTimePeriode(this.getFormatLTS(hostAllAgendas[hostAllAgendas.length - 1].endTime), this.getRoomEND_HOUR(hostRoomID));
      if (availablePeriod >= draggdTimePeriod && !isAvailable) {
        this.setAgendaTime(draggedAgenda, hostAllAgendas[hostAllAgendas.length - 1].endTime, draggdTimePeriod, hostRoomID);
        isAvailable = true;
      }
    }
    return isAvailable;
  }

  getRoomSTART_HOUR(roomID: string): string {
    const room: RoomAgenda = this.doctorAllAgendas.find(roomAgenda => roomAgenda.room._id === roomID);
    const date: Date = new Date(this.date);
    const horaire: any = room.room.horaire.find(horaire => horaire.day === date.getUTCDay() + 1);
    const roomStartDate: Date = new Date(horaire.start);
    return (roomStartDate.getUTCHours() - 1) + ':' + roomStartDate.getUTCMinutes();
  }

  getRoomEND_HOUR(roomID: string): string {
    const room: RoomAgenda = this.doctorAllAgendas.find(roomAgenda => roomAgenda.room._id === roomID);
    const date: Date = new Date(this.date);
    const horaire: any = room.room.horaire.find(horaire => horaire.day === date.getUTCDay() + 1);
    const roomEndDate: Date = new Date(horaire.end);
    let endHour: number = roomEndDate.getUTCHours() + 1;
    endHour = 6 < endHour && endHour < 23 ? endHour : 23;
    return endHour + ':' + roomEndDate.getUTCMinutes();
  }

  getSplittedTimePeriode(startTime: string, endTime: string): number {
    const splittedStartTime = startTime.split(':');
    const splittedEndTime = endTime.split(':');
    const startMin = (Number(splittedStartTime[1]) < 10) ? (Number(splittedStartTime[1]) * 6) : Number(splittedStartTime[1]);
    const endMin = (Number(splittedEndTime[1]) < 10) ? (Number(splittedEndTime[1]) * 6) : Number(splittedEndTime[1]);
    const startTimeNumber = Number(splittedStartTime[0]) + (startMin / 60);
    const endTimeNumber = Number(splittedEndTime[0]) + (endMin / 60);
    return endTimeNumber - startTimeNumber;
  }

  setAgendaTime(agenda, newStartTime: string, period: number, hostRoomID) {
    if (newStartTime === this.getRoomSTART_HOUR(hostRoomID)) {
      const startDate = new Date(agenda.startTime);
      const startHour: number = Number(this.getRoomSTART_HOUR(hostRoomID).split(':')[0]);
      startDate.setUTCHours(startHour, 0);
      agenda.startTime = (new Date(startDate)).toISOString();
      agenda.endTime = (new Date(this.getAgendaEndTimeByPeriod(startDate.toISOString(), period))).toISOString();
    } else {
      agenda.startTime = (new Date(newStartTime)).toISOString();
      agenda.endTime = (new Date(this.getAgendaEndTimeByPeriod(newStartTime, period))).toISOString();
    }
  }

  getAgendaEndTimeByPeriod(newStartTime: string, period: number): Date {
    const periodSplitted = (period + '').split('.');
    const endTime = new Date(newStartTime);
    endTime.setUTCHours(endTime.getUTCHours() + Number(periodSplitted[0]));
    if (periodSplitted[1]) {
      const min = (Number(periodSplitted[1]) < 10) ? (Number(periodSplitted[1]) * 6) : Number(periodSplitted[1]) * 0.6;
      endTime.setUTCMinutes(endTime.getUTCMinutes() + (min));
    }
    return endTime;
  }

  // @HostListener('window:mouseup', ['$event'])
  // mouseUp(event) {
  //   if (event.button !== 0 || this.readOnlyMode || this.onSurgeonSelect) {
  //     return;
  //   }

  //   if (!this.hasEnterDelete) {
  //     if (this.indexI !== -1 && this.indexJ !== -1 && !this.isResizing) {
  //       if (this.isVacation) {
  //         const draggedAgenda: Agenda = this.modifiedDoctorAgendas[this.indexI][this.indexJ];
  //         const hostRoomParams: { hostRoomID: string; hour: string } = this.hasEnterARoom(event.clientX, event.clientY);
  //         const hostRoomID: string = hostRoomParams ? hostRoomParams.hostRoomID : '';
  //         const startHour: string = hostRoomParams ? hostRoomParams.hour : undefined;
  //         const draggedRoomAgenda: any = this.doctorAllAgendas.find(agenda => agenda.room._id === draggedAgenda.room);
  //         const hostRoomAgenda: any = this.doctorAllAgendas.find(agenda => agenda.room._id === hostRoomID);

  //         if (!this.checkIfAgendaCompatibleWithRoom(hostRoomAgenda, draggedAgenda, startHour)) {
  //           this.toastService.errorToast('Échange non autorisé');
  //           this.ngOnChanges(null);
  //         } else {
  //           /* ------------------------------ DELETE FROM DRAGGED ROOM ------------------------------ */
  //           // For the case of sameRoom and 1 element in openSurg
  //           // let openSurgWasFull: boolean = false;
  //           const surgeonOpeningHistory =
  //             draggedRoomAgenda.surgeonopeninghistory
  //               .find(agenda => (draggedAgenda._id === agenda._id && agenda.opening));
  //           if (surgeonOpeningHistory) {
  //             draggedRoomAgenda.surgeonopeninghistory.forEach(histAgenda => {
  //               const agenda = {...histAgenda};
  //               if (agenda._id === draggedAgenda._id) {
  //                 agenda.opening = false;
  //                 agenda.description = 'closed opening';
  //               }
  //               agenda._id = idStringGenerator();
  //               draggedRoomAgenda.surgeonopening.push(agenda);
  //             });
  //           } else {
  //             if (draggedRoomAgenda.surgeonopening.length === 1) {
  //               draggedRoomAgenda.surgeonopening.forEach(
  //                 agenda => {
  //                   if (draggedAgenda._id === agenda._id) {
  //                     agenda.opening = false;
  //                     agenda.description = 'closed opening';
  //                     agenda._id = idStringGenerator();
  //                   }
  //                 }
  //               );
  //             } else {
  //               draggedRoomAgenda.surgeonopening = draggedRoomAgenda.surgeonopening.filter(agenda => draggedAgenda._id !== agenda._id);
  //             }
  //           }
  
  //           draggedRoomAgenda.isModified = true;
  //           hostRoomAgenda.isModified = true;
  //           draggedAgenda.room = hostRoomAgenda.room._id;
  //           const hostOpeningsHist = [];
  //           /* ------------------------------ ADJUST HOST ROOM ------------------------------ */
  
  //           if (hostRoomAgenda.surgeonopening.length === 0 && hostRoomAgenda.room._id !== draggedRoomAgenda.room._id) {
  //             // IN HISTORY
  //             // To store Opening history to openingSurgeon, to keep visible agendas
  //             hostRoomAgenda.surgeonopeninghistory
  //               .forEach(agenda => {
  //                 if (agenda._id !== draggedAgenda._id && agenda.opening) {
  //                   const agendaToADD = {...agenda};
  //                   agendaToADD._id = idStringGenerator();
  //                   hostOpeningsHist.push(agendaToADD);
  //                 }
  //                 agenda.opening = false;
  //               });
  //           }
  
  //           // store to OS
  //           const hostAgendaOpeningSurgeon = hostRoomAgenda.surgeonopening.find(agenda => agenda._id === draggedAgenda._id);
  //           if (hostAgendaOpeningSurgeon) {
  //             hostAgendaOpeningSurgeon.opening = true;
  //           } else {
  //             const AgendaToAdd = {...draggedAgenda};
  //             AgendaToAdd._id = idStringGenerator();
  //             hostRoomAgenda.surgeonopening.push(AgendaToAdd);
  //             hostRoomAgenda.surgeonopening = [...hostRoomAgenda.surgeonopening, ...hostOpeningsHist];
  //           }
  //           this.savePrograms();
  //           this.updateRoomsData.emit();
  //         }
  //       } else {
  //         const draggedAgenda: Agenda = this.modifiedDoctorAgendas[this.indexI][this.indexJ];
  //         const hostRoomParams: { hostRoomID: string; hour: string } = this.hasEnterARoom(event.clientX, event.clientY);
  //         const hostRoomID: string = hostRoomParams ? hostRoomParams.hostRoomID : '';
  //         const draggedRoomAgenda: any = this.doctorAllAgendas.find(agenda => agenda.room._id === draggedAgenda.room);
  //         const hostRoomAgenda: any = this.doctorAllAgendas.find(agenda => agenda.room._id === hostRoomID);

  //         // Fire event
  //         this.onDoctorAgendaDrop.emit({ doctorAgenda: draggedAgenda, sourceRoom: draggedRoomAgenda, destinationRoom: hostRoomAgenda });
  //       }
  //     }
  //   }

  //   if (this.isResizing) {
  //     const hostRoom = this.getHostRoomByAgendaOpening(this.modifiedDoctorAgendas[this.indexI][this.indexJ]._id);
  //     if (this.isSurgeonOpenHistAgenda(hostRoom)) {
  //       const updatedAgenda = {...this.modifiedDoctorAgendas[this.indexI][this.indexJ]};
  //       hostRoom.surgeonopening.push({...updatedAgenda, ...{deleteID: true}});
  //       hostRoom.surgeonopeninghistory.forEach(agenda => {
  //         if (agenda._id === updatedAgenda._id) {
  //           agenda.opening = false;
  //         }
  //         if (agenda.opening) {
  //           hostRoom.surgeonopening.push({...agenda});
  //           agenda.opening = false;
  //         }
  //       });
  //     }
  //     hostRoom.isModified = true;
  //     this.savePrograms();
  //     this.updateRoomsData.emit();
  //   }

  //   this.dasToUpdate = null;
  //   this.agendaBeforeResize = null;
  //   this.indexI = -1;
  //   this.indexJ = -1;
  //   this.isResizing = false;
  //   this.isDragging = false;
  //   this.draggedStartTime = null;
  //   this.draggedEndTime = null;
  //   this.isAgendaPressing = false;
  //   const scrollContainer: HTMLElement = document.getElementById('room-scrollable-container');
  //   this.enableScroll(scrollContainer);
  //   this.canDrag = false;
  //   this.isScrolling = false;
  //   this.unsubscribeFromMouseMoveEvents();
  // }

  // @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.readOnlyMode || this.onSurgeonSelect) {
      return;
    }
    if (this.isResizing) {
      const enteredRoom = this.hasEnterARoom(event.clientX, event.clientY);
      // changeable
      const modifiedAgenda = this.getAgendaByID(this.modifiedDoctorAgendas[this.indexI][this.indexJ]._id);
      const modifiedHostRoom = this.getHostRoomByAgendaOpening(modifiedAgenda._id);
      if (enteredRoom && modifiedHostRoom.room._id === enteredRoom.hostRoomID) {
        const currentHour = enteredRoom.hour;
        const hostRoomID = enteredRoom.hostRoomID;
        const draggedEndTime: Date = new Date(modifiedAgenda.endTime);
        draggedEndTime.setUTCHours(Number(currentHour.split(':')[0]));
        draggedEndTime.setUTCMinutes(Number(currentHour.split(':')[1]));

        const roomHostAgenda: any = this.doctorAllAgendas.find(roomAgenda => roomAgenda.room._id === hostRoomID);
        let hostAllAgendas =
          roomHostAgenda.surgeonopening.length
            ? roomHostAgenda.surgeonopening.filter((agenda: DoctorAgenda) => agenda.opening)
            : roomHostAgenda.surgeonopeninghistory.filter((agenda: DoctorAgenda) => agenda.opening);
        let nextStartTime = this.getNextAgendaStartTime(modifiedAgenda._id, hostAllAgendas);

        // If we dont have any following doctoragenda, on check the limit of resizing to roomEndTime
        let roomLimitReached, roomEndTime;
        if (!nextStartTime) {
          let tmpRoomEndHour = this.getRoomEND_HOUR(hostRoomID);
          roomEndTime = new Date(draggedEndTime);
          roomEndTime.setUTCHours(Number(tmpRoomEndHour.split(':')[0]) - 1);
          roomEndTime.setUTCMinutes(Number(tmpRoomEndHour.split(':')[1]));

          if (draggedEndTime > roomEndTime) {
            roomLimitReached = true;
          }
        }

        hostAllAgendas = hostAllAgendas.filter(agenda => agenda._id !== modifiedAgenda._id);
        const isNoConflict = this.canResizeAgendaInARoom(modifiedAgenda.startTime, draggedEndTime, hostAllAgendas, nextStartTime) && !roomLimitReached;
        const agendaToChange: any = this.doctorAgendas.find((agenda: DoctorAgenda) => agenda._id === modifiedAgenda._id);
        if (!this.hasAtLeasTAnHour(modifiedAgenda, currentHour)) {
          // Giving it the 1hour, when it smaller than 1hours
          const endDate = new Date(this.modifiedDoctorAgendas[this.indexI][this.indexJ].startTime);
          endDate.setUTCHours(endDate.getUTCHours() + 1);
          this.modifiedDoctorAgendas[this.indexI][this.indexJ].endTime = endDate.toISOString();
          agendaToChange.endTime = endDate.toISOString();
        } else if (agendaToChange && (isNoConflict || nextStartTime || roomLimitReached) && this.hasAtLeasTAnHour(modifiedAgenda, currentHour)) {
          if (isNoConflict) {
            // Giving it the dragged StarTime
            this.modifiedDoctorAgendas[this.indexI][this.indexJ].endTime = draggedEndTime.toISOString();
            agendaToChange.endTime = draggedEndTime.toISOString();
          } else if (roomLimitReached) {
            // Set doctoragenda end time equals to room end time
            this.modifiedDoctorAgendas[this.indexI][this.indexJ].endTime = roomEndTime;
            agendaToChange.endTime = roomEndTime;
          } else {
            // Giving it the nextAgenda StarTime
            this.modifiedDoctorAgendas[this.indexI][this.indexJ].endTime = nextStartTime ? nextStartTime : this.modifiedDoctorAgendas[this.indexI][this.indexJ].endTime;
            agendaToChange.endTime = nextStartTime ? nextStartTime : this.modifiedDoctorAgendas[this.indexI][this.indexJ].endTime;
          }
        }
        this.ngOnChanges(null);
      }
    }

    if (this.isDragging) {
      const scrollContainer: HTMLElement = document.getElementById('room-scrollable-container');
      // To scroll when it near bottom or top
      this.isNearToScroll = this.isUserNearBottom(event.clientY) || this.isUserNearTop(event.clientY, scrollContainer);
      this.disableScroll(scrollContainer);
      if (this.isVacation && this.hasEnterARoom(event.x, event.y)) {
        this.updateDraggingHours(this.hasEnterARoom(event.x, event.y).hour);
      }
      if (this.isNearToScroll && !this.isScrolling) {
        this.scrollAndDrag(event, scrollContainer);
      }
    }
  }

  getAgendaByID(agendaID): Agenda {
    for (let index = 0; index < this.doctorAllAgendas.length; index++) {
      const roomAgenda: any = this.doctorAllAgendas[index];
      const AllRoomAgenda =
        roomAgenda.surgeonopening.length
          ? roomAgenda.surgeonopening
          : roomAgenda.surgeonopeninghistory;
      const searchedAgenda = AllRoomAgenda.find(agenda => agenda._id === agendaID);
      if (searchedAgenda) {
        return searchedAgenda;
      }
    }
    return null;
  }

  getHostRoomByAgendaOpening(agendaID: string): any {
    for (let index = 0; index < this.doctorAllAgendas.length; index++) {
      const roomAgenda: any = this.doctorAllAgendas[index];
      const AllRoomAgenda =
        roomAgenda.surgeonopening.length
          ? roomAgenda.surgeonopening
          : roomAgenda.surgeonopeninghistory;
      const searchedAgenda = AllRoomAgenda.find(agenda => agenda._id === agendaID);
      if (searchedAgenda) {
        return roomAgenda;
      }
    }
    return null;
  }

  compareDates(dateA, dateB): boolean {
    const dateAA: Date = new Date(dateA);
    const dateBB: Date = new Date(dateB);

    return dateAA.getTime() < dateBB.getTime();
  }

  getNextAgendaStartTime(agendaID, hostAllAgenda): string {
    hostAllAgenda.sort((agendaA, agendaB) => {
      const startTimeA = agendaA.startTime;
      const startTimeB = agendaB.startTime;
      if (startTimeA > startTimeB) {
        return 1;
      }
      if (startTimeA < startTimeB) {
        return -1;
      }
      return 0;
    });
    const indexAgenda = hostAllAgenda.findIndex(agenda => agenda._id === agendaID);
    return indexAgenda < hostAllAgenda.length - 1
      ? hostAllAgenda[indexAgenda + 1].startTime
      : null;
  }

  hasAtLeasTAnHour(agenda, currentHour) {
    const startTime = this.getFormatLTS(agenda.startTime);
    const periodAgenda = this.getSplittedTimePeriode(startTime, currentHour);
    return periodAgenda > 1;
  }

  getFormatLTS(dateTime: string | Date): string {
    const date: Date = new Date(dateTime);
    const hours = date.getUTCHours() < 10 ? '0' + date.getUTCHours() : date.getUTCHours();
    const minutes = date.getUTCMinutes() < 10 ? '0' + date.getUTCMinutes() : date.getUTCMinutes();
    return hours + ':' + minutes;
  }

  scrollAndDrag(event, scrollContainer) {
    this.isScrolling = true;
    if (this.indexI !== -1 && this.indexJ !== -1) {
      const scrollInstance = this.scrollComponent.osInstance();
      const el: HTMLElement = document.getElementById('agenda-' + this.modifiedDoctorAgendas[this.indexI][this.indexJ]._id);
      const topEl: number = !el.style.top.includes('-') ? Number(el.style.top.replace(/\D/g, '')) : (Number(el.style.top.replace(/\D/g, '')) * -1);
      const marginToAdd: number = this.isUserNearBottom(event.clientY) ? 9 : -9;
      
      if ((marginToAdd > 0 && scrollInstance.scroll().ratio.y < 1) || (marginToAdd < 0 && scrollInstance.scroll().ratio.y > 0)) {
        scrollContainer.scrollTop += marginToAdd;
        this.scrollComponent.osInstance().scroll({ y : `+=${marginToAdd}` });
        el.style.top = (marginToAdd + topEl) + 'px';
      }
      
    }
    if (this.isNearToScroll) {
      setTimeout(() => {
        this.scrollAndDrag(event, scrollContainer);
      }, 50);
    } else {
      this.isScrolling = false;
    }
  }

  disableScroll(scrollContainer) {
    if (!scrollContainer) {
      return;
    }
    let supportsPassive = false;
    const wheelOpt = supportsPassive ? {passive: false} : false;
    const wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
    try {
      scrollContainer.addEventListener('test', null, Object.defineProperty({}, 'passive', {
        get() {
          supportsPassive = true;
        }
      }));
    } catch (e) {
    }
    scrollContainer.addEventListener('DOMMouseScroll', this.preventDefault, false); // older FF
    scrollContainer.addEventListener(wheelEvent, this.preventDefault, wheelOpt); // modern desktop
    scrollContainer.addEventListener('touchmove', this.preventDefault, wheelOpt); // mobile
    scrollContainer.addEventListener('keydown', this.preventDefaultForScrollKeys, false);
  }

  // call this to Enable
  enableScroll(scrollContainer) {
    if (!scrollContainer) {
      return;
    }
    let supportsPassive = false;
    const wheelOpt = supportsPassive ? {passive: false} : false;
    const wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
    try {
      scrollContainer.addEventListener('test', null, Object.defineProperty({}, 'passive', {
        get() {
          supportsPassive = true;
        }
      }));
    } catch (e) {
    }
    scrollContainer.removeEventListener('DOMMouseScroll', this.preventDefault, false);
    scrollContainer.removeEventListener(wheelEvent, this.preventDefault, wheelOpt);
    scrollContainer.removeEventListener('touchmove', this.preventDefault, wheelOpt);
    scrollContainer.removeEventListener('keydown', this.preventDefaultForScrollKeys, false);
  }

  private isUserNearBottom(mouseY: number): boolean {
    const threshold = 70;
    const position = mouseY;
    const height = document.body.scrollHeight;
    return position > height - threshold;
  }

  private isUserNearTop(mouseY: number, element: Element): boolean {
    const position = mouseY;
    const height = element.getBoundingClientRect().top;
    return position < height + 70;
  }

  hasEnterARoom(x, y): any {
    y = this.isResizing ? y : y - this.marginBetweenMouseAndTopElement;
    const agendaDivsElements: NodeListOf<Element> = document.querySelectorAll('.agenda-room-container-by-id');
    for (let index = 0; index < agendaDivsElements.length; index++) {
      const elementDiv: Element = agendaDivsElements[index];
      const roomID = elementDiv.id;

      const top = elementDiv.getBoundingClientRect().top;
      const bottom = elementDiv.getBoundingClientRect().bottom;
      const left = elementDiv.getBoundingClientRect().left;
      const right = elementDiv.getBoundingClientRect().right;
      if (x >= left && x <= right && y >= top && y <= bottom) {
        return {
          hostRoomID: roomID,
          hour: this.isVacation ? this.getCorrespondingHour(y - elementDiv.getBoundingClientRect().top, roomID) : null
        };
      }
    }
  }

  getRoomBounding(roomID: string): any {
    const agendaDivsElements: NodeListOf<Element> = document.querySelectorAll('.agenda-room-container-by-id');
    for (let index = 0; index < agendaDivsElements.length; index++) {
      const elementDiv: Element = agendaDivsElements[index];
      if (roomID === elementDiv.id) {
        // get room boundings
        const roomHeight: number = elementDiv.getBoundingClientRect().height;
        // get header boundings
        const rContainerMargins = 8;
        const childrenDivs: HTMLCollection = elementDiv.children;
        const rContainer: Element = childrenDivs.namedItem('r-container');
        const headerHeight: number = rContainer.getBoundingClientRect().top - elementDiv.getBoundingClientRect().top + rContainerMargins;
        // get Hour boundings
        let hourHeight: number;
        if (this.isVacation) {
          let hoursMargins = 0;
          const hourDiv: Element = document.getElementById(this.room._id + '-hour-0');
          hoursMargins += parseInt(window.getComputedStyle(hourDiv).marginBottom, 10);
          hoursMargins += parseInt(window.getComputedStyle(hourDiv).marginTop, 10);
          hourHeight = hourDiv ? hourDiv.getBoundingClientRect().height + hoursMargins : 21;
        }
        return {roomHeight, headerHeight, hourHeight};
      }
    }
    return {roomHeight: 650, headerHeight: 77, hourHeight: 21};
  }

  getCorrespondingHour(height: number, roomID: string): string {
    const roomBounding = this.getRoomBounding(roomID);
    const divMaxHeight = roomBounding.roomHeight;
    const hourHeight = roomBounding.hourHeight;
    const headerHeight = roomBounding.headerHeight;

    const endHour: string = this.getRoomEND_HOUR(roomID);
    const startHour: string = this.getRoomSTART_HOUR(roomID);

    const maxHour = Number(endHour.split(':')[0]);
    const START_HOUR: string = Number(startHour.split(':')[0]) + '';
    const END_HOUR: string = maxHour + ':00';

    if (0 < height && height < headerHeight + hourHeight) {
      return height > headerHeight && height <= headerHeight + hourHeight / 2
        ? START_HOUR + ':00'
        : height > headerHeight + hourHeight / 2 && height <= headerHeight + 3 * hourHeight / 4
          ? START_HOUR + ':15'
          : height > headerHeight + hourHeight * 3 / 4 && height < headerHeight + 3.5 * hourHeight / 4
            ? START_HOUR + ':30'
            : height >= headerHeight + 3.5 * hourHeight / 4 && height <= headerHeight + hourHeight
              ? START_HOUR + ':45'
              : START_HOUR + ':00';
    }
    for (let previousHeight = headerHeight + hourHeight, hour = this.startHour + 1; hour < maxHour; hour++ && previousHeight <= divMaxHeight, previousHeight += hourHeight) {
      if (previousHeight < height && height < previousHeight + hourHeight) {
        return height > previousHeight && height <= previousHeight + hourHeight / 2
          ? hour + ':00'
          : height > previousHeight + hourHeight / 2 && height <= previousHeight + 3 * hourHeight / 4
            ? hour + ':15'
            : height > previousHeight + hourHeight * 3 / 4 && height < previousHeight + 3.5 * hourHeight / 4
              ? hour + ':30'
              : hour + ':45';
      }
    }
    return END_HOUR;
  }

  updateDate(isAdd: boolean) {
    if (this.dasToUpdate === null || this.indexI === -1 || this.indexJ === -1) {
      return;
    }
    for (let i = 0; i < this.doctorAgendas.length; i++) {
      if (this.doctorAgendas[i]._id === this.modifiedDoctorAgendas[this.indexI][this.indexJ]._id) {
        if (!this.agendaBeforeResize) {
          this.agendaBeforeResize = {...this.modifiedDoctorAgendas[this.indexI][this.indexJ]};
        }

        if (isAdd) {
          this.doctorAgendas[i].endTime = this.manageRemoveMinutes(this.doctorAgendas[i].startTime, this.doctorAgendas[i].endTime, 15);
          this.dasToUpdate.endTime = this.manageRemoveMinutes(this.doctorAgendas[i].startTime, this.doctorAgendas[i].endTime, 15);
        } else {
          this.doctorAgendas[i].endTime = this.manageAddMinutes(this.doctorAgendas[i].startTime, this.doctorAgendas[i].endTime, 15);
          this.dasToUpdate.endTime = this.manageAddMinutes(this.doctorAgendas[i].startTime, this.doctorAgendas[i].endTime, 15);
        }
      }
    }
    this.ngOnChanges(null);
  }

  isSurgeonOpenHistAgenda(hostRoom: any): boolean {
    return hostRoom.surgeonopening.length === 0;
  }

  getHours(agenda: Agenda): string {
    const d1 = new Date(agenda.startTime);
    const d2 = new Date(agenda.endTime);
    return (
      d1.getUTCHours() + 'h' + (d1.getUTCMinutes() ? d1.getUTCMinutes() : '00')
      + ' - ' +
      d2.getUTCHours() + 'h' + (d2.getUTCMinutes() ? d2.getUTCMinutes() : '00')
    );
  }

  getStartHours(agenda: Agenda): string {
    return this.getFormatLTS(agenda.startTime);
  }

  getEndHours(agenda: Agenda): string {
    return this.getFormatLTS(agenda.endTime);
  }

  updateDraggingHours(startTime: string) {
    const d1 = new Date(this.modifiedDoctorAgendas[this.indexI][this.indexJ].startTime);
    const d2 = new Date(this.modifiedDoctorAgendas[this.indexI][this.indexJ].endTime);
    const period = this.getSplittedTimePeriode(this.getFormatLTS(d1), this.getFormatLTS(d2));
    const startDate = new Date();
    startDate.setUTCHours(Number(startTime.split(':')[0]));
    startDate.setUTCMinutes(Number(startTime.split(':')[1]));
    const endDate = this.getAgendaEndTimeByPeriod(startDate.toISOString(), period);
    this.draggedStartTime = startDate;
    this.draggedEndTime = endDate;
  }

  addUpdateDate(item) {
  }

  addMinutes(startDate, minutes) {
    const newd = new Date(startDate);

    return new Date(newd.getTime() + minutes * 60000);
  }

  manageAddMinutes(startDate, endDate, minutes) {
    const tmp = this.addMinutes(endDate, minutes);
    const date = tmp.toISOString();

    const roomStarTimes = [];
    this.doctorAgendas.forEach(agenda => {
      if (agenda.startTime > startDate && agenda.opening) {
        roomStarTimes.push(this.getFormatLTS(agenda.startTime));
      }
    });
    let roomFirstStarTime = roomStarTimes.length
      ? roomStarTimes.reduce(function (p, v) {
        return (p < v ? p : v);
      })
      : '';
    roomFirstStarTime = roomFirstStarTime.split(':');
    const limitHours = roomStarTimes.length
      ? Number(roomFirstStarTime[0])
      : 20;
    const limitMin = roomStarTimes.length
      ? Number(roomFirstStarTime[1])
      : 0;

    if (
      tmp.getUTCHours() < limitHours
      || (tmp.getUTCHours() === limitHours && tmp.getUTCMinutes() <= limitMin)
    ) {
      return date;
    }
    return endDate;
  }

  manageRemoveMinutes(startDate, endDate, minutes) {
    const newd = new Date(startDate);
    const tmp = this.removeMinutes(endDate, minutes);
    const date = tmp.toISOString();
    const isBefore = moment(date).isBefore(newd);
    const isSame = moment(date).isSame(newd);

    if (isBefore === false && isSame === false && moment(tmp).diff(moment(newd), 'hours') >= 1) {
      return date;
    }
    return endDate;
  }

  removeMinutes(date, minutes) {
    const newd = new Date(date);

    return new Date(newd.getTime() - minutes * 60000);
  }


  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  setIntialTime() {
    this.startHour = Number(this.from) ? Number(this.from) : 7;
    this.endHour = Number(this.to) ? Number(this.to) + 1 : 21;
    const stepHour: number = !this.isFullMode ? 60 : 30;

    this.generateHours(this.startHour, this.endHour, stepHour);
    this.fullNumberOfHours = this.endHour - this.startHour + 1;
  }

  generateHours(startHour: number, endHour: number, stepSizeMinutes: number) {
    const hours: string[] = [];
    if (this.isOneRoom) {
      let i = startHour * 60;
      while (i < endHour * 60) {
        hours.push(
          ('0' + Math.floor(i / 60)).slice(-2) + ':' + ('0' + (i % 60)).slice(-2)
        );
        i = i + stepSizeMinutes;
      }
    } else {
      let i = startHour * 60;
      while (i < endHour * 60) {
        hours.push(
          ('0' + Math.floor(i / 60)).slice(-2) + ':' + ('0' + (i % 60)).slice(-2)
        );
        i = i + stepSizeMinutes;
      }
    }
    this.hours = hours;
  }

  getPDASize(pda) {
    const fullpercent = (this.fullNumberOfHours) * 60;
    const startTime = new Date(pda.startTime);
    const endTime = new Date(pda.endTime);

    const PDASize = (
      (((endTime.getUTCHours() - startTime.getUTCHours()) * 60 +
          (endTime.getUTCMinutes() - startTime.getUTCMinutes())) *
        100) /
      fullpercent
    );
    var LARGE_SO_RATE: number = ((endTime.getUTCHours() - startTime.getUTCHours()) <= 9) ? 0.01 : -0.019;
    var SMALL_SO_RATE: number = this.fullNumberOfHours <= 13 ? 0.02 : 0;
    const SO_RATE: number = this.fullNumberOfHours <= 16 ? SMALL_SO_RATE : LARGE_SO_RATE;
    return PDASize + PDASize * SO_RATE - 0.3;
  }

  groupBy(list: any[], key: string): any[] {
    const doctorAgendas = [];
    const groupedAgendas = list.reduce(function (mList, doctorAgenda) {
      const date = new Date(doctorAgenda[key]);
      const hours = date.getUTCHours().toString();
      const minutes = date.getUTCMinutes().toString();
      const title = hours + ':' + minutes;
      (mList[title] = mList[title] || []).push(doctorAgenda);
      return mList;
    }, {});
    for (const itemKey of Object.keys(groupedAgendas)) {
      doctorAgendas.push(groupedAgendas[itemKey]);
    }
    return doctorAgendas;
  }

  getHourFromDate(stringDate: string): number {
    const date = new Date(stringDate);
    return date.getUTCHours();
  }

  getTotalMinutesFromDate(stringDate: string): number {
    const date = new Date(stringDate);
    return date.getUTCHours() * 60 + date.getUTCMinutes();
  }

  cleanSmallTimes(doctorAgendas) {
    const newDAs: any[] = [];
    for (const da of doctorAgendas) {
      if (
        this.getTotalMinutesFromDate(da.endTime) -
        this.getTotalMinutesFromDate(da.startTime) >
        30
      ) {
        newDAs.push(da);
      }
    }
    return newDAs;
  }

  collectSurgeons(das: any[]): any[] {
    const daNewList: any[] = [];
    const doctorAgendas: any[] = JSON.parse(JSON.stringify(das));
    for (let i = 0; i < doctorAgendas.length; i++) {
      if (i === 0) {
        daNewList.push(doctorAgendas[0]);
      } else {
        if (
          doctorAgendas[i].surgeon &&
          daNewList[daNewList.length - 1].surgeon._id ===
          doctorAgendas[i].surgeon._id
        ) {
          daNewList[daNewList.length - 1].endTime = doctorAgendas[i].endTime;
        } else {
          daNewList.push(doctorAgendas[i]);
        }
      }
    }
    return daNewList;
  }

  limitTime(doctorAgendas: any[]): any[] {
    for (const item of doctorAgendas) {
      const time = new Date(item.endTime);
      const startTime = new Date(item.startTime);
      const hour = time.getUTCHours();
      const hourStart = startTime.getUTCHours();
      if (hour <= 1) {
        time.setUTCHours(hourStart + 1);
        const t = time.toISOString();
        item.endTime = t;
      }
    }
    return doctorAgendas;
  }

  clearOpeningFalse(das: any[]): any[] {
    return das.filter((item) => {
      return !('opening' in item) || item.opening === true;
    });
  }

  markCurrentResizedSugeonOpening(das: any[]): void {
    if (this.isResizing) {
      for (const PDAs of das) {
        for (const SO of PDAs) {
          if (this.isResizing && this.modifiedDoctorAgendas[this.indexI][this.indexJ].isResizing && SO._id === this.modifiedDoctorAgendas[this.indexI][this.indexJ]._id) {
            SO.isResizing = true;      
          }
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isOneRoom !== undefined && this.isOneRoom === true) {
      this.calcMinSize();
    }
    this.currentEnteredRoom = changes && changes.currentEnteredRoom && changes.currentEnteredRoom.currentValue;
    if (!changes || !changes.currentEnteredRoom || (changes.currentEnteredRoom && changes.currentEnteredRoom.isFirstChange())) {
      this.refreshData();
    }
    if (!this.isShowIntervetions) {
      this.isSpecialNeeds();
    }
  }

  refreshData(): void {
    this.setIntialTime();
    let das: DoctorAgenda[];
    if (this.doctorAgendas) {
      das = JSON.parse(JSON.stringify(this.doctorAgendas)); 
    } else {
      das = [];
    }
    das = this.clearOpeningFalse(das);
    if (this.doctorAgendas) {
      das = das.sort((a, b) => {
        const firstStartTime = new Date(a.startTime);
        const secondStartTime = new Date(b.startTime);
        return (
          firstStartTime.getUTCHours() * 60 +
          firstStartTime.getUTCMinutes() -
          (secondStartTime.getUTCHours() * 60 + secondStartTime.getUTCMinutes())
        );
      });
      das = this.limitTime(das);
      das = this.groupBy(das, 'startTime');
      if (das && das.length > 0 && this.isFullMode) {
        const smalleststartHour = this.getHourFromDate(das[0][0].startTime);
        const highestEndHour =
          this.getHourFromDate(das[das.length - 1][0].endTime) + 2;
        this.fullNumberOfHours = highestEndHour - smalleststartHour;

        this.generateHours(
          this.startHour,
          highestEndHour,
          this.fullMode || this.isOneRoom
            ? this.getStepSize()
            : 60
        );
        this.startHour = smalleststartHour;
      }
      das = this.getSizedItems(das);
      das = this.pushDeleteVisibility(das);
      das = this.pushDraggingShowLine(das);
      das = this.pushDraggingShowHours(das);
      this.markCurrentResizedSugeonOpening(das);
      if (!this.isFullMode) {
        das = this.pushBreaksv2(das);
      }
      this.modifiedDoctorAgendas = das;
      if (!this.isShowIntervetions) {
        this.isSpecialNeeds();
      }
      if (this.isDoctorAgenda && this.isFullMode) {
        this.addEmptySlotOfTime();
      }
      this.showContent = true;
    }
  }

  addEmptySlotOfTime(): void {
    let previousEndTime = null;
    for (let i = 0; i < this.modifiedDoctorAgendas.length; i += 1) {
      const PDAs = this.modifiedDoctorAgendas[i];
      if (previousEndTime) {
        const currentStartTime = this.getStartTimeOfPDAs(PDAs);
        if (currentStartTime > previousEndTime) {
          // Add empty time slot to doctor agendas
          const emptyTimeSlot = [
            {
              isEmpty: true,
              startTime: previousEndTime,
              endTime: currentStartTime
            }
          ];
          this.modifiedDoctorAgendas.splice(i, 0, emptyTimeSlot);
        }
      }
      previousEndTime = this.getEndTimeOfPDAs(PDAs);
    }
  }

  getStartTimeOfPDAs(PDAs: any[]): Date {
    let minStartTime;
    for (const DA of PDAs) {
      if (!minStartTime) {
        minStartTime = new Date(DA.startTime);
      } else {
        if (DA.startTime < minStartTime) {
          minStartTime = DA.startTime;
        }
      }
    }
    return minStartTime;
  }

  getEndTimeOfPDAs(PDAs: any[]): Date {
    let maxEndTime;
    for (const DA of PDAs) {
      if (!maxEndTime) {
        maxEndTime = new Date(DA.endTime);
      } else {
        if (DA.endTime > maxEndTime) {
          maxEndTime = DA.endTime;
        }
      }
    }
    return maxEndTime;
  }

  getStepSize(): number {
    if (!this.doctorAgendas.length) {
      return 30;
    }

    const MIN_STEP_SIZE_PER_ROOM = 5;
    const sortedAgenda: DoctorAgenda[] = this.doctorAgendas.sort(
      (agendaA, agendaB) => {
        const hoursDiffA: number = new Date(agendaA.endTime).getTime() - new Date(agendaA.startTime).getTime();
        const hoursDiffB: number = new Date(agendaB.endTime).getTime() - new Date(agendaB.startTime).getTime();
        if (hoursDiffA < hoursDiffB) {
          return -1;
        }
        if (hoursDiffA > hoursDiffB) {
          return 1;
        }
        return 0;
      });
    const smallestAgendaHour: number = this.getSplittedTimePeriode(this.getFormatLTS(sortedAgenda[0].startTime), this.getFormatLTS(sortedAgenda[0].endTime));
    const endMinutes = new Date(sortedAgenda[0].endTime).getUTCHours() * 60 + new Date(sortedAgenda[0].endTime).getUTCMinutes();
    const startMinutes = new Date(sortedAgenda[0].startTime).getUTCHours() * 60 + new Date(sortedAgenda[0].startTime).getUTCMinutes();

    const smallestAgendaMinutes: number = endMinutes - startMinutes;
    const stepSize: number =
      smallestAgendaHour >= 1
        ? Number((smallestAgendaMinutes / MIN_STEP_SIZE_PER_ROOM).toFixed(0))
        : Number((smallestAgendaMinutes / MIN_STEP_SIZE_PER_ROOM).toFixed(0));

    return stepSize > 4 ? stepSize : 4;
  }

  removeDoctorAgendaFromList(doctorAgendaId: string): void {
    for (let i = 0; i < this.doctorAgendas.length; i++) {
      if (this.doctorAgendas[i]._id === doctorAgendaId) {
        this.doctorAgendas.splice(i, 1);
      }
    }
  }

  onSpecialNeedsClick(da) {
    this.dialog.open(NeedsPopupComponent, {
      width: "500px",
      data: {
        title: "Besoins Spéciaux - Salle " + this.room.roomNumber + " - " + da.surgeon.firstName + " " + da.surgeon.lastName,
        needs: da.needs,
        soMode: true
      }
    })
  }

  Confirm(das: DoctorAgenda[], i: number, j: number, event: Event, customDelete?: boolean): void {
    if (this.readOnlyMode || this.onSurgeonSelect) {
      return;
    }
    this.hasEnterDelete = false;
    event.stopPropagation();
    if (this.isOneRoom /*|| window.location.href.endsWith("/par-interventions")*/) {
      if (das && das[j]) {
        this.removeOneDoctorAgenda(das[j]._id, i, j);
      }
    } else {
      if (this.isOnlyHist) {
        this.ConfirmForNonImpatedPrograms(das, i, j, event);
      } else {
        if (this.isVacation) {
          this.deleteWithBufferCheck(das, i, j, event, customDelete);
        } else {
          const description = `Etes vous sûr(e) de vouloir supprimer cette intervention ?`;
          const title = 'Supprimer cette intervention ?';

          this.popupManagerService.openConfirmationPopup(title, description, 380, 'danger', 'Oui, supprimer').afterClosed().subscribe((result) => {
            if (result) {
              this.remove(das, i, j, event, this.notifyAnesth);
            }
          });
        }
      }
    }

  }

  deleteWithBufferCheck(das: DoctorAgenda[], i: number, j: number, event: Event, customDelete?: boolean) {
    this.bufferProgramService
      .getRoomPrograms(this.date.substring(0, 10), this.room._id)
      .subscribe((res) => {
        let message1 = "";
        if (window.location.href.endsWith("/par-interventions"))
          message1 = "Etes vous sûr(e) de vouloir supprimer cette intervention ?"
        else
          message1 = `Êtes vous sûr(e) de vouloir supprimer cette vacation ?`;
        const message2 = `Cette vacation est déjà associée à un programme le ${this.datePipe.transform(
          this.date.substring(0, 10),
          'dd/MM/yyyy'
        )}`;
        const profileType = 'room';

        const programs = res.programs;
        if (customDelete) {
          // this.saveCoWorker(programs, BUFFER_POSITION_ANESTHESISTS);
          // this.saveCoWorker(programs, BUFFER_POSITION_NURSES);
          this.remove(das);
        } else {
          if (res.existPrograms && !window.location.href.endsWith("/par-interventions")) {
            const description = `<p>Cette vacation est déjà associée à un programme le ${this.datePipe.transform(this.date.substring(0, 10), 'dd/MM/yyyy')}</p><p>Êtes vous sûr(e) de vouloir supprimer cette vacation ?</p>`;
            this.popupManagerService.openConfirmationPopup('Supprimer cette vacation ?', description, 370, 'danger', 'Oui, supprimer').afterClosed()
            .subscribe(res => {
              if (res) {
                  // this.saveCoWorker(programs, BUFFER_POSITION_ANESTHESISTS);
                  // this.saveCoWorker(programs, BUFFER_POSITION_NURSES);
                  if (programs[0] && this.storageService.programsToSaveInCache.every((prog) => prog.room._id != programs[0].room._id)) {
                    this.storageService.programsToSaveInCache.push(...programs);
                  }
                  this.remove(das, i, j, event, this.notifyAnesth);
                }
              });
          } else {
            this.ConfirmForNonImpatedPrograms(das, i, j, event);
          }
        }
      });
  }

  // savePrograms() {
  //   this.bufferProgramService
  //     .getRoomPrograms(this.date.substring(0, 10), this.room._id)
  //     .subscribe((res) => {
  //       const programs = res.programs;
  //       this.saveCoWorker(programs, BUFFER_POSITION_ANESTHESISTS);
  //       this.saveCoWorker(programs, BUFFER_POSITION_NURSES);
  //     });
  // }

  // saveCoWorker(programs, positionInBuffer) {
  //   if (programs.length > 0) {
  //     // remove old coworker
  //     const day = programs[0].date;
  //     const roomId = programs[0].room._id;
  //     const localStorageKey = this.utilisService.getSavedRoomsLocalStorageKey(
  //       positionInBuffer
  //     );
  //     let rooms = this.storageService.getData(localStorageKey);
  //     if (rooms) {
  //       rooms = rooms.filter(
  //         (elt) => !(elt.roomId == roomId && elt.day == day)
  //       );
  //     }
  //     const coWorkerId = programs[0][positionInBuffer];
  //     if (coWorkerId && (!isArray(coWorkerId) || coWorkerId.length > 0)) {
  //       const room = {
  //         coWorkerId,
  //         day,
  //         roomId,
  //       };
  //       if (rooms) {
  //         rooms.push(room);
  //       } else {
  //         rooms = [room];
  //       }
  //       this.storageService.saveData(localStorageKey, rooms);
  //     }
  //   }
  // }

  ConfirmForNonImpatedPrograms(
    das: DoctorAgenda[],
    i: number,
    j: number,
    event: Event
  ): void {
    event.stopPropagation();
    let description = `Etes vous sûr(e) de vouloir supprimer cette vacation?`;
    let title = 'Supprimer cette vacation ?'

    this.popupManagerService.openConfirmationPopup(title, description, 340, 'danger', 'Oui, supprimer').afterClosed().subscribe((result) => {
      if (result) {
        this.remove(das, i, j, event, this.notifyAnesth);
      }
    });
  }

  remove(
    das: DoctorAgenda[],
    i?: number,
    j?: number,
    event?: Event,
    notify?: boolean
  ) {
    if (!this.isVacation) {
      this.deletedIntervention.emit(das);
      return;
    }

    let agendaToDelete;
    if (event) {
      event.stopPropagation();
    }
    if (!this.isFullMode) {
      if (das.length === 1) {
        agendaToDelete = das[0];
      } else {
        agendaToDelete = das[j];
      }
    } else {
      agendaToDelete = this.modifiedDoctorAgendas[i][j];
    }

    if (!this.isOnlyHist) {
      this.doctorAllAgendas.forEach((doctorAgenda: any) => {
        let deletedAgenda;
        if (doctorAgenda.surgeonopening) {
          deletedAgenda = doctorAgenda.surgeonopening.find(agenda => agenda._id === agendaToDelete._id);
        }
        const deletedAgendaHist = doctorAgenda.surgeonopeninghistory.find(agenda => agenda._id === agendaToDelete._id);
        if (deletedAgenda) {
          doctorAgenda.isModified = true;
          deletedAgenda.opening = false;
          deletedAgenda.description = 'closed opening';
        }
        if (deletedAgendaHist && doctorAgenda.surgeonopening && !doctorAgenda.surgeonopening.length) {
          doctorAgenda.surgeonopeninghistory.forEach(agendaHist => {
            const agenda = {...agendaHist};
            if (agenda._id === deletedAgendaHist._id) {
              doctorAgenda.isModified = true;
              agenda.opening = false;
              agenda.description = 'closed opening';
            }
            agenda._id = idStringGenerator();
            doctorAgenda.surgeonopening.push(agenda);
          });
        }
      });
    } else {
      this.doctorAllAgendas.forEach((doctorAgenda: any) => {
        const deletedAgenda = doctorAgenda.surgeonopeninghistory.find(agenda => agenda._id === agendaToDelete._id);
        if (deletedAgenda) {
          doctorAgenda.isModified = true;
          doctorAgenda.surgeonopeninghistory =
            doctorAgenda.surgeonopeninghistory.filter(agenda => agenda._id !== agendaToDelete._id);
            this.onChange.emit();
            this.configDelete.emit(deletedAgenda)
        }
      });
    }

    this.ngOnChanges(null);
    this.updateRoomsData.emit();

  }

  removeOneDoctorAgenda(doctorAgendaId: string, i: number, j: number) {
    if (this.removeDoctorAgendaSubscription) {
      this.removeDoctorAgendaSubscription.unsubscribe();
    }

    const description = `Etes vous sûr(e) de vouloir supprimer cette intervention ?`;
    this.popupManagerService.openConfirmationPopup('Supprimer cette intervention ?', description, 370, 'danger', 'Oui, supprimer').afterClosed().subscribe((result) => {
      if (result) {
        this.removeDoctorAgendaSubscription = this.doctorAgendasService
          .deleteOneDoctorAgenda(doctorAgendaId)
          .subscribe((ok) => {
            this.removeDoctorAgendaFromList(this.modifiedDoctorAgendas[i][j]._id);
            this.ngOnChanges(null);
            this.refresh.emit('refresh');
          });
      }
    });
  }

  getSizedItems(docAgendas) {
    for (let i = 0; i < docAgendas.length; i++) {
      const PDAs = docAgendas[i]; 
      for (let j = 0; j < PDAs.length; j++) {
  
        const currentPDA = PDAs[j];
        currentPDA.size = this.getPDASize(currentPDA);
      }
    }
  
    for (let i = 1; i < docAgendas.length; i++) {
      const prevPDA = docAgendas[i - 1];
      const currentPDA = docAgendas[i];
  
      const prevEndTime = new Date(prevPDA[0].endTime);
      const currentStartTime = new Date(currentPDA[0].startTime);
  
      if (prevEndTime.getTime() === currentStartTime.getTime()) {
          prevPDA[0].size -= 0.2;
      }
    }
  
    return docAgendas;
  }
  
  

  doctorAgendaRightClicked(event: any, doctorAgenda: any): void {
    if (doctorAgenda.isEmpty || this.readOnlyMode || this.onSurgeonSelect) {
      return;
    }

    event.preventDefault();

    this.onRightClickOnDoctorAgenda.emit({ event, doctorAgenda });
  }

  pushDeleteVisibility(docAgendas: any[]): any[] {
    for (const PDAs of docAgendas) {
      for (const da of PDAs) {
        da.isHovering = false;
      }
    }
    return docAgendas;
  }

  pushDraggingShowLine(docAgendas: any[]): any[] {
    for (const PDAs of docAgendas) {
      for (const da of PDAs) {
        da.isDragging = false;
      }
    }
    return docAgendas;
  }

  pushDraggingShowHours(docAgendas: any[]): any[] {
    for (const PDAs of docAgendas) {
      for (const da of PDAs) {
        da.showHours = false;
      }
    }
    return docAgendas;
  }

  getMaxDAsEndTime(PDAs) {
    let maxEndTime = '0';
    for (const da of PDAs) {
      const daEndTime = da.endTime;
      if (daEndTime > maxEndTime) {
        maxEndTime = daEndTime;
      }
    }
    return maxEndTime;
  }

  getDistance(topDAs, BottomDAs) {
    if (BottomDAs === undefined) {
      return 0;
    }
    const maxTopEndTime = new Date(this.getMaxDAsEndTime(topDAs));
    const bottomStartTime = new Date(BottomDAs[0].startTime);
    const fullpercent = this.fullNumberOfHours * 60;
    const distance =
      (((bottomStartTime.getUTCHours() - maxTopEndTime.getUTCHours()) * 60 +
          (bottomStartTime.getUTCMinutes() - maxTopEndTime.getUTCMinutes())) *
        100) /
      fullpercent;
    return distance;

  }

  getMargin_Rate(): number {
    if (this.fullNumberOfHours <= 13) {
      return 0.086;
    } else if (this.fullNumberOfHours === 14) {
      return 0.079;
    } else if (this.fullNumberOfHours === 15) {
      return 0.075;
    } else if (this.fullNumberOfHours <= 17) {
      return 0.065;
    }
    return 0.055;
  }

  getDistanceBetweenDates(date1, date2) {
    const fullpercent = this.fullNumberOfHours * 60;

    const d1 = new Date(date1);
    const d2 = new Date(date2);
    const BREAKS_MARGIN_RATE: number = this.getMargin_Rate();
    const distance =
      (((d2.getUTCHours() - d1.getUTCHours()) * 60 +
          (d2.getUTCMinutes() - d1.getUTCMinutes())) *
        100) /
      fullpercent;
    return {distance: (distance + (distance * BREAKS_MARGIN_RATE))};
  }

  pushBreaksv2(docAgendas) {
    if (docAgendas.length) {
      const dayStart = new Date();
      dayStart.setUTCHours(this.startHour);
      dayStart.setUTCMinutes(0);

      const MARGIN_RATE = 2;
      const docAgendasWithBreaks = [];
      let distBefore = 0;

      for (let i = 0; i < docAgendas.length; i++) {
        if (i === 0) {
          distBefore = this.fullNumberOfHours <= 15 ? 0.2 : 0.6;
        } else {
          const precedentAgenda: any = docAgendas[i - 1][0];
          const agendaEarlySize = this.getDistanceBetweenDates(
            dayStart.toISOString(),
            precedentAgenda.startTime
          ).distance;
          distBefore = precedentAgenda.size + agendaEarlySize + MARGIN_RATE;
        }
        let distance =
          this.getDistanceBetweenDates(dayStart.toISOString(), docAgendas[i][0].startTime).distance - distBefore;
        distance = distance < -1 ? -1.6 : distance;
        docAgendasWithBreaks.push({distance});
        docAgendasWithBreaks.push(docAgendas[i]);
      }

      return docAgendasWithBreaks;
    }
  }

  isAgendaCollapsed(da: DoctorAgenda): boolean {
    return (
      this.doctorAgendas.find(
        (agenda: DoctorAgenda) => this.getFormatLTS(agenda.endTime) === this.getFormatLTS(da.startTime)
      ) !== undefined ? true : false
    );
  }

  getDisplayMode(height) {
    if (this.isFullMode || height * (this.isFullMode ? 2 : 1) >= 23) {
      return 'column';
    }
    return 'row';
  }

  getDisplaySize(height) {
    if (height * (this.isFullMode ? 2 : 1) >= 11) {
      return 'normal';
    }
    return 'small';
  }

  getPDAsMaxHeight(PDAs) {
    let maxHeight = 0;
    if (!this.isArray(PDAs)) {
      maxHeight = PDAs.distance + 2;
    } else {
      for (const da of PDAs) {
        const daHeight = this.getPDASize(da);
        if (daHeight > maxHeight) {
          maxHeight = daHeight;
        }
      }
    }
    if (maxHeight === 0) {
      maxHeight = 0.1;
    }
    return maxHeight;
  }

  getFxFlex(length) {
    if (length < 1) {
      return 'no data';
    }
    return ((100 - (length === 1 ? 0 : 2)) / length);
  }

  getBackgroundColor(surgeon) {
    if (surgeon.specialties && surgeon.specialties.length > 0) {
      const specialty = surgeon.specialties.find((specialty) => String(specialty.hospital) === String(this.room.hospital._id))
      return this.specialtyColorService.getColorForSpecialty(specialty ? specialty.name ? specialty.name : null : null);
    }
  }

  getSpecialtyFontColor(surgeon: Profile): string {
    let surgeonType;
    if (surgeon) {
      if (surgeon.specialties && surgeon.specialties.length > 0) {
        const specialty = surgeon.specialties.find((specialty) => String(specialty.hospital) === String(this.room.hospital._id))
        surgeonType = specialty.name;
      }
    }
    return getSpecialtyFontColor(surgeonType);
  }

  getMargin() {
    if (this.isFullMode || !this.hours) {
      return 15;
    }
    return (405.6 / this.hours.length - 16.2).toFixed(2);
  }

  openDialog(i: number, j: number): void {
    if (this.isPopup || this.isDisablePopup || this.readOnlyMode || this.onSurgeonSelect) {
      return;
    }
    if (!this.isFullMode || !this.isDoctorAgenda) {
      return;
    }
    this.openInterventionPopupComponent(i, j);

  }

  openInterventionPopupComponent(i: number, j: number) {
    const dialogRef = this.dialog.open(InterventionDetailsPopupComponent, {
      width: '960px',
      data: {modifiedDoctorAgendas: this.modifiedDoctorAgendas[i][j], room: this.room, rooms: this.doctorAllAgendas, frontEndDoctorAgendas: []},
    });
    dialogRef.afterClosed().subscribe((message) => {
      if (message && message.status === 'refresh') {
        this.refresh.emit('refresh');
      }
    });
  }

  mouseEnterDelete(event, i: number, j: number) {
    if (!this.readOnlyMode || !this.onSurgeonSelect) {
      this.hasEnterDelete = true;
    }
  }

  mouseLeftDelete(event, i: number, j: number) {
    if (!this.readOnlyMode || !this.onSurgeonSelect) {
      this.hasEnterDelete = false;
    }
  }

  mouseEnter(event, i: number, j: number) {
    this.modifiedDoctorAgendas[i][j].isHovering = true;
  }

  mouseEnterSurgeon(surgeonOpening: any, j: number): void {
    if (this.isDisableAllEvents && !this.readOnlyMode && !this.onSurgeonSelect) {
      this.initTooltip(surgeonOpening, j);
    }
  }
  
  mouseLeaveSurgeon(): void {
    if (!this.readOnlyMode || !this.onSurgeonSelect) {
      this.resetTooltip();
    }
  }

  initTooltip(surgeonOpening: any, surgeonOpeningIndexInSameRoomArg: number): void {
    const tempTooltip = {
      surgeonName: '',
      surgeonOpeningIndexInSameRoom: surgeonOpeningIndexInSameRoomArg
    };

    if (!surgeonOpening) {
      return;
    }

    let surgeon;

    if (surgeonOpening.surgeon && surgeonOpening.surgeon._id) {
      surgeon = surgeonOpening.surgeon;
    } else {
      surgeon = surgeonOpening.surgeonId;
    }

    if (!surgeon) {
      return;
    }

    tempTooltip.surgeonName = toPascalCase(`${surgeon.firstName} ${surgeon.lastName}`);
    
    this.tooltip = tempTooltip;
  }

  resetTooltip(): void {
    this.tooltip = null;
  }

  mouseLeave(event, i: number, j: number) {
    this.modifiedDoctorAgendas[i][j].isHovering = false;
    // this.resetTooltip();
  }

  mousePressed(event, i: number, j: number, agenda: any) {
    // If any button other than left button is clicked we return immediatly
    if (event.button !== 0 || agenda.isEmpty || this.readOnlyMode || this.onSurgeonSelect) {
      return;
    }

    this.isAgendaPressing = true;
    this.subscribeToMouseMoveEvents();
    setTimeout(() => {
      try {
        this.modifiedDoctorAgendas[i][j].isHovering = false;
      } catch (error) {
      }
    }, 150);
    if (!this.isDisableAllEvents && !this.hasEnterDelete && !this.isResizing) {
      agenda.showHours = true;
      this.modifiedDoctorAgendas[i][j].isDragging = true;
      this.indexI = i;
      this.indexJ = j;
      this.isDragging = true;
      this.allignElemWithMouse(event);
    } else {
      this.canDrag = true;
      this.isDragging = false;
    }
  }

  allignElemWithMouse(event, el?: HTMLElement) {
    const elem: HTMLElement = el ? el : document.getElementById('agenda-' + this.modifiedDoctorAgendas[this.indexI][this.indexJ]._id);
    this.marginBetweenMouseAndTopElement = (event.clientY - 1 - elem.getBoundingClientRect().top);
  }

  getAgendaHTMLElement(i, j): HTMLElement {
    return document.getElementById('agenda-' + this.modifiedDoctorAgendas[i][j]._id);
  }

  mouseAgendaUP(event, i: number, j: number) {
    if (!this.readOnlyMode || !this.onSurgeonSelect) {
      this.modifiedDoctorAgendas[i][j].isHovering = true;
      this.modifiedDoctorAgendas[i][j].isDragging = false;
      this.isDragging = false;
      this.isAgendaPressing = false;
      this.unsubscribeFromMouseMoveEvents();
    }
  }

  ngOnDestroy(): void {
    if (this.onDataChangeSubjectSubscription) {
      this.onDataChangeSubjectSubscription.unsubscribe();
    }
  }

  close() {
    this.closePopup.emit(true);
  }

  isSpecialNeeds(): any {
    if (isArray(this.modifiedDoctorAgendas)) {
      for (let da of this.modifiedDoctorAgendas) {
        if (isArray(da)) {
          for (let doctorAgenda of da) {
            if (doctorAgenda && doctorAgenda.surgeon && doctorAgenda.surgeon.specialties) {
              const specialty = doctorAgenda.surgeon.specialties.find((specialty) => String(specialty.hospital) === String(doctorAgenda.hospital))
              doctorAgenda.isSpecialNeeds = false;
              if (doctorAgenda && doctorAgenda.surgeon && doctorAgenda.surgeon.surgeonNeeds && doctorAgenda.surgeon.surgeonNeeds.length > 0 && Object.keys(doctorAgenda.surgeon.surgeonNeeds[0]).length > 0) {
                // we have surgeonNeeds, we need to compare vacationNeeds with surgeonNeeds
                doctorAgenda.isSpecialNeeds = this.areNeedsDifferent(doctorAgenda.needs, doctorAgenda.surgeon.surgeonNeeds);
              } else {
                if (specialty) {
                  // we don't have surgeonNeeds, we need to compare vacationNeeds with specialtyNeeds
                  doctorAgenda.isSpecialNeeds = this.areNeedsDifferent(doctorAgenda.needs, specialty.needs);
                }
              }
            }
          }
        }
      }
    }
  }

  areNeedsDifferent(array1: { role: Role | string, need: number }[], array2: { role: Role | string, need: number }[]): boolean {
    const array1Filtred = array1.filter((item) => Number(item.need) > 0);
    const array2Filtred = array2.filter((item) => Number(item.need) > 0);

    if (array1Filtred.length !== array2Filtred.length) {
      return true;
    }

    for (const item1 of array1Filtred) {
      const found = array2Filtred.find((item2) => {
        const role1 = item1.role && (item1.role as Role)._id ? (item1.role as Role)._id : item1.role;
        const role2 = item2.role && (item2.role as Role)._id ? (item2.role as Role)._id : item2.role;
        return String(role1) === String(role2);
      })
      
      if (!found || Number(found.need) !== Number(item1.need)) {
        return true;
      }
    }

    for (const item1 of array2Filtred) {
      const found = array1Filtred.find((item2) => {
        const role1 = item1.role && (item1.role as Role)._id ? (item1.role as Role)._id : item1.role;
        const role2 = item2.role && (item2.role as Role)._id ? (item2.role as Role)._id : item2.role;
        return String(role1) === String(role2);
      })
      
      if (!found || Number(found.need) !== Number(item1.need)) {
        return true;
      }
    }

    return false;
  }

  onLeftClick(i: number, j: number) {
    if (this.smartPlanningDetailsLayout)
      this.openInterventionPopupComponent(i, j);
  }
}
