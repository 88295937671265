import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { urlEndPoints } from '../config/end-points';
import { Notifications } from './../models/notifications.model';

@Injectable()
export class NotificationDal {
    constructor(private httpClient: HttpClient) {}

    getNotifications(profileId: string, page: number, pageSize: number, keyword?: string): Observable<Notifications> {
        const params = keyword 
        ?   `page=${page}&pageSize=${pageSize}&keyword=${keyword}`
        :   `page=${page}&pageSize=${pageSize}`
        return this.httpClient.get<Notifications>(`${urlEndPoints.notifications}${profileId}?${params}`);
    }

    getNotificationsCounter(profileId: string): Observable<number> {
        return this.httpClient.get<number>(`${urlEndPoints.notifications}counter/${profileId}`);
    }
   
}
