import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Profile} from '../../models/profile.model';
import {ProfileService} from '../../services/profile.service';
import {Skill} from '../../models/skill.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Subscription} from 'rxjs';
import {ErrorService} from '../../services/error.service';
import { UtilisService } from 'src/app/shared/services/utilis.service';

@Component({
  selector: 'app-add-surgeon-dialog',
  templateUrl: './add-surgeon-dialog.component.html',
  styleUrls: ['./add-surgeon-dialog.component.scss']
})
export class AddSurgeonDialogComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  step = 0;
  skills: Skill[];
  public profile: Profile;
  public originalSpe : string;

  private profileCreationSubscription: Subscription;
  private editProfilSubscription: Subscription;
  private VacationNeedsSubscription: Subscription;

  constructor(
    private profileService: ProfileService,
    private UtilisService: UtilisService,
    public dialogRef: MatDialogRef<AddSurgeonDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private errorService: ErrorService
  ) {
    this.profile = Object.assign({}, this.data.profile);
  }

  ngOnInit() {}

  profileChange(event: { profile: Profile, specialty: string }): void {
    const { profile, specialty } = event;
    this.data.profile = profile;
    this.isLoading = true;
    if (this.data.openType === 'ADD') {
      this.createProfile()
    } else {
      this.originalSpe = specialty;
      this.editProfile();
      this.editVacationNeeds();
    }
  }

  editSkillsClicked(profile: any): void {
    this.data.profile = profile;
    this.step = 1;
  }

  editProfile(): void {
    this.profileCreationSubscription = this.profileService.editProfile(this.data.profile).subscribe((result) => {
      this.UtilisService.displaySuccessToast()
      this.dialogRef.close(result);
    }, error => this.errorService.handleError(error));
  }

  editVacationNeeds(): void {
    this.VacationNeedsSubscription = this.profileService.editVacationNeeds(this.data.profile,this.originalSpe).subscribe(() => {
      this.UtilisService.displaySuccessToast();
    }, error => this.errorService.handleError(error));
  }

  createProfile(): void {
    this.profileCreationSubscription = this.profileService.createProfileWithSkills({
      profile: this.data.profile,
      skills: this.skills
    }).subscribe((result) => {
      this.isLoading = false;
      this.dialogRef.close(result);
    }, error => this.errorService.handleError(error));
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    if (this.profileCreationSubscription) {
      this.profileCreationSubscription.unsubscribe();
    }

    if (this.editProfilSubscription) {
      this.editProfilSubscription.unsubscribe();
    }
    if(this.VacationNeedsSubscription) {
      this.VacationNeedsSubscription.unsubscribe()
    }
  }

}
