import { Injectable } from "@angular/core";
import { RealProgramDal } from "../dal/real-program.dal";
import { Observable } from "rxjs";
import { Room } from "../models/room.model";

@Injectable()
export class RealProgramService {
  constructor(private realProgramDal: RealProgramDal) {}

  getNonValidatedDays(day?: string): Observable<Date[]> {
    return this.realProgramDal.getNonValidatedDays(day);
  }

  getNonValidatedDaysOfPreviousWeek(day?: string): Observable<Date[]> {
    return this.realProgramDal.getNonValidatedDaysOfPreviousWeek(day);
  }

  insertDayProgram(date: string, rooms: Room[], urgenceProfile?) {
    return this.realProgramDal.insertDayProgram(date, rooms, urgenceProfile);
  }

  // getRealPrograms(date: string): Observable<any[]> {
  //   return this.realProgramDal.getRealPrograms(date);
  // }

  getRealProgramsInf(nurseId: string, searchString: string, startDay: string, EndDay: string, limit: number, page : number , pagination:boolean = true): Observable<any[]> {
    return this.realProgramDal.getRealProgramsInf(nurseId, searchString, startDay, EndDay, limit, page  , pagination);
  }

  getFromDataExtraction(fromDate: string, toDate: string) {
    return this.realProgramDal.getFromDataExtraction(fromDate, toDate);
  }
}
