import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
    selector : "toggle",
    templateUrl : "./app-toggle.component.html",
    styleUrls: ["./app-toggle.component.scss"]
})

export class ToggleComponent implements OnInit{

    @Input() options : string[] = [];
    @Input() actifOption : string = "";
    @Input() showBorder: boolean = false;
    @Input() showBorderBottom: boolean = false;
    @Input() equalColumnsWidth: boolean = false;

    @Output() onOptionClick = new EventEmitter<string>();

    constructor(){}

    ngOnInit(): void {
        if (this.options.length === 1) 
            this.equalColumnsWidth = false;
    }

    optionClickHandler(option : string){
        this.onOptionClick.emit(option);
    }
}