import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CalendarSwitch } from '../models/calendarSwitch';
import { urlEndPoints } from '../config/end-points';
import { ProgramSwitch } from '../models/programSwitch';
import { Observable } from 'rxjs';
import { Calendar } from '../models/calendar.model';
import { CalendarRequest } from '../models/calendarRequest';
import { position } from 'html2canvas/dist/types/css/property-descriptors/position';

@Injectable({
  providedIn: 'root',
})
export class SwitchService {
  constructor(private httpClient: HttpClient) {}

  countExchangeRequestsOnold(startDate: any, endDate: any, position: string) {
    let query =
      'fromDate=' + startDate + '&toDate=' + endDate + '&position=' + position;
    return this.httpClient.get<any>(
      `${urlEndPoints.switch}/countRequestsOnHold?` + query
    );
  }

  rejectCalendarSwitchRequest(calendarSwitch : any, responseDescription: string) {
    calendarSwitch.responseDescription = responseDescription
    return this.httpClient.post<CalendarSwitch>(
      `${urlEndPoints.switchGardes}reject`, calendarSwitch
    )
  }

  deleteCalendarSwitch(request: CalendarRequest) {
    return this.httpClient.delete<CalendarSwitch>(
      `${urlEndPoints.switchGardes}` + request._id
    )
  }

  getGardeCalendar(position: string, profileId: string, date: string) {
    return this.httpClient.get<any[]>(
      `${urlEndPoints.switchGardes}getGardeCalendar?position=${position}&profileId=${profileId}&date=${date}`
    )
  }

  acceptCalendarSwitchRequest(calendarSwitch : any) {
    return this.httpClient.post<CalendarSwitch>(
      `${urlEndPoints.switchGardes}accept`, calendarSwitch
    )
  }

  createCalendarRequest(calendarSwitch: CalendarSwitch) {
    return this.httpClient.post<CalendarSwitch>(
      `${urlEndPoints.switchGardes}`,
      calendarSwitch
    );
  }

  // NOT USED
  // updateRequest(request: any, type: string, calendarType: string) {
  //   request.type = calendarType
  //   if (type == 'garde')
  //     return this.httpClient.put<any>(`${urlEndPoints.switchGardes}`, request);
  //   else
  //     return this.httpClient.put<any>(
  //       `${urlEndPoints.ProgramSwitchs}`,
  //       request
  //     );
  // }

  getEtraExchangeRequest(requestId: string) {
    return this.httpClient.get<any>(
      urlEndPoints.switch + `programs/${requestId}`
    );
  }

  // NOT USED
  // exchangePrograms(programSwitch: ProgramSwitch) {
  //   return this.httpClient.post<ProgramSwitch>(
  //     `${urlEndPoints.ProgramSwitchs}`,
  //     programSwitch
  //   );
  // }

  deleteGardeSwitchRequest(id: string) {
    return this.httpClient.delete<any>(`${urlEndPoints.switchGardes}${id}`);
  }

  deleteExtrSwitchRequest(id: string) {
    return this.httpClient.delete<any>(`${urlEndPoints.ProgramSwitchs}${id}`);
  }

  deleteSwitch(id: string) {
    return this.httpClient.delete<any>(`${urlEndPoints.switchGardes}${id}`);
  }

  getActiveGardesExchangeRequests(allTypes?) {
    const param = allTypes ? `allHistory=${true}` : '';
    return this.httpClient.get<any>(`${urlEndPoints.switch}all?${param}`);
  }
  
  getPaginateActiveSwitchRequestsFromTo(fromDate:string, toDate: string, isNight: boolean, page?: number, limit?: number, isPaginate: boolean = true, position: string = null, searchValue: string = null): Observable<any> {
    return this.httpClient.get<any>(
        `${urlEndPoints.switchGardes}request?` +
        'page=' +
        (page ? page : 1) +
        '&limit=' +
        limit +
        '&isPaginate=' +
        isPaginate +
        '&fromDate=' +
        fromDate +
        '&toDate=' +
        toDate +
        '&position=' +
        position +
        (searchValue ? '&searchValue=' + searchValue : '') +
        '&isNight=' +
        isNight)
  }
  getPaginateAllSwitchRequestsFromTo(fromDate:string, toDate: string, page?: number, limit?: number, isPaginate: boolean = true, searchValue: string = null, profileId: string = null, position = null, showOnHold: boolean = null, isNight: boolean = null): Observable<any> {
    return this.httpClient.get<any>(
        `${urlEndPoints.switchGardes}history?` +
        'page=' +
        (page ? page : 1) +
        '&limit=' +
        limit +
        '&isPaginate=' +
        isPaginate +
        '&fromDate=' +
        fromDate +
        '&toDate=' +
        toDate +
        (isNight !== null ? '&isNight=' + isNight : '') +
        (showOnHold != null ? '&showOnHold=' + showOnHold : '') +
        (searchValue ? '&searchValue=' + searchValue : '') +
        (profileId ? '&profile=' + profileId : '') +
        (position? '&position=' + position : '')
    )
  }

  getActiveGardesExchangeRequestsFromTo(
    allTypes?,
    startDate?: string,
    endDate?: string,
    page?: number, 
    limit?: number,
    isSenior?
  ) {
    const param = allTypes ? `allHistory=${true}&` : '';
    const query = 'fromDate=' + startDate + '&toDate=' + endDate + '&page=' + (page ? page : 1) + '&limit=' + limit +'&isSenior=' + isSenior   ;
    return this.httpClient.get<any>(
      `${urlEndPoints.switch}allFromTo?${param}` + query
    );
  }

  getActiveProgramsExchangeRequests() {
    return this.httpClient.get<any>(`${urlEndPoints.ProgramSwitchs}`);
  }

  getRequestHistory(profileId: string, type: string,page?: number, limit?: number) {
    if (type == 'garde')
      return this.httpClient.get<any>(
        `${urlEndPoints.switch}history/${profileId ? profileId : 'all'}`+ '?page=' + (page ? page : 1) + '&limit=' + limit
      );
    else
      return this.httpClient.get<any>(
        `${urlEndPoints.ProgramSwitchs}history/${profileId ? profileId : ''}`+ '?page=' + (page ? page : 1) + '&limit=' + limit
      );
  }
 
  // NOT USED
  // countAvailableRequestsOnHold(position) {
  //   return this.httpClient.get<any>(
  //     `${urlEndPoints.switch}countAvailableRequestsOnHold?position=${position}`
  //   );
  // }
}
