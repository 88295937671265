import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isNaN'
})
export class isNaNPipe implements PipeTransform {

  transform(number: number, showSign?: boolean): string {
    let sign = '' ;
    if(showSign){
      sign = number >= 0 ? '+' : '';
    }
    if(number == Infinity) return `${showSign ? '+' : ''}0`;
    if(isNaN(number)) return `${showSign ? '+' : ''}0`;
    if(number !== undefined && number !== null) return sign + (+(+number).toFixed(1) / 1).toString().split('.').join(',');
    return `${showSign ? '+' : ''}0`;
  }

}
