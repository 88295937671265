import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent
} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {Observable} from 'rxjs/internal/Observable';
import {throwError} from 'rxjs/internal/observable/throwError';

import {urlEndPoints} from '../../shared/config/end-points';
import {StorageService} from './storage.service';
import { AuthenticationService } from './authentication.service';
import { NavigationService } from './navigation.service';
import { pages } from 'src/app/shared/config/pages';
import { UserService } from 'src/app/shared/services/user.service';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

  constructor(
    private storageService: StorageService,
    private authenticationService: AuthenticationService,
    private navigationService : NavigationService,
    private userService: UserService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    if (req.url === urlEndPoints.login || req.url === urlEndPoints.token) {
      return next.handle(req);
    } else {
      const token = this.storageService.getAccessToken();
      let selectedHospitalsId = this.userService.getSelectedHospitals();
      selectedHospitalsId = selectedHospitalsId ? selectedHospitalsId : [];
      
      const step1 = this.addToken(req, token)
      const step2 = this.addHospitals(step1, selectedHospitalsId)
      
      return next.handle(step2).pipe(
        catchError(err => {
            if (err instanceof HttpErrorResponse) {
              switch ((err as HttpErrorResponse).status) {
                case 400:
                  return this.handle400Error(err);
                case 401:
                  return this.handle401Error();
                default:
                  return throwError(err);
              }
            }
          }
        ));
    }
  }

  addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({setHeaders: {Authorization: 'Bearer ' + token}});
  }

  addHospitals(req: HttpRequest<any>, selectedHospitalsId: string[]): HttpRequest<any> {
    return req.clone({setHeaders: {
      "Selected-Hospitals-Id": selectedHospitalsId
    }})
  }

  handle400Error(error): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    if (error && error.status === 400 && error.error && error.error.error === 'invalid_grant') {
      // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
      return this.logoutUser();
    }
    throw new Error(error.message);
  }

  logoutUser() {
    this.authenticationService.logout();
    this.navigationService.navigateByUrl(pages.login).catch();
    return throwError('User not authorized');
  }

  handle401Error(){
    if (!this.authenticationService.isAuthenticated()) {
      this.navigationService.navigateByUrl(pages.login).catch();
    }
    return this.logoutUser();
  }

}
