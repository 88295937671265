import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subscription } from 'rxjs';
import { frenchDays } from 'src/app/shared/const/glabals.const';
import { Recurrence } from 'src/app/shared/models/recurrence.model';
import { Specialty } from 'src/app/shared/models/specialty.model';

@Component({
  selector: 'app-add-edit-recurrence',
  templateUrl: './add-edit-recurrence.component.html',
  styleUrls: ['./add-edit-recurrence.component.scss']
})
export class AddEditRecurrenceComponent implements OnInit, OnDestroy {
  public days = frenchDays.slice(0, frenchDays.length - 2).map((d, index) => { return { label: d, value: index + 2 } });
  public formGroup: FormGroup;

  public openType: string = 'ADD';
  public recurrence: Recurrence;
  public types = [
    {
      label: 'Bloc',
      value: 'bloc'
    },
    {
      label: 'Consultation',
      value: 'consultation'
    },
    {
      label: 'Extraclinique',
      value: 'extraclinique'
    }
  ]

  private typeChangesSubscription: Subscription;

  constructor(
    private dialogRef: MatDialogRef<AddEditRecurrenceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
  ) {
    this.openType = this.data.openType;
    this.recurrence = this.data.recurrence;
    this.createForm();
  }

  get specialties() {
    if (!this.formGroup) {
      return this.data.blocSpecialties;
    }
    switch (this.formGroup.get('type').value) {
      case 'bloc':
        return this.data.blocSpecialties.filter((specialty) => specialty.name !== 'DEFAULT_');
      case 'consultation':
        return this.data.consultationSpecialties.filter((specialty) => specialty.name !== 'DEFAULT_');
      default:
        return [];
    }
  }

  ngOnInit() {
    this.listenToTypeChanges();
  }

  createForm() {
    let selectedSpecialty;
    if (this.recurrence) {
      if (this.recurrence.specialty) {
        selectedSpecialty = (<Specialty>this.recurrence.specialty)._id;
      } else {
        selectedSpecialty = null;
      }
    } else {
      selectedSpecialty = this.specialties[0] ? this.specialties[0]._id : null;
    }

    const formFields = {
      dayOfWeek: [(this.recurrence ? this.recurrence.dayOfWeek : 2), [Validators.required]],
      type: [(this.recurrence ? this.recurrence.type : 'bloc'), [Validators.required]],
      specialty: [selectedSpecialty],
    };
    
    this.formGroup = this.formBuilder.group(formFields);
  }

  listenToTypeChanges(): void {
    if (this.typeChangesSubscription) {
      this.typeChangesSubscription.unsubscribe();
    }

    this.typeChangesSubscription = this.formGroup.get('type').valueChanges.subscribe((value) => {
      const selectedSpecialtyId = this.formGroup.get('specialty').value;

      if (!this.specialties.find((s) => String(s._id) === String(selectedSpecialtyId))) {
        this.formGroup.controls['specialty'].setValue(this.specialties[0] ? this.specialties[0]._id : null);
      }
    });
  }

  save(): void {
    const recurrence = {
      dayOfWeek: this.formGroup.get('dayOfWeek').value,
      type: this.formGroup.get('type').value,
      specialty: this.specialties.find((s) => String(s._id) === String(this.formGroup.get('specialty').value))
    };
    this.dialogRef.close(recurrence);
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    if (this.typeChangesSubscription) {
      this.typeChangesSubscription.unsubscribe();
    }
  }
}
