import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-radio-button-group',
  templateUrl: './radio-button-group.component.html',
  styleUrls: ['./radio-button-group.component.scss']
})
export class RadioButtonGroupComponent implements OnInit {
  @Input() formCtrl: FormControl = new FormControl();

  // layout : 'column' | 'row'
  @Input() layout: string = 'column';

  @Input() label: string = '';
  @Input() hintMessage: string = '';

  @Input() elements: { label: string, value: any, color?: string }[] = [];
  @Input() disabled: boolean = false;

  @Output() onValueChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  getSelectedValue(selectedValue: any) {
    if(this.formCtrl) {
      this.onValueChanged.emit(selectedValue);
    }
  }

}
