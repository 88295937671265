import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { TimeAlert } from "../models/timeAlerts.model";
import { TimeAlertDal } from "../dal/timeAlerts.dal";

@Injectable()
export class TimeAlertService {
  constructor(private TimeAlertDal: TimeAlertDal) {}

  getAllTimeAlerts(): Observable<TimeAlert[]> {
    return this.TimeAlertDal.getAllTimeAlerts();
  }

  createNewTimeAlert(timeAlert: TimeAlert): Observable<TimeAlert> {
    return this.TimeAlertDal.createNewTimeAlert(timeAlert);
  }

  updateTimeAlert(
    timeAlertID: string,
    timeAlert: TimeAlert
  ): Observable<TimeAlert> {
    return this.TimeAlertDal.updateTimeAlert(timeAlertID, timeAlert);
  }

  deleteTimeAlert(timeAlertID: string): Observable<TimeAlert> {
    return this.TimeAlertDal.deleteTimeAlert(timeAlertID);
  }
}
