import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Room} from '../../shared/models/room.model';

@Injectable()
export class HeaderService {

  public updateOpenedRoomsCounter = new BehaviorSubject(true);

  constructor() {}

  indexOf(room: Room, rooms: Room[]): number {
    for (let i = 0; i < rooms.length; i++) {
      if (rooms[i]._id === room._id) {
        return i;
      }
    }
    return -1;
  }
}
