import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import * as moment from "moment";
import { UserService } from "../../../services/user.service";
import { HospitalService } from "src/app/shared/services/hospital.service";
import { Profile } from "src/app/shared/models/profile.model";
import { Subscription } from "rxjs";
import { BufferProgramService } from "src/app/shared/services/buffer-program.service";
import { ErrorService } from "src/app/shared/services/error.service";
import { Role } from "src/app/shared/models/role.model";

@Component({
  selector: "app-anesth-week-popup",
  templateUrl: "./anesth-week-popup.component.html",
  styleUrls: ["./anesth-week-popup.component.scss"],
})
export class AnesthWeekPopupComponent implements OnInit, OnDestroy {
  // public days = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];
  public days: Date[] = [];

  public day: moment.Moment;
  public profile: Profile;
  public isLoading: boolean = true;
  public weekDays: string[] = [];
  public isNight: boolean = false
  public roleData: Role[];
  public osComponentOptions = {
    className : 'os-theme-dark custom-sidebar',
    nativeScrollbarsOverlaid : {
      showNativeScrollbars : true
    },
    paddingAbsolute: true,
    scrollbars: {
      autoHide: 'never',
      clickScrolling: true
    },
  };

  public options1 = [
    {
      label: 'Jour',
      value: false,
      icon: 'light_mode'
    },
    {
      label: 'Nuit',
      value: true,
      icon: 'dark_mode'
    }
  ];
  public actifOption1 = false;

  public allPrograms: any[] = [];
  public blocPrograms: any[] = [];
  public consultationPrograms: any[];
  public extracliniquesPrograms: any[] = [];
  public gardes: any[] = []
  public dateFormat: string[] = [];
  public allBloc: any[] = []
  public allConsult: any[] = []
  public allExtra: any[] = []
  public allGarde: any[] = []
  public dateFormatGarde: string[] = []

  public userSelectedHospital: string;
  public doesHospitalHaveDayNightOption: boolean;

  private getProgramsSubscription: Subscription;
  public dateDay = []
  public dateGardes = []

  get date() {
    return moment(this.day).format("YYYY-MM-DD").toString();
  }
  
  constructor(
    private hospitalService: HospitalService,
    private bufferProgramService: BufferProgramService,
    private userService: UserService,
    private errorService: ErrorService,
    public dialogRef: MatDialogRef<AnesthWeekPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.userSelectedHospital = this.userService.getSelectedHospitals()[0];
    this.doesHospitalHaveDayNightOption = this.hospitalService.doesHospitalHaveDayNightOption();
  }

  async ngOnInit() {
    this.day = moment(this.data.day)

    this.days = this.getDateWeekDays(this.day);
    this.profile = this.data.profile

    this.isLoading = false;
  }

  toggleNight(event) {
    if (event == this.isNight)
      return

      this.actifOption1 = event
    this.isNight = event
  }

  format(date) {
    return moment(date).format("DD/MM/YYYY")
  }

  filter(list, date) {
    return list.filter(ele => {return new Date(ele.date).getTime() == new Date(date).getTime()})
  }

  getPrograms() {
    return new Promise<void>((resolve, reject) => {
      if (this.getProgramsSubscription) {
        this.getProgramsSubscription.unsubscribe()
      }
  
      this.isLoading = true
  
      const startOfWeek = moment(this.day.clone().startOf('isoWeek')).format('YYYY-MM-DD');
      const endOfWeek = moment(this.day.clone().endOf('isoWeek')).format('YYYY-MM-DD');
      
      this.getProgramsSubscription = this.bufferProgramService.getDayPrograms(startOfWeek, endOfWeek, this.profile._id + '').subscribe(val => {
        this.dateDay = []
        this.dateGardes = []
        this.allPrograms = []
        this.blocPrograms = []
        this.consultationPrograms = []
        this.extracliniquesPrograms = []

        if (val) {
          // Set specialtySurgeon from specialtiesSurgeon and current selected hospital
          this.userSelectedHospital = this.userService.getSelectedHospitals()[0];
          val.blocs.forEach((prg) => {
            if (prg && prg.specialtiesSurgeon) {
              prg.specialtySurgeon = prg.specialtiesSurgeon.find((s) => String(s.hospital) === String(this.userSelectedHospital));
            }
          });

          this.gardes = val.garde.sort((a, b) => { return new Date(a).getTime() - new Date(b).getTime() });

          let tempGarde = [];
          this.gardes.forEach(ele => {
            tempGarde.push(ele[0]);
          })
          this.gardes = tempGarde;

          this.blocPrograms = val.blocs;
          this.consultationPrograms = val.consultations;
          this.extracliniquesPrograms = val.extracliniques;

          if (this.doesHospitalHaveDayNightOption) {
            this.allPrograms = val.blocs.concat(val.extracliniques.concat(val.consultations))
          } else {
            this.allPrograms = val.blocs.concat(val.extracliniques).concat(val.consultations).concat(this.gardes);
          }

          this.allPrograms.forEach(ele => {
            if (!this.dateDay.includes(ele.date))
              this.dateDay.push(ele.date)
          })

          this.blocPrograms.forEach(ele => {
            if (ele.startTime)
              ele.startTime = moment(ele.startTime).utc().format("HH:mm").replace(':', 'h')
            if (ele.endTime)
              ele.endTime = moment(ele.endTime).utc().format("HH:mm").replace(':', 'h')

              ele.anesthesists = ele.anesthesists.sort((a, b) => {
                return (a.firstName + " " + a.lastName).localeCompare(b.firstName + " " + b.lastName)
              })
          })

          this.dateDay.sort((a, b) => { return new Date(a).getTime() - new Date(b).getTime() })

          this.dateFormat = []
          this.allBloc = []
          this.allConsult = []
          this.allExtra = []

          this.dateDay.forEach(date => {
            this.dateFormat.push(this.format(date))
            this.allBloc.push(this.filter(this.blocPrograms, date))
            this.allConsult.push(this.filter(this.consultationPrograms, date))
            this.allExtra.push(this.filter(this.extracliniquesPrograms, date))
          })
          
          this.gardes.forEach(ele => {
            ele.object.sort((a, b) => {
              return a.reason.title.localeCompare(b.reason.title)
            })
          })

          if (this.doesHospitalHaveDayNightOption) {
            this.gardes.forEach(ele => {
              if (!this.dateGardes.includes(new Date(ele.date)))
                this.dateGardes.push(new Date(ele.date))
            })
    
            this.dateGardes.sort((a, b) => { return new Date(a).getTime() - new Date(b).getTime() })
    
            this.allGarde = []
            this.dateFormatGarde = []
    
            this.dateGardes.forEach(date => {
              this.allGarde.push(this.filter(this.gardes, date))
              this.dateFormatGarde.push(this.format(date))
            })
          } else {
            this.allGarde = []
    
            this.dateDay.forEach(date => {
              this.allGarde.push(this.filter(this.gardes, date))
            })
          }
        }
        resolve();
      }, (error) => {
        this.errorService.handleError(error);
        reject();
      })
    }) 
  }

  async chosenWeekHandler(event) {
    this.isLoading = true;
    this.day = event.value;
    await this.getPrograms();
    this.isLoading = false;
  }

  async onWeekChange(type: string) {
    this.isLoading = true;
    if (type === 'previous') {
      this.day = this.day.clone().subtract(7, 'd');
    } else {
      this.day = this.day.clone().add(7, 'd');
    }
    await this.getPrograms();
    this.isLoading = false;
  }

  handleWeekPicked(event) {
    this.days = [];

    if (event === 'next') {
      this.day = this.day.clone().add(7, 'd');
    } else if (event === 'previous') {
      this.day = this.day.clone().subtract(7, 'd');
    } else {
      this.day = event.value;
    }
    this.days = this.getDateWeekDays(this.day);
  }

  getDateWeekDays(definedDate: moment.Moment): Date[] {
    const date: moment.Moment = moment.utc(definedDate);
    const days: Date[] = [];
    const startOfWeek = moment.utc(date).startOf('isoWeek');
    const endOfWeek = moment.utc(date).endOf('isoWeek');
    let day = startOfWeek;
    while (day <= endOfWeek) {
      days.push(day.toDate());
      day = day.clone().add(1, 'd');
    }
    return days;
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    if (this.getProgramsSubscription) {
      this.getProgramsSubscription.unsubscribe()
    }
  }
}