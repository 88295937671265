import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PasswordValidator } from '../../utils/password.validator';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { pages } from '../../config/pages';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.scss']
})
export class ResetPasswordFormComponent implements OnInit {
  @Input() public resetToken
  @Input() public userId
  public passwordForm: FormGroup;
  private resetPasswordSubscription: Subscription;
  public isFormValid: boolean;
  successMessage: string;
  errorMessage: string;

  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private router: Router) {
  }

  ngOnInit() {
    this.createPasswordForm();
  }

  createPasswordForm(): void {
    this.passwordForm = this.formBuilder.group({
      resettoken: [this.resetToken],
      password: ['', [Validators.required, Validators.minLength(2)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(2)]]
    }, {
      validator: PasswordValidator.MatchPassword
    });
  }

  updateFormCheck(isFormValid: boolean): void {
    this.isFormValid = isFormValid;
  }

  changePassword(): void {

    if (this.passwordForm.valid) {

      var data = {
        password: this.passwordForm.value.password,
        token: this.resetToken,
        userId: this.userId
      }

      if(this.resetPasswordSubscription) this.resetPasswordSubscription.unsubscribe();
      this.resetPasswordSubscription = this.authenticationService.changePassword(data).subscribe(
        () => this.onSuccess(),
        () => this.onError()
      );
    }

  }

  onSuccess(): void {
    this.passwordForm.reset();
      this.successMessage = "Le mot de passe a été modifié avec succès";
    setTimeout(() => {
      this.successMessage = null;
      this.router.navigate([pages.login]);
    }, 3000);
  }

  onError() {
    this.errorMessage = "Erreur dans le système";
  }

  ngOnDestroy() {
    if (this.resetPasswordSubscription) {
      this.resetPasswordSubscription.unsubscribe();
    }
  }

}
