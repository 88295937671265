import {Component, Input, OnInit} from '@angular/core';

import {Profile} from '../../models/profile.model';


@Component({
  selector: 'app-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss']
})
export class ProfileCardComponent implements OnInit {

  @Input() profile: Profile;
  @Input() cssClass: string;
  @Input() badge?: string | number;
  @Input() startTime?: string | Date;
  @Input() endTime?: string | Date;
  @Input() isGuard: boolean = false;
  constructor() {
  }

  ngOnInit() {}

}
