import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  styleUrls: ['./checkbox-group.component.scss']
})
export class CheckboxGroupComponent implements OnInit, OnChanges {
  @Input() formCtrl: FormControl = new FormControl();

  // layout : 'column' | 'row'
  @Input() layout: string = 'column';
  @Input() layoutAlign: string = 'default default';

  @Input() label: string = '';
  @Input() hintMessage: string = '';

  @Input() triggerChangesSubject: Subject<void> = new Subject<void>();

  @Input() elements: { label: string, value: any, isChecked: boolean, isDisabled?: boolean, priority?: number }[] = [];

  @Input() withSelector: boolean = false;

  constructor() { }

  ngOnInit() {
    this.triggerChangesSubject.subscribe(() => {
      this.refreshCheckboxButtons();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refreshCheckboxButtons();
  }

  refreshCheckboxButtons(): void {
    const checkedElementsFromFormCtrl = this.formCtrl.value;
    if (checkedElementsFromFormCtrl) {
      this.elements.forEach((elt) => {
        // If the value of the checkbox is not a string we have to change the comparaison function
        if (this.withSelector) {
          const elem = checkedElementsFromFormCtrl.find((e) => e.value === elt.value)
          if (elem) {
            elt.isChecked = true;
            elt.priority = elem.priority
          } else {
            elt.isChecked = false;
          }
        } else {
          if (checkedElementsFromFormCtrl.includes(elt.value)) {
            elt.isChecked = true;
          } else {
            elt.isChecked = false;
          }
        }
      });
    }
  }


  changeSelection() {
    this.fetchSelectedItems()
  }

  fetchSelectedItems() {
    let checkedElements;

    if (!this.withSelector) {
      checkedElements = this.elements.filter((value, index) => {
        return value.isChecked
      }).map((element) => element.value);
    } else {
      checkedElements = this.elements.filter((value, index) => {
        return value.isChecked
      }).map((element) => {
        return {value: element.value, priority: element.priority}
      });
    }
    

    this.formCtrl.setValue(checkedElements);
  }
}
