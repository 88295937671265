import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { OPPOSITE_ZOOM_VALUE } from '../../const/glabals.const';

@Component({
  selector: 'app-avatars-tooltip',
  templateUrl: './avatars-tooltip.component.html',
  styleUrls: ['./avatars-tooltip.component.scss']
})
export class AvatarsTooltipComponent implements OnInit {
  @Input() infos: any[];
  @Input() left: string;
  @Input() top: string;
  @Input() offset: number;
  @Input() tooltipPosition: string;
  level: string;
  time: string;
  leftDiffrence: number;
  constructor() { }

  ngOnInit() {
    this.level = `${Math.round(((this.infos[0].x + this.offset ) * 100)) / 100}`;
    this.time = this.convertToTime(this.infos[0].y);
    this.leftDiffrence = this.calculateDiff();
  }
  calculateDiff() {
    let diff;
    switch (this.infos.length) {
      case 1:
        diff = 360;
        break;
      case 2:
        diff = 650;
        break;
      default:
        diff = 950;
    };
    
    switch (this.tooltipPosition) {
      case 'left':
        return (parseFloat(this.left) * OPPOSITE_ZOOM_VALUE) - diff;
      case 'right':
        return (parseFloat(this.left) * OPPOSITE_ZOOM_VALUE);
      default:
        return (parseFloat(this.left) * OPPOSITE_ZOOM_VALUE);
    }
  }

  get getTop(): number {
    return (parseFloat(this.top) * OPPOSITE_ZOOM_VALUE);
  }

  convertToTime(time: number): string {
    return Math.floor(time) + ' h ' + ((time - Math.floor(time)) * 60).toFixed(0) + ' min';
  }

  floor(input: number): number {
    return Math.floor(input);
  }
}
