import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import { CustomDateAdapter } from '../../utils/custom-date-picker';
import { Moment } from 'moment';


const MY_FORMATS = {
  parse: {
    dateInput: 'MM',
  },
  display: {
    dateInput: 'DATE_SELECTED',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@Component({
  selector: 'app-costum-date-picker-week',
  templateUrl: './costum-date-picker-week.component.html',
  styleUrls: ['./costum-date-picker-week.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'fr' },
  ]
})
export class CostumDatePickerWeekComponent implements OnInit, OnChanges, OnDestroy {

  @Output() dateSwitch = new EventEmitter<string>();
  @Output() weekPicked = new EventEmitter<string>();
  @Input() day: moment.Moment;
  date: FormControl;

  constructor(private _adapter: DateAdapter<any>) {
  }

  ngOnInit() {
    this.date = new FormControl({ value: this.day, disabled: true });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.day.previousValue) {
      this.date = new FormControl({ value: this.day, disabled: true });
    }
  }

  dateSwitchFunc(type) {
    this.dateSwitch.emit(type);
  }

  dateIsPicked(event) {
    event.value.set({ hour: 12 });
    this.weekPicked.emit(event);
  }

  ngOnDestroy() {
    localStorage.setItem('date', 'null');
  }
}
