import { HttpClient } from "@angular/common/http";

import { Observable } from "rxjs";
import { urlEndPoints } from "../config/end-points";
import { Injectable } from "@angular/core";
import { Room } from "../models/room.model";
import { ProgramReFormat } from "../utils/reformat-program";

@Injectable()
export class RealProgramDal {
  constructor(private http: HttpClient) {}

  getNonValidatedDays(day?: string): Observable<Date[]> {
    if (day) {
      return this.http.get<Date[]>(
        `${urlEndPoints.realPrograms}days/novalidated?day=${day}`
      );
    } else {
      return this.http.get<Date[]>(
        `${urlEndPoints.realPrograms}days/novalidated`
      );
    }
  }

  getNonValidatedDaysOfPreviousWeek(day?: string): Observable<Date[]> {
    if (day) {
      return this.http.get<Date[]>(
        `${urlEndPoints.realPrograms}days/novalidated/previousWeek?day=${day}`
      );
    } else {
      return this.http.get<Date[]>(
        `${urlEndPoints.realPrograms}days/novalidated/previousWeek`
      );
    }
  }

  insertDayProgram(date: string, rooms: Room[], urgenceProfile) {
    return this.http.post<any>(
      `${urlEndPoints.realPrograms}insert-many?date=${date}`,
      // The next line is equivalent to .flat(1)
      [].concat(...ProgramReFormat.roomsTorealProgramsFormat(rooms, urgenceProfile))
    );
  }

  // getRealPrograms(date: string): Observable<any[]> {
  //   return this.http.get<Date[]>(
  //     `${urlEndPoints.realPrograms}/list?date=${date}`
  //   );
  // }

  getRealProgramsInf(nurseId: string, searchString: string, startDay: string, EndDay: string, limit: number, page : number , pagination:boolean): Observable<any[]> {
    let endPoint = urlEndPoints.realPrograms + nurseId  + '?search=' + searchString + '&page=' + page +'&limit=' + limit + '&toDate='+ EndDay + '&fromDate=' + startDay + '&pagination=' + pagination;
    return this.http.get<Date[]>(endPoint);
  }

  getFromDataExtraction(fromDate: string, toDate: string): Observable<any>  {
    return this.http.get<any>(`${urlEndPoints.realPrograms}getFromDataExtraction?fromDate=${fromDate}&toDate=${toDate}`);
  }
}
