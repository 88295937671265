import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { urlEndPoints } from '../config/end-points';
import { Role } from '../models/role.model';
import { Formation } from '../models/formation.model';
import { Paramedical } from '../models/paramedical.model';
import { AllFormation } from '../models/allFormation.model';
import { AllRole } from '../models/allRole.model';

@Injectable()
export class ParamedicalDal {
    constructor(private httpClient: HttpClient) {}

    getAllRoles(): Observable<AllRole[]> {
        return this.httpClient.get<AllRole[]>(`${urlEndPoints.paramedicals}allRoles`);
    }

    getAllFormations(): Observable<AllFormation[]> {
        return this.httpClient.get<AllFormation[]>(`${urlEndPoints.paramedicals}allFormations`);
    }

    getRoles(): Observable<Role[]> {
        return this.httpClient.get<Role[]>(`${urlEndPoints.paramedicals}roles`);
    }

    getRolesById(rolesIds: string[]): Observable<Role[]> {
        const params = new HttpParams({ fromObject: { rolesIds } });

        return this.httpClient.get<Role[]>(`${urlEndPoints.paramedicals}rolesById`, { params });
    }
    addRole(name: string, priority: number): Observable<Role> {
        return this.httpClient.post<Role>(`${urlEndPoints.paramedicals}roles`, {name, priority});
    }

    modifyRole(id: string, priority: number): Observable<Role> {
        return this.httpClient.patch<Role>(`${urlEndPoints.paramedicals}roles/${id}`, {priority});
    }

    deleteRole(id: string): Observable<any> {
        return this.httpClient.delete<Role>(`${urlEndPoints.paramedicals}roles/${id}`);
    }

    getFormations(): Observable<Formation[]> {
        return this.httpClient.get<Formation[]>(`${urlEndPoints.paramedicals}formations`);
    }

    addFormation(name: string, groupedWith: string[]): Observable<Formation> {
        return this.httpClient.post<Formation>(`${urlEndPoints.paramedicals}formations`, {name, groupedWith});
    }

    modifyFormation(id: string, groupedWith: string[]): Observable<Role> {
        return this.httpClient.patch<Role>(`${urlEndPoints.paramedicals}formations/${id}`, {groupedWith});
    }

    deleteFormation(id: string): Observable<any> {
        return this.httpClient.delete<Role>(`${urlEndPoints.paramedicals}formations/${id}`);
    }

    getParamedicals(): Observable<Paramedical[]> {
        return this.httpClient.get<Paramedical[]>(`${urlEndPoints.paramedicals}paramedicals`);
    }

    addParamedical(formation: string, roles: string[], ratingSystem: boolean): Observable<Paramedical> {
        return this.httpClient.post<Paramedical>(`${urlEndPoints.paramedicals}paramedicals`, {formation, roles, ratingSystem});
    }

    modifyParamedical(id: string, formation: string, roles: string[], ratingSystem: boolean): Observable<Role> {
        return this.httpClient.patch<Role>(`${urlEndPoints.paramedicals}paramedicals/${id}`, {formation, roles, ratingSystem});
    }

    deleteParamedical(id: string): Observable<any> {
        return this.httpClient.delete<Role>(`${urlEndPoints.paramedicals}paramedicals/${id}`);
    }
}
