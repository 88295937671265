import {ActivatedRouteSnapshot, CanActivate,} from "@angular/router";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

import {NavigationService} from './navigation.service';
import {pages} from '../../shared/config/pages';
import { UserService } from "src/app/shared/services/user.service";

@Injectable()
export class SurgeonsGuard implements CanActivate {

  constructor(
    private navigationService: NavigationService,
    private userService: UserService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    const isAnesWithLowLevelAccess = this.userService.isAnesWithLowLevelAccess();

    switch (route.data.url) {
        case pages.parPraticiens: {
            if (isAnesWithLowLevelAccess) {
                this.navigationService.navigateTo(pages.parSalles, null, null, false).catch();
            } else {
                return true;
            }
        }
        default: {
            return true;
        }
    }
  }

}
