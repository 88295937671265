import { Component, OnInit, Inject, ChangeDetectorRef, SimpleChange} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
@Component({
  selector: 'app-popup-import-calendars',
  templateUrl: './popup-import-calendars.component.html',
  styleUrls: ['./popup-import-calendars.component.scss']
})
export class ImportCalendarsPopupComponent implements OnInit {

  public title: string = "";
  public fromDate: Date;
  public toDate: Date;
  public formGroup: FormGroup;
  public minDate: Date;
  
  constructor(public dialogRef: MatDialogRef<ImportCalendarsPopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public formBuilder: FormBuilder,
              private readonly changeDetectorRef: ChangeDetectorRef ) { 
    this.title = data.title;
    this.fromDate = data.fromDate;
    this.toDate = data.toDate;
  }

  ngOnInit() {
    this.makeForm();
  }

  ngOnChanges(changes: SimpleChange) {
    if (this.formGroup.get("startDate").value > this.formGroup.get("endDate").value) {
      this.formGroup.get("endDate").reset();
    }
  }

  makeForm(): void {
      this.formGroup = this.formBuilder.group({
        startDate: [this.fromDate, [Validators.required]],
        endDate: [this.toDate, [Validators.required]],
      });
    this.minDate = new Date(this.fromDate);

    this.formGroup.get("startDate").valueChanges.subscribe(x => {
      this.minDate = x;
    })
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  import() {
    this.dialogRef.close({startDate: this.formGroup.value.startDate, endDate: this.formGroup.value.endDate});
  }

  close() {
    this.dialogRef.close();
  }
}
