import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Observable, Subscription } from "rxjs";
import { ANESTHETIST } from "../../const/glabals.const";
import { ErrorService } from "../../services/error.service";
import { HospitalService } from "../../services/hospital.service";
import { TimeAlert } from "../../models/timeAlerts.model";
import { TimeAlertService } from "../../services/timeAlert.service";
import { ToastService } from "../../services/toast.service";
import { Formation } from "../../models/formation.model";
import { ParamedicalService } from "../../services/paramedical.service";

@Component({
  selector: "app-time-alert-dialog",
  templateUrl: "./add-time-alert-dialog.component.html",
  styleUrls: ["./add-time-alert-dialog.component.scss"],
})
export class AddTimeAlertDialogComponent implements OnInit, OnDestroy {
  public isSending: boolean;
  public formGroup: FormGroup;
  public toggle: boolean;
  public showseniority: boolean = false;
  public seniorityValid: boolean = true;

  public timeAlerts: TimeAlert[];
  public timeAlert: TimeAlert;
  public originalTimeAlerts: TimeAlert[];

  private addTimeAlertSubscription: Subscription;
  private editTimeAlertSubscription: Subscription;
  private formGroupChangeSubscription: Subscription;

  private formations: Formation[];
  public isLoading: boolean = true;
  public postes = [
    // {
    //   label: "IBODE",
    //   value: "Ibode",
    //   isChecked: false,
    //   isDisabled: false,
    // },
    // {
    //   label: "IDE",
    //   value: "Ide",
    //   isChecked: false,
    //   isDisabled: false,
    // },
    // {
    //   label: "AS",
    //   value: "As",
    //   isChecked: false,
    //   isDisabled: false,
    // },
    {
      label: "IADE",
      value: "Iade",
      isChecked: false,
      isDisabled: false,
    },

    {
      label: "Anesthésiste",
      value: "Anesthésiste",
      isChecked: false,
      isDisabled: false,
    },
  ];

  public residencies = [
    {
      label: "Titulaire",
      value: "Titulaire",
      isChecked: false,
      isDisabled: false,
    },
    {
      label: "Vacataire",
      value: "Vacataire",
      isChecked: false,
      isDisabled: false,
    },
  ];

  public seniority = [
    {
      label: "Sénior",
      value: "Senior",
      isChecked: false,
      isDisabled: false,
    },
    {
      label: "Junior",
      value: "Junior",
      isChecked: false,
      isDisabled: false,
    },
    {
      label: "Interne",
      value: "Interne",
      isChecked: false,
      isDisabled: false,
    },
  ];

  form: FormGroup;

  constructor(
    private toastService: ToastService,
    public hospitalService: HospitalService,
    private timeAlertService: TimeAlertService,
    private formBuilder: FormBuilder,
    private errorService: ErrorService,
    private dialogRef: MatDialogRef<AddTimeAlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { mode: string; timeAlert: TimeAlert },
    private paramedicalService: ParamedicalService
  ) {}

  ngOnInit() {
    if (this.data.mode === "edit") {
      this.timeAlert = this.data.timeAlert;
    }
    
    this.getFormations().subscribe((formations: Formation[]) => {
      this.formations = formations;

      const nursesPostes = this.constructNursesPostesAndExcludeIade();
      this.postes.unshift(...nursesPostes);

      this.makeForm();

      this.formGroup.get("postes").valueChanges.subscribe((val) => {
        this.showSeniority(val);
      });
      this.formGroup.get("seniority").valueChanges.subscribe((val) => {
        this.actualiseSeniority(val);
      });

      this.showSeniority(this.formGroup.value.postes);
      this.actualiseSeniority(this.formGroup.value.seniority);

      this.form = this.formBuilder.group({});

      this.isLoading = false;
    });
  }

  constructNursesPostesAndExcludeIade(): any[] {
    const postes = [];

    for (const formation of this.formations) {
      if (formation.name.toLowerCase() !== 'iade') {
        postes.push({
          label: formation.name,
          value: `${formation.name.slice(0, 1).toUpperCase()}${formation.name.slice(1).toLowerCase()}`,
          isChecked: false,
          isDisabled: false
        });
      }
    }

    return postes;
  }

  getFormations(): Observable<Formation[]> {
    return this.paramedicalService.getFormations();
  }

  close(): void {
    this.dialogRef.close(false);
  }

  makeForm() {
    if (this.data.mode === "edit") {
      this.formGroup = this.formBuilder.group({
        _id: this.data.timeAlert._id,
        maxTime: [this.data.timeAlert.maxTime, [Validators.required]],
        verificationPeriod: [
          this.data.timeAlert.verificationPeriod,
          [Validators.required],
        ],
        postes: [this.data.timeAlert.postes, [Validators.required]],
        seniority: [this.data.timeAlert.seniority],
        residencies: [this.data.timeAlert.residencies, [Validators.required]],
        isLunchBreak: [this.data.timeAlert.isLunchBreak]
      });
    } else {
      this.formGroup = this.formBuilder.group({
        maxTime: [0, [Validators.required]],
        verificationPeriod: [0, [Validators.required]],
        postes: [[], [Validators.required]],
        seniority: [[]],
        residencies: [[], [Validators.required]],
        isLunchBreak: [false]
      });
    }
  }

  save() {
    if (this.data.mode === "edit") {
      this.editTimeAlert(this.formGroup.value);
    } else {
      this.addTimeAlert(this.formGroup.value);
    }
  }

  showSeniority(val) {
    this.showseniority =
      val.includes(ANESTHETIST) &&
      this.hospitalService.doesHospitalHaveAnesthDetailsOption();
    if (!this.formGroup.value.seniority) this.formGroup.value.seniority = [];
    this.seniorityValid = !(
      this.showseniority && this.formGroup.value.seniority.length == 0
    );
  }

  actualiseSeniority(val) {
    if (!val) val = [];
    this.seniorityValid = !(this.showseniority && val.length == 0);
  }

  addTimeAlert(timeAlert: TimeAlert) {
    this.isSending = true;
    this.addTimeAlertSubscription = this.timeAlertService
      .createNewTimeAlert(timeAlert)
      .subscribe(
        (res) => {
          this.isSending = false;
          this.dialogRef.close(timeAlert);
          this.toastService.creationToast("Une régle de cumul d'heure a été ajoutée");
        },
        (error) => {
          this.errorService.handleError(error)
          this.isSending = false;
          this.toastService.errorToast("Une règle de cumul d'heure existe déja");
        }
        
      );
  }

  editTimeAlert(timeAlert: TimeAlert) {
    this.isSending = true;
    this.editTimeAlertSubscription = this.timeAlertService
      .updateTimeAlert(timeAlert._id, timeAlert)
      .subscribe(
        (res) => {
          this.isSending = false;
          this.dialogRef.close(timeAlert);
          this.toastService.creationToast("Une régle de cumul d'heure a été modifiée");
        },
        (error) => {
          this.errorService.handleError(error)
          this.isSending = false;
          this.toastService.errorToast("Une règle de cumul d'heure existe déja");
        } 
      );
  }

  ngOnDestroy() {
    if (this.addTimeAlertSubscription) {
      this.addTimeAlertSubscription.unsubscribe();
    }

    if (this.editTimeAlertSubscription) {
      this.editTimeAlertSubscription.unsubscribe();
    }

    if (this.formGroupChangeSubscription) {
      this.formGroupChangeSubscription.unsubscribe();
    }
  }
}
