import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { get } from 'lodash';

import { Token } from '../models/token.model';
import { User } from '../../shared/models/user.model';
import { DATE_WEEK, DATE_DAY } from 'src/app/shared/const/glabals.const';
import { BufferProgram } from 'src/app/shared/models/buffer-program.model';

const tokenStorageKey: string = 'SOP_USER_TOKEN';
const userStorageKey: string = 'SOP_USER_OBJECT';

@Injectable()
export class StorageService {

  // IDS des rooms on l'on va sauvegarder les programmes dans le cache (a la suppression d'une vacation)
  public programsToSaveInCache: BufferProgram[] = [];

  constructor(
    private localStorageService: LocalStorageService
  ) {}
  
  clearDate() {
    localStorage.removeItem(DATE_DAY);
    localStorage.removeItem(DATE_WEEK);
  }

  storeObjectInLocalStorage(object: JSON, key: string): void {
    this.localStorageService.store(key, object);
  }

  getObjectFromLocalStorage(key: string): any {
    return this.localStorageService.retrieve(key);
  }

  clearObjectFromLocalStorage(key: string): void {
    this.localStorageService.clear(key);
  }

  getAccessToken(): string {
    const token: Token = this.getToken();
    return get(token, 'access_token', null);
  }

  getRefreshToken(): string {
    const token: Token = this.getToken();
    return get(token, 'refresh_token', null);
  }

  getToken(): Token {
    return this.localStorageService.retrieve(tokenStorageKey);
  }

  clearToken(): void {
    this.localStorageService.clear(tokenStorageKey);
  }

  storeToken(token: Token): void {
    this.localStorageService.store(tokenStorageKey, token);
  }

  storeUser(user: User): void {
    this.localStorageService.store(userStorageKey, user);
  }

  getUser(): User {
    return this.localStorageService.retrieve(userStorageKey);
  }

  clearUser(): void {
    this.localStorageService.clear(userStorageKey);
  }

  clearSelectedHospitals(): void {
    this.localStorageService.clear("selectedHospitalsId")
  }

  saveData(key: string, data: any) {
    localStorage.setItem(key, JSON.stringify(data));
  }

  getData(key: string) {
    const data = localStorage.getItem(key)
    if (data) {
      return JSON.parse(data)
    }
    return null
  }

}
