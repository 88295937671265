import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {Router} from "@angular/router";

import * as Sentry from "@sentry/angular";

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SideNavComponent} from './side-nav/side-nav.component';

import {CoreModule} from './core/core.module';
import {SharedModule} from './shared/shared.module';
import {AccessDeniedComponent} from './access-denied/access-denied.component';
import {MessagingChatService} from './shared/services/messagingChat.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GlobalErrorHandler } from './core/services/globalErrorHandler.service';
import { ThemingComponent } from './theming/theming.component';
import { InputsShowroomComponent } from './theming/inputs-showroom/inputs-showroom.component';
import { DragDropComponent } from './theming/drag-drop/drag-drop.component';
import { SpecialtyColorService } from './specialty-color.service';
import { ConfigPopupComponent } from './smart-planning/config-popup/config-popup.component';

registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [
    AppComponent,
    SideNavComponent,
    AccessDeniedComponent,
    ThemingComponent,
    ConfigPopupComponent,
    InputsShowroomComponent,
    DragDropComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    NgbModule.forRoot(),
    // NgbModule
  ],
  entryComponents: [
    ConfigPopupComponent
  ],
  providers: [
    MessagingChatService,
    {provide: LOCALE_ID, useValue: 'fr'},
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    SpecialtyColorService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
