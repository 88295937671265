import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';

import {urlEndPoints} from '../config/end-points';
import {subscribeOn} from "rxjs/operators";
import { activityData } from '../interfaces/statop.interfaces';

@Injectable()
export class StatopDal {

  constructor(private httpClient: HttpClient) {
  }

  // First Row

  getActivityData(fromDate: string, toDate: string, profilesType: string, hospitalId: string, surgeonId: string, roomId: string, specialtyId: string, limit: string): Observable<activityData> {
    return this.httpClient.get<activityData>(urlEndPoints.statop + 'activity?fromDate=' + fromDate + '&toDate=' + toDate + '&profilesType=' + profilesType + '&hospitalId=' + hospitalId + '&surgeonId=' + surgeonId + '&roomId=' + roomId + '&specialtyId=' + specialtyId + '&limit=' + limit);
  }

  getEfficacityData(fromDate: string, toDate: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.statop + 'efficacity?fromDate=' + fromDate + '&toDate=' + toDate);
  }

  getRoomAnalysisData(fromDate: string, toDate: string, roomId: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.statop + 'roomAnalysis?fromDate=' + fromDate + '&toDate=' + toDate + '&roomId=' + roomId);
  }

  getSpecialtyAnalysisData(fromDate: string, toDate: string, specialtyId: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.statop + 'specialtyAnalysis?fromDate=' + fromDate + '&toDate=' + toDate + '&specialtyId=' + specialtyId);
  }

  getSlowRequestsData(fromDate: string, toDate: string, roomId: string, specialtyId: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.statop + 'slowRequests?fromDate=' + fromDate + '&toDate=' + toDate + '&roomId=' + roomId + '&specialtyId=' + specialtyId);
  }



  getStatOpInterventionsTotal(startDate: string, endDate: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.statop + 'totalInterventions?fromDate=' + startDate + '&toDate=' + endDate);
  }

  getStatOpInterventionsProgress(startDate: string, endDate: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.statop + 'interventions?fromDate=' + startDate + '&toDate=' + endDate);
  }

  getStatOpInterventionsPerDay(startDate: string, endDate: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.statop + 'interventionsPerDay?fromDate=' + startDate + '&toDate=' + endDate);
  }

  // Second Row

  getStatOpInterventionsPerSpecialty(startDate: string, endDate: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.statop + 'interventionPerSpecialty?fromDate=' + startDate + '&toDate=' + endDate);
  }

  getStatOpInterventionsTopSpecialty(startDate: string, endDate: string, limit: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.statop + 'topSpecialty?fromDate=' + startDate + '&toDate=' + endDate + '&limit=' + limit);
  }

  getInterventionsPerSpecialtySurgeon(startDate: string, endDate: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.statop + 'interventionPerSpecialtySurgeon?fromDate=' + startDate + '&toDate=' + endDate);
  }

  getEffectivenessRate(startDate: string, endDate: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.statop + 'effectivenessRate?fromDate=' + startDate + '&toDate=' + endDate);
  }


  getRoomData(startDate: string, endDate: string, roomId: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.statop + 'oneRoomStat?fromDate=' + startDate + '&toDate=' + endDate + '&roomId=' + roomId);
  }

  getRoomTop(startDate: string, endDate: string, roomId: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.statop + 'HeaderTop?fromDate=' + startDate + '&toDate=' + endDate + '&roomId=' + roomId);
  }

  getRatesHeader(startDate: string, endDate: string, roomId: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.statop + 'ratesHeader?fromDate=' + startDate + '&toDate=' + endDate + '&roomId=' + roomId);
  }

  getRatesHeaderSpecialty(startDate: string, endDate: string, specialtyId: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.statop + 'ratesHeaderSpecialty?fromDate=' + startDate + '&toDate=' + endDate + '&specialtyId=' + specialtyId);
  }

  getSpecialtyTop(startDate: string, endDate: string, specialtyId: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.statop + '/HeaderTopBySpecialty/?fromDate=' + startDate + '&toDate=' + endDate + '&specialtyId=' + specialtyId);
  }

  getRoomEffectivenessRatePerDay(startDate: string, endDate: string, roomId: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.statop + 'roomeEffectivenessRatePerDay?fromDate=' + startDate + '&toDate=' + endDate + '&roomId=' + roomId);
  }

  getRoomEffectivenessRate(startDate: string, endDate: string, roomId: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.statop + 'roomEffectivenessRate?fromDate=' + startDate + '&toDate=' + endDate + '&roomId=' + roomId);
  }

  getRoomsBubblesData(startDate: string, endDate: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.statop + 'roomsBubles?fromDate=' + startDate + '&toDate=' + endDate);
  }

  getStatOpBoxPlot(startDate: string, endDate: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.statop + 'boxPlotSpecialty?fromDate=' + startDate + '&toDate=' + endDate);
  }


  //////////////////
  // One Surgeon  //
  //////////////////

  getOneSurgeonFirstGeneralData(startDate: string, endDate: string, surgeonId: string, hospital?: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.statop + 'surgeonHeader?fromDate=' + startDate + '&toDate=' + endDate + '&surgeonId=' + surgeonId + (hospital ? "&hospital=" + hospital: ""));
  }


  getOneSurgeonStatOpInterventionsTotal(startDate: string, endDate: string, surgeonId: string, roomId?: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.statop + 'surgeonTotalInterventions?fromDate=' + startDate + '&toDate=' + endDate + '&surgeonId=' + surgeonId + '&roomId=' + roomId);
  }

  getOneSurgeonStatOpInterventionsTotalPerRoom(startDate: string, endDate: string, surgeonId: string, roomId: string): Observable<any> {
    return this.httpClient.get<any>(`${urlEndPoints.statop}surgeonTotalInterventionsPerRoom?fromDate=${startDate}&toDate=${endDate}&surgeonId=${surgeonId}&roomId=${roomId}`);
  }

  getOneSurgeonStatOpInterventionsProgress(startDate: string, endDate: string, surgeonId: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.statop + 'surgeonInterventions?fromDate=' + startDate + '&toDate=' + endDate + '&surgeonId=' + surgeonId);
  }

  getOneSurgeonStatOpInterventionsPerDay(startDate: string, endDate: string, surgeonId: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.statop + 'surgeonInterventionsPerDay?fromDate=' + startDate + '&toDate=' + endDate + '&surgeonId=' + surgeonId);
  }

  getOneSurgeonEffectivenessRate(startDate: string, endDate: string, surgeonId: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.statop + 'surgeonEffectivenessRate?fromDate=' + startDate + '&toDate=' + endDate + '&surgeonId=' + surgeonId);
  }

  getOneSurgeonEffectivenessRatePerDay(startDate: string, endDate: string, surgeonId: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.statop + 'surgeonEffectivenessRatePerDay?fromDate=' + startDate + '&toDate=' + endDate + '&surgeonId=' + surgeonId);
  }

  getSurgeonsRanking(startDate: string, endDate: string, hospitalId, hasToValidate?: boolean): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.statop + 'surgeonsRanking?fromDate=' + startDate + '&toDate=' + endDate + '&hospitalId=' + hospitalId + '&hasToValidate=' + hasToValidate);
  }

  getAdditionRate(fromDate: string, toDate: string, surgeonId: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.statop + 'getLateDoctorAgendaPercentage/?surgeonId=' + surgeonId + '&fromDate=' + fromDate + '&toDate=' + toDate);
  }

  getOvertakingRate(fromDate: string, toDate: string, surgeonId: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.statop + 'roomsOverflowRateBySurgeon/?surgeonId=' + surgeonId + '&fromDate=' + fromDate + '&toDate=' + toDate);
  }

  getCountsPerReasons(hospitalId, startDate: string, endDate: string, profilesType: string): Observable<any> {
    return this.httpClient.get<any>(
      urlEndPoints.statop + 'countsPerReasons?fromDate=' + startDate + 
      '&toDate=' + endDate + '&profilesType=' + profilesType +
      '&hospitalId=' + hospitalId
    );  
  }

  getProfilesByReasonAndDate(hospitalId, date: string, reasonId: string, profilesType: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.statop + 
      'profiles?date=' + date + 
      '&reason=' + reasonId +
      '&profilesType=' + profilesType +
      '&hospitalId=' + hospitalId
    );
	}

  getStatOpEffectifPerSeniority(startDate: string, endDate: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.statop + 'effectifPerSeniority?fromDate=' + startDate + '&toDate=' + endDate);
  }

  getStatOpEffectifTotal(startDate: string, endDate: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.statop + 'totalEffectif?fromDate=' + startDate + '&toDate=' + endDate);
  }

  getStatOpResidencySeniorityDistribution(startDate: string, endDate: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.statop + 'seniorityResidencyDistribution?fromDate=' + startDate + '&toDate=' + endDate);
  }

  getStatOpNeedsPerSpecialty(startDate: string, endDate: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.statop + 'needsPerSpecialty?fromDate=' + startDate + '&toDate=' + endDate);
  }

  getStatOpAvailability(startDate: string, endDate: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.statop + 'availabilities?fromDate=' + startDate + '&toDate=' + endDate);
  }

  getTopThreeNumberOfGuards(startDate: string, endDate: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.statop + 'top3/guards?fromDate=' + startDate + '&toDate=' + endDate);
  }

  getStatOpAbsencePerReason(startDate: string, endDate: string, profile?:string): Observable<any> {
    const url = profile ? profile + '?fromDate=' + startDate + '&toDate=' + endDate : '?fromDate=' + startDate + '&toDate=' + endDate;
    return this.httpClient.get<any>(urlEndPoints.statop + '/details/absences/' + url);
  }

  getStatOpExtracliniqueProfiles(startDate: string, endDate: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.statop + 'to/prioritize/for/extraclinique?fromDate=' + startDate + '&toDate=' + endDate);
  }

  getStatOpGeneral(startDate: string, endDate: string, type: string, specialty:string): Observable<any> {
    let query = "";
    if(type) query += '&type=' + type
    if(specialty) query += '&specialty=' + specialty;

    return this.httpClient.get<any>(urlEndPoints.statop + 'general?fromDate=' + startDate + '&toDate=' + endDate + query);
  }

  getStatOpDistributionPerType(startDate: string, endDate: string , profileId: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.statop + '/work/distribution/' + profileId + '?fromDate=' + startDate + '&toDate=' + endDate);
  }

  getStatOpNumberOfGuards(startDate: string, endDate: string, profileId : string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.statop + 'total/gardes/' +profileId +'?fromDate=' + startDate + '&toDate=' + endDate);
  }

  getStatOpGuardDsitribution(startDate: string, endDate: string, profileId: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.statop + '/distribution/gardes/' + profileId + '?fromDate=' + startDate + '&toDate=' + endDate);
  }

  getStatOpWeekendGuardDsitribution(startDate: string, endDate: string, profileId: string): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.statop + '/distribution/weekendGardes/' + profileId + '?fromDate=' + startDate + '&toDate=' + endDate);
  }
  getRatesVariationPerDate(fromDate:string,toDate:string) : Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.statop + 'getRatesVariationPerDate/'+ '?fromDate=' + fromDate + '&toDate=' + toDate);
  }
  getRatesPerDayOfTheWeek(fromDate:string,toDate:string,room?:string) : Observable<any> {
    if(room){
      return this.httpClient.get<any>(urlEndPoints.statop + 'getRatesPerDayOfTheWeek/'+ '?fromDate=' + fromDate + '&toDate=' + toDate + '&room=' + room);
    }else 
    return this.httpClient.get<any>(urlEndPoints.statop + 'getRatesPerDayOfTheWeek/'+ '?fromDate=' + fromDate + '&toDate=' + toDate );
  }
  getGlobalRates(fromDate:string,toDate:string,hospitalId:string,room?:string) : Observable<any> {
    if(room){
      return this.httpClient.get<any>(urlEndPoints.statop + 'getGlobalRates/'+ '?fromDate=' + fromDate + '&toDate=' + toDate + '&room=' + room);
    }else 
    return this.httpClient.get<any>(urlEndPoints.statop + 'getGlobalRates/'+ '?fromDate=' + fromDate + '&toDate=' + toDate);
  }

  getSurgeonsOverflowRate(startDate: string, endDate: string, hasToValidate?: boolean): Observable<any> {
    return this.httpClient.get<any>(urlEndPoints.statop + 'getSurgeonOverflowRate?fromDate=' + startDate + '&toDate=' + endDate + '&hasToValidate=' + hasToValidate);
  }

  getRatesPerDateAndRoom(startDate: string, endDate: string, hospitalId): Observable<any> {
    return this.httpClient.post<any>(urlEndPoints.statop + 'ratesPerDateAndRoom?fromDate=' + startDate + '&toDate=' + endDate + '&hospitalId=' + hospitalId, null);
  }

/* ***************************** */ 


getTVO(startDate: string, endDate: string, surgeonId?: string, roomId?: string, specialtyId?: string): Observable<any> {
  return this.httpClient.get<any>(urlEndPoints.statop + 'getTVO?fromDate=' + startDate + '&toDate=' + endDate+ '&surgeonId=' + surgeonId + '&roomId=' + roomId + '&specialtyId=' + specialtyId);
}

getTotalTVO(startDate: string, endDate: string, surgeonId?: string, roomId?: string, specialtyId?: string): Observable<any> {
  return this.httpClient.get<any>(urlEndPoints.statop + 'getTotalTVO?fromDate=' + startDate + '&toDate=' + endDate+ '&surgeonId=' + surgeonId + '&roomId=' + roomId + '&specialtyId=' + specialtyId);
}

getTROS(startDate: string, endDate: string, surgeonId?: string, roomId?: string, specialtyId?: string, withoutUrgences?: boolean): Observable<any> {
  return this.httpClient.get<any>(urlEndPoints.statop + 'getTROS?fromDate=' + startDate + '&toDate=' + endDate+ '&surgeonId=' + surgeonId + '&roomId=' + roomId
                                                                                                + '&specialtyId=' + specialtyId + '&withoutUrgences=' + withoutUrgences);
}

getAverageLateStartRate(startDate: string, endDate: string, surgeonId?: string, roomId?: string, specialtyId?: string): Observable<any> {
  return this.httpClient.get<any>(urlEndPoints.statop + 'getAverageLateStartRate?fromDate=' + startDate + '&toDate=' + endDate+ '&surgeonId=' + surgeonId + '&roomId=' + roomId + '&specialtyId=' + specialtyId);
}

getAverageEarlyEndRate(startDate: string, endDate: string, surgeonId?: string, roomId?: string, specialtyId?: string): Observable<any> {
  return this.httpClient.get<any>(urlEndPoints.statop + 'getAverageEarlyEndRate?fromDate=' + startDate + '&toDate=' + endDate+ '&surgeonId=' + surgeonId + '&roomId=' + roomId + '&specialtyId=' + specialtyId);
}

getIsAmbuRate(startDate: string, endDate: string, surgeonId?: string, roomId?: string, specialtyId?: string): Observable<any> {
  return this.httpClient.get<any>(urlEndPoints.statop + 'getIsAmbuRate?fromDate=' + startDate + '&toDate=' + endDate+ '&surgeonId=' + surgeonId + '&roomId=' + roomId + '&specialtyId=' + specialtyId);
}

getIsUrgencesRate(startDate: string, endDate: string, surgeonId?: string, roomId?: string, specialtyId?: string): Observable<any> {
  return this.httpClient.get<any>(urlEndPoints.statop + 'getIsUrgencesRate?fromDate=' + startDate + '&toDate=' + endDate + '&surgeonId=' + surgeonId + '&roomId=' + roomId + '&specialtyId=' + specialtyId);
}

getTimeBetweenInterventions(startDate: string, endDate: string, surgeonId?: string, roomId?: string, specialtyId?: string): Observable<any> {
  return this.httpClient.get<any>(urlEndPoints.statop + 'getTimeBetweenInterventions?fromDate=' + startDate + '&toDate=' + endDate + '&surgeonId=' + surgeonId + '&roomId=' + roomId + '&specialtyId=' + specialtyId);
}

getCleaningTime(startDate: string, endDate: string, surgeonId?: string, roomId?: string, specialtyId?: string): Observable<any> {
  return this.httpClient.get<any>(urlEndPoints.statop + 'getCleaningTime?fromDate=' + startDate + '&toDate=' + endDate + '&surgeonId=' + surgeonId + '&roomId=' + roomId + '&specialtyId=' + specialtyId);
}

getRoomHours(startDate: string, endDate: string, roomId: string): Observable<any> {
  return this.httpClient.get<any>(urlEndPoints.statop + 'getRoomHours?fromDate=' + startDate + '&toDate=' + endDate + '&roomId=' + roomId);
}

}
