import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from "@angular/material";
import { CustomDateAdapterForDay } from '../../utils/custom-date-adapter';
import { CalendarService } from 'src/app/shared/services/calendar.service';
import { UtilisService } from 'src/app/shared/services/utilis.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ANESTHETIST, ANESTHETIST_TYPES, IADE, NURSE } from '../../const/glabals.const';
import { Router } from '@angular/router';
import { ToastService } from '../../services/toast.service';
import { HospitalService } from '../../services/hospital.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { Specialty } from '../../models/specialty.model';
import { Subscription } from 'rxjs';
import { SpecialtyService } from '../../services/specialty.service';
import { ErrorService } from '../../services/error.service';
import { WishesService } from '../../services/wishes.service';
import { toPascalCase } from "../../utils/cross-functions";
import { ProfileService } from '../../services/profile.service';
import { Profile } from '../../models/profile.model';

@Component({
  selector: 'app-relaunch-availability',
  templateUrl: './relaunch-availability.component.html',
  styleUrls: ['./relaunch-availability.component.scss'],
  providers: [{ provide: DateAdapter, useClass: CustomDateAdapterForDay }]
})
export class RelaunchAvailabilityComponent implements OnInit {
  public toPascalCase = toPascalCase;
  formGroup: any;
  isSending: boolean
  message: string;
  // IBODE et IDE -> IBODE/IDE : one checkbox
  // AS -> AS : one checkbox
  // IBODE et IDE et AS -> Infirmiere : two checkbox
  // Anesthésistes -> Anesthésiste : one checkbox
  // Iade -> Iade : one checkbox
  // Anesthésistes et Iade -> ANESTHESIST_IADE : two checkbox
  public specialties: Specialty[] | any[];
  public profilesPositions: { label: string, value: string }[] = [];
  public profiles: any[];
  public ratedProfiles: any[];
  public filteredProfiles: any[] = [];
  public selectedProfiles: any[] = [];
  private page: number = 1;
  private pageSize: number;

  public chosenPosition: any = [];
  public chosenResidencies: any = [];
  public chosenSeniorities : string[] = [];
  public chosenSpecialty: any;

  public loadingProfiles; boolean = false;
  public targeted: boolean = false;
  public competenceFilter: boolean = false;
  public showRole: boolean = true;
  public popupLoading: boolean = true;

  public profileType: string = '';
  public type: string;

  residencies = [
    { value: 'Vacataire', label: 'Vacataire', isChecked: true, isDisabled: false },
    { value: 'Titulaire', label: 'Titulaire', isChecked: false, isDisabled: false  }
  ]
  public seniorities = [
    {
      label: "Sénior",
      value: "Senior",
      isChecked: false,
      isDisabled: false,
    },
    {
      label: "Junior",
      value: "Junior",
      isChecked: false,
      isDisabled: false,
    },
    {
      label: "Interne",
      value: "Interne",
      isChecked: false,
      isDisabled: false,
    }
  ]

  
  public typeInfOptions = [
    {
      label: "Aide Opératoire",
      value: "Aide Opératoire",
    },
    {
      label: "Circulante",
      value: "Circulante",
    }
  ];

  public typeAnesthOptions = [
    {
      label: "Bloc",
      value: "Bloc",
    },
    {
      label: "Consultation",
      value: "Consultation",
    }
  ];

  isInternResponsible: boolean;
  currentUser: any;
  public isDateValid: boolean = false;
  
  public disabled: Boolean = false;
  public isSpinning: Boolean = false;
  public showSeniority: boolean = false;
  public seniorityValid: boolean = false;
  public isNurse: boolean;

  private fetchSpecialtiesSubscription: Subscription;
  private getProfilesBySpecSubscription: Subscription;
  private getProfilesSubscription: Subscription;

  public saveButtonTooltip: string = "Les dates ne sont pas valides";

  public isMultiHospitals: boolean = false;
  public hospitals: any[] = []
  selectedHospitalsId: any;

  constructor(
    public dialogRef: MatDialogRef<RelaunchAvailabilityComponent>,
    private hospitalService: HospitalService,
    private formBuilder: FormBuilder,
    private calendarService: CalendarService,
    private utilisService: UtilisService,
    private userService: UserService,
    private router: Router,
    private storageService: StorageService,
    private toastService: ToastService,
    private specialtyService: SpecialtyService,
    private errorService: ErrorService,
    private wishesService: WishesService,
    private profileService: ProfileService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.isInternResponsible = this.userService.isInternResponsible();

    if (this.isInternResponsible) {
      this.seniorities = [
        {
          label: "Interne",
          value: "Interne",
          isChecked: false,
          isDisabled: false,
        }
      ];
    }
  }

  async ngOnInit() {
    this.getProfileType();
    this.setPositions()
    this.getCurrentUser()
    this.isMultiHospitals = this.userService.isCurrentUserHasMultipleHospitalsSelected()
    this.currentUser = this.storageService.getUser()
    this.selectedHospitalsId = this.userService.getSelectedHospitals()
    this.selectedHospitalsId.forEach(hospitalId => {
      const hospital = this.currentUser.profile.hospitals.find((hospital) => String(hospital._id) == String(hospitalId))
      this.hospitals.push({
        name: hospital.name,
        _id: hospital._id
      })
    });

    this.specialties = await this.fetchSpecialties();
    this.makeForm();
    this.initListeners();
    this.showSeniority = (this.hospitalService.doesHospitalHaveAnesthDetailsOption() && this.formGroup.value.position.length >= 1 &&  this.formGroup.value.position[0] == "Anesthésiste")
    this.actualiseValid(null, null)
    this.formGroup.get("specialty").setValue(this.specialties[0]._id);
    this.popupLoading = false;
    if(this.competenceFilter){
      this.getProfilesBySpecialty(this.profileType).then((res)=>{
      this.ratedProfiles = res;
      this.loadingProfiles = false;
    });
    }else{
      const hospitalId = this.isMultiHospitals ? this.hospitals[0]._id : this.selectedHospitalsId[0]
      this.getProfiles(hospitalId).then((res)=>{
        this.profiles = res;
        this.loadingProfiles = false;
        this.filterProfiles();
      })
    }
  }

  actualiseValid(position, seniority) {
    
    if (!position)
      position = this.formGroup.value.position
    if (!seniority)
      seniority = this.formGroup.value.seniority

    if (!position || position.length == 0)
      this.seniorityValid = false;
    else if (!this.hospitalService.doesHospitalHaveAnesthDetailsOption())
      this.seniorityValid = true
    else if (!position.includes("Anesthésiste"))
      this.seniorityValid = true;
    else if (position.includes("Anesthésiste"))
      this.seniorityValid = seniority.length >= 1;
  }

  initListeners(): void {
    this.formGroup.controls['startDate'].valueChanges.subscribe(value => {
      this.updateIsDateValid();
    });

    this.formGroup.controls['endDate'].valueChanges.subscribe(value => {
      this.updateIsDateValid();
    });

    this.formGroup.get("specialty").valueChanges.subscribe((val) => {
      this.chosenSpecialty = val;
      this.isNurse
      ? this.isNursesNeeded(val)
      : this.isAnesthNeeded(val)
      this.filterProfiles();
      this.formGroup.controls['selectAllProfiles'].setValue(false);
    });

    this.formGroup.get("position").valueChanges.subscribe((val) => {
      this.showSeniority = (this.hospitalService.doesHospitalHaveAnesthDetailsOption() && val.length >= 1 && val.includes("Anesthésiste"))
      this.actualiseValid(val, null);
      this.formGroup.controls['selectAllProfiles'].setValue(false);
      this.chosenPosition = val;

      this.filterProfiles();

    });

    this.formGroup.get("seniority").valueChanges.subscribe((val) => {
      this.actualiseValid(null, val);
      this.chosenSeniorities = val;
      if(this.competenceFilter){
        this.filterProfiles();
      }else{

      const hospitalId = this.isMultiHospitals ? this.hospitals[0]._id : this.selectedHospitalsId[0]
      this.filterProfiles();
      }
      this.formGroup.controls['selectAllProfiles'].setValue(false);
    });

    this.formGroup.get("targeted").valueChanges.subscribe((val) => {
      this.targeted = val;
      if(val)
      this.filterProfiles();
      this.formGroup.controls['selectAllProfiles'].setValue(false);
      this.formGroup.controls['competenceFilter'].setValue(false);

    });

    this.formGroup.get("competenceFilter").valueChanges.subscribe((val) => {
      this.competenceFilter = val;
      if(val){
        this.getProfilesBySpecialty(this.profileType).then((res)=>{
          this.ratedProfiles = res;
          this.loadingProfiles = false;
          this.filterProfiles();
          this.formGroup.controls['selectAllProfiles'].setValue(false);
        });
      }else{
        this.filterProfiles();
      }
    });

    if(this.isNurse){
      this.formGroup.get("typeInf").valueChanges.subscribe((val) => {

        this.typeInfOptions.forEach((role)=> {
          if(val === role.value)
            this.type = role.value;
        })

        this.filterProfiles();
        this.formGroup.controls['selectAllProfiles'].setValue(false);
      });
    }else{
      this.formGroup.get("typeAnesth").valueChanges.subscribe((val) => {
        if(val == this.typeAnesthOptions[0].value)
          this.type = 'bloc';
        if(val == this.typeAnesthOptions[1].value)
          this.type = 'consultation';
        this.formGroup.controls['selectAllProfiles'].setValue(false);
        this.filterProfiles();

      });
    }
    
    this.formGroup.controls['residency'].valueChanges.subscribe((val) => {
      
      this.chosenResidencies = val;
      this.filterProfiles();
      this.formGroup.controls['selectAllProfiles'].setValue(false);
    });
    

    this.formGroup.controls['selectAllProfiles'].valueChanges.subscribe((val) => {
        this.selectAll(this.filteredProfiles, val);
    });
  }

  onChangeService() {
    this.loadingProfiles = true;
    this.profiles = [];
    this.ratedProfiles=[];
    this.filteredProfiles=[];
    
    this.showSeniority = (this.hospitalService.doesHospitalHaveAnesthDetailsOption() && this.formGroup.value.position.length >= 1 &&  this.formGroup.value.position[0] == "Anesthésiste")
    if (window.location.href.endsWith("Anesthesiste") && this.hospitalService.doesHospitalHaveAnesthDetailsOption()) {
      this.getAnesthSpecialties();
      this.getProfilesBySpecialty(this.formGroup.value.position).then((res)=>{
      // this.getProfilesBySpecialtyfromhospital(this.formGroup.value.position, this.formGroup.value.hospitals._id).then((res)=>{
        this.ratedProfiles = res;
        this.filterProfiles();
      });
    } else {
      this.fetchSpecialties().then((specialties)=>{
        this.specialties = specialties;
        this.showSeniority = (this.hospitalService.doesHospitalHaveAnesthDetailsOption() && this.formGroup.value.position.length >= 1 &&  this.formGroup.value.position[0] == "Anesthésiste")
        this.actualiseValid(null, null)
        this.formGroup.get("specialty").setValue(this.specialties[0]._id);
      });
      this.getProfilesBySpecialty(this.formGroup.value.position).then((res)=>{
        this.ratedProfiles = res;
        this.filterProfiles();
      });
    } 
    this.filterProfiles();
  }

  updateIsDateValid(): void {
    if (this.getStartDate <= this.getEndDate) {
      this.isDateValid = true;
    } else {
      this.isDateValid = false;
    }
  }

  setPositions() {
    const path = this.router.url.split('/')[2];
    if (path == NURSE) {
      this.chosenPosition = [this.data.position[0].name];
      this.profilesPositions = this.data.position.map((position, index) => {
        return {
          label: position.name,
          value: position.name,
          isChecked: index === 0,
        };
      })
    } else {
      this.chosenPosition[0] = 'Anesthésiste'
      this.profilesPositions = [
        {
          label: 'Anesthésistes',
          value: ANESTHETIST
        },
        {
          label: 'Iade',
          value: IADE[0]
        }
      ];
    }
    if (this.userService.isIadRes()){
      this.chosenPosition[0] = 'Iade'
      this.profilesPositions = [
        {
          label: 'Iade',
          value: IADE[0]
        }
      ];
    }

    if (this.isInternResponsible){
      this.profilesPositions = [
        {
          label: 'Anesthésistes',
          value: ANESTHETIST
        }
      ];
    }
    

  }

  getCurrentUser(): void {
    this.currentUser = this.userService.getCurrentUser();
  }

  makeForm() {
    if (!this.isMultiHospitals) {
      if(this.isNurse) {
        this.formGroup = this.formBuilder.group({
          startDate: ["", [Validators.required]],
          endDate: ["", [Validators.required]],
          position: [[this.profilesPositions[0].value], [Validators.required]],
          residency: [this.residencies[0].value, [Validators.required]],
          seniority: [[]],
          specialty: [this.specialties[0], [Validators.required]],
          targeted: [false, [Validators.required]],
          competenceFilter : [false, [Validators.required]],
          selectAllProfiles: [false],
          typeInf :["Aide Opératoire",[Validators.required]],
        });
      } else {
        this.formGroup = this.formBuilder.group({
          startDate: ["", [Validators.required]],
          endDate: ["", [Validators.required]],
          position: [[this.profilesPositions[0].value], [Validators.required]],
          residency: [this.residencies[0].value, [Validators.required]],
          seniority: [[]],
          specialty: [this.specialties[0], [Validators.required]],
          targeted: [false, [Validators.required]],
          competenceFilter : [false, [Validators.required]],
          selectAllProfiles: [false],
          typeAnesth :["Bloc",[Validators.required]],
        });
      }
    } else {
      if (this.isNurse) {
        this.formGroup = this.formBuilder.group({
          startDate: ["", [Validators.required]],
          endDate: ["", [Validators.required]],
          position: [[this.profilesPositions[0].value], [Validators.required]],
          residency: [this.residencies[0].value, [Validators.required]],
          seniority: [[]],
          specialty: [this.specialties[0], [Validators.required]],
          targeted: [false, [Validators.required]],
          competenceFilter : [false, [Validators.required]],
          selectAllProfiles: [false],
          typeInf :["Aide Opératoire",[Validators.required]],
          hospitals: [this.hospitals[0], [Validators.required]]
        });
      } else {
        this.formGroup = this.formBuilder.group({
          startDate: ["", [Validators.required]],
          endDate: ["", [Validators.required]],
          position: [[this.profilesPositions[0].value], [Validators.required]],
          residency: [this.residencies[0].value, [Validators.required]],
          seniority: [[]],
          specialty: [this.specialties[0], [Validators.required]],
          targeted: [false, [Validators.required]],
          competenceFilter : [false, [Validators.required]],
          selectAllProfiles: [false],
          typeAnesth :["Bloc",[Validators.required]],
          hospitals: [this.hospitals[0], [Validators.required]]
        });
      }
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  get getStartDate() {
    return this.formGroup.controls['startDate'].value
  }

  get getEndDate() {
    return this.formGroup.controls['endDate'].value
  }

  changedEditor(event) {
    this.message = event['editor']['root']['innerHTML']
  }

  getSelectedPosition(): string {
    if(this.isNurse){
      if(this.chosenPosition.length == 3){
        return NURSE;
      }
      if(this.chosenPosition.length == 2){
        return 'IBODE/IDE';
      }else{
        return this.chosenPosition[0].name;
      }
    }else{
      if(this.chosenPosition.length == 2){
        return 'ANESTHESIST_IADE'; // this will never be verified
      }else{
        return this.chosenPosition[0].includes('Anesthésiste') ? 'Anesthésiste' : 'Iade';
      }
    }
  
  }

  startTimeBigThanEndTime = (d: Date): boolean => {
    if (this.getStartDate && this.getStartDate instanceof Date) {
      return this.getStartDate.getTime() <= d.getTime();
    }
    return true;
  };

  fetchSpecialties(): Promise <Specialty[]>{
      if(this.isNurse){
        return this.getNurseSpecialties();
      }else{
        return this.getAnesthSpecialties();
      } 
  }

  getAnesthSpecialties(){
    return new Promise<Specialty[]>((resolve,reject)=>{      
      var blocSpecialties=[]
      var consultationSpecialties=[];
      this.wishesService.getWishesByProfileAndType(this.currentUser.profile._id, 'bloc').subscribe(result => {
        result = result.filter(s => s.specialty.name !== "DEFAULT_" &&
        s.specialty.name !== 'Bariatrique' &&
        s.specialty.name !== 'Urgences' && 
        s.specialty.name!=='Anesthésie-réanimation' &&
        !s.specialty.name.match(/extra/i));

        blocSpecialties = result.map((obj)=>obj.specialty);
        this.wishesService.getWishesByProfileAndType(this.currentUser.profile._id,'consultation').subscribe(result => {
          result = result.filter(s => s.specialty.name !== "DEFAULT_" &&
          s.specialty.name !== 'Bariatrique' &&
          s.specialty.name !== 'Urgences' && 
          s.specialty.name!=='Anesthésie-réanimation' &&
          !s.specialty.name.match(/extra/i));

        consultationSpecialties = result.map((obj)=>obj.specialty);

        let specialties = [...(blocSpecialties || []), ...(consultationSpecialties || [])].reduce((acc, curr) => {
          if (!acc.some(item => item._id === curr._id)) {
            acc.push(curr);
          }
          return acc;
        }, []);  
        resolve(specialties);      
        });
      });
    });   
  }

  getNurseSpecialties(){
    return new Promise<Specialty[]>((resolve,reject)=>{
      this.fetchSpecialtiesSubscription = this.specialtyService.getAllSpecialties().subscribe((specialties: Specialty[]) => {
        if (specialties && specialties.length > 0) {
          specialties = specialties.filter(s => s.name !== "DEFAULT_");
          resolve(specialties);
        }
      }, error => {
        this.errorService.handleError(error)
        reject(error);
      });
    
    }) 
  }

  getProfileType(){
    this.chosenResidencies.push(this.residencies[0].value);
    let url = this.router.url;
    if(url.includes('Infirmiere')){
      this.isNurse = true;
      this.profileType = 'nurse';  
      this.type = 'ratedAideOp'
      this.data.position = this.data.position.filter((pos) => pos.name != 'IADE' && pos.name != 'Iade');  
    }else{
      this.isNurse = false;
      this.profileType = 'anesth';
      this.type = 'bloc'
    }
  }

  getProfilesBySpecialty(position:string){
    this.loadingProfiles = true;
    return new Promise <any[]> ((resolve,reject)=>{
      this.getProfilesBySpecSubscription = this.profileService.getProfilesBySpecialty(position)
    .subscribe(
      (res)=>{
        resolve(res);    
      }
    )
    })
  }

  getProfilesBySpecialtyfromhospital(position:string, hospitalId:string){
    this.loadingProfiles = true;
    return new Promise <any[]> ((resolve,reject)=>{
      this.getProfilesBySpecSubscription = this.profileService.getProfilesBySpecialtyFromHospital(position , hospitalId)
    .subscribe(
      (res)=>{
        }
    )
    })
  }

  filterProfiles(){
    const profiles = this.competenceFilter && !this.chosenPosition.includes('Iade') ? this.ratedProfiles : this.profiles;
    this.filteredProfiles = [];
    if(!profiles || !this.chosenPosition.length || !this.chosenResidencies.length){
      return;
    }

    if(!this.competenceFilter){
      this.filteredProfiles = profiles.filter((profile)=>{
        return this.chosenResidencies.includes(profile.residency) 
        && (!this.isNurse && this.showSeniority
            ? this.chosenSeniorities.includes(profile.seniority) 
            : true)
        && this.chosenPosition.includes(profile.position);
      })
      const uniqueIds = {}; 
      const uniqueObjects = [];
    
      for (const obj of this.filteredProfiles) {
        const fieldValue = obj['_id'];
    
        if (!uniqueIds[fieldValue]) {
          uniqueIds[fieldValue] = true;
          uniqueObjects.push(obj);
        }
      }
      this.filteredProfiles = uniqueObjects;
      this.sortProfiles(this.filteredProfiles);
    return;
    } else {
      if (this.chosenPosition.includes("Iade")) {
        this.filteredProfiles = this.profiles.filter(profile => { 
          return profile.specialties && profile.specialties.find(specialty => String(specialty) === this.chosenSpecialty)
        });
        return;
      }
      let specialty = this.specialties.find(obj=>obj._id==this.chosenSpecialty);

      specialty.needs.forEach((role) => {
          if (role.role.name === this.type) {
            this.type = role.role._id;
          }
      });
      this.filteredProfiles = profiles.map(
        (profilesBySpecialty) => {
          if(profilesBySpecialty && profilesBySpecialty.specialty && profilesBySpecialty.specialty._id == this.chosenSpecialty && profilesBySpecialty[this.type] && profilesBySpecialty[this.type].length){
            return {...profilesBySpecialty[this.type]};
          }
        }).filter((profile)=> {return profile != undefined})
      if(!this.filteredProfiles || !this.filteredProfiles[0]) return;
      this.filteredProfiles = Object.keys(this.filteredProfiles[0]).map(key => this.filteredProfiles[0][key]);

      this.filteredProfiles = this.filteredProfiles.filter((profile)=>{ 
        return profile && this.chosenResidencies.includes(profile.residency) 
            && (!this.isNurse && this.showSeniority && !this.chosenPosition.includes('Iade') ? this.chosenSeniorities.includes(profile.seniority) : true)
            && this.chosenPosition.includes(profile.position);          
        });
    }
  }

  sortProfiles(profiles :Profile[]){
    if(!profiles) return;
    profiles = profiles.sort((a,b)=>
    {
      if (a.firstName.localeCompare(b.firstName) !== 0) { //if 2 firstNames don't have the same sort
        return a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase());
      } else {                                            // else compare by lastname
        return a.lastName.toLowerCase().localeCompare(b.lastName.toLowerCase());
      }
    });

  }

  filterNurses(profiles) {
    let selectedFormations = this.formGroup.value.position;
    profiles = profiles.filter((profile => {
      return selectedFormations.includes(profile.position)
    }));
    return profiles;
  }

  getProfiles(hospitalId) {
    this.loadingProfiles = true;
    let position = this.isNurse
        ? 'Infirmiere'
        : this.chosenPosition
    // let position = this.isNurse && this.chosenPosition.length > 1 
    //     ? 'IBODE , IDE , AS'
    //     : this.chosenPosition
    // if(position.length == 0){
    //   position = 'IBODE , IDE , AS'
    // }
    if(position == 'Anesthésiste'){
      position = 'anesth'
    }
    return new Promise <any []> ((resolve, reject)=>{
      this.getProfilesSubscription = this.profileService
      .getProfilesWithUserByPositionResidencyFromHospital(
        hospitalId,
        position,
        null,
        null,
        "",
        undefined,
        true,
        false,
      )
      .subscribe(
        (res) => {
          let result = this.isNurse ? this.filterNurses(res.docs) : res.docs
          this.loadingProfiles = false;
          resolve(res.docs);
        },
        (error) => {
          this.errorService.handleError(error);
        }
      );
    })
  }

  getSelectedProfiles(){
    return this.filteredProfiles.filter(profile=> profile.checked).map(elet => elet._id);
  }

  selectAll(profiles: any[], check:boolean) {
    profiles.map((el) => 
      {      
        if (!el.haveUser) {
          el.checked = false;
        } else {
          el.checked = check;
        } }
      );
  }

  isNursesNeeded(spec:any){
    let specialty = this.specialties.find(obj=>obj._id==spec);
    if(specialty){
      this.typeInfOptions = [];

      specialty.needs.forEach((role) => {
          this.typeInfOptions.push({
              label: role.role.name,
              value: role.role.name
          });
      });

      //Evite que le role selectionné soit basé sur la précédente spécialité.
      this.formGroup.get('typeInf').setValue(this.typeInfOptions[0].value)
    }
  }

  isAnesthNeeded(spec:any){
    if(this.specialties){
      let specialty = this.specialties.find(obj=>obj._id==spec);
      if(specialty){
        if(specialty.type.length<=0){
          this.showRole=false;
          this.typeInfOptions = [];
          return;
        }
        if(specialty.type.length>=2){
          this.showRole=true;
          this.typeAnesthOptions = [
            {
              label: "Bloc",
              value: "Bloc",
            },
            {
              label: "Consultation",
              value: "Consultation",
            }
          ];
          return;
        }
        if(specialty.type.includes('bloc')){
          this.showRole=true;
          this.formGroup.get('typeAnesth').setValue('Bloc')
  
          this.typeAnesthOptions = [
            {
              label: "Bloc",
              value: "Bloc",
            }
          ];
          return;
        }
        if(specialty.type.includes('consultation')){
          this.showRole=true;
          this.formGroup.get('typeAnesth').setValue('Consultation')
          this.typeAnesthOptions = [
            {
              label: "Consultation",
              value: "Consultation",
            }
          ];
          return;
        }
      }
    }
  }


  save() {
    this.isSpinning = true;
    this.disabled = true;
    this.selectedProfiles = this.getSelectedProfiles();
    let body = {
      startDate: this.utilisService.formatDate2(this.getStartDate, 2),
      endDate: this.utilisService.formatDate2(this.formGroup.controls['endDate'].value, 2),
      position: this.getSelectedPosition(),
      residency: this.formGroup.controls['residency'].value,
      seniority: this.formGroup.value.seniority,
      targeted: this.formGroup.value.targeted,
      hospital: "",
      profiles: this.selectedProfiles,
      message: this.message,
      individuals:this.selectedProfiles
    }
    if (this.isMultiHospitals) {
      body.hospital = this.formGroup.value.hospitals._id;      
    } else {
      body.hospital = this.currentUser.profile.hospitals[0]._id;
    }
    if (this.getStartDate.getTime() <= this.formGroup.controls['endDate'].value.getTime()) {
      this.calendarService.relaunchAvailability(body).subscribe(res => {
        this.disabled = false;
        this.isSpinning = false;
        this.close()
      })
    } else {
      this.toastService.errorToast('La date de début que vous avez sélectionné est plus grande que la date de fin');
    }
  }

  ngOnDestroy(): void {
    if (this.fetchSpecialtiesSubscription) {
      this.fetchSpecialtiesSubscription.unsubscribe();
    }

    if (this.getProfilesBySpecSubscription) {
      this.getProfilesBySpecSubscription.unsubscribe();
    }

    if (this.getProfilesSubscription) {
      this.getProfilesSubscription.unsubscribe();
    }
  }

}
