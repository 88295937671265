import { Component, Input, OnInit, Inject, ChangeDetectorRef, SimpleChange} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-popup-import-data',
  templateUrl: './popup-import-data.component.html',
  styleUrls: ['./popup-import-data.component.scss']
})
export class ImportDataPopupComponent implements OnInit {

  @Input() title: string = "";
  @Input() generateBtnTitle: string = "";
  public isTodayMinDate: boolean;
  isAllSelected: boolean;
  isAllRoomsSelected: boolean;
  public selectedDate: Date;
  public formGroup: FormGroup;
  public minDate = new Date();
  private getStartDateSubscription: Subscription;
  
  constructor(public dialogRef: MatDialogRef<ImportDataPopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public formBuilder: FormBuilder,
              private readonly changeDetectorRef: ChangeDetectorRef ) { 
    this.title = data.title;
    this.generateBtnTitle = data.generateBtnTitle;
    this.isAllSelected = true;
    this.selectedDate = data.date;
    this.isTodayMinDate = data.isTodayMinDate;
    }

  ngOnInit() {
    this.makeForm();
  }

  ngOnChanges(changes: SimpleChange) {
    if (this.formGroup.get("startDate").value > this.formGroup.get("endDate").value) {
      this.formGroup.get("endDate").reset();
    }
  }

  makeForm(): void {
      this.formGroup = this.formBuilder.group({
        startDate: [this.selectedDate, [Validators.required]],
        endDate: [this.selectedDate, [Validators.required]],
      });
    this.minDate = new Date(this.selectedDate);
    
    if(this.getStartDateSubscription) this.getStartDateSubscription.unsubscribe();
    
    this.getStartDateSubscription = this.formGroup.get("startDate").valueChanges.subscribe(x => {
        this.minDate = x;
    })
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  import() {
    this.dialogRef.close({startDate: this.formGroup.value.startDate, endDate: this.formGroup.value.endDate});
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    if(this.getStartDateSubscription) this.getStartDateSubscription.unsubscribe();
  }
}
