import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';

import {urlEndPoints} from '../config/end-points';
import {AllIntervention} from '../models/all-intervention.model';
import { map } from 'rxjs/operators';

@Injectable()
export class AllInterventionDal {

  constructor(private httpClient: HttpClient) {
  }

  getAllInterventions(filterName: string, limit: number, page: number): Observable<any> {
    return this.httpClient.get<any[]>(urlEndPoints.allInterventions + '?limit=' + limit + '&page=' + page + '&name=' + filterName);
  }

  addIntervention(intervention: AllIntervention): Observable<AllIntervention> {
    return this.httpClient.post<AllIntervention>(urlEndPoints.allInterventions, intervention);
  }
}
