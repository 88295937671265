import {Injectable} from '@angular/core';
import {Event, NavigationEnd, Router} from '@angular/router';

import {Observable} from 'rxjs/internal/Observable';
import {catchError, filter} from 'rxjs/operators';

import {pages} from '../../shared/config/pages';
import {AuthenticationService} from './authentication.service';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class NavigationService {

  private history = [];
  public setSidenav: Subject<boolean> = new Subject<boolean>();


  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  getCurrentPage() {
    return this.router.url;
  }

  routeChanges(): Observable<NavigationEnd> {
    return this.router.events.pipe(
      filter((event: Event) => event instanceof NavigationEnd),
      catchError(error => {
        return error;
      }),
    ) as Observable<NavigationEnd>;
  }

  initNavigation(event: NavigationEnd): Promise<void> {
    if (this.authenticationService.isAuthenticated()) {
      return this.navigateTo(event.url, null);
    } else {
      return this.navigateTo(pages.login, null);
    }
  }

  hideSideNavInCurrentPage(event: NavigationEnd): boolean {
    return event.url === pages.login || event.url === pages.signIn || event.url === pages.firstLogin
      || event.url === pages.resetRequest || event.url.includes("/reset-password/") || event.url === pages.fullscreenHome;
  }

  navigateTo(page: string, event?: NavigationEnd | undefined, data?: any, replaceUrl = true, state = undefined): Promise<void> {
    (document.body.style as any).zoom = "100%"; //Reset zoom
    const currentPage = this.currentPage(event);
    if (page !== currentPage && !(currentPage === pages.root && page === pages.defaultRoute)) {
      return new Promise((resolve, reject) => {
        this.router.navigate([page], {queryParams: data, replaceUrl, state: state})
          .then(success => {
            if (success) {
              resolve();
            } else {
              reject(new Error('navigation failed'));
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    }
    return Promise.resolve();
  }

  reloadCurrentRoute() {
    const currentUrl = this.router.url;
    if (currentUrl.includes(pages.pendingRequestsHome)) {
      this.router.navigateByUrl(pages.about, {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
    } else {
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          this.router.navigate([currentUrl]);
      });
    }
  }

  navigateByUrl(page: string, event?: NavigationEnd | undefined, data?: any): Promise<void> {
    const currentPage = this.currentPage(event);
    if (page !== currentPage && !(currentPage === pages.root && page === pages.defaultRoute)) {
      return this.router.navigateByUrl(page, {queryParams: data, replaceUrl: true})
        .then(success => {
          if (!success) {
            console.error('navigation failed');
          }
        });
    }
    return Promise.resolve();
  }


  public loadRouting(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({urlAfterRedirects}: NavigationEnd) => {
        this.history = [...this.history, urlAfterRedirects];
      });
  }

  public getHistory(): string[] {
    return this.history;
  }

  public getPreviousUrl(): string {
    return this.history[this.history.length - 2] || '/';
  }

  goBack(): Promise<void> {
    return this.navigateTo(this.getPreviousUrl());
  }

  private currentPage(event: NavigationEnd | undefined): string | null {
    return event ? event.url : null;
  }

}
