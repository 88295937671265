import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {Chart, ChartOptions, registerables} from 'chart.js';
import {BLOC, NURSE} from '../../const/glabals.const';
import {capitalize, transformText} from '../../utils/cross-functions';
import {PRESENCE_REASONS} from '../../const/absence-reasons.const';
import { themeStyling, themeStylingAccountGraphColors, themeStylingColors } from 'src/themes/common/principal-theme';
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(...registerables);
Chart.register(ChartDataLabels);

@Component({
  selector: 'app-doghnut-chart',
  templateUrl: './doghnut-chart.component.html',
  styleUrls: ['./doghnut-chart.component.scss']
})
export class DoghnutChartComponent implements AfterViewInit, OnInit, OnChanges {
  @Input() isSurgeon: true;
  @Input() absenceDescription: any;
  @Input() presences: number;
  @Input() isBig: boolean = false;
  @Input() refreshChartSubject: Subject<void> = new Subject<void>();


  @Input() canvasName: string;
  @Input() data: any[];
  @Input() type: string;
  @Input() chartType: string;
  @Input() showTooltips: boolean = false;
  chartDate: any[];
  chartLabels: string[];
  formattedData: string[];
  chartColors: string[];

  chart: BehaviorSubject<Chart> = new BehaviorSubject(undefined);

  colors = themeStylingAccountGraphColors.account_availability_graph;

  absenceColors = themeStylingAccountGraphColors.account_availability_graph_absence;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.data = this.formatData(this.data);
    // ChartDate is the data that we will pass to chart.js
    this.chartDate = this.generateData();
    // chartLabels is the labels that we will pass to chart.js and to the legend
    this.chartLabels = this.generateLabels();
    // formattedData is the data that we will pass to the legend
    this.formattedData = this.generateFormattedData();
    this.chartColors = this.generateColors();

    // If we already created the graph, means the html is already rendred, means we already executed ngAfterViewInit
    if (this.chart.getValue()) {
      this.chart.getValue().destroy();
      this.showGraph();
    }  
  }

  showGraph(): void {
    const config: ChartOptions & { cutout: any } = {
      hover: { mode: null },
      plugins : {
        datalabels: {
          display: false,
          color: this.chartType === 'PRESENCE' ?   themeStylingAccountGraphColors.font_account_availability_graph_presence : themeStylingAccountGraphColors.font_account_availability_graph_absence,
          font: {
            size: 14,
            weight: 'bold'
          },
        },
        tooltip: {
          enabled: this.showTooltips,
          borderColor: '#000000',
          borderWidth: 1,
          titleColor: '#000000',
          footerColor: '#000000',
          displayColors: false,
          cornerRadius: 2,
          callbacks: {
            label: (tooltipItem) => {
              if (this.data[tooltipItem.dataIndex].tooltip) {
                return this.data[tooltipItem.dataIndex].tooltip;
              }
              return capitalize(this.data[tooltipItem.dataIndex].label) + " : " + this.data[tooltipItem.dataIndex].formattedData ? this.data[tooltipItem.dataIndex].formattedData : this.data[tooltipItem.dataIndex].value;
            }
          }
        },
        legend: {
          display: false,
          position: 'right',
          labels: {
            font : {
              family: themeStyling.fontFamilyPrimary,
              size: 14,
            },
            padding: 20,
            boxWidth: 10
          }
        }
      },
      aspectRatio: 1,
      responsive: true,
      maintainAspectRatio: false,
      cutout: '70%'
    };

    this.chart.next(new Chart(this.canvasName, {
      type: 'doughnut',
      data: {
        labels: this.chartLabels,
        datasets: [
          {
            backgroundColor: this.chartColors,
            data: this.chartDate
          }
        ]
      },
      options: config
    }));
  }

  // subscribeToDataUpdate(): void {
  //   this.refreshChartSubject.subscribe(() => {
  //     this.data = this.formatData(this.data);
  //     // ChartDate is the data that we will pass to chart.js
  //     this.chartDate = this.generateData();
  //     // chartLabels is the labels that we will pass to chart.js and to the legend
  //     this.chartLabels = this.generateLabels();
  //     // formattedData is the data that we will pass to the legend
  //     this.formattedData = this.generateFormattedData();
  //     this.chartColors = this.generateColors();

  //     this.chart.value.data.datasets = [
  //       {
  //         backgroundColor: this.chartColors,
  //         data: this.chartDate
  //       }
  //     ];

  //     this.chart.value.update();
  //   })
  // }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.showGraph();
  }

  formatData(data){
    const blocData = data.find(d => d.label === BLOC);
    const otherData =  data.filter(d => d.label !== BLOC);
    if(blocData){
      return [blocData, ...otherData]
    }
    return otherData
  }

  getBlockDays() {
    for (let i = 0; i < this.data.length; i++) {
      if (this.data[i].label === 'BLOCK') {
        const value = this.data[i].value;
        this.data.splice(i, 1);
        return value;
      }
    }
    return 0;
  }
  addBlockToBloc(value: number): void {
    for (let i = 0; i < this.data.length; i++) {
      if (this.data[i].label === 'BLOC') {
        this.data[i].value = this.data[i].value + value;
        return;
      }
    }
    if (value > 0) {
      this.data.push({
        value,
        label: 'BLOC'
      });
    }
  }

  generateLabels(): string[] {

    const labels: string[] = [];
    const isNurse = (this.type === NURSE);
    for (const item of this.data) {
      if (item.label) {
        const blocLabel = capitalize(item.label)
        labels.push(blocLabel);
      }
    }
    return labels;
  }

  itemIsPermitted(label: string): boolean {
    const isNurse = (this.type === NURSE);
    if (isNurse) {
      if (this.chartType === 'PRESENCE') {
        return label !== 'CONSULTATION' && PRESENCE_REASONS.includes(label);
      } else { // ABSENCE
        return label !== 'CONGRESS';
      }
    }
    return true;
  }

  generateData(): any[] {
    const data: any[] = [];
    for (const item of this.data) {
      if (item.label) {
        data.push(item.value);
      }
    }
    return data;
  }

  generateFormattedData(): any[] {
    const data: any[] = [];
    for (const item of this.data) {
      if (item.formattedData) {
        data.push(item.formattedData);
      } else {
        data.push(item.value);
      }
    }
    return data;
  }

  generateColors(): any[] {

    let data: any[] = [];
    switch(this.chartType) {
      case "ABSENCE":
        data = this.absenceColors       
        break;
      case "PRESENCE":
        data =  this.getPresenceColors(this.data) ;
        break;
      default:
        data = this.colors;
    }
    return data;
  }

  getPresenceColors(data){
    return data.map(item => {
      if(item.label.toLowerCase() === "bloc"){
        return themeStylingAccountGraphColors.account_availability_graph_bloc
      }else if(item.label.toLowerCase() === "consultation"){
        return themeStylingAccountGraphColors.account_availability_graph_consultation
      }else if(item.label.toLowerCase() === "garde" || item.label.toLowerCase() === "astreinte" || item.label.toLowerCase() === "garde iade"){
        return themeStylingAccountGraphColors.account_availability_graph_garde
      }else{
        return item.color
      } 
    })
  }

  getColor(name) {
    switch (name) {
      case 'Bloc':
        return '#7FD7A7';
      case 'Astreinte':
        return '#FFDF80';
      case 'Garde':
        return '#FFDF80';
      case 'Vacation':
        return '#7ED7F9';
      case 'Consultation':
        return '#BAB8B9';
      case 'Formation':
        return '#FC8274';
      case 'Congé payé':
        return '#B42224';
      case 'RTT':
        return '#F48284';
      case 'Repos d\'astreinte':
        return '#CC5E5C';
      case 'Repos de garde':
        return '#CC5E5C';
      case 'Maladie':
        return '#FFA07A';
      case 'Présence':
        return '#30cc71';
      default:
        break;
    }
  }

}
