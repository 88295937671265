import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReasonDal } from '../dal/reason.dal';
import { RuleDal } from '../dal/rule.dal';
import { Rule } from '../models/rule.model';

@Injectable()

export class RuleService {

  constructor(private ruleDal: RuleDal) {
  }

  getAllRules(){
    return this.ruleDal.getAllRules();
  }

  addRule(rule: Rule){
    return this.ruleDal.addRule(rule);
  }

  editRule(rule : Rule){
    return this.ruleDal.editRule(rule);
  }

  deleteRule(ruleID : string){
    return this.ruleDal.deleteRule(ruleID);
  }

}