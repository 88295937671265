
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { NavigationCancel, NavigationEnd, NavigationStart, Router, RouterEvent } from "@angular/router";
import { User } from "../shared/models/user.model";
import { pages } from "../shared/config/pages";
import { isAnesthetist, Profile } from "../shared/models/profile.model";
import { ANESTHETIST, NURSE, NURSE_TYPES, SURGEON } from "../shared/const/glabals.const";
import { StorageService } from "../core/services/storage.service";
import { NavigationService } from "../core/services/navigation.service";
import { ErrorService } from "../shared/services/error.service";
import { MessagingChatService } from "../shared/services/messagingChat.service";
import { UserService } from "./../shared/services/user.service";
import { MENU } from "./../shared/config/menu";
import { HeaderService } from "./../core/services/header.service";
import { AuthenticationService } from "src/app/core/services/authentication.service";
import { Room } from "../shared/models/room.model";
import { RoomService } from "src/app/shared/services/room.service";
import { ChatGlobalSocket } from "../core/services/chatGlobalSocket.service";
import { ChatService } from '../core/services/chat.service';
import { Observable, Subscription } from "rxjs";
import * as _ from "lodash";
import { NotificationService } from "./../shared/services/notification.service";
import { UtilisService } from "src/app/shared/services/utilis.service";
import { NotificationsFeedComponent } from "./../shared/components/notifications-feed/notifications-feed.component";
import { capitalize, onAvatarError } from "../shared/utils/cross-functions";
import { ICONSET } from "src/themes/common/principal-theme";
import * as moment from "moment";
import { SO } from "../shared/models/surgeonOpenings.model";
import {ConfirmDialogComponent} from '../shared/components/confirm-dialog/confirm-dialog.component';
import {MatDialog, MatNavList} from '@angular/material';
import {AnnuairePopupComponent} from '../shared/components/annuaire-popup/annuaire-popup.component';
import { HospitalService } from "../shared/services/hospital.service";
import { roomWithSOAndSOH } from "../shared/interfaces/room.interfaces";
import { F, T } from "@angular/cdk/keycodes";
import { Subject } from 'rxjs';
import { takeUntil } from "rxjs/operators";



@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})

export class SideNavComponent implements OnInit, OnDestroy {
  public rightClickedPage: any;
  enteredButton = true;
  isMatMenuOpen = false;
  isMatMenu2Open = false;
  prevButtonTrigger;
  public pages = MENU;
  public iconTheme = ICONSET;

  public unseenMessages: number = 0;

  public currentPage: string;
  public currentUser: User;
  public currentUserImage: string;
  public isAnesth: boolean;
  private changeHospitalModeSubjectSubscription: Subscription;
  private getUnseenNotificationsSubscription: Subscription;
  private getMessageSeenNumSubscription: Subscription;
  private getSendBoxMessagesSubscription: Subscription;
  private getGlobalNewMessagesSubscription: Subscription;
  private goToMyAccountSubscription: Subscription;
  private checkSaveBeforeLogoutSubscription: Subscription;
  private goToConfigurationSubscription: Subscription;
  private goToSurgeonSubscription: Subscription;
  private checkSaveBeforeNavigateSubscription: Subscription;
  private getChangeHospitalsSelectedSubscription: Subscription;

  public osComponentOptions = {
    className : "os-theme-dark custom-sidebar",
    nativeScrollbarsOverlaid : {
      showNativeScrollbars : true
    },
    overflowBehavior : {
      x : "hidden",
  },
    paddingAbsolute: true,
    scrollbars: {
      autoHide: 'never',
      clickScrolling: true
    },
  };

  @Output() sidenavClose = new EventEmitter();
  @Output() sidenavToggle = new EventEmitter();
  @Input() isThereNewModifs:boolean;
  @Output() saveAgendas=new EventEmitter<boolean>();
  @ViewChild('rightClickMenu', { static: false }) rightClickMenu: any;
  @ViewChild('rightClickBtnContainer', {static: false}) rightClickBtnContainer: ElementRef;
  isUserHasLowLevel: boolean;
  userIsAuthenticated: boolean;
  openedRooms: number;
  roomsSubscription: any;
  currentDay: Date;
  sideNavReduced = false;
  enter: boolean;
  currentUserPosition : string;
  currentUserName : string;

  public isMultiHospitals: boolean = false;
  public hospitals: any[] = [];
  public selectedHospitals: any;
  public selectedHospitalsName: string = "";
  public soloHospitalMode: boolean = false;
  public nbSelectedHospitals: number = 1;

  private openedRoomsSub: Subscription;
  private inGlobalNewChannelSub: Subscription;
  private unseenMessagesSub: Subscription;
  private openedRoomsCounterSubscription: Subscription;
  private sideNavSubscription: Subscription;
  private routerSubscription: Subscription;
  notificationsCounter: number;
  private clickedPage: any;

  sidenavToggleSubject: Subject<any> = new Subject<any>();

  constructor(
    private utilisService: UtilisService,
    private notificationService: NotificationService,
    private roomService: RoomService,
    private authenticationService: AuthenticationService,
    private headerService: HeaderService,
    private ren: Renderer2,
    private chatService: ChatService,
    private chatGlobalSocket: ChatGlobalSocket,
    private storageService: StorageService,
    private messagingChatService: MessagingChatService,
    private userService: UserService,
    private router: Router,
    private navigationService: NavigationService,
    private errorService: ErrorService,
    private dialog: MatDialog,
    private hospitalService: HospitalService,
  ) {
    this.currentDay = new Date();
    this.toggleSideNav();
    this.routerSubscription = router.events.subscribe((event: RouterEvent) => {     
      if (event instanceof NavigationStart) {
        this.setCurrentPage(event.url);
      }

      if (event instanceof NavigationCancel) {
        this.setCurrentPage();
      }

      if (event instanceof NavigationEnd) {
        this.setCurrentPage();
      }
    });

    // Connection to socket
    this.chatService.connect();
  }

  setCurrentPage(urlArg: string = null) {
    let url = urlArg ? urlArg : this.router.url;
    const current: string[] = url.split("/");
    if (current.length > 1) {
      if (current[1] === "hrsuite") {
        this.currentPage = "hrsuite";
     } else if (current[1].includes("profiles")) {
        this.currentUser.profile.position == 'Cadre de bloc' || ((this.currentUser.profile.position == 'Anesthésiste' || (this.currentUser.profile.position == 'Iade' && this.currentUser.levelOfAccess >1))) ? this.currentPage = 'hrsuite' : this.currentPage = 'profiles'

      } else if (current[1].includes("smart-planning")) {
        this.currentPage = pages.smartPlanning;
      } else if (current[1] !== '') {
        this.currentPage = "/" + url.split("/")[1];
      }
      if (
        current.length > 2 &&
        current[1] === "pending-requests" &&
        current[2] === "home"
      ) {
        this.currentPage = "/home";
      }
    }
  }

  ngOnInit() {
    this.getCurrentUser();
    this.setCurrentPage();
    this.isAnesth = isAnesthetist(this.currentUser.profile);
    this.getUnseenMessagesNum();
    this.getUnseenNotificationsCounter();
    this.getMessageSeenNum();
    this.inGlobalNewChannel();
    this.getSendBoxMessages();
    this.getGlobalNewMessages();

    this.isMultiHospitals = this.userService.isCurrentUserHasMultipleHospitals();
    this.isUserHasLowLevel = this.userService.isUserHasLowLevelAccess();
    this.userIsAuthenticated = this.authenticationService.isAuthenticated();
    this.openedRoomsCounterSubscription = this.headerService.updateOpenedRoomsCounter.subscribe(
      () => {
        this.refreshOpenedRoomsCounter();
      },
      (error) => this.errorService.handleError(error)
    );
    if (this.isMultiHospitals) {
      this.checkSoloHospitalPage();
      this.getChangedHospitalsSelected();
      this.changeHospitalModeSubject();
      this.getUserHospitals();
    }
  }

  checkSoloHospitalPage() {
    const currentUrl = this.router.url
    const soloPages = [
      "configuration/general",
      "configuration/structure",
      "configuration/rh",
      "configuration/besoins",
      "configuration/tvo",
      "configuration/fiche-poste",
      "parcours-ibode",
      "smart-planning",
      "messagerie",
      "besoins",
      "Chirurgien/par-salles",
      "Chirurgien/par-interventions",
      "commission-regulation"
    ]

    if (soloPages.some((v) => currentUrl.includes(v))) {
      this.soloHospitalMode = true;
    }
  }

  changeHospitalModeSubject() {
    if(this.changeHospitalModeSubjectSubscription) this.changeHospitalModeSubjectSubscription.unsubscribe();
    this.changeHospitalModeSubjectSubscription = this.userService.soloHospitalModeSubject
    .subscribe((val) => {
      let selectedHospitals = this.selectedHospitals
      let selectedHospitalId = "";
      if (val == true) {
        if (Array.isArray(this.selectedHospitals)) {
          selectedHospitals = this.selectedHospitals[0]
          selectedHospitalId = this.selectedHospitals[0].id
        }
      } else {
        selectedHospitals.selected = true;
        selectedHospitalId = selectedHospitals.id
        selectedHospitals = [selectedHospitals]
      }
      this.hospitals.forEach(hospital => {
        if (hospital.id === selectedHospitalId) {
          hospital.selected = true;
        } else {
          hospital.selected = false
        }
      });
      this.soloHospitalMode = val;
      this.selectedHospitals = selectedHospitals
    })
  }

  getChangedHospitalsSelected() {
    if(this.getChangeHospitalsSelectedSubscription) this.getChangeHospitalsSelectedSubscription.unsubscribe();
    this.getChangeHospitalsSelectedSubscription = this.userService.changedHospitalsSelectedSubject.subscribe(() => {
      const selectedHospitals = [...this.userService.getSelectedHospitals()];
      const userHospitals = [...this.userService.getCurrentUserHospitals()];
      
      const hospitals = []
      let selectedHospitalsName = ""
      this.selectedHospitals = []
      userHospitals.forEach(hospital => {
        if (selectedHospitals.includes(hospital._id)) {
          hospitals.push({
            name: hospital.name,
            id: hospital._id,
            selected: true,
          })
          if (selectedHospitalsName.length > 0) {
            selectedHospitalsName += ", "
          }
          selectedHospitalsName += hospital.name;
        }
      });
      if (!this.soloHospitalMode) {
        this.selectedHospitals = hospitals
      } else {
        this.selectedHospitals = hospitals[0];
      }
      this.selectedHospitalsName = selectedHospitalsName

      this.refreshOpenedRoomsCounter();
      this.getUnseenNotificationsCounter();
    })
  }

  compareCategoryObjects(object1: any, object2: any) {
    return object1 && object2 && object1.id == object2.id;
  }

  getUserHospitals() {
    const hospitals = [...this.userService.getCurrentUserHospitals()];
    const lastSelectedHospitals = this.userService.getSelectedHospitals()
    hospitals.forEach(hospital => {
      this.hospitals.push({
        name: hospital.name,
        id: hospital._id,
        selected: false
      })
    });
    if (lastSelectedHospitals.length === 0) {
      this.hospitals[0].selected = true;
      if (!this.soloHospitalMode) {
        this.selectedHospitals = [this.hospitals[0]];
      } else {
        this.selectedHospitals = this.hospitals[0];
      }
      this.selectedHospitalsName = this.hospitals[0].name;
    } else {
      const selectedHospitals = []
      let selectedHospitalsName = ""
      this.nbSelectedHospitals = 0;
      this.hospitals.forEach(hospital => {
        if (lastSelectedHospitals.includes(hospital.id)) {
          hospital.selected = true
          selectedHospitals.push(hospital)
          this.nbSelectedHospitals++
          if (selectedHospitalsName.length > 0) {
            selectedHospitalsName += ", "
          }
          selectedHospitalsName += hospital.name;
        }
      });
      if (!this.soloHospitalMode) {
        this.selectedHospitals = selectedHospitals;
        this.selectedHospitals[0].selected = true;
      } else {
        this.selectedHospitals = selectedHospitals[0];
        this.selectedHospitals.selected = true;
      }
      this.selectedHospitalsName = selectedHospitalsName
    }
  }

  /**
   * Open the right click menu on the sidenav's link click
   * @param event right click event that is captured
   * @param page page on which the right click was done
   */
  sideNavRightItemClicked(event: PointerEvent, page:any){
    event.preventDefault();

    // apply the style on the div corresponding to the page that has been clicked
      // add the focus event
    setTimeout(() => {
      if(this.rightClickBtnContainer) {
        this.rightClickBtnContainer.nativeElement.focus();
      }
    });

    // close the hover menu
    if(this.prevButtonTrigger)
      this.prevButtonTrigger.closeMenu();
    if(this.isMatMenuOpen) {
        this.isMatMenuOpen = false;
        this.isMatMenu2Open = false;
      }

    // position the popup
    this.rightClickedPage = page;
    this.rightClickMenu.nativeElement.style.left = event.clientX + 10 + 'px';
    this.rightClickMenu.nativeElement.style.top = event.clientY * 1.2 + 'px';
  } 

  /**
   * Close the right clicked menu
   */
  closeRightClickedMenu() {
    if(document.activeElement !== this.rightClickBtnContainer.nativeElement) {
      this.rightClickedPage = null;
    }
  }

  /**
   * Open the link in a new tab
   */
  openNewTab() { 
    window.open(this.rightClickedPage.link);
  }

  /**
   * Open the link in a new window
   */
  openNewWindow() {
    // window's dimensions
    const width = window.innerWidth;
    const height = window.innerHeight;
    let pageLink = this.rightClickedPage.link;
    
    // Open window
    window.open(pageLink, '_blank', `width=${width},height=${height}`);
  }

  onChangeHospital(event) {
    let hospitalIds = []
    this.selectedHospitals = event;

    if (!this.soloHospitalMode) {
      let count = 0;
      event.forEach(element => {
        hospitalIds.push(element.id)
        count++;
      });
      this.nbSelectedHospitals = count;
      let selectedHospitalsName = ""
      this.hospitals.forEach(hospital => {
        hospital.selected = false;
      });
      event.forEach(element => {
        const hospital = this.hospitals.find((hospital) => hospital.id == element.id)
        hospital.selected = true;
        if (hospital) {
          if (selectedHospitalsName.length > 0) {
            selectedHospitalsName += ", "
          }
          selectedHospitalsName += hospital.name;
        }
      });
      this.selectedHospitalsName = selectedHospitalsName
      this.storageService.saveData("selectedHospitalsId", hospitalIds);
    } else {
      this.nbSelectedHospitals = 1;
      this.selectedHospitalsName = event.name
      this.storageService.saveData("selectedHospitalsId", [event.id]);
    }
    
    this.refreshOpenedRoomsCounter();
    this.getUnseenNotificationsCounter();
    this.navigationService.reloadCurrentRoute();
  }

  openAnnuairePopup() {
    const dialogRef = this.dialog.open(AnnuairePopupComponent, {
      width: '870px',
      height: '1050px',
      data: {
      },
    });
    return dialogRef.afterClosed();
  }

  refreshOpenedRoomsCounter(): void {
    if (this.openedRoomsSub) {
      this.openedRoomsSub.unsubscribe();
    }
    this.openedRoomsSub = this.roomService.getHospitalRoomsDaysSurgeonOpenings(moment().format("YYYY-MM-DD").toString())
      .subscribe((data) => {
        this.openedRooms = this.countOpenedRooms(data);
      }, (error) => {
        console.error(error);
      });
  }

  countOpenedRooms(data: roomWithSOAndSOH[]): number {
    let cpt = 0;
    data.forEach((room: any) => {
      if (room.surgeonopening.length > 0) {
        const openingSurgeonOpenings = room.surgeonopening.find((SO) => SO.opening);
        if (openingSurgeonOpenings) {
          cpt += 1;
        }
      } else {
        const openingSurgeonOpeningsHistories = room.surgeonopeninghistory.find((SOH) => SOH.opening);
        if (openingSurgeonOpeningsHistories) {
          cpt += 1;
        }
      }
    })
    return cpt;
  }

  navigateToHome() {
    this.navigationService.navigateTo('/home', null, null, false);
  }


  getUnseenNotificationsCounter() {
    if(this.getUnseenNotificationsSubscription) this.getUnseenNotificationsSubscription.unsubscribe();
    this.getUnseenNotificationsSubscription = this.notificationService
      .getNotificationsCounter(this.currentUser.profile._id)
      .subscribe((res) => {
        this.notificationsCounter = res;
      });
  }

  getPosition() {
    if (this.hospitalService.doesHospitalHaveAnesthDetailsOption() && this.isAnesth && this.currentUser.profile.seniority) {
      if (this.currentUser.profile.seniority == 'Senior')
        return 'Sénior';
      return this.currentUser.profile.seniority
    }
    return this.currentUser.profile.position;
  }

  inGlobalNewChannel(){
    if(this.inGlobalNewChannelSub) this.inGlobalNewChannelSub.unsubscribe();
    this.inGlobalNewChannelSub = this.chatService.inGlobalNewChannelSubject.subscribe(res => {
      if(!_.isEmpty(res)){
          this.unseenMessages++
        }
      },
      (error) => {
        this.errorService.handleError(error);
      }
    );
  }

  getUnseenMessagesNum() {
    if(this.unseenMessagesSub) this.unseenMessagesSub.unsubscribe();
    this.unseenMessagesSub = this.chatService.unseenMessagesSubject.subscribe(
      (res) => {
        this.unseenMessages = res;
      },
      (error) => {
        this.errorService.handleError(error);
      }
    );
  }

  getMessageSeenNum() {
    if(this.getMessageSeenNumSubscription) this.getMessageSeenNumSubscription.unsubscribe();
    this.getMessageSeenNumSubscription = this.chatGlobalSocket.messageSeenNumSubject
    .subscribe(
      (res) => {
        if (res) {
          this.unseenMessages = this.unseenMessages - res;
        }
      },
      (error) => {
        this.errorService.handleError(error);
      }
    );
  }

  getSendBoxMessages() {
    if(this.getSendBoxMessagesSubscription) this.getSendBoxMessagesSubscription.unsubscribe();
    this.getSendBoxMessagesSubscription = this.chatGlobalSocket.sendBoxMessagesSubject.subscribe(
      (res) => {
        if (res) {
          this.unseenMessages++;
        }
      },
      (error) => {
        this.errorService.handleError(error);
      }
    );
  }

  getGlobalNewMessages() {
    if(this.getGlobalNewMessagesSubscription) this.getGlobalNewMessagesSubscription.unsubscribe();
    this.getGlobalNewMessagesSubscription = this.chatService.globalNewMessagesSubject.subscribe(
      (res) => {
        if (res) {
          this.unseenMessages = this.unseenMessages + res;
        }
      },
      (error) => {
        this.errorService.handleError(error);
      }
    );
  }

  get position() {
    if (this.currentUser && this.currentUser.profile) {
      if (isAnesthetist(this.currentUser.profile)) {
        return ANESTHETIST;
      } else {
        return NURSE;
      }
    }
    return NURSE;
  }

  getCurrentUser(): void {
    this.currentUser = this.storageService.getUser();
    this.currentUserImage = this.currentUser && this.currentUser.profile && this.currentUser.profile.profilePic ? this.currentUser.profile.profilePic : onAvatarError(this.currentUser.profile);
    this.currentUserPosition = this.getCurrentUserPoistion();
    this.currentUserName = this.getCurrentUserName();
    if (this.currentUserPosition === 'Iade' && this.currentUser.levelOfAccess >= 4) {
      this.pages.forEach((page) => {
        if (Array.isArray(page.subMenus)) {
          const calendrierSubMenu = page.subMenus.find((submenu) => {return submenu.title === 'Chirurgien' });    
          if (calendrierSubMenu) {
            calendrierSubMenu.items.forEach((menu) => { 
              if (menu.pageTitle === 'Par vacations'){
                if (menu.link !== '/hrsuite/Chirurgien/par-salles' ){
                  menu.pageTitle = "Par besoins"
                }
              }
            })
          } 
        } 
      });
    }
  }
    


  getCurrentUserName(){
    let name = this.currentUser && this.currentUser.profile ?  `${capitalize(this.currentUser.profile.firstName)} ${this.currentUser.profile.lastName.charAt(0)}.` : "";

    if(this.sideNavReduced){
      name = this.currentUser && this.currentUser.profile ?  `${this.currentUser.profile.firstName.charAt(0)}. ${this.currentUser.profile.lastName.charAt(0)}.` : "";
    }
    return name;
  }
  getCurrentUserPoistion(){
    let position = this.currentUser && this.currentUser.profile ? this.currentUser.profile.position : "";

    if(this.sideNavReduced){
      switch (position) {
        case 'Cadre de bloc':
          position = "CDB"
          break;
        case 'Anesthésiste':
          position = "Anesth"
          break;
        case 'Infirmiere':
          position = "Inf"
          break;
      }
          }
    return position;
  }

  getAuthorizedPage(items: any[]) {
    const authorizedPage = items.filter((item) =>
      this.isAllowToAccessToItem(item)
    );
    return authorizedPage[0];
  }

  onAvatarErrorComponent(): void {
    let picture: string;
    if (NURSE_TYPES.includes(this.currentUser.profile.position)) {
      picture = 'assets/images/icons/nurse.svg';
    } else {
      switch (this.currentUser.profile.position) {
        case 'Chirurgien':
          picture = 'assets/images/icons/surgeon.svg';
          break;
        case 'Anesthésiste':
          picture = 'assets/images/icons/f-doctor.svg';
          break;
        case 'Iade':
          picture = 'assets/images/icons/f-doctor.svg';
          break;
        case 'IADE':
          picture = 'assets/images/icons/f-doctor.svg';
          break;
        case 'Cadre de bloc':
          picture = 'assets/images/icons/surgeon.svg';
          break;
        default:
          picture = 'assets/images/icons/surgeon.svg';
      }
    }
    this.currentUserImage = picture;
  }

  onSideNavItemClick(event, page) {
    this.currentPage = page.link;
  }

  isCurrent(page): boolean {
    return this.currentPage === page.link;
  }

  goToMyAccount() {
    if(this.goToMyAccountSubscription) this.goToMyAccountSubscription.unsubscribe();
    this.goToMyAccountSubscription = this.goToNextEvent().subscribe(res => {
      if (res === false) {
        this.saveAgendas.emit(true);
      }else if (res === true){
        this.navigationService.navigateTo(pages.profile, null, null, false);
      }
    })
  }

  buttonEnter(trigger, event) {
    // /*
    setTimeout(() => {
      if (this.prevButtonTrigger && this.prevButtonTrigger != trigger) {
        this.prevButtonTrigger.closeMenu();
        this.prevButtonTrigger = trigger;
        this.isMatMenuOpen = false;
        this.isMatMenu2Open = false;
        trigger.openMenu();
      } else if (!this.isMatMenuOpen) {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger;
        trigger.openMenu();
      } else {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger;
      }
    });
    // */
  }

  buttonLeave(trigger, button) {
    if (!trigger) {
      return;
    }
    // /*
    setTimeout(() => {
      if (this.enteredButton && !this.isMatMenuOpen) {
        trigger.closeMenu();
        this.ren.removeClass(button, "cdk-focused");
        this.ren.removeClass(button, "cdk-program-focused");
      }
      if (!this.isMatMenuOpen) {
        trigger.closeMenu();
        this.ren.removeClass(button, "cdk-focused");
        this.ren.removeClass(button, "cdk-program-focused");
      } else {
        this.enteredButton = false;
      }
    }, 250);
    // */
  }

  menuenter() {
    this.isMatMenuOpen = true;
    if (this.isMatMenu2Open) {
      this.isMatMenu2Open = false;
    }
  }

  menuLeave(trigger, button) {
    if (!trigger) {
      return;
    }
    setTimeout(() => {
      if (!this.isMatMenu2Open && !this.enteredButton) {
        this.isMatMenuOpen = false;
        trigger.closeMenu();
        this.ren.removeClass(button, "cdk-focused");
        this.ren.removeClass(button, "cdk-program-focused");
      } else {
        this.isMatMenuOpen = false;
      }
    }, 20);
  }

  isAllowToAccessToItem(item: any) {
    const whoCanAccess = item.whoCanSee;
    const neededFeatures = item.neededFeatures ? item.neededFeatures : [];
    const neededOptions = item.neededOptions ? item.neededOptions : [];

    for (const neededFeature of neededFeatures) {
      if (!this.hospitalService.checkIfHospitalHaveFeature(neededFeature)) {
        return false;
      }
    }

    for (const neededOption of neededOptions) {
      if (!this.hospitalService.checkIfHospitalHaveOption(neededOption)) {
        return false;
      }
    }

    if (whoCanAccess === "ALL") return true;
    else {
      const index = whoCanAccess.findIndex(
        (_whoCanSee) =>
          _whoCanSee.position.includes(this.currentUser.profile.position) &&
          _whoCanSee.levelOfAccess == this.currentUser.levelOfAccess &&
          _whoCanSee.residency == this.currentUser.profile.residency && 
          (
            _whoCanSee.denyAccessForInterneResponsible ? 
            !this.currentUser.isInternResponsible :
            true
          )
      );
      return index !== -1;
    }
  }

  displaySubMenus(page: any) {
    if (!page.subMenus) {
      return false;
    } else {
      let result = false;
      page.subMenus.forEach((subMenu) => {
        result = result || this.displaySubMenu(subMenu);
      });
      return result;
    }
  }

  displaySubMenu(subMenu) {
    let result = false;
    subMenu.items.forEach((item) => {
      result = result || this.isAllowToAccessToItem(item);
    });
    return result;
  }

  isSubMenusActif(subMenus) {
    if (!subMenus) {
      return false;
    } else {
      let isPageActif = false;
      subMenus.forEach((subMenu) => {
        isPageActif = isPageActif || this.isSubMenuActif(subMenu);
      });
      return isPageActif;
    }
  }

  isSubMenuActif(subMenu: any): boolean {
    let subMenuActif = false;
    subMenu.items.forEach((item) => {
      subMenuActif = subMenuActif || this.isLinkActif(item.link);
    });
    return subMenuActif;
  }

  isLinkActif(link: string) {
    if (this.currentPage) {
      return this.currentPage.includes(link);
    } else {
      return false;
    }
  }

  onLogout() {
    this.storageService.saveData("isDisconnecting", true);
    this.router
      .navigate([pages.login])
      .then(() => {
        this.storageService.saveData("isDisconnecting", false);
        this.authenticationService.logout();
      })
      .catch((error) => this.errorService.handleError(error));
  }

  checkSaveBeforeLogout() {
    if(this.checkSaveBeforeLogoutSubscription) this.checkSaveBeforeLogoutSubscription.unsubscribe();
    this.checkSaveBeforeLogoutSubscription = this.goToNextEvent().subscribe(res => {
      if (res === false) {
        this.saveAgendas.emit(true);
      } else if (res === true){
        this.onLogout()
      }
    })
  }

  goToConfiguration() {
    if(this.goToConfigurationSubscription) this.goToConfigurationSubscription.unsubscribe();
    this.goToConfigurationSubscription = this.goToNextEvent().subscribe(res => {
      if (res === false) {
        this.saveAgendas.emit(true);
      }else if (res === true){
        if (this.currentUser.levelOfAccess >= 4) {
          this.navigationService.navigateTo(pages.configuration, null, null, false);
        }
      }
    })
  }

  goToSurgeon() {
    if(this.goToSurgeonSubscription) this.goToSurgeonSubscription.unsubscribe();
    this.goToSurgeonSubscription = this.goToNextEvent().subscribe(res => {
      if (res === false) {
        this.saveAgendas.emit(true);
      }else if (res === true){
        if (!this.isUserHasLowLevel)
          this.navigationService.navigateTo(
            pages.parSalles,
            null,
            { date: this.currentDay },
            false
          );
      }
    })
  }

  isCloseButtonArea(x, y) {
    return x < 100 && y < 60;
  }

  _sidenavToggle() {
    this.sideNavReduced = !this.sideNavReduced;
    this.currentUserName = this.getCurrentUserName();
    this.currentUserPosition = this.getCurrentUserPoistion();
    this.sidenavToggle.emit();
    this.utilisService.toggleSidenav();
  }

  shouldHideSpan(pageTitle) {
    if (this.isNotificationLink(pageTitle)) {
      return this.notificationsCounter == 0;
    }
    if(pageTitle == "Messagerie") {
      return this.unseenMessages == 0
    }
    return true
  }

  getCounter(pageTitle) {
    if (pageTitle === "Messagerie") {
      return this.unseenMessages;
    } else if (this.isNotificationLink(pageTitle)) {
      return this.notificationsCounter;
    }
  }

  isNotificationLink(pageTitle) {
    return pageTitle === "Notifications";
  }

  goToNextEvent() {
    if (this.isThereNewModifs) return this.utilisService.openUnSavedChangesPopup()
    else return new Observable(sub => sub.next(true));
  }

  checkSaveBeforeNavigate(event:any , page:any , isNotificationLink:boolean = true){
    if(this.checkSaveBeforeNavigateSubscription) this.checkSaveBeforeNavigateSubscription.unsubscribe();
    this.checkSaveBeforeNavigateSubscription = this.goToNextEvent().subscribe(res => {
      if (res === false) {
        this.saveAgendas.emit(true);
      }else if (res === true){
        if(this.isNotificationLink(page.pageTitle) && isNotificationLink) {
          this.utilisService
            .openPopup(NotificationsFeedComponent, {
              panelClass: "custom-dialog-container-without-padding",
            })
            .afterClosed()
            .subscribe((res) => this.getUnseenNotificationsCounter());
        } else {
          event.stopPropagation();
          this.navigationService.navigateTo(page.link, null, null, false);
        }
      }
    })
  }

  itemClickedHandler(event, page) {
    this.checkSaveBeforeNavigate(event, page)
  }

  itemSubMenuClickedHandler(event, page) {
    this.checkSaveBeforeNavigate(event, page,false)
  }

  navigateToMessages(event){
    const page = {
                  pageTitle: 'Messagerie',
                  link: pages.messaging,
              }
    this.checkSaveBeforeNavigate(event, page)
  }

  navigateToNotifications(event){
    const page = {
                  pageTitle: 'Notifications',
                  link: '#',
              }
    this.checkSaveBeforeNavigate(event, page)
  }


  navigateToTutoriel(event){
    const page = {
                  pageTitle: 'Tutoriel',
                  link: pages.about,
              }
    this.checkSaveBeforeNavigate(event, page)
  }

  getNameAbb(profile :Profile){
    const firstName = profile.lastName;
    const lastName = profile.firstName;
    return lastName && firstName ?lastName.substring(0,1)+firstName.substring(0,1)   : "";
  }

  isFilter(page , type){
   return page && (type === "menu" && this.iconTheme === "default" && (page.pageTitle === "SmartPlanning" || page.pageTitle === "StatOp" || page.pageTitle === "Messagerie" ))
  }

  isImage(page : any , type : string){
    return this.iconTheme !== "default"
  }

  getIcon(page){
    return page.icon[this.iconTheme]
  }

  toggleSideNav() {
    if (this.sideNavSubscription) {
      this.sideNavSubscription.unsubscribe();
    }

    this.sideNavSubscription = this.userService.sideNav.subscribe(() => {
      this._sidenavToggle()
    });
  }

  ngOnDestroy() {
    if (this.openedRoomsSub) {
      this.openedRoomsSub.unsubscribe();
    }

    this.chatService.disconnectSocket();

    if (this.roomsSubscription) {
      this.roomsSubscription.unsubscribe();
    }

    if (this.inGlobalNewChannelSub) {
      this.inGlobalNewChannelSub.unsubscribe();
    }

    if (this.unseenMessagesSub) {
      this.unseenMessagesSub.unsubscribe();
    }

    if (this.openedRoomsCounterSubscription) {
      this.openedRoomsCounterSubscription.unsubscribe();
    }

    if (this.sideNavSubscription) {
      this.sideNavSubscription.unsubscribe();
    }

    if (this.changeHospitalModeSubjectSubscription) {
      this.changeHospitalModeSubjectSubscription.unsubscribe();
    }

    if (this.getUnseenNotificationsSubscription) {
      this.getUnseenNotificationsSubscription.unsubscribe();
    }

    if (this.getMessageSeenNumSubscription) {
      this.getMessageSeenNumSubscription.unsubscribe();
    }

    if (this.getSendBoxMessagesSubscription) {
      this.getSendBoxMessagesSubscription.unsubscribe();
    }

    if (this.getGlobalNewMessagesSubscription) {
      this.getGlobalNewMessagesSubscription.unsubscribe();
    }

    if (this.goToMyAccountSubscription) {
      this.goToMyAccountSubscription.unsubscribe();
    }

    if (this.goToSurgeonSubscription) {
      this.goToSurgeonSubscription.unsubscribe();
    }

    if (this.checkSaveBeforeNavigateSubscription) {
      this.checkSaveBeforeNavigateSubscription.unsubscribe();
    }

    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }

    if (this.getChangeHospitalsSelectedSubscription) {
      this.getChangeHospitalsSelectedSubscription.unsubscribe();
    }

    if (this.goToConfigurationSubscription) {
      this.goToConfigurationSubscription.unsubscribe();
    }

    if (this.sideNavReduced) this.sidenavToggle.emit();
  }
}
