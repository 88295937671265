import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatChipInputEvent } from '@angular/material';
import { EventService } from '../event/service/event.service';
import { _Event } from '../../models/event.model';
import { ErrorService } from '../../services/error.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-day-event',
  templateUrl: './day-event.component.html',
  styleUrls: ['./day-event.component.scss']
})
export class DayEventComponent implements OnInit, AfterContentChecked {
  @Input() position: string;
  @Input() day: string;
  @Input() _event: _Event
  message: string = "Ajouter..."
  hideTags: boolean;
  visible: boolean = true;
  selectable: boolean = true;
  removable: boolean = true;
  addOnBlur: boolean = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  _eventsName: string[]
  addDayEventsSubscription: Subscription;
  updateEventsSubscription: Subscription;
  @Output() onUpdateEvents : EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public errorService: ErrorService,
    public dialog: MatDialog,
    private eventService: EventService,
    private cdr: ChangeDetectorRef
  ) {
    this._eventsName = []
  }

  ngOnInit() {
    this.cdr.detach();
    this.day = this.day.substr(0, 10)
    if (this._event) {
      this._eventsName = this._event.events
    }
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our event
    if ((value || '').trim() && value != "Ajouter...") {
      this._eventsName.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }
    this.save()
  }

  remove(_event: any): void {
    const index = this._eventsName.indexOf(_event);

    if (index >= 0) {
      this._eventsName.splice(index, 1);
    }

    this.save()

  }

  addMessage() {
    this.message = "Ajouter..."
  }

  removeMessage() {
    this.message = ""
  }

  save() {
    const body: _Event = {
      date: this.day,
      position: this.position,
      events: this._eventsName
    }
    if (!this._event) {
      this.addEvents(body)
    } else {
      this.updateEvents(body, this._event._id)
    }
  }

  addEvents(body) {
    this.addDayEventsSubscription = this.eventService.addDayEvents(body).subscribe((res: any) => {
      this._event = res
      this.onUpdateEvents.emit(true)
    }, error => this.errorService.handleError(error))
  }

  updateEvents(body: _Event, eventId: number) {
    this.updateEventsSubscription = this.eventService.updateDayEvents(body, eventId).subscribe((res: any) => {
      this.onUpdateEvents.emit(true)
    }, error => this.errorService.handleError(error))
  }

  ngOnChanges(changes: SimpleChanges) {
    this._eventsName = []
    this.day = this.day.substr(0, 10)
    if (this._event) {
      this._eventsName = this._event.events
    }
  }

  ngOnDestroy(): void {
    if (this.addDayEventsSubscription) {
      this.addDayEventsSubscription.unsubscribe()
    }
    if(this.updateEventsSubscription){
      this.updateEventsSubscription.unsubscribe()
    }
  }
}
