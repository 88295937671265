import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {urlEndPoints} from '../config/end-points';
import {Observable} from 'rxjs';
import { MessagingDal } from '../dal/messaging.dal';


@Injectable()
export class MessagingService {

  constructor(private httpClient: HttpClient , private messagingDal : MessagingDal) {
  }

 getProfilesByPosition(position: string, residency? : string, pagination? : boolean , withoutPic?: boolean): Observable<any> {
    return this.messagingDal.getProfilesByPosition(position , residency, pagination, withoutPic);
  }

  getChannels(page: number, limit : number  ,search ? :string) {
    return this.messagingDal.getChannels(page , limit , search);
  }

  getChannel(channelId : string){
    return this.messagingDal.getChannel(channelId);
  }

  getChannelByTwoProfileId(profileId: string) {
    return this.messagingDal.getChannelByTwoProfileId(profileId);
  }

  getChannelMessages(channelID : string ,page: number, limit : number){
    return this.messagingDal.getChannelMessages(channelID , page , limit);
  }

  getChannelMembers(channelID : string){
    return this.messagingDal.getChannelMembers(channelID);
  }

  getMessageSeenBy(messageID : string){
    return this.messagingDal.getMessageSeenBy(messageID);
  }

  getSeenByOpeningChannel(channelID : string){
    return this.messagingDal.getSeenByOpeningChannel(channelID);
  }

  checkExistingOneToOneChannel(profileID : string){
    return this.messagingDal.checkExistingOneToOneChannel(profileID);
  }

}
