import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { Role } from '../../models/role.model';
import { ParamedicalService } from '../../services/paramedical.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-needs-popup',
  templateUrl: './needs-popup.component.html',
  styleUrls: ['./needs-popup.component.scss']
})
export class NeedsPopupComponent implements OnInit, OnDestroy {

  public title: string = "";
  public roles: Role[];
  public needs: any[];
  public soMode: boolean = false;

  private getAllRolesSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<NeedsPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public paramedicalService: ParamedicalService
  ) {
    this.title = this.data.title;
    this.needs = this.data.needs;
    this.soMode = this.data.soMode;
  }

  async ngOnInit() {
    if (!this.soMode) {
      this.getAllRolesOfHospital();
    }
  }

  getAllRolesOfHospital() {
    if (this.getAllRolesSubscription) {
      this.getAllRolesSubscription.unsubscribe();
    }

    this.getAllRolesSubscription = this.paramedicalService.getRoles()
    .subscribe((roles) => {
      this.roles = roles;
      this.roles.forEach((role:any) => {
        role.need = 0;
      });
    })
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    if (this.getAllRolesSubscription) {
      this.getAllRolesSubscription.unsubscribe();
    }
  }
}
