import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatChipInputEvent } from '@angular/material';
import { EventService } from '../event/service/event.service';
import { _Event } from '../../models/event.model';
import { ErrorService } from '../../services/error.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-day-event',
  templateUrl: './day-event.component.html',
  styleUrls: ['./day-event.component.scss']
})
export class DayEventComponent implements OnInit, AfterContentChecked {
  @Input() position: string;
  @Input() selectedHospitals: string[]; // TO UPDATE
  @Input() day: string;
  @Input() _event: _Event[]

  message: string = "Ajouter..."
  hideTags: boolean;
  visible: boolean = true;
  selectable: boolean = true;
  removable: boolean = true;
  addOnBlur: boolean = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  _eventName: string;
  _eventsName: string[] = [];
  addDayEventsSubscription: Subscription;
  updateEventsSubscription: Subscription;
  @Output() onUpdateEvents : EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public errorService: ErrorService,
    public dialog: MatDialog,
    private eventService: EventService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.cdr.detach();
    this.day = this.day.substr(0, 10);
    this._eventsName = [];
    if (this._event) { 
      this._event.forEach((event) => this._eventsName.push(event.event));
    }
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  /**
   * Add an event to the calendar
   * @param event the input text event
   */
  addEvent(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our event
    if ((value || '').trim() && value != "Ajouter...") {
      this._eventsName.push(value.trim());
      this._eventName = value.trim();
    }
    else {
      return;
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }

    // create the body of the request to send
    const body: _Event = {
      date: this.day,
      position: this.position,
      event: this._eventName,
      hospitals: this.selectedHospitals
    }

    // call upon the service
    if(this.addDayEventsSubscription) this.addDayEventsSubscription.unsubscribe();
    this.addDayEventsSubscription = this.eventService.addDayEvents(body).subscribe((res: any) => {
      this._event = res
      this.onUpdateEvents.emit(true)
    }, error => this.errorService.handleError(error))
  }

  /**
   * Remove an event from the calendar
   * @param _event the name of the event to remove
   */
  removeEvent(_event: any): void {
    const index = this._eventsName.indexOf(_event);
   
    if (index >= 0) {
      this._eventsName.splice(index, 1);

      // get the event
      let eventToDeleteId = this._event[index]._id;

      // make the body
      const body: _Event = {
        _id: eventToDeleteId,
        date: this.day,
        position: this.position,
        event: this._eventName,
        hospitals: this.selectedHospitals
      };

      // call the service to delete the event
      if(this.updateEventsSubscription) this.updateEventsSubscription.unsubscribe();
      this.updateEventsSubscription = this.eventService.updateDayEvents(body, eventToDeleteId).subscribe((res: any) => {
        this.onUpdateEvents.emit(true)
      }, error => this.errorService.handleError(error))
    }

  }


  /**
   * Add the message 'Ajouter...' to notify the user to add an event
   */
  addMessage() {
    this.message = "Ajouter..."
  }

  /**
   * Remove the message 'Ajouter...' when the user starts typing an event
   */
  removeMessage() {
    this.message = ""
  }

 
  /**
   * Check on any event changes
   * @param changes the changes made in the html component
   */
  ngOnChanges(changes: SimpleChanges) {
    this._eventsName = [];
    this.day = this.day.substr(0, 10);
    if (this._event) {
      this._event.forEach((event) => this._eventsName.push(event.event));
    }
  }

  ngOnDestroy(): void {
    if (this.addDayEventsSubscription) {
      this.addDayEventsSubscription.unsubscribe()
    }
    if(this.updateEventsSubscription){
      this.updateEventsSubscription.unsubscribe()
    }
  }
}
