import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urlEndPoints } from '../config/end-points';
import { Observable } from 'rxjs';
import { ReasonMap } from '../models/reasonMap.model';

@Injectable()

export class ReasonMapDal {

  constructor(private httpClient: HttpClient) {}

  getSuggestedReasonsMaps(includeNotSuggested = false) : Observable<ReasonMap[]> {
    return this.httpClient.get<any>(urlEndPoints.reasonsMaps + 'suggested' + (includeNotSuggested ? '/?includeNotSuggested=true' : ''));
  }

}