import { Component, OnInit, Input } from '@angular/core';
import { RemoveAccents } from '../../utils/cross-functions';
import { NavigationService } from '../../../core/services/navigation.service';
import { pages } from '../../config/pages';
import { Profile } from '../../models/profile.model';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-most-frequent',
  templateUrl: './most-frequent.component.html',
  styleUrls: ['./most-frequent.component.scss']
})
export class MostFrequentComponent implements OnInit {

  @Input() topSpecialty: any;
  @Input() topSurgeon: any;
  @Input() mostAct?: any;
  imgLink: string = '';
  isUserHasLowLevel: any;

  constructor(
    private userService: UserService,
    private navigationService: NavigationService) {
  }

  ngOnInit() {
    this.isUserHasLowLevel = this.userService.isUserHasLowLevelAccess()
    if (this.topSpecialty.length > 0) {
      this.imgLink = this.topSpecialty[0].specialty.image;
    }
  }

  getSpecialtyName() {
    if (this.topSpecialty[0]) {
      if (this.topSpecialty[0].specialty) {
        return this.topSpecialty[0].specialty.name.toLowerCase().split(' ').join('-');
      }

      return 'urgences';
    }
    return 'urgences';

  }

  goToSurgeonPage(surgeon: Profile) {
    if (!this.isUserHasLowLevel)
      this.navigationService.navigateTo(pages.statopBlocParPraticien + '/' + surgeon._id, null, null, false);
  }
}
