import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { ANESTHETIST, Iade, NURSE } from '../../const/glabals.const';
import { Profile } from '../../models/profile.model';
import { Reason } from '../../models/reason.model';
import { CalendarRequestService } from '../../services/calendar-request.service';
import { ErrorService } from '../../services/error.service';
import { ProfileService } from '../../services/profile.service';
import { ReasonService } from '../../services/reason.service';
import { ToastService } from '../../services/toast.service';
import * as lodash from "lodash";

import { toPascalCase } from '../../utils/cross-functions';
import { HospitalService } from '../../services/hospital.service';
import { UserService } from '../../services/user.service';
import { User } from '../../models/user.model';
import { StorageService } from 'src/app/core/services/storage.service';
import { Formation } from '../../models/formation.model';
import { ParamedicalService } from '../../services/paramedical.service';

@Component({
  selector: 'app-grouped-rh-validation-dialog',
  templateUrl: './grouped-rh-validation-dialog.component.html',
  styleUrls: ['./grouped-rh-validation-dialog.component.scss']
})
export class GroupedRhValidationDialogComponent implements OnInit {
  public toPascalCase = toPascalCase;
  private formations: Formation[];
  public isLoading: boolean = true;
  public isSending: boolean = false;

  public formGroup: FormGroup;

  public allReasons: Reason[] = [];
  
  public filtredReasons = [];

  public isLoadingProfiles: boolean = false;
  public allProfiles: Profile[] = [];
  public filtredProfiles: Profile[] = [];

  public profilesPositions: { label: string, value: string }[] = [];

  public profilesResidencies: { label: string, value: string }[] = [
    {
      label: 'Titulaire',
      value: 'Titulaire'
    },
    {
      label: 'Vacataire',
      value: 'Vacataire'
    }
  ];

  public requestsTypes = [
    {
      label: 'Présence',
      value: 'AVAILABLE'
    },
    {
      label: 'Absence',
      value: 'ABSENT'
    }
  ];

  public seniority = [
    {label: 'Sénior', value: 'Senior', isChecked: false, isDisabled: false},
    {label: 'Junior', value: 'Junior', isChecked: false, isDisabled: false},
    {label: 'Interne', value: 'Interne', isChecked: false, isDisabled: false},
  ];

  public actions = [
    { 
      label: 'Accepter',
      value: 'APPROVED'
    },
    {
      label: 'Refuser',
      value: 'REJECTED',
      color: 'danger'
    }
  ];

  public reasonsSubscription: Subscription;
  public showseniority : boolean;
  public seniorityValid : boolean
  public isInternResponsible: boolean;

  public isMultiHospitals: boolean = false;
  public hospitals: any[] = []
  public currentUser: User;

  constructor(
    public dialogRef: MatDialogRef<GroupedRhValidationDialogComponent>,
    public formBuilder: FormBuilder,
    public reasonService: ReasonService,
    private errorService: ErrorService,
    private hospitalService : HospitalService,
    public calendarRequestService: CalendarRequestService,
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    public toastService: ToastService,
    public profileService: ProfileService,
    public userService: UserService,
    private storageService: StorageService,
    private paramedicalService: ParamedicalService
  ) {
    this.isInternResponsible = this.userService.isInternResponsible();
    if (this.isInternResponsible) {
      this.seniority = [
        { label: 'Interne', value: 'Interne', isChecked: false, isDisabled: false },
      ];
    }
   }

  async ngOnInit() {
    this.isMultiHospitals = this.userService.isCurrentUserHasMultipleHospitalsSelected()
    this.currentUser = this.storageService.getUser()
    const selectedHospitalsId = this.userService.getSelectedHospitals()
    selectedHospitalsId.forEach(hospitalId => {
      const hospital = this.currentUser.profile.hospitals.find((hospital) => String(hospital._id) == String(hospitalId))
      this.hospitals.push({
        name: hospital.name,
        _id: hospital._id
      })
    });
    
    this.initProfiles(this.hospitals[0]._id);
    this.initPositions();
    this.initReasons(this.hospitals[0]._id);
    this.makeForm();
    this.initListeners();
    this.showSeniority(this.formGroup.value.postes)
    this.actualiseSeniority(this.formGroup.value.seniority)
  }

  showSeniority(val) {
    if (!val)
      return
    this.showseniority = val.includes(ANESTHETIST) && this.hospitalService.doesHospitalHaveAnesthDetailsOption()
    if (!this.formGroup.value.seniority)
      this.formGroup.value.seniority = []
    this.seniorityValid = !(this.showseniority && this.formGroup.value.seniority.length == 0)
  }

  actualiseSeniority(val) {
    if (!val)
      val = []
    this.seniorityValid = !(this.showseniority && val.length == 0)
  }

  initListeners(): void {
    this.formGroup.get('isTargeted').valueChanges.subscribe((val) => {
      this.filterProfiles();
    });

    this.formGroup.get('profilesPositions').valueChanges.subscribe((val) => {
      this.onProfilesPositionChange(null);
    });

    this.formGroup.get('profilesResidencies').valueChanges.subscribe((val) => {
      this.onProfilesResidenciesChange(null);
    });

    this.formGroup.get('requestType').valueChanges.subscribe((val) => {
      this.onRequestsTypeChange(null);
    });

    this.formGroup.get('profilesPositions').valueChanges.subscribe((val) => {
      this.showSeniority(val)
    })
    
    this.formGroup.get('seniority').valueChanges.subscribe((val) => {
      this.actualiseSeniority(val);
      this.onProfilesSenioritiesChange(val);
    })

    if (this.isMultiHospitals) {
      this.formGroup.get('hospitals').valueChanges.subscribe(async (val) => {
        this.allProfiles = [];
        this.allReasons = [];
        await this.initProfiles(val._id);
        await this.initReasons(val._id);
        this.showSeniority(this.formGroup.get("profilesPositions").value)
        this.actualiseSeniority(this.formGroup.get("seniority").value)

        this.filterProfiles();
        this.onProfilesPositionChange(null);
      });
    }
  }

  initProfiles(hospitalId?: string) {
    return new Promise<void>((resolve, reject) => {
      this.isLoadingProfiles = true;
      this.profileService.getAllProfilesIncludeDeleted(hospitalId).subscribe((profiles) => {
        // Sort profiles by name
        const sortedProfiles = profiles.sort((a, b) => `${a.firstName.toLowerCase()} ${a.lastName.toLowerCase()}`.localeCompare(`${b.firstName.toLowerCase()} ${b.lastName.toLowerCase()}`));
  
        // We may have duplicats of profiles and already deleted profiles
        for (const profile of sortedProfiles) {
          if (!this.allProfiles.find((p) => String(p._id) === String(profile._id))) {
            this.allProfiles.push(profile);
          }
        }
        this.isLoadingProfiles = false;
        return resolve();
      }, (error) => {
        this.errorService.handleError(error);
        this.isLoadingProfiles = false;
        return reject();
      });
    })
  }

  filterProfiles(): void {
    if (this.isTargeted) {
      if (this.formGroup.controls.profilesPositions.value.length === 0 || this.formGroup.controls.profilesResidencies.value.length === 0 || (this.showseniority && this.formGroup.controls.seniority.value.length == 0)) {
        this.filtredProfiles = [];
        return;
      }

      // Make a deep copy of the array
      let allProfilesCopy = lodash.cloneDeep(this.allProfiles);

      // Filter profiles based on selected positions and residencies and seniorities
      this.filtredProfiles = allProfilesCopy.filter((profile) => {
        // Filter on position
        if (!this.formGroup.controls.profilesPositions.value.includes(profile.position)) {
          return false;
        }

        // Filter on residency
        if (!this.formGroup.controls.profilesResidencies.value.includes(profile.residency)) {
          return false;
        }

        // Filter on seniority
        if (this.showseniority) {
          if (profile.position === ANESTHETIST && !this.formGroup.controls.seniority.value.includes(profile.seniority)) {
            return false;
          }
        }

        return true;
      });
    }
  }

  initReasons(hospitalId?: string) {
    return new Promise<void>((resolve, reject) => {
      this.reasonsSubscription = this.reasonService.getAllReasons(hospitalId).subscribe((reasons) => {
        const profileType = this.passedData.originalView === "INF" ? NURSE : ANESTHETIST;
        if(profileType === ANESTHETIST)
          this.allReasons = reasons.filter((reason) => !reason.disabled && (reason.postes.includes(Iade) || reason.postes.includes(profileType) ))
        else
          this.allReasons = reasons.filter((reason) => !reason.disabled && reason.postes.includes(profileType));
        return resolve();
      }, (error) => {
        this.errorService.handleError(error);
        return reject();
      });
    })
  }

  initPositions(): void {
    switch (this.passedData.profilesPosition) {
      case NURSE:
        this.getFormationsAndInitPositions();
        break;
      case ANESTHETIST:
        this.isLoading = false;
        if (this.isInternResponsible) {
          this.profilesPositions = [
            {
              label: 'Anesthésiste',
              value: 'Anesthésiste'
            }
          ];
        } else {
          this.profilesPositions = [
            {
              label: 'Anesthésiste',
              value: 'Anesthésiste'
            },
            {
              label: 'IADE',
              value: 'Iade'
            }
          ];
        }
        break;
      case "Iade":
        this.isLoading = false;
        this.profilesPositions = [
          {
            label: 'IADE',
            value: 'Iade'
          }
        ];
        break;
    }
  }

  getFormationsAndInitPositions(): void {
    this.paramedicalService.getFormations().subscribe((formations: Formation[]) => {
      this.formations = formations;

      const nursesPostes = this.constructNursesPostesAndExcludeIade();
      this.profilesPositions = nursesPostes;

      this.isLoading = false;
    });
  }

  constructNursesPostesAndExcludeIade(): any[] {
    const postes = [];

    for (const formation of this.formations) {
      if (formation.name.toLowerCase() !== 'iade') {
        postes.push({
          label: formation.name,
          value: formation.name,
          isChecked: false
        });
      }
    }

    return postes;
  }

  close(): void {
    this.dialogRef.close();
  }

  get isTargeted() {
    return this.formGroup.controls['isTargeted'].value
  }

  makeForm(): void {
    if (!this.isMultiHospitals) {
      this.formGroup = this.formBuilder.group({
        fromDate: [this.passedData.fromDate, [Validators.required]],
        toDate: [this.passedData.toDate, [Validators.required]],
        profilesPositions: [this.passedData.profilesPosition == "Iade" ? ["Iade"] : "", [Validators.required]],
        profilesResidencies: ['', [Validators.required]],
        seniority: [[]],
        requestType: ['', [Validators.required]],
        reason: ['null'],
        isTargeted: [false, [Validators.required]],
        action: ['', [Validators.required]]
      });
    } else {
      this.formGroup = this.formBuilder.group({
        fromDate: [this.passedData.fromDate, [Validators.required]],
        toDate: [this.passedData.toDate, [Validators.required]],
        profilesPositions: [this.passedData.profilesPosition == "Iade" ? ["Iade"] : "", [Validators.required]],
        profilesResidencies: ['', [Validators.required]],
        seniority: [[]],
        requestType: ['', [Validators.required]],
        reason: ['null'],
        isTargeted: [false, [Validators.required]],
        action: ['', [Validators.required]],
        hospitals: [this.hospitals[0], [Validators.required]]
      });
    }
  }

  // RequestsType: Absence, Présence
  onRequestsTypeChange(event: any): void {
    this.filterReasons();
  }

  // ProfilesResidencies: Titulaire, Vacataire
  onProfilesResidenciesChange(event: any): void {
    this.filterReasons();
    this.filterProfiles();
  }

  // ProfilesPosition: IDE, IBODE, Iade, Anesthésiste
  onProfilesPositionChange(event: any): void {
    this.filterReasons();
    this.filterProfiles();
  }

  onProfilesSenioritiesChange(event: any): void {
    this.filterReasons();
    this.filterProfiles();
  }

  filterReasons(): void {
    if (this.formGroup.controls.profilesPositions.value.length === 0 || 
      this.formGroup.controls.profilesResidencies.value.length === 0 || 
      (this.showseniority && this.formGroup.controls.seniority.value.length == 0) ||
      this.formGroup.controls.requestType.value.length === 0) {
      this.filtredReasons = [];
      return;
    }

    let selectedPositionsSet = new Set<string>();

    for (const position of this.formGroup.controls.profilesPositions.value) {
      switch (position) {
        case 'IDE': {
          selectedPositionsSet.add('Infirmiere');
          break;
        }
        case 'IBODE': {
          selectedPositionsSet.add('Infirmiere');
          break;
        }
        case 'AS': {
          selectedPositionsSet.add('Infirmiere');
          break;
        }
        case 'Iade': {
          selectedPositionsSet.add('Iade');
          break;
        } case 'Anesthésiste': {
          selectedPositionsSet.add('Anesthésiste');
          break;
        }
        default: {
          selectedPositionsSet.add('Infirmiere');
          break;
        }
      };
    }

    let selectedPositions = [...selectedPositionsSet];

    this.filtredReasons = this.allReasons.filter((reason) => {
      // Filter on request type
      if (reason.availability !== this.formGroup.controls.requestType.value) {
        return false;
      }

      // Filter on profiles residencies
      for (let profileResidency of this.formGroup.controls.profilesResidencies.value) {
        if (!reason.residencies.includes(profileResidency)) {
          return false;
        }
      }

      // Filter on profiles positions
      for (let profilePosition of selectedPositions) {
        if (!reason.postes.includes(profilePosition)) {
          return false;
        }
      }

      // Filter on profiles seniorities
      if (this.showseniority) {
        for (let seniority of this.formGroup.controls.seniority.value) {
          if (!reason.seniority.includes(seniority)) {
            return false;
          }
        }
      }
        
      return true;
    });

    /*
    const postesLength:number = this.formGroup.value.profilesPositions.length ;
    if(postesLength< 2){
      this.filtredReasons = this.filtredReasons.filter( (reason)=>{
        return postesLength === 0 ? false :this.postesHasTitle(reason.postes, this.formGroup.value.profilesPositions[0])
      });
    }
    */
  }

  getSelectedIDS(profiles: Profile[]):string[] {
    const selectProfiles = profiles.filter(el => el.checked);
    return selectProfiles.map(el => el._id);
  }

  submit(): void {
    const values = this.formGroup.value;

    if (moment(values.fromDate).isAfter(moment(values.toDate))) {
      this.toastService.errorToast('Les dates que vous avez saisies sont incorrectes');
      return;
    }

    values.reason = this.filtredReasons.find(ele => {
      return ele.title == values.reason
    })

    const data = {
      fromDate: moment(values.fromDate).format('YYYY-MM-DD'),
      toDate: moment(values.toDate).format('YYYY-MM-DD'),
      profilesPositions: values.profilesPositions,
      profilesResidencies: values.profilesResidencies,
      availability: values.requestType,
      reasonId: values.reason && values.reason._id !== 'null' ? values.reason._id : null,
      action: values.action,
      targetedIds : values.isTargeted ? this.getSelectedIDS(this.filtredProfiles) : null,
      seniority: values.seniority,
      isExtraclinique: values.reason && values.reason.title == 'Extraclinique',
      hospital: this.isMultiHospitals ? values.hospitals._id : this.userService.getSelectedHospitals()[0]
    };

    this.isSending = true;
    
    this.calendarRequestService.updateManyRequests(data).subscribe((result) => {
      this.isSending = false;
      this.dialogRef.close({ action: data.action, traitedRequestsCount: result.traitedRequestsCount });
    }, (error) => {
      this.isSending = false;
      this.errorService.handleError(error)
      this.dialogRef.close();
    });
  }

  capitalizeFirstLetter(str: string): string {
    if (!str) {
      return str;
    }
    return `${str[0].toUpperCase()}${str.substr(1).toLowerCase()}`;
  }

  postesHasTitle(postes, title): boolean{
    return postes.find( 
      (poste:string)=> poste.toLowerCase() === title.toLowerCase()
    ) !== undefined;
  }
}
