import { Component, OnInit, Input } from '@angular/core';
import { ProgramAPI } from '../../dal/buffer-program.dal';
import { PROGRAM_CARD_BACKGROUND_COLOR_1, PROGRAM_CARD_BACKGROUND_COLOR_2 } from 'src/app/shared/const/glabals.const';;
import { UtilisService } from './../../services/utilis.service';
import { Calendar } from '../../models/calendar.model';
import { Profile } from '../../models/profile.model';
import { Reason } from '../../models/reason.model';

@Component({
  selector: 'app-bloc-program',
  templateUrl: './bloc-program.component.html',
  styleUrls: ['./bloc-program.component.scss']
})
export class BlocProgramComponent implements OnInit {

  @Input() program: ProgramAPI;
  @Input() calendars: Calendar[];
  @Input() color?: number;
  @Input("garde") garde: boolean;

  constructor(
   private UtilisService:UtilisService
  ) { }

  eAccentToE(str: string){
    return this.UtilisService.eAccentToE(str)
  }

  ngOnInit(): void {
  }

  get chunks(){
    if(!this.calendars || !this.calendars.length) return [];
    return Array(Math.ceil(this.calendars.length/3)).fill(0);
  }

  get guardTitle(){
    const title = this.calendars && this.calendars.length > 0 ? (<Reason>this.calendars[0].reason).title : "";
    return title
  }

  getColor(): string{
    return (this.color % 2) == 0 ? PROGRAM_CARD_BACKGROUND_COLOR_1 : PROGRAM_CARD_BACKGROUND_COLOR_2
  }

}
