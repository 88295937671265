import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {SpecialtyService} from '../../services/specialty.service';
import {RankingService} from '../../services/ranking.service';
import {Subscription} from 'rxjs';
import {SkillService} from '../../services/skill.service';
import {MatDialog} from '@angular/material';
import {ErrorService} from '../../services/error.service';
import {PopupManagerService} from "../../services/popup-manager.service";
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-intialize-profile-skills',
  templateUrl: './intialize-profile-skills.component.html',
  styleUrls: ['./intialize-profile-skills.component.scss'],
})

export class IntializeProfileSkillsComponent implements OnInit, OnDestroy {

  private getSpecialtiesWithRanksSubscription: Subscription;
  @Output() skillsValidation = new EventEmitter();
  @Input() profile;
  @Input() openType: string;

  @Output() close = new EventEmitter();

  selected = '1';
  specialtiesWithRanks: any[];
  isLoading: boolean = true;
  skillServiceSubscription: Subscription;
  imgLinks: string[];
  listOfRatings: any[] = [];

  public osComponentOptions = {
    className : "os-theme-dark custom-sidebar",
    nativeScrollbarsOverlaid : {
      showNativeScrollbars : true
    },
    overflowBehavior : {
      x : "hidden",
  },
    paddingAbsolute: true,
    scrollbars: {
      autoHide: 'never',
      clickScrolling: true
    },
  };

  constructor(
    private rankingService: RankingService,
    private skillService: SkillService,
    private errorService: ErrorService,
    private popupManagerService: PopupManagerService,
    private userService: UserService
  ) {
  }

  ngOnInit() {
    if (this.openType === 'EDIT') {
      this.getNurseSkills();
    } else {
      this.getSpecialtiesWithRanks();
    }
  }

  getNurseSkills() {
    const selectedHospital = this.userService.getSelectedHospitals()
    this.skillServiceSubscription = this.skillService.getSkillsOfNurse(this.profile._id).subscribe(res => {
      this.getSpecialtiesWithRanksSubscription = this.rankingService.getSpecialtiesWithRanks({ formation: this.profile.position, hospitalId: selectedHospital[0]}).subscribe((results) => {
        this.formatSkills(res, results);
        this.specialtiesWithRanks = this.specialtiesWithRanks.filter(item => item.specialtyName !== 'Bariatrique');
        this.setSpecialtiesIcons();
        this.isLoading = false;
      }, error => this.errorService.handleError(error))
    }, error => this.errorService.handleError(error));
  }

  getAsLevel(first: boolean) {
    if (this.profile.position == 'AS') {
      return first? 0 : 2
    } 
    return 1;
  }

  formatSkills(list: any[], ratings: any) {
    ratings.forEach(specialty => {
      specialty.ratings.forEach(rating => {
        if (!this.listOfRatings.find((rating_) => String(rating_.role.name) === String(rating.role.name))) {
          this.listOfRatings.push(rating)
        }
      });
    });
    for (let index = 0; index < list.length; index++) {
      const skill = list[index];
      const rating = ratings.find((r) => String(r.specialty._id) === String(skill.specialty._id))
      
      if (rating) {
        skill.ratings = rating.ratings
        skill.ratings.forEach(rating => {
          if (String(rating.role._id) === String(skill.role._id)) {
            rating.nurseLevel = skill.level
            rating.oldNurseLevel = skill.level
            rating.tmpLevel = skill.level
            rating.maxLevel = rating.level.length + 1
            rating.timeIndicator = skill.timeIndicator
          }
        });
      }
    };
    list = this.sortSpecialties(list);
    const skillsArray: any[] = [];
    for (const item of list) {
      if (!skillsArray.find((sk) => String(sk.specialtyId) === String(item.specialty._id))) {
        skillsArray.push({
          specialtyId: item.specialty._id,
          specialtyName: item.specialty.name,
          specialtyImage: item.specialty.image,
          ratings: item.ratings
        });
      }
    }
    this.specialtiesWithRanks = skillsArray;
  }

  skillChanged(skill: any, rating: any) {
    const specialtyRole = this.getSpecialtyRole(skill, rating)
    if (this.openType === 'EDIT') {
      if (specialtyRole.tmpLevel === "0" || specialtyRole.tmpLevel === 0) {
        this.openConfirmationDialog(skill, specialtyRole);
      } else {
        if (specialtyRole.oldNurseLevel !== specialtyRole.nurseLevel) {
          specialtyRole.timeIndicator = 0;
        }
      }
      specialtyRole.tmpLevel = specialtyRole.nurseLevel
    }
  }

  openConfirmationDialog(skill: any, rating: any): void {
    const description = `En modificant cette valeur vous autorisez ${this.profile.firstName} ${this.profile.lastName} à travailler en ${skill.specialtyName} en tant que ${rating.role.name}. Etes-vous sûr(e) de votre choix ?`;

    this.popupManagerService.openConfirmationPopup('Autoriser la progression ?', description, 375, 'primary', 'Oui, autoriser').afterClosed().subscribe(result => {
      if (!result) {
        rating.oldNurseLevel = "0"
        rating.nurseLevel = rating.oldNurseLevel;
      }
    });
  }
  
  isSpecialtyHaveRole(specialty, role) {
    if (specialty && specialty.ratings) {
      for (let index = 0; index < specialty.ratings.length; index++) {
        const rating = specialty.ratings[index];
        if (String(rating.role.name) === String(role.role.name)) {
          return true
        }
      }
      return false;
    }
  }

  getSpecialtyRole(specialty, role) {
    for (let index = 0; index < specialty.ratings.length; index++) {
      const rating = specialty.ratings[index];
      if (String(rating.role.name) === String(role.role.name)) {
        return rating
      } 
    }
    return null;
  }

  getSpecialtiesWithRanks() {
    if (this.getSpecialtiesWithRanksSubscription) {
      this.getSpecialtiesWithRanksSubscription.unsubscribe();
    }
    this.getSpecialtiesWithRanksSubscription = this.rankingService.getSpecialtiesWithRanks({ formation: this.profile.position, hospitalId: this.profile.hospitals[0] }).subscribe((results) => {
      this.specialtiesWithRanks = results;
      this.getlistOfRatings();
      this.generateSkillsList(this.specialtiesWithRanks);
      this.setSpecialtiesIcons();
      if (!this.specialtiesWithRanks || this.specialtiesWithRanks.length <= 0) {
        this.skillsValidation.emit(this.specialtiesWithRanks)
      }
      this.isLoading = false;
    });
  }

  getlistOfRatings() {
    this.specialtiesWithRanks.forEach(specialty => {
      specialty.ratings.forEach(rating => {
        if (!this.listOfRatings.find((rating_) => String(rating_.role.name) === String(rating.role.name))) {
          this.listOfRatings.push(rating)
        }
      });
    });
  }

  generateSkillsList(list: any[]) {
    list = this.sortSpecialties(list);
    const skillsArray: any[] = [];
    this.specialtiesWithRanks.forEach(specialty => {
      specialty.ratings.forEach(rating => {
        rating.nurseLevel = 1;
        rating.tmpLevel = 1;
        rating.maxLevel = rating.level.length + 1
      });
    });
    for (const item of list) {
      skillsArray.push({
        specialtyId: item.specialty._id,
        specialtyName: item.specialty.name,
        specialtyImage: item.specialty.image,
        ratings: item.ratings
      });
    }
    this.specialtiesWithRanks = skillsArray;
  }

  sortSpecialties(skillsArray: any[]) {
    return skillsArray.sort((a, b) => {
      return a.specialty.priority - b.specialty.priority; //ERR
    });
  }

  generateListByLength(length) {
    const list: number[] = [];
    for (let i = 0; i < length; i++) {
      list.push(i + 1);
    }
    return list;
  }

  onSkillsValidation() {
    this.specialtiesWithRanks = this.specialtiesWithRanks.filter((specialty) => specialty.ratings)
    this.specialtiesWithRanks.forEach(specialty => {
      specialty.ratings.forEach(rating => {
        rating.nurseLevel = parseInt(rating.nurseLevel)
      });
    });
    this.skillsValidation.emit(this.specialtiesWithRanks);
  }

  setSpecialtiesIcons() {
    let specialtiesLinks: string[] = [];
    for (let specialty of this.specialtiesWithRanks) {
      specialtiesLinks.push(specialty.specialtyImage);
    }
    this.imgLinks = specialtiesLinks;
  }


  ngOnDestroy(): void {
    if (this.getSpecialtiesWithRanksSubscription) {
      this.getSpecialtiesWithRanksSubscription.unsubscribe();
    }

    if (this.skillServiceSubscription) {
      this.skillServiceSubscription.unsubscribe();
    }
  }

  closeDialog(): void {
    this.close.emit();
  }
}
