import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { urlEndPoints } from '../config/end-points';
import { Specialty } from '../models/specialty.model';


@Injectable()
export class SpecialtyDal {

  constructor(private httpClient: HttpClient) {
  }

  // NOT USED
  // getNumberofSpecialties(): Observable<number> {
  //   return this.httpClient.get<number>(urlEndPoints.specialty + "/numberOfSpecialties")
  // }

  getIsSpecialityExist(name: string): Observable<Specialty[]> {
    return this.httpClient.get<Specialty[]>(urlEndPoints.specialty + "/exist/" + name)
  }

  getAllSpecialties(includeUrgence: boolean): Observable<Specialty[]> {
    return this.httpClient.get<Specialty[]>(urlEndPoints.specialty + (includeUrgence ? '?urgences=yes' : '')).pipe(map((specialties: any) => {
        return specialties.docs;
      }));
  }

  getAllSpecialtiesFromHospital(includeUrgence: boolean, hospital: string): Observable<Specialty[]> {
    return this.httpClient.get<Specialty[]>(urlEndPoints.specialty + '?hospital=' + hospital + (includeUrgence ? '&urgences=yes' : '')).pipe(map((specialties: any) => {
        return specialties.docs;
      }));
  }

  getSharedSpecialties(): Observable<Specialty[]> {
    return this.httpClient.get<Specialty[]>(`${urlEndPoints.specialty}/shared`);
  }

  addSpecialty(specialty: Specialty): Observable<any> {
    return this.httpClient.post<Specialty>(urlEndPoints.specialty, specialty);
  }

  updateSpecialty(specialty: Specialty): Observable<any> {
    return this.httpClient.put<Specialty>(urlEndPoints.specialty + '/' + specialty._id, specialty);
  }

  deleteSpecialty(specialtyId: number): Observable<any> {
    return this.httpClient.delete<any>(urlEndPoints.specialty + '/' + specialtyId);
  }

  softDeleteSpecialty(specialtyId: number): Observable<any> {
    return this.httpClient.put<Specialty>(urlEndPoints.specialty + '/softDelete/' + specialtyId, null);
  }

  softRestoreSpecialty(specialtyId: string): Observable<any> {
    return this.httpClient.put<Specialty>(urlEndPoints.specialty + '/softRestore/' + specialtyId, null);
  }

  getAllSpecialtiesWithNameAndColor(): Observable<any[]> {
    return this.httpClient.get<any[]>(urlEndPoints.specialty + '/allSpecialtiesWithNameAndColor');
  }
}
