import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LevelUpRequest, LevelUpRequestsPaginated } from "../models/levelUpRequest.model";
import { urlEndPoints } from "../config/end-points";
import { Observable } from "rxjs";

@Injectable()
export class LevelUpRequestDAL {
    constructor(private httpClient: HttpClient) {

    }

    getLevelUpRequests(page?: number, limit?: number): Observable<LevelUpRequest[] | LevelUpRequestsPaginated> {
        let params = new HttpParams();
        
        if (page != undefined && limit != undefined) {
            page = page || 1;
            limit = limit || 3;
            params = params.append('page', String(page));
            params = params.append('limit', String(limit));
        }
        
        return this.httpClient.get<LevelUpRequest[] | LevelUpRequestsPaginated>(
            `${urlEndPoints.levelUpRequests}`, 
            { params }
        );
    }

    validateOrRejectLevelUpRequest(levelUpRequestId: String, action: string): Observable<void> {
        if (!['accept', 'decline'].includes(action)) {
            throw new Error('action must be equal to either "accept" or "decline"');
        }

        const URI = `${urlEndPoints.levelUpRequests}${levelUpRequestId}/${action}`;
        
        return this.httpClient.put<void>(URI, {});
    }
}