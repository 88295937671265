import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { Profile } from "src/app/shared/models/profile.model";
import { StorageService } from "../../../core/services/storage.service";
import { User } from "../../models/user.model";
import { AddProfileDialogComponent } from "../add-profile-dialog/add-profile-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { NURSE, SURGEON } from "../../const/glabals.const";
import { UserService } from "./../../services/user.service";
import {StatopService} from "../../services/statop.service";
import * as moment from "moment";

@Component({
  selector: "app-basic-profile",
  templateUrl: "./basic-profile.component.html",
  styleUrls: ["./basic-profile.component.scss"],
})
export class BasicProfileComponent implements OnInit, OnChanges {
  @Input() profile: Profile;
  @Input() showSpecialty: boolean = false;
  @Input() principalWish: { name: string , icon: string};
  @Input() showPrincipalWish: boolean = false;
  imageLink: string = "";
  arrivalDate: string;
  currentUser: User;
  @Output() profileChanges = new EventEmitter();
  public SURGEON: string = SURGEON;
  getUserByProfileIdSubscription: any;
  currentProfileMail: any;

  constructor(
    private userService: UserService,
    private dialog: MatDialog,
    private storageService: StorageService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.profile) {
      this.resetVariables();
      this.getUserByProfile();
      this.currentUser = this.storageService.getUser();
      this.arrivalDate = moment(this.profile.startingDate).format('DD/MM/YYYY');
    }
  }

  resetVariables(): void {
    this.imageLink = '';
    this.currentProfileMail = null;
  }

  ngOnInit() {
    // this.getUserByProfile();
    // this.currentUser = this.storageService.getUser();
    // const date: Date = new Date(this.profile.startingDate);
    // this.arrivalDate =
      // date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
  }

  getUserByProfile() {
    if (!this.profile.mail) {
      this.getUserByProfileIdSubscription = this.userService
        .getUserByProfileId(this.profile._id)
        .subscribe((doc: any) => {
          if(doc.doc){
            this.currentProfileMail = doc.doc.email;
          }
        });
    } else {
      this.currentProfileMail = this.profile.mail
    }
  }
}
