import { Injectable } from '@angular/core';
import { PythonAPIDal } from '../dal/pythonAPI.dal';
import { Observable } from 'rxjs/internal/Observable';
import { anesthsRanks, nursesRanking } from '../interfaces/pythonAPI.interfaces';

@Injectable()
export class PythonAPIService {

  constructor(private pythonAPIdal: PythonAPIDal) {
  }

  autoFillingByInterval(startDate: string, endDate: string, roomsToKeep: any[], profilesToKeep: any[], roomsConfiguration: {}, autofillingType?: string) {
      return this.pythonAPIdal.autoFillingByInterval(startDate, endDate, roomsToKeep, profilesToKeep, roomsConfiguration, autofillingType);
    }

  getNursesRanking(date: string): Observable<nursesRanking[]> {
    return this.pythonAPIdal.getNursesRanking(date);
  }
  autoFillingByIntervalAnesth(startDate: string, endDate: string, seniority?: any, autofillingTypes?:any, roomsToKeep?: any[], profilesToKeep?: any[], isSplitTeams?: boolean) {
    return this.pythonAPIdal.autoFillingByIntervalAnesth(startDate, endDate, seniority, autofillingTypes, roomsToKeep, profilesToKeep, isSplitTeams)
  }

  getAnesthRanking(date: string): Observable<anesthsRanks> {
    return this.pythonAPIdal.getAnesthsRanking(date);
  }

  
  getRoomsOverflowData(date: string, displayReal?: boolean): Observable<any[]> {
    return this.pythonAPIdal.getRoomsOverflowData(date, displayReal);
  }

  getInterventionOverflowData(input: string): Observable<any[]> {
    return this.pythonAPIdal.getInterventionOverflowData(input);
  }

  getRoomsOverflowOfUnsavedData(date: string, roomsWithDoctorAgendas: any): Observable<any[]> {
    return this.pythonAPIdal.getRoomsOverflowOfUnsavedData(date, roomsWithDoctorAgendas);
  }
  getSurgeonOpeningsOfSurgeonByInterval(surgeonId: string, startDate: string, endDate: string): Observable<any[]> {
    return this.pythonAPIdal.getSurgeonOpeningsOfSurgeonByInterval(surgeonId, startDate, endDate);
  }


  
}
