import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-un-saved-changes-pop-up',
  templateUrl: './un-saved-changes-pop-up.component.html',
  styleUrls: ['./un-saved-changes-pop-up.component.scss']
})
export class UnSavedChangesPopUpComponent implements OnInit {

  public message: string;
  public home : boolean;

  constructor(private dialogRef: MatDialogRef<UnSavedChangesPopUpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.message = this.data.message;
    this.home = this.data.home;
  }

  cancel(): void {
    this.dialogRef.close("cancel");
  }

  continue(): void {
    this.dialogRef.close("continue");
  }

  save(): void {
    this.dialogRef.close("save");
  }

}
