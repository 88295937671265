import {AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild, OnChanges} from '@angular/core';
import {ANESTHETIST, Iade, IADE, NURSE, ONLY_ANESTH, SURGEON} from '../../const/glabals.const';
import {Subscription} from 'rxjs';
import {ErrorService} from '../../services/error.service';
import {ProfileService} from '../../services/profile.service';
import {NavigationService} from '../../../core/services/navigation.service';
import {UserService} from 'src/app/shared/services/user.service';
import {Profile} from 'src/app/shared/models/profile.model';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit, OnDestroy, OnChanges {
  @Input() fullWidth: boolean;
  @Input() emitProfileObject: boolean;
  @Input() isParent: boolean = false;
  @Input() type: string = NURSE;
  @Input() emitProfile: boolean;
  @Input() justTitular: boolean = false;
  @Input() noUrgences: boolean = false;
  @Input() isAllProfiles: boolean = true;
  @Input() onlyInterns: boolean = false;
  @Input() isNoUserAssociate: boolean = false;
  @Input() multiType = [];

  @Input() placeholder: string = 'Rechercher une personne ...';
  @Input() label: string = '';
  @Input() hintMessage: string = '';
  @Input() hospitalId: string = undefined

  //iconType : 'image' / 'mat-icon'.
  @Input() iconType: string = 'mat-icon';

  //Icon : button icon : svg / mat-icon.
  @Input() icon: string = 'search';

  @Input() selectedProfile: Profile;

  @Output() onRouterChange = new EventEmitter<string>();
  @Output() onProfileSelected = new EventEmitter<any>();
  @Output() onMultipleProfile = new EventEmitter<any>();
  @Output() isLoadingChange = new EventEmitter<boolean>();

  private searchSpecInfByNameSubscription: Subscription;

  public lastSearchValue: string = '';
  public isShowSearchResults: boolean = false;
  @ViewChild('search', null) searchElement: ElementRef;
  isLoading: boolean = false;
  spInfList: any[];
  public allProfiles: Profile[];
  class: any;

  public osComponentOptions = {
    className: 'os-theme-dark custom-sidebar',
    nativeScrollbarsOverlaid: {
      showNativeScrollbars: false
    },
    overflowBehavior: {
      x: 'hidden',
    },
    paddingAbsolute: true,
    scrollbars: {
      autoHide: 'never',
    },
  };


  constructor(
    private userService: UserService,
    private profileService: ProfileService,
    private errorService: ErrorService,
    private navigationService: NavigationService
  ) {
  }

  ngOnInit() {
    if (this.fullWidth) {
      this.class = 'width-100';
    }
    // this.fetchAllProfilesOfType();
  }

  ngOnChanges() {
    this.fetchAllProfilesOfType();
  }

  onEnter(searchInput: string): void {
    this.lastSearchValue = searchInput;
    this.isLoading = true;
    this.isLoadingChange.emit(this.isLoading);
    this.filterProfiles(searchInput);
    this.isLoading = false;
    this.isLoadingChange.emit(this.isLoading);
  }

  filterProfiles(searchInput: string): void {
    if (!this.allProfiles ||this.allProfiles.length == 0)
      return;
    this.spInfList = this.allProfiles.filter((profile) => {
      let firstLast = `${profile.firstName} ${profile.lastName}`;
      let lastFirst = `${profile.lastName} ${profile.firstName}`;
      firstLast = firstLast.toLowerCase();
      lastFirst = lastFirst.toLowerCase();
      const searchInputLowerCase = searchInput.toLowerCase();
      if (firstLast.includes(searchInputLowerCase) || lastFirst.includes(searchInputLowerCase)) {
        return true;
      }
    });
  }

  toggleSearchResults(event: any): void {
    event.stopPropagation();
    if (this.isShowSearchResults) {
      this.isShowSearchResults = !this.isShowSearchResults;
      this.searchElement.nativeElement.blur();
      this.hideInputText();
    } else {
      this.isShowSearchResults = !this.isShowSearchResults;
      this.searchElement.nativeElement.focus();
      this.showInputText();
    }
  }

  showSearchResults(event: any): void {
    if (event) {
      event.stopPropagation();
    }
    this.searchElement.nativeElement.focus();
    this.isShowSearchResults = true;
    this.searchElement.nativeElement.value = this.lastSearchValue;
  }

  hideInputText(): void {
    // we hide input text if we are not searching and we have a selected profile
    if (this.selectedProfile) {
      this.searchElement.nativeElement.value = '';
    }
  }

  showInputText(): void {
    // We show input text if we are searching
    this.searchElement.nativeElement.value = this.lastSearchValue;
  }


  profileClick(profile: Profile, event: any): void {
    this.selectedProfile = profile;
    const id = profile._id;
    if (this.emitProfileObject) {
      this.onProfileSelected.emit(profile);
      return;
    } else if (this.emitProfile) {
      this.onProfileSelected.emit(id);
      return;
    }

    let prefix;
    if (this.type === NURSE) {
      prefix = 'parcours-ibode/competence/competence-individuelle/';
    } else if (this.type === SURGEON) {
      prefix = 'statop/bloc/par-praticien/';
    } else {
      prefix = 'configuration/fiche-poste/';
    }

    this.navigationService.navigateByUrl(prefix + id).then(() => {
      if (!this.isParent) {
        this.onRouterChange.emit('refresh');

      }
    }).catch();
  }

  fetchAllProfilesOfType(): void {
    this.isLoading = true;
    this.isLoadingChange.emit(this.isLoading);
    if (this.searchSpecInfByNameSubscription) {
      this.searchSpecInfByNameSubscription.unsubscribe();
    }

    let seniorities;

    if (this.type === ONLY_ANESTH) {
      seniorities = this.onlyInterns ? 'Interne' : 'Senior, Junior, Interne';
    }
    if (this.multiType.length > 0) {

      this.allProfiles = []
      this.multiType.forEach(t => {
        this.searchSpecInfByNameSubscription = this.profileService.getProfilesByPositionFromHospital(this.hospitalId, t, null, null, '', this.justTitular, this.isAllProfiles, this.noUrgences, seniorities, false, this.isNoUserAssociate)
        .subscribe((results) => {
          this.isLoading = false;
          this.isLoadingChange.emit(this.isLoading);
          this.allProfiles = [...this.allProfiles, ...results.docs]
          this.spInfList = this.allProfiles
          this.onMultipleProfile.emit(this.spInfList)
          }        
        , error => this.errorService.handleError(error));      
      })
    }
    else {

      this.searchSpecInfByNameSubscription = this.profileService.getProfilesByPositionFromHospital(this.hospitalId, this.type, null, null, '', this.justTitular, this.isAllProfiles, this.noUrgences, seniorities, false, this.isNoUserAssociate)
      .subscribe((results) => {
        this.isLoading = false;
        this.isLoadingChange.emit(this.isLoading);
        this.allProfiles = results.docs;
        this.spInfList = this.allProfiles;
      }, error => this.errorService.handleError(error)); 
    }
  }

  @HostListener('window:click', ['$event'])
  click(event) {
    this.isShowSearchResults = false;
    this.hideInputText();
  }

  ngOnDestroy() {
    if (this.searchSpecInfByNameSubscription) {
      this.searchSpecInfByNameSubscription.unsubscribe();
    }
  }
}