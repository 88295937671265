import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { User } from 'src/app/shared/models/user.model';
import { UserService } from 'src/app/shared/services/user.service';
import {PopupManagerService} from "../../services/popup-manager.service";
import { escapeRegExp } from '../../utils/cross-functions';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {
  columns = ['profile', 'position', 'email', 'levelOfAccess','deleteUser'];
  isUserDelete: boolean = false
  users: any[];
  public isInternResponsible: boolean;

  public filteredUsers: any[];
  // Search bar
  public searchValue: string;

  constructor(
    private userService: UserService,
    public dialogRef: MatDialogRef<UsersListComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private dialog: MatDialog,
    private popupManagerService: PopupManagerService,
  ) {
    this.isInternResponsible = this.userService.isInternResponsible();
  }

  ngOnInit() {
    this.users = [...this.data.users.sort((a, b) => b.levelOfAccess - a.levelOfAccess)];
    this.filteredUsers = this.users;
  }

  removeUser(user: User) {
    this.userService.hardDeleteUser(user).subscribe(res => {
      this.isUserDelete = true
      this.removeUserFromlist(user)
    })
  }

  removeUserFromlist(user: User) {
    const usersTemp = [...this.users]
    this.users = []
    this.users = usersTemp.filter(userItem => userItem._id != user._id)
  }

  openDeleteConfirmation(user) {
    const description = `Etes vous sûr(e) de supprimer l'utilisateur ? L'utilisateur sera inaccessible dans les services de SmartOp et ne pourra plus être récupéré.`;

    this.popupManagerService.openConfirmationPopup('Supprimer cet utilisateur ?', description, 335, 'danger', 'Oui, supprimer').afterClosed().subscribe(result => {
      if (result) {
        this.removeUser(user)
      }
    });
  }

  close() {
    this.dialogRef.close(this.isUserDelete)
  }

  displayLevelOfAccess(levelOfAccess) {
    if (levelOfAccess) {
      switch (levelOfAccess) {
        case 1:
          return "Collaborateur limité";
        case 2:
          return "Collaborateur";
        case 3:
          return "Responsable planning";
        case 4:
          return "Référent hôpital";
        case 5:
          return "Administrateur";
      }
    }
    return "-";
  }

  formatName(name) {
    const formattedName = name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    return formattedName;
  }

  isEmailTruncated(email: string): boolean {
    const maxLength = 36;
    return email.length > maxLength;
  }

  isProfileTruncated(firstName: string, lastName: string): boolean {
    if (!firstName || !lastName)
    {
      return false;
    }
    const maxLength = 30;
    return (firstName.length + lastName.length) > maxLength;
  }

  // Search bar
  doFilter(value){
    value = escapeRegExp(value);
    this.filteredUsers = [...this.users].filter(user => {
      var re = new RegExp(value, 'gi');
      return user.profile.firstName.match(re) != null || user.profile.lastName.match(re) != null 
    })
  }
}
