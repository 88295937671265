import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';

import { urlEndPoints } from '../config/end-points';
import { User } from '../models/user.model';

@Injectable()
export class UserDal {

  constructor(private httpClient: HttpClient) {
  }

  getAllUsers(): Observable<User[]> {
    return this.httpClient.get<User[]>(urlEndPoints.users);
  }

  addUser(user: User): Observable<any> {
    return this.httpClient.post<any>(urlEndPoints.users, user);
  }

  modifyUser(user: User): Observable<User> {
    return this.httpClient.put<User>(urlEndPoints.users + user._id, user);
  }

  archiveUser(idUser): Observable<User> {
    return this.httpClient.delete<User>(urlEndPoints.users + idUser);
  }

  getUser(idUser): Observable<any> {
    return this.httpClient.get<User>(urlEndPoints.users + idUser);
  }
  getUserByProfileId(profileId): Observable<any> {
    return this.httpClient.get<User>(urlEndPoints.users + 'by-profile/' + profileId);
  }

  updateLevelOfAccess(levelOfAccess: number, userId: string): Observable<number> {
    return this.httpClient.put<number>(urlEndPoints.users + 'levelOfAccess/' + userId, { levelOfAccess });
  }

  getUsersCounter(): Observable<number> {
    return this.httpClient.get<number>(urlEndPoints.users + 'counter/');
  }

  hardDeleteUser(user: User): Observable<User> {
    return this.httpClient.delete<User>(urlEndPoints.users + "/hardDelete/" + user._id);
  }
}
