import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { HttpParams, HttpClient } from '@angular/common/http';
import { urlEndPoints } from '../config/end-points';
import { Room } from '../models/room.model';
import { Observable } from 'rxjs';
import { Period } from '../models/period.model';
import { map } from 'rxjs/operators';
import {DoctorAgenda} from '../models/doctor-agenda.model';


@Injectable({
  providedIn: 'root'
})
export class DoctorAgendaDal {

  constructor(private http: HttpClient) {
  }

  getAllDoctorAgendas(roomId?: string, day?: string, period?: Period):
  Observable<Room> {
    let baseUrl = urlEndPoints.doctorAgendas;
    if (roomId) {
      baseUrl += 'room/' + roomId;
    }
    let params = new HttpParams();
    params = params.append('limit', '1000');
    if (day) {
      params = params.append('jour', day);
    }
    if (period && period.from) {
      params = params.append('from', period.from);
    }
    if (period && period.to) {
      params = params.append('to', period.to);
    }
    return this.http.get<Room>(baseUrl, {params}).pipe(
      map((result: any) => {
        if (!result) { return null; }
        const room: Room = result.room;
        if (!room) {
          return null;
        }
        room.agendas = result.agendas;
        room.isRoomOpen = result.isRoomOpen
        return room;
      }));
}

  addIntervention(doctorAgendaBody: DoctorAgenda): Observable<any> {
    return this.http.post<DoctorAgenda>(`${urlEndPoints.doctorAgendas}`, doctorAgendaBody);
  }

  updateIntervention(doctorAgendaId: string, doctorAgendaBody: DoctorAgenda): Observable<DoctorAgenda> {
    return this.http.put<DoctorAgenda>(`${urlEndPoints.doctorAgendas}` + doctorAgendaId, doctorAgendaBody);
  }
  deleteOneDoctorAgenda(doctorAgendaId): Observable<any> {
    return this.http.delete<any>(`${urlEndPoints.doctorAgendas}?agendaId=` + doctorAgendaId);
  }
  deleteRoomDayDoctorAgenda(roomId: string, date: string): Observable<any> {
    return this.http.delete<any>(`${urlEndPoints.doctorAgendas}?roomId=` + roomId + '&day=' + date);
  }

  checkIfPeriodIsAvailable(roomId: string, startTime: string, endTime: string): Observable<any> {
    return this.http.get<any>(`${urlEndPoints.doctorAgendas}checkIfPeriodAvailable?roomId=${roomId}&startTime=${startTime}&endTime=${endTime}`);
  }

  addManyIntervention(doctorAgendaBody: DoctorAgenda[]): Observable<any> {
    return this.http.post<DoctorAgenda>(`${urlEndPoints.doctorAgendas}addMany`, doctorAgendaBody);
  }

  deleteManyIntervention(doctorAgendaIdBody: string[]): Observable<any> {
    return this.http.post<DoctorAgenda>(`${urlEndPoints.doctorAgendas}deleteMany`, doctorAgendaIdBody);
  }

  updateManyIntervention(doctorAgendaBody: DoctorAgenda[]): Observable<any> {
    return this.http.post<DoctorAgenda>(`${urlEndPoints.doctorAgendas}updateMany`, doctorAgendaBody);
  }

  SOFromDataPipeline(date: string, roomsId: any[], cacheData?: any, deleteIfEmpty?: boolean ): Observable<any>  {
    const requestBody = {
      date: date,
      roomsId: roomsId,
      cacheData: cacheData,
      deleteIfEmpty: deleteIfEmpty
    }
    return this.http.post<any>(`${urlEndPoints.doctorAgendas}SOFromDataPipeline`, requestBody);
  }

  getFromDataExtraction(fromDate: string, toDate: string): Observable<any>  {
    return this.http.get<any>(`${urlEndPoints.doctorAgendas}getFromDataExtraction?fromDate=${fromDate}&toDate=${toDate}`);
  }
  getLastUpdateDateDA(date: string , hospital : string ): Observable<any> {
    return this.http.get<any[]>(urlEndPoints.doctorAgendas + "lastUpdateDateDA?date="+ date + "&hospital="+hospital);
  }
  
 
}

