import { Component, Input, OnInit } from "@angular/core";
import { Location } from "@angular/common";

@Component({
  selector: "app-header-with-title",
  templateUrl: "./header-with-title.component.html",
  styleUrls: ["./header-with-title.component.scss"],
})
export class HeaderWithTitleComponent implements OnInit {
  @Input() title: string;
  @Input() hideReturnButton: string;
  @Input() isBlueTitle: boolean;
  constructor(private location: Location) {}

  ngOnInit() {}

  goToPreviousView() {
    this.location.back();
  }
}
