import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent implements OnInit, OnChanges {
  public toggle: boolean = false;
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() hintMessage: string = '';
  @Input() disabled: boolean;
  @Input() cpWidth: string;

  // This is used to handle two way binding
  @Input() inputModel: any;
  @Output() inputModelChange = new EventEmitter<any>();
  public colorWithHashtag: string;

  @Output() onKeyUp: EventEmitter<any> = new EventEmitter<any>();


  constructor() { }

  ngOnInit(): void {
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.inputModel) {
      this.colorWithHashtag = this.inputModel;
      this.inputModel = this.deleteHashtag(this.inputModel);
    }
  }

  inputModelChangeHandler(): void {
    this.colorWithHashtag = this.addHashtag(this.inputModel);
    this.inputModelChange.emit(this.colorWithHashtag);
  }

  onKeyUpHandler(input: any): void {
    this.onKeyUp.emit(input);
  }

  toggleColorsPicker(): void {
    if (!this.disabled) {
      this.toggle = !this.toggle;
    }
  }

  setColor(color){
    this.inputModel = this.deleteHashtag(color);
    this.colorWithHashtag = color;
	  this.inputModelChange.emit(this.colorWithHashtag);
  }

  deleteHashtag(color: string): string {
    if (color && color.includes('#')) {
      return color.replace('#', '');
    }
    return color;
  }

  addHashtag(color: string): string {
    if (color) {
      return `#${color}`;
    }
    return color;
  }
}
