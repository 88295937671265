import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ExclusionDal } from '../dal/exclusion.dal';

@Injectable({
  providedIn: 'root'
})
export class ExclusionService {

  constructor(private exclusionDal: ExclusionDal) { }

  getExclusions(profileId: string): Observable<any[]> {
    return this.exclusionDal.getExclusions(profileId);
  }


  deleteExclusions(exclusionId: string) {
    return this.exclusionDal.deleteExclusions(exclusionId);
  }

  addExclusions(exclusions: any[]) {
    return this.exclusionDal.addExclusions(exclusions);
  }
}
