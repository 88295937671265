import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatTime'
})
export class FormatTimePipe implements PipeTransform {

  transform(time: any): any {
    return this.formatDate(time.substring(0, 10));
  }

  formatDate(str) {
    const arr = str.split("-")
    return arr[2] + "-" + arr[1] + "-" + arr[0]
  }

}
