import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { User } from '@sentry/angular';
import * as moment from "moment";
import { Subject, Subscription } from 'rxjs';

import { NavigationService } from 'src/app/core/services/navigation.service';
import { ANESTHETIST } from '../../const/glabals.const';
import { Profile } from '../../models/profile.model';
import { ToastService } from '../../services/toast.service';
import { UserService } from '../../services/user.service';
import { VacationService } from '../../services/vacation.service';
import { Specialty } from '../../models/specialty.model';
import { RoomService } from '../../services/room.service';
import { consultationExtracliniqueData, roomFinalData } from '../day-program-recap/day-program-interfaces';
import { anesthsRanks, nursesRanking } from '../../interfaces/pythonAPI.interfaces';
import { HospitalService } from '../../services/hospital.service';
import { cloneWithoutPromise } from '../../utils/cross-functions';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-profiles-suggestions',
  templateUrl: './profiles-suggestions.component.html',
  styleUrls: ['./profiles-suggestions.component.scss']
})
export class ProfilesSuggestionsComponent implements OnInit, OnDestroy {
  public availableProfiles: any[];
  public alreadyAssigned: any[];
  public absentTitulairesProfiles: any[];
  public absentVacatairesProfiles: any[];
  public nursesRankings: nursesRanking[];
  public anesthsRankings: anesthsRanks;
  public nursesRankingsLoaded: boolean = false;
  public anesthsRankingsLoaded: boolean = false;
  public currentUser: User;
  public subHeader: string = '';
  public isRespIade: boolean = false;
  public isCadreDeBloc: boolean = false;
  public levelOfAccess: number = 1;
  public isRespAnesth: boolean = false;
  public isRespInterne: boolean = false;
  public hospitalHaveAnesthDetailsOption: boolean = false;
  public itemsPerPage: number = 3;
  public currentPageAvailable: number = 0;
  public currentPageProfilesAvailable: any[];
  public hasMoreProfilesAvailable: boolean = false;
  public currentPageAbsentTitulaire: number = 0;
  public currentPageProfilesAbsentTitulaire: any[];
  public hasMoreProfilesAbsentTitulaire: boolean = false;
  public currentPageAbsentVacataire: number = 0;
  public currentPageProfilesAbsentVacataire: any[];
  public hasMoreProfilesAbsentVacataire: boolean = false;


  public types: string[] = ["Anesthésistes", "Paramédical"]
  public selectedType: any = 0;
  public selectedSubType: any = 0;
  public subTypes: any[] = []

  private updateRankingsSubscription: Subscription;
  private addVacationSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<ProfilesSuggestionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private navigationService: NavigationService,
    private vacationService: VacationService,
    private userService: UserService,
    private toastService: ToastService,
    private roomsService: RoomService,
    private hospitalService: HospitalService
  ) {
    this.isRespIade = this.userService.isIadRes();
    this.isRespAnesth = this.userService.isAnesthResponsable()
    this.isRespInterne = this.userService.isInternResponsible()
    this.isCadreDeBloc = this.userService.isCadreBloc()
    this.levelOfAccess = this.userService.getLevelAccess()

    this.hospitalHaveAnesthDetailsOption = this.hospitalService.doesHospitalHaveAnesthDetailsOption()
    this.nursesRankings = this.data.nursesRanks
    this.anesthsRankings = this.data.anesthsRanks
    this.nursesRankingsLoaded = this.data.nursesRanksLoaded
    this.anesthsRankingsLoaded = this.data.anesthsRanksLoaded
    this.subHeader = this.getSubHeader()
    this.currentUser = this.userService.getCurrentUser();
    this.setPermission();
    if (this.isCadreDeBloc && this.levelOfAccess === 4) {
      this.subTypes = this.data.roleData.filter((role) => role.name !== "IADE").map((role) => role.name)
      this.initShownNurses();
    } else {
      this.initShownAnesths();
    }
    this.getRankingsSubscription();
  }

  setPermission() {
    if (!this.hospitalHaveAnesthDetailsOption) {
      if (this.isRespIade || (this.isRespAnesth && !this.isRespInterne)) {
        this.types = ["Anesthésistes"]
        this.subTypes = ['IADE']
      } else if (this.isRespInterne) {
        this.types = ["Anesthésistes"]
        this.subTypes = ['Interne']
      } else if (this.isCadreDeBloc && this.levelOfAccess === 4) {
        this.types = ["Paramédical"]
      } else {
        this.subTypes = ['IADE']
      }
    } else {
      if (this.isRespIade) {
        this.types = ["Anesthésistes"]
        this.subTypes = ['IADE']
      } else if (this.isCadreDeBloc && this.levelOfAccess === 4) {
        this.types = ["Paramédical"]
      } else if ((this.isRespAnesth && !this.isRespInterne)) {
        this.types = ["Anesthésistes"]
        this.subTypes = ['Sénior/Junior', 'Interne', 'IADE']
      } else if (this.isRespInterne) {
        this.types = ["Anesthésistes"]
        this.subTypes = ['Interne']
      } else {
        this.subTypes = this.data.isBloc ? ['Sénior/Junior', 'Interne', 'IADE'] : ['Sénior/Junior', 'Interne']
      }
    }
  }

  getRankingsSubscription() {
    if (this.updateRankingsSubscription) {
      this.updateRankingsSubscription.unsubscribe();
    }

    this.updateRankingsSubscription = this.roomsService.updateRankingsSmartPlanning.subscribe((rankings) => {
      this.nursesRankings = rankings.nursesRankings
      this.anesthsRankings = rankings.anesthsRankings
      this.onTypeChange();
    })
  }

  get profilesType(): string {
    if (this.types[this.selectedType] === "Anesthésistes") {
      return 'ANESTH';
    }
    return 'NURSE'
  }

  ngOnInit() {}

  onTypeChange() {
    this.subHeader = this.getSubHeader();
    this.selectedSubType = 0;
    if (this.types[this.selectedType] === "Anesthésistes") {
      if (!this.hospitalHaveAnesthDetailsOption) {
        this.subTypes = ['IADE']
      } else {
        if (this.isRespIade) {
          this.subTypes = ['IADE']
        } else {
          this.subTypes = this.data.isBloc ? ['Sénior/Junior', 'Interne', 'IADE'] : ['Sénior/Junior', 'Interne']
        }
      }
      this.initShownAnesths();
    } else {
      this.subTypes = this.data.roleData.filter((role) => role.name !== "IADE").map((role) => role.name)
      this.initShownNurses();
    }
  }

  onSubTypeChange() {
    if (this.types[this.selectedType] === "Anesthésistes") {
      this.initShownAnesths();
    } else {
      this.initShownNurses();
    }
  }

  getSubHeader() {
    if (this.data.isBloc) {
      const room = this.data.data.room;
      const selectedHospital = (this.userService.getSelectedHospitals())[0]
      if (this.types[this.selectedType] === "Anesthésistes") {
        const specialty = this.data.data.team.surgeons.filter((surgeon) => !surgeon.isFillingSurgeon)[0].profile.specialties.find((specialty) => String(specialty.hospital) === String(selectedHospital))

        return `Salle ${room.roomNumber} - ${specialty.name}`;
      } else {
        const surgeons = this.data.data.team.surgeons
        const specialties = []
        surgeons.forEach(surgeon => {
          if (!surgeon.isFillingSurgeon) {
            const specialty = surgeon.profile.specialties.find((specialty) => String(specialty.hospital) === String(selectedHospital))
            specialties.push(specialty.name)
          }
        });

        return `Salle ${room.roomNumber} - ${specialties.join(", ")}`;
      }
    } else {
      if (this.data.isExtra) {
        return 'Extraclinique'
      } else {
        const specialty = this.data.data.specialty

        return `Consultation - ${specialty.name}`;
      }
    }
  }

  initShownNurses(): void {
    this.availableProfiles = []
    this.absentTitulairesProfiles = []
    this.absentVacatairesProfiles = []
    this.hasMoreProfilesAvailable= false
    this.currentPageProfilesAvailable=[]
    this.currentPageAvailable=0
    this.hasMoreProfilesAbsentTitulaire= false
    this.currentPageProfilesAbsentTitulaire=[]
    this.currentPageAbsentTitulaire=0
    this.hasMoreProfilesAbsentVacataire= false
    this.currentPageProfilesAbsentVacataire=[]
    this.currentPageAbsentVacataire=0

    if (!this.nursesRankings) {
      return;
    }

    let arrayToConsider;

    const roomObject = this.nursesRankings[String(this.data.data.roomId)];

    if (!roomObject) {
      return;
    }

    const roleData = roomObject.find((d) => String(d.role.name) === this.subTypes[this.selectedSubType])
    if (roleData) {
      const data = JSON.parse(JSON.stringify(roleData))
      if (data) {
        arrayToConsider = data.scores
      }
    }

    if (!arrayToConsider) {
      this.availableProfiles = []
      this.absentTitulairesProfiles = []
      this.absentVacatairesProfiles = []
      return;
    }

    this.availableProfiles = arrayToConsider
      .filter((elt) => elt.calendar && elt.calendar.reason.availability === 'AVAILABLE' && !this.data.data.team.others.map((card) => card.profile._id).includes(String(elt.profile._id)))

    this.alreadyAssigned = [];
    this.data.rooms.forEach((room: roomFinalData) => {
      this.alreadyAssigned = this.alreadyAssigned.concat(room.team.others.map(card => card.profile._id));
    });

    this.absentTitulairesProfiles = arrayToConsider
      .filter((elt) => elt.profile.residency === 'Titulaire' && (!elt.calendar || elt.calendar.reason.availability === 'ABSENT'))
      .map((elt) => { return { ...elt, isOpeningVacation: false } });

    this.absentVacatairesProfiles = arrayToConsider
      .filter((elt) => elt.profile.residency === 'Vacataire' && (!elt.calendar || elt.calendar.reason.availability === 'ABSENT'))
      .map((elt) => { return { ...elt, isOpeningVacation: false } });
    this.updateCurrentPageProfiles("available");
    this.updateCurrentPageProfiles("absentTitulaire");
    this.updateCurrentPageProfiles("absentVacataire");

  }

  initShownAnesths(): void {
    this.availableProfiles = []
    this.absentTitulairesProfiles = []
    this.absentVacatairesProfiles = []
    this.hasMoreProfilesAvailable= false
    this.currentPageProfilesAvailable=[]
    this.currentPageAvailable=0
    this.hasMoreProfilesAbsentTitulaire= false
    this.currentPageProfilesAbsentTitulaire=[]
    this.currentPageAbsentTitulaire=0
    this.hasMoreProfilesAbsentVacataire= false
    this.currentPageProfilesAbsentVacataire=[]
    this.currentPageAbsentVacataire=0

    if (!this.anesthsRankings) {
      return;
    }

    let arrayToConsider: any[];

    if (this.subTypes[this.selectedSubType] === "Sénior/Junior") {
      if (!this.anesthsRankings) {
        return;
      }
      arrayToConsider = this.anesthsRankings.seniorsJuniorsRanks;
    } else if (this.subTypes[this.selectedSubType] === "Interne") {
      if (!this.anesthsRankings) {
        return;
      }
      arrayToConsider = this.anesthsRankings.internesRanks;
    } else {
      if (!this.nursesRankings) {
        return;
      }
      const roomObject = this.nursesRankings[String(this.data.data.roomId)];
      if (!roomObject) {
        return;
      }

      const roleData = roomObject.find((d) => String(d.role.name) === "IADE")
      if (roleData) {
        const data = JSON.parse(JSON.stringify(roleData))
        if (data) {
          arrayToConsider = data.scores
        }
      }

      if (!arrayToConsider) {
        this.availableProfiles = []
        this.absentTitulairesProfiles = []
        this.absentVacatairesProfiles = []
        return;
      }

      this.availableProfiles = arrayToConsider
        .filter((elt) => elt.calendar && elt.calendar.reason.availability === 'AVAILABLE' && !this.data.data.team.others.map((card) => card.profile._id).includes(String(elt.profile._id)))
        .slice(0, 3);

      this.absentTitulairesProfiles = arrayToConsider
        .filter((elt) => elt.profile.residency === 'Titulaire' && (!elt.calendar || elt.calendar.reason.availability === 'ABSENT'))
        .slice(0, 3)
        .map((elt) => { return { ...elt, isOpeningVacation: false } });

      this.absentVacatairesProfiles = arrayToConsider
        .filter((elt) => elt.profile.residency === 'Vacataire' && (!elt.calendar || elt.calendar.reason.availability === 'ABSENT'))
        .slice(0, 3)
        .map((elt) => { return { ...elt, isOpeningVacation: false } });
      return;
    }

    if (!arrayToConsider) {
      this.availableProfiles = []
      this.absentTitulairesProfiles = []
      this.absentVacatairesProfiles = []
      return;
    }

    const object = arrayToConsider.find((elt) => {
      if (this.data.isBloc) {
        return elt.type === 'bloc' && elt.rooms && elt.rooms.includes(this.data.data.roomId);
      } else if (!this.data.isExtra) {
        return elt.type === 'consultation' && elt.specialties && elt.specialties.some((e) => e.specialty === this.data.data.specialty._id);
      } else {
        // Extraclinique
        return elt.type === 'bloc' && elt.specialties && elt.specialties.some((e) => e.specialty === this.data.data.specialty._id);
      }
    });

    if (!object) {
      return;
    }

    let alreadyAssignedAnesths = []

    this.data.rooms.forEach((room: roomFinalData) => {
      alreadyAssignedAnesths = alreadyAssignedAnesths.concat(room.team.others.map((card) => card.profile._id))
    });

    this.data.consultExtraData.forEach((consultExtraData: consultationExtracliniqueData) => {
      alreadyAssignedAnesths = alreadyAssignedAnesths.concat(consultExtraData.team.map((card) => card.profile._id))
    });

    this.availableProfiles = object.profiles
      .filter((elt) => elt.calendar && elt.calendar.reason.availability === 'AVAILABLE');

    this.alreadyAssigned = this.availableProfiles
      .filter((elt) => alreadyAssignedAnesths.includes(String(elt.profile._id)))
      .map(elt => elt.profile._id);

    this.absentTitulairesProfiles = object.profiles
      .filter((elt) => elt.profile.residency === 'Titulaire' && (!elt.calendar || elt.calendar.reason.availability === 'ABSENT'))
      .map((elt) => { return { ...elt, isOpeningVacation: false } });

    this.absentVacatairesProfiles = object.profiles
      .filter((elt) => elt.profile.residency === 'Vacataire' && (!elt.calendar || elt.calendar.reason.availability === 'ABSENT'))
      .map((elt) => { return { ...elt, isOpeningVacation: false } });
    this.updateCurrentPageProfiles("available");
    this.updateCurrentPageProfiles("absentTitulaire");
    this.updateCurrentPageProfiles("absentVacataire");
  }

  goToMessagerie(profile) {
    if (profile.position === 'Chirurgien')
      return ;
    this.navigateTo(profile);
  }

  updateCurrentPageProfiles(profilesAvailability: string): void {
    if (profilesAvailability === "available"){
      const startIndex = this.currentPageAvailable * this.itemsPerPage;
      this.currentPageProfilesAvailable = this.availableProfiles.slice(startIndex, startIndex + this.itemsPerPage);
      this.hasMoreProfilesAvailable = (startIndex + this.itemsPerPage) < this.availableProfiles.length;
    }
    else{
      if(profilesAvailability === "absentTitulaire"){
        const startIndex = this.currentPageAbsentTitulaire * this.itemsPerPage;
        this.currentPageProfilesAbsentTitulaire = this.absentTitulairesProfiles.slice(startIndex, startIndex + this.itemsPerPage);
        this.hasMoreProfilesAbsentTitulaire = (startIndex + this.itemsPerPage) < this.absentTitulairesProfiles.length;
      }
      else{
        const startIndex = this.currentPageAbsentVacataire * this.itemsPerPage;
        this.currentPageProfilesAbsentVacataire = this.absentVacatairesProfiles.slice(startIndex, startIndex + this.itemsPerPage);
        this.hasMoreProfilesAbsentVacataire = (startIndex + this.itemsPerPage) < this.absentVacatairesProfiles.length;
      }
    }

  }

  showNextProfiles(profilesAvailability: string): void {
    if (profilesAvailability === "available"){
      if (this.hasMoreProfilesAvailable) {
        this.currentPageAvailable++;
        this.updateCurrentPageProfiles(profilesAvailability);
      }
    }
    else{
      if(profilesAvailability === "absentTitulaire"){
        if (this.hasMoreProfilesAbsentTitulaire) {
          this.currentPageAbsentTitulaire++;
          this.updateCurrentPageProfiles(profilesAvailability);
        }

      }
      else{
        if (this.hasMoreProfilesAbsentVacataire) {
          this.currentPageAbsentVacataire++;
          this.updateCurrentPageProfiles(profilesAvailability);
        }
      }
    }

  }

  showPreviousProfiles(profilesAvailability: string): void {
    if (profilesAvailability === "available"){
      if (this.currentPageAvailable > 0 ) {
        this.currentPageAvailable--;
        this.updateCurrentPageProfiles(profilesAvailability);
      }
    }
    else{
      if(profilesAvailability === "absentTitulaire"){
        if (this.currentPageAbsentTitulaire > 0 ) {
          this.currentPageAbsentTitulaire--;
          this.updateCurrentPageProfiles(profilesAvailability);
        }

      }
      else{
        if (this.currentPageAbsentVacataire > 0 ) {
          this.currentPageAbsentVacataire--;
          this.updateCurrentPageProfiles(profilesAvailability);
        }
      }
    }

  }


  openTargetedVacation(item): void {
    item.isOpeningVacation = true;
    var type: string;
    var vacation;
    if (this.profilesType === 'NURSE') {
      type = this.data.roleData[this.selectedSubType]._id;
      vacation = this.createNurseBlocVacations(type, item);
    } else {
      if (this.subHeader.includes('Consultation')) {
        type = this.subHeader.substring (0,13).split(' ')[0];
        vacation = this.createConsultationVacation(type,item);
      }
      else {
        type = 'Bloc'
        vacation = this.createNurseBlocVacations(type,item);
      }
    }

    if (this.profilesType === 'NURSE') {
      vacation.vacation.position = 'IBODE / IDE / AS';
    } else {
      vacation.vacation.position = ANESTHETIST;
      vacation.vacation.seniority = ['Senior', 'Junior', 'Interne'];
    }

    if(this.addVacationSubscription) this.addVacationSubscription.unsubscribe();
    this.addVacationSubscription = this.vacationService.addVacation(vacation)
      .subscribe((response) => {
        item.isOpeningVacation = false;
        this.toastService.infoToast('Operation prise en charge');
      });
  }

  navigateTo(data){
    this.close();
    this.navigationService.navigateTo('/messagerie', null, {profile: JSON.stringify(data)}, false);
  }

  onScroll(event: any): void {

  }

  onProfileClick(profile: { profile: Profile }): void {
    if (this.profilesType !== 'ANESTH' && this.alreadyAssigned.includes(profile.profile._id)) {
      return;
    }
    if (this.profilesType === 'NURSE') {
      this.dialogRef.close({profile: profile.profile, role: this.data.roleData[this.selectedSubType]});
    } else {
      this.dialogRef.close(profile.profile);
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  createNurseBlocVacations(type: string, item: any){
    var specialty = this.getNurseBlosSpecialties();
    return(
      {
        vacation: {
          hospital: this.data.data.room.hospital._id,
          needs: 1,
          voluntaries: [],
          chosenOnes: [],
          dateofcreation: moment().format("YYYY-MM-DD"),
          period: 'Journée',
          date: moment(this.data.date).format("YYYY-MM-DD"),
          residency: 'Titulaire et Vacataire',
          state: "OPENED",
          position: undefined,
          seniority: undefined,
          individuals: [String(item.profile._id)],
          informations: {
            type: type,
            specialty
          }
        },
        message: {
          text: "(Vacation supprimée)",
        },
      }
    )

  }

  createConsultationVacation(type: string, item:any){
    return {
      vacation: {
        hospital: this.data.data.room.hospital._id,
        needs: 1,
        voluntaries: [],
        chosenOnes: [],
        dateofcreation: moment().format("YYYY-MM-DD"),
        period: 'Journée',
        date: moment(this.data.date).format("YYYY-MM-DD"),
        residency: 'Titulaire et Vacataire',
        state: "OPENED",
        position: undefined,
        seniority: undefined,
        individuals: [String(item.profile._id)],
        informations: {
          type: type,
          specialty: this.data.data.specialty
        }
      },
      message: {
        text: "(Vacation supprimée)",
      },
    }
  }

  getNurseBlosSpecialties(): any[] {
    const room = this.data.data.room;
    const specialtiesObjects = [];
    const specialties = [];

    if (room.programs && room.programs.length > 0) {
      room.programs.forEach((p) => {
        let specialty;

        if (p.surgeon) {
          if (p.surgeon.specialties && p.surgeon.specialties.length > 0) {
            const surgeonSpecialty = p.surgeon.specialties.find((specialty) => String(specialty.hospital) === String(room.hospital._id));

            if (surgeonSpecialty) {
              specialty = surgeonSpecialty;
            }
          }
        }

        if (specialty) {
          specialtiesObjects.push(specialty);

        }
      });
    } else if (room.surgeons) {
      room.surgeons.forEach((s) => {
        let specialty;
        if (s.specialties && s.specialties.length > 0) {
          const surgeonSpecialty = s.specialties.find((specialty) => String(specialty.hospital) === String(room.hospital._id));

          if (surgeonSpecialty) {
            specialty = surgeonSpecialty;
          }
        }

        if (specialty) {
          specialtiesObjects.push(specialty);
        }
      });
    } else if (room.surgeon) {
      let specialty;
      if (room.surgeon.specialties && room.surgeon.specialties.length > 0) {
        const surgeonSpecialty = room.surgeon.specialties.find((specialty) => String(specialty.hospital) === String(room.hospital._id));

        if (surgeonSpecialty) {
          specialty = surgeonSpecialty;
        }
      }

      if (specialty) {
        specialtiesObjects.push(specialty);
      }
    }

    specialtiesObjects.forEach((specialty) => {
      if (specialty && !specialties.includes(specialty)) {
        specialties.push(specialty);
      }
    })

    if (specialties.length > 0 ) {
      return specialties;
    }
  }

  ngOnDestroy(): void {
    if (this.updateRankingsSubscription) {
      this.updateRankingsSubscription.unsubscribe();
    }

    if (this.addVacationSubscription) {
      this.addVacationSubscription.unsubscribe();
    }
  }
}
