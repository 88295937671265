import { Component, OnInit } from '@angular/core';
import { Profile } from '../../../shared/models/profile.model';
import { Subscription, throwError } from 'rxjs';
import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { StorageService } from 'src/app/core/services/storage.service';
import { User } from '../../models/user.model';
import { UserService } from '../../services/user.service';
import { ErrorService } from '../../services/error.service';
import { ProfileService } from '../../services/profile.service';
import { ToastService } from '../../services/toast.service';
import { NURSE, ANESTHETIST, SURGEON } from 'src/app/shared/const/glabals.const';
import { UtilisService } from './../../services/utilis.service';
import {PopupManagerService} from "../../services/popup-manager.service";

@Component({
  selector: 'app-profiles-list',
  templateUrl: './profiles-list.component.html',
  styleUrls: ['./profiles-list.component.scss']
})
export class ProfilesListComponent implements OnInit {

  profilesList: Profile[];
  anasthColumns = ['profile', 'actif', 'deleteProfile'];
  public levelOfAccess: number = -1;
  updateSub: Subscription;
  public updating: boolean;
  public isChanged: boolean;
  public isInternResponsible: boolean;

  profileActif: boolean[];
  actifIsLoading: boolean[];
  softDeleteProfileSubscription: Subscription;
  softRestoreProfileSubscription: Subscription;
  title: string;
  deleteProfileSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<ProfilesListComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private storageService: StorageService,
    private userService: UserService,
    private errorService: ErrorService,
    private profileService: ProfileService,
    private toastService: ToastService,
    private UtilisService: UtilisService,
    private dialog: MatDialog,
    private popupManagerService: PopupManagerService
  ) {
    this.isInternResponsible = this.userService.isInternResponsible();
    dialogRef.backdropClick().subscribe(() => this.close())

    if (!data || !data.data)
      return
    this.profilesList = data.data.sort((a, b) => {
      if (a.user && !b.user) return -1;
      if (b.user && !a.user) return 1;
    });
  }

  initializeActifColumn() {
    this.actifIsLoading = Array(this.data.data.length).fill(false);

    const selectedHospitalsId = this.userService.getSelectedHospitals()

    // this.profileActif = this.data.data.map(profile => profile.deletedAt === null)

    this.profileActif = this.data.data.map(profile => {
      if (!profile.desactivatedAt || profile.desactivatedAt === null || profile.desactivatedAt.length === 0) {
        return true;
      } else if (profile.desactivatedAt && !profile.desactivatedAt.some(entry => entry === selectedHospitalsId[0])) {
        return true;
      }
      return false;
    });
  }

  ngOnInit() {
    this.setTitle()
    this.initializeActifColumn();
    this.getLevelOfAccess();
  }

  setTitle() {
    switch (this.data.profilePosition) {
      case "Iade": this.title = "Liste des Iade"; break
      case NURSE: this.title = "Liste des Ibode / Ide / AS"; break
      case ANESTHETIST: this.title = "Liste des Anesthésistes / Iade"; break
      case SURGEON: this.title = "Liste des Chirurgiens"; break
      default: this.title = "Liste des Ibode / Ide / AS"

    }
  }

  close() {
    this.dialogRef.close(this.isChanged);
  }

  updateLevelOfAccess(level: number, user: User) {
    this.updating = true;
    this.updateSub.unsubscribe();
    this.updateSub = this.userService.updateLevelOfAccess(level, user._id).subscribe(res => {
      if (res) user.levelOfAccess = level;
      this.updating = false;
    }, error => {
      this.updating = false;
      throwError(error)
    });
  }

  changeProfileState(state: boolean, profile: Profile, index: number) {
    this.isChanged = true;
    if (state) {
      this.softRestoreProfile(profile, index)
    } else {
      this.softDeleteProfile(profile, index)
    }
  }

  softDeleteProfile(profile: Profile, index: number): void {
    this.actifIsLoading[index] = true;
    this.softDeleteProfileSubscription = this.profileService.softDeleteProfile(profile._id).subscribe((res) => {
      this.actifIsLoading[index] = false;
    }, error => this.errorService.handleError(error));
  }

  softRestoreProfile(profile: Profile, index: number): void {
    this.actifIsLoading[index] = true;
    this.softRestoreProfileSubscription = this.profileService.softRestoreProfile(profile._id).subscribe((res) => {
      this.actifIsLoading[index] = false;
    }, error => this.errorService.handleError(error));
  }

  displayLevelOfAccess(levelOfAccess) {
    if (levelOfAccess) {
      switch (levelOfAccess) {
        case 1:
          return "Collaborateur limité";
        case 2:
          return "Collaborateur";
        case 3:
          return "Responsable planning";
        case 4:
          return "Référent hôpital";
        case 5:
          return "Administrateur";
      }
    }
    return "-";
  }

  checkIfAllowed() {
    if (!this.data.isEdit) {
      this.toastService.displayToast("Vous n'avez pas accès à cette opération", 10000, true)
    }
  }

  getLevelOfAccess() {
    this.levelOfAccess = this.storageService.getUser().levelOfAccess;
  }

  get levels() {
    return [1, 2, 3, 4].filter(level => this.levelOfAccess > level);
  }


  openDeleteConfirmation(profile): void {
    const description = `Etes vous sûr(e) de supprimer ce profil ? Le profil sera inaccessible dans les services de SmartOp et ne pourra plus être récupéré. Vous pouvez désactiver temporairement le profil depuis l'onglet Configuration.`;

    this.popupManagerService.openConfirmationPopup('Supprimer ce profil ?', description, 0, 'danger', 'Oui, supprimer').afterClosed().subscribe(result => {
      if (result) {
        this.deleteProfile(profile);
      }
    });
  }

  deleteProfile(profile): void {
    this.deleteProfileSubscription = this.profileService.deleteProfile(profile._id).subscribe(() => {
      this.removeProfile(profile)
      this.UtilisService.displaySuccessToast()
      this.isChanged = true;
    }, error => this.errorService.handleError(error));
  }

  removeProfile(deletedProfile) {
    const profilesCloned = [...this.profilesList]
    this.profilesList = []
    this.profilesList = profilesCloned.filter(profile => profile._id != deletedProfile._id)
  }

  ngOnDestroy() {
    //if (this.updateSub) this.updateSub.unsubscribe();
    if (this.softDeleteProfileSubscription) this.softDeleteProfileSubscription.unsubscribe();
    if (this.softRestoreProfileSubscription) this.softRestoreProfileSubscription.unsubscribe();
  }

}

