import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retryWhen, scan, delay } from 'rxjs/operators';

@Injectable()
export class RetryInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        retryWhen(errors =>
          errors.pipe(
            scan((acc, error) => {
              if (acc >= 2) {
                throw error;
              }
              return acc + 1;
            }, 0),
            delay(500)
          )
        )
      );
  }
}