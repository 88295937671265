import {Component, Inject, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import { Subject, Subscription, forkJoin } from "rxjs";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {InterventionPopupComponent} from '../intervention-popup/intervention-popup.component';
import * as moment from 'moment/moment';
import * as lodash from 'lodash';
import {Room} from '../../models/room.model';
import {Intervention} from '../../models/intervention.model';
import {InterventionService} from '../../services/intervention.service';
import {ErrorService} from '../../services/error.service';
import { DoctorAgenda } from '../../models/doctor-agenda.model';
import { setOverflowDatasColors, getRateColor, calculateOverflowRate } from '../../utils/cross-functions';
import { Role } from "src/app/shared/models/role.model";
import { RoomService } from '../../services/room.service'
import { PythonAPIService } from '../../services/pythonApi.service';
import { ParamedicalService } from "src/app/shared/services/paramedical.service";
import {Profile} from '../../models/profile.model';
import { ProfileService } from "src/app/shared/services/profile.service";
import { takeUntil } from 'rxjs/operators';

export interface ProfileInterface {
  firstName: string;
  lastName: string;
  // Add other properties as needed
}
@Component({
  selector: 'app-intervention-details-popup',
  templateUrl: './intervention-details-popup.component.html',
  styleUrls: ['./intervention-details-popup.component.scss']
})

export class InterventionDetailsPopupComponent implements OnInit, OnDestroy {

  public data: any;
  public savedData: any;
  public room: Room;
  public intervention: Intervention;
  public sterileMaterial: any;
  public isLoading: boolean;
  public mustRefresh: boolean;
  public overflowColor: string;
  public rooms: any[];
  public updatedIntervention: DoctorAgenda;
  public estimatedEndTime: any;
  public estimatedEndTimeMs: any;
  private isCadreDeBloc: boolean = false;
  private getInterventionsSubscription: Subscription;
  private getInterventionOverflowDataSubscription: Subscription;
  // private roles: Role[];

  // private rolesSubscription : Subscription;

  constructor(
    public errorService: ErrorService,
    public roomService: RoomService,
    public pythonApisService : PythonAPIService,
    public interventionService: InterventionService,
    private paramedicalService: ParamedicalService,
    private profileService: ProfileService,
    
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<InterventionDetailsPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public datas: any,
    ) {
    this.room = datas.room;
    this.rooms = datas.rooms;
    this.isCadreDeBloc = datas.isCadreDeBloc;
    this.init(datas.modifiedDoctorAgendas);
  }

  async ngOnInit() {

    this.getIntervention();
    if (this.datas.showRealizedMode) {
      await this.getRoles();
      await this.getNurses();
      await this.getAnesth();
    }
  }

  init(data) {
    this.savedData = lodash.cloneDeep(data);
    this.data = lodash.cloneDeep(data);
    this.data.date = moment(this.data.date).format('DD/MM/YYYY');
    this.data.startTime = this.formatHours(this.data.startTime);
    this.data.endTime = this.formatHours(this.data.endTime);
    if(this.getInterventionOverflowDataSubscription) this.getInterventionOverflowDataSubscription.unsubscribe();

    if (!this.datas.showRealizedMode){
      this.getInterventionOverflowDataSubscription = this.pythonApisService.getInterventionOverflowData(this.datas.modifiedDoctorAgendas._id).subscribe((estimatedDuration: any) => {
          const startTimeMilliseconds = this.formatHoursToMilliseconds(this.data.startTime);
          const endTimeMilliseconds = this.formatHoursToMilliseconds(this.data.endTime);
          this.estimatedEndTimeMs = startTimeMilliseconds + estimatedDuration.totalEstimatedDuration;
          this.estimatedEndTime = this.formatHours(this.estimatedEndTimeMs);
          
          const overflowDuration = this.estimatedEndTimeMs - endTimeMilliseconds;
          const plannedDuration = endTimeMilliseconds - startTimeMilliseconds;
          const overflowRate = calculateOverflowRate(plannedDuration, overflowDuration, 1); 
          this.overflowColor = getRateColor(overflowRate);
      });
    }
  }

  formatHoursToMilliseconds(timeString: string): number {
    const [hoursString, minutesString] = timeString.split(' h ');
    const hours = parseInt(hoursString, 10);
    const minutes = parseInt(minutesString, 10);
    const totalTimeMilliseconds = hours * 60 * 60 * 1000 + minutes * 60 * 1000;
  
    return totalTimeMilliseconds;
  }

  getIntervention(): void {
    this.isLoading = true;
    const surgeonID = this.data.surgeon ? this.data.surgeon._id : '';
    if(this.getInterventionsSubscription) this.getInterventionsSubscription.unsubscribe();
    this.getInterventionsSubscription = this.interventionService.getAllInterventions('', surgeonID)
      .subscribe((interventions: any) => {
        if (interventions) {
          this.intervention = interventions.docs.find( (intervention: Intervention) => intervention.act === this.data.interventionType);
          if (this.intervention && this.intervention.drapage.length !== 0) {
            this.intervention.drapage = this.intervention.drapage.filter(material => {
              return !!material.material;
            });
          }
          if (this.intervention && this.intervention.fermeture.length !== 0) {
            this.intervention.fermeture = this.intervention.fermeture.filter(material => {
              return !!material.material;
            });
          }
          if (this.intervention && this.intervention.instrumentation.length !== 0) {
            this.intervention.instrumentation = this.intervention.instrumentation.filter(material => {
              return !!material.material;
            });
          }
          this.sterileMaterial = this.getSterileMaterial;
          this.isLoading = false;
        }
      }, error => this.errorService.handleError(error));
  }

  get getSterileMaterial() {
    let materials = [];
    if (this.intervention) {
      materials.push(...this.intervention.drapage.filter(material => {
        return material.material.isSterile && material.material;
      }));
      materials.push(...this.intervention.instrumentation.filter(material => {
        return material.material.isSterile && material.material;
      }));
      materials.push(...this.intervention.fermeture.filter(material => {
        return material.material.isSterile && material.material;
      }));

      materials = materials.filter((material, index, self) =>
          index === self.findIndex((t) => (
            t.material._id === material.material._id
          ))
      );
    }
    return materials;
  }

  async getRoles() {
    const rolesIds = this.data.nurses.map((nurse) => nurse.role);
    const roles = await this.paramedicalService.getRolesById(rolesIds).toPromise();

    this.data.nurses.forEach((nurse) => {
      roles.forEach((role)=> {
        if (role._id === nurse.role) {
          nurse.roleName = role.name;
        }
      })
    })
  }

  async getNurses() {
    const profilePromises = this.data.nurses.map(nurse => 
      this.profileService.getProfileById(nurse.profile).toPromise()
    );

    const profiles = await Promise.all(profilePromises) as ProfileInterface[];

    this.data.nurses.forEach((nurse, index) => {
      const profile = profiles[index];
      if (profile) {
        nurse.finalFirstName = profile.firstName;
        nurse.finalLastName = profile.lastName;
      }
    });
  }

  async getAnesth() {
    const profile = await this.profileService.getProfileById(this.data.anesthesists[0]).toPromise();

      if (profile) {
        this.data.anesthFinalFirstName = profile.firstName;
        this.data.anesthFinalLastName = profile.lastName;
      }
  }

  getDoubleDidgetValue(value: number): string {
    return ('0' + value).slice(-2);
  }

  formatHours(date) {
    const d = new Date(date);
    d.setTime(d.getTime() + d.getTimezoneOffset() * 60 * 1000);
    return `${this.getDoubleDidgetValue(d.getHours())} h ${this.getDoubleDidgetValue(d.getMinutes())}`;
  }

  onEdit() {
    const dialogRef = this.dialog.open(InterventionPopupComponent, {
      width: "550px",
      data: {
        type: 'EDIT',
        roomId: this.room._id,
        room: this.room,
        rooms: this.rooms,
        data: this.savedData,
        date: new Date(this.savedData.date),
        frontEndDoctorAgendas: this.datas.frontEndDoctorAgendas
      },
    });
    dialogRef.afterClosed().subscribe((obj) => {
      if (obj && obj.status === 'refresh') {
        this.savedData.admissionType = obj.intervention.admissionType;
        this.savedData.allergies = obj.intervention.allergies;
        this.savedData.anesthType = obj.intervention.anesthType;
        this.savedData.date = new Date(obj.intervention.date).toISOString();
        this.savedData.startTime = new Date(obj.intervention.startTime).toISOString();
        this.savedData.endTime = new Date(obj.intervention.endTime).toISOString();
        this.savedData.interventionType = obj.intervention.interventionType;
        this.savedData.patientAge = obj.intervention.patientAge;
        this.savedData.patientAllergy = obj.intervention.patientAllergy;
        this.savedData.patientGender = obj.intervention.patientGender;
        this.savedData.room = obj.intervention.room;
        this.savedData.surgeon = obj.intervention.surgeon;
        this.room = this.rooms.find((elt) => String(elt.room._id) === String(obj.intervention.room)).room;
        this.init(this.savedData);
        this.updatedIntervention = this.savedData;
        this.mustRefresh = true;
      }
    });
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    if (this.mustRefresh) {
      this.dialogRef.close({ status: 'refresh', intervention: this.updatedIntervention });
    }

    if(this.getInterventionsSubscription) {
      this.getInterventionsSubscription.unsubscribe();
    }

    if (this.getInterventionOverflowDataSubscription) {
      this.getInterventionOverflowDataSubscription.unsubscribe();
    }
  }
}
