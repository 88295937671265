import {Injectable} from '@angular/core';

import {ToastService} from "./toast.service";

@Injectable()
export class ErrorService {

  constructor(private toastService: ToastService) {
  }

  handleError(error: any): void {
    try {
      let tipMessage: string = 'app/unknown-error';
      if (error && error.name === 'HttpErrorResponse' && !error.status) {
        tipMessage = 'app/network-error';
      } else if (error) {
        if (error.error) {
          error = error.error;
        }
        if (error.code) {
          tipMessage = error.code.toString();
        }
      }
      tipMessage = tipMessage.substr(0, 500);
      this.toast(tipMessage);
    } catch (e) {
      console.error(e);
    }
  }

  simpleErrorMsg(msg: string) {
    this.toast(msg, 7000);
  }

  private toast(tipMessage: string, time?: number): void {
    this.toastService.errorToast(tipMessage, time);
  }

}
