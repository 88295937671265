import { Injectable } from "@angular/core";
import { BufferProgramDal } from "../dal/buffer-program.dal";
import { BufferProgram } from "../models/buffer-program.model";
import { DyasStatus, Period } from "../models/period.model";
import { Observable, of } from "rxjs";
import { Room } from "../models/room.model";
import { roomFinalData } from "../components/day-program-recap/day-program-interfaces";

@Injectable({
  providedIn: "root",
})
export class BufferProgramService {

  constructor(private bufferProgramDal: BufferProgramDal) {}

  getSurgeonPrograms(fromDate: string, toDate: string, profileId: string) {
    return this.bufferProgramDal.getSurgeonPrograms(
      fromDate,
      toDate,
      profileId
    );
  }

  getRoomPrograms(date: string, roomId: string): Observable<any> {
    return this.bufferProgramDal.getRoomPrograms(date, roomId);
  }

  checkExistenceOfBufferProgramsFromTo(startDate, endDate, concernedRooms): Observable<any> {
    return this.bufferProgramDal.checkExistenceOfBufferProgramsFromTo(startDate, endDate, concernedRooms);
  }

  getExtraclinique(profileId: string, date: string): Observable<any> {
    return this.bufferProgramDal.getExtraclinique(profileId, date);
  }
  // Unused
  // getWorkingDays(monday: string): Observable<any> {
  //   return this.bufferProgramDal.getWorkingDays(monday);
  // }

  // INFO: Unused Route
  // getWeekStatus(date: string): Observable<any> {
  //   return this.bufferProgramDal.getWeekStatus(date);
  // }

  getRoomBufferPrograms(room?: string, day?: string, period?: Period) {
    return this.bufferProgramDal.getAllBufferPrograms(room, day, period);
  }

  // getNurseBufferPrograms(
  //   idNurse: string,
  //   period: Period
  // ): Observable<BufferProgram[]> {
  //   return this.bufferProgramDal.getNurseBufferPrograms(idNurse, period);
  // }

  getAllBufferPrograms(room?: string, day?: string, period?: Period) {
    return this.bufferProgramDal.getAllBufferPrograms(room, day, period);
  }

  getAllRoomsBufferPrograms(day: string) {
    return this.bufferProgramDal.getAllRoomsBufferPrograms(day);
  }

  getAllRoomsBufferProgramsFromTo(startDate: string, endDate: string) {
    return this.bufferProgramDal.getAllRoomsBufferProgramsFromTo(startDate,endDate);
  }

  updateBufferProgram(
    programId: string,
    bufferProgram: BufferProgram
  ): Observable<BufferProgram> {
    return this.bufferProgramDal.updateBufferProgram(programId, bufferProgram);
  }

  addBufferProgram(date: string, bufferProgram: any): Observable<BufferProgram> {
    return this.bufferProgramDal.addBufferProgram(date, bufferProgram);
  }
  // Unused
  // getWeekDaysSatus(week): Observable<DyasStatus> {
  //   return this.bufferProgramDal.getWeekDaysSatus(week);
  // }

  updateManyBufferProgram(
    date: string,
    rooms: roomFinalData[],
    formattedBUffers?,
    urgenceProfile?,
  ): Observable<BufferProgram[]> {
    return this.bufferProgramDal.updateManyBufferProgram(
      date,
      rooms,
      formattedBUffers,
      urgenceProfile,
    );
  }

  deleteBufferProgram(idBuffer: string): Observable<BufferProgram> {
    return this.bufferProgramDal.deleteBufferProgram(idBuffer);
  }

  cleanRoom(roomId: String, day: string): Observable<boolean> {
    return this.bufferProgramDal.cleanRoom(roomId, day);
  }

  cleanAllRooms(hospitalId: string, day: string): Observable<boolean> {
    return this.bufferProgramDal.cleanAllRooms(hospitalId, day);
  }

  cleanRoomWithProg(roomId: String, day: string, prog: any): Observable<boolean> {
    return this.bufferProgramDal.cleanRoomWithProg(roomId, day, prog);
  }

  getPrograms(fromDate, toDate, profileId): Observable<any[]> {
    return this.bufferProgramDal.getPrograms(fromDate, toDate, profileId);
  }

  getDayPrograms(date: string, toDate: string, profileId: string): Observable<any> {
    return this.bufferProgramDal.getDayPrograms(date, toDate, profileId)
  }
}
