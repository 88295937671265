import {NgModule, Optional, SkipSelf} from '@angular/core';

import {NgxWebstorageModule} from 'ngx-webstorage';

import {StorageService} from './services/storage.service';
import {AuthenticationService} from './services/authentication.service';
import {NavigationService} from './services/navigation.service';
import {AuthenticationGuard} from './services/authentication.guard';
import {MultipleHospitalsGuard} from "./services/multiple-hospitals.guard"
import {HttpInterceptorService} from './services/http-interceptor.service';

import {SharedModule} from '../shared/shared.module';
import {HeaderService} from './services/header.service';
import { CanDeactivateGuard } from '../shared/services/can-deactivate.guard';
import { ChatGlobalSocket } from './services/chatGlobalSocket.service';
import { ChatService } from './services/chat.service';
import { SurgeonsGuard } from './services/surgeons.guard';
import { PasswordGuard } from '../shared/services/password.guard';
import { RetryInterceptor } from './services/retry-interceptor.service';

@NgModule({
  declarations: [],
  imports: [
    SharedModule,
    NgxWebstorageModule.forRoot(),
  ],
  providers: [
    AuthenticationService,
    NavigationService,
    AuthenticationGuard,
    MultipleHospitalsGuard,
    StorageService,
    HeaderService,
    CanDeactivateGuard,
    HttpInterceptorService,
    RetryInterceptor,
    ChatGlobalSocket,
    ChatService,
    SurgeonsGuard,
    PasswordGuard,
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only.');
    }
  }
}
