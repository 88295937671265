import { Component, OnInit } from '@angular/core';

import { FormBuilder } from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import { NavigationService } from 'src/app/core/services/navigation.service';
import { UserService } from '../../services/user.service';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { pages } from '../../config/pages';
import { StorageService } from 'src/app/core/services/storage.service';
import { UtilisService } from '../../services/utilis.service';

@Component({
  selector: 'app-exit-dialog',
  templateUrl: './exit-dialog.component.html',
  styleUrls: ['./exit-dialog.component.scss']
})
export class ExitDialogComponent implements OnInit {

  checkoutForm = this.formBuilder.group({
    password: '',
  });

  public authenticationError = false;
  public hide = true
  public disabled: boolean = false;

  constructor(
    private storageService: StorageService, 
    public dialogRef: MatDialogRef<ExitDialogComponent>,    
    private navigationService: NavigationService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private utilisService: UtilisService
    ) { 

    }

  public passwordErrorMessagesObject = {
    required: `Le mot de passe est obligatoire`,
  };

  ngOnInit() {
    
  }

  closeClick():  void {
    this.dialogRef.close("close");
  }

  exit() {
    const user: any = {
      email: this.userService.getCurrentUser().email,
      password: this.checkoutForm.value.password
  };
    this.disabled = true
    this.authenticationService.logIn(user).subscribe(() => {
      this.storageService.saveData("fullscreenActivated", false);
      this.dialogRef.close("exit");
      this.disabled = false;
      return true
    },
    (err) => {
      this.authenticationError = true;
      this.disabled = false;
      return false
    }
    );
  }
}
