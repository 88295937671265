import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import { ANESTHETIST } from '../../const/glabals.const';
import {Reason} from '../../models/reason.model';
import {Rule} from '../../models/rule.model';
import {ErrorService} from '../../services/error.service';
import { HospitalService } from '../../services/hospital.service';
import {RuleService} from '../../services/rule.service';
import {UserService} from '../../services/user.service';
import{ HOSPITAL_END_TIME,HOSPITAL_START_TIME,NURSE_MORNING_SHIFT_END_TIME,} from "../../const/glabals.const";
import { ReasonService } from '../../services/reason.service';
import { getFirstHospitalSelectedData } from '../../utils/cross-functions';

@Component({
  selector: 'app-rule-dialog',
  templateUrl: './add-rule-dialog.component.html',
  styleUrls: ['./add-rule-dialog.component.scss']
})
export class AddRuleDialogComponent implements OnInit, OnDestroy {

  public isSending: boolean;
  public formGroup: FormGroup;
  public toggle: boolean;
  public showseniority: boolean = false;
  public seniorityValid: boolean = true;

  public reasonsLoading: boolean;
  public reasons: Reason[];
  public reasonsWithoutRule: Reason[];

  public originalReasons: Reason[];
  public originalReasonsWithoutRule: Reason[];

  private addRuleSubscription: Subscription;
  private editRuleSubscription: Subscription;
  private formGroupChangeSubscription: Subscription;
  private allReasonsSubscription: Subscription;

  private hospitalStartTime :string;
  private hospitalEndTime :string ;
  private hospitalMiddleTime :string;

  private form: FormGroup;

  private date: Date;
  public postes = [
    {
      label: 'Anesthésiste',
      value: 'Anesthésiste',
      isChecked: false,
      isDisabled: false
    },
    {
      label: 'Iade', 
      value: 'Iade', 
      isChecked: false,
      isDisabled: false
    },
    {
      label: 'Infirmiere',
      value: 'Infirmiere',
      isChecked: false,
      isDisabled: false
    },
  ];
  public residencies = [
    {
      label: 'Vacataire',
      value: 'Vacataire',
      isChecked: false,
      isDisabled: false
    },
    {
      label: 'Titulaire',
      value: 'Titulaire',
      isChecked: false,
      isDisabled: false
    },
  ];

  public seniority = [
    {
      label: 'Sénior',
      value: 'Senior',
      isChecked: false,
      isDisabled: false
    },
    {
      label: 'Junior',
      value: 'Junior',
      isChecked: false,
      isDisabled: false
    },
    {
      label: 'Interne',
      value: 'Interne',
      isChecked: false,
      isDisabled: false
    },
  ];
  public daysOfTheWeek = [
    {
        label: 'Lun',
        value: 1,
        isChecked: false,
        isDisabled: false
    },
    {
        label: 'Mar',
        value: 2,
        isChecked: false,
        isDisabled: false
    },
    {
        label: 'Mer',
        value: 3,
        isChecked: false,
        isDisabled: false
    },
    {
        label: 'Jeu',
        value: 4,
        isChecked: false,
        isDisabled: false
    },
    {
        label: 'Ven',
        value: 5,
        isChecked: false,
        isDisabled: false
    },
    {
        label: 'Sam',
        value: 6,
        isChecked: false,
        isDisabled: false
    },
    {
        label: 'Dim',
        value: 0,
        isChecked: false,
        isDisabled: false
    },
];

  constructor(
    public hospitalService: HospitalService,
    private ruleService: RuleService,
    private reasonService: ReasonService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private errorService: ErrorService,
    private dialogRef: MatDialogRef<AddRuleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: 
    { mode: string,
      rule: Rule, 
      reasons: Reason[], 
      reasonsWithoutRule: Reason[],
      startDate: String             
    }
  ) {}

  ngOnInit() {
    if (this.data.mode !== 'edit') {
      this.initHospitalTime();
    } else {
      this.initEditTime(this.data.rule);
    }
    this.reasons = this.data.reasons;
    this.reasonsWithoutRule = this.data.reasonsWithoutRule

    if (this.data.mode === 'edit') {
      this.reasonsWithoutRule.push(this.data.rule.reason)
    }
    this.originalReasons = [...this.reasons];
    this.originalReasonsWithoutRule = [...this.reasonsWithoutRule];
    if (this.data.mode === 'edit') {
      this.reasons = this.reasons.filter(r => r._id !== this.data.rule.reason._id)
    }
    this.makeForm();

    this.formGroup.get('postes').valueChanges.subscribe((val) => {
      this.showSeniority(val)
    })
    this.formGroup.get('seniority').valueChanges.subscribe((val) => {
      this.actualiseSeniority(val)
    })

    this.showSeniority(this.formGroup.value.postes)
    this.actualiseSeniority(this.formGroup.value.seniority) 

    this.form = this.formBuilder.group({});
  }

  close(): void {
    this.dialogRef.close(false);
  }

  makeForm() {
    let afternoonEndTime: string;
    let morningStartTime: string;
    if (this.data.mode === 'edit') {
      const reason = this.setReason(this.data.rule.reason)
      const reasonFollowed = this.setReasonFollowed(this.data.rule.reasonFollowed)

      this.formGroup = this.formBuilder.group({
        reason: [reason, [Validators.required]],
        reasonFollowed: [reasonFollowed, [Validators.required]],
        days: [this.data.rule.days, [Validators.required]],
        postes: [this.data.rule.postes, [Validators.required]],
        seniority: [this.data.rule.seniority],
        residencies: [this.data.rule.residencies, [Validators.required]],
        morningStartTime: [
          morningStartTime ? morningStartTime : this.hospitalStartTime,
        
        ],
        afternoonEndTime: [
          afternoonEndTime ? afternoonEndTime : this.hospitalEndTime,
         
        ],
      });
      this.filterPostesAndResidencies();
    } else {
      this.formGroup = this.formBuilder.group({
        reason: [null, [Validators.required]],
        reasonFollowed: [null, [Validators.required]],
        days: [[], [Validators.required]],
        postes: [[], [Validators.required]],
        seniority: [[]],
        residencies: [[], [Validators.required]],
        morningStartTime: [
          morningStartTime ? morningStartTime : this.hospitalStartTime,
         
        ],
        afternoonEndTime: [
          afternoonEndTime ? afternoonEndTime : this.hospitalEndTime,
       
        ],
      });
    }
  }

  setReason(reason: Reason) {
    return this.data.reasonsWithoutRule.find(r => r._id === reason._id)
  }

  setReasonFollowed(reason: Reason) {
    return this.data.reasons.find(r => r._id === reason._id)
  }

  save() {
    let formValue = this.formGroup.value;
    this.addAdditionalTimeInfosToPayload(formValue).then(formValue => {
    if (this.data.mode === 'edit') {
      let rule = {
        _id: this.data.rule._id,
        ...formValue,
      }
      this.editRule(rule)
    } else {
      let rule = {
        ...formValue
      }
      this.addRule(rule)
    }
    })
    .catch(error => console.error(error));
  }

  showSeniority(val) {
    this.showseniority = val.includes(ANESTHETIST) && this.hospitalService.doesHospitalHaveAnesthDetailsOption()
    if (!this.formGroup.value.seniority)
      this.formGroup.value.seniority = []
    this.seniorityValid = !(this.showseniority && this.formGroup.value.seniority.length == 0)
  }

  actualiseSeniority(val) {
    if (!val)
      val = []
    this.seniorityValid = !(this.showseniority && val.length == 0)
  }

  addRule(rule) {
    this.isSending = true;
    if (!rule.postes.includes(ANESTHETIST))
      rule.seniority = []
    if(this.addRuleSubscription) this.addRuleSubscription.unsubscribe();
    this.addRuleSubscription = this.ruleService.addRule(rule).subscribe(res => {
      this.isSending = false;
      this.dialogRef.close(rule);
    }, error => this.errorService.handleError(error));
  }

  editRule(rule) {
    this.isSending = true;
    if(this.editRuleSubscription) this.editRuleSubscription.unsubscribe();
    this.editRuleSubscription = this.ruleService.editRule(rule).subscribe(res => {
      this.isSending = false;
      this.dialogRef.close(rule);
    }, error => this.errorService.handleError(error));
  }

  changeReason(reason) {
    if (!reason) {
      return;
    }
    this.formGroup.patchValue({
      postes: [],
      residencies: [],
    });

    let reasonFollowed = this.formGroup.controls.reasonFollowed.value

    this.postes = [
      {
        label: 'Anesthésiste',
        value: 'Anesthésiste',
        isChecked: false,
        isDisabled: false
      },
      {
        label: 'Iade', 
        value: 'Iade', 
        isChecked: false,
        isDisabled: false
      },
      {
        label: 'Infirmiere',
        value: 'Infirmiere',
        isChecked: false,
        isDisabled: false
      },
    ];

    this.seniority = [
      {
        label: 'Sénior',
        value: 'Senior',
        isChecked: false,
        isDisabled: false
      },
      {
        label: 'Junior',
        value: 'Junior',
        isChecked: false,
        isDisabled: false
      },
      {
        label: 'Interne',
        value: 'Interne',
        isChecked: false,
        isDisabled: false
      },
    ]

    this.residencies = [
      {
        label: 'Vacataire',
        value: 'Vacataire',
        isChecked: false,
        isDisabled: false
      },
      {
        label: 'Titulaire',
        value: 'Titulaire',
        isChecked: false,
        isDisabled: false
      },
    ];

    this.postes = this.postes.filter(p => reason.postes.includes(p.value) && ((reasonFollowed && reasonFollowed.postes.includes(p.value)) || !reasonFollowed))
    this.residencies = this.residencies.filter(r => reason.residencies.includes(r.value) && ((reasonFollowed && reasonFollowed.residencies.includes(r.value)) || !reasonFollowed))
    this.reasons = this.originalReasons.filter(r => r._id !== reason._id);

    if (!reasonFollowed) {
      setTimeout(() => {
        this.formGroup.controls.reasonFollowed.patchValue(null);
      }, 1);
    }
  }

  filterPostesAndResidencies(): void {
    const reason = this.formGroup.controls.reason.value;
    const reasonFollowed = this.formGroup.controls.reasonFollowed.value;
    
    this.postes = [
      {
        label: 'Anesthésiste',
        value: 'Anesthésiste',
        isChecked: false,
        isDisabled: false
      },
      {
        label: 'Iade', 
        value: 'Iade', 
        isChecked: false,
        isDisabled: false
      },
      {
        label: 'Infirmiere',
        value: 'Infirmiere',
        isChecked: false,
        isDisabled: false
      },
    ];

    this.residencies = [
      {
        label: 'Vacataire',
        value: 'Vacataire',
        isChecked: false,
        isDisabled: false
      },
      {
        label: 'Titulaire',
        value: 'Titulaire',
        isChecked: false,
        isDisabled: false
      },
    ];

    this.postes = this.postes.filter(p => reason.postes.includes(p.value) && ((reasonFollowed && reasonFollowed.postes.includes(p.value)) || !reasonFollowed))
    this.residencies = this.residencies.filter(r => reason.residencies.includes(r.value) && ((reasonFollowed && reasonFollowed.residencies.includes(r.value)) || !reasonFollowed))
  }

  changeReasonFollowed(reasonFollowed) {
    if (!reasonFollowed) {
      return;
    }
    this.formGroup.patchValue({
      postes: [],
      residencies: []
    });
    const reason = this.formGroup.controls.reason.value;

    this.postes = [
      {
        label: 'Anesthésiste',
        value: 'Anesthésiste',
        isChecked: false,
        isDisabled: false
      },
      {
        label: 'Iade', 
        value: 'Iade', 
        isChecked: false,
        isDisabled: false
      },
      {
        label: 'Infirmiere',
        value: 'Infirmiere',
        isChecked: false,
        isDisabled: false
      },
    ];

    this.residencies = [
      {
        label: 'Vacataire',
        value: 'Vacataire',
        isChecked: false,
        isDisabled: false
      },
      {
        label: 'Titulaire',
        value: 'Titulaire',
        isChecked: false,
        isDisabled: false
      },
    ];
    this.postes = this.postes.filter(p => reasonFollowed.postes.includes(p.value) && ((reason && reason.postes.includes(p.value)) || !reason))
    this.residencies = this.residencies.filter(r => reasonFollowed.residencies.includes(r.value) && ((reason && reason.residencies.includes(r.value)) || !reason))
    this.reasonsWithoutRule = this.originalReasonsWithoutRule.filter(r => r._id !== reasonFollowed._id)

    if (!reason) {
      setTimeout(() => {
        this.formGroup.controls.reason.patchValue(null);
      }, 1);
    }
  }

  getStartTime(): string {
    return this.formGroup.controls.morningStartTime.value;
  }

  getEndTime(): string {
    return this.formGroup.controls.afternoonEndTime.value;
  }

  setStartTime(time: string): void {
    this.formGroup.controls.morningStartTime.patchValue(time);
  }

  setEndTime(time: string): void {
    this.formGroup.controls.afternoonEndTime.patchValue(time);
  }

  generateDate(time: string, _date?: Date): Date {
    let date = new Date(_date);
    const splitTime = time.split(":");
    date.setUTCHours(+splitTime[0]);
    date.setUTCMinutes(+splitTime[1]);
    return date;
  }

  addAdditionalTimeInfosToPayload(formValue:any){
    const date = new Date();
    this.hospitalMiddleTime ? this.hospitalMiddleTime : this.hospitalMiddleTime=NURSE_MORNING_SHIFT_END_TIME
    formValue.morningEndTime = this.setSecondesToZeros(this.generateDate(
      this.hospitalMiddleTime,
      date
    ));
    formValue.afternoonStartTime = this.setSecondesToZeros(this.generateDate(
      this.hospitalMiddleTime,
      date
    ));
    formValue.afternoonEndTime = this.setSecondesToZeros(this.generateDate(formValue.afternoonEndTime,date));
    formValue.morningStartTime = this.setSecondesToZeros(this.generateDate(formValue.morningStartTime,date));
    const adjustedArray = this.adjustTimeRanges(
      new Date(formValue.morningStartTime),
      new Date(formValue.morningEndTime),
      new Date(formValue.afternoonStartTime),
      new Date(formValue.afternoonEndTime)
    );
    formValue.morningStartTime = adjustedArray[0];
    formValue.morningEndTime = adjustedArray[1];
    formValue.afternoonStartTime = adjustedArray[2];
    formValue.afternoonEndTime = adjustedArray[3];

    return Promise.resolve(formValue);

  }

  initHospitalTime(): void {
    const hospital = getFirstHospitalSelectedData(this.userService.getCurrentUser(), this.userService.getSelectedHospitals())
    if (!hospital) {
      this.hospitalStartTime = HOSPITAL_START_TIME;
      this.hospitalEndTime = HOSPITAL_END_TIME;
    } else {
      this.hospitalStartTime = `${(new Date(hospital.startTime)).getUTCHours()}:${(new Date(hospital.startTime)).getUTCMinutes()}`;
      this.hospitalEndTime = `${(new Date(hospital.endTime)).getUTCHours()}:${(new Date(hospital.endTime)).getUTCMinutes()}`;
      this.hospitalMiddleTime = `${(new Date(hospital.middleTime)).getUTCHours()}:${(new Date(hospital.middleTime)).getUTCMinutes()}`;
    }
  }

  initEditTime(rule:any): void {
    this.hospitalStartTime = rule.morningStartTime;
    this.hospitalEndTime = rule.afternoonEndTime;
  }

  setSecondesToZeros(time:Date){
    let timeObject = new Date(time);
    timeObject.setSeconds(0);
    timeObject.setMilliseconds(0);
    return timeObject.toISOString();
  }

  adjustTimeRanges(morningStartTime:Date,
    morningEndTime:Date, 
    afternoonStartTime:Date, 
    afternoonEndTime:Date) {
    if (morningStartTime.getTime() >= morningEndTime.getTime()) {
      morningStartTime = new Date(morningEndTime.getTime());
    }
  
    if (afternoonStartTime.getTime() >= afternoonEndTime.getTime()) {
      afternoonStartTime = new Date(afternoonEndTime.getTime());
    }
  
    if (morningEndTime.getTime() >= afternoonEndTime.getTime()) {
      morningEndTime = new Date(afternoonEndTime.getTime());
    }

    if(morningEndTime.getTime()>=afternoonStartTime.getTime()){
      morningEndTime = new Date(afternoonStartTime.getTime());
    }
    return [morningStartTime, morningEndTime, afternoonStartTime, afternoonEndTime];
  }
  
  ngOnDestroy() {
    if (this.addRuleSubscription) {
      this.addRuleSubscription.unsubscribe()
    }

    if (this.editRuleSubscription) {
      this.editRuleSubscription.unsubscribe()
    }

    if (this.formGroupChangeSubscription) {
      this.formGroupChangeSubscription.unsubscribe()
    }

    if (this.allReasonsSubscription) {
      this.allReasonsSubscription.unsubscribe()
    }
  }

}
