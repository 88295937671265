import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';

import {urlEndPoints} from '../config/end-points';
import {Room} from '../models/room.model';
import {Specialty} from '../models/specialty.model';

@Injectable()
export class AutofillingDal {

  constructor(private httpClient: HttpClient) {
  }

  autoFillingByInterval(
    startDate: string, 
    endDate: string, 
    seniority: string, 
    autofillingTypes: string[], 
    roomsToKeep: string[], 
    profilesToKeep: string[]
  ): Observable<any[]> {
    return this.httpClient.post<any[]>(`${urlEndPoints.autofilling}${seniority}/${startDate}/${endDate}`, {
      autofillingTypes,
      roomsToKeep,
      profilesToKeep
    });
  }

  getAnesthsRanking(date: string): Observable<any> {
    return this.httpClient.get<any>(`${urlEndPoints.autofilling}ranks/${date}`);
  }
}
