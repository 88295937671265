import {Profile} from '../../models/profile.model';
import {Component, Input, OnChanges, OnInit, SimpleChanges, HostListener, Output, EventEmitter, ChangeDetectorRef} from '@angular/core';
import {Specialty} from '../../models/specialty.model';

import * as moment from 'moment';
import {SURGEON, NURSE_TYPES, ANESTHETIST, IADE} from '../../const/glabals.const';
import { HospitalService } from '../../services/hospital.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { UserService } from '../../services/user.service';
import { getFirstHospitalSelectedData } from 'src/app/shared/utils/cross-functions';
import { Paramedical } from '../../models/paramedical.model';
import { Role } from '../../models/role.model';
import { ParamedicalService } from '../../services/paramedical.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-smart-op-avatar',
  templateUrl: './smart-op-avatar.component.html',
  styleUrls: ['./smart-op-avatar.component.scss']
})
export class SmartOpAvatarComponent implements OnInit, OnChanges {
  @Input() hoverHighlight : boolean;
  @Input() showImage: boolean = true;
  @Input() profile: Profile;
  @Input() hidePosition: boolean = false;
  @Input() displayMode: string = 'row';
  @Input() avatarBoxColor: string = 'primary';
  @Input() badge?: string | number;
  @Input() startTime?: string | Date;
  @Input() endTime?: string | Date;
  @Input() showArrival?: boolean = false;
  @Input() showSeniority?: boolean = true;
  @Input() isGuard: boolean = false;
  @Input() viewFlex;
  @Input() alignSpecialty: boolean = false;
  @Input() hideSpecialty: boolean = false;
  @Input() hideResendency: boolean = false;
  @Input() isSurgeonSelection: boolean = false;
  @Input() isBigRow: boolean = false;
  @Input() isSideNav: boolean = false;
  @Input() smallAvatar: boolean = false;
  @Input() smallSpecialty: boolean = false;
  @Input() smallName: boolean = false;
  @Input() tinyMode: boolean = false;
  @Input() isSmartPlanningDesign: boolean = false;
  @Input() isPictureRight: boolean = false;
  @Input() onlyResidency: boolean = false;
  @Input() showTooltip: boolean = false;
  @Input() hideHospital: boolean = false;
  @Input() programRecap: boolean = false;
  @Input() isAnnuaire: boolean = false;
  @Input() isImportProfile: boolean = false;
  @Input() isAvatarOfTab: boolean = false;
  @Input() isAccountView: boolean = false;
  @Input() isStatopView: boolean = false;
  @Input() isIbodeView: boolean = false;
  @Input() isMyProgramView: boolean = false;
  @Input() hospitalSelectedInSurgeonTabStatop: any;
  @Input() paramedicals: Paramedical[];
  @Input() roles: Role[]
  @Input() role: Role
  @Input() isHome: boolean = false;
  @Input() pictureBorder = false;

  @Output() onChangeRole = new EventEmitter<any>();
  @Input() nameOverFlow: boolean = false;

  // only right is coded and used at the moment
  @Input() hoursPosition: string = 'right';
  // hoursLayout: 'row' | 'column'
  @Input() hoursLayout: string = 'row';

  // Disable bigScreen on some pages
  @Input() disableBigScreen: boolean = true;

  public picture: string;
  public isBigScreen: boolean = true;
  public isMiddleScreen: boolean = false;
  public isToMuchZoomScreen: boolean = false;
  public isAnestProfile: boolean = false;
  public isSurgeonProfile: boolean = false;
  public availableRoles: Role[] = [];
  public canEditRoles: boolean = false;
  private getDroppedNurse: Subscription;
  private getUserConfigurationsSubscription: Subscription;

  public selectedHospitalInSoloMode;
  specialty: Specialty;
  isSurgeon: boolean;

  private isMultiHospital: boolean = false;

  constructor(
    private hostpitalService: HospitalService,
    private storageService: StorageService,
    private userService: UserService,
    private changeDetection: ChangeDetectorRef,
    ) {
    this.onResize();
  }

  @HostListener("window:resize", [])
  private onResize() {
    var seuilToMuchZoomScreen = 1300;
    if (window.innerWidth <= seuilToMuchZoomScreen)
    {
      this.isToMuchZoomScreen = true;
    }
    else
    {
      this.isToMuchZoomScreen = false;
    }
    var seuilDisableBigScreen = this.isAvatarOfTab ? 1760 : 750;
    var seuildisableMiddleScreen = this.isAvatarOfTab ? 2000 : 950;
    var seuilBigScreen = this.isAvatarOfTab ? 1500 : 950;
    var seuilMiddleScreen = this.isAvatarOfTab ? 2000 : 1050;
    if (this.isAnestProfile || (this.isSurgeonProfile && this.profile && this.profile.firstName && this.profile.lastName && this.getSize2Name(this.profile.firstName, this.profile.lastName) < 15))
    {
      var seuilDisableBigScreen = seuilDisableBigScreen - 100;
      var seuildisableMiddleScreen = seuildisableMiddleScreen - 200;
      var seuilBigScreen = seuilBigScreen - 200;
      var seuilMiddleScreen = seuilMiddleScreen - 200;
    }
    else if(!this.isAnestProfile && !this.isSurgeonProfile && this.profile && this.profile.firstName && this.profile.lastName && this.getSize2Name(this.profile.firstName, this.profile.lastName) < 15)
    {
      var seuilDisableBigScreen = seuilDisableBigScreen - 65;
      var seuildisableMiddleScreen = seuildisableMiddleScreen - 65;
      var seuilBigScreen = seuilBigScreen - 65;
      var seuilMiddleScreen = seuilMiddleScreen - 65;
    }


    if (this.disableBigScreen) {
      this.isBigScreen = true;
    } else {
      if(this.showSeniority){
        if (window.innerWidth < seuilDisableBigScreen) {
          this.isBigScreen = false;
        } else {
          this.isBigScreen = true;
          if(window.innerWidth < seuildisableMiddleScreen){
            this.isMiddleScreen = true;
          } else {
            this.isMiddleScreen = false;
          }
        }
      } else {
        if (window.innerWidth < seuilBigScreen) {
          this.isBigScreen = false;
        } else {
          this.isBigScreen = true;
          if(window.innerWidth < seuilMiddleScreen){
            this.isMiddleScreen = true;
          } else {
            this.isMiddleScreen = false;
          }
        }
      }
    }
  }

  getSize2Name(name1: string, name2: string) {
    if (name1 && name2){
    return name1.length + name2.length + 1;}
    else
    {
      return 0;
    }
  }

  ngOnInit(): void {   
    this.isMultiHospital = this.userService.isCurrentUserHasMultipleHospitalsSelected()
    this.getPosition();
    this.showSeniority = this.hostpitalService.doesHospitalHaveAnesthDetailsOption();
    if (this.profile) {
      this.isAnestProfile = this.profile.position === 'Anesthésiste';
      this.isSurgeonProfile =this.profile.position === 'Chirurgien';
      if (this.profile.startingDate) {
        this.profile.startingDate = this.profile.startingDate.substring(0, 10);
      }
      if (this.profile.profilePic && this.profile.profilePic !== '') {
        this.picture = this.profile.profilePic;
      }
      this.initPicture(this.profile.profilePic);
    }
    
    const currentUser = this.userService.getCurrentUser();
    this.selectedHospitalInSoloMode = getFirstHospitalSelectedData(currentUser, this.userService.getSelectedHospitals());
    this.getSpecialty();

    var seuilToMuchZoomScreen = 1300;
    if (window.innerWidth <= seuilToMuchZoomScreen)
    {
      this.isToMuchZoomScreen = true;
    }
    else
    {
      this.isToMuchZoomScreen = false;
    }
    var seuilDisableBigScreen = this.isAvatarOfTab ? 1760 : 900;
    var seuilMiddleScreen = this.isAvatarOfTab ? 2000 : 1100;

    if (this.isAnestProfile || (this.isSurgeonProfile && this.profile && this.getSize2Name(this.profile.firstName, this.profile.lastName) < 15))
    {
      var seuilDisableBigScreen = seuilDisableBigScreen - 100;
      var seuilMiddleScreen = seuilMiddleScreen - 200;
    }
    else if(!this.isAnestProfile && !this.isSurgeonProfile && this.profile && this.getSize2Name(this.profile.firstName, this.profile.lastName) < 15)
    {
      var seuilDisableBigScreen = seuilDisableBigScreen - 65;
      var seuilMiddleScreen = seuilMiddleScreen - 65;
    }
    if (this.disableBigScreen) {
      this.isBigScreen = true;
    } else {
      if (window.innerWidth < seuilDisableBigScreen) {
        this.isBigScreen = false;
      } else {
        this.isBigScreen = true;
        if(window.innerWidth < seuilMiddleScreen){
          this.isMiddleScreen = true;
        } else {
          this.isMiddleScreen = false;
        }
      }
    }
    if (this.programRecap && (this.isNurse || this.isIade) && !this.isHome) {
      // setTimeout(() => {
      // })
      this.setProfileRoles();
    }

    const isCadreBlocWithHighLevel = this.userService.isCadreBlocWithHighLevel();
    const isCadreBlocWithMediumLevel = this.userService.isCadreBlocWithMediumLevel();

    if (isCadreBlocWithHighLevel || isCadreBlocWithMediumLevel) {
      this.canEditRoles = true;
    }
  }

  ngAfterViewInit() {
    
  }

  setProfileRoles() {
    const paramedical = this.paramedicals ? this.paramedicals.find((paramedical) => paramedical.formation.name === this.profile.position) : null
    if (!paramedical) {
      if (this.roles) {
        this.availableRoles = this.roles
        if (!this.role) {
          // this.role = this.availableRoles[0]
          this.onChangeRole.emit(this.availableRoles[0]);
        }
      }
    } else {
      let roles:any = paramedical.roles.sort(function(a, b) {
        if (Number(a.priority) < Number(b.priority)) {
          return -1
        }
        if (Number(a.priority) > Number(b.priority)) {
          return 1
        }
        return 0
      })
      roles = roles.map((role) => role.roleId)
      this.availableRoles = roles;
      if (!this.role) {
        // this.role = this.availableRoles[0]
        this.onChangeRole.emit(this.availableRoles[0]);
      }
    }
  }

  formatUTCHours(date: Date): string {
    const d = new Date(date);
    let hours = `${d.getUTCHours()}`;
    let minutes = `${d.getUTCMinutes()}`;

    if (hours.length < 2) {
      hours = `0${hours}`;
    }

    if (minutes.length < 2) {
      minutes = `0${minutes}`;
    }

    return `${hours} h ${minutes}`;
  }

  isNurse(): boolean {
    if (this.profile.position && NURSE_TYPES.includes(this.profile.position)) {
      return true;
    }
    return false;
  }

  isIade(): boolean {
    if (IADE.includes(this.profile.position)) {
      return true;
    }
    return false;
  }

  getPosition() {
    if (this.profile) {
      this.isSurgeon = (this.profile.position === SURGEON);
    }
  }

  getSpecialty(): void {
    var selectedHospital = null;

    if (this.isStatopView && this.hospitalSelectedInSurgeonTabStatop) {
      selectedHospital = this.hospitalSelectedInSurgeonTabStatop;
    } else {
      selectedHospital = this.selectedHospitalInSoloMode;
    }

    if (this.profile && this.profile.specialties && Array.isArray(this.profile.specialties) && selectedHospital) {
      const filteredSpecialties = this.profile.specialties.find((specialty) => specialty && specialty.hospital && String(specialty.hospital) === String(selectedHospital._id));
      if (filteredSpecialties) {
        this.specialty = filteredSpecialties;
      }
    }
  }

  compareRole(a, b) {
    if (a && b) {
      return String(a._id) === String(b._id)
    }
    else {
      return false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && this.profile) {
      if (this.profile.profilePic && this.profile.profilePic !== '') {
        this.picture = this.profile.profilePic;
      }
      this.initPicture(this.profile.profilePic);
      this.getSpecialty();
      if (this.programRecap && (this.isNurse || this.isIade) && !this.isHome) {
        // setTimeout(() => {
        // })
        this.setProfileRoles();
      }
    }
  }

  onRoleChange(event) {
    this.onChangeRole.emit(event);
  }

  onAvatarError(): void {
    let picture: string;
    if (!this.isSurgeonSelection) {
      if (NURSE_TYPES.includes(this.profile.position)) {
        picture = 'assets/images/icons/nurse.svg';
      } else {
        switch (this.profile.position) {
          case 'Chirurgien':
            picture = 'assets/images/icons/surgeon.svg';
            break;
          case 'Anesthésiste':
            picture = 'assets/images/icons/f-doctor.svg';
            break;
          case 'Iade':
            picture = 'assets/images/icons/f-doctor.svg';
            break;
          case 'IADE':
            picture = 'assets/images/icons/f-doctor.svg';
            break;
          case 'Cadre de bloc':
            picture = 'assets/images/icons/surgeon.svg';
            break;
        }
      }
    } else {
      picture = 'assets/images/icons/surgeon.svg';
    }
    this.picture = picture;
  }

  getDateFormat(date){
    return moment(date).format("DD/MM/YYYY")
  }

  initPicture(image: string): void {
    if (!image) {
      this.onAvatarError();
    } else {
      this.picture = image
    }
  }

  get fxLayoutAlign() {
    return this.displayMode === 'row' ? 'start center' : 'center center';
  }

  get isColumn() {
    return this.displayMode === 'column';
  }

  get fullName(): any {
    const reverseNameOrder = this.storageService.getData("reverseOrderName")

    let firstName = this.profile.firstName || '';
    let lastName = this.profile.lastName || '';
    
    if (!this.isBigScreen) {
      if (reverseNameOrder === true && firstName !== "Dr") {
        return (lastName ? (lastName.substring(0, 3) + '. ') : '') + (firstName ? (firstName[0] + '.') : '');
      } else {
        return (firstName ? (firstName[0] + '. ') : '') + (lastName ? (lastName.substring(0, 3) + '.') : '');
      }
    } else {
      return (reverseNameOrder === true && firstName !== "Dr") ? `${lastName} ${firstName}` : `${firstName} ${lastName}`;
    }
      
  }

  getSeniority(seniority) {
    switch (seniority) {
      case 'Senior':
        return "Sénior"
      case 'Junior':
        return "Junior"
      case 'Interne':
        return "Interne"
      default:
        return "Undefined"
    }
  }

  truncatePositionForBigAndMiddleScreen(position) {
    if (!position) {
      return "";
    }
    var inputString = position;
    if (inputString.length <= 11) {
      return inputString;
    } else {
      return inputString.substring(0, 11) + "...";
    }
  }

  truncateSpecialtyForBigAndMiddleScreen(specialty) {
    if (!specialty)
    {
      return '';
    }
    var inputString = specialty.name;
    if (inputString.length <= 13) {
      return inputString;
    } else {
      if (specialty.diminutif)
      {
        return specialty.diminutif;
      }
      else{
      return inputString.substring(0, 13) + "...";}
    }
  }
  
  truncateSpecialtyForNotBigScreen(specialty) {
    if (!specialty)
    {
      return '';
    }
    var inputString = specialty.name;
    if (this.isToMuchZoomScreen)
    {
      if (specialty.diminutif)
      {
        return specialty.diminutif;
      }
      else
      {
      return '...';
    }
    }
    if (inputString.length <= 8) {
      return inputString;
    } else {
      return inputString.substring(0, 8) + "...";
    }
  }
}


export function onAvatarErrorFunction(profile: Profile): string {
  let picture: string;
  if (NURSE_TYPES.includes(profile.position)) {
    picture = 'assets/images/icons/nurse.svg';
  } else {
    switch (profile.position) {
      case 'Chirurgien':
        picture = 'assets/images/icons/surgeon.svg';
        break;
      case 'Anesthésiste':
        picture = 'assets/images/icons/f-doctor.svg';
        break;
      case 'Iade':
        picture = 'assets/images/icons/f-doctor.svg';
        break;
      case 'IADE':
        picture = 'assets/images/icons/f-doctor.svg';
        break;
      case 'Cadre de bloc':
        picture = 'assets/images/icons/surgeon.svg';
        break;
    }
  }
  picture = 'assets/images/icons/surgeon.svg';
  return picture;
}
