import { Profile } from 'src/app/shared/models/profile.model';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-box-exchange',
  templateUrl: './box-exchange.component.html',
  styleUrls: ['./box-exchange.component.scss']
})
export class BoxExchangeComponent implements OnInit {
  @Input() data: any
  @Input() profile2: Profile
  constructor() { }

  ngOnInit(): void {
  }

}
