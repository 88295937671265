import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';


@Pipe({
    name: 'dateToTime',
})
export class DateToTimePipe implements PipeTransform {
    transform(value: Date): any {
        return moment.utc(value).format('HH:mm');
    }
}
