import { Injectable } from '@angular/core';
import { anesthNeedsDAL } from '../dal/anesth-needs.dal';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class anesthNeedsService {

  constructor(private anesthNeedsDAL: anesthNeedsDAL) { }

  generateNeeds(TVOCycleId: string, concernedRooms, allRooms, startDate: string, endDate: string, generationType: string, generationMode: string): Observable<any[]> {
    return this.anesthNeedsDAL.generateNeeds(TVOCycleId, concernedRooms, allRooms, startDate, endDate, generationType, generationMode)
  }
}
