import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urlEndPoints } from '../config/end-points';
import { Reason } from '../models/reason.model';

@Injectable()

export class ReasonDal {

  constructor(private httpClient: HttpClient) {
  }

  getAllReasons(hospitalId?: string){
    return this.httpClient.get<any>(urlEndPoints.reasons + (hospitalId ? "?hospital=" + hospitalId : ""));
  }

  getDayReasons(hospital?: string) {
    return this.httpClient.get<any>(urlEndPoints.reasons + 'day' + (hospital ? "?hospital=" + hospital : ""));
  }

  getNightReasons(hospital?: string) {
    return this.httpClient.get<any>(urlEndPoints.reasons + 'gardes' + (hospital ? "?hospital=" + hospital : ""));
  }

  getReasonsWithoutRule(){
    return this.httpClient.get<any>(urlEndPoints.reasons + "noRules");
  }

  addReason(reason){
    return this.httpClient.post<any>(urlEndPoints.reasons , reason);
  }

  editReason(reason : Reason){
    return this.httpClient.put<any>(urlEndPoints.reasons + reason._id , reason);
  }

}