import {Component, Input, OnInit, Output, EventEmitter, SimpleChanges, OnDestroy, ChangeDetectorRef, Inject, SimpleChange, HostListener} from '@angular/core';
import {FormControl} from '@angular/forms';
import {DateAdapter, MAT_DATE_FORMATS, MatCalendar, MatDateFormats} from '@angular/material';
import {DatePickerMondayFirst} from '../../utils/date-picker-monday-first';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { ConfigurationService } from 'src/app/configuration/configuration.service';
import { DatePickerShowWeekService } from './date-picker-show-week.service';
import { ShowWeekCountHeaderComponent } from './show-week-count-header/show-week-count-header.component';

@Component({
  selector: 'app-date-picker-show-week',
  templateUrl: './date-picker-show-week.component.html',
  styleUrls: ['./date-picker-show-week.component.scss'],
  providers: [{ provide: DateAdapter, useClass: DatePickerMondayFirst }]
})
export class DatePickerShowWeekComponent implements OnInit {
    @Input() label: string = '';
    @Input() placeholder: string = '';
    @Input() isOnlyMonday: boolean = false;
    @Input() isOnlyFriday: boolean = false;
    @Input() formCtrl: FormControl;
    @Input() minDate: Date;
    @Input() maxDate: Date;

    @Output() dateChanged = new EventEmitter();

    constructor(private datePickerShowWeekService: DatePickerShowWeekService,) {}

    ngOnInit(): void {}
    header = ShowWeekCountHeaderComponent

    onlyMonday = (d: Date): boolean => {
        const day = d.getDay();
        return day === 1;
    };

    onlyFriday = (d: Date): boolean => {
        const day = d.getDay();
        return day === 0;
    };

    selectMonth(event) {
        this.datePickerShowWeekService.calendarSubject.next(event)
    }

    changeDay(event) {
        this.dateChanged.emit(event)
    }

}
