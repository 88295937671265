import {Component, Inject, OnInit, SimpleChanges} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, throwMatDialogContentAlreadyAttachedError} from '@angular/material/dialog';
import {DoctorAgenda} from '../../models/doctor-agenda.model';
import * as moment from 'moment';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Profile} from '../../models/profile.model';
import {Subscription} from 'rxjs';
import {ProfileService} from '../../services/profile.service';
import {ErrorService} from '../../services/error.service';
import {DoctorAgendasService} from '../../services/doctor-agendas.service';
import {RealProgramService} from '../../services/real-program.service';
import {StorageService} from '../../../core/services/storage.service';
import {User} from '../../models/user.model';
import {Intervention} from '../../models/intervention.model';
import {InterventionService} from '../../services/intervention.service';
import {INTERVENTION_END_TIME, INTERVENTION_START_TIME, SURGEON} from '../../const/glabals.const';
import {BLOCK_MANAGER} from '../../const/positions.const';
import {UtilisService} from 'src/app/shared/services/utilis.service';
import {Room} from '../../models/room.model';
import {UserService} from '../../services/user.service';
import {AllIntervention} from "../../models/all-intervention.model";
import {AllInterventionService} from "../../services/all-intervention.service";
import { v4 as uuidv4 } from 'uuid';
import { getFirstHospitalSelectedData } from '../../utils/cross-functions';

uuidv4()

@Component({
  selector: 'app-intervention-popup',
  templateUrl: './intervention-popup.component.html',
  styleUrls: ['./intervention-popup.component.scss']
})

export class InterventionPopupComponent implements OnInit {

  public date: moment.Moment;
  public searchResults: Profile[] = [];
  public isLoading = false;
  public formGroup: FormGroup;
  public hasEditPermission: boolean;
  public intervention: Intervention;
  private currentUser: User;
  private searchSpecSurgeonByNameSubscription: Subscription;
  private addInterventionSubscription: Subscription;
  private updateInterventionSubscription: Subscription;
  private checkIfPeriodIsAvailableSubscription: Subscription;
  public surgeon: Profile;
  public isIntervention = false;
  public isTimeAvailable = false;
  public SURGEON: string = SURGEON;
  public roomNumber: string;
  public interventionInfo: any;
  public isSpinning = false;
  public manualSave: boolean = false;
  private currentRoomData: any;

  public showPeriodIsNotAvailableMessage: boolean = false;
  public errorMessage: string = 'La période demandée est déjà occupée';
  private rooms : any[];

  public anesthType = [
    {
      label: 'AG',
      value: 'AG',
    },
    {
      label: 'ALR',
      value: 'ALR',
    }
  ];
  public admissionType = [
    {
      label: 'Ambu',
      value: 'Ambu',
    },
    {
      label: 'Hospi',
      value: 'Hospi',
    }
  ];

  public patientGender = [
    {
      label: 'Homme',
      value: 'Masculin',
    },
    {
      label: 'Femme',
      value: 'Féminin',
    }
  ];

  private roomHospitalId: string = ""
  public isMultiHospitals: boolean = false;

  actClick(intervention: AllIntervention) {
    this.formGroup.patchValue({interventionType: intervention.name});
    this.isIntervention = true;
  }

  get checkHospitalLimits() {
    if (this.formGroup && this.formGroup.value) {
      const startTime = this.formGroup.value.startTime;
      const endTime = this.formGroup.value.endTime;

      const hospital = getFirstHospitalSelectedData(this.userService.getCurrentUser(), this.userService.getSelectedHospitals())
      if (!hospital)
        return this.checkRoomLimits;
      const roomStartTime = new Date(hospital.startTime);
      const roomEndTime = new Date(hospital.endTime);

      const roomStartHour: number = roomStartTime.getUTCHours() - 1;
      let roomEndHour: number = roomEndTime.getUTCHours() + 1;
      roomEndHour = 6 < roomEndHour && roomEndHour < 23 ? roomEndHour : 23;

      const isLimits = (
        Number(startTime.split(':')[0]) >= roomStartHour &&
        Number(endTime.split(':')[0]) > roomStartHour &&
        Number(startTime.split(':')[0]) < roomEndHour &&
        (Number(endTime.split(':')[0]) < roomEndHour ||
          (
            (Number(endTime.split(':')[0]) === roomEndHour) &&
            (Number(endTime.split(':')[1]) === 0)
          )
        )
      );

      return isLimits;
    }
    return this.checkRoomLimits;
  }

  get checkRoomLimits() {
    if (this.formGroup && this.formGroup.value) {
      const startTime = this.formGroup.value.startTime;
      const endTime = this.formGroup.value.endTime;
      const date = new Date(this.data.date);
      let hourDay;
      if (this.data.room)
        hourDay = this.data.room.horaire.find(dayHours => dayHours.day === date.getUTCDay() + 1);
      if (!hourDay)
        return true;
      const roomStartTime = new Date(hourDay.start);
      const roomEndTime = new Date(hourDay.end);
      const roomStartHour: number = roomStartTime.getUTCHours() - 1;
      let roomEndHour: number = roomEndTime.getUTCHours() + 1;
      roomEndHour = 6 < roomEndHour && roomEndHour < 23 ? roomEndHour : 23;

      const isLimits = (
        Number(startTime.split(':')[0]) >= roomStartHour &&
        Number(endTime.split(':')[0]) > roomStartHour &&
        Number(startTime.split(':')[0]) < roomEndHour &&
        (Number(endTime.split(':')[0]) < roomEndHour ||
          (
            (Number(endTime.split(':')[0]) === roomEndHour) &&
            (Number(endTime.split(':')[1]) === 0)
          )
        )
      );
      return isLimits;
    }
    return false;
  }

  get selectedPeriodOK(): boolean {
    if (this.formGroup && this.formGroup.value) {
      const startTime = this.formGroup.value.startTime;
      const endTime = this.formGroup.value.endTime;
      return (
        Number(startTime.split(':')[0]) < Number(endTime.split(':')[0]) ||
        (Number(startTime.split(':')[0]) === Number(endTime.split(':')[0]) &&
          Number(startTime.split(':')[1]) < Number(endTime.split(':')[1])
        )
      );
    }
    return false;
  }

  checkIfTimeIsAvailable(): boolean {
    if (this.formGroup && this.formGroup.value) {
      const startTime = this.formGroup.value.startTime;
      const endTime = this.formGroup.value.endTime;
      const mStartTime = new Date(this.data.date);
      const mEndTime = new Date(this.data.date);

      mStartTime.setUTCHours(startTime.split(':')[0]);
      mStartTime.setUTCMinutes(startTime.split(':')[1]);
      mEndTime.setUTCHours(endTime.split(':')[0]);
      mEndTime.setUTCMinutes(endTime.split(':')[1]);
      return (
        this.checkIfRoomHoursAvailable(mStartTime, mEndTime) &&
        this.selectedPeriodOK &&
        this.checkHospitalLimits
      );
    }
  }

  constructor(public dialogRef: MatDialogRef<InterventionPopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private UtilisService: UtilisService,
              private formBuilder: FormBuilder,
              private profileService: ProfileService,
              private doctorAgendasService: DoctorAgendasService,
              private realProgramService: RealProgramService,
              private storageService: StorageService,
              private allInterventionService: AllInterventionService,
              private interventionService: InterventionService,
              private userService: UserService,
              private errorService: ErrorService) {
    this.date = moment(data.date).clone().utc();
    this.rooms = data.rooms;
    this.roomNumber = data.room.roomNumber;
    const room = this.rooms.find((room) => String(room.room._id) === String(this.data.roomId))
    this.roomHospitalId = room.room.hospital._id
    if (data.type === 'EDIT') {
      this.interventionInfo = data.data;
    } else {
      this.interventionInfo = {};
    }
    this.isMultiHospitals = this.userService.isCurrentUserHasMultipleHospitalsSelected()
  }

  ngOnInit() {
    var urlcourante = document.location.href; 
    if (urlcourante.endsWith("par-interventions")) {
      this.manualSave = true;
    }
    else {
      this.manualSave = false;
    }

    this.currentUser = this.storageService.getUser();
    this.hasEditPermission = (this.currentUser.levelOfAccess === 5 || this.currentUser.levelOfAccess === 4 && this.currentUser.profile.position === BLOCK_MANAGER);
    this.makeForm();
    this.isTimeAvailable = this.checkIfTimeIsAvailable();
    this.doFilter('');
  }

  checkIfRoomHoursAvailable(startTime: Date, endTime: Date): boolean {
    let endhour = this.getFormatLTS(endTime);
    let starthour = this.getFormatLTS(startTime);
    const agendas = this.data;

    for (let index = 0; index < agendas.length; index++) {
      const hostAgenda = agendas[index];
      const hostStartTime: string = this.getFormatLTS(hostAgenda.startTime);
      const hostEndTime: string = this.getFormatLTS(hostAgenda.endTime);
      if (
        (starthour < hostStartTime && hostStartTime < endhour) ||
        (starthour < hostEndTime && hostEndTime < endhour) ||
        (hostStartTime < starthour && starthour < hostEndTime) ||
        (hostStartTime < endhour && endhour < hostEndTime) ||
        (starthour === hostStartTime && endhour === hostEndTime)
      ) {
        return false;
      }
    }

    return true;
  }

  getFormatLTS(dateTime: string | Date): string {
    const date: Date = new Date(dateTime);
    let hours = date.getUTCHours() < 10 ? '0' + date.getUTCHours() : date.getUTCHours();
    let minutes = date.getUTCMinutes() < 10 ? '0' + date.getUTCMinutes() : date.getUTCMinutes();
    return hours + ':' + minutes
  }

  makeForm(): void {
    if (this.interventionInfo && this.interventionInfo.interventionType)
      this.isIntervention = true;
    this.surgeon = this.interventionInfo && this.interventionInfo.surgeon;
    this.formGroup = this.formBuilder.group({
      selectedRoom: [this.data.roomId, [Validators.required]],
      date: [{value: this.date.format(), disabled: false}, [Validators.required]],
      startTime: [this.getStartTime(), [Validators.required]],
      endTime: [this.getEndTime(), [Validators.required]],
      interventionType: [this.interventionInfo && this.interventionInfo.interventionType || '', Validators.required],
      patientGender: [this.interventionInfo && this.interventionInfo.patientGender, Validators.required],
      patientAge: [(this.interventionInfo && this.interventionInfo.type === 'ADD') ? '' : parseInt(this.interventionInfo && this.interventionInfo.patientAge), [Validators.required]],
      anesthType: [this.interventionInfo && this.interventionInfo.anesthType],
      admissionType: [this.interventionInfo && this.interventionInfo.admissionType, [Validators.required]],
      allergies: [this.interventionInfo && this.interventionInfo.patientAllergy],
    });
  }

  getEndTime(): string {
    if (this.data.type === 'ADD') {
      return INTERVENTION_END_TIME;
    } else {
      const d = new Date(this.interventionInfo.endTime);
      d.setTime(d.getTime() + d.getTimezoneOffset() * 60 * 1000);
      return `${this.getDoubleDidgetValue(d.getHours())}:${this.getDoubleDidgetValue(d.getMinutes())}`;
    }
  }

  getStartTime(): string {
    if (this.data.type === 'ADD') {
      return INTERVENTION_START_TIME;
    } else {
      const d = new Date(this.interventionInfo.startTime);
      d.setTime(d.getTime() + d.getTimezoneOffset() * 60 * 1000);
      return `${this.getDoubleDidgetValue(d.getHours())}:${this.getDoubleDidgetValue(d.getMinutes())}`;
    }
  }

  getDoubleDidgetValue(value: number): string {
    return ('0' + value).slice(-2);
  }

  setStartTime(event): void {
    this.formGroup.controls.startTime.setValue(event);
    this.isTimeAvailable = this.checkIfTimeIsAvailable();
  }

  setEndTime(event): void {
    this.formGroup.controls.endTime.setValue(event);
    this.isTimeAvailable = this.checkIfTimeIsAvailable();
  }

  async submitProfile() {
    const data: any = {};
    if (this.formGroup.valid) {
      this.isSpinning = true;
      data._id = this.interventionInfo ? this.interventionInfo._id : null;
      data.room = this.formGroup.value.selectedRoom;
      data.interventionType = this.formGroup.value.interventionType;
      data.patientGender = this.formGroup.value.patientGender;
      data.patientAge = Number(this.formGroup.value.patientAge);
      data.anesthType = this.formGroup.value.anesthType;
      data.admissionType = this.formGroup.value.admissionType;
      data.allergies = this.formGroup.value.allergies;
      data.patientAllergy = this.formGroup.value.allergies;
      data.hospital = this.roomHospitalId

      const startTime = this.formGroup.value.startTime;
      const endTime = this.formGroup.value.endTime;

      const tmp = moment(this.formGroup.value.date).toDate();
      data.date = new Date(Date.UTC(tmp.getFullYear(), tmp.getMonth(), tmp.getDate(), 0, 0, 0, 0));

      data.startTime = new Date(data.date);
      data.endTime = new Date(data.date);

      data.startTime.setUTCHours(startTime.split(':')[0]);
      data.startTime.setUTCMinutes(startTime.split(':')[1]);

      data.endTime.setUTCHours(endTime.split(':')[0]);
      data.endTime.setUTCMinutes(endTime.split(':')[1]);
      this.interventionInfo.surgeon = this.surgeon;

      if (this.manualSave) {
        data.surgeon = (this.interventionInfo.surgeon) ? this.interventionInfo.surgeon : null;
      } else {
        data.surgeon = (this.interventionInfo.surgeon) ? this.interventionInfo.surgeon._id : null;
      }

      data.room = this.formGroup.value.selectedRoom;

      // Check if period is available
      const isPeriodAvailable = await this.checkIfPeriodIsAvailable(data);
      this.isSpinning = false;
      
      if (isPeriodAvailable) {
        if (this.data.type !== 'ADD') {
          this.updateIntervention(this.interventionInfo._id, data);
        } else {
          this.addIntervention(data);
        }
      } else {
        this.showPeriodIsNotAvailableMessage = true;
      }
    }
  }

  async updateIntervention(interventionId: string, newIntervention) {
    if (this.manualSave) {
      newIntervention.surgeon = this.surgeon;
      this.dialogRef.close({status: 'refresh', intervention: newIntervention});
    } else {
      if(this.updateInterventionSubscription) this.updateInterventionSubscription.unsubscribe();
      this.updateInterventionSubscription = this.doctorAgendasService.updateIntervention(interventionId, newIntervention).subscribe((da) => {
        this.isSpinning = false;
        this.UtilisService.displaySuccessToast()
        da.surgeon = this.surgeon;
        this.dialogRef.close({status: 'refresh', intervention: da});
      }, error => this.errorService.handleError(error));
    }
  }

  checkIfPeriodIsAvailable(newIntervention): Promise<boolean> {
    return new Promise((resolve, reject) => {
      // resolve(false);
      if (new Date(newIntervention.date).getTime() === this.date.toDate().getTime()) {
        // If new time is in the same day

        const destinationRoomDoctorAgendas = this.rooms
          .find((elt) => String(elt.room._id) === String(newIntervention.room)).doctoragendas;

        resolve(this.checkIfPeriodIsAvailableInArrayOfDoctorAgendas(destinationRoomDoctorAgendas, newIntervention));
      } else {
        // If new time is not the same day
        // Check if period available in frontData
        
        const destinationDayFrontEndDoctorAgendas = this.data.frontEndDoctorAgendas
        .filter((elt) => new Date(elt.date).getTime() === new Date(newIntervention.date).getTime());
        
        const isPeriodAvailable = this.checkIfPeriodIsAvailableInArrayOfDoctorAgendas(destinationDayFrontEndDoctorAgendas, newIntervention);

        if (!isPeriodAvailable) {
          // if period is not available we show error message
          resolve(false);
        } else {
          // else we check in the backend
          if (this.checkIfPeriodIsAvailableSubscription) {
            this.checkIfPeriodIsAvailableSubscription.unsubscribe();
          }

          this.checkIfPeriodIsAvailableSubscription = this.doctorAgendasService.checkIfPeriodIsAvailable(
            String(newIntervention.room),
            new Date(newIntervention.startTime).toISOString(),
            new Date(newIntervention.endTime).toISOString(),
          )
            .subscribe((response) => {
              resolve(response.isPeriodAvailable);
            }, (error) => {
              reject(error);
              console.error(error);
            });
        }
      }
    });
  }

  checkIfPeriodIsAvailableInArrayOfDoctorAgendas(doctorAgendas: any[], newIntervention: any): boolean {
    const startTime = new Date(newIntervention.startTime);
    const endTime = new Date(newIntervention.endTime);

    for (const da of doctorAgendas) {
      const DAStartTime = new Date(da.startTime);
      const DAEndTime = new Date(da.endTime);

      if (String(da._id) !== String(newIntervention._id)) {
        if (DAStartTime <= startTime && DAEndTime > startTime) {
          return false;
        } else if (DAStartTime < endTime && DAEndTime >= endTime) {
          return false;
        } else if (DAStartTime >= startTime && DAEndTime <= endTime) {
          return false;
        }
      }
    }

    return true;
  }

  async changedRoom(): Promise<void> {
    await this.setCurrentRoomData();
  }

  async setCurrentRoomData(): Promise<void> {
    this.currentRoomData = this.rooms.find((e) => {
      if (e.room._id === this.formGroup.value.selectedRoom) {
        return e;
      }
    })
    this.roomHospitalId = this.currentRoomData.room.hospital._id
    this.surgeon = undefined
    this.formGroup.patchValue({interventionType: ''});
    this.isIntervention = false;
  }

  addIntervention(intervention: DoctorAgenda) {
    if (this.manualSave) {
      intervention._id = uuidv4();
      this.dialogRef.close(intervention);
      this.isSpinning = false;
      return
    }
    if (this.addInterventionSubscription) {
      this.addInterventionSubscription.unsubscribe();
    }
    this.addInterventionSubscription = this.doctorAgendasService.addIntervention(intervention).subscribe((newDA) => {
      this.isSpinning = false;
      this.UtilisService.displaySuccessToast()
      this.dialogRef.close('refresh');
    }, error => this.errorService.handleError(error));
  }


  doFilter(searchInput: string): void {
    this.isLoading = true;
    if (this.searchSpecSurgeonByNameSubscription) {
      this.searchSpecSurgeonByNameSubscription.unsubscribe();
    }
    this.searchSpecSurgeonByNameSubscription = this.profileService.getProfilesByPosition(SURGEON, 1, 150, searchInput, false, false, true).subscribe((results) => {
      this.isLoading = false;
      this.searchResults = results.docs;
    }, error => this.errorService.handleError(error));
  }

  profileChange(profile: Profile) {
    this.surgeon = profile;
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    if(this.updateInterventionSubscription) this.updateInterventionSubscription.unsubscribe();
    if(this.checkIfPeriodIsAvailableSubscription) this.checkIfPeriodIsAvailableSubscription.unsubscribe();
    if(this.addInterventionSubscription) this.addInterventionSubscription.unsubscribe();
    if(this.searchSpecSurgeonByNameSubscription) this.searchSpecSurgeonByNameSubscription.unsubscribe();
  }
}
