import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import * as moment from "moment";
import { ANESTHETIST, NURSE } from "../../const/glabals.const";
import { ProfileService } from "../../services/profile.service";
import { UserService } from "../../services/user.service";
import { UtilisService } from "./../../services/utilis.service";
import {ReadInPopupComponent} from '../read-in-popup/read-in-popup.component';
import {MatDialog} from '@angular/material';

@Component({
  selector: "app-vacataires-summary",
  templateUrl: "./vacataires-summary.component.html",
  styleUrls: ["./vacataires-summary.component.scss"],
})
export class VacatairesSummaryComponent implements OnChanges {
  position: string;
  @Input() originalView: string;
  @Input() startDate: string;
  @Input() endDate: string;

  title: string = "Récapitulatif des vacataires";
  pageSize = 5;
  vacatairesSummary = ([] = []);
  workedDays: string[] = [];
  vacatairesSummaryPaged: any[];
  isLoading : boolean;

  constructor(
    public dialog: MatDialog,
    private profileService: ProfileService,
    private utilService: UtilisService,
    private userService: UserService
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getPosition();
    this.getVacatairesSummary();
  }

  getPosition() {
    if (this.originalView == "ANE") {
      if (this.userService.isIadRes()) {
        this.position = "IADE";
      } else {
        this.position = ANESTHETIST;
      }
    } else {
      this.position = NURSE;
    }
  }

  getVacatairesSummary() {
    this.isLoading = true;
    const startDate: string = moment(this.startDate).format("YYYY-MM-DD");
    const endDate: string = moment(this.endDate).format("YYYY-MM-DD");
    this.profileService
      .getProfilesSummary(this.position, startDate, endDate)
      .subscribe((res) => {
        this.isLoading = false;
        this.vacatairesSummary = res;
      });
  }

  getWorkedDays() {
    if (this.vacatairesSummaryPaged instanceof Array) {
      this.workedDays = this.vacatairesSummaryPaged.map((vacataireSummary: any) => {
        let workedDay = "";
        vacataireSummary.workedDates.forEach(
          (workedDates) => (workedDay += `-${workedDates}\n`)
        );
        return workedDay;
      });
    }
  }

  pageSelectedHandler(event) {
    if (event instanceof Array) {
      setTimeout(() => {
        this.vacatairesSummaryPaged = [];
        this.vacatairesSummaryPaged = event;
        this.getWorkedDays()
      }, 100);
    }
  }
  
  openReadInPopup(header, title, content) {
    const closeRef = this.dialog.open(ReadInPopupComponent, {
      width: '500px',
      data: {
        header,
        title,
        content,
      }
    });
    closeRef.afterClosed().subscribe((result) => {

    });
  }
}
