import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RecurrenceDal } from '../dal/recurrence.dal';

@Injectable({
  providedIn: 'root'
})
export class RecurrencesService {

  constructor(private recurrenceDal: RecurrenceDal) { }

  getProfileRecurrences(profileId: string): Observable<any> {
    return this.recurrenceDal.getProfileRecurrences(profileId);
  }

  editProfileRecurrences(profileId: string, rules: any): Observable<any> {
    return this.recurrenceDal.editProfileRecurrences(profileId, rules);
  }
}
