import {AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ANESTHETIST, IADE, NURSE, ONLY_ANESTH, SURGEON} from '../../const/glabals.const';
import {Subscription} from 'rxjs';
import {ErrorService} from '../../services/error.service';
import {ProfileService} from '../../services/profile.service';
import {NavigationService} from '../../../core/services/navigation.service';
import {UserService} from 'src/app/shared/services/user.service';
import {Profile} from 'src/app/shared/models/profile.model';
import { BufferProgramService } from '../../services/buffer-program.service';
import { SwitchService } from '../../services/switch.service';

@Component({
  selector: 'app-search-bar-extraclinique',
  templateUrl: './search-bar-extraclinique.component.html',
  styleUrls: ['./search-bar-extraclinique.component.scss']
})

export class SearchBarExtracliniqueComponent implements OnInit, OnDestroy {
  @Input() fullWidth: boolean;
  @Input() emitProfileObject: boolean;
  @Input() isParent: boolean = false;
  @Input() type: string = NURSE;
  @Input() emitProfile: boolean;
  @Input() justTitular: boolean = false;
  @Input() noUrgences: boolean = false;
  @Input() isAllProfiles: boolean = true;
  @Input() onlyInterns: boolean = false;
  @Input() seniority: string;
  @Input() date: string;
  @Input() searchType: string;
  @Input() exchangeType: string;
  @Input() actualProfile: Profile;

  @Input() placeholder: string = 'Rechercher une personne ...';
  @Input() label: string = '';
  @Input() hintMessage: string = '';

  //iconType : 'image' / 'mat-icon'.
  @Input() iconType: string = 'mat-icon';

  //Icon : button icon : svg / mat-icon.
  @Input() icon: string = 'search';

  @Input() profile: Profile;

  @Output() onRouterChange = new EventEmitter<string>();
  @Output() onProfileSelected = new EventEmitter<any>();
  private searchSpecInfByNameSubscription: Subscription;

  public lastSearchValue: string = '';
  public isShowSearchResults: boolean = false;
  private savedData: any[];
  @ViewChild('search', null) searchElement: ElementRef;
  isLoading: boolean = false;
  spInfList: any[];
  public allProfiles: any[];
  class: any;

  public osComponentOptions = {
    className: 'os-theme-dark custom-sidebar',
    nativeScrollbarsOverlaid: {
      showNativeScrollbars: false
    },
    overflowBehavior: {
      x: 'hidden',
    },
    paddingAbsolute: true,
    scrollbars: {
      autoHide: 'never',
    },
  };


  constructor(
    private userService: UserService,
    private profileService: ProfileService,
    private errorService: ErrorService,
    private navigationService: NavigationService,
    private bufferProgramService: BufferProgramService,
    private SwitchService: SwitchService
  ) {
  }

  ngOnInit() {
    if (this.fullWidth) {
      this.class = 'width-100'
    }
    this.isLoading = true;

    if (this.exchangeType == "garde") {
      this.searchSpecInfByNameSubscription = this.SwitchService.getGardeCalendar(this.profile.position, this.profile._id, this.date)
            .subscribe((results) => {
              this.isLoading = false;
              results.forEach((ele) => {
                let month = (new Date(ele.date)).getMonth() + 1
                ele.date = (new Date(ele.date)).getDate() + "/" + month + "/" + (new Date(ele.date)).getFullYear()
                ele.period = null

                if (ele.date[1] == '/')
                  ele.date = "0" + ele.date
              
                if (ele.date[4] == '/')
                  ele.date = ele.date.substring(0, 3) + '0' + ele.date.substring(3)
                })

              this.allProfiles = results.filter(ele => {
                return (ele.profile._id != this.profile._id)
              })
              this.spInfList = this.allProfiles
              this.filterAndFormatProfiles(this.spInfList)
        }, error => this.errorService.handleError(error))
    }
    else {
      this.searchSpecInfByNameSubscription = this.bufferProgramService.getExtraclinique(this.profile._id, this.date)
            .subscribe((results) => {
              this.isLoading = false;
              results.forEach((ele) => {
                let month = (new Date(ele.date)).getMonth() + 1
                ele.date = (new Date(ele.date)).getDate() + "/" + month + "/" + (new Date(ele.date)).getFullYear()

                if (ele.date[1] == '/')
                  ele.date = "0" + ele.date
              
                if (ele.date[4] == '/')
                  ele.date = ele.date.substring(0, 3) + '0' + ele.date.substring(3)

                let start = new Date(ele.startTime)
                let end = new Date(ele.endTime)

                if (end.getHours() <= 13)
                  ele.period = "(Matin)"
                else if (start.getHours() >= 13)
                  ele.period = "(Après-midi)"
                else
                  ele.period = "(Journée)"
              })

              this.allProfiles = results.filter(ele => {
                return (ele.profile._id != this.profile._id)
              })
              this.spInfList = this.allProfiles
              this.filterAndFormatProfiles(this.spInfList)
        }, error => this.errorService.handleError(error))
    };

  }

  filterAndFormatProfiles(res) {
		if (res) {
      this.spInfList = res.sort((ele1, ele2) => {
        return ele1.date == ele2.date? ele1.profile.name <= ele2.profile.name : ele1.date < ele2.date
      })
      this.spInfList = this.spInfList.filter(ele => {
        return (ele.profile._id != this.profile._id)
      })
    }
  }

  onEnter(searchInput: string): void {
    this.lastSearchValue = searchInput;
    this.isLoading = true;
    this.filterProfiles(searchInput);
    this.isLoading = false;
  }

  filterProfiles(searchInput: string): void {
    if (!this.allProfiles ||this.allProfiles.length == 0)
      return;
    this.spInfList = this.allProfiles.filter((ele) => {
      let firstLast = `${ele.profile.firstName} ${ele.profile.lastName}`;
      let lastFirst = `${ele.profile.lastName} ${ele.profile.firstName}`;
      firstLast = firstLast.toLowerCase();
      lastFirst = lastFirst.toLowerCase();
      const searchInputLowerCase = searchInput.toLowerCase();
      if (firstLast.includes(searchInputLowerCase) || lastFirst.includes(searchInputLowerCase)) {
        return true
      }
    });
  }

  toggleSearchResults(event: any): void {
    event.stopPropagation();
    if (this.isShowSearchResults) {
      this.isShowSearchResults = !this.isShowSearchResults;
      this.searchElement.nativeElement.blur();
      this.hideInputText();
    } else {
      this.isShowSearchResults = !this.isShowSearchResults;
      this.searchElement.nativeElement.focus();
      this.showInputText();
    }
  }

  showSearchResults(event: any): void {
    if (event) {
      event.stopPropagation();
    }
    this.searchElement.nativeElement.focus();
    this.isShowSearchResults = true;
    this.searchElement.nativeElement.value = this.lastSearchValue;
  }

  hideInputText(): void {
    // we hide input text if we are not searching and we have a selected profile
    if (this.profile) {
      this.searchElement.nativeElement.value = '';
    }
  }

  showInputText(): void {
    // We show input text if we are searching
    this.searchElement.nativeElement.value = this.lastSearchValue;
  }


  profileClick(profile: Profile, event: any): void {
      this.onProfileSelected.emit(profile);
      return;

    let prefix;
    if (this.type === NURSE) {
      prefix = 'parcours-ibode/competence/competence-individuelle/';
    } else if (this.type === SURGEON) {
      prefix = 'statop/bloc/par-praticien/';
    } else {
      prefix = 'configuration/fiche-poste/';
    }

    this.navigationService.navigateByUrl(prefix + profile._id).then(() => {
      if (!this.isParent) {
        this.onRouterChange.emit('refresh');

      }
    }).catch();
  }

  @HostListener('window:click', ['$event'])
  click(event) {
    this.isShowSearchResults = false;
    this.hideInputText();
  }

  ngOnDestroy() {
    if (this.searchSpecInfByNameSubscription) {
      this.searchSpecInfByNameSubscription.unsubscribe();
    }
  }
}