import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';

import {urlEndPoints} from '../config/end-points';
import {Room} from '../models/room.model';
import {Specialty} from '../models/specialty.model';
import { roomWithDoctorAgendas, roomWithSOAndSOH } from '../interfaces/room.interfaces';
import { dailyNeeds } from '../models/anesthNeed.model';
import { BufferProgram } from '../models/buffer-program.model';

@Injectable()
export class RoomDal {

  constructor(private httpClient: HttpClient) {
  }

  getNumberofRooms(hospitalId?: string): Observable<number> {
    let url = urlEndPoints.rooms + 'numberOfRooms';
    if (hospitalId) {
        url += `?hospitalId=${hospitalId}`;
    }

    return this.httpClient.get<number>(url);
  }

  getAllRooms(): Observable<Room[]> {
    return this.httpClient.get<Room[]>(urlEndPoints.rooms);
  }

  getHospitalRoomsWithSurgeonActivity(startDate: string, endDate: string, surgeonId: string): Observable<any> {
    return this.httpClient.get<any>(`${urlEndPoints.rooms}/roomsWithSurgeonActivity?startDate=${startDate}&endDate=${endDate}&surgeonId=${surgeonId}`)
  }

  getAllRoomsWithPrograms(jour: string, allRooms: boolean = true): Observable<any[]> {
    let endPoint;
    endPoint = urlEndPoints.bufferPrograms + '?jour=' + jour + '&allRooms=' + allRooms;
    return this.httpClient.get<any[]>(endPoint);
  }

  getHospitalRoomsDayDoctorAgendas(day: string, roomID?: string): Observable<roomWithDoctorAgendas[]> {
    return this.httpClient.get<roomWithDoctorAgendas[]>(urlEndPoints.rooms + 'includeda/' + day + (roomID ? '/' + roomID : ''));
  }

  getHospitalRoomsDayRealPrograms(day: string): Observable<Room[]> {
    return this.httpClient.get<Room[]>(urlEndPoints.rooms + 'includerp/' + day);
  }

  getHospitalRoomsDaysSurgeonOpenings(day: string): Observable<any[]> {
    return this.httpClient.get<any[]>(urlEndPoints.openings + '?date=' + day);
  }

  getHospitalRoomsDaysSurgeonOpeningsFromTo(startDate: string, endDate: string): Observable<any[]> {
    return this.httpClient.get<any[]>(urlEndPoints.openings + 'interval?from=' + startDate+'&to='+endDate);
  }

  getHospitalRoomsDaysSurgeonOpeningsClosedFromTo(startDate: string, endDate: string, concernedRooms: string[]): Observable<any[]> {
    return this.httpClient.post<any[]>(urlEndPoints.openings + 'closedInterval?from=' + startDate+'&to='+endDate, { concernedRooms });
  }

  updateHospitalRoomsWeekDayConfg(weekDay: number, week: number, surgeonOpenings: any[]): Observable<any[]> {
    return this.httpClient.post<any[]>(urlEndPoints.openings + 'main?day=' + weekDay + '&week=' + week, surgeonOpenings);
  }

  updateRoomSurgeonOpening(date: string, roomId: string, surgeonOpenings: any[] ,notify? : boolean, cachePrograms?: BufferProgram[]): Observable<any[]> {
    const body = {
      openings: surgeonOpenings,
      programs: cachePrograms
    }
    return this.httpClient.post<any[]>(urlEndPoints.openings + 'updateRoomSo?date=' + date + '&roomId=' + roomId + '&notify='+notify, body);
  }

  deleteSO(openingId: string): Observable<any> {
    return this.httpClient.delete<any>(`${urlEndPoints.openings}deleteSO?openingId=${openingId}`);
  }

  deleteSOH(sohToDelete: any[]): Observable<any> {
    return this.httpClient.post<any>(`${urlEndPoints.openings}deleteSOH`, {sohToDelete: sohToDelete});
  }

  updateSOH(sohToUpdate: any[]) : Observable<any> {
    return this.httpClient.post<any>(`${urlEndPoints.openings}updateSOH`, {sohToUpdate});
  }

  getAvailableRooms(day: string): Observable<Room[]> {
    return this.httpClient.get<Room[]>(`${urlEndPoints.rooms}opened?day=${day}`);
  }

  addRoom(room: Room): Observable<Room> {
    return this.httpClient.post<Room>(urlEndPoints.rooms, room);
  }

  modifyRoom(room: Room): Observable<Room> {
    return this.httpClient.put<Room>(urlEndPoints.rooms + room._id, room);
  }

  archiveRoom(idRoom: string): Observable<Room> {
    return this.httpClient.delete<Room>(urlEndPoints.rooms + idRoom);
  }

  softDeleteRoom(idRoom: string): Observable<any> {
    return this.httpClient.put<Specialty>(urlEndPoints.rooms + '/softDelete/' + idRoom, null);
  }

  getRoom(idRoom: string): Observable<Room> {
    return this.httpClient.get<Room>(urlEndPoints.rooms + idRoom);
  }

  getHospitalRooms(): Observable<Room[]> {
    return this.httpClient.get<any>(urlEndPoints.rooms);
  }

  getNursesRanking(date: string): Observable<any> {
    return this.httpClient.get<any>(`${urlEndPoints.rooms}ranks?date=${date}`);
  }

  getHospitalRoomsWeekDayConfg(day: number, week: number, tvo?: string): Observable<any[]> {
    return this.httpClient.get<any[]>(urlEndPoints.openings + `main?day=${day}&week=${week}&tvo=${tvo}`);
  }

  getAnesthFixNeeds(day: number, week: number, tvo: string): Observable<any[]> {
    return this.httpClient.get<any[]>(urlEndPoints.anesthNeeds + 'fixNeeds' + `?day=${day}&week=${week}&tvo=${tvo}`);
  }

  updateAnesthFixNeeds(day: number, week: number, tvo: string, needs: any[]): Observable<any[]> {
    return this.httpClient.post<any[]>(urlEndPoints.anesthNeeds + 'fixNeeds'  + `?day=${day}&week=${week}&tvo=${tvo}`, needs);
  }

  getAnesthDailyNeeds(weekDay: string): Observable<dailyNeeds[]> {
    return this.httpClient.get<dailyNeeds[]>(urlEndPoints.anesthNeeds + 'dailyNeeds/' + weekDay);
  }

  updateAnesthDailyNeeds(weekDay: string, needs: any[]): Observable<any[]> {
    return this.httpClient.post<any[]>(urlEndPoints.anesthNeeds + 'dailyNeeds/' + weekDay, needs);
  }

  autoFillingByInterval(startDate: string, endDate: string, roomsToKeep: any[], profilesToKeep: any[], isSplitTeams: boolean): Observable<any[]> {
    return this.httpClient.post<Room[]>(`${urlEndPoints.rooms}autoFillingByInterval?startDate=${startDate}&endDate=${endDate}`, { roomsToKeep, profilesToKeep, isSplitTeams });
  }
}
