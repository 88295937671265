import { timestamps, updatedBy } from "../../interfaces/shared.interfaces";
import { BufferProgram } from "../../models/buffer-program.model";
import { Profile } from "../../models/profile.model";
import { Role } from "../../models/role.model";
import { Room } from "../../models/room.model";
import { RoleLevel } from "../../models/skill.model";
import { Specialty } from "../../models/specialty.model";
import { SO } from "../../models/surgeonOpenings.model";

export interface roomFinalDataTeam {
    surgeons: surgeonCard[],
    others: profileCard[]
}

export interface EffectiveByRole {
    morningNurseEffectif: {
        role: Role,
        morningNurses: number
    }[],
    afternoonNurseEffectif: {
        role: Role,
        afternoonNurses: number
    }[]
}

export interface roomFinalData {
    roomId: string,
    roomNumber: string | Number,
    room: Room
    programIndex?: number,
    isEmpty: boolean,
    modified: boolean
    isSplit: boolean,
    splitByVacation: boolean,
    programs?: BufferProgram[] & {mergedPrograms?: BufferProgram[]}
    originalPrograms?: BufferProgram[]
    surgeonOpenings?: SO[] & {mergedPrograms?: SO[]},
    isBP: boolean
    originalSO?: SO[]
    team: roomFinalDataTeam;
    morningPrograms?: roomFinalData
    afternoonPrograms?: roomFinalData
}

export interface profileCard {
    profile?: Profile,
    role?: Role,
    originalTime?: {startTime: Date, endTime: Date},
    time: {startTime: Date, endTime: Date},
    isGarde?: boolean
    nbAssignments?: number
    isDuplicate?: boolean,
    isIntersectingRoom?: boolean,
    invalidTime?: boolean
    partTime?: boolean,
    scorePromise?: Promise<RoleLevel[]>,
    
    // Anesths
    timeRange?: TimeRange; // For anesth (day/morining/afternoon)
    priority?: boolean
    substitution?: boolean
    exclusion?: boolean
    badgeLoaded?: boolean
    onlyConsultExtra?: boolean
    onlyExtra?: boolean
}

export interface surgeonCard {
    profile?: Profile,
    time: {startTime: Date, endTime: Date}
    isFillingSurgeon?: boolean
}

export interface consultationExtracliniqueData extends updatedBy, timestamps {
    specialty: Specialty,
    title: string,
    isSplit: boolean,
    dayPrograms: consultationExtracliniquePrograms[],
    morningPrograms: consultationExtracliniquePrograms[],
    afternoonPrograms: consultationExtracliniquePrograms[],
    isMorning: boolean,
    effectif: number,
    needs?: number,
    modified: boolean
    team: profileCard[];
}

export interface consultationExtracliniquePrograms {
    anesth: Profile,
    currentStartTime: Date,
    currentEndTime: Date
}

export enum TimeRange {
    day = 'Journée',
    morning = 'Matin',
    afternoon = 'Après-midi',
}