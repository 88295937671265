import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { urlEndPoints } from '../config/end-points';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class anesthNeedsDAL {

  constructor(private httpClient: HttpClient) {
  }

  generateNeeds(TVOCycleId: string, concernedRooms, allRooms, startDate: string, endDate: string, generationType: string, generationMode: string): Observable<any[]> {
      return this.httpClient.post<any[]>(urlEndPoints.anesthNeeds + "generateNeeds", {TVOCycleId, concernedRooms, allRooms, startDate, endDate, generationType, generationMode})
  }
}
