import { themeStylingColors } from "src/themes/common/principal-theme";
import { ANESTHETIST, NURSE, TITULAIRE, VACATAIRE } from "./glabals.const";

export const ABSENCE_REASONS = ['GUARD_REST', 'RTT', 'CP', 'TRAINING', 'CONGRESS', 'SICKNESS', 'ECHANGE_DE_JOUR', 'OTHER'];
export const ABSENCE_REASONS_IADE = [...ABSENCE_REASONS.slice(0, ABSENCE_REASONS.length - 1), 'AVAILABLE2', ...ABSENCE_REASONS.slice(ABSENCE_REASONS.length - 1)];
export const PRESENCE_REASONS = ['BLOC', 'GUARD', 'CONSULTATION', 'VACATION'];

export const GUARD_REST_REASON = {
    _id  : null,
    availability: "ABSENT",
    color: themeStylingColors.danger_base,
    disabled: false,
    freeComment: false,
    icon: "star",
    img: null,
    isGarde: false,
    period: "DAY",
    seniority: null,
    postes: [ANESTHETIST, NURSE],
    residencies: [TITULAIRE, VACATAIRE],
    title: "GUARD_REST"
  }
  
  export const CLINIQUE_REASON = {
    _id  : null,
    availability: "AVAILABLE",
    color: themeStylingColors.positive_base,
    disabled: false,
    freeComment: false,
    icon: "star",
    img: null,
    isGarde: false,
    period: "DAY",
    postes: [ANESTHETIST, NURSE],
    seniority: null,
    residencies: [TITULAIRE, VACATAIRE],
    title: "BLOC"
  }

  export const CONSULTATION_REASON = {
    _id  : null,
    availability: "AVAILABLE",
    color: themeStylingColors.positive_base,
    disabled: false,
    freeComment: false,
    icon: "star",
    img: null,
    isGarde: false,
    period: "DAY",
    postes: [ANESTHETIST],
    seniority: null,
    residencies: [TITULAIRE, VACATAIRE],
    title: "CONSULTATION"
  }

