import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AutofillingDal } from '../dal/autofilling.dal';

@Injectable({
  providedIn: 'root'
})
export class AutofillingService {

  constructor(private autofillingDal: AutofillingDal) { }

  autoFillingByInterval(startDate: string, endDate: string, seniority: string, autofillingTypes: string[], roomsToKeep: string[], profilesToKeep: string[]): Observable<any[]> {
    return this.autofillingDal.autoFillingByInterval(startDate, endDate, seniority, autofillingTypes, roomsToKeep, profilesToKeep);
  }

  getAnesthsRanking(date: string): Observable<any> {
    return this.autofillingDal.getAnesthsRanking(date);
  }
}
