import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-button-save',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonSaveComponent implements OnInit {

  @Input() type: string
  @Input() title: string = "Sauvegarder"
  @Input() disabled: boolean
  @Output() buttonClicked = new EventEmitter()

  constructor() { }

  ngOnInit() {
  }

  buttonClickedHandler() {
    this.buttonClicked.emit()
  }

}
