import {Component, ElementRef, EventEmitter, HostListener, OnInit, Output} from '@angular/core';
import {ProfileService} from '../../services/profile.service';
import {UtilisService} from '../../services/utilis.service';
import {Profile} from '../../models/profile.model';
import {ErrorService} from '../../services/error.service';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-annuraire',
    templateUrl: './annuraire.component.html',
    styleUrls: ['./annuraire.component.scss']
})
export class AnnuraireComponent implements OnInit {
    @Output() closeAnnuraire = new EventEmitter();
    isLoading = true
    profiles: Profile[]
    pageSize
    page = 1
    searchValue: string = "";
    hasNextPage: any;
    scrollLoading: boolean;
    getProfilesSubscription: any;

    constructor(
        private matDialog: MatDialog,
        private utilsService: UtilisService,
        private eRef: ElementRef,
        private profileService: ProfileService,
        private errorService: ErrorService,
    ) {
        this.pageSize = Math.round(window.innerHeight / 100) + 7;
    }

    ngOnInit(): void {
        this.getProfileList()
    }

    addNumberHandler() {
        // const popupRef = this.utilsService.openPopup(AddAnnuaireComponent, {})
        // popupRef.afterClosed().subscribe(number => {
        //   if (number)
        //     this.numbers.push(number)
        // })
    }

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (this.eRef.nativeElement.contains(event.target) || event.target.id == 'annuaire-icon') {
        } else {
            this.close()
        }
    }

    doFilter = (value: string): void => {
        this.page = 1;
        this.searchValue = value;
        this.isLoading = true
        this.getProfileList();
    }


    getProfileList(concat?) {

        if (this.getProfilesSubscription) {
            this.getProfilesSubscription.unsubscribe();
        }

        this.getProfilesSubscription = this.profileService.getProfiles(this.page, this.pageSize, this.searchValue)
            .subscribe(res => {
                concat ? this.profiles = this.profiles.concat(res.docs) : this.profiles = res.docs;
                this.profiles = this.profiles.filter(profile => profile.lastName != "Urgences");
                this.isLoading = false;
                this.scrollLoading = false
                this.hasNextPage = res.hasNextPage;
                this.page++;
            }, error => {
                this.isLoading = false;
                this.errorService.handleError(error)
            });
    }

    deleteNumberHandler(number: number) {
        const message = "Vous vous apprêtez à supprimer un numéro de service. Voulez vous continuer ?"
        this.utilsService.openConfirmationPopup(this.matDialog, ConfirmDialogComponent, message).afterClosed().subscribe(result => {
            if (result) {
                this.deleteNumber(number)
            }
        });
    }

    deleteNumber(number: number) {
        // this.numberService.deleteNumber(number).subscribe(res => {
        //   this.numbers = this.numbers.filter(el => el._id != number._id)
        // })
    }


    onScroll(e) {
        const tableViewHeight = e.target.offsetHeight;
        const tableScrollHeight = e.target.scrollHeight;
        const scrollLocation = e.target.scrollTop;
        const buffer = 100;
        const limit = tableScrollHeight - tableViewHeight - buffer;
        if (scrollLocation > limit && this.hasNextPage && !this.scrollLoading) {
            this.scrollLoading = true
            this.getProfileList(true);
        }
    }

    close() {
        this.closeAnnuraire.emit(true)
    }

}
