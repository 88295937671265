import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

import { Observable, of } from "rxjs";

import { urlEndPoints } from "../config/end-points";
import { BufferProgram } from "../models/buffer-program.model";
import { Room } from "../models/room.model";
import { ProgramReFormat } from "../utils/reformat-program";
import { DyasStatus, Period } from "../models/period.model";
import { UserService } from "../services/user.service";
import * as moment from "moment";
import { buffer, map } from "rxjs/operators";
import { Specialty } from "../models/specialty.model";
import { roomBufferPrograms } from "../interfaces/buffer-program.interfaces";
import { roomFinalData } from "../components/day-program-recap/day-program-interfaces";

@Injectable()
export class BufferProgramDal {

  constructor(private http: HttpClient, private userService: UserService) {}
  getRoomPrograms(date: string, roomId: string): Observable<any> {
    return this.http.post<any>(
      `${urlEndPoints.openings}/checkBeforeDeleteSO?date=${date}&roomId=${roomId}`,
      {}
    );
    // return of([
    //   "program1",
    //   "program2"
    // ])
  }

  getSurgeonPrograms(fromDate: string, toDate: string, profileId: string) {
    const body = {
      description: "Autre",
      opening: false,
    };
    return this.http.post<any>(
      `${urlEndPoints.openings}/checkBeforeDeleteSO?surgeonId=${profileId}&fromDate=${fromDate}&toDate=${toDate}`,
      body
    );
  }

  getPrograms(fromDate: any, toDate, profileId: any): Observable<any[]> {
    return this.http.get<any>(
      `${urlEndPoints.bufferPrograms}/nurseProgOfDuration?profileId=${profileId}&fromDate=${fromDate}&toDate=${toDate}`
    );
  }

  getDayPrograms(date: string, toDate: string, profileId: string): Observable<any[]> {
    return this.http.get<any>(
      `${urlEndPoints.bufferPrograms}/dayPrograms/${profileId}/${date}?toDate=${toDate}`
    )
  }

  getExtraclinique(profileId: string, date: string): Observable<any> {
    return this.http.get<any>(
      `${urlEndPoints.bufferPrograms}getExtraclinique?profileId=${profileId}&date=${date}`
    );
  }

  checkExistenceOfBufferProgramsFromTo(startDate, endDate, concernedRooms): Observable<any> {
    return this.http.post<any>(
      `${urlEndPoints.bufferPrograms}checkExistence?startDate=${startDate}&endDate=${endDate}`
    , { concernedRooms });
  }
  // Unused

  // getWorkingDays(monday: string): Observable<any> {
  //   const profileId = this.userService.getCurrentUser().profile._id;
  //   return this.http.get<any>(
  //     `${urlEndPoints.bufferPrograms}/nurseWeek/${profileId}/${monday}`
  //   );
  // }

  // INFO: Unused Route
  // getWeekStatus(date: string): Observable<any> {
  //   return this.http.get<Room[]>(
  //     `${urlEndPoints.bufferPrograms}/weekstatus/${date}`
  //   );
  // }

  getAllBufferPrograms(
    roomId?: string,
    day?: string,
    period?: Period
  ): Observable<Room> {
    let baseUrl = urlEndPoints.bufferPrograms;
    if (roomId) {
      baseUrl += "room/" + roomId;
    }
    let params = new HttpParams();
    params = params.append("limit", "1000");
    if (day) {
      params = params.append("jour", day);
    }
    if (period && period.from) {
      params = params.append("from", period.from);
    }
    if (period && period.to) {
      params = params.append("to", period.to);
    }
    return this.http.get<Room>(baseUrl, { params });
  }

  // getNurseBufferPrograms(
  //   idNurse: string,
  //   period: Period
  // ): Observable<BufferProgram[]> {
  //   return this.http.get<BufferProgram[]>(
  //     `${urlEndPoints.statop}getOneNurseBuffer?nurseId=${idNurse}&fromDate=${period.from}&toDate=${period.to}`
  //   );
  // }

  getAllRoomsBufferPrograms(day: string): Observable<roomBufferPrograms[]> {
    let params = new HttpParams();
    params = params.append("jour", day);
    return this.http.get<roomBufferPrograms[]>(urlEndPoints.bufferPrograms, { params });
  }

  getAllRoomsBufferProgramsFromTo(startDate: string, endDate: string): Observable<Room[]> {
    const to = new Date(endDate); to.setDate(to.getDate()+1)
    let params = new HttpParams();
    params = params.append("from", startDate);
    params = params.append("to", moment(to).format('YYYY-MM-DD'));
    return this.http.get<Room[]>(urlEndPoints.bufferPrograms, { params });
  }

  addBufferProgram(date: string, bufferProgram: any): Observable<BufferProgram> {
    return this.http.post<BufferProgram>(
      urlEndPoints.bufferPrograms + "updateMany" + `?date=${date}`,
      bufferProgram
    );
  }

  updateBufferProgram(
    programId: string,
    bufferProgram: BufferProgram
  ): Observable<BufferProgram> {
    const newBufferProgram = ProgramReFormat.programReFormating(bufferProgram);
    return this.http.put<BufferProgram>(
      urlEndPoints.bufferPrograms + programId,
      newBufferProgram
    );
  }
  // Unused
  // getWeekDaysSatus(week: any): Observable<DyasStatus> {
  //   return this.http.get<DyasStatus>(
  //     urlEndPoints.bufferPrograms + "weekstatus/" + week
  //   );
  // }

  updateManyBufferProgram(
    date: string,
    rooms: roomFinalData[],
    formattedBUffers?,
    urgenceProfile?,
  ): Observable<BufferProgram[]> {
    const rawBufferPrograms = ProgramReFormat.roomsToBufferProgramsFormat(rooms, urgenceProfile);
    // The next line is equivalent to .flat(1)
    const bufferPrograms = [].concat(...rawBufferPrograms);
    const mergedBuffers = bufferPrograms.concat(formattedBUffers);
    return this.http.post<BufferProgram[]>(
      `${urlEndPoints.bufferPrograms}/updateMany?date=${date}`,
      mergedBuffers
    );
  }

  deleteBufferProgram(idBuffer: string): Observable<BufferProgram> {
    return this.http.delete<BufferProgram>(
      `${urlEndPoints.bufferPrograms}/${idBuffer}`
    );
  }

  cleanRoom(roomId: any, day: string): Observable<boolean> {
    return this.http.delete<boolean>(
      `${urlEndPoints.bufferPrograms}${roomId}/${day}`
    );
  }

  cleanAllRooms(hospitalId: string, day: string): Observable<boolean> {
    return this.http.delete<boolean>(
      `${urlEndPoints.bufferPrograms}rooms/${hospitalId}/${day}`
    )
  }

  cleanRoomWithProg(roomId: any, day: string, prog: any): Observable<boolean> {
    return this.http.delete<boolean>(
      `${urlEndPoints.bufferPrograms}${roomId}/${day}?surgeon=${prog.surgeon._id}&startTime=${prog.startTime}&endTime=${prog.endTime}`, 
    );
  }
  
}

export interface ProgramAPI {
  specialty: Specialty,
  programs: any[],
  rooms?: Room[],
  needs: any
};
