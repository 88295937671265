import { Component, Input, OnChanges, HostListener, OnInit, Output, SimpleChanges, EventEmitter, ElementRef, Renderer2 } from "@angular/core";
import { Profile } from "../../models/profile.model";
import { Subscription } from "rxjs";
import { ProfileService } from "../../services/profile.service";
import { ErrorService } from "../../services/error.service";
import { SURGEON } from "../../const/glabals.const";

@Component({
  selector: "app-surgeon-feed-avatar",
  templateUrl: "./surgeon-feed-avatar.component.html",
  styleUrls: ["./surgeon-feed-avatar.component.scss"],
})
export class SurgeonFeedAvatarComponent implements OnChanges {
  @Input() profile: Profile;
  public displayMode = "row";
  @Input() displaySize = "normal";
  @Input() isVerySmall: boolean = false;
  @Input() tooManyParallele: boolean = false;
  @Input() hospital: string;
  @Input() allSurgeonProfiles: Profile[];
  @Input() canSurgeonSelect: boolean = false;
  @Input() da: any

  @Output() onSurgeonSelect = new EventEmitter<boolean>();
  @Output() onSurgeonSelected = new EventEmitter<Profile>();
  public isUrgence = false;

  casedName;
  specialtyName;
  public specialtyImage: string;

  public picture: string;

  public isSearch: boolean = false;
  public osComponentOptions = {
    className: 'os-theme-dark custom-sidebar',
    nativeScrollbarsOverlaid: {
      showNativeScrollbars: false
    },
    overflowBehavior: {
      x: 'hidden',
    },
    paddingAbsolute: true,
    scrollbars: {
      autoHide: 'never',
    },
  };
  public isLoading: boolean = true;
  public surgeonProfiles: Profile[];
  public lastSearchValue: string = '';
  public tinyMode: boolean = false;

  private mouseDownListener;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    this.picture = this.getProfilePic();

    this.casedName = this.getCasedName(
      this.profile.firstName + " " + this.profile.lastName
    );

    this.specialtyName = this.getSpecialty();
    this.setSpecialtyImage();

    if (this.tooManyParallele) {
      this.displayMode = "column";
    } else {
      this.displayMode = "row";
    }
    if (this.allSurgeonProfiles && this.allSurgeonProfiles.length > 0 && this.da) {
      const surgeonDa = this.da.surgeon;
      const surgeonDaSpecialties = surgeonDa.specialties
      const daSpecialty = surgeonDaSpecialties.find((specialty) => String(specialty.hospital) === String(this.hospital))
      if (daSpecialty) {
        if (daSpecialty.name !== "Urgences") {
          this.allSurgeonProfiles = this.allSurgeonProfiles.filter((profile) => profile.specialties.some((specialty) => String(specialty._id) === String(daSpecialty._id)))
        }
      } else {
        this.canSurgeonSelect = false;
      }
      this.isLoading = false;
    }
  }

  getProfilePic() {
    if (!this.profile) {
      return;
    }
    if (this.hospital && this.profile.specialties && this.profile.specialties.length > 0) {
      const specialty = this.profile.specialties.find((specialty) => String(specialty.hospital) === String(this.hospital))
      if (specialty && specialty.name === "Urgences") {
        this.isUrgence = true;
        return specialty.image;
      }
    }
    return this.profile.profilePic;
  }

  getCasedName(fullName: string): string {
    return fullName
      .split(" ")
      .map((word: string) => {
        if (word !== "") {
          return word[0].toUpperCase() + word.slice(1).toLowerCase();
        }
      })
      .join(" ");
  }


  getSpecialty() {
    let specialtyName: string;
    if (this.hospital && this.profile.specialties && this.profile.specialties.length > 0) {
      const specialty = this.profile.specialties.find((specialty) => String(specialty.hospital) === String(this.hospital))
      if (specialty) {
        specialtyName = specialty.name
      } else {
        specialtyName = null
      }
    } else {
      return null;
    }
    if (this.isVerySmall) {
      specialtyName = specialtyName
        .split(" ")
        .map((value, index, array) =>
          index < array.length - 1 ? value[0] + "." : value
        )
        .join(" ");
    }
    return specialtyName;
  }

  setSpecialtyImage(): void {
    if (!this.profile) {
      return;
    }
    if (this.hospital && this.profile.specialties && this.profile.specialties.length > 0) {
      const specialty = this.profile.specialties.find((specialty) => String(specialty.hospital) === String(this.hospital))
      if (specialty) {
        if (specialty.name === "Urgences") {
          return;
        }
        this.specialtyImage = specialty.image;
      } else {
        return null
      }
    } else {
      this.specialtyImage = null;
    }
  }


  stopPropagation(event: any): void {
    event.stopPropagation();
  }
  
  mouseEnterSurgeonSelect() {
    if (this.canSurgeonSelect) {
      this.onSurgeonSelect.emit(true)
    }
  }

  mouseLeaveSurgeonSelect() {
    if (this.canSurgeonSelect) {
      this.onSurgeonSelect.emit(false)
    }
  }

  changeSurgeon() {
    if (this.canSurgeonSelect) {
      this.isSearch = true;
      this.tinyMode = true;
      this.surgeonProfiles = this.allSurgeonProfiles
      this.listenToMouseDownEvents();
    }
  }

  profileClick(profile) {
    if (this.canSurgeonSelect) {
      this.isSearch = false;
      this.tinyMode = false;
      this.specialtyName = this.getSpecialty();
      this.onSurgeonSelect.emit(false)
      this.onSurgeonSelected.emit(profile)
    }
  }

  onEnter(searchInput: string): void {
    if (this.canSurgeonSelect) {
      this.lastSearchValue = searchInput;
      // this.isLoading = true;
      this.filterProfiles(searchInput);
      // this.isLoading = false;
    }
  }
  
  filterProfiles(searchInput: string): void {
    if (this.canSurgeonSelect) {
      if (!this.allSurgeonProfiles ||this.allSurgeonProfiles.length == 0)
        return;

      this.surgeonProfiles = this.allSurgeonProfiles.filter((profile) => {
        let firstLast = `${profile.firstName} ${profile.lastName}`;
        let lastFirst = `${profile.lastName} ${profile.firstName}`;
        firstLast = firstLast.toLowerCase();
        lastFirst = lastFirst.toLowerCase();
        const searchInputLowerCase = searchInput.toLowerCase();
        if (firstLast.includes(searchInputLowerCase) || lastFirst.includes(searchInputLowerCase)) {
          return true;
        }
      });
    }
  }

  listenToMouseDownEvents(): void {
    this.mouseDownListener = this.renderer.listen('window', 'mousedown', (event) => {
      if (this.canSurgeonSelect) {
        if (!this.elementRef.nativeElement.contains(event.target)) {
          this.isSearch = false;
          this.tinyMode = false;
          this.specialtyName = this.getSpecialty();
          this.onSurgeonSelect.emit(false)
          this.removeMouseDownListener();
        }
      }
    });
  }

  removeMouseDownListener(): void {
    if (this.mouseDownListener) {
      this.mouseDownListener();
    }
  }
}
