import { Injectable } from '@angular/core';
import { BufferProgramService } from 'src/app/shared/services/buffer-program.service';
import { Profile } from './shared/models/profile.model';
import { ConfirmDialogComponent } from './shared/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class SurgeonVerificationService {
  getProgamsSubsc: any;

  constructor(
    private bufferProgramService: BufferProgramService,
    private dialog: MatDialog
  ) { }



  openConfiPopup(programs, message: string, message2: string, profileType: string) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        cssClass: 'danger',
        message,
        profileType,
        programms: programs,
        message2
      },
    });
    return dialogRef.afterClosed()
  }
}


// message: `Êtes vous sûr(e) de vouloir supprimer les vacations de cette salle?`,
// profileType,
// programms: programs,
// message2: "Cette salle a plusieurs vacations:",