import { Injectable } from '@angular/core';
import { Rating } from '../models/rating.model';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { urlEndPoints } from '../config/end-points';

@Injectable({
  providedIn: 'root'
})
export class RankingDal {

  constructor(private http: HttpClient) { }

  getRatingBySpecialties(specialesIds: string[]): Observable<Rating[]> {
    return this.http.post<Rating[]>(urlEndPoints.rating, { specialties: specialesIds });
  }
  getSpecialtiesWithRanks(args?: {formation?: string, hospitalId?: string, isIadeResp?: boolean}): Observable<any> {
    let formation, hospitalId, isIadeResp;

    if (args) {
      formation = args.formation;
      hospitalId = args.hospitalId;
      isIadeResp = args.isIadeResp;
    }

    let params = new HttpParams();

    if (formation) {
      params = params.append("formation", formation);
    }

    if (hospitalId) {
      params = params.append("hospital", hospitalId);
    }

    if (isIadeResp) {
      params = params.append("isIadeResp", isIadeResp);
    }

    return this.http.get<any>(urlEndPoints.specialtyRank, { params });
  }
  getRatingsBySpecialtyName(specialtyName: string): Observable<Rating> {
    return this.http.get<Rating>(urlEndPoints.ratingByName + `?specialtyName=${specialtyName}`);
  }

  getRatingsBySpecialtyId(specialty: string): Observable<Rating[]> {
    return this.http.get<Rating[]>(urlEndPoints.specialtyRank + '/get' + `/${specialty}`);
  }

  getDefaultRatingsBySpecialtyName(specialtyName: string): Observable<Rating[]> {
    return this.http.get<Rating[]>(urlEndPoints.defaultRating + `?specialtyName=${specialtyName}`);
  }

  updateRatingSystem(ratingSystem: Rating): Observable<Rating> {
    return this.http.put<Rating>(urlEndPoints.specialtyRank + '/' + ratingSystem._id, ratingSystem);
  }

  addRatingSystem(ratingSystem: Rating): Observable<Rating> {
    return this.http.post<Rating>(urlEndPoints.specialtyRank, ratingSystem);
  }

  addUpdateRatingSystems(ratingSystems: any[]): Observable<Rating> {
    return this.http.put<Rating>(urlEndPoints.specialtyRank, {ratingSystems});
  }
}
