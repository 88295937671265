import {
  AfterViewInit,
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  Output,
  EventEmitter,
} from "@angular/core";
import { CdkDrag, CdkDragDrop, CdkDragMove, moveItemInArray } from "@angular/cdk/drag-drop";
import { MatDialog } from "@angular/material/dialog";

import { merge, Subscription, Observable, forkJoin, BehaviorSubject } from "rxjs";
import { map, startWith, switchMap, tap } from "rxjs/operators";
import * as moment from "moment";

import { Room } from "../../models/room.model";
import { isAnesthetist, isNurse, Profile } from "../../models/profile.model";

import { ErrorService } from "../../services/error.service";
import { BufferProgramService } from "../../services/buffer-program.service";
import { RealProgramService } from "../../services/real-program.service";

import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { formatName } from "../../utils/name-formator";
import { checkDuplication } from "../../utils/duplication.checker";
import { ToastService } from "../../services/toast.service";
import { NavigationService } from "../../../core/services/navigation.service";
import { pages } from "../../config/pages";
import { ProfileService } from "../../services/profile.service";
import { BufferProgram } from "../../models/buffer-program.model";
import {
  ANESTHETIST,
  components,
  Iade,
  IADE,
  NURSE,
  NURSE_TYPES,
  OPPOSITE_ZOOM_VALUE,
  SURGEON,
  WEIGHT,
  ZOOM_VALUE,
} from "../../const/glabals.const";
import {
  Calendar,
  isAbsent,
  isAvailable,
  isConsultation,
  isGuard,
  mapCalendarsToAvailableAnesthetists,
} from "../../models/calendar.model";
import { StorageService } from "../../../core/services/storage.service";
import { DoctorAgendasService } from "../../services/doctor-agendas.service";
import { DoctorAgenda } from "../../models/doctor-agenda.model";
import { UtilisService } from "../../services/utilis.service";
import { RoomService } from "../../services/room.service";
import { dateWithTime, getFirstHospitalSelectedData, setTypeAttributeArray,sortRoomsByPriority } from "../../utils/cross-functions";
import { ProgramReFormat } from "../../utils/reformat-program";
import { FeedRoomDialogComponent } from "../feed-room-dialog/feed-room-dialog.component";
import { PrintService } from "src/app/smart-planning/program-recap/print-service/print-service.service";
import { ExportDayComponent } from "../export-day-popup/export-day-popup.component";
import { AutofillingComponent } from "../autofilling-popup/autofilling-popup.component";
import { UserService } from "../../services/user.service";
import { ProgramCommentaryService } from "../../services/program-commentary.service";
import { ProgramCommentary } from "../../models/program-commentary.model";
import { PopupManagerService } from "../../services/popup-manager.service";
import { ProfilesSuggestionsComponent } from "../profiles-suggestions/profiles-suggestions.component";
import { HospitalService } from "../../services/hospital.service";
import { Specialty } from "../../models/specialty.model";
import { SpecialtyService } from "../../services/specialty.service";
import { AutofillingService } from "../../services/autofilling.service";
import { resolve } from "url";
import { PythonAPIService } from "../../services/pythonApi.service";
import { ParamedicalService } from "../../services/paramedical.service";
import { Paramedical } from "../../models/paramedical.model";
import { Role } from "../../models/role.model";
import { SO } from "../../models/surgeonOpenings.model";
import { roomWithDoctorAgendas } from "../../interfaces/room.interfaces";

const speed = 10;

@Component({
  selector: "app-day-program-recap-old",
  templateUrl: "./day-program-recap-old.component.html",
  styleUrls: ["./day-program-recap-old.component.scss"],
})
export class DayProgramRecapOldComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() day: Date;
  @Input() isAutoFilling: boolean;
  @Input() home: boolean;
  @Input() isHome: boolean;
  @Input() fetchDataOnDateChange: boolean = false;
  @Input() showRoomsWithNoBufferPrograms: boolean = true;
  @Input() showAllRooms: boolean = false;
  @Input() redirectOnClickOnProfile: boolean = false;

  @Input() mode: string = "pending-request"; // pending-request or smart-planning

  @Output() onRolesReception: EventEmitter<Role[]> = new EventEmitter<Role[]>();

  @ViewChild('tableElement', { static: false }) tableElement: ElementRef;
  @ViewChild("scrollEl", { static: false }) scrollEl: ElementRef<HTMLElement>;
  @ViewChild("availableProfilesSection", { static: false })
  availableProfilesSection;
  @ViewChild("availableAnesthsContainer", { static: false })
  availableAnesthsContainer;
  @ViewChild("availableNursesContainer", { static: false })
  availableNursesContainer;
  @ViewChild("availableNursesSpecialContainer", { static: false })
  @ViewChildren(CdkDrag)
  dragEls: QueryList<CdkDrag>;

  public math = Math;
  public osComponentOptions = {
    className: "os-theme-dark custom-sidebar",
    nativeScrollbarsOverlaid: {
      showNativeScrollbars: true,
    },
    overflowBehavior: {
      x: "hidden",
    },
    paddingAbsolute: true,
    scrollbars: {
      autoHide: "never",
      clickScrolling: true,
    },
  };

  public dynamicMarginsForAvailableProfiles = {
    nursesMarginRight: "12px",
    anesthsMarginRight: "12px",
    consultationExtracliniqueAnesthsMarginRight: "12px",

    numberOfAnesths: -1,
    numberOfNurses: -1,
    numberOfConsultationExtracliniqueAnesths: -1,

    draggingAnesthIndex: -1,
    draggingNurseIndex: -1,
    draggingConsultationExtracliniqueAnesthIndex: -1
  };
  
  public profile: Profile;
  public tableMaxHeight: string = "100%";
  public profilesViews = [
    "Anesthésistes / Iade disponibles",
    "Hors salle (Ibode / Ide)",
    "Horaires Partiels",
  ];
  public actifProfilesView = "Anesthésistes / Iade disponibles";
  public anesthetistsCalendars: Calendar[] = [];
  public consultationExtracliniqueAnesthetists: any[] = [];
  public ANESTHETIST = ANESTHETIST;
  public NURSE = NURSE;
  public nonValidatedDays: Date[];
  public IADEsAlreadyInRoom: any[] = [];
  public allAvailableIades: any[] = [];

  public isLoading: boolean[] = [true, true];
  public programAlreadyValidated: boolean;
  public nurses: (Profile | any)[];
  public blocAnesthetists: (Profile | any)[];
  public urgenceProfile: Profile;
  public showAvailableProfiles: boolean = true;
  public levelOfAccess: number;
  public isAnesthResponsable: boolean;
  @Input() readOnly: boolean = false;
  @Input() hideAvailableProfiles: boolean = false;
  public consultationNeeds: any[];
  public blocNeeds: any[];
  public bigScreen: boolean = true;
  public consultDate: Date = null;
  public extraDate: Date = null;
  public canUserSeeAndEditNurses: boolean = false;

  public canUserSeeConsultationExtraclinique: boolean = false;
  public canUserEditBlocAnesths: boolean = false;
  public canUserEditConsultationExtracliniqueAnesths: boolean = false;

  public isInternResponsible: boolean = false;
  public isIADEResponsible: boolean = false;

  public canRedirectToSurgeon: boolean = false;
  // canRedirectToAnesthesiste is usefull when hospital does not have ANESTH_DETAILS option
  public canRedirectToAnesthesiste: boolean = false;
  public canRedirectToIADE: boolean = false;
  // canRedirectToSeniorJunior is usefull when hospital have ANESTH_DETAILS option
  public canRedirectToSeniorJunior: boolean = false;
  // canRedirectToInterne is usefull when hospital have ANESTH_DETAILS option
  public canRedirectToInterne: boolean = false;
  public canRedirectToNurse: boolean = false;

  nameDay;

  public comment: string = null;
  public programCommentary: ProgramCommentary;

  private draggedItem: {
    nurseType?: number;
    room: number;
    program: number;
    item: Profile;
  };
  private animationFrame: number;
  private subsScroll: Subscription = new Subscription();
  private insertSubscription: Subscription;
  private dialogSub: Subscription;
  private routerSubscription: Subscription;
  private roomsSubscription: Subscription;
  private realProgramSubscription: Subscription;
  private deleteProgramSubscription: Subscription;
  private updateBufferProgramSubscription: Subscription;
  private nursesSubscription: Subscription;
  private anesthetistsSubscription: Subscription;
  private getUrgenceProfileSubscription: Subscription;
  private programCommentarySubscription: Subscription;
  private availableNursesSubscription: Subscription;
  private getConflitsSubscription: Subscription;
  private getParamedicalsSubscription: Subscription;
  private getRolesSubscription: Subscription;

  public paramedicalData: Paramedical[];
  public roleData: Role[];

  public actifSection = 'Bloc';
  public sections = [
    'Bloc',
    'Consultation',
    'Extraclinique'
  ];
  public specialties: Specialty[];
  public extracliniqueSpecialty: Specialty;
  public consultationSpecialties: Specialty[];
  public extracliniqueObject: any;
  public consultationsObjects: any;
  public allBufferPrograms: any[];
  doctorAgendasProgramSubscription: Subscription;
  agendas: DoctorAgenda[] = [];
  public nursesRankingsSubject: BehaviorSubject<any[]> = new BehaviorSubject(null);
  public anesthsRankingsSubject: BehaviorSubject<{ seniorsJuniorsRanks: any[], internesRanks: any[] }> = new BehaviorSubject(null);
  nursesProfile: any[] = [];
  displayDA: boolean[] = [];
  isProgramsChanged: boolean;
  typeAttributeArray: string[];
  mergedRooms: any[] = [];
  fullRooms: Room[];
  roomsSurgeonOpenings: any[];
  rooms: roomWithDoctorAgendas[];
  getHospitalRoomsSubscription: Subscription;
  private getNursesRankingsSubscription: Subscription;
  private getAnesthsRankingsSubscription: Subscription;
  private getRelatedDataSubscription: Subscription;
  private getDailyNeedsSubscription: Subscription;
  isExporting: boolean;
  userId: string;
  exportSubscription: Observable<unknown[]>;
  loadSplitNurses: boolean = true;
  // public isBlocProgramsAlreadyValidated: boolean;
  // public isConsultationExtracliniqueProgramsAlreadyValidated: boolean;
  public doesHospitalHaveProgramsDataPipelineOption: boolean;
  public isNursesProgramsAlreadyValidated: boolean;
  public isAnesthsProgramsAlreadyValidated: boolean;
  public doesHospitalHaveAnesthDetailsOption: boolean;

  public specialConfigurations: any[] = [];
  public specialSurgeonOpening: any[] = [];
  public filtredAnesthesists: any[] = [];
  public isHovered: boolean = false;

  public configData: any;
  public draggableWidth: string;
  public draggableWidthAnesth: string;
  public draggableWidthNurse: string;

  public initialTableWidth: string = "100%";
  public tableBlocWidth: string;
  public tableConsultationWidth: string;
  public tableExtraWidth: string;

  public blocConfigs = {
    hideChirurgiensColumn: false,
    hideSallesColumn: false,
    hideAnesthesistColumn: false,
  
    sallesColumnWidth: "380px",
    chirurgiensItemWidth: "25%",
    paramedicalItemWidth: "50%",
    anesthesistItemWidth: "25%",
  }
  public consultationConfigs: any = {
    hideSpecialtyColumn: false,
    hideDayColumn: false,
    hideMorningColumn: false,
    hideAfternoonColumn: false,

    specialtyWidth: "25%",
    dayWidth: "25%",
    morningWidth: "25%",
    afternoonWidth: "25%",
  }
  public extraConfigs: any = {
    hideExtraColumn: false,
    hideExtraDayColumn: false,
    hideExtraMorningColumn: false,
    hideExtraAfternoonColumn: false,
    
    extraWidth: "25%",
    extraDayWidth: "25%",
    extraMorningWidth: "25%",
    extraAfternoonWidth: "25%",
  }

  public isMultiHospitals: boolean = false;

  constructor(
    private toastService: ToastService,
    private printService: PrintService,
    private roomsService: RoomService,
    private pythonAPIService: PythonAPIService,
    private errorService: ErrorService,
    private utilisService: UtilisService,
    private agendaService: DoctorAgendasService,
    private bufferProgramService: BufferProgramService,
    private programCommentaryService: ProgramCommentaryService,
    private UtilisService: UtilisService,
    private matDialog: MatDialog,
    private navigationService: NavigationService,
    private profileService: ProfileService,
    private realProgramService: RealProgramService,
    private dialog: MatDialog,
    private storageService: StorageService,
    private userService: UserService,
    private popupManagerService: PopupManagerService,
    private changeDetectorRef: ChangeDetectorRef,
    private hospitalService: HospitalService,
    private specialtyService: SpecialtyService,
    private autofillingService: AutofillingService,
    private el: ElementRef,
    private paramedicalService: ParamedicalService
  ) {
    this.onResize();
    this.draggedItem = null;
    this.nurses = [];
    this.nonValidatedDays = [];
    this.doesHospitalHaveProgramsDataPipelineOption = this.hospitalService.doesHospitalHaveProgramsDataPipelineOption();
    this.doesHospitalHaveAnesthDetailsOption = this.hospitalService.doesHospitalHaveAnesthDetailsOption();
  }

  getConfigData() {
    // this.configData = this.storageService.getData('configData') || [];
    this.configData = [];
  }

  saveConfigData() {
    this.storageService.saveData('configData', this.configData);
  }

  getUserConfig(): any {
    const userId = this.storageService.getUser().profile._id;
    let userConfig = this.configData.find(config => config.userId === userId);
  
    return userConfig;
  }

  removeOldestConfig() {
    if (this.configData.length >= 3) {
      this.configData.sort((a, b) => new Date(a.lastConnectedAt).getTime() - new Date(b.lastConnectedAt).getTime());
      this.configData.shift();
      this.saveConfigData();
    }
  }

  calculateItemsWidth(): string {
    if (!this.tableElement) {
      return '';
    }

    const userConfig = this.getUserConfig();

    // Calculate the new table width
    let initialTableWidthValue = parseFloat(this.initialTableWidth);
    let newTableWidthValue = initialTableWidthValue;

    if(this.blocConfigs.hideChirurgiensColumn || this.blocConfigs.hideAnesthesistColumn){
      if (this.blocConfigs.hideChirurgiensColumn && this.blocConfigs.hideAnesthesistColumn) {
        this.blocConfigs.paramedicalItemWidth = '84%';
      } else {
        this.blocConfigs.paramedicalItemWidth = '69%';
      }
    } else {
      this.blocConfigs.paramedicalItemWidth = '50%';
    }

    if(!this.blocConfigs.hideChirurgiensColumn){
      if (!this.blocConfigs.hideAnesthesistColumn) {
        this.blocConfigs.chirurgiensItemWidth = '25%';
      } else {
        this.blocConfigs.chirurgiensItemWidth = '25%';
      }
    } else {
      this.blocConfigs.chirurgiensItemWidth = '55px';
      newTableWidthValue -= 20;
    }
    
    if(!this.blocConfigs.hideAnesthesistColumn){
      if (!this.blocConfigs.hideChirurgiensColumn) {
        this.blocConfigs.anesthesistItemWidth = '25%';
      } else {
        this.blocConfigs.anesthesistItemWidth = '25%';
      }
    } else {
      this.blocConfigs.anesthesistItemWidth = '55px';
      newTableWidthValue -= newTableWidthValue === 80 ? 10 : 20;
    }

    if(!this.blocConfigs.hideSallesColumn){
      this.blocConfigs.sallesColumnWidth = '380px';
    } else {
      this.blocConfigs.sallesColumnWidth = '55px';
      newTableWidthValue -= newTableWidthValue === 75 ? 10 : newTableWidthValue === 60 ? 5 : 22;
    }

    this.tableBlocWidth = newTableWidthValue.toFixed(2) + '%';

    userConfig.tableData.tableBlocWidth = this.tableBlocWidth;
  }

  calculateConsultationWidth(): string {
    let totalColumns = 4;
    let reducedColumns = 0;
  
    if (this.consultationConfigs.hideSpecialtyColumn) {
      reducedColumns++;
    }
    if (this.consultationConfigs.hideDayColumn) {
      reducedColumns++;
    }
    if (this.consultationConfigs.hideMorningColumn) {
      reducedColumns++;
    }
    if (this.consultationConfigs.hideAfternoonColumn) {
      reducedColumns++;
    }
  
    let visibleColumns = totalColumns - reducedColumns;
  
    let width = (100 / visibleColumns ).toFixed(2) + '%';

    if(!this.consultationConfigs.hideSpecialtyColumn){
      this.consultationConfigs.specialtyWidth = width;
    }
    if(!this.consultationConfigs.hideDayColumn){
      this.consultationConfigs.dayWidth = width;
    }
    if(!this.consultationConfigs.hideMorningColumn){
      this.consultationConfigs.morningWidth = width;
    }
    if(!this.consultationConfigs.hideAfternoonColumn){
      this.consultationConfigs.afternoonWidth = width;
    }
    return width;
  }

  calculateExtraWidth(): string {
    let totalColumns = 4;
    let reducedColumns = 0;
  
    if (this.extraConfigs.hideExtraColumn) {
      reducedColumns++;
    }
    if (this.extraConfigs.hideExtraDayColumn) {
      reducedColumns++;
    }
    if (this.extraConfigs.hideExtraMorningColumn) {
      reducedColumns++;
    }
    if (this.extraConfigs.hideExtraAfternoonColumn) {
      reducedColumns++;
    }
  
    let visibleColumns = totalColumns - reducedColumns;
  
    let width = (100 / visibleColumns ).toFixed(2) + '%';

    if (!this.extraConfigs.hideExtraColumn) {
      this.extraConfigs.extraWidth = width;
    }
    if (!this.extraConfigs.hideExtraDayColumn) {
      this.extraConfigs.extraDayWidth = width;
    }
    if (!this.extraConfigs.hideExtraMorningColumn) {
      this.extraConfigs.extraMorningWidth = width;
    }
    if (!this.extraConfigs.hideExtraAfternoonColumn) {
      this.extraConfigs.extraAfternoonWidth = width;
    }
  
    return width;
  }

  calculateSallesColumnWidth(): string {
    if (this.blocConfigs.hideSallesColumn) {
      this.blocConfigs.sallesColumnWidth = '55px';
      return '55PX';
    } else {
      this.blocConfigs.sallesColumnWidth = '380px';
      return '380px';
    }
  }

  calculateChirurgiensItemWidth(): void {
    if (this.blocConfigs.hideChirurgiensColumn) {
      this.blocConfigs.chirurgiensItemWidth = '55px';
      this.changeDetectorRef.detectChanges();
    } else {
      this.calculateItemsWidth();
      this.changeDetectorRef.detectChanges();
    }
  }
  
  calculateParamedicalItemWidth(): void {
    this.calculateItemsWidth();
    this.changeDetectorRef.detectChanges();
  }
  
  calculateAnesthesistItemWidth(): void {
    if (this.blocConfigs.hideAnesthesistColumn) {
      this.blocConfigs.anesthesistItemWidth = '55px';
      this.changeDetectorRef.detectChanges();
    } else {
      this.calculateItemsWidth();
      this.changeDetectorRef.detectChanges();
    }
  }

  calculateSpecialtyColumnWidth(): void {
    if (this.consultationConfigs.hideSpecialtyColumn) {
      this.consultationConfigs.specialtyWidth = '55px';
    } else {
      this.consultationConfigs.specialtyWidth = this.calculateConsultationWidth();
    }
  }
  
  calculateDayColumnWidth(): void {
    if (this.consultationConfigs.hideDayColumn) {
      this.consultationConfigs.dayWidth = '55px';
    } else {
      this.consultationConfigs.dayWidth = this.calculateConsultationWidth();
    }
  }
  
  calculateMorningColumnWidth(): void {
    if (this.consultationConfigs.hideMorningColumn) {
      this.consultationConfigs.morningWidth = '55px';
    } else {
      this.consultationConfigs.morningWidth = this.calculateConsultationWidth();
    }
  }
  
  calculateAfternoonColumnWidth(): void {
    if (this.consultationConfigs.hideAfternoonColumn) {
      this.consultationConfigs.afternoonWidth = '55px';
    } else {
      this.consultationConfigs.afternoonWidth = this.calculateConsultationWidth();
    }
  }

  calculateExtraColumnWidth(): void {
    if (this.extraConfigs.hideExtraColumn) {
      this.extraConfigs.extraWidth = '55px';
    } else {
      this.extraConfigs.extraWidth = this.calculateExtraWidth();
    }
  }
  
  calculateExtraDayColumnWidth(): void {
    if (this.extraConfigs.hideExtraDayColumn) {
      this.extraConfigs.extraDayWidth = '55px';
    } else {
      this.extraConfigs.extraDayWidth = this.calculateExtraWidth();
    }
  }
  
  calculateExtraMorningColumnWidth(): void {
    if (this.extraConfigs.hideExtraMorningColumn) {
      this.extraConfigs.extraMorningWidth = '55px';
    } else {
      this.extraConfigs.extraMorningWidth = this.calculateExtraWidth();
    }
  }
  
  calculateExtraAfternoonColumnWidth(): void {
    if (this.extraConfigs.hideExtraAfternoonColumn) {
      this.extraConfigs.extraAfternoonWidth = '55PX';
    } else {
      this.extraConfigs.extraAfternoonWidth = this.calculateExtraWidth();
    }
  }

  toggleColumn(job: string): void {
    const userConfig = this.getUserConfig();

    switch (job) {
      case 'surgeon':
        this.blocConfigs.hideChirurgiensColumn = !this.blocConfigs.hideChirurgiensColumn;
        userConfig.tableData.blocConfigs.hideChirurgiensColumn = this.blocConfigs.hideChirurgiensColumn;
        break;
      case 'salles':
        this.blocConfigs.hideSallesColumn = !this.blocConfigs.hideSallesColumn;
        userConfig.tableData.blocConfigs.hideSallesColumn = this.blocConfigs.hideSallesColumn;
        break;
      case 'anesthesist':
        this.blocConfigs.hideAnesthesistColumn = !this.blocConfigs.hideAnesthesistColumn;
        userConfig.tableData.blocConfigs.hideAnesthesistColumn = this.blocConfigs.hideAnesthesistColumn;
        break;
    }

    this.calculateItemsWidth();

    this.saveConfigData();

    this.setTargetElementsWidth();
    this.changeDetectorRef.detectChanges();
  }

  toggleConsultationColumn(job: string): void {
    const userConfig = this.getUserConfig();
    let reducedColumns = 0;

    switch (job) {
      case 'specialty':
        this.consultationConfigs.hideSpecialtyColumn = !this.consultationConfigs.hideSpecialtyColumn;
        userConfig.tableData.consultationConfigs.hideSpecialtyColumn = this.consultationConfigs.hideSpecialtyColumn;
        break;
      case 'day':
        this.consultationConfigs.hideDayColumn = !this.consultationConfigs.hideDayColumn;
        userConfig.tableData.consultationConfigs.hideDayColumn = this.consultationConfigs.hideDayColumn;
        break;
      case 'morning':
        this.consultationConfigs.hideMorningColumn = !this.consultationConfigs.hideMorningColumn;
        userConfig.tableData.consultationConfigs.hideMorningColumn = this.consultationConfigs.hideMorningColumn;
        break;
      case 'afternoon':
        this.consultationConfigs.hideAfternoonColumn = !this.consultationConfigs.hideAfternoonColumn;
        userConfig.tableData.consultationConfigs.hideAfternoonColumn = this.consultationConfigs.hideAfternoonColumn;
        break;
    }

    this.calculateSpecialtyColumnWidth();
    this.calculateDayColumnWidth();
    this.calculateMorningColumnWidth();
    this.calculateAfternoonColumnWidth();

    this.calculateConsultationWidth();

    // Calculate the number of reduced columns
    if (this.consultationConfigs.hideSpecialtyColumn) {
      reducedColumns++;
    }
    if (this.consultationConfigs.hideDayColumn) {
      reducedColumns++;
    }
    if (this.consultationConfigs.hideMorningColumn) {
      reducedColumns++;
    }
    if (this.consultationConfigs.hideAfternoonColumn) {
      reducedColumns++;
    }

    // Calculate the new table width
    let initialTableWidthValue = parseFloat(this.initialTableWidth);
    let newTableWidthValue = initialTableWidthValue - (reducedColumns * 22);
    this.tableConsultationWidth = newTableWidthValue.toFixed(2) + '%';

    userConfig.tableData.tableConsultationWidth = this.tableConsultationWidth;

    userConfig.lastConnectedAt = new Date();
    this.saveConfigData();

    this.setTargetElementsWidth();
  }

  toggleExtraColumn(job: string): void {
    const userConfig = this.getUserConfig();
    let reducedColumns = 0;

    switch (job) {
      case 'extra':
        this.extraConfigs.hideExtraColumn = !this.extraConfigs.hideExtraColumn;
        userConfig.tableData.extraConfigs.hideExtraColumn = this.extraConfigs.hideExtraColumn;
        break;
      case 'day':
        this.extraConfigs.hideExtraDayColumn = !this.extraConfigs.hideExtraDayColumn;
        userConfig.tableData.extraConfigs.hideExtraDayColumn = this.extraConfigs.hideExtraDayColumn;
        break;
      case 'morning':
        this.extraConfigs.hideExtraMorningColumn = !this.extraConfigs.hideExtraMorningColumn;
        userConfig.tableData.extraConfigs.hideExtraMorningColumn = this.extraConfigs.hideExtraMorningColumn;
        break;
      case 'afternoon':
        this.extraConfigs.hideExtraAfternoonColumn = !this.extraConfigs.hideExtraAfternoonColumn;
        userConfig.tableData.extraConfigs.hideExtraAfternoonColumn = this.extraConfigs.hideExtraAfternoonColumn;
        break;
    }

    this.calculateExtraColumnWidth();
    this.calculateExtraDayColumnWidth();
    this.calculateExtraMorningColumnWidth();
    this.calculateExtraAfternoonColumnWidth();

    this.calculateExtraWidth();

    // Calculate the number of reduced columns
    if (this.extraConfigs.hideExtraColumn) {
      reducedColumns++;
    }
    if (this.extraConfigs.hideExtraDayColumn) {
      reducedColumns++;
    }
    if (this.extraConfigs.hideExtraMorningColumn) {
      reducedColumns++;
    }
    if (this.extraConfigs.hideExtraAfternoonColumn) {
      reducedColumns++;
    }

    // Calculate the new table width
    let initialTableWidthValue = parseFloat(this.initialTableWidth);
    let newTableWidthValue = initialTableWidthValue - (reducedColumns * 22.5);
    this.tableExtraWidth = newTableWidthValue.toFixed(2) + '%';

    userConfig.tableData.tableExtraWidth = this.tableExtraWidth;

    userConfig.lastConnectedAt = new Date();
    this.saveConfigData();

    this.setTargetElementsWidth();
  }

  get blocAnesthetistsDropZone() {
    let anesthetists = [];
    this.mergedRooms.forEach((room) => {
      if (room.emptyRoom) {
        if (this.doesHospitalHaveAnesthDetailsOption) {
          if (!this.isIADEResponsible && !this.isInternResponsible) {
            anesthetists.push(
              room.firstSurgeonOpeningId + "seniorJunior" + room._id,
              room.firstSurgeonOpeningId + "interne" + room._id,
              room.firstSurgeonOpeningId + "iade" + room._id
            );
          } else {
            if (this.isInternResponsible) {
              anesthetists.push(
                room.firstSurgeonOpeningId + "interne" + room._id,
              );
            } else if (this.isIADEResponsible) {
              anesthetists.push(
                room.firstSurgeonOpeningId + "iade" + room._id
              );
            }
          }
        } else {
          anesthetists.push(
            room.firstSurgeonOpeningId + "anesthetist" + room._id,
            room.firstSurgeonOpeningId + "iade" + room._id
          );
        }
      } else {
        if (this.doesHospitalHaveAnesthDetailsOption) {
          if (!this.isIADEResponsible && !this.isInternResponsible) {
            anesthetists.push(
              room.programs[0]._id + "seniorJunior" + room._id,
              room.programs[0]._id + "interne" + room._id
            ); 

            for (const program of room.programs) {
              anesthetists.push(
                program._id + "iade" + room._id
              ); 
            }
          } else {
            if (this.isInternResponsible) {
              anesthetists.push(
                room.programs[0]._id + "interne" + room._id,
              ); 
            } else if (this.isIADEResponsible) {
              for (const program of room.programs) {
                anesthetists.push(
                  program._id + "iade" + room._id
                ); 
              }
            }
          }
        } else {
          anesthetists.push(
            room.programs[0]._id + "anesthetist" + room._id
          );
          for (const program of room.programs) {
            anesthetists.push(
              program._id + "iade" + room._id
            ); 
          }
        }
      }
    });

    return anesthetists
      .concat(this.outOfRoomAnesthetistsIds)
      .concat(["blocAnesthetistsList"])
  }

  get nursesIDs() {
    let nurses = [];
    this.mergedRooms.forEach((room) => {
      if (room.emptyRoom) {
        if (room.firstSurgeonOpeningId) {
          nurses.push(room.firstSurgeonOpeningId + room._id,
            room.firstSurgeonOpeningId + "iade" + room._id);
        }
      } else if (room.programs.length > 1) {
        room.programs.forEach((p) => {
          if (p._id) {
            nurses.push(p._id + room._id,
              p._id + "iade" + room._id);
          }
        });
      } else {
        if (room.programs[0] && room.programs[0]._id) {
          nurses.push(room.programs[0]._id + room._id,
            room.programs[0]._id + "iade" + room._id);
        }
      }
    });
    return nurses
      .concat(this.outOfRoomNursesIds)
      .concat(this.outOfRoomAnesthetistsIds)
      .concat(["nursesList"]);
  }
  

  get showAnesthsList(): boolean {
    const response =
      (
        (
          (this.canUserEditBlocAnesths && this.actifSection === 'Bloc') || 
          (this.canUserEditConsultationExtracliniqueAnesths && this.actifSection !== 'Bloc')
        ) && 
          !this.canUserSeeAndEditNurses
      ) ||
      (
        (
          (this.canUserEditBlocAnesths && this.actifSection === 'Bloc') || 
          (this.canUserEditConsultationExtracliniqueAnesths && this.actifSection !== 'Bloc')
        ) &&
          this.canUserSeeAndEditNurses &&
          this.actifProfilesView === "Anesthésistes / Iade disponibles"
      );
    return response;
  }

  get showNursesList(): boolean {
    const response =
      (this.canUserSeeAndEditNurses && !this.canUserEditBlocAnesths && this.actifProfilesView === "Hors salle (Ibode / Ide)") ||
      (this.canUserEditBlocAnesths &&
        this.canUserSeeAndEditNurses &&
        this.actifProfilesView === "Hors salle (Ibode / Ide)"  && !this.isIADEResponsible);
    return response;
  }

  get showNursesSpecialList(): boolean {
    const response =
      (this.canUserSeeAndEditNurses && !this.canUserEditBlocAnesths && this.actifProfilesView === "Horaires Partiels") ||
      (this.canUserEditBlocAnesths &&
        this.canUserSeeAndEditNurses &&
        this.actifProfilesView === "Horaires Partiels");
    return response;
  }

  get outOfRoomNursesIds(): any {
    return this.nurses.map((nurse) => {
      return nurse.profile._id;
    });
  }

  get outOfRoomAnesthetistsIds() {
    return this.blocAnesthetists.map((anesthetist) => {
      return anesthetist._id;
    });
  }

  get isDayWeek(): boolean {
    const day: number = new Date(this.getDate()).getUTCDay();
    return 0 < day && day < 6;
  }

  get isValidationView(): boolean {
    const user = this.userService.getCurrentUser();
    if (user) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const actifDay = new Date(this.day);
      return (
        this.home ||
        (
          (
            !this.doesHospitalHaveProgramsDataPipelineOption ||
            this.doesHospitalHaveAnesthDetailsOption
          ) && 
          actifDay.getTime() < today.getTime())
      );
    }
  }

  @HostListener("window:resize", [])
  private onResize() {
    if(window.innerWidth < 1760){
      this.bigScreen = false;
    }else{
      this.bigScreen = true;
    }

    this.setTargetElementsWidth();
  }

  setTargetElementsWidth() {
    let found = false;
    if (this.actifSection === 'Bloc') {
      if (this.mergedRooms && this.mergedRooms.length > 0) {
        const sourceElements = document.getElementsByClassName('profile-container2');
        for (let i = 0; i < sourceElements.length; i++) {
          const sourceElementWidth = (sourceElements[i] as HTMLElement).offsetWidth;    
          if (sourceElementWidth > 0) {
            this.draggableWidthAnesth = `${sourceElementWidth}px`;
            this.changeDetectorRef.detectChanges();
            found = true;
            break;
          }
        }
        if (!found) {
          const sourceElements11 = document.getElementsByClassName('column');
          if (sourceElements11.length >= 1) {
            const sourceElementWidth = (sourceElements11[1] as HTMLElement).offsetWidth;
            this.draggableWidthAnesth = `${sourceElementWidth}px`;
          }
        }
        const sourceElements2 = document.getElementsByClassName('empty-profile-container-paramedical');
        for (let i = 0; i < sourceElements2.length; i++) {
          const sourceElementWidth = (sourceElements2[i] as HTMLElement).offsetWidth;    
          if (sourceElementWidth > 0) {
            this.draggableWidthNurse = `${sourceElementWidth}px`;
            this.changeDetectorRef.detectChanges();
            break;
          }
        }
      }
    }
    if(this.actifSection === 'Consultation'){
      const sourceElements = document.getElementsByClassName('empty-profile-container-new');
      if (sourceElements.length > 0) {
        const sourceElementWidth = (sourceElements[0] as HTMLElement).offsetWidth;
        this.draggableWidth = `${sourceElementWidth}px`;
        this.changeDetectorRef.detectChanges();
      } else {
        const sourceElements2 = document.getElementsByClassName('column');
        if (sourceElements2.length >= 1) {
          const sourceElementWidth = (sourceElements2[1] as HTMLElement).offsetWidth;
          this.draggableWidth = `${sourceElementWidth}px`;
        }
      }
    } 
    if(this.actifSection === 'Extraclinique'){
      const sourceElements = document.getElementsByClassName('empty-profile-container-new');
      if (sourceElements.length > 0) {
        const sourceElementWidth = (sourceElements[0] as HTMLElement).offsetWidth;
        this.draggableWidth = `${sourceElementWidth}px`;
        this.changeDetectorRef.detectChanges();
      } else {
        const sourceElements2 = document.getElementsByClassName('column');
        if (sourceElements2.length >= 1) {
          const sourceElementWidth = (sourceElements2[1] as HTMLElement).offsetWidth;
          this.draggableWidth = `${sourceElementWidth}px`;
        }
      }
    }
  }

  getProgramCommentary(date: string) {
    this.programCommentary = null;
    this.comment = null;

    if (this.programCommentarySubscription) {
      this.programCommentarySubscription.unsubscribe();
    }

    this.programCommentarySubscription = this.programCommentaryService.getProgramCommentary(date).subscribe(
      (res) => {
        if (res && res.length !== 0) {
          this.programCommentary = res[0];
          this.comment = res[0].comment;
        }
      },
      (error) => {
        this.errorService.handleError(error);
      }
    );
  }

  ngDoCheck(): void {
    this.setTableMaxHeight();
    this.setMarginRightForAvailableAnesth();
    this.setMarginRightForAvailableNurse();
  }

  setTableMaxHeight(): void {
    let max = 100;
    let offset;

    if (this.availableProfilesSection) {
      max = this.availableProfilesSection.nativeElement.offsetHeight;
    }

    if (this.doesHospitalHaveAnesthDetailsOption && this.canUserSeeConsultationExtraclinique) {
      offset = 63;
    } else {
      offset = 13
    }

    this.tableMaxHeight = `calc(100% - ${max + offset}px)`;
  }

  addProgramCommentary(date: string) {
    const body: ProgramCommentary = {
      date: date,
      comment: this.comment,
      hospital: getFirstHospitalSelectedData(this.userService.getCurrentUser(), this.userService.getSelectedHospitals())
    };
    this.programCommentaryService.addProgramCommentary(body).subscribe(
      (res) => {
        if (res) {
          this.programCommentary = res;
          this.comment = res.comment;
        }
      },
      (error) => {
        this.errorService.handleError(error);
      }
    );
  }

  updateProgramCommentary(date: string) {
    const body: ProgramCommentary = {
      date: date,
      comment: this.comment,
      hospital: getFirstHospitalSelectedData(this.userService.getCurrentUser(), this.userService.getSelectedHospitals())
    };
    this.programCommentaryService
      .updateProgramCommentary(body, this.programCommentary._id)
      .subscribe(
        (res) => {
          if (res && this.comment) {
            this.programCommentary.comment = this.comment;
          }
        },
        (error) => {
          this.errorService.handleError(error);
        }
      );
  }

  getParamedicalData() {
    if (this.getParamedicalsSubscription) {
      this.getParamedicalsSubscription.unsubscribe();
    }

    return new Promise<void>((resolve, reject) => {
      this.getParamedicalsSubscription = this.paramedicalService.getParamedicals()
      .subscribe((paramedicals) => {
        this.paramedicalData = paramedicals
        resolve();
      })
    })
  }

  getRolesData() {
    if (this.getRolesSubscription) {
      this.getRolesSubscription.unsubscribe();
    }

    return new Promise<void>((resolve, reject) => {
      this.getRolesSubscription = this.paramedicalService.getRoles()
      .subscribe((roles) => {
        this.roleData = roles
        this.onRolesReception.emit(this.roleData);
        resolve();
      })
    })
  }

  async ngOnInit() {
    if (!this.userService.isIadRes() && !this.userService.isInternResponsible() && !(this.userService.isCadreBloc() && this.userService.getLevelAccess() === 4)) {
      this.sections = ["Bloc", "Consultation", "Extraclinique"]
    } else {
      this.sections = ["Bloc"]
    }

    this.isMultiHospitals = this.userService.isCurrentUserHasMultipleHospitalsSelected()
    this.levelOfAccess = this.storageService.getUser().levelOfAccess;
    this.userId = this.storageService.getUser().profile._id;

    this.getConfigData();
    let userConfig = this.getUserConfig();

    if (!userConfig) {
      this.removeOldestConfig();
      userConfig = {
        userId: this.userId,
        lastConnectedAt: new Date(),
        tableData: {},
      };
      this.configData.push(userConfig);
    }
    
    // Bloc

    if (!userConfig.tableData.blocConfigs) {
      userConfig.tableData.blocConfigs = this.blocConfigs;
    }

    await this.getRolesData();
    await this.getParamedicalData();

    this.configData.push(userConfig);
  
    // this.calculateChirurgiensItemWidth();
    // this.calculateAnesthesistItemWidth();
    // this.calculateSallesColumnWidth();
    // this.calculateParamedicalItemWidth();

    // this.calculateItemsWidth();

    // Consultation

    if (!userConfig.tableData.consultationConfigs) {
      userConfig.tableData.consultationConfigs = this.consultationConfigs;
    }

    this.configData.push(userConfig);

    this.calculateSpecialtyColumnWidth();
    this.calculateDayColumnWidth();
    this.calculateMorningColumnWidth();
    this.calculateAfternoonColumnWidth();

    this.calculateConsultationWidth();

    // Extraclinique

    if (!userConfig.tableData.extraConfigs) {
      userConfig.tableData.extraConfigs = this.extraConfigs;
    }

    this.configData.push(userConfig);

    this.calculateExtraColumnWidth();
    this.calculateExtraDayColumnWidth();
    this.calculateExtraMorningColumnWidth();
    this.calculateExtraAfternoonColumnWidth();

    this.calculateExtraWidth();

    this.blocConfigs.hideChirurgiensColumn = userConfig.tableData.blocConfigs.hideChirurgiensColumn;
    this.blocConfigs.hideSallesColumn = userConfig.tableData.blocConfigs.hideSallesColumn;
    this.blocConfigs.hideAnesthesistColumn = userConfig.tableData.blocConfigs.hideAnesthesistColumn;
    this.tableBlocWidth = userConfig.tableData.tableBlocWidth;

    this.consultationConfigs.hideSpecialtyColumn = userConfig.tableData.consultationConfigs.hideSpecialtyColumn;
    this.consultationConfigs.hideDayColumn = userConfig.tableData.consultationConfigs.hideDayColumn;
    this.consultationConfigs.hideMorningColumn = userConfig.tableData.consultationConfigs.hideMorningColumn;
    this.consultationConfigs.hideAfternoonColumn = userConfig.tableData.consultationConfigs.hideAfternoonColumn;
    this.tableConsultationWidth = userConfig.tableData.tableConsultationWidth;

    this.extraConfigs.hideExtraColumn = userConfig.tableData.extraConfigs.hideExtraColumn;
    this.extraConfigs.hideExtraDayColumn = userConfig.tableData.extraConfigs.hideExtraDayColumn;
    this.extraConfigs.hideExtraMorningColumn = userConfig.tableData.extraConfigs.hideExtraMorningColumn;
    this.extraConfigs.hideExtraAfternoonColumn = userConfig.tableData.extraConfigs.hideExtraAfternoonColumn;
    this.tableExtraWidth = userConfig.tableData.tableExtraWidth;

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const dayWithoutTime = new Date(this.day);
    dayWithoutTime.setHours(0, 0, 0, 0);
    if ((!this.isHome || !this.doesHospitalHaveProgramsDataPipelineOption)) {
      if (dayWithoutTime <= today)
      {
        this.getNonValidatedDays();
      }
      else
      {
        this.setIsProgramAlreadyValidated();
      }
    }
    moment.locale("fr");
    this.getUrgenceProfile();
    this.loadSplitNurses = false;
    this.nameDay = this.getDayName(this.day);
    this.initRolesPermissionsVariables();

    if (!this.fetchDataOnDateChange) {
      this.blocNeeds = null;      
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      this.nurses=[];
      this.nursesProfile=[];
      this.anesthetistsCalendars = [];
      this.blocAnesthetists = [];
      this.IADEsAlreadyInRoom = [];
      this.filtredAnesthesists = [];
      this.consultationExtracliniqueAnesthetists = [];

      if (this.day >= today) {
        if (!this.isHome)
          {
          await this.getAvailableNurses();
        }

        this.getNursesRankings();

        this.getAnesthsRankings();
        this.getHospitalRoomsDaysDoctorAgendas();
        this.getAvailableAnesthetists();
      }
    }
    if (!this.isHome) {
      this.getProgramCommentary(this.getDate());
    }
    await this.getRelatedData();
  }

  updateFiltredAnesthesists() {
    this.filtredAnesthesists = this.consultationExtracliniqueAnesthetists.filter((obj) => obj.currentStartTime);
    return this.filtredAnesthesists;
  }

  getSpecialties(): Observable<Specialty[]> {
    return this.specialtyService.getAllSpecialties(true);
  }

  async getRelatedData() {
    if (this.getRelatedDataSubscription) {
      this.getRelatedDataSubscription.unsubscribe();
    }

    this.isLoading[0] = true;

    const observables = [];

    // Get surgeon openings with buffer programs
    observables.push(this.getHospitalRoomsDaysSurgeonOpenings());
    
    // Get available anesthsists
    observables.push(this.getAvailableAnesthetists());
    observables.push(this.getDailyNeeds());

// yoyo


    // If hospital have the option ANESTH_DETAILS we have to show the sections 'Consultation' and 'Extraclinique'
    // In order to show 'Consultation' and 'Extraclinique' sections, we have to fetch specialties, 
    // Before fetching specialties, we check maybe we already fetched them
    if (this.doesHospitalHaveAnesthDetailsOption && !this.specialties) {
      observables.push(this.getSpecialties());
    }

    this.getRelatedDataSubscription = forkJoin(observables).subscribe(async (data) => {
      this.consultationNeeds = data[2].filter((elt) => elt.type === 'consultation').sort((a, b) => a.specialty.priority - b.specialty.priority);
      this.blocNeeds = data[2].filter((elt) => elt.type === 'bloc');

      // Add bloc needs to rooms objects
      this.initAnesthEffectifNeedsToRoomsObjects();

      // Construct objects to use in the front Consultation and Extraclinique
      if (this.doesHospitalHaveAnesthDetailsOption) {
        if (data[3]) {
          // First time fetching specialties
          this.specialties = data[3];
          this.extracliniqueSpecialty = this.specialties.find((spe) => spe.name === 'Extraclinique');
          this.consultationSpecialties = this.specialties
            .filter((spe) => spe.type.includes('consultation'))
            .sort((a, b) => {
              if (a.name === 'Anesthésie-réanimation') {
                return -1;
              }
    
              if (b.name === 'Anesthésie-réanimation') {
                return 1;
              }
    
              if (a.hospital === b.hospital) {
                return a.priority - b.priority;
              } else {
                const hospitalOrder = a.hospital.localeCompare(b.hospital);
              
                if (hospitalOrder === 0) {
                  const priorityOrder = a.priority - b.priority;
                  if (priorityOrder === 0) {
                    return a._id.localeCompare(b._id);
                  } else {
                    return priorityOrder;
                  }
                } else {
                  return hospitalOrder;
                }
              }
            });
        }

        this.constructConsultationAndExtracliniqueObjects();
      }

      this.initAvailableAnesthesists(data[1]);

      if (this.doesHospitalHaveAnesthDetailsOption) {
        await this.userOnWhichProg();
      }

      this.isLoading[0] = false;
      this.isLoading[1] = false
    }, (error) => {
      console.error(error);
      this.errorService.handleError(error)
      this.isLoading[0] = false;
    });
  }

  initAnesthEffectifNeedsToRoomsObjects(): void {
    this.mergedRooms.forEach((room) => {
      const roomId = room.isSplit ? room.roomId : room._id;

      room.roomsGroupedWith = this.getRoomsGroupedWith(roomId);

      if (this.doesHospitalHaveAnesthDetailsOption) {
        room.interneEffectif = this.getRoomEffectifByType(room, 'interne');
        room.interneNeeds = this.getRoomNeedsByType(room, 'interne');
      }

      room.anesthEffectif = this.getRoomEffectifByType(room, 'anesth');
      room.anesthNeeds = this.getRoomNeedsByType(room, 'anesth');

      room.iadeEffectif = this.getRoomEffectifByType(room, 'iade');
      room.iadeNeeds = this.getRoomNeedsByType(room, 'iade');
    });
  }

  getRoomsGroupedWith(roomId: string): any[] {
    const roomsGroupedWith = [];

    const found = this.blocNeeds.find((roomObject) => roomObject.rooms.find((e) => e._id === roomId));

    if (!found) {
      return [];
    }

    found.rooms.forEach((r) => {
      if (r._id !== roomId) {
        roomsGroupedWith.push(r);
      }
    });

    return roomsGroupedWith;
  }

  // type: 'anesth' | 'interne' | 'iade'
  getRoomEffectifByType(room: any, type: string): number {
    let effectif = 0;
    const roomId = room.isSplit ? room.roomId : room._id;

    if (room.programs && room.programs.length > 0) {
      // If we already saved
      switch (type) {
        case 'anesth':
          if (this.doesHospitalHaveAnesthDetailsOption) {
            effectif += room.programs[0].seniorJunior ? 1 : 0;
            break;
          } else {
            effectif += room.programs[0].anesthesiste ? room.programs[0].anesthesiste.position === ANESTHETIST ? 1 : 0 : 0;
            break;
          }
        case 'interne':
          effectif += room.programs[0].interne ? 1 : 0;
          break;
        case 'iade':
          if (room.programs[0].nurses) {
            room.programs[0].nurses.forEach(nurse => {
              effectif += nurse.profile.position === Iade ? 1 : 0
            });
          }
          break;
        default :
          break;
      }
    } else {
      // If we didn't save yet
      switch (type) {
        case 'anesth':
          if (this.doesHospitalHaveAnesthDetailsOption) {
            effectif += room.seniorJunior ? 1 : 0;
            break;
          } else {
            effectif += room.anesthesiste ? room.anesthesiste.position === ANESTHETIST ? 1 : 0 : 0;
            break;
          }
        case 'interne':
          effectif += room.interne ? 1 : 0;
          break;
        case 'iade':
          if (room.nurses) {
            room.nurses.forEach(nurse => {
              effectif += nurse.profile.position === Iade ? 1 : 0
            });
          }
          break;
        default :
          break;
      }
    }

    room.roomsGroupedWith.forEach((r) => {
      if (r._id !== roomId) {
        const r2 = this.mergedRooms.find((r3) => {
          const roomId2 = r3.isSplit ? r3.roomId : r3._id;

          return roomId2 === r._id;
        });
        if (r2 && r2.programs && r2.programs.length > 0 && (!r2.isSplit || r2._id === r2.roomId)) {
          switch (type) {
            case 'anesth':
              if (this.doesHospitalHaveAnesthDetailsOption) {
                if (r2.programs[0].seniorJunior) {
                  if (!room.programs || !room.programs[0].seniorJunior) {
                    effectif++;
                  } else if (room.programs[0].seniorJunior && (r2.programs[0].seniorJunior._id !== room.programs[0].seniorJunior._id)) {
                    effectif++;
                  }
                }
                break;
              } else {
                if (r2.programs[0].anesthesiste && r2.programs[0].anesthesiste.position === ANESTHETIST) {
                  if (!room.programs || !room.programs[0].anesthesiste) {
                    effectif++;
                  } else if (room.programs[0].anesthesiste && ((r2.programs[0].anesthesiste._id !== room.programs[0].anesthesiste._id))) {
                    effectif++;
                  }
                }
                break;
              }
            case 'interne':
              if (r2.programs[0].interne) {
                if (!room.programs || !room.programs[0].interne) {
                  effectif++
                } else if (room.programs[0].interne && (r2.programs[0].interne._id !== room.programs[0].interne._id)) {
                  effectif++
                }
              }
              break;
            case 'iade':
              if (r2.programs[0].nurses) {
                r2.programs[0].nurses.forEach(nurse => {
                  effectif += nurse.profile.position === Iade ? 1 : 0
                });
              }
              break;
            default :
              break;
          }
        } else if (!r2.isSplit || r2._id === r2.roomId) {
          switch (type) {
            case 'anesth':
              if (this.doesHospitalHaveAnesthDetailsOption) {
                if (r2.seniorJunior) {
                  if (!room.seniorJunior) {
                    effectif++;
                  } else if (room.seniorJunior && (r2.seniorJunior._id !== room.seniorJunior._id)) {
                    effectif++;
                  }
                }
                break;
              } else {
                if (r2.anesthesiste && r2.anesthesiste.position === ANESTHETIST) {
                  if (!room.anesthesiste) {
                    effectif++;
                  } else if (room.anesthesiste && ((r2.anesthesiste._id !== room.anesthesiste._id))) {
                    effectif++;
                  }
                }
                break;
              }
            case 'interne':
              if (r2.interne) {
                if (!room.interne) {
                  effectif++
                } else if (room.interne && (r2.interne._id !== room.interne._id)) {
                  effectif++
                }
              }
              break;
            case 'iade':
              if (r2.nurses) {
                r2.nurses.forEach(nurse => {
                  effectif += nurse.profile.position === Iade ? 1 : 0
                });
              }
              break;
            default :
              break;
          }
        }
      }
    });

    return effectif;
  }

  // type: 'anesth' | 'interne' | 'iade'
  getRoomNeedsByType(room: any, type: string): number {
    const roomId = room.isSplit ? room.roomId : room._id;
    const roomNeedsObject = this.blocNeeds.find((elt) => {
      return elt.room._id === roomId;
    });
    
    if (roomNeedsObject) {
      switch (type) {
        case 'anesth':
          if (this.doesHospitalHaveAnesthDetailsOption) {
            return (roomNeedsObject.seniorNeeds * WEIGHT.SENIOR) + (roomNeedsObject.juniorNeeds * WEIGHT.JUNIOR);
          } else {
            return roomNeedsObject.anesthNeeds;
          }
        case 'interne':
          return roomNeedsObject.internNeeds;
        case 'iade':
          return roomNeedsObject.iadeNeeds;
        default :
          return 0;
      }
    }
    return 0;
  }

  formatRoomsNames(rooms: any[]): string {
    return rooms.map((r) => r.roomNumber).join(', ');

  }

  initAvailableAnesthesists(anesthetistsCalendars: Calendar[]): void {
    this.anesthetistsCalendars = anesthetistsCalendars.filter((value) => !isAbsent(value));
    this.filterAvailableAnesthesists();

    setTimeout(() => {
      this.setTableMaxHeight();
    }, 1000);
  }

  filterAvailableAnesthesists(): void {
    let calendars;

    if (this.doesHospitalHaveAnesthDetailsOption) {
      calendars = this.anesthetistsCalendars;
    } else {
      calendars = this.anesthetistsCalendars.filter((value) => !isConsultation(value));
    }

    // Sort
    calendars = calendars.sort(((a, b) => {
      if ((isGuard(a) && isGuard(b)) || (!isGuard(a) && !isGuard(b))) {
        let positionOrder = a.profile.position.localeCompare(b.profile.position);
        if (positionOrder !== 0) {
          return positionOrder;
        } else {
          // Same position
          let residencyOrder = a.profile.residency.localeCompare(b.profile.residency);
          if (residencyOrder !== 0) {
            return residencyOrder;
          } else {
            // Same residency
            if (a.profile.position === ANESTHETIST) {
              if (this.doesHospitalHaveAnesthDetailsOption) {
                // Filter by seniority
                if (a.profile.seniority !== b.profile.seniority) {
                  switch (a.profile.seniority) {
                    case 'Senior':
                      return -1;
                    case 'Junior':
                      if (b.profile.seniority === 'Senior') {
                        return 1;
                      } else {
                        return -1;
                      }
                    case 'Interne':
                      return 1;
                  }
                }
              }
            }

            // Filter by name
            let firstNameOrder = a.profile.firstName.localeCompare(b.profile.firstName);

            if (firstNameOrder !== 0) {
              return firstNameOrder;
            } else {
              return a.profile.lastName.localeCompare(b.profile.lastName);
            }
          }
        }
      } else {
        return (isGuard(a) && !isGuard(b)) ? -1 : 1;
      }
    }));


    if (this.doesHospitalHaveAnesthDetailsOption) {
      let blocAnesths = [];
      let consultationExtracliniqueAnesths = [];

      calendars.forEach((calendar) => {
        if (!this.isProfileAssignedToConsultationOrExtracliniqueProgram(calendar.profile)) {
          
          if (!this.IADEsAlreadyInRoom.find((iade) => String(calendar.profile._id) === String(iade.profile._id))) {
            if (IADE.includes(calendar.profile.position)) {
              blocAnesths.push(
                {
                  ...calendar.profile,
                  profile: {
                    ...calendar.profile,
                    startTime: IADE.includes(calendar.profile.position) ? new Date(calendar.morningStartTime) : undefined,
                    endTime: IADE.includes(calendar.profile.position) ? new Date(calendar.afternoonEndTime) : undefined
                  },
                  role: null,
                  startTime: IADE.includes(calendar.profile.position) ? calendar.morningStartTime : undefined,
                  endTime: IADE.includes(calendar.profile.position) ? calendar.afternoonEndTime: undefined,
                  isGarde: isGuard(calendar),
                }
              );
            } else {
              blocAnesths.push(
                {
                  ...calendar.profile,
                  startTime: IADE.includes(calendar.profile.position) ? calendar.morningStartTime : undefined,
                  endTime: IADE.includes(calendar.profile.position) ? calendar.afternoonEndTime: undefined,
                  isGarde: isGuard(calendar),
                }
              );
            }
          }
          
          if (calendar.profile.position === Iade)
          {
            this.allAvailableIades.push(
              {
                ...calendar.profile,
                profile: {
                  ...calendar.profile,
                  startTime: IADE.includes(calendar.profile.position) ? new Date(calendar.morningStartTime) : undefined,
                  endTime: IADE.includes(calendar.profile.position) ? new Date(calendar.afternoonEndTime) : undefined
                },
                role: null,
                startTime: IADE.includes(calendar.profile.position) ? calendar.morningStartTime : undefined,
                endTime: IADE.includes(calendar.profile.position) ? calendar.afternoonEndTime: undefined,
                isGarde: isGuard(calendar),
              }
            );
          }
        }
        if (calendar.profile.position === ANESTHETIST && ['Senior', 'Junior'].includes(calendar.profile.seniority)) {
          if (!this.isProfileAssignedToBlocProgram(calendar.profile)) {
            let availableTime = this.getAvailableTime(calendar);

            consultationExtracliniqueAnesths.push(
              {
                anesth: calendar.profile,
                originalStartTime: calendar.morningStartTime,
                originalEndTime: calendar.afternoonEndTime,
                currentStartTime: availableTime ? availableTime.startTime : null,
                currentEndTime: availableTime ? availableTime.endTime : null,
                isGarde: isGuard(calendar)
              }
            );
          } else {
            consultationExtracliniqueAnesths.push(
              {
                anesth: calendar.profile,
                originalStartTime: calendar.morningStartTime,
                originalEndTime: calendar.afternoonEndTime,
                currentStartTime: null,
                currentEndTime: null,
                isGarde: isGuard(calendar)
              }
            );
          }
        }
      });

      this.blocAnesthetists = blocAnesths;

      this.consultationExtracliniqueAnesthetists = consultationExtracliniqueAnesths;

      this.filtredAnesthesists = this.updateFiltredAnesthesists();

      this.updateBlocAnestWithIadeAlradyInPrograms(true);

      if (this.isIADEResponsible)
      {
        this.filterBlocAnestForRespIADE();
      }
    }
    else {
      this.blocAnesthetists = calendars.filter((an) => !this.IADEsAlreadyInRoom.find((iade) => String(an.profile._id) === String(iade.profile._id))).map(an => {
        if (IADE.includes(an.profile.position)) {
          return {
            ...an.profile,
            profile: {
              ...an.profile,
              startTime: IADE.includes(an.profile.position) ? new Date(an.morningStartTime) : undefined,
              endTime: IADE.includes(an.profile.position) ? new Date(an.afternoonEndTime) : undefined
            },
            role: null,
            startTime: IADE.includes(an.profile.position) ? new Date(an.morningStartTime) : undefined,
            endTime: IADE.includes(an.profile.position) ? new Date(an.afternoonEndTime) : undefined,
            isGarde: isGuard(an)
          };
        } else {
          return {
            ...an.profile,
            startTime: IADE.includes(an.profile.position) ? new Date(an.morningStartTime) : undefined,
            endTime: IADE.includes(an.profile.position) ? new Date(an.afternoonEndTime) : undefined,
            isGarde: isGuard(an)
          };
        }
      });
      this.updateBlocAnestWithIadeAlradyInPrograms(false);

      if (this.isIADEResponsible)
      {
        this.filterBlocAnestForRespIADE();
      }
  }
}

  filterBlocAnestForRespIADE() {
    const filteredBlocAnesthetists = [];
    
    for (var i = 0; i < this.blocAnesthetists.length; i++) {
      if (this.blocAnesthetists[i].position === "Iade") {
        filteredBlocAnesthetists.push(this.blocAnesthetists[i]);
      }
    }

    this.blocAnesthetists = filteredBlocAnesthetists;
  }

  updateBlocAnestWithIadeAlradyInPrograms(AnestDetails: boolean) {

    if (this.allBufferPrograms && this.allBufferPrograms.length > 0) {
      const anesthetistsInProgram = [];
    
      this.allBufferPrograms.forEach(program => {
        program.programs.forEach(p => {
          if (!AnestDetails) {
            if (p.anesthesiste && p.anesthesiste.firstName && p.anesthesiste.lastName) {
              anesthetistsInProgram.push(p.anesthesiste);
            }
          }
          else {
            p.nurses.forEach(nurse => {
              if (nurse.profile.position === Iade) {
                anesthetistsInProgram.push(nurse);
              }
            });
          }
        });
      });
  
    const indicesToRemove = [];
    
    for (var i = 0; i < this.blocAnesthetists.length; i++) {
      if (this.blocAnesthetists[i].position === "Iade") {
        for (var j = 0; j < anesthetistsInProgram.length; j++) {
          if (this.blocAnesthetists[i]._id === anesthetistsInProgram[j]._id) {
            indicesToRemove.push(i);
            break;
          }
        }
      }
    }
  
    for (var k = indicesToRemove.length - 1; k >= 0; k--) {
      this.blocAnesthetists.splice(indicesToRemove[k], 1);
    }}
  }

  getAvailableTime(calendar: Calendar): { startTime: string | Date, endTime: string | Date } {
    let response;
    const assignedPeriod = this.getAssignedPeriod(calendar.profile._id);
    let date;
    date = new Date(moment(this.day).format("YYYY-MM-DD").toString());
    date.setUTCHours(0, 0, 0, 0);
    const hospital = getFirstHospitalSelectedData(this.userService.getCurrentUser(), this.userService.getSelectedHospitals())
    let hospitalMiddleTime;
    hospitalMiddleTime = hospital.middleTime;
    hospitalMiddleTime = new Date(dateWithTime(date, hospitalMiddleTime))

    if (assignedPeriod) {
      if (assignedPeriod === 'day') {
        response = null;
      } else if (assignedPeriod === 'morning') {
        const startTime = new Date(hospitalMiddleTime);
        const endTime = new Date(calendar.afternoonEndTime);

        if (startTime >= endTime) {
          response = null;
        } else {
          response = { startTime, endTime };
        }
      } else if (assignedPeriod === 'afternoon') {
        const startTime = new Date(calendar.morningStartTime);
        const endTime = new Date(hospitalMiddleTime);

        if (startTime >= endTime) {
          response = null;
        } else {
          response = { startTime, endTime };
        }
      } else {
        response = {
          startTime: new Date(calendar.morningStartTime),
          endTime: new Date(calendar.afternoonEndTime)
        };
      }
    } else {
      response = {
        startTime: new Date(calendar.morningStartTime),
        endTime: new Date(calendar.afternoonEndTime)
      };
    }

    return response;
  }

  getAssignedPeriod(profileId: string): string {
    let isAssignedInTheMorning = false;
    let isAssignedInTheAfternoon = false;

    // Extraclinique
    let found = this.extracliniqueObject.dayPrograms.find((elt) => elt.anesth._id === profileId);
    
    if (found) {
      return 'day';
    } else {
      found = this.extracliniqueObject.morningPrograms.find((elt) => elt.anesth._id === profileId);

      if (found) {
        isAssignedInTheMorning = true;
      }

      found = this.extracliniqueObject.afternoonPrograms.find((elt) => elt.anesth._id === profileId);

      if (found) {
        isAssignedInTheAfternoon = true;
      }

      if (isAssignedInTheMorning && isAssignedInTheAfternoon) {
        return 'day';
      }
    }

    // Consultation
    for (const item of this.consultationsObjects) {
      if (!isAssignedInTheMorning && !isAssignedInTheAfternoon) {
        found = item.dayPrograms.find((elt) => elt.anesth._id === profileId);
      } else {
        found = false;
      }

      if (found) {
        return 'day';
      } else {
        if (!isAssignedInTheMorning) {
          found = item.morningPrograms.find((elt) => elt.anesth._id === profileId);
        } else {
          found = false;
        }

        if (found) {
          isAssignedInTheMorning = true;
        }

        if (!isAssignedInTheAfternoon) {
          found = item.afternoonPrograms.find((elt) => elt.anesth._id === profileId);
        } else {
          found = false;
        }
  
        if (found) {
          isAssignedInTheAfternoon = true;
        }
  
        if (isAssignedInTheMorning && isAssignedInTheAfternoon) {
          return 'day';
        }
      }
    }

    if (isAssignedInTheMorning) {
      return 'morning';
    }

    if (isAssignedInTheAfternoon) {
      return 'afternoon';
    }

    return null;
  }

  isProfileAssignedToBlocProgram(profile: Profile): boolean {
    for (const room of this.mergedRooms) {
      let isAssigned;

      isAssigned = (room.anesthesiste && room.anesthesiste._id === profile._id) ||
        (room.seniorJunior && room.seniorJunior._id === profile._id) ||
        (room.interne && room.interne._id === profile._id) ||
        (room.iade && room.iade._id === profile._id);
        
      if (isAssigned) {
        return true;
      }

      if (room.programs) {
        isAssigned = room.programs.some((prog) => 
          (prog.anesthesiste && prog.anesthesiste._id === profile._id) ||
          (prog.seniorJunior && prog.seniorJunior._id === profile._id) ||
          (prog.interne && prog.interne._id === profile._id) ||
          (prog.iade && prog.iade._id === profile._id) ||
          (prog.nurses && prog.nurses.some((nurse) => nurse.profile._id === profile._id))
        );
      }

      if (isAssigned) {
        return true;
      }
    }

    return false;
  }

  isProfileAssignedToConsultationProgram(profile: Profile): boolean {
    const consultationAndExtracliniquePrograms = [];
    if (this.consultationsObjects) {
      this.consultationsObjects.forEach((obj) => {
        consultationAndExtracliniquePrograms.push(...obj.dayPrograms, ...obj.morningPrograms, ...obj.afternoonPrograms);
      });
    }
    const isAssigned = consultationAndExtracliniquePrograms
    .some((prog) => prog.anesth._id === profile._id);

    if(isAssigned) {
      consultationAndExtracliniquePrograms.forEach((prog) => {
        if (prog.anesth._id === profile._id) {
          this.consultDate = prog.currentStartTime;
        }
      })
    } else {
      this.consultDate = null; 
    }

    return isAssigned;
  }

  isProfileAssignedToExtracliniqueProgram(profile: Profile): boolean {
    const consultationAndExtracliniquePrograms = [];
   
    consultationAndExtracliniquePrograms.push(...this.extracliniqueObject.dayPrograms, ...this.extracliniqueObject.morningPrograms, ...this.extracliniqueObject.afternoonPrograms);

    const isAssigned = consultationAndExtracliniquePrograms
      .some((prog) => prog.anesth._id === profile._id);

    if(isAssigned) {
      consultationAndExtracliniquePrograms.forEach((prog) => {
        if (prog.anesth._id === profile._id) {
          this.extraDate = prog.currentStartTime;
        }
      })
    }
    else {
      this.consultDate = null; 
    }

    return isAssigned;
  }


  isProfileAssignedToConsultationOrExtracliniqueProgram(profile: Profile): boolean {
    const consultationAndExtracliniquePrograms = [];
    this.consultationsObjects.forEach((obj) => {
      consultationAndExtracliniquePrograms.push(...obj.dayPrograms, ...obj.morningPrograms, ...obj.afternoonPrograms);
    });
    consultationAndExtracliniquePrograms.push(...this.extracliniqueObject.dayPrograms, ...this.extracliniqueObject.morningPrograms, ...this.extracliniqueObject.afternoonPrograms);

    const isAssigned = consultationAndExtracliniquePrograms
      .some((prog) => prog.anesth._id === profile._id);

    return isAssigned;
  }

  async ngOnChanges(changes: SimpleChanges) {
    this.nameDay = this.getDayName(this.day);
    if (changes.day && this.fetchDataOnDateChange) {
      this.blocNeeds = null;
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      this.nurses=[];
      this.nursesProfile=[];
      this.anesthetistsCalendars = [];
      this.blocAnesthetists = [];
      this.IADEsAlreadyInRoom = [];
      this.consultationExtracliniqueAnesthetists = [];
      this.filtredAnesthesists = [];      
      if (this.day >= today) {
        this.getNursesRankings();
        this.getAnesthsRankings();
        if (!this.isHome) {
          await this.getAvailableNurses();
        }
        this.getHospitalRoomsDaysDoctorAgendas();
        this.getAvailableAnesthetists();
      }
      if (!this.isHome) {
        this.getProgramCommentary(this.getDate());
      }
      await this.getRelatedData();
    }
  }

  constructConsultationAndExtracliniqueObjects(): void {
    // this.allBufferPrograms contains all buffer programs of the day
    // this.specialties contains all the specialties of the hospital

    const date = new Date(moment(this.day).format("YYYY-MM-DD").toString());
    date.setUTCHours(0, 0, 0, 0);

    let consultationAndExtracliniqueBufferPrograms = this.allBufferPrograms.find((obj) => obj._id == null);
    consultationAndExtracliniqueBufferPrograms = consultationAndExtracliniqueBufferPrograms ? consultationAndExtracliniqueBufferPrograms.programs : [];
    consultationAndExtracliniqueBufferPrograms = consultationAndExtracliniqueBufferPrograms.filter((program) => program.anesthesists.length === 1);
    const extracliniqueBufferPrograms = consultationAndExtracliniqueBufferPrograms.filter((program) => program.type === 'bloc' && String(program.specialty) === this.extracliniqueSpecialty._id);
    const consultationsBufferPrograms = consultationAndExtracliniqueBufferPrograms.filter((program) => program.type === 'consultation');

    const extracliniqueDayPrograms = this.getFormattedDayPrograms(extracliniqueBufferPrograms);
    const extracliniqueMorningPrograms = this.getFormattedMorningPrograms(extracliniqueBufferPrograms);
    const extracliniqueAfternoonPrograms = this.getFormattedAfternoonPrograms(extracliniqueBufferPrograms);

    // updatedBy is the always the same for all the objects of the day
    const tmp = consultationAndExtracliniqueBufferPrograms.find((elt) => elt.updatedBy && elt.updatedAt);
    const updatedBy = tmp ? tmp.updatedBy : null;
    const updatedAt = tmp ? tmp.updatedAt : null;

    // the field anesthesists in bufferprogram contain only one anesth if the bufferprogram is for consultation or extraclinique
    this.extracliniqueObject = {
      specialty: this.extracliniqueSpecialty,
      dayPrograms: extracliniqueDayPrograms,
      morningPrograms: extracliniqueMorningPrograms,
      afternoonPrograms: extracliniqueAfternoonPrograms,
      effectif: this.calculateEffectif(extracliniqueDayPrograms, extracliniqueMorningPrograms, extracliniqueAfternoonPrograms),
      updatedAt: updatedAt,
      updatedBy: updatedBy,
      edited: false,
    }

    this.consultationsObjects = [];
    
    this.consultationSpecialties.forEach((spe) => {
      const specialtyBufferPrograms = consultationsBufferPrograms.filter((program) => String(program.specialty) === String(spe._id));

      // the field anesthesists in bufferprogram contain only one anesth if the bufferprogram is for consultation or extraclinique
      const dayPrograms = this.getFormattedDayPrograms(specialtyBufferPrograms);
      const morningPrograms = this.getFormattedMorningPrograms(specialtyBufferPrograms);
      const afternoonPrograms = this.getFormattedAfternoonPrograms(specialtyBufferPrograms);

      this.consultationsObjects.push({
        specialty: spe,
        dayPrograms: dayPrograms,
        morningPrograms: morningPrograms,
        afternoonPrograms: afternoonPrograms,
        effectif: this.calculateEffectif(dayPrograms, morningPrograms, afternoonPrograms),
        needs: this.getConsultationNeedsBySpecialtyId(spe._id),
        updatedAt: updatedAt,
        updatedBy: updatedBy,
        edited: false,
      });
    });
  }

  getConsultationNeedsBySpecialtyId(specialtyId: string): number {
    const found = this.consultationNeeds.find((elt) => elt.specialty && elt.specialty._id === specialtyId);

    const response = found ? (found.seniorNeeds * WEIGHT.SENIOR) + (found.juniorNeeds * WEIGHT.JUNIOR) + (found.internNeeds * WEIGHT.INTERN) : 0;

    return response;
  }

  get consultationAndExtracliniqueDropZones(): any[] {
    const zones = [];

    zones.push('consultationExtracliniqueAnesthetistsList', 'extraclinique-day', 'extraclinique-morning', 'extraclinique-afternoon');

    if (this.consultationSpecialties) {
      this.consultationSpecialties.forEach((spe) => zones.push(`${spe._id}-day`, `${spe._id}-morning`, `${spe._id}-afternoon`));
    }

    return zones;
  }

  prepareExtracliniqueBufferProgramsToSave(): BufferProgram[] {
    const response = [];

    let date;
    date = new Date(moment(this.day).format("YYYY-MM-DD").toString());
    date.setUTCHours(0, 0, 0, 0);
    date = date.toISOString();
    let hospital = this.userService.getSelectedHospitals()[0];
    hospital = this.userService.getCurrentUser().profile.hospitals.find((hospital_) => String(hospital_._id) === String(hospital));
    const type = 'bloc';

    const extracliniqueId = this.extracliniqueObject.specialty._id;

    this.extracliniqueObject.dayPrograms.forEach((program) => {
      response.push({
        hospital: hospital._id,
        date,
        anesthesists: [program.anesth._id],
        startTime: new Date(dateWithTime(date, hospital.startTime)).toISOString(),
        endTime: new Date(dateWithTime(date, hospital.endTime)).toISOString(),
        type,
        specialty: extracliniqueId,
      });
    });

    this.extracliniqueObject.morningPrograms.forEach((program) => {
      response.push({
        hospital: hospital._id,
        date,
        anesthesists: [program.anesth._id],
        startTime: new Date(dateWithTime(date, hospital.startTime)).toISOString(),
        endTime: new Date(dateWithTime(date, hospital.middleTime)).toISOString(),
        type,
        specialty: extracliniqueId,
      });
    });

    this.extracliniqueObject.afternoonPrograms.forEach((program) => {
      response.push({
        hospital: hospital._id,
        date,
        anesthesists: [program.anesth._id],
        startTime: new Date(dateWithTime(date, hospital.middleTime)).toISOString(),
        endTime: new Date(dateWithTime(date, hospital.endTime)).toISOString(),
        type,
        specialty: extracliniqueId,
      });
    });

    return response;
  }

  prepareConsultationBufferProgramsToSave(): BufferProgram[] {
    const response = [];

    let date;
    date = new Date(moment(this.day).format("YYYY-MM-DD").toString());
    date.setUTCHours(0, 0, 0, 0);
    date = date.toISOString();
    let hospital = this.userService.getSelectedHospitals()[0]
    const type = 'consultation';
    const currentUser = this.userService.getCurrentUser();
    hospital = currentUser.profile.hospitals.find((hospital_) => String(hospital_._id) === String(hospital))
    
    this.consultationsObjects.forEach((spe) => {
      const specialtyId = spe.specialty._id;

      spe.dayPrograms.forEach((program) => {
        response.push({
          hospital: hospital._id,
          date,
          anesthesists: [program.anesth._id],
          startTime: new Date(dateWithTime(date, hospital.startTime)).toISOString(),
          endTime: new Date(dateWithTime(date, hospital.endTime)).toISOString(),
          type,
          specialty: specialtyId,
        });
      });

      spe.morningPrograms.forEach((program) => {
        response.push({
          hospital: hospital,
          date,
          anesthesists: [program.anesth._id],
          startTime: new Date(dateWithTime(date, hospital.startTime)).toISOString(),
          endTime: new Date(dateWithTime(date, hospital.middleTime)).toISOString(),
          type,
          specialty: specialtyId,
        });
      });

      spe.afternoonPrograms.forEach((program) => {
        response.push({
          hospital: hospital,
          date,
          anesthesists: [program.anesth._id],
          startTime: new Date(dateWithTime(date, hospital.middleTime)).toISOString(),
          endTime: new Date(dateWithTime(date, hospital.endTime)).toISOString(),
          type,
          specialty: specialtyId,
        });
      });
    })

    return response;
  }

  // Remove all programs from object
  removeAllProgramsFromObject(item: any): void {
    // I am looping backwards in the following loops because i delete elements while looping,
    //  and looping forwards will skip elements because we are increasing the index in each iteration
    // and we are changing the array length because we are deleting
    let tmpCpt;

    tmpCpt = item.dayPrograms.length - 1;
    while (tmpCpt >= 0) {
      this.removeAnesthetistFromProgramObject(item.dayPrograms, tmpCpt, item, null, false);
      tmpCpt -= 1;
    }

    tmpCpt = item.morningPrograms.length - 1;
    while (tmpCpt >= 0) {
      this.removeAnesthetistFromProgramObject(item.morningPrograms, tmpCpt, item, null, false);
      tmpCpt -= 1;
    }

    tmpCpt = item.afternoonPrograms.length - 1;
    while (tmpCpt >= 0) {
      this.removeAnesthetistFromProgramObject(item.afternoonPrograms, tmpCpt, item, null, false);
      tmpCpt -= 1;
    }

    item.edited = true;

    item.effectif = 0;

    this.isProgramsChanged = true;
  }

  checkBeforeDropProfile(profileWithHours: any, period: string): boolean {
    const hospital = getFirstHospitalSelectedData(this.userService.getCurrentUser(), this.userService.getSelectedHospitals())

    let date;
    date = new Date(moment(this.day).format("YYYY-MM-DD").toString());
    date.setUTCHours(0, 0, 0, 0);

    let startTime;
    let endTime;

    switch (period) {
      case 'day':
        startTime = new Date(dateWithTime(date, hospital.startTime));
        endTime = new Date(dateWithTime(date, hospital.endTime));
        break;
      case 'morning':
        startTime = new Date(dateWithTime(date, hospital.startTime));
        endTime = new Date(dateWithTime(date, hospital.middleTime));
        break;
      case 'afternoon':
        startTime = new Date(dateWithTime(date, hospital.middleTime));
        endTime = new Date(dateWithTime(date, hospital.endTime));
        break;
      default:
        startTime = new Date(dateWithTime(date, hospital.startTime));
        endTime = new Date(dateWithTime(date, hospital.endTime));
        break;
    }

    if (new Date(profileWithHours.currentStartTime) <= startTime && new Date(profileWithHours.currentEndTime) >= endTime) {
      return true;
    }

    return false;
  }

  dropInNurses(event: any, roomIndex: any, progIndex?: any): void {
    // progIndex ---> -1 if room is empty
    // progIndex ---> null if room is not empty and no special configuration
    // progIndex ---> >= 0 if room is not empty and special configuration
    const sourceArrayId = event.previousContainer.id;
    const sourceArray = event.previousContainer.data;
    const sourceIndex = event.previousIndex;

    const destinationArrayId = event.container.id;
    const destinationArray = event.container.data;    
    const destinationIndex = event.currentIndex;

    const item = sourceArray[sourceIndex];

    const droppedRoom = this.mergedRooms[roomIndex];

    const isDraggingFromInside = this.draggedItem.room >= 0;

    if (sourceArray[sourceIndex].profile && (IADE.includes(sourceArray[sourceIndex].profile.position) || NURSE_TYPES.includes(sourceArray[sourceIndex].profile.position))) {
      if (!isDraggingFromInside && !this.canDropInRoomSplit(sourceArray[sourceIndex], roomIndex, progIndex)) {
        return;
      }
      if (progIndex === -1) {
        if (!this.mergedRooms[roomIndex].nurses) {
          this.mergedRooms[roomIndex].nurses = []
        }
        if (destinationArray.indexOf(sourceArray[sourceIndex]) === -1) {
          if (sourceArrayId !== destinationArrayId) {
            this.duplicateValue(
              roomIndex,
              progIndex,
              "nurses",
              sourceArray[sourceIndex],
              true
              );
            this.mergedRooms[roomIndex].nurses.splice(destinationIndex, 0, JSON.parse(JSON.stringify(sourceArray[sourceIndex])))
            if (IADE.includes(sourceArray[sourceIndex].profile.position)) {
              if (this.IADEsAlreadyInRoom.indexOf(sourceArray[sourceIndex]) === -1) {
                this.IADEsAlreadyInRoom.push(sourceArray[sourceIndex])
              }
            } else {
              this.setNewNursesCard(droppedRoom, "nurses", roomIndex, -1, this.draggedItem)
            }
            if (this.draggedItem.program == undefined && this.draggedItem.room >= 0) {
              // Delete profile from all the programs
              const profileId = sourceArray[sourceIndex].profile._id;
              for (const program of this.mergedRooms[this.draggedItem.room].programs) {
                const index = program.nurses.findIndex((e) => String(e.profile._id) === String(profileId));
                program.nurses.splice(index, 1)
              }
            } else {
              sourceArray.splice(sourceArray.indexOf(sourceArray[sourceIndex]), 1)
            }
          } else {
            moveItemInArray(destinationArray, sourceIndex, destinationIndex)
          }
        }
      } else {
        if (progIndex == null) {
          // all programs of room (pas de configuration spéciale)
          if (destinationArray.indexOf(sourceArray[sourceIndex]) === -1) {
            if (sourceArrayId !== destinationArrayId) {
              if (IADE.includes(sourceArray[sourceIndex].profile.position)) {
                if (this.IADEsAlreadyInRoom.indexOf(sourceArray[sourceIndex]) === -1) {
                  this.IADEsAlreadyInRoom.push(sourceArray[sourceIndex])
                }
              } else {
                this.dynamicMarginsForAvailableProfiles.draggingNurseIndex = -1;
              }

              for (const [programIndex, program] of droppedRoom.programs.entries()) {
                program.nurses.splice(destinationIndex, 0, JSON.parse(JSON.stringify(sourceArray[sourceIndex])))
              }

              if (!IADE.includes(sourceArray[sourceIndex].profile.position)) {
                this.setNewNursesCard(droppedRoom, "nurses", roomIndex, 0, this.draggedItem)
              }

              if (this.draggedItem.program == undefined && this.draggedItem.room >= 0) {
                // Delete profile from all the programs
                const profileId = sourceArray[sourceIndex].profile._id;
                for (const program of this.mergedRooms[this.draggedItem.room].programs) {
                  const index = program.nurses.findIndex((e) => String(e.profile._id) === String(profileId));
                  program.nurses.splice(index, 1)
                }
              } else {
                sourceArray.splice(sourceArray.indexOf(sourceArray[sourceIndex]), 1)
              }
            } else {
              moveItemInArray(destinationArray, sourceIndex, destinationIndex)
            }
          }
        } else {
          if (destinationArray.indexOf(sourceArray[sourceIndex]) === -1) {
            if (sourceArrayId !== destinationArrayId) {
              destinationArray.splice(destinationIndex, 0, JSON.parse(JSON.stringify(sourceArray[sourceIndex])))
              if (IADE.includes(sourceArray[sourceIndex].profile.position)) {
                if (this.IADEsAlreadyInRoom.indexOf(sourceArray[sourceIndex]) === -1) {
                  this.IADEsAlreadyInRoom.push(sourceArray[sourceIndex])
                }
              } else {
                this.dynamicMarginsForAvailableProfiles.draggingNurseIndex = -1;
                this.setNewNursesCard(droppedRoom, "nurses", roomIndex, progIndex, this.draggedItem)
              }
              if (this.draggedItem.program == undefined && this.draggedItem.room >= 0) {
                const profileId = sourceArray[sourceIndex].profile._id;
                // Delete profile from all the programs
                for (const program of this.mergedRooms[this.draggedItem.room].programs) {
                  const index = program.nurses.findIndex((e) => String(e.profile._id) === String(profileId));
                  program.nurses.splice(index, 1)
                }
              } else {
                sourceArray.splice(sourceArray.indexOf(sourceArray[sourceIndex]), 1)
              }
            } else {
              moveItemInArray(destinationArray, sourceIndex, destinationIndex)
            }
          }
        }
      }
      this.initAnesthEffectifNeedsToRoomsObjects();
      this.isProgramsChanged = true;
    } else {
      this.toastService.infoToast(`Cette zone de dépôt ne peut contenir que des Infirmière ou IADE`);
    }
  }

  handleRoleChange(role, nurse) {
    if (role && nurse) {
      nurse.role = JSON.parse(JSON.stringify(role));
      this.changeDetectorRef.detectChanges();
    }
  }

  dropInConsultationOrExtraclinique(event: any): void {
    const sourceArrayId = event.previousContainer.id;
    const sourceArray = event.previousContainer.data;
    const sourceIndex = event.previousIndex;

    const destinationArrayId = event.container.id;
    const destinationArray = event.container.data;    
    const destinationIndex = event.currentIndex;

    const item = sourceArray[sourceIndex];

    let dropAllowed = false;

    if (destinationArrayId === 'consultationExtracliniqueAnesthetistsList') {
      dropAllowed = true;
    } else {
      let period;
      if (destinationArrayId.includes('day')) {
        period = 'day';
      } else if (destinationArrayId.includes('morning')) {
        period = 'morning';
      } else if (destinationArrayId.includes('afternoon')) {
        period = 'afternoon';
      }

      dropAllowed = this.checkBeforeDropProfile(item, period);
    }

    if (dropAllowed && sourceArrayId !== destinationArrayId) {
      const source = {
        id: sourceArrayId,
        array: sourceArray,
        index: sourceIndex
      };

      const destination = {
        id: destinationArrayId,
        array: destinationArray,
        index: destinationIndex
      };

      this.dropAnesth(source, destination);
      this.isProgramsChanged = true;
    }
    
    // Refresh effectif and mark objects as edited
    if (this.actifSection === 'Consultation') {
      this.markConsultationObjectsEditedById([sourceArrayId, destinationArrayId]);
      this.refreshEffectifInConsultationObjects();
    } else if (this.actifSection === 'Extraclinique') {
      this.refreshEffectifInExtracliniqueObject();
      this.extracliniqueObject.edited = true;
    }

    this.dynamicMarginsForAvailableProfiles.draggingConsultationExtracliniqueAnesthIndex = -1;
  }

  dropAnesth(source: { id: string, array: any[], index: number }, destination: { id: string, array: any[], index: number }): void {
    const hospital = getFirstHospitalSelectedData(this.userService.getCurrentUser(), this.userService.getSelectedHospitals())

    let date;
    date = new Date(moment(this.day).format("YYYY-MM-DD").toString());
    date.setUTCHours(0, 0, 0, 0);
    const hospitalStartTime = new Date(dateWithTime(date, hospital.startTime));
    const hospitalMiddleTime = new Date(dateWithTime(date, hospital.middleTime));
    const hospitalEndTime = new Date(dateWithTime(date, hospital.endTime));

    const item = source.array[source.index];
    const destinationItem = JSON.parse(JSON.stringify(item));
    

    if (source.id === 'consultationExtracliniqueAnesthetistsList') {
      // Droping from outside the room

      const index = this.consultationExtracliniqueAnesthetists.findIndex((obj) => obj.anesth._id === item.anesth._id);

      if (destination.id.includes('day')) {
        // Droping into day programs

        this.consultationExtracliniqueAnesthetists[index].currentStartTime = null;
        this.consultationExtracliniqueAnesthetists[index].currentEndTime = null;
        destination.array.splice(destination.index, 0, destinationItem);
      }

      if (destination.id.includes('morning')) {
        // Droping into morning programs

        if ((new Date(item.currentEndTime).getTime() - hospitalMiddleTime.getTime()) === 0) {
          this.consultationExtracliniqueAnesthetists[index].currentStartTime = null;
          this.consultationExtracliniqueAnesthetists[index].currentEndTime = null;
        } else {
          this.consultationExtracliniqueAnesthetists[index].currentStartTime = hospitalMiddleTime;
        }

        destinationItem.currentStartTime = new Date(dateWithTime(date, hospital.startTime));
        destinationItem.currentEndTime = hospitalMiddleTime;
        destination.array.splice(destination.index, 0, destinationItem);
      }

      if (destination.id.includes('afternoon')) {
        if ((hospitalMiddleTime.getTime() - new Date(item.currentStartTime).getTime()) === 0) {
          this.consultationExtracliniqueAnesthetists[index].currentStartTime = null;
          this.consultationExtracliniqueAnesthetists[index].currentEndTime = null;
        } else {
          this.consultationExtracliniqueAnesthetists[index].currentEndTime = hospitalMiddleTime;
        }

        destinationItem.currentStartTime = hospitalMiddleTime;
        destinationItem.currentEndTime = new Date(dateWithTime(date, hospital.endTime));
        destination.array.splice(destination.index, 0, destinationItem);
      }
    } else {
      if (destination.id === 'consultationExtracliniqueAnesthetistsList') {
        this.removeAnesthetistFromProgramObject(source.array, source.index, null, null, false);
      }

      if (destination.id.includes('morning')) {
        // source is day programs

        // Delete item from day programs
        source.array.splice(source.index, 1);

        const found = this.consultationExtracliniqueAnesthetists.find((elt) => elt.anesth._id === item.anesth._id);

        if (found) {
          found.currentStartTime = hospitalMiddleTime;
          found.currentEndTime = found.originalEndTime;
        }

        destinationItem.currentStartTime = hospitalStartTime;
        destinationItem.currentEndTime = hospitalMiddleTime;
        destination.array.splice(destination.index, 1, destinationItem);
      }

      if (destination.id.includes('afternoon')) {
        // source is day programs

        // Delete item from day programs
        source.array.splice(source.index, 1);

        // Push item to available profiles
        const found = this.consultationExtracliniqueAnesthetists.find((elt) => elt.anesth._id === item.anesth._id);

        if (found) {
          found.currentStartTime = found.originalStartTime;
          found.currentEndTime = hospitalMiddleTime;
        }

        destinationItem.currentStartTime = hospitalMiddleTime;
        destinationItem.currentEndTime = hospitalEndTime;
        destination.array.splice(destination.index, 1, destinationItem);
      }

      if (destination.id.includes('day')) {
        // source is day programs

        // Delete item from day programs
        source.array.splice(source.index, 1);

        // Push item to available profiles
        const found = this.consultationExtracliniqueAnesthetists.find((elt) => elt.anesth._id === item.anesth._id);

        if (found) {
          found.currentStartTime = null;
          found.currentEndTime = null;
        }

        destinationItem.currentStartTime = hospitalStartTime;
        destinationItem.currentEndTime = hospitalEndTime;
        destination.array.splice(destination.index, 1, destinationItem);
      }
    }
  }

  markConsultationObjectsEditedById(compoundIds: string[]): void {
    const indexesToMarkedAsEdited = this.indexInConsultationsObjectsById(compoundIds);

    indexesToMarkedAsEdited.forEach((index) => {
      this.consultationsObjects[index].edited = true;
    })
  }

  indexInConsultationsObjectsById(compoundIds: string[]): number[] {
    // compoundId : '{specialtyId}-{type}'
    // type : 'day' | 'morning' | 'afternoon'

    const ids = compoundIds.map((id) => id.split('-')[0]);

    let response = [];
    let hits = 0;
    let i = 0;

    while (hits < ids.length && i < this.consultationsObjects.length) {
      const index = ids.findIndex((id) => id === this.consultationsObjects[i].specialty._id);
      if (index >= 0) {
        response[index] = i;
        hits += 1;
      }
      i += 1;
    }

    return response;
  }

  getDailyNeeds(){
    const date = this.getDate();

    return this.roomsService.getAnesthDailyNeeds(date);
  }

  removeAnesthetistFromProgramObject(programsArray: any[], programIndex: number, object = null, type = null, refreshEffectif: boolean = true): void {
    const item = programsArray[programIndex];
    programsArray.splice(programIndex, 1);

    if (object) {
      object.edited = true;
    }

    const found = this.consultationExtracliniqueAnesthetists.find((elt) => elt.anesth._id === item.anesth._id);

    if (found) {
      let minStartTime, maxEndTime;

      if (found.currentStartTime && found.currentEndTime) {
        minStartTime = Math.min(new Date(found.currentStartTime).getTime(), new Date(item.currentStartTime).getTime());
        maxEndTime = Math.max(new Date(found.currentEndTime).getTime(), new Date(item.currentEndTime).getTime());
      } else {
        minStartTime = new Date(item.currentStartTime);
        maxEndTime = new Date(item.currentEndTime);
      }

      found.currentStartTime = new Date(minStartTime);
      found.currentEndTime = new Date(maxEndTime);
    }

    if (refreshEffectif) {
      if (type === 'consultation') {
        this.refreshEffectifInConsultationObjects();
      } else if (type === 'extraclinique') {
        this.refreshEffectifInExtracliniqueObject();
      }
    }

    this.isProgramsChanged = true;
  }

  refreshEffectifInConsultationObjects(): void {
    this.consultationsObjects.forEach((elt) => {
      elt.effectif = this.calculateEffectif(elt.dayPrograms, elt.morningPrograms, elt.afternoonPrograms);
    })
  }

  refreshEffectifInExtracliniqueObject(): void {
    this.extracliniqueObject.effectif = this.calculateEffectif(this.extracliniqueObject.dayPrograms, this.extracliniqueObject.morningPrograms, this.extracliniqueObject.afternoonPrograms);
  }

  calculateEffectif(dayPrograms: any[], morningPrograms: any[], afternoonPrograms: any[]): number {
    let response = 0;

    dayPrograms.forEach((program) => {
      switch (program.anesth.seniority) {
        case 'Senior':
          response += WEIGHT.SENIOR;
          break;
        case 'Junior':
          response += WEIGHT.JUNIOR;
          break;
        case 'Interne':
          response += WEIGHT.INTERN;
          break;
        default:
          response += 1;
          break;
      }
    });

    morningPrograms.forEach((program) => {
      switch (program.anesth.seniority) {
        case 'Senior':
          response += WEIGHT.SENIOR / 2;
          break;
        case 'Junior':
          response += WEIGHT.JUNIOR / 2;
          break;
        case 'Interne':
          response += WEIGHT.INTERN / 2;
          break;
        default:
          response += 0.5;
          break;
      }
    });

    afternoonPrograms.forEach((program) => {
      switch (program.anesth.seniority) {
        case 'Senior':
          response += WEIGHT.SENIOR / 2;
          break;
        case 'Junior':
          response += WEIGHT.JUNIOR / 2;
          break;
        case 'Interne':
          response += WEIGHT.INTERN / 2;
          break;
        default:
          response += 0.5;
          break;
      }
    });

    return response;
  }

  initRolesPermissionsVariables(): void {
    const isCadreBlocWithHighLevel = this.userService.isCadreBlocWithHighLevel();
    const isCadreBlocWithMediumLevel = this.userService.isCadreBlocWithMediumLevel();
    const isAnesthResponsable = this.userService.isAnestgWithHighLevel();
    this.isIADEResponsible = this.userService.isIadRes();
    this.isInternResponsible = this.userService.isInternResponsible();

    if (isCadreBlocWithHighLevel || isCadreBlocWithMediumLevel) {
      this.canUserSeeAndEditNurses = true;
    }

    // Init this.canUserSeeConsultationExtraclinique
    // if (isCadreBlocWithHighLevel || isAnesthResponsable || this.isInternResponsible || (isAnesth && this.userService.getCurrentUser().profile.seniority !== 'Interne')) {
    //   this.canUserSeeConsultationExtraclinique = true;
    // }

    this.canUserSeeConsultationExtraclinique = true;

    // Init this.canUserEditBlocAnesths
    if (isCadreBlocWithHighLevel || isAnesthResponsable || this.isInternResponsible || this.isIADEResponsible || this.userService.isAnesthTitulaireWith2LevelAccess()) {
      this.canUserEditBlocAnesths = true;
    }

    // Init this.canUserEditConsultationExtracliniqueAnesths
    if (isCadreBlocWithHighLevel || (isAnesthResponsable && !this.isInternResponsible) || this.userService.isAnesthTitulaireWith2LevelAccess()) {
      this.canUserEditConsultationExtracliniqueAnesths = true;
    }

    if (!this.canUserEditBlocAnesths) {
      this.profilesViews.splice(0, 1);
      this.actifProfilesView = 'Hors salle (Ibode / Ide)';
    }

    // Init canRedirect variables
    if (isCadreBlocWithHighLevel) {
      this.canRedirectToSurgeon = true;
      this.canRedirectToAnesthesiste = true;
      this.canRedirectToIADE = true;
      this.canRedirectToSeniorJunior = true;
      this.canRedirectToInterne = true;
      this.canRedirectToNurse = true;
    }

    if (isCadreBlocWithMediumLevel) {
      this.canRedirectToSurgeon = true;
      this.canRedirectToNurse = true;
    }

    if (isAnesthResponsable && !this.isInternResponsible) {
      this.canRedirectToSurgeon = true;
      this.canRedirectToAnesthesiste = true;
      this.canRedirectToIADE = true;
      this.canRedirectToSeniorJunior = true;
      this.canRedirectToInterne = true;
    }

    if (isAnesthResponsable && this.isInternResponsible) {
      this.canRedirectToSurgeon = true;
      this.canRedirectToInterne = true;
    }

    if (this.isIADEResponsible) {
      this.canRedirectToIADE = true;
    }
  }

  getNursesRankings(): void {
    if(this.isHome){
      return;
    }
    
    if (this.getNursesRankingsSubscription) {
      this.getNursesRankingsSubscription.unsubscribe();
    }

    this.nursesRankingsSubject.next(null);
    this.getNursesRankingsSubscription = this.pythonAPIService.getNursesRanking(this.getDate()).subscribe((response) => {
      this.nursesRankingsSubject.next(response);
    });
  }

  getAnesthsRankings(): void {
    if(this.isHome){
      return;
    }

    if (!this.doesHospitalHaveAnesthDetailsOption) {
      return;
    }

    if (this.getAnesthsRankingsSubscription) {
      this.getAnesthsRankingsSubscription.unsubscribe();
    }

    this.anesthsRankingsSubject.next(null);
    
    this.getAnesthsRankingsSubscription = this.pythonAPIService.getAnesthRanking(this.getDate()).subscribe((response) => {
      this.anesthsRankingsSubject.next(response);
    });
  }

  formatHours(date: Date): string {
    const d = new Date(date);
    let hours = `${d.getHours()}`;
    let minutes = `${d.getMinutes()}`;

    if (hours.length < 2) {
      hours = `0${hours}`;
    }

    if (minutes.length < 2) {
      minutes = `0${minutes}`;
    }

    return `${hours} h ${minutes}`;
  }

  getUrgenceProfile() {
    if (this.getUrgenceProfileSubscription) {
      this.getUrgenceProfileSubscription.unsubscribe();
    }

    this.getUrgenceProfileSubscription = this.profileService
      .getProfiles(1, 1, "Urgences")
      .subscribe(
        (res) => {
          this.urgenceProfile = res.docs[0];
        },
        (error) => {
          this.isLoading[0] = false;
          this.errorService.handleError(error);
        }
      );
  }

  getAvailableAnesthetists(): Observable<Calendar[]> {
    this.anesthetistsCalendars = [];
    this.blocAnesthetists = [];
    this.IADEsAlreadyInRoom = [];
    this.consultationExtracliniqueAnesthetists = [];

    const date = new Date(moment(this.day).format("YYYY-MM-DD").toString());
    date.setUTCHours(0, 0, 0, 0);
    const from = moment.utc(date).toDate();
    from.setUTCHours(5, 0, 0, 0);
    const to = moment.utc(date).toDate();
    to.setUTCHours(23, 0, 0, 0);
    const period = {
      from: from.toISOString(),
      to: to.toISOString(),
    };

    return this.profileService.getProfilesWithTimes(ANESTHETIST, null, period);
  }

  getSplitNurses() {
    this.loadSplitNurses = true;
    let nurses = [];

    if (this.mergedRooms.length == 0) return;
    let room = this.mergedRooms.filter((elem) => {
      let valid = 0;
      if (!elem.programs) return false;
      elem.programs.forEach((prog) => {
        if (prog.nurses && prog.nurses.length > 0) valid++;
      });
      return valid > 0;
    });
    room.forEach((elems) => {
      if (elems.programs) {
        elems.programs.forEach((elem, index) => {
          if (elem.nurses) {
            elem.nurses.forEach(nurse => {
              if (!IADE.includes(nurse.profile.position)) {
                nurses.push({
                  nurse: nurse,
                  index: index,
                  position: "nurses",
                  startTime: elem.startTime ? elem.startTime : null,
                  endTime: elem.endTime ? elem.endTime : null,
                });
              } else {
                this.IADEsAlreadyInRoom.push(nurse)
              }
            });
          }
        });
      }

      // if (elems.programs.length > 1) {
      //   if (!this.isBPsHaveSameProfil(elems, 'nurses')) {
      //     elems.programs.forEach((elem, index) => {
      //       if (elem.nurses) {
      //         elem.nurses.forEach(nurse => {
      //           if (!IADE.includes(nurse.profile.position)) {
      //             nurses.push({
      //               nurse: nurse,
      //               index: index,
      //               position: "nurses",
      //               startTime: elem.startTime ? elem.startTime : null,
      //               endTime: elem.endTime ? elem.endTime : null,
      //             });
      //           } else {
      //             this.IADEsAlreadyInRoom.push(nurse)
      //           }
      //         });
      //       }
      //     });
      //   }
      // } else {
      //   elems.programs.forEach((elem, index) => {
      //     if (elem.nurses) {
      //       elem.nurses.forEach(nurse => {
      //         if (!IADE.includes(nurse.profile.position)) {
      //           nurses.push({
      //             nurse: nurse,
      //             index: index,
      //             position: "nurses",
      //             startTime: elem.startTime ? elem.startTime : null,
      //             endTime: elem.endTime ? elem.endTime : null,
      //           });
      //         } else {
      //           this.IADEsAlreadyInRoom.push(nurse)
      //         }
      //       });
      //     }
      //   });
      // }
    });
    let date;
    let card;
    let newNurses = nurses.reduce(function (acc, val) {
      let index = acc.indexOf(val.nurse.profile._id);
      if (index === -1) {
        acc.push(val.nurse.profile._id);
      }
      return acc;
    }, []);

    newNurses.forEach((elem) => {
      let nurseHours = nurses.filter((n) => n.nurse.profile._id === elem);
      nurseHours.sort(function (a, b) {
        let dateA = new Date(a.startTime).getUTCHours();
        let dateB = new Date(b.startTime).getUTCHours();
        return dateA - dateB;
      });
      if (
        nurseHours[0].nurse.profile.calendar[0] &&
        nurseHours[0].nurse.profile.calendar[0].morningStartTime &&
        nurseHours[0].startTime > nurseHours[0].nurse.profile.calendar[0].morningStartTime
      ) {
        date = this.formatDate(nurseHours[0].startTime);
        card = JSON.parse(JSON.stringify(nurseHours[0].nurse));
        card.profile["startTime"] = nurseHours[0].nurse.profile.calendar[0].morningStartTime;
        card.profile["endTime"] = nurseHours[0].startTime;
        this.addToAvailableNurses(card);
      }
      if (
        nurseHours[0].nurse.profile.calendar[0] && nurseHours[nurseHours.length - 1].endTime <
        nurseHours[0].nurse.profile.calendar[0].afternoonEndTime
        ) {
        date = this.formatDate(nurseHours[0].startTime);
        card = JSON.parse(JSON.stringify(nurseHours[0].nurse));
        card.profile["startTime"] = nurseHours[nurseHours.length - 1].endTime;
        card.profile["endTime"] = nurseHours[0].nurse.profile.calendar[0].afternoonEndTime;
        this.addToAvailableNurses(card);
      }
      if (nurseHours.length > 1) {
        for (let i: number = 1; i < nurseHours.length; i++) {
          if (nurseHours[i].startTime > nurseHours[i - 1].endTime) {
            date = this.formatDate(nurseHours[0].startTime);
            card = JSON.parse(JSON.stringify(nurseHours[nurseHours.length - 1].nurse));
            card.profile["startTime"] = nurseHours[i - 1].endTime;
            card.profile["endTime"] = nurseHours[i].startTime;
            this.addToAvailableNurses(card);
          }
        }
      }
    });
  }

  getAvailableNurses() {
    return new Promise<void>(async (resolve, reject) => {
      this.nurses = [];
      const date = moment(this.day).format("YYYY-MM-DD").toString();
      await new Promise<void>((resolve1, reject1) => {
        if (this.nursesSubscription) {
          this.nursesSubscription.unsubscribe();
        }
        this.nursesSubscription = this.profileService
          .getOutOfRoomNurses(date)
          .subscribe(
            (nurses: any) => {
              nurses = nurses.map((v) => ({profile: v, isOriginOutOfRoom: true, role: null }));
              nurses = nurses.concat(this.nurses);
              this.nurses = nurses.sort((a, b) => {
                return `${a.profile.firstName.toLowerCase()} ${a.profile.lastName.toLowerCase()}`.localeCompare(
                  `${b.profile.firstName.toLowerCase()} ${b.profile.lastName.toLowerCase()}`
                );
              });
    
              setTimeout(() => {
                this.setTableMaxHeight();
              }, 1000);
              resolve1();
            },
            (error) => {
              this.errorService.handleError(error);
              reject1();
              reject();
            }
          );
      })
  
      await new Promise<void>((resolve2, reject2) => {
        if (this.availableNursesSubscription) {
          this.availableNursesSubscription.unsubscribe();
        }
    
        this.availableNursesSubscription = this.profileService.getAllAvailableNurses(date).subscribe(
          (profile : Profile[]) =>  {
            this.nursesProfile = profile;
            resolve2();
          },
          (error) => {
            this.errorService.handleError(error);
            reject2();
            reject();
          }
        )
      })
      resolve();
    })
  }

  getHospitalRoomsDaysDoctorAgendas() {
    if (this.getHospitalRoomsSubscription) {
      this.getHospitalRoomsSubscription.unsubscribe();
    }
    this.rooms = [];
    const date: string = moment(this.day).format("YYYY-MM-DD").toString();
    this.getHospitalRoomsSubscription = this.roomsService
      .getHospitalRoomsDayDoctorAgendas(date)
      .subscribe(
        (rooms) => {
          this.rooms = rooms;
          sortRoomsByPriority(this.rooms, false);
          sortRoomsByPriority(this.roomsSurgeonOpenings, false);
        },
        (error) => this.errorService.handleError(error)
      );
  }

  isCardAssignTwoHours(nurse: any) {
    let startTimeString = this.getStartTime(nurse.profile.startTime);
    let startTimeNumber = parseInt(startTimeString.replace(":", ""), 10);

    let endTimeString = this.getStartTime(nurse.profile.endTime);
    let endTimeNumber = parseInt(endTimeString.replace(":", ""), 10);
    
    if (startTimeNumber + 200 < endTimeNumber) {
      return false;
    }
    return true;
  }

  generateId() {
    const ObjectId = (
      m = Math,
      d = Date,
      h = 16,
      s = (s) => m.floor(s).toString(h)
    ) =>
      s(d.now() / 1000) + " ".repeat(h).replace(/./g, () => s(m.random() * h));
    return ObjectId();
  }

  formatRoomNumberForSpecialTeamReduced(roomNumber: string): string {  
    if (String(roomNumber).includes('| Equipe')) {
      const [salle, equipe] = roomNumber.split('|').map(part => part.trim());
      const equipeAbbreviation = equipe.replace('Equipe', 'E').trim().replace(' ', '');
  
      const result = `Salle ${salle} : ${equipeAbbreviation}`;
  
      return result;
    } else {
      return `Salle ${roomNumber}`;
    }
  }
  

  splitSurgeonOpeningOneRoom(room: any, index: number) {
    let r = JSON.parse(JSON.stringify(room));
    const copy = JSON.parse(JSON.stringify(room));
    const date = this.formatDate(r.surgeonOpenings[0].startTime);
    let newId = this.generateId();

    copy.isSplit = true;

    this.specialSurgeonOpening.push({
      _id: room._id,
      _newId: [newId],
      room: copy,
    });

    r.isSplit = true;
    const middleTime = new Date(
      (new Date(r.surgeonOpenings[0].startTime).getTime() + new Date(r.surgeonOpenings[0].endTime).getTime()) / 2
      ).toISOString();
      
    this.mergedRooms[index].roomNumber = room.roomNumber + " | Equipe 1";
    this.mergedRooms[index].surgeonOpenings[0].endTime = middleTime;
    this.mergedRooms[index].isSplit = true;
    this.mergedRooms[index].roomId = this.mergedRooms[index]._id;

    r.roomId = r._id;
    r._id = newId;
    r.roomNumber = r.roomNumber + " | Equipe 2";
    r.firstSurgeonOpeningId = r.surgeonOpenings[0]._id;
    r.surgeonOpenings[0].startTime = middleTime;
    r.surgeonOpenings[0].nurses = [];
    r.surgeonOpenings[0].anesthesiste = null;

    room.isSplit = true;

    this.mergedRooms.splice(index + 1, 0, r);
  }

  splitSurgeonOpeningMutipleRoom(room: any, roomPosition: number) {
    let copy = JSON.parse(JSON.stringify(room));
    let NewIdArr = [];

    for (let i = 0; i < room.surgeonOpenings.length - 1; ++i) {
      const newId: string = this.generateId();
      NewIdArr.push(newId);
    }
    room.surgeonOpenings.forEach((so, index) => {
      let r = JSON.parse(JSON.stringify(room));
      r.roomId = r._id;
      if (index != 0) {
        r._id = NewIdArr[index - 1];
      }
      so.nurses = [];
      so.anesthesiste = null;
      r.roomNumber = room.roomNumber + " | Equipe " + (index + 1);
      r.firstSurgeonOpeningId = room.surgeonOpenings[0]._id;
      r.surgeonOpenings = [so];
      r.surgeons = [room.surgeons[index]];
      r.isSplit = true;
      this.mergedRooms.splice(roomPosition + 1 + index, 0, r);
    });
    this.specialSurgeonOpening.push({
      _id: room._id,
      _newId: NewIdArr,
      room: copy,
    });
    this.mergedRooms[roomPosition].isSplit = true;
    this.mergedRooms.splice(roomPosition, 1);
    this.mergedRooms[roomPosition].surgeons.splice(1);
    this.mergedRooms[roomPosition].surgeonOpenings.splice(1);
  }

  splitSurgeonOpeningRoom(room: any, index: number) {
    if (room.surgeonOpenings && room.surgeonOpenings.length > 1) {
      this.splitSurgeonOpeningMutipleRoom(room, index);}
    else {
      //Separate the all day opening in 2 room (morning and afternoon)
      this.splitSurgeonOpeningOneRoom(room, index);
    }
  }

  splitProgramsOneRoom(room: any, index: number) {
    let date = this.formatDate(room.programs[0].startTime);
    // if (this.mergedRooms[index].programs[0].nurses) {
    //   this.mergedRooms[index].programs[0].nurses.forEach(nurse => {
    //     this.addToAvailableNurses(nurse);
    //   });
    //   this.mergedRooms[index].programs[0].nurses = [];
    // }

    this.mergedRooms[index].programs[0].updatedBy = null;
    let r = JSON.parse(JSON.stringify(this.mergedRooms[index].programs[0]));

    const middleTime = new Date(
      (new Date(r.startTime).getTime() + new Date(r.endTime).getTime()) / 2
    ).toISOString();

    this.mergedRooms[index].programs[0].endTime = middleTime;
    r.startTime = middleTime;
    r._id = this.generateId();
    this.mergedRooms[index].programs[1] = r;

    let progIds = [];
    for (const p of this.mergedRooms[index].programs) {
      progIds.push(p._id);
    }

    this.mergedRooms[index].isSplit = true;
    this.specialConfigurations.push({
      _id: this.mergedRooms[index]._id,
      progs: progIds,
    });
  }

  splitProgramsMultipleRoom(room: any, index: number) {
    
    // if (this.mergedRooms[index].programs[0].nurses) {
    //   let nurses = JSON.parse(
    //     JSON.stringify(this.mergedRooms[index].programs[0].nurses)
    //     );
    //   nurses.forEach(nurse => {
    //     nurse.profile.startTime = this.mergedRooms[index].programs[0].startTime;
    //     nurse.profile.endTime = this.mergedRooms[index].programs.at(-1).endTime;
    //     this.addToAvailableNurses(nurse);
    //   });
    // }

    this.mergedRooms[index].programs.forEach((elem, count) => {
      // elem.nurses = [];
      elem.updatedBy = null;
    });
    this.mergedRooms[index].emptyRoom = false;
    room.isSplit = true;
    let progIds = [];
    for (const p of this.mergedRooms[index].programs) {
      progIds.push(p._id);
    }
    this.specialConfigurations.push({
      _id: room._id,
      progs: progIds,
    });
  }

  splitProgramsRoom(room: any, index: number) {
    if (room.programs.length > 1) {
      this.splitProgramsMultipleRoom(room, index);
    } else {
      this.splitProgramsOneRoom(room, index);
    }
  }

  splitRoom(room: any, index: number) {
    if (this.mergedRooms[index].emptyRoom) {
      this.splitSurgeonOpeningRoom(this.mergedRooms[index], index);
    } else {
      this.splitProgramsRoom(this.mergedRooms[index], index);
    }
  }

  openSplitConfirmation(room: any, index: number): void {
    const description = `Voulez vous faire une configuration différente par chirurgien dans cette salle ?`;

    this.popupManagerService
      .openConfirmationPopup(
        "Configuration spécial pour cette salle ?",
        description,
        330,
        "danger",
        "Oui"
      )
      .afterClosed()
      .subscribe((result) => {
        if (result)
         {
          room.isSplit = true;
          this.splitRoom(room, index);
        }
      });
  }

  openDeleteConfirmation(room: Room, index: number): void 
  {
    const description = `Êtes-vous sûr de vouloir vider cette salle ?`;
  
    this.popupManagerService
      .openConfirmationPopup(
        "Vider cette salle ?",
        description,
        330,
        "danger",
        "Oui, vider"
      )
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.cleanRoom(room, index);
          this.markIADEsAsAvailableAfterClearRoomOrProgram(room, index);
        }
      });
  }

  openDeleteAllRoomsConfirmation() : void {
    const description = `Êtes-vous sûr de vouloir vider toutes les salles ?`;

    this.popupManagerService
    .openConfirmationPopup(
      "Vider les salles ?",
      description,
      330,
      "danger",
      "Oui, vider"
    )
    .afterClosed()
    .subscribe((result) => {
      if (result)
      {
        const hospitalId = this.userService.getSelectedHospitals()[0];

        this.cleanAllRooms(hospitalId);

        this.mergedRooms.forEach((room, index) => {
          this.markIADEsAsAvailableAfterClearRoomOrProgram(room, index);
        });
      }
    });
  }

  markIADEsAsAvailableAfterClearRoomOrProgram(room, index): void {
    if (index || index == 0) {
      for (var i = (this.IADEsAlreadyInRoom.length - 1); i >= 0; i--) {
        const itemID = this.IADEsAlreadyInRoom[i].profile._id;

        const IADEs = room.programs[index].nurses.filter((item) => IADE.includes(item.profile.position));
        for (var k = 0; k < IADEs.length; k++) {
          const iade = IADEs[k];

          if (iade.profile._id === itemID) {
            this.addAvailableAnesthetist(this.IADEsAlreadyInRoom[i]);
            this.IADEsAlreadyInRoom.splice(i, 1);
            break;
          }
        }
      }
    } else {
      for (var i = (this.IADEsAlreadyInRoom.length - 1); i >= 0; i--) {
        const itemID = this.IADEsAlreadyInRoom[i].profile._id;

        for (var j = 0; j < room.programs.length; j++) {
          const IADEs = room.programs[j].nurses.filter((item) => IADE.includes(item.profile.position));

          for (var k = 0; k < IADEs.length; k++) {
            const iade = IADEs[k];

            if (iade.profile._id === itemID) {
              this.addAvailableAnesthetist(this.IADEsAlreadyInRoom[i]);
              this.IADEsAlreadyInRoom.splice(i, 1);
              break;
            }
          }
        }
      }
    }
  }

  openDeleteSpecialTeamConfirmation(room: Room, index: number): void {
    const title_popup_team = "Vider cette équipe spéciale ?";
    const title_popup_room = "Vider cette salle ?";
  
    const description_team = `Êtes-vous sûr de vouloir vider cette équipe spéciale ?`;
    const description_room = `Êtes-vous sûr de vouloir vider cette salle ?`;
  
    let description: string;
    let title_popup: string;
  
    let emptyTeamsCount = 0;
  
    for (const program of room.programs) {
      if (program.anesthesists.length === 0 && (program.nurses === null || program.nurses.length === 0) ) {
        emptyTeamsCount++;
      }
    }
  
    if (emptyTeamsCount === room.programs.length - 1) {
      title_popup = title_popup_room;
      description = description_room;
    } else {
      title_popup = title_popup_team;
      description = description_team;
    }
  
    this.popupManagerService
      .openConfirmationPopup(
        title_popup,
        description,
        330,
        "danger",
        "Oui, vider"
      )
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.markIADEsAsAvailableAfterClearRoomOrProgram(room, index);
          if (emptyTeamsCount === room.programs.length - 1) {
            this.cleanRoom(room, index);
          } else {
            this.deleteProgramAtIndex(room, index);
          }
        }
      });
  }
  

  openFeedRoom(room: Room): void {
    const dialogRef = this.dialog.open(FeedRoomDialogComponent, {
      panelClass: "room-details-dialog-container",
      data: {
        cssClass: "danger",
        isPopup: true,
        room: this.getRoomByID(room._id),
        date: this.day,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.cleanRoom(room, -1);
      }
    });
  }

  getRoomByID(roomID: string) {
    return this.rooms.find((room) => room.room._id === roomID);
  }

  cleanRoom(room: Room, index: number): void {
    this.bufferProgramService
      .cleanRoom(room._id, this.formatDate(this.day))
      .subscribe(
        async (res) => {
          await this.getAvailableNurses();
          this.loadSplitNurses = false;
          this.fillRoomWithSurgeonOpenings(room);
          this.initAnesthEffectifNeedsToRoomsObjects();
        },
        (error) => {
          this.isLoading[0] = false;
          this.errorService.handleError(error);
        }
      );
  }

  cleanAllRooms(hospitalId: string): void {
    this.bufferProgramService
      .cleanAllRooms(hospitalId, this.formatDate(this.day))
      .subscribe(
        async (res) => {
          await this.getAvailableNurses();
          this.loadSplitNurses = false;
          this.fillAllRoomsWithSurgeonOpenings();
          this.initAnesthEffectifNeedsToRoomsObjects();
        },
        (error) => {
          this.isLoading[0] = false;
          this.errorService.handleError(error);
        }
      );
  }

  deleteProgramAtIndex(room: Room, index: number): void {
    if (index >= 0 && index < room.programs.length) {
      var programToDelete = room.programs[index];
      programToDelete.anesthesists = [];
      programToDelete.nurses = [];

      this.bufferProgramService.updateBufferProgram(programToDelete._id, programToDelete )
      .subscribe(
        async (res) => {
          await this.getAvailableNurses();
          this.loadSplitNurses = false;
          this.getSplitNurses();
          // this.fillRoomWithSurgeonOpenings(room);
          // this.initAnesthEffectifNeedsToRoomsObjects();
        },
        (error) => {
          this.isLoading[0] = false;
          this.errorService.handleError(error);
        }
      );
    }
  }

  fillRoomWithSurgeonOpenings(room) {
    this.mergedRooms = this.mergedRooms.map((mergedRoom, i) => {
      if (mergedRoom._id == room._id) {
        return this.formatSurgeonOpeningRoom(
          this.roomsSurgeonOpenings[i],
          this.typeAttributeArray[i]
        );
      } else {
        return mergedRoom;
      }
    });
    if (!this.loadSplitNurses) {
      this.getSplitNurses();
    }
  }

  fillAllRoomsWithSurgeonOpenings() {
    this.mergedRooms = this.mergedRooms.map((mergedRoom, i) => {
      return this.formatSurgeonOpeningRoom(
       this.roomsSurgeonOpenings[i],
       this.typeAttributeArray[i]
      );
    });
    if (!this.loadSplitNurses) {
      this.getSplitNurses();
    }
  }

  formatDate(date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  
  // setIsProgramAlreadyValidated(): void {
  //   const user = this.userService.getCurrentUser();
  //   let day = new Date(this.day);
  //   day = new Date(
  //     Date.UTC(
  //       day.getFullYear(),
  //       day.getMonth(),
  //       day.getDate(),
  //       0,
  //       0,
  //       0,
  //       0
  //     )
  //   );

  //   let today = new Date();
  //   today = new Date(
  //     Date.UTC(
  //       today.getFullYear(),
  //       today.getMonth(),
  //       today.getDate(),
  //       0,
  //       0,
  //       0,
  //       0
  //     )
  //   );

  //   if (!user) {
  //     return;
  //   }

  //   if (this.doesHospitalHaveProgramsDataPipelineOption && this.doesHospitalHaveAnesthDetailsOption) {
  //     if (day < today) {
  //       this.isBlocProgramsAlreadyValidated = true;

  //       for (const d of this.nonValidatedDays) {
  //         if (new Date(d).getTime() === day.getTime()) {
  //           this.isConsultationExtracliniqueProgramsAlreadyValidated = false;
  //           return;
  //         }
  //       }

  //       if (
  //         (this.consultationsObjects && this.consultationsObjects.some((spe) => spe.dayPrograms.length > 0 || spe.morningPrograms.length > 0 || spe.afternoonPrograms.length > 0)) ||
  //         (this.extracliniqueObject && (this.extracliniqueObject.dayPrograms.length > 0 || this.extracliniqueObject.morningPrograms.length > 0 || this.extracliniqueObject.afternoonPrograms.length > 0))
  //       ) {
  //         this.isConsultationExtracliniqueProgramsAlreadyValidated = true;
  //       } else {
  //         this.isConsultationExtracliniqueProgramsAlreadyValidated = false;
  //       }
  //       return;
  //     } else {
  //       this.isBlocProgramsAlreadyValidated = false;
  //       this.isConsultationExtracliniqueProgramsAlreadyValidated = false;
  //       return;
  //     }
  //   }
    
  //   if (this.doesHospitalHaveProgramsDataPipelineOption && !this.doesHospitalHaveAnesthDetailsOption) {
  //     if (day < today) {
  //       this.isBlocProgramsAlreadyValidated = true;
  //       this.isConsultationExtracliniqueProgramsAlreadyValidated = true;
  //     } else {
  //       this.isBlocProgramsAlreadyValidated = false;
  //       this.isConsultationExtracliniqueProgramsAlreadyValidated = false;
  //     }
  //     return;
  //   }

  //   if (!this.doesHospitalHaveProgramsDataPipelineOption) {
  //     for (const d of this.nonValidatedDays) {
  //       if (new Date(d).getTime() === day.getTime()) {
  //         this.isBlocProgramsAlreadyValidated = false;
  //         this.isConsultationExtracliniqueProgramsAlreadyValidated = false;
  //         return;
  //       }
  //     }

  //     if (
  //       this.mergedRooms.some((room) => !room.emptyRoom) ||
  //       (this.consultationsObjects && this.consultationsObjects.some((spe) => spe.dayPrograms.length > 0 || spe.morningPrograms.length > 0 || spe.afternoonPrograms.length > 0)) ||
  //       (this.extracliniqueObject && (this.extracliniqueObject.dayPrograms.length > 0 || this.extracliniqueObject.morningPrograms.length > 0 || this.extracliniqueObject.afternoonPrograms.length > 0))) {
  //         this.isBlocProgramsAlreadyValidated = true;
  //         this.isConsultationExtracliniqueProgramsAlreadyValidated = true;
  //     } else {
  //       this.isBlocProgramsAlreadyValidated = false;
  //       this.isConsultationExtracliniqueProgramsAlreadyValidated = false;
  //     }
  //   }
  // }

  setIsProgramAlreadyValidated(): void {
    const user = this.userService.getCurrentUser();
    let day = new Date(this.day);
    day = new Date(
      Date.UTC(
        day.getFullYear(),
        day.getMonth(),
        day.getDate(),
        0,
        0,
        0,
        0
      )
    );

    const now = new Date();
    let today = new Date();
    today = new Date(
      Date.UTC(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        0,
        0,
        0,
        0
      )
    );

    if (!user) {
      return;
    }

    if (this.doesHospitalHaveAnesthDetailsOption) {
      // Set this.isAnesthsProgramsAlreadyValidated
      if (day < today || (day.getTime() === today.getTime() && now.getHours() >= 21)) {
        this.isAnesthsProgramsAlreadyValidated = true;
      } else {
        this.isAnesthsProgramsAlreadyValidated = false;
      }

      // Set this.isNursesProgramsAlreadyValidated
      if (this.doesHospitalHaveProgramsDataPipelineOption) {
        if (day < today) {
          this.isNursesProgramsAlreadyValidated = true;
        } else {
          this.isNursesProgramsAlreadyValidated = false;
        }
      } else {
        for (const d of this.nonValidatedDays) {
          if (new Date(d).getTime() === day.getTime()) {
            this.isNursesProgramsAlreadyValidated = false;
            return;
          }
        }
        
        if (this.mergedRooms.some((room) => !room.emptyRoom)) {
          this.isNursesProgramsAlreadyValidated = true;
        } else {
          this.isNursesProgramsAlreadyValidated = false;
        }
      }
    } else {
      // Set this.isNursesProgramsAlreadyValidated
      if (this.doesHospitalHaveProgramsDataPipelineOption) {
        if (day < today) {
          this.isNursesProgramsAlreadyValidated = true;
          this.isAnesthsProgramsAlreadyValidated = true;
        } else {
          this.isNursesProgramsAlreadyValidated = false;
          this.isAnesthsProgramsAlreadyValidated = false;
        }
      } else {
        for (const d of this.nonValidatedDays) {
          if (new Date(d).getTime() === day.getTime()) {
            this.isNursesProgramsAlreadyValidated = false;
            this.isAnesthsProgramsAlreadyValidated = false;
            return;
          }
        }
        
        if (this.mergedRooms.some((room) => !room.emptyRoom)) {
          this.isNursesProgramsAlreadyValidated = true;
          this.isAnesthsProgramsAlreadyValidated = true;
        } else {
          this.isNursesProgramsAlreadyValidated = false;
          this.isAnesthsProgramsAlreadyValidated = false;
        }
      }
    }
  }

  getRoomsObservable(day, roomsSurgeonOpenings, realPrograms?: boolean): Observable<any> {
    if (day) {
      return this.bufferProgramService.getAllRoomsBufferPrograms(day)
        .pipe(
          map(
            (rooms: Room[]) => {
              // Here
              this.allBufferPrograms = rooms;
              const sortRooms = this.sortPrograms(rooms);
              this.displayDA = rooms.map((_) => false);
              this.isLoading[0] = false;
              this.mergeRoomsSurgonOpeningsWithRoomsBufferPrograms(
                roomsSurgeonOpenings,
                sortRooms
              );
              const today = new Date();
              today.setHours(0, 0, 0, 0);
              const dayWithoutTime = new Date(this.day);
              dayWithoutTime.setHours(0, 0, 0, 0);
              if ((!this.isHome || !this.doesHospitalHaveProgramsDataPipelineOption)) {
                if (dayWithoutTime <= today)
                {
                  this.getNonValidatedDays();
                }
                else
                {
                  this.setIsProgramAlreadyValidated();
                }
              }
              if (realPrograms) this.insertDayProgram();
            },
            (error) => {
              this.isLoading[0] = false;
              this.errorService.handleError(error);
            }
          )
        );
    } else {
      return new Observable(sub => sub.next(true));
    }
  }

  getRooms(day, roomsSurgeonOpenings, realPrograms?: boolean): void {
    if (day) {
      if (this.roomsSubscription) {
        this.roomsSubscription.unsubscribe();
      }

      this.roomsSubscription = this.getRoomsObservable(day, roomsSurgeonOpenings, realPrograms).subscribe();
    }
  }

  getTeam(program) {
    const team = []

    program.anesthesists.forEach(anesthesist => {
      team.push({
        profile: anesthesist
      })
    });

    if (program.interne && !team.find((card) => String(card.profile._id) === String(program.interne._id))) {
      team.push({
        profile: program.interne
      })
    }

    if (program.seniorJunior && !team.find((card) => String(card.profile._id) === String(program.seniorJunior._id))) {
      team.push({
        profile: program.seniorJunior
      })
    }

    program.nurses.forEach(nurse => {
      team.push({
        profile: nurse.profile,
        role: nurse.role
      })
    });

    return team
  }

  checkIfSplitByVacation(isBP: boolean, programs: BufferProgram[] | SO[]) {
    const morningPrograms: BufferProgram[] = []
    const afternoonPrograms: BufferProgram[] = []
    const hospital = getFirstHospitalSelectedData(this.userService.getCurrentUser(), this.userService.getSelectedHospitals())
    const middleTime = new Date(hospital.middleTime)
    const middleTimeModified = new Date("1970-01-01");
    middleTimeModified.setUTCHours(middleTime.getUTCHours(), middleTime.getUTCMinutes(), middleTime.getUTCSeconds(), middleTime.getUTCMilliseconds())

    if (isBP) {
      (programs as BufferProgram[]).forEach(program => {
        const startTime = new Date("1970-01-01")
        startTime.setUTCHours(new Date(program.startTime).getUTCHours(), new Date(program.startTime).getUTCMinutes(), new Date(program.startTime).getUTCSeconds(), new Date(program.startTime).getUTCMilliseconds())
        const endTime = new Date("1970-01-01")
        endTime.setUTCHours(new Date(program.endTime).getUTCHours(), new Date(program.endTime).getUTCMinutes(), new Date(program.endTime).getUTCSeconds(), new Date(program.endTime).getUTCMilliseconds())
        
        if (endTime.getTime() <= (middleTimeModified.getTime() + 3600000)) {
          morningPrograms.push(program)
        } else {
          if (startTime.getTime() >= (middleTimeModified.getTime() - 3600000)) {
            afternoonPrograms.push(program)
          }
        }
      });

      if (morningPrograms.length === 1 && afternoonPrograms.length === 1) {
        return {splitByVacation: true, morningPrograms, afternoonPrograms};
      }
  
      let sameMorningTeam: boolean = true;
      let sameAfternoonTeam: boolean = true;
      if (morningPrograms.length > 1) {
        const firstProgramTeam = this.getTeam(morningPrograms[0])
        morningPrograms.forEach((morningProgram: BufferProgram) => {
          const team = this.getTeam(morningProgram)

          team.forEach(card => {
            if (!firstProgramTeam.find((card2) => card2.role && card.role ? String(card2.profile._id) === String(card2.profile._id) && String(card2.role._id) === String(card2.role._id) : String(card2.profile._id) === String(card2.profile._id))) {
              sameMorningTeam = false;
            }
          });
        });
      }
  
      if (afternoonPrograms.length > 1) {
        const firstProgramTeam = this.getTeam(afternoonPrograms[0])
        afternoonPrograms.forEach((afternoonProgram: BufferProgram) => {
          const team = this.getTeam(afternoonProgram)

          team.forEach(card => {
            if (!firstProgramTeam.find((card2) => card2.role && card.role ? String(card2.profile._id) === String(card2.profile._id) && String(card2.role._id) === String(card2.role._id) : String(card2.profile._id) === String(card2.profile._id))) {
              sameMorningTeam = false;
            }
          });
        });
      }
  
      if (!sameMorningTeam || !sameAfternoonTeam) {
        return {splitByVacation: true, morningPrograms, afternoonPrograms};
      }
    }

    return {splitByVacation: false, morningPrograms, afternoonPrograms};
  }

  mergeRoomsSurgonOpeningsWithRoomsBufferPrograms(
    roomsSurgeonOpenings: any[],
    fullRooms: any[]
  ) {
    this.mergedRooms = [];
    const fullRoomsIds = fullRooms.map((room) => room._id);
    this.mergedRooms = roomsSurgeonOpenings.map((roomSurgeonOpenings, i) => {
      const roomId = roomSurgeonOpenings.room._id;
      const index = fullRoomsIds.findIndex(
        (fullRoomId) => fullRoomId == roomId
      );

      let fullRoom;

      if (index >= 0) {
        fullRoom = fullRooms[index];

        fullRoom.programs.forEach((program) => {
          if (this.doesHospitalHaveAnesthDetailsOption) {
            if (program.anesthesists) {
              program.seniorJunior = program.anesthesists.find((profile) => [ANESTHETIST].includes(profile.position) && ['Senior', 'Junior'].includes(profile.seniority));
              program.interne = program.anesthesists.find((profile) => [ANESTHETIST].includes(profile.position) && ['Interne'].includes(profile.seniority));
            }
          } else {
            if (program.anesthesists) {
              program.anesthesiste = program.anesthesists[0]; 
            }
          }
        });
      }

      if (index >= 0) {
        return {
          ...fullRoom,
          emptyRoom: false,
        };
      } else {
        const typeAttribute = this.typeAttributeArray[i];
        return this.formatSurgeonOpeningRoom(
          roomSurgeonOpenings,
          typeAttribute
        );
      }
    });

    // Set morningAfternoon split
    this.mergedRooms.forEach(room => {
      if (room.programs && room.programs.length > 1) {
        const result = this.checkIfSplitByVacation(true, room.programs);

        room.splitByVacation = result.splitByVacation
        room.morningPrograms = result.morningPrograms
        room.afternoonPrograms = result.afternoonPrograms
      }
    });

    for (let i: number = 0; i < this.mergedRooms.length; i++) {
      if (this.mergedRooms[i].surgeonOpenings) {
        this.mergedRooms[i].surgeonOpenings = this.mergedRooms[
          i
        ].surgeonOpenings.sort(function (a, b) {
          let dateA = new Date(a.startTime).getUTCHours();
          let dateB = new Date(b.startTime).getUTCHours();
          return dateA - dateB;
        });
      }
    }
    if (!this.loadSplitNurses) {
      this.getSplitNurses();
      // this.checkDuplicate();
    }

    if (this.blocNeeds) {
      this.initAnesthEffectifNeedsToRoomsObjects();
    }
  }

  getStartTimeCard(programs: BufferProgram[]) {
    let time = { startTime: moment.utc(programs[0].startTime).toDate(), endTime: moment.utc(programs[0].endTime).toDate() }

    programs.forEach(program => {
      let startTime = moment.utc(program.startTime).toDate();
      let endTime = moment.utc(program.endTime).toDate();

      if (startTime.getTime() < new Date(time.startTime).getTime()) {
        time.startTime = moment.utc(startTime).toDate()
      }

      if (endTime.getTime() > new Date(time.endTime).getTime()) {
        time.endTime = moment.utc(endTime).toDate()
      }
    });
    return time
  }

  getHours(program, room, moment: string, index: number, programs?: any, mAm?: boolean) {
    if (!room.isSplit && !mAm) {
      if (moment === "startTime") {
        return program[moment];
      } else if (moment === "endTime") {
        if (this.mergedRooms[index].programs && this.mergedRooms[index].programs.length > 0) {
          return this.mergedRooms[index].programs[this.mergedRooms[index].programs.length - 1][moment];
        } else {
          return '';
        }
      }
    } else {
      if (!room.splitByVacation) {
        const time = this.getStartTimeCard(programs);

        if (moment === "startTime") {
          return time.startTime
        } else {
          return time.endTime
        }
      }
    }
    return program[moment];
  }

  //IMPORTANT//
  formatSurgeonOpeningRoom(roomSurgeonOpenings, typeAttribute) {
    return {
      ...roomSurgeonOpenings.room,
      roomNumber: roomSurgeonOpenings.room.roomNumber,
      surgeonOpenings: roomSurgeonOpenings[typeAttribute],
      emptyRoom: true,
      surgeons: this.getSurgeons(roomSurgeonOpenings, typeAttribute),
      anesthesiste: null,
      seniorJunior: null,
      interne: null,
      iade: null,
      nurses: [],
      isSplit: false,
      firstSurgeonOpeningId: this.isRoomWithoutSurgeonOpenings(
        roomSurgeonOpenings,
        typeAttribute
      )
        ? null
        : roomSurgeonOpenings[typeAttribute][0]._id,
      noSurgeonOpenings: this.isRoomWithoutSurgeonOpenings(
        roomSurgeonOpenings,
        typeAttribute
      ),
    };
  }

  openNursesSuggestionsPopup(
    room: any, 
    position: string, 
    dropArguments?: { i: number, j: number, container: { id: string, data: any[] } }
  ): void {
    let roomId;
    if (room && room.room) {
      // Configuration spéciale deja enregistrée
      roomId = room.room;
    } else if (room.isSplit) {
      // configuration spéciale pas encore enregistrée
      for (const elt of this.specialSurgeonOpening) {
        if (elt._newId.includes(String(room._id))) {
          roomId = elt._id;
          break;
        }
      }

      if (!roomId) {
        roomId = room._id;
      }
    } else {
      // configuration simple enregistrée ou non enregistrée
      roomId = room._id;
    }
    const alreadyAssignedNurses = this.getAlreadyAssignedNurses();

    let filtredRoles;

    if (this.canUserEditBlocAnesths && !this.canUserSeeAndEditNurses) {
      filtredRoles = this.roleData.filter((role) => role.name.toLowerCase() === 'iade');
    } else if (this.canUserSeeAndEditNurses && !this.canUserEditBlocAnesths) {
      filtredRoles = this.roleData.filter((role) => role.name.toLowerCase() !== 'iade');
    } else {
      filtredRoles = this.roleData;
    }

    const dialogRef = this.matDialog.open(ProfilesSuggestionsComponent, {
      width: '1100px',
      maxWidth: 'unset',
      height: '815px',
      data: {
        date: this.day,
        roomId,
        position,
        alreadyAssignedProfiles: alreadyAssignedNurses,
        nursesRankingsSubject: this.nursesRankingsSubject,
        room,
        paramedicalData: this.paramedicalData,
        roleData: filtredRoles
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data && data.profile) {
        // The user clicked on a profile, we simulate drag & drop behaviour to avoid editing/creating functions
        if (data.role.name.toLowerCase() === 'iade' || data.profile.position.toLowerCase() === 'iade') {
          let iadeIndex = this.blocAnesthetists.findIndex((elt) => elt.profile ? String(elt.profile._id) === String(data.profile._id) : String(elt._id) === String(data.profile._id));
          let iade = this.blocAnesthetists.find((elt) => elt.profile ? String(elt.profile._id) === String(data.profile._id) : String(elt._id) === String(data.profile._id));

          iade.role = data.role

          if (iadeIndex !== -1) {
            // Simulate dragging
            this.outOfRoomDragStart(this.blocAnesthetists[iadeIndex], iadeIndex);

            // Simulate dropping
            this.dropInNurses({
              previousIndex: iadeIndex,
              previousContainer: { id: "blocAnesthetistsList", data: this.blocAnesthetists },
              currentIndex: dropArguments.container.data.length + 1,
              container: dropArguments.container
            },
            dropArguments.i,
            dropArguments.j
            )
          }
        } else {
          let nurseIndex = this.nurses.findIndex((elt) => String(elt.profile._id) === String(data.profile._id));
          let nurse = this.nurses.find((elt) => String(elt.profile._id) === String(data.profile._id));

          nurse.role = data.role

          if (nurseIndex !== -1) {
            // Simulate dragging
            this.outOfRoomDragStart(this.nurses[nurseIndex], nurseIndex);

            // Simulate dropping
            this.dropInNurses({
              previousIndex: nurseIndex,
              previousContainer: { id: "nursesList", data: this.nurses },
              currentIndex: dropArguments.container.data.length + 1,
              container: dropArguments.container
            },
            dropArguments.i,
            dropArguments.j
            )
          }
        }
      }
    })
  }

  fillSpecialtyByProgramOrSO(object: any, specialties: string[]): string[] {
    if (object.programs && object.programs.length > 0) {
      for (let prog of object.programs) {
        if (prog.surgeon) {
          specialties = specialties.concat(prog.surgeon.specialties);
        }
      }
    } else if (object.surgeons && object.surgeons.length > 0) {
      for (let surgeon of object.surgeons) {
        specialties = specialties.concat(surgeon.specialties);
      }
    }
    return specialties;
  }
  
  openAnesthsSuggestionsPopup(
    type: string, 
    object: any, 
    position: string, 
    period: string, 
    blocDropArguments: { i: number, j: number, position: number, isSurgeonOpening?: number, anesthType?: number },
    consultationExtracliniqueDropArguments: { container: { id: string, data: any[] } }
  ): void {
    const room = type === 'bloc' ? object : null;
    var specialty;
    var specialties = [];
    if (type !== 'bloc') {
      specialty = object.specialty;
    } else {
      specialties = this.fillSpecialtyByProgramOrSO(object, specialties);
    }
    const alreadyAssignedAnesths = this.getAlreadyAssignedAnesths(type, period);

    const dialogRef = this.matDialog.open(ProfilesSuggestionsComponent, {
      width: '1100px',
      maxWidth: 'unset',
      height: '815px',
      data: {
        date: this.day,
        position,
        alreadyAssignedProfiles: alreadyAssignedAnesths,
        anesthsRankingsSubject: this.anesthsRankingsSubject,
        type,
        room,
        specialty,
        specialties
      },
    });

    dialogRef.afterClosed().subscribe((profile) => {
      if (profile) {
        if (type === 'bloc') {
          // The user clicked on a profile, we simulate drag & drop behaviour to avoid editing/creating functions
          let anesthIndex = this.blocAnesthetists.findIndex(elt => elt._id === profile._id);

          if (anesthIndex !== -1) {
            // Simulate dragging
            this.outOfRoomDragStart(this.blocAnesthetists[anesthIndex], anesthIndex);

            // Simulate dropping
            this.drop(blocDropArguments.i, blocDropArguments.j, blocDropArguments.position, blocDropArguments.isSurgeonOpening, blocDropArguments.anesthType);
          }
        } else {
          // The user clicked on a profile, we simulate drag & drop behaviour to avoid editing/creating functions
          let anesthIndex = this.filtredAnesthesists.findIndex(elt => elt.anesth._id === profile._id);

          if (anesthIndex !== -1) {
            // Simulate dragging
            this.outOfConsultationAndExtracliniqueDragStart(this.filtredAnesthesists[anesthIndex], anesthIndex);

            // Simulate dropping
            // const sourceArrayId = event.previousContainer.id;
            // const sourceArray = event.previousContainer.data;
            // const sourceIndex = event.previousIndex;
        
            // const destinationArrayId = event.container.id;
            // const destinationArray = event.container.data;    
            // const destinationIndex = event.currentIndex;

            this.dropInConsultationOrExtraclinique({ 
              previousIndex: anesthIndex, 
              previousContainer: { id: 'consultationExtracliniqueAnesthetistsList', data: this.filtredAnesthesists } ,
              currentIndex: consultationExtracliniqueDropArguments.container.data.length + 1,
              container: consultationExtracliniqueDropArguments.container
            });
          }
        }
      }
    });
  }

  getAlreadyAssignedAnesths(type: string, period: string): any[] {
    const response = [];

    if (type === 'bloc') {
      // Get all profiles assigned in consultation or extraclinique
      this.consultationsObjects.forEach((item) => {
        response.push(...item.dayPrograms.map((e) => String(e.anesth._id)));
        response.push(...item.morningPrograms.map((e) => String(e.anesth._id)));
        response.push(...item.afternoonPrograms.map((e) => String(e.anesth._id)));
      });

      response.push(...this.extracliniqueObject.dayPrograms.map((e) => String(e.anesth._id)));
      response.push(...this.extracliniqueObject.morningPrograms.map((e) => String(e.anesth._id)));
      response.push(...this.extracliniqueObject.afternoonPrograms.map((e) => String(e.anesth._id)));
    } else {
      // Get all profiles assigned in bloc
      this.mergedRooms.forEach((room) => {
        if (room.seniorJunior && room.seniorJunior._id) {
          response.push(String(room.seniorJunior._id));
        }
  
        if (room.interne && room.interne._id) {
          response.push(String(room.interne._id));
        }
  
        if (room.programs && room.programs.length > 0) {
          room.programs.forEach((program) => {
            if (program.seniorJunior && program.seniorJunior._id) {
              response.push(String(program.seniorJunior._id));
            }
  
            if (program.interne && program.interne._id) {
              response.push(String(program.interne._id));
            }
          })
        }
      });

      // + Get all profiles assigned in consultation or extraclinique in the specific period
      if (period === 'day') {
        this.consultationsObjects.forEach((item) => {
          response.push(...item.dayPrograms.map((e) => String(e.anesth._id)));
          response.push(...item.morningPrograms.map((e) => String(e.anesth._id)));
          response.push(...item.afternoonPrograms.map((e) => String(e.anesth._id)));
        });
  
        response.push(...this.extracliniqueObject.dayPrograms.map((e) => String(e.anesth._id)));
        response.push(...this.extracliniqueObject.morningPrograms.map((e) => String(e.anesth._id)));
        response.push(...this.extracliniqueObject.afternoonPrograms.map((e) => String(e.anesth._id)));
      } else if (period === 'morning') {
        this.consultationsObjects.forEach((item) => {
          response.push(...item.dayPrograms.map((e) => String(e.anesth._id)));
          response.push(...item.morningPrograms.map((e) => String(e.anesth._id)));
        });
  
        response.push(...this.extracliniqueObject.dayPrograms.map((e) => String(e.anesth._id)));
        response.push(...this.extracliniqueObject.morningPrograms.map((e) => String(e.anesth._id)));
      } else {
        // Afternoon
        this.consultationsObjects.forEach((item) => {
          response.push(...item.dayPrograms.map((e) => String(e.anesth._id)));
          response.push(...item.afternoonPrograms.map((e) => String(e.anesth._id)));
        });
  
        response.push(...this.extracliniqueObject.dayPrograms.map((e) => String(e.anesth._id)));
        response.push(...this.extracliniqueObject.afternoonPrograms.map((e) => String(e.anesth._id)));
      }
    }

    return response;
  }

  getAlreadyAssignedNurses(): any[] {
    const response = [];

    this.mergedRooms.forEach((room) => {
      if (room.nurses && room.nurses.length > 0) {
        room.nurses.forEach(nurse => {
          response.push(String(nurse.profile._id));
        });
      }

      if (room.programs && room.programs.length > 0) {
        room.programs.forEach((program) => {
          if (program.nurses && program.nurses.length > 0) {
            program.nurses.forEach(nurse => {
              response.push(String(nurse.profile._id));
            });
          }
        })
      }
    });

    return response;
  }

  isRoomWithoutSurgeonOpenings(roomSurgeonOpenings, typeAttribute) {
    return roomSurgeonOpenings[typeAttribute].length == 0;
  }

  getSurgeons(roomSurgeonOpenings, typeAttribute) {
    return roomSurgeonOpenings[typeAttribute].map(
      (surgeonOpening) => surgeonOpening.surgeon
    );
  }

  formatPrograms(programs: BufferProgram[], period: string): any[] {
    let date;
    date = new Date(moment(this.day).format("YYYY-MM-DD").toString());
    date.setUTCHours(0, 0, 0, 0);

    let currentStartTime, currentEndTime;

    const hospital = getFirstHospitalSelectedData(this.userService.getCurrentUser(), this.userService.getSelectedHospitals())
    let hospitalStartTime = new Date(dateWithTime(date, hospital.startTime));
    let hospitalMiddleTime = new Date(dateWithTime(date, hospital.middleTime));
    let hospitalEndTime = new Date(dateWithTime(date, hospital.endTime));

    switch (period) {
      case 'day':
        currentStartTime = hospitalStartTime;
        currentEndTime = hospitalEndTime;
        break;
      case 'morning':
        currentStartTime = hospitalStartTime;
        currentEndTime = hospitalMiddleTime;
        break;
      case 'afternoon':
        currentStartTime = hospitalMiddleTime;
        currentEndTime = hospitalEndTime;
        break;
      default:
        currentStartTime = hospitalStartTime;
        currentEndTime = hospitalEndTime;
    }

    return programs.map((bufferProgram) => { return { anesth: bufferProgram.anesthesists[0], currentStartTime, currentEndTime } })
  }

  getFormattedMorningPrograms(programs: BufferProgram[]): BufferProgram[] {
    const response = this.utilisService.getMorningPrograms({ programs });
    return this.formatPrograms(response, 'morning');
  }

  getFormattedAfternoonPrograms(programs: BufferProgram[]): BufferProgram[] {
    const response = this.utilisService.getAfternoonPrograms({ programs });
    return this.formatPrograms(response, 'afternoon');
  }

  getFormattedDayPrograms(programs: BufferProgram[]): BufferProgram[] {
    const response = this.utilisService.getDayPrograms({ programs });
    return this.formatPrograms(response, 'day');
  }

  // getMorningPrograms(room: Room): BufferProgram[] {
  //   return this.utilisService.getMorningPrograms(room);
  // }

  // getAfternoonPrograms(room: Room): BufferProgram[] {
  //   return this.utilisService.getAfternoonPrograms(room);
  // }

  isBPsHaveSameTeam(room: Room): boolean {
    return this.utilisService.isBPsHaveSameTeam(room);
  }

  isBPsHaveSameProfil(room: Room, prop: string) {
    return this.utilisService.isBPsHaveSameProfil(room, prop)
  }

  sortPrograms(rooms: Room[]): Room[] {
    for (let i = 0; i < rooms.length; i++) {
      if (rooms[i] && rooms[i].programs) {
        rooms[i].programs = rooms[i].programs.filter(program => program);
        rooms[i].programs.sort((a, b) =>
          new Date(a.startTime) >= new Date(b.startTime) ? 1 : -1
        );
      }
    }
    this.checkValidation();
    return rooms;
  }
  

  checkValidation(): void {
    for (const day of this.nonValidatedDays) {
      if (day === this.day) {
        this.programAlreadyValidated = false;
        break;
      }
    }
  }

  goToNextEvent() {
    if (this.isProgramsChanged)
      return this.utilisService.openUnSavedChangesPopup();
    else return new Observable((sub) => sub.next(true));
  }

  async goToNextDay(type: string) {
    this.isProgramsChanged = false;
    const dateCopy = new Date(this.day);
    switch (type) {
      case "previous":
        dateCopy.setDate(dateCopy.getDate() - 1);
        break;
      case "next":
        dateCopy.setDate(dateCopy.getDate() + 1);
        break;
    }
    this.day = dateCopy;
    this.programAlreadyValidated = false;
    this.loadSplitNurses = false;
    this.blocNeeds = null;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    this.nurses=[];
    this.nursesProfile=[];
    this.anesthetistsCalendars = [];
    this.blocAnesthetists = [];
    this.IADEsAlreadyInRoom = [];
    this.consultationExtracliniqueAnesthetists = [];
    this.filtredAnesthesists = [];
    if (this.day >= today) {
      this.getNursesRankings();
      if (!this.isHome)
      {
        await this.getAvailableNurses();
      }
      this.getAnesthsRankings();
      this.getHospitalRoomsDaysDoctorAgendas();
      this.getAvailableAnesthetists();
    }
    if (!this.isHome) {
      this.getProgramCommentary(this.getDate());
    }
    await this.getRelatedData();
  }

  isChangesUnsaved() {
    return this.isProgramsChanged;
  }

  getNonValidatedDays(): void {
    if (this.realProgramSubscription) {
      this.realProgramSubscription.unsubscribe();
    }

    this.realProgramSubscription = this.realProgramService
      .getNonValidatedDays(moment(this.day).format("YYYY-MM-DD"))
      .subscribe(
        (days: Date[]) => {
          if (days) {
            this.nonValidatedDays = days;
            this.setIsProgramAlreadyValidated();
          }
        },
        (error) => {
          this.errorService.handleError(error);
        }
      );
  }

  goBack(): void {
    if (this.home) {
      this.navigationService
        .navigateTo(pages.home)
        .catch((error) => this.errorService.handleError(error));
    } else {
      this.navigationService
        .navigateTo(pages.smartPlanning, null, {
          day: moment(this.day).format("YYYY-MM-DD"),
        })
        .catch((error) => this.errorService.handleError(error));
    }
  }

  dragStart(i, j, draggedItem: Profile, nurseType?: number): void {
    let draggedItemClone = {};
    if (isAnesthetist(draggedItem)) {
      Object.assign(draggedItemClone, draggedItem);
    } else {
      draggedItemClone = draggedItem;
    }
    // if (!draggedItemClone["startTime"] || !draggedItemClone["endTime"]) {
    //      if (j == -1) {

    //      }
    // }
    this.draggedItem = {
      room: i,
      program: j,
      item: draggedItemClone,
      nurseType: nurseType,
    };

    this.dynamicMarginsForAvailableProfiles.draggingAnesthIndex = -1;
    this.dynamicMarginsForAvailableProfiles.draggingNurseIndex = -1;
  }

  outOfConsultationAndExtracliniqueDragStart(anesth: Profile, index: number): void {
    this.dynamicMarginsForAvailableProfiles.draggingConsultationExtracliniqueAnesthIndex = index;
  }

  getProgramIndex(programs: any[], program: any): number {
    const id = programs.findIndex((p) => p._id == program._id);
    return id;
  }

  outOfRoomDragStart(draggedItem: Profile, index?: number): void {
    let draggedItemClone = {};
    if (isAnesthetist(draggedItem)) {
      Object.assign(draggedItemClone, draggedItem);
    } else {
      draggedItemClone = draggedItem;
    }

    this.draggedItem = {
      room: undefined,
      program: undefined,
      item: draggedItemClone,
      nurseType: undefined,
    };
    
    if (index || index === 0) {
      if (isNurse(draggedItem)) {
        this.dynamicMarginsForAvailableProfiles.draggingNurseIndex = index;
      } else if (isAnesthetist(draggedItem)) {
        this.dynamicMarginsForAvailableProfiles.draggingAnesthIndex = index;
      }
    }
  }
  
  openPermutePopup(i, j, itemToChange, position, anesthType?: number): void {
    const description = `Etes vous sûr(e) de vouloir permuter ${formatName(
      this.draggedItem.item.firstName,
      this.draggedItem.item.lastName,
      true,
      " "
    )} et
    ${formatName(itemToChange.firstName, itemToChange.lastName, true, " ")} ?`;
    this.dialogSub = this.popupManagerService
      .openConfirmationPopup(
        "Permuter ?",
        description,
        0,
        "primary",
        "Oui, permuter"
      )
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.permute(i, j, itemToChange, position, false, anesthType);
        }
      });
  }

  permute(i, j, itemToChange, position, isSurgeonOpening?, anesthType?: number) {
    switch (position) {
      case 0:
        let fieldName;
        if (this.doesHospitalHaveAnesthDetailsOption) {
          switch (anesthType) {
            case 0:
              fieldName = 'seniorJunior';
              break;
            case 1:
              fieldName = 'interne';
              break;
            case 2:
              fieldName = 'iade';
              break;
            default:
              fieldName = 'anesthesiste';
              break;
          }
        } else {
          fieldName = 'anesthesiste';
        }


        this.duplicateValue(
          i,
          j,
          fieldName,
          this.draggedItem.item,
          isSurgeonOpening
        );

        //On retrouve l'element selectionner dans la listes blocAnesthetists.
        if (this.draggedItem.item.position === 'Iade'){
          const idToFind = this.draggedItem.item._id;
          const lastNameToFind = this.draggedItem.item.lastName;
          
          const foundElement = this.blocAnesthetists.find(value => 
            value._id === idToFind
          );
        
          if (foundElement) {
            const indexToRemove = this.blocAnesthetists.indexOf(foundElement);
            if (indexToRemove !== -1) {
              this.blocAnesthetists.splice(indexToRemove, 1);
              this.IADEsAlreadyInRoom.push(this.draggedItem);
            }
          }
        }
        

        if (
          this.draggedItem.room === undefined &&
          this.draggedItem.program === undefined
        ) {
          if (itemToChange) {
            this.addAvailableAnesthetist(itemToChange);
          }
        } else {
          this.duplicateValue(
            this.draggedItem.room,
            this.draggedItem.program,
            fieldName,
            itemToChange
          );
        }
        break;
      // case 1:
      //   this.duplicateValue(
      //     i,
      //     j,
      //     "nurse1",
      //     this.draggedItem.item,
      //     isSurgeonOpening
      //   );
      //   if (
      //     this.draggedItem.room === undefined &&
      //     this.draggedItem.program === undefined
      //   ) {
      //     this.nurses.splice(this.nurses.indexOf(this.draggedItem.item), 1);
      //     if (itemToChange) {
      //       this.nurses.push(itemToChange);
      //     }
      //   } else {
      //     this.duplicateValue(
      //       this.draggedItem.room,
      //       this.draggedItem.program,
      //       "nurse1",
      //       itemToChange
      //     );
      //   }
      //   break;
      // case 2:
      //   this.duplicateValue(
      //     i,
      //     j,
      //     "nurse2",
      //     this.draggedItem.item,
      //     isSurgeonOpening
      //   );
      //   if (
      //     this.draggedItem.room === undefined &&
      //     this.draggedItem.program === undefined
      //   ) {
      //     this.nurses.splice(this.nurses.indexOf(this.draggedItem.item), 1);
      //     if (itemToChange) {
      //       this.nurses.push(itemToChange);
      //     }
      //   } else {
      //     this.duplicateValue(
      //       this.draggedItem.room,
      //       this.draggedItem.program,
      //       "nurse2",
      //       itemToChange
      //     );
      //   }
      //   break;
      // case 3:
      //   this.duplicateValue(
      //     i,
      //     j,
      //     "nurse1",
      //     this.draggedItem.item,
      //     isSurgeonOpening
      //   );
      //   this.duplicateValue(
      //     this.draggedItem.room,
      //     this.draggedItem.program,
      //     "nurse2",
      //     itemToChange
      //   );
      //   break;
      // case 4:
      //   this.duplicateValue(
      //     i,
      //     j,
      //     "nurse2",
      //     this.draggedItem.item,
      //     isSurgeonOpening
      //   );
      //   this.duplicateValue(
      //     this.draggedItem.room,
      //     this.draggedItem.program,
      //     "nurse1",
      //     itemToChange
      //   );
      //   break;
      default:
        break;
    }
    this.draggedItem = null;
    itemToChange = null;
    this.dynamicMarginsForAvailableProfiles.draggingAnesthIndex = -1;
    this.dynamicMarginsForAvailableProfiles.draggingNurseIndex = -1;
    this.initAnesthEffectifNeedsToRoomsObjects();
  }

  duplicateValue(
    i: number,
    j: number,
    attribute: string,
    value,
    isSurgeonOpening?,
    nurseId?
  ) {
    if (isSurgeonOpening) {
      // we need set value to room attribute
      if (
        ['anesthesiste', 'seniorJunior', 'interne', 'iade'].includes(attribute) &&
        this.specialSurgeonOpening.length != 0 &&
        this.mergedRooms[i].isSplit == true
      ) {
        this.duplicateSurgeonOpenings(i, attribute, value);
      } else if (attribute === "nurses") {
        const nurseIndex = this.mergedRooms[i][attribute].findIndex((nurse) => String(nurse.profile._id) === String(nurseId))
        if (nurseIndex !== -1) {
          this.mergedRooms[i][attribute].splice(nurseIndex, 1);
        }
      } 
      else {
        this.mergedRooms[i][attribute] = value;
      }
    } else {
      if (
        ['anesthesiste', 'seniorJunior', 'interne', 'iade'].includes(attribute) ||
        (attribute !== "nurses" && this.isBPsHaveSameTeam(this.mergedRooms[i]) &&
          !this.mergedRooms[i].isSplit)
      ) {
        this.mergedRooms[i].programs.forEach((elem) => {
          if (value && value.type == "undefined" && ['anesthesiste', 'seniorJunior', 'interne', 'iade'].includes(attribute)) {
            elem[attribute] = value;
            return
          }
          elem[attribute] = value;
        });
      } else if (attribute === "nurses") {
        const nurseIndex = this.mergedRooms[i].programs[j][attribute].findIndex((nurse) => String(nurse.profile._id) === String(nurseId))
        if (nurseIndex !== -1) {
          this.mergedRooms[i].programs[j][attribute].splice(nurseIndex, 1);
        }
      } else {
        this.mergedRooms[i].programs[j][attribute] = value;
      }
    }
  }

  duplicateSurgeonOpenings(i: number, attribute: string, value) {
    if (this.mergedRooms[i].roomNumber.includes("Equipe 1")) {
      this.mergedRooms[i][attribute] = value; //put anesth in team 1
      let val = this.specialSurgeonOpening.find(
        (sso) => sso._id === this.mergedRooms[i]._id
      );

      val._newId.forEach((id: string) => {
        //put anesth in other teams
        let findRoom = this.mergedRooms.find((mr) => mr._id == id);
        findRoom[attribute] = value;
      });
    } else if (this.mergedRooms[i].roomNumber.includes("Equipe")) {
      let val = this.specialSurgeonOpening.find(
        (sso) =>
          sso.room.firstSurgeonOpeningId ===
          this.mergedRooms[i].firstSurgeonOpeningId
      );
      let roomToUpdate = this.mergedRooms.filter(
        (
          elem //find all room with firstSurgeonOpening id equal
        ) => val.room.firstSurgeonOpeningId === elem.firstSurgeonOpeningId
      );
      roomToUpdate.forEach((r) => (r[attribute] = value));
    }
  }

  displayProgram(room: Room, i: number) {
    //call agenda function
    //  this.closeAllPopups();
    //  this.getDoctorAgendas(room, i);
  }

  closeAllPopups() {
    this.displayDA = this.displayDA.map((_) => false);
  }

  getDoctorAgendas(room: Room, i: number): void {
    if (this.doctorAgendasProgramSubscription) {
      this.doctorAgendasProgramSubscription.unsubscribe();
    }

    this.doctorAgendasProgramSubscription = this.agendaService
      .getAllDoctorAgendas(room._id, this.day.toString(), null)
      .subscribe(
        (room: Room) => {
          this.agendas = room.agendas;
          this.displayDA[i] = true;
        },
        (error) => {
          this.isLoading[0] = false;
          this.errorService.handleError(error);
        }
      );
  }

  removeAnesthetist(i: number, j: number, anesthType: number): void {
    let fieldName;

    if (this.doesHospitalHaveAnesthDetailsOption) {
      switch (anesthType) {
        case 0:
          fieldName = 'seniorJunior';
          break;
        case 1:
          fieldName = 'interne';
          break;
        case 2:
          fieldName = 'iade';
          break;
        default:
          fieldName = 'anesthesiste';
          break;
      }
    } else {
      fieldName = 'anesthesiste';
    }
    const anesthetist = JSON.parse(JSON.stringify(this.mergedRooms[i].programs[j][fieldName])); // Make a copy of the object
    this.addAvailableAnesthetist(anesthetist);
    if (j == 0) {
      this.duplicateValue(i, j, fieldName, undefined, false);
      this.duplicateValue(i, j + 1, fieldName, undefined, false);
    }
    this.isProgramsChanged = true;
    this.initAnesthEffectifNeedsToRoomsObjects();
  }

  addAvailableAnesthetist(anesthetist: Profile): void {
    const availableAnesthetistsIds = this.blocAnesthetists.map((a) => a._id);
    if (anesthetist && !availableAnesthetistsIds.includes(anesthetist._id)) {
      const iadesWithSameId = this.allAvailableIades.filter(iade => iade.profile._id === anesthetist._id);
      if (iadesWithSameId.length !== 0) {
        const iade = iadesWithSameId[0];
        this.blocAnesthetists.push(iade);
        this.sortIadesInBlocAnesthetists();
      } else {
        this.blocAnesthetists.push(anesthetist);
      }
    }
  }

  sortIadesInBlocAnesthetists(): void {
    const iades = this.blocAnesthetists.filter(anesthetist => anesthetist.position === "Iade");
    iades.sort((a, b) => {
      const nameA = `${a.firstName} ${a.lastName}`.toUpperCase();
      const nameB = `${b.firstName} ${b.lastName}`.toUpperCase();
      return nameA.localeCompare(nameB);
    });
    this.blocAnesthetists = this.blocAnesthetists.filter(anesthetist => anesthetist.position !== "Iade");
    this.blocAnesthetists.push(...iades);
  }
  removeNurseFromProgram(
    roomIndex,
    programIndex,
    nurseId,
    isSurgeonOpening
  ): void {
    if (isSurgeonOpening) {
      this.removeNurseFromProgramInSurgeonOpeningDataStructure(
        roomIndex,
        programIndex,
        nurseId
      );
    } else {
      this.removeNurseFromProgramInBufferProgramDataStructure(
        roomIndex,
        programIndex,
        nurseId
      );
    }
    this.isProgramsChanged = true;
  }

  removeNurseFromProgramInBufferProgramDataStructure(
    roomIndex,
    programIndex,
    nurseId
  ): void {
    const description = `Etes vous sur(e)s de vouloir retirer l'infirmière de cette salle ?`;
    this.popupManagerService
      .openConfirmationPopup(
        "Retirer l'infirmière ?",
        description,
        330,
        "danger",
        "Oui, retirer"
      )
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          let prog = JSON.parse(JSON.stringify(this.mergedRooms[roomIndex].programs[programIndex]));
          const nurses = prog["nurses"]
          const tmp = nurses.find((nurse) => String(nurse.profile._id) === String(nurseId))
          const nurse = JSON.parse(JSON.stringify(tmp))
          if (this.isBPsHaveSameTeam(this.mergedRooms[roomIndex])) {
            for (let i: number = 0; i < this.mergedRooms[roomIndex].programs.length; i++) {
              const nurseIndex = this.mergedRooms[roomIndex].programs[i]["nurses"].findIndex((nurse) => String(nurse.profile._id) === String(nurseId))
              if (nurseIndex !== -1) {
                this.mergedRooms[roomIndex].programs[i]["nurses"].splice(nurseIndex, 1);
              }
              prog.endTime = this.mergedRooms[roomIndex].programs[i].endTime;
            }
          }

          let n = this.nursesProfile.find((n) => n._id === nurse._id)

          if (n && n.startTime > prog.startTime) {
            prog.startTime = n.startTime;
            prog["isOriginOutOfRoom"] = true
          }
          if (n && n.endTime < prog.endTime) {
            prog.endTime = n.endTime;
            prog["isOriginOutOfRoom"] = true
          }

          this.duplicateValue(
            roomIndex,
            programIndex,
            "nurses",
            undefined,
            false,
            nurseId
          );

          if (IADE.includes(nurse.profile.position)) {
            this.addAvailableAnesthetist(nurse);
            this.initAnesthEffectifNeedsToRoomsObjects();
          } else {
            this.addNurseIsAlreadyInCard(nurse, prog, "nurses");
          }

        }
      });
  }

  removeNurseFromProgramInSurgeonOpeningDataStructure(
    roomIndex,
    programIndex,
    nurseId
  ): void {
    let prog = JSON.parse(JSON.stringify(this.mergedRooms[roomIndex].surgeonOpenings[
      programIndex == -1 ? 0 : programIndex
    ]))
    const nurses = [...this.mergedRooms[roomIndex]['nurses']]
    const nurse = JSON.parse(JSON.stringify(nurses.find((nurse) => String(nurse.profile._id) === String(nurseId))));
    let n = this.nursesProfile.find((n) => n._id === nurse._id)
    
    if (n && n.startTime > prog.startTime) {
      prog.startTime = n.startTime;
      prog["isOriginOutOfRoom"] = true
    }
    if (n && n.endTime < prog.endTime) {
      prog.endTime = n.endTime;
      prog["isOriginOutOfRoom"] = true
    }
    this.duplicateValue(
      roomIndex,
      programIndex,
      "nurses",
      undefined,
      true,
      nurseId
    );
    if (IADE.includes(nurse.profile.position)) {
      this.addAvailableAnesthetist(nurse);
      this.initAnesthEffectifNeedsToRoomsObjects();
    } else {
      this.addNurseIsAlreadyInCard(nurse, prog, "nurses");
    }
  }

  addNurseIsAlreadyInCard(nurse, prog, nurseField) {
    nurse.profile["startTime"] = prog.startTime;
    nurse.profile["endTime"] = prog.endTime;
    nurse["isOriginOutOfRoom"] = !prog.isOriginOutOfRoom ? false : true;
    this.addToAvailableNurses(nurse);
  }

  getProfileHour(id) {
    const n = this.nursesProfile.find(elem => elem._id === id);
    if (!n) {
      return null;
    }
    let startTimeString = this.getStartTime(n.startTime);
    let startTimeNumber = parseInt(startTimeString.replace(":", ""), 10);

    let endTimeString = this.getStartTime(n.endTime);
    let endTimeNumber = parseInt(endTimeString.replace(":", ""), 10);

    return {startTime: startTimeNumber, endTime: endTimeNumber};
  }

  checkDuplicate() {
    for (let nurse of this.nurses) {
      const n = this.nursesProfile.find(elem => elem._id === nurse.profile._id);
      if (n) {
        const otherNurses = this.nurses.find((nurse_) => String(nurse_.profile._id) === String(nurse.profile._id) && (nurse_.profile.startTime !== n.startTime || nurse_.profile.endTime !== n.endTime))
        const originNurse = this.nurses.find((nurse_) => String(nurse_.profile._id) === String(nurse.profile._id) && nurse_.profile.startTime === n.startTime && nurse_.profile.endTime === n.endTime)

        if (originNurse && otherNurses) {
          var index = this.nurses.indexOf(originNurse);
          if (index !== -1) {
            this.nurses.splice(index, 1);
            length--;
          }
        }
      }
    }
  }

  mergeNurseAvailable(newNurse, actualNurses) {
    for (let nurse of actualNurses) {
      if (nurse.profile.startTime === newNurse.profile.endTime) {
        newNurse.profile.endTime = nurse.profile.endTime;
        var index = this.nurses.indexOf(nurse);
        if (index !== -1) {
          this.nurses.splice(index, 1);
        }
      }
      if (nurse.profile.endTime === newNurse.profile.startTime) {
        newNurse.profile.startTime = nurse.profile.startTime;
        var index = this.nurses.indexOf(nurse);
        if (index !== -1) {
          this.nurses.splice(index, 1);
        }
      }
      if (nurse.profile.startTime === newNurse.profile.startTime &&
         nurse.profile.endTime === newNurse.profile.endTime) {
        var index = this.nurses.indexOf(nurse);
        if (index !== -1) {
          this.nurses.splice(index, 1);
        }
      }
    }
    const n = this.getProfileHour(newNurse.profile._id);

    if (!this.isNurseAssignedToAtLeastOneProgram(newNurse.profile._id)) {
      const tmp = this.nursesProfile.find(elem => elem._id === newNurse.profile._id);
      if (tmp) {
        newNurse.profile.startTime = tmp.startTime
        newNurse.profile.endTime = tmp.endTime
      }
    }
    let startTimeString = this.getStartTime(newNurse.profile["startTime"]);
    let startTimeNumber = parseInt(startTimeString.replace(":", ""), 10);

    let endTimeString = this.getStartTime(newNurse.profile["endTime"]);
    let endTimeNumber = parseInt(endTimeString.replace(":", ""), 10);


    if (n && startTimeNumber <= n.startTime && endTimeNumber >= n.endTime) {
      newNurse.isOriginOutOfRoom = true;
    } else {
      newNurse.isOriginOutOfRoom = false;
    }
    return newNurse;
  }

  isNurseAssignedToAtLeastOneProgram(nurseId: string): boolean {
    for (const item of this.mergedRooms) {
      if (item.programs && item.programs.length > 0) {
        for (const program of item.programs) {
          for (const element of program.nurses) {
            if (element.profile._id === nurseId) {
              return true;
            }
          }
        }
      }

      if (item.nurses && item.nurses.length > 0) {
        for (const element of item.nurses) {
          if (element.profile._id === nurseId) {
            return true;
          }
        }
      }
    }

    return false;
  }

  addToAvailableNurses(nurse): void {
    let availableNurses = this.nurses.filter((n) => String(n.profile._id) === String(nurse.profile._id));
    nurse = this.mergeNurseAvailable(nurse, availableNurses);
    if (nurse) {
      this.nurses.push(nurse);
      this.nurses = this.nurses.sort((a, b) => {
        return `${a.profile.firstName.toLowerCase()} ${a.profile.lastName.toLowerCase()}`.localeCompare(
          `${b.profile.firstName.toLowerCase()} ${b.profile.lastName.toLowerCase()}`
        );
      });
    }
  }

  removeAnesthetistFromProgram(
    roomIndex: number,
    programIndex: number,
    isSurgeonOpening: boolean,
    anesthType?: number
  ): void {
    if (isSurgeonOpening) {
      this.removeAnesthFromProgramInSurgeonOpeningDataStructure(
        roomIndex,
        programIndex,
        anesthType
      );
    } else {
      this.removeAnesthFromProgramInBufferProgramDataStructure(
        roomIndex,
        programIndex,
        anesthType
      );
    }
  }

  removeAnesthFromProgramInBufferProgramDataStructure(
    roomIndex: number,
    programIndex: number,
    anesthType
  ): void {
    let position;
    if (this.doesHospitalHaveAnesthDetailsOption) {
      switch (anesthType) {
        case 0:
          position = 'anesthésiste';
          break;
        case 1:
          position = 'anesthésiste';
          break;
        case 2:
          position = 'IADE';
          break;
        default:
          position = 'anesthésiste / IADE';
          break;
      }
    } else {
      position = 'anesthésiste / IADE';
    }
    const description = `Etes vous sur(e)s de vouloir retirer l'${position} de cette salle ?`;

    this.popupManagerService
      .openConfirmationPopup(
        `Retirer l'${position} ?`,
        description,
        370,
        "danger",
        "Oui, retirer"
      )
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.removeAnesthetist(roomIndex, programIndex, anesthType);
        }
      });
  }

  removeAnesthFromProgramInSurgeonOpeningDataStructure(
    roomIndex: number,
    programIndex: number,
    anesthType: number
  ): void {
    let fieldName;

    if (this.doesHospitalHaveAnesthDetailsOption) {
      switch (anesthType) {
        case 0:
          fieldName = 'seniorJunior';
          break;
        case 1:
          fieldName = 'interne';
          break;
        case 2:
          fieldName = 'iade';
          break;
        default:
          fieldName = 'anesthesiste';
          break;
      }
    } else {
      fieldName = 'anesthesiste';
    }

    const anesth = JSON.parse(JSON.stringify(this.mergedRooms[roomIndex][fieldName]));
    this.addAvailableAnesthetist(anesth);
    this.duplicateValue(
      roomIndex,
      programIndex,
      fieldName,
      undefined,
      true
    );
    this.initAnesthEffectifNeedsToRoomsObjects();
  }

  canDropInRoom(room, anesthType: number = null) {
    const doesProfileMatchDropArea = this.doesProfileMatchDropArea(anesthType);

    if (!doesProfileMatchDropArea) {
      this.toastService.infoToast(`Cette zone de dépôt ne peut contenir que des ${anesthType === 0 ? 'séniors/juniors' : anesthType === 1 ? 'internes' : 'anesthésistes' }`);
    }

    const test = !(room.noSurgeonOpenings) && doesProfileMatchDropArea;
    return test;
  }

  doesProfileMatchDropArea(anesthType: number): boolean {
    // anesthType: 0 -> seniorJunior; 1 -> Interne;

    const draggedProfile = this.draggedItem.item;

    if (draggedProfile.position === ANESTHETIST) {
      switch (anesthType) {
        case 0:
          return ['Senior', 'Junior'].includes(draggedProfile.seniority) ? true : false;
        case 1:
          return ['Interne'].includes(draggedProfile.seniority) ? true : false;
        default:
          return true;
      }
    } else {
      return false;
    }
  }

  getStartTime(date: any): string {
    let d = new Date(date);
    d.setTime(d.getTime() + d.getTimezoneOffset() * 60 * 1000);
    return `${d.getHours()}:${d.getMinutes() === 0 ? "00" : d.getMinutes()}`;
  }

  setNewNursesCard(
    room: any,
    nursePosition: string,
    roomindex: number,
    progIndex: number,
    item: any
  ) {
    let prog;
    if (room.emptyRoom) {
      prog = room.surgeonOpenings[0];
    } else {
      prog = room.programs[progIndex];
    }
    let nCopy = room.emptyRoom ? [...room[nursePosition]] : [...prog[nursePosition]]
    nCopy.filter((item) => !IADE.includes(item.profile.position)).forEach(nurse => {
      let obj = [];
      this.mergedRooms.forEach((elem) => {
        if (
          (elem.emptyRoom && elem.nurses && elem.nurses.some((nurse_) => String(nurse_.profile._id) === String(nurse.profile._id)))
        ) {
          obj.push({
            _id: nurse.profile._id,
            startTime: elem.surgeonOpenings[0].startTime,
            endTime: elem.surgeonOpenings.at(-1).endTime,
          });
        }
        if (!elem.emptyRoom) {
          if (!this.isBPsHaveSameProfil(elem, 'nurses')) {
            for (const p of elem.programs) {
                if (
                  (p.nurses && p.nurses.some((nurse_) => String(nurse_.profile._id) === String(nurse.profile._id)))
                ) {
                  obj.push({
                    _id: nurse.profile._id,
                    startTime: p.startTime,
                    endTime: p.endTime,
                  });
                }
            }
          } else {
            if (
              (elem.programs[0].nurses && elem.programs[0].nurses.some((nurse_) => String(nurse_.profile._id) === String(nurse.profile._id)))
            ) {
              obj.push({
                _id: nurse.profile._id,
                startTime: elem.programs[0].startTime,
                endTime: elem.programs.at(-1).endTime,
              });
            }
          }
        }
      });
  
      let copyStartTimeString = this.getStartTime(nurse.profile.startTime);
      let copyStartTimeNumber = parseInt(
        copyStartTimeString.replace(":", ""),
        10
      );
  
      let copyEndTimeString = this.getStartTime(nurse.profile.endTime);
      let copyEndTimeNumber = parseInt(copyEndTimeString.replace(":", ""), 10);
  
      obj = obj.filter((o) => {
        let startTimeString = this.getStartTime(o.startTime);
        let startTimeNumber = parseInt(startTimeString.replace(":", ""), 10);
  
        let endTimeString = this.getStartTime(o.endTime);
        let endTimeNumber = parseInt(endTimeString.replace(":", ""), 10);
        if (
          copyStartTimeNumber <= startTimeNumber ||
          copyEndTimeNumber >= endTimeNumber
        ) {
          return true;
        }
      });
    
      if (obj.length > 1) {
        this.newCardSplit(obj, nurse, copyStartTimeNumber, copyEndTimeNumber)
        return
      } else if (obj.length === 0) {
        return 
      }
      let startTimeString = this.getStartTime(obj[0]["startTime"]);
      let startTimeNumber = parseInt(startTimeString.replace(":", ""), 10);
  
      let endTimeString = this.getStartTime(obj[0]["endTime"]);
      let endTimeNumber = parseInt(endTimeString.replace(":", ""), 10);
      

      if (copyStartTimeNumber < startTimeNumber) {
        JSON.parse(JSON.stringify(nurse))
        let n = JSON.parse(JSON.stringify(nurse));
        n.profile["startTime"] = nurse.profile.startTime;
        n.profile["endTime"] = obj[0]["startTime"];
        n["isOriginOutOfRoom"] = false;
        this.addToAvailableNurses(n);
      }
      if (copyEndTimeNumber > endTimeNumber) {
        let n = JSON.parse(JSON.stringify(nurse));
        n.profile["startTime"] = obj[0]["endTime"];
        n.profile["endTime"] = nurse.profile.endTime;
        n["isOriginOutOfRoom"] = false;
        this.addToAvailableNurses(n);
      }
    });
  }

  newCardSplit(obj : any, copy, start, end) {  
    let startTimeString = this.getStartTime(obj[0]["endTime"]);
    let startTimeNumber = parseInt(startTimeString.replace(":", ""), 10);

    let endTimeString = this.getStartTime(obj[1]["startTime"]);
    let endTimeNumber = parseInt(endTimeString.replace(":", ""), 10);

    if (startTimeNumber < endTimeNumber) {
      let n = JSON.parse(JSON.stringify(copy));
      n.profile["startTime"] = obj[0]["endTime"];
      n.profile["endTime"] = obj[1]["startTime"];
      n["isOriginOutOfRoom"] = false;
      this.addToAvailableNurses(n);
    } else {
      let n = JSON.parse(JSON.stringify(copy));
      n.profile["startTime"] = obj[1]["endTime"];
      n.profile["endTime"] = obj[0]["startTime"];
      n["isOriginOutOfRoom"] = false;
      this.addToAvailableNurses(n);
    }
  }

  dropIsAvailable(prog: any, item: any): boolean {
    let startTimeString = this.getStartTime(item.item.startTime);
    let startTimeNumber = parseInt(startTimeString.replace(":", ""), 10);

    let endTimeString = this.getStartTime(item.item.endTime);
    let endTimeNumber = parseInt(endTimeString.replace(":", ""), 10);

    let progStartTimeString = this.getStartTime(prog.startTime);
    let progStartTimeNumber = parseInt(
      progStartTimeString.replace(":", ""),
      10
    );

    let progEndTimeString = this.getStartTime(prog.endTime);
    let progEndTimeNumber = parseInt(progEndTimeString.replace(":", ""), 10);

    if (
      startTimeNumber <= progStartTimeNumber &&
      endTimeNumber >= progEndTimeNumber
    ) {
      return true;
    } else {
      return false;
    }
  }

  canDropInRoomSplit(nurse, index, prog) : boolean{
    let start, end;
    prog = prog == -1 ? 0 : prog
    if (this.mergedRooms[index].emptyRoom) {
      start = this.mergedRooms[index].surgeonOpenings[prog].startTime;
      end = this.mergedRooms[index].surgeonOpenings[prog].endTime;
    } else if (this.isBPsHaveSameTeam(this.mergedRooms[index])) {
      start = this.mergedRooms[index].programs[0].startTime;
      end = this.mergedRooms[index].programs.at(-1).endTime;
    } else {
        start = this.mergedRooms[index].programs[prog].startTime;
        end = this.mergedRooms[index].programs[prog].endTime;
    }
    if (!nurse.profile.startTime && !nurse.profile.endTime) {
      nurse.profile["startTime"] = start;
      nurse.profile["endTime"] = end;
    }
  
    nurse = this.mergeNurseAvailable(nurse, []);

    let obj = [];
    this.mergedRooms.forEach((elem) => {
      if (
        (elem.emptyRoom && elem.nurses && elem.nurses.some((nurse_) => String(nurse_.profile._id) === String(nurse.profile._id)))
      ) {
        obj.push({
          _id: nurse.profile._id,
          startTime: elem.surgeonOpenings[0].startTime,
          endTime: elem.surgeonOpenings.at(-1).endTime,
        });
      }
      if (!elem.emptyRoom) {
        for (const p of elem.programs) {
          if (
            (p.nurses && p.nurses.some((nurse_) => String(nurse_.profile._id) === String(nurse.profile._id)))
          ) {
            obj.push({
              _id: nurse.profile._id,
              startTime: p.startTime,
              endTime: p.endTime,
            });
          }
        }
      }
    });

    let copyStartTimeString = this.getStartTime(start);
    let copyStartTimeNumber = parseInt(copyStartTimeString.replace(":", ""), 10);

    let copyEndTimeString = this.getStartTime(end);
    let copyEndTimeNumber = parseInt(copyEndTimeString.replace(":", ""), 10);

    obj = obj.filter((o) => {
      let startTimeString = this.getStartTime(o.startTime);
      let startTimeNumber = parseInt(startTimeString.replace(":", ""), 10);
      
      let endTimeString = this.getStartTime(o.endTime);
      let endTimeNumber = parseInt(endTimeString.replace(":", ""), 10);
      if (
        !((copyStartTimeNumber >= endTimeNumber) || (copyEndTimeNumber <= startTimeNumber))
      ) {
        return true;
      }
    });

    if (obj.length === 0) {
      return true;
    }
    return false;
  }

  drop(i, j, position, isSurgeonOpening?, anesthType?: number): void {
    // position => 0: anesth, 1: nurse1, 2: nurse2
    // i => line or room index
    // j => program index when special configuration
    // anesthType => useful when doesHospitalHaveAnesthDetailsOption; 0: serniorJunior, 1: interne, 2: IADE
  
    const droppedRoom = this.mergedRooms[i];
    if (this.canDropInRoom(droppedRoom, anesthType)) {
      this.isProgramsChanged = true;
      let itemToChange = null;

      if (
        this.draggedItem.nurseType &&
        this.draggedItem.nurseType !== position
      ) {
        if (this.draggedItem.nurseType === 1) {
          position = 4;
        } else {
          position = 3;
        }
      }
      const isDraggingFromInside = this.draggedItem.room >= 0;
      switch (position) {
        case 0:
          if (this.doesHospitalHaveAnesthDetailsOption) {
            switch (anesthType) {
              case 0:
                itemToChange = isSurgeonOpening ? droppedRoom.seniorJunior : droppedRoom.programs[j].seniorJunior;
                break;
              case 1:
                itemToChange = isSurgeonOpening ? droppedRoom.interne : droppedRoom.programs[j].interne;
                break;
              case 2:
                itemToChange = isSurgeonOpening ? droppedRoom.iade : droppedRoom.programs[j].iade;
                break;
              default:
                itemToChange = isSurgeonOpening ? droppedRoom.anesthesiste : droppedRoom.programs[j].anesthesiste;
                break;
            }
          } else {
            itemToChange = isSurgeonOpening ? droppedRoom.anesthesiste : droppedRoom.programs[j].anesthesiste;
          }
          if (itemToChange && itemToChange._id !== this.draggedItem.item._id) {
            if (isSurgeonOpening) {
              this.permute(i, j, itemToChange, position, isSurgeonOpening, anesthType);
            } else {
              this.openPermutePopup(i, j, itemToChange, position, anesthType);
            }
          } else if (!itemToChange) {
            this.permute(i, j, undefined, position, isSurgeonOpening, anesthType);
          }
          break;
        // case 1 || 2 || 3 || 4:
        //   itemToChange = isSurgeonOpening
        //     ? droppedRoom.nurses
        //     : droppedRoom.programs[j].nurses;
          
        //   if (!isDraggingFromInside && !this.canDropInRoomSplit(this.draggedItem, i, j)) {
        //     break;
        //   };
        //   if (itemToChange && itemToChange._id !== this.draggedItem.item._id) {
        //     if (isSurgeonOpening) {
        //       this.permute(i, j, itemToChange, position, isSurgeonOpening);
        //     } else {
        //       this.openPermutePopup(i, j, itemToChange, position);
        //     }
        //   } else if (!itemToChange) {
        //     this.permute(i, j, undefined, position, isSurgeonOpening);
        //   }
        //   this.setNewNursesCard(droppedRoom, "nurses", i, j, this.draggedItem);
        //   break;
        default:
          break;
      }
    } else {
      this.dynamicMarginsForAvailableProfiles.draggingAnesthIndex = -1;
      this.dynamicMarginsForAvailableProfiles.draggingNurseIndex = -1;
    }
  }

  setMarginRightForAvailableAnesth(): void {
    this.dynamicMarginsForAvailableProfiles.anesthsMarginRight = `12px`;
    this.dynamicMarginsForAvailableProfiles.consultationExtracliniqueAnesthsMarginRight = `12px`;

    if (this.availableAnesthsContainer) {
      let containerWidth =
        this.availableAnesthsContainer._osTargetRef.nativeElement.offsetWidth;
      containerWidth = Math.floor(containerWidth * ZOOM_VALUE);
      containerWidth -= 14;

      const a = Math.floor(containerWidth / 264);
      const b = containerWidth % 264;
      let numberOfElementsInRow = a;

      let totalSpaceForMargins;

      // 12 -> min margin right
      if (b > a * 12) {
        // if we can set at least 12px margin right
        totalSpaceForMargins = b;
      } else {
        // else we use the width of an element as margin (n-1 element instead of n elements in one row)
        totalSpaceForMargins = 264 + b;
        numberOfElementsInRow = a - 1;
      }

      this.dynamicMarginsForAvailableProfiles.numberOfAnesths =
        numberOfElementsInRow;

      this.dynamicMarginsForAvailableProfiles.numberOfConsultationExtracliniqueAnesths = numberOfElementsInRow;

      const marginRight = Math.floor(
        totalSpaceForMargins / (numberOfElementsInRow - 1)
      );
      this.dynamicMarginsForAvailableProfiles.anesthsMarginRight = `${
        marginRight * OPPOSITE_ZOOM_VALUE
      }px`;
      this.dynamicMarginsForAvailableProfiles.consultationExtracliniqueAnesthsMarginRight = `${
        marginRight * OPPOSITE_ZOOM_VALUE
      }px`;
    }
  }

  setMarginRightForAvailableNurse(): void {
    this.dynamicMarginsForAvailableProfiles.nursesMarginRight = `12px`;

    if (this.availableNursesContainer) {
      let containerWidth =
        this.availableNursesContainer._osTargetRef.nativeElement.offsetWidth;
      containerWidth = Math.floor(containerWidth * ZOOM_VALUE);
      containerWidth -= 14;

      const a = Math.floor(containerWidth / 264);
      const b = containerWidth % 264;
      let numberOfElementsInRow = a;

      let totalSpaceForMargins;

      // 12 -> min margin right
      if (b > a * 12) {
        // if we can set at least 12px margin right
        totalSpaceForMargins = b;
      } else {
        // else we use the width of an element as margin (n-1 element instead of n elements in one row)
        totalSpaceForMargins = 264 + b;
        numberOfElementsInRow = a - 1;
      }

      this.dynamicMarginsForAvailableProfiles.numberOfNurses =
        numberOfElementsInRow;

      const marginRight = Math.floor(
        totalSpaceForMargins / (numberOfElementsInRow - 1)
      );
      this.dynamicMarginsForAvailableProfiles.nursesMarginRight = `${
        marginRight * OPPOSITE_ZOOM_VALUE
      }px`;
    }
  }

  toggleShowAvailableProfiles(): void {
    this.showAvailableProfiles = !this.showAvailableProfiles;
    setTimeout(() => {
      this.setTableMaxHeight();
      this.setMarginRightForAvailableAnesth();
      this.setMarginRightForAvailableNurse();
    }, 200);
  }

  dropToOutOfRoom(type: string): void {
    if (
      this.draggedItem &&
      this.draggedItem.item &&
      this.mergedRooms[this.draggedItem.room]
    ) {
      if (type === NURSE && (this.draggedItem.item as any).profile && (this.draggedItem.item as any).profile.position && (this.draggedItem.item as any).profile.position !== "Iade") {
        this.nurses.push(this.draggedItem.item);
        this.duplicateValue(
          this.draggedItem.room,
          this.draggedItem.program,
          "nurses" + this.draggedItem.nurseType,
          undefined,
        );
      } else if (type === ANESTHETIST) {
        this.addAvailableAnesthetist(this.draggedItem.item);
        this.duplicateValue(
          this.draggedItem.room,
          this.draggedItem.program,
          "anesthesiste",
          undefined
        );
      }
    }
    this.draggedItem = null;
    this.dynamicMarginsForAvailableProfiles.draggingAnesthIndex = -1;
    this.dynamicMarginsForAvailableProfiles.draggingNurseIndex = -1;
  }

  getFullRooms() {
    const roomsWithBufferPrograms = this.mergedRooms.filter((room) => !room.emptyRoom);

    roomsWithBufferPrograms.forEach((room) => {
      room.programs.forEach((program) => {
        const anesthesists = [];

        if (this.doesHospitalHaveAnesthDetailsOption) {
            if (program.seniorJunior) {
              anesthesists.push(program.seniorJunior);
            }

            if (program.interne) {
              anesthesists.push(program.interne);
            }

            if (program.iade) {
              anesthesists.push(program.iade);
            }
        } else {
          if (program.anesthesiste) {
            anesthesists.push(program.anesthesiste);
          }
        }

        program.anesthesists = anesthesists;
      });
    })

    return roomsWithBufferPrograms;
  }

  saveComment() {
    if (this.comment !== null) {
      if (this.programCommentary !== null)
        this.updateProgramCommentary(this.getDate());
      else this.addProgramCommentary(this.getDate());
    }
  }

  save(): Promise<void> {
    if (!this.home) {
      return this.saveBufferProgram();
    } else {
      return this.saveBufferProgram(true);
    }
  }

  saveBufferProgram(realPrograms?: boolean): Promise<void> {
    this.isLoading[0] = true;
    if (this.specialConfigurations.length > 0) {
      return this.addBufferPrograms(realPrograms);
    } else {
      return this.updateBufferPrograms(realPrograms);
    }
  }

  formatSpecialBufferFromSurgeon(roomsWithSo: any[]): any[] {
    if (this.specialSurgeonOpening.length > 0) {
      this.specialSurgeonOpening.forEach((val) => {
        let openings = [];
  
        let itemToDel = roomsWithSo.filter((item) =>
          val._newId.includes(item._id)
        );
        let itemToDelOrigin = roomsWithSo.filter(
          (item) => val._id === item._id
        );
        const positionOrigin = roomsWithSo.indexOf(itemToDelOrigin[0]);
        itemToDelOrigin = itemToDelOrigin.concat(itemToDel);
        // proceed to remove an item only if it exists.
        if (itemToDelOrigin.length != 0) {
          itemToDelOrigin.map((item) => {
            if (item.surgeonOpenings && item.surgeonOpenings[0]) {
              item.surgeonOpenings[0].nurses = item.nurses;
              item.surgeonOpenings[0].anesthesiste = item.anesthesiste;
              item.surgeonOpenings[0].seniorJunior = item.seniorJunior;
              item.surgeonOpenings[0].interne = item.interne;
            }
          });

          for (const elem of itemToDelOrigin) {
            // if (!elem.surgeonOpenings[0])
            //   {
            //     continue;
            //   }
            openings.push(elem.surgeonOpenings[0]);
            let index = roomsWithSo.indexOf(elem);
            if (index !== -1) {
              roomsWithSo.splice(index, 1);
            }
          }
        }
        if (val.room) {
          val.room.surgeonOpenings = openings;
          roomsWithSo.splice(positionOrigin, 0, val.room);
        }
      });
    }
    return roomsWithSo;
  }
  

  generateBufferFromSurgeons(): any[] {
    let roomsWithSurgeonOpenings = this.mergedRooms.filter(
      (mergedRoom) => mergedRoom.emptyRoom
    );
    const date = moment(this.day).format("YYYY-MM-DD").toString();
    roomsWithSurgeonOpenings = this.formatSpecialBufferFromSurgeon(
      roomsWithSurgeonOpenings
    );
  
    return roomsWithSurgeonOpenings.reduce((allBuffers, mergedRoom) => {
      const roomBuffers = this.generateRoomBuffers(mergedRoom, date);
      if (roomBuffers.some(buffer => buffer.nurses !== null && buffer.nurses.length > 0 || buffer.anesthesists.length !== 0)) {
        return allBuffers.concat(roomBuffers);
      } else {
        return allBuffers;
      }
    }, []);
  }
  

  generateRoomBuffers(room, date) {
    let roomBuffers = [];
    if (room.anesthesiste || room.seniorJunior || room.interne || room.iade || room.nurses || room.isSplit) {
      roomBuffers = room.surgeonOpenings.map((el) => {
        const anesthesists = [];

        if (this.doesHospitalHaveAnesthDetailsOption) {
          if (room.isSplit) {
            if (el.seniorJunior) {
              anesthesists.push(el.seniorJunior._id);
            }

            if (el.interne) {
              anesthesists.push(el.interne._id);
            }

            if (el.iade) {
              anesthesists.push(el.iade._id);
            }
          } else {
            if (room.seniorJunior) {
              anesthesists.push(room.seniorJunior._id);
            }

            if (room.interne) {
              anesthesists.push(room.interne._id);
            }

            if (room.iade) {
              anesthesists.push(room.iade._id);
            }
          }
        } else {
          if (room.isSplit) {
            if (el.anesthesiste) {
              anesthesists.push(el.anesthesiste._id);
            }
          } else {
            if (room.anesthesiste) {
              anesthesists.push(room.anesthesiste._id);
            }
          }
        }

        let data;
        data = {
          anesthesists,
          nurses: !room.isSplit
            ? room.nurses
              ? room.nurses.filter((nurse) => {
                if (nurse && nurse.role && nurse.role._id &&
                  nurse.profile && nurse.profile._id) {
                    return {
                      role: String(nurse.role._id),
                      profile: String(nurse.profile._id)
                    }
                  } else {
                    return false
                  }
                })
              : null
            : el.nurses
            ? el.nurses.filter((nurse) => {
              if (nurse && nurse.role && nurse.role._id &&
                nurse.profile && nurse.profile._id) {
                  return {
                    role: String(nurse.role._id),
                    profile: String(nurse.profile._id)
                  }
                } else {
                  return false
                }
              }
            )
            : null,
          room: room._id,
          surgeon: el.surgeon ? el.surgeon._id : null,
          date: date + "T00:00:00.000Z",
          startTime: date + el.startTime.substring(10, 17) + "00.000Z",
          endTime: date + el.endTime.substring(10, 17) + "00.000Z",
          type: 'bloc',
          hospital: this.userService.getSelectedHospitals()[0],
        }

        return data;
      });
      
    }
    return roomBuffers;
  }

  createSpecialRoomBuffer(rooms, date) {
    let roomBuffers = [];
    if (!rooms[0].programs) {
      return []
    }
    rooms[0].programs.forEach((p) => {
      roomBuffers.push({
        anesthesist: p.anesthesiste ? p.anesthesiste._id : null,
        nurses: p.nurses ? p.nurses.map((nurse) => ({
          role: String(nurse.role._id),
          profile: String(nurse.profile._id)
        })) : null,
        room: rooms[0]._id,
        surgeon: p.surgeon ? p.surgeon._id : null,
        date: date + "T00:00:00.000Z",
        startTime: date + p.startTime.substring(10, 17) + "00.000Z",
        endTime: date + p.endTime.substring(10, 17) + "00.000Z",
        hospital: this.userService.getSelectedHospitals()[0]
      });
    });
    return roomBuffers;
  }

  formatSpecialBufferPrograms() {
    let res = [];
    const date = moment(this.day).format("YYYY-MM-DD").toString();
    this.specialConfigurations.forEach((val) => {
      let rooms = this.mergedRooms.filter((room) => room._id == val._id);
      val.progs.forEach((_id) =>
        this.bufferProgramService.deleteBufferProgram(_id).subscribe()
      );
      if (rooms.length > 0) {
        res = res.concat(this.createSpecialRoomBuffer(rooms, date));
      }
    });
    return res;
  }

  addBufferPrograms(realPrograms?: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        let consultationBufferProgramsToSave, extracliniqueBufferProgramsToSave;
        if (this.doesHospitalHaveAnesthDetailsOption) {
          consultationBufferProgramsToSave = this.prepareConsultationBufferProgramsToSave();
          extracliniqueBufferProgramsToSave = this.prepareExtracliniqueBufferProgramsToSave();
        } else {
          consultationBufferProgramsToSave = [];
          extracliniqueBufferProgramsToSave = [];
        }

        let bufferProgram = this.formatSpecialBufferPrograms();

        this.bufferProgramService
          .addBufferProgram(this.getDate(), [...bufferProgram, ...consultationBufferProgramsToSave, ...extracliniqueBufferProgramsToSave])
          .subscribe(
            () => {
              this.specialConfigurations = [];
              this.getRooms(this.getDate(), this.roomsSurgeonOpenings, realPrograms);
              resolve();
            },
            (error) => {
              this.isLoading[0] = false;
              this.errorService.handleError(error);
              reject(error);
            }
        );
      } catch (e) {
        reject(e);
      }
    });
  }

  updateBufferPrograms(realPrograms?: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        let consultationBufferProgramsToSave, extracliniqueBufferProgramsToSave;
        if (this.doesHospitalHaveAnesthDetailsOption) {
          consultationBufferProgramsToSave = this.prepareConsultationBufferProgramsToSave();
          extracliniqueBufferProgramsToSave = this.prepareExtracliniqueBufferProgramsToSave();
        } else {
          consultationBufferProgramsToSave = [];
          extracliniqueBufferProgramsToSave = [];
        }

        this.updateBufferProgramSubscription = this.bufferProgramService
          .updateManyBufferProgram(
            this.getDate(),
            this.getFullRooms(), //NO EMPTY
            [...this.generateBufferFromSurgeons(), ...consultationBufferProgramsToSave, ...extracliniqueBufferProgramsToSave],
            this.urgenceProfile,
          )
          .subscribe(
            () => {
              this.isProgramsChanged = false;
              if (!this.home)
                this.toastService.infoToast("L'opération a été prise en charge");
              this.getRooms(
                this.getDate(),
                this.roomsSurgeonOpenings,
                realPrograms
              );
              resolve();
            },
            (error) => {
              this.isLoading[0] = false;
              this.errorService.handleError(error);
              reject(error);
            }
          );
      } catch (e) {
        reject(e);
      }
    });
  }

  getDayName(date) {
    return this.utilisService.getDayName(date);
  }

  validate(): void {
    const description = `Etes vous sûr(e) de vouloir valider le programme du ${moment
      .utc(this.day)
      .format("LL")} ?`;

    this.popupManagerService
      .openConfirmationPopup(
        "Valider le programme ?",
        description,
        0,
        "primary",
        "Valider"
      )
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.saveBufferProgram(true);
        }
      });
  }

  prepareConsultationAndExtracliniqueRealProgramsToSave(): any[] {
    const response = [];
    const constultationAndExtraclinique = this.allBufferPrograms.find((elt) => !elt._id);

    if (constultationAndExtraclinique) {
      constultationAndExtraclinique.programs.forEach((pro) => {
        const realProgram = {
          specialty: pro.specialty,
          bufferprogram: pro._id,
          anesthesists: pro.anesthesists.map((p) => p._id),
          date: pro.date,
          roomEntryTime: pro.startTime,
          roomExitTime: pro.endTime,
          type: pro.type,
        };

        response.push(realProgram);
      });
    }
    return response;
  }

  insertDayProgram() {
    this.isLoading[0] = true;
    let blocPrograms = [];

    blocPrograms = this.getFullRooms();

    this.insertSubscription = this.realProgramService
      .insertDayProgram(
        this.getDate(),
        blocPrograms, 
        this.urgenceProfile
      )
      .subscribe(
        () => {
          this.isLoading[0] = false;
          this.toastService.infoToast("L'opération a été prise en charge");
          this.programAlreadyValidated = true;
          this.goBack();
        },
        (error) => {
          this.isLoading[0] = false;
          this.errorService.handleError(error);
        }
      );
  }

  async cancelAll() {
    await this.getRelatedData();
  }

  deleteProgram(): void {
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      width: "400px",
      height: "150px",
      data: {
        cssClass: "danger",
        message: `Etes vous sûr(e) de vouloir supprimer le programme de ${moment
          .utc(this.day)
          .format("LL")} ?`,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteDayProgram();
      }
    });
  }

  deleteDayProgram(): void {
    let counter = 0;
    let total = this.countPrograms();
    this.getFullRooms().forEach((room) => {
      room.programs.forEach((program) => {
        this.deleteProgramSubscription = this.bufferProgramService
          .deleteBufferProgram(program._id)
          .subscribe(
            () => {
              counter++;
              if (total === counter) {
                this.toastService.infoToast("L'opération a été prise en charge");
                this.navigationService
                  .navigateByUrl(pages.pendingRequestsHome)
                  .then()
                  .catch((error) => this.errorService.handleError(error));
              }
            },
            (error) => {
              counter++;
              this.errorService.handleError(error);
            }
          );
      });
    });
  }

  countPrograms(): number {
    let counter = 0;
    this.getFullRooms().forEach((room) => {
      room.programs.forEach(() => {
        counter++;
      });
    });
    return counter;
  }

  showItem(room: Room, attribute: string, index: number): boolean {
    return checkDuplication(room, attribute, index);
  }

  /***************************************************************************************************** */

  async ngAfterViewInit() {
    await new Promise((resolve, reject) => {
      const onMove$ = this.dragEls.changes.pipe(
        startWith(this.dragEls),
        map((d: QueryList<CdkDrag>) => d.toArray()),
        map((dragels) => dragels.map((drag) => drag.moved)),
        switchMap((obs) => merge(...obs)),
        tap(this.triggerScroll)
      );
  
      this.subsScroll.add(onMove$.subscribe());
  
      const onDown$ = this.dragEls.changes.pipe(
        startWith(this.dragEls),
        map((d: QueryList<CdkDrag>) => d.toArray()),
        map((dragels) => dragels.map((drag) => drag.ended)),
        switchMap((obs) => merge(...obs)),
        tap(this.cancelScroll)
      );
  
      // Bloc
      this.calculateChirurgiensItemWidth();
      this.calculateAnesthesistItemWidth();
      this.calculateSallesColumnWidth();
      this.calculateParamedicalItemWidth();
  
      this.calculateItemsWidth();
  
      // Consultation
      this.calculateSpecialtyColumnWidth();
      this.calculateDayColumnWidth();
      this.calculateMorningColumnWidth();
      this.calculateAfternoonColumnWidth();
  
      this.calculateConsultationWidth();
  
      // Extraclinique
      this.calculateExtraColumnWidth();
      this.calculateExtraDayColumnWidth();
      this.calculateExtraMorningColumnWidth();
      this.calculateExtraAfternoonColumnWidth();
  
      this.calculateExtraWidth();
  
      this.subsScroll.add(onDown$.subscribe());
    })
    this.setTargetElementsWidth();
  }

  ngAfterViewChecked() {
    if(this.blocConfigs.chirurgiensItemWidth.length === 0){
      this.calculateChirurgiensItemWidth();
      this.calculateAnesthesistItemWidth();
      this.calculateSallesColumnWidth();
      this.calculateParamedicalItemWidth();
  
      this.calculateItemsWidth();
  
      this.setTargetElementsWidth();
    }
  }

  @bound
  public triggerScroll($event: CdkDragMove) {
    if (this.animationFrame) {
      cancelAnimationFrame(this.animationFrame);
      this.animationFrame = undefined;
    }
    this.animationFrame = requestAnimationFrame(() => this.scroll($event));
  }

  @HostListener("window:beforeunload")
  saveDate() {
    this.UtilisService.saveDate(this, this.day);
  }

  getOnlyOpenedSurgeonOpeningsOfRoom(roomSurgeonOpenings) {
    return roomSurgeonOpenings.filter(
      (roomSurgeonOpening) =>
        !("opening" in roomSurgeonOpening) || roomSurgeonOpening.opening
    );
  }

  getOnlyOpenedSurgeonOpeningsOfRooms() {
    this.roomsSurgeonOpenings = this.roomsSurgeonOpenings.map(
      (roomSurgeonOpenings) => {
        return {
          ...roomSurgeonOpenings,
          surgeonopeninghistory: this.getOnlyOpenedSurgeonOpeningsOfRoom(
            roomSurgeonOpenings.surgeonopeninghistory
          ),
          surgeonopening: this.getOnlyOpenedSurgeonOpeningsOfRoom(
            roomSurgeonOpenings.surgeonopening
          ),
        };
      }
    );
  }

  getHospitalRoomsDaysSurgeonOpenings() {
    const date = this.getDate();

    return this.roomsService
      .getHospitalRoomsDaysSurgeonOpenings(date)
        .pipe(
          switchMap(
            (rooms: any[]) => {
              this.roomsSurgeonOpenings = rooms;
              sortRoomsByPriority(this.roomsSurgeonOpenings, false)
              this.typeAttributeArray = setTypeAttributeArray(
                this.roomsSurgeonOpenings,
                false
              );

              this.getOnlyOpenedSurgeonOpeningsOfRooms();
              return this.getRoomsObservable(date, this.roomsSurgeonOpenings);
            },
          )
        );
  }

  getDate() {
    return moment(this.day).format("YYYY-MM-DD").toString();
  }

  openExportDialog() {
    const dialogRef = this.dialog.open(ExportDayComponent, {
      // width: '400px',
      data: {
        date: this.getDate(),
        isDayWeek: this.isDayWeek,
        comment: this.comment
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.toastService.infoToast("Le planning a été généré en pdf!");
      }
    });
  }

  goToWeekVue() {
    this.navigationService
      .navigateTo(pages.weekVue, null, {
        day: moment(this.day).format("YYYY-MM-DD"),
      })
      .catch((error) => this.errorService.handleError(error));
  }

  async autoFilling() {
    const dialogRef = this.dialog.open(AutofillingComponent, {
      // width: "600px",
      data: { date: this.getDate(), isDayWeek: this.isDayWeek, rooms: this.mergedRooms, specialSurgeonOpening: this.specialSurgeonOpening, roleData: this.roleData },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.loadSplitNurses = false;
        this.blocNeeds = null;
        this.toastService.infoToast("Le remplissage automatique a été fait");
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const dayWithoutTime = new Date(this.day);
        dayWithoutTime.setHours(0, 0, 0, 0);
        if ((!this.isHome || !this.doesHospitalHaveProgramsDataPipelineOption)) {
          if (dayWithoutTime <= today)
          {
            this.getNonValidatedDays();
          }
          else
          {
            this.setIsProgramAlreadyValidated();
          }
        }
        this.getUrgenceProfile();
        this.nurses=[];
        this.nursesProfile=[];
        this.anesthetistsCalendars = [];
        this.blocAnesthetists = [];
        this.IADEsAlreadyInRoom = [];
        this.consultationExtracliniqueAnesthetists = [];
        this.filtredAnesthesists = [];
        if (this.day >= today) {
          await this.getAvailableNurses();
          this.getHospitalRoomsDaysDoctorAgendas();
          this.getNursesRankings();
          this.getAnesthsRankings();
          this.getAvailableAnesthetists();
        }  
        await this.getRelatedData();

      }
    });
  }

  switchDisponiblesProfilesView(view: string): void {
    if (view !== this.actifProfilesView) {
      this.actifProfilesView = view;
      this.changeDetectorRef.detectChanges();
    }
  }

  switchSection(section: string): void {
    if (section !== this.actifSection) {
      this.actifSection = section;
      this.filterAvailableAnesthesists();
      this.changeDetectorRef.detectChanges();
      this.setTargetElementsWidth();
    }
  }

  mousePressedAProfile(profile: any): void {
    profile.isActive = true;
  }

  mouseUpProfile(profile: any): void {
    profile.isActive = false;
  }

  mouseLeavedAProfile(profile: any): void {
    profile.isActive = false;
  }

  mouseEntredAProfile(profile: any): void {
    profile.isActive = true;
  }

  onProfileClick(profile: Profile, allowRedirection: boolean) {
    if (!allowRedirection || ["Iade", "IADE"].includes(profile.position) && this.levelOfAccess === 4) {
      return;
    }
    
    if (profile.position === SURGEON) {
      if (this.userService.getCurrentUser().profile.position === ANESTHETIST) {
        this.navigationService.navigateTo(
          pages.parSallesBesoins,
          null,
          { date: this.getDate() },
          false
        );
      } else {
        this.navigationService.navigateTo(
          pages.parSalles,
          null,
          { date: this.getDate() },
          false
        );
      }
    } else {
      this.navigationService.navigateTo(
        pages.profiles + "/" + profile._id,
        null,
        null,
        false
      );
    }

    /*
    if (profile.position === SURGEON) {
      if (levelOfAccess >= 4) {
        this.navigationService.navigateTo(
          pages.parSalles,
          null,
          { date: this.getDate() },
          false
        );
      }
    } else if (isNurse(profile)) {
      if (levelOfAccess >= 4) {
        this.navigationService.navigateTo(
          pages.profiles + "/" + profile._id,
          null,
          null,
          false
        );
      }
    } else if (isAnesthetist(profile)) {
      if (levelOfAccess >= 4 || isAnesthetist(currentUser.profile)) {
        this.navigationService.navigateTo(
          pages.profiles + "/" + profile._id,
          null,
          null,
          false
        );
      }
    }
    */
  }

  async userOnWhichProg() {
    return new Promise<void>(async (resolve) => {
      this.isLoading[1] = true
      const profileId = this.userService.getCurrentUser().profile
      if (this.isProfileAssignedToBlocProgram(profileId)) {
        this.actifSection = "Bloc"
      } else {
        const isAssignedToConsultationProgram = this.isProfileAssignedToConsultationProgram(profileId);
        const isAssignedToExtracliniqueProgram = this.isProfileAssignedToExtracliniqueProgram(profileId);

        if (isAssignedToConsultationProgram || isAssignedToExtracliniqueProgram) {
          if (this.extraDate === null) {
            this.actifSection = "Consultation"
            
          }
          else if (this.consultDate === null) {
            this.actifSection = "Extraclinique"
          }
          else {
            if (this.consultDate < this.extraDate) {
              this.actifSection = "Consultation"
            }
            else {
              this.actifSection = "Extraclinique"
            }
          }
        } else {
          this.actifSection = "Bloc"
        }
      } 
      
      this.isLoading[1] = false
      resolve()
    })
  }

  ngOnDestroy(): void {
    this.saveDate();

    if (this.getNursesRankingsSubscription) {
      this.getNursesRankingsSubscription.unsubscribe();
    }

    if (this.programCommentarySubscription) {
      this.programCommentarySubscription.unsubscribe();
    }

    if (this.getUrgenceProfileSubscription) {
      this.getUrgenceProfileSubscription.unsubscribe();
    }

    if (this.availableNursesSubscription) {
      this.availableNursesSubscription.unsubscribe();
    }

    if (this.getHospitalRoomsSubscription) {
      this.getHospitalRoomsSubscription.unsubscribe();
    }

    if (this.realProgramSubscription) {
      this.realProgramSubscription.unsubscribe();
    }

    if (this.doctorAgendasProgramSubscription) {
      this.doctorAgendasProgramSubscription.unsubscribe();
    }

    if (this.nursesSubscription) {
      this.nursesSubscription.unsubscribe();
    }
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
    if (this.roomsSubscription) {
      this.roomsSubscription.unsubscribe();
    }
    if (this.insertSubscription) {
      this.insertSubscription.unsubscribe();
    }
    if (this.dialogSub) {
      this.dialogSub.unsubscribe();
    }
    if (this.subsScroll) {
      this.subsScroll.unsubscribe();
    }
    if (this.updateBufferProgramSubscription) {
      this.updateBufferProgramSubscription.unsubscribe();
    }
    if (this.deleteProgramSubscription) {
      this.deleteProgramSubscription.unsubscribe();
    }
    if (this.anesthetistsSubscription) {
      this.anesthetistsSubscription.unsubscribe();
    }

    if (this.getRelatedDataSubscription) {
      this.getRelatedDataSubscription.unsubscribe();
    }
  }

  @bound
  private cancelScroll() {
    if (this.animationFrame) {
      cancelAnimationFrame(this.animationFrame);
      this.animationFrame = undefined;
    }
  }

  private scroll($event: CdkDragMove) {
    const { y } = $event.pointerPosition;
    const baseEl = this.scrollEl.nativeElement;
    const box = baseEl.getBoundingClientRect();
    const scrollTop = baseEl.scrollTop;
    const top = box.top + -y;
    if (top > 0 && scrollTop !== 0) {
      const newScroll = scrollTop - speed * Math.exp(top / 50);
      baseEl.scrollTop = newScroll;
      this.animationFrame = requestAnimationFrame(() => this.scroll($event));
      return;
    }

    const bottom = y - box.bottom;
    if (bottom > 0 && scrollTop < box.bottom) {
      const newScroll = scrollTop + speed * Math.exp(bottom / 50);
      baseEl.scrollTop = newScroll;
      this.animationFrame = requestAnimationFrame(() => this.scroll($event));
    }
  }
}

export function bound(target: object, propKey: string | symbol) {
  // tslint:disable-next-line: ban-types
  const originalMethod = (target as any)[propKey] as Function;

  if (typeof originalMethod !== "function") {
    throw new TypeError("@bound can only be used on methods.");
  }

  if (typeof target === "function") {
    return {
      value() {
        return originalMethod.apply(target, arguments);
      },
    };
  } else if (typeof target === "object") {
    return {
      get() {
        const instance = this;
        Object.defineProperty(instance, propKey.toString(), {
          value() {
            return originalMethod.apply(instance, arguments);
          },
        });
        return instance[propKey];
      },
    } as PropertyDescriptor;
  }
}
