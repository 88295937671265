import {Component, Inject, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Profile} from '../../models/profile.model';
import {Subject, Subscription} from 'rxjs';
import {ProfileService} from '../../services/profile.service';
import {ErrorService} from '../../services/error.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DAYS} from '../../const/days.const';
import {INTERVENTION_END_TIME, INTERVENTION_START_TIME, SURGEON} from '../../const/glabals.const';
import { DoctorAgenda } from '../../models/doctor-agenda.model';
import { UserService } from '../../services/user.service';
import { SpecialtyService } from '../../services/specialty.service';
import * as moment from 'moment';
import { getFirstHospitalSelectedData, getSelectedHospitalData, sortRoomsByPriority, toPascalCase } from '../../utils/cross-functions';
import { RoomService } from '../../services/room.service';
import { Room } from '../../models/room.model';
import { VacationService } from '../../services/vacation.service';
import { HrsuiteService } from 'src/app/hrsuite/hrsuite.service';
import { BufferProgramService } from '../../services/buffer-program.service';
import { PopupManagerService } from '../../services/popup-manager.service';
import { DatePipe } from '@angular/common';
import { ParamedicalService } from '../../services/paramedical.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-add-vacation-popup',
  templateUrl: './add-vacation-popup.component.html',
  styleUrls: ['./add-vacation-popup.component.scss']
})
export class AddVacationPopupComponent implements OnInit {
  public openType: string;
  public doctorAgenda;
  public startTime;
  public endTime;
  public nurseCirculante;
  public nurseAideOp;
  public nurseAideSoignante;
  public toPascalCase = toPascalCase;
  public SURGEON:string = SURGEON;
  public formGroup: FormGroup;
  public formRadio: FormGroup;
  public searchResults: Profile[] = [];
  surgeon: Profile;
  profileSelected: boolean = false;
  private searchSpecSurgeonByNameSubscription: Subscription;
  private getProfilesBySpecSubscription: Subscription;
  private getHospitalRoomsDaysSurgeonOpeningsSubscription: Subscription;
  private getActiveVacationRequestSubscription: Subscription;
  private getRolesSubscription: Subscription;
  private getAllSpecialtiesFromHospitalSubscription: Subscription;
  private getRoomsSubscription: Subscription;

  vacationPeriod = [ 
                    { label: 'Journée', value: 'day'},
                    { label:'Matin', value: 'morning'}, 
                    { label:'Après-midi', value: 'afternoon'}
                  ];


  roles: any[] = [];
  isLoading: boolean = false;
  public isFocused: boolean = false;
  public isTimeAvailable: boolean = true;
  public isVacationModes: boolean = false;
  hasVacationSlots: boolean = false;
  days = DAYS;
  specialties: any[];
  vacationRequests: any;
  public date: moment.Moment;
  private rooms : any;
  public isspecialtiestoggle: boolean = false;
  public loadSpecialties: boolean = false;
  private currentRoomData: any;
  toggledPresentOnly : boolean;
  public surgeonProfiles: Profile[] = [];
  public surgeonsLoading: boolean = false;
  private specialtySurgeonsData: any[] = [];
  private gotData: boolean = false;
  public isMultiHospitals: boolean = false;
  public needs: any = []

  public isTVO: boolean = false;
  public selectedSpecialties = [];
  public vacationModes = [
    {
      label: 'Choisir un praticien',
      value: 'choosePracticioner'
    },
    {
      label: 'Diffuser une vacation',
      value: 'diffuseVacation'
    }
  ];
  public actifVacationMode: string = 'choosePracticioner';

  private roomHospitalId: string = ""

  // true: ok, false: not ok
  get checkHospitalLimits(){
    if (this.formGroup && this.formGroup.value) {
      const day = this.data.day;
      const hospitalId = this.data.hostRoom.room.hospital._id ? this.data.hostRoom.room.hospital._id : this.data.hostRoom.room.hospital;
      const hospital = getSelectedHospitalData(this.userService.getCurrentUser(), hospitalId);
      if (!hospital)
        return false;

      let startTime, endTime;

      startTime = this.formGroup.value.startTime;
      endTime = this.formGroup.value.endTime;

      startTime = Number(startTime.split(':')[0]) + (Number(startTime.split(':')[1]) / 60);
      endTime = Number(endTime.split(':')[0]) + (Number(endTime.split(':')[1]) / 60);
      
      let hospitalStartTime, hospitalEndTime;

      hospitalStartTime = new Date(hospital.startTime);
      hospitalEndTime = new Date(hospital.endTime);
      
      hospitalStartTime = hospitalStartTime.getUTCHours() + (hospitalStartTime.getUTCMinutes() / 60);
      hospitalEndTime = hospitalEndTime.getUTCHours() + (hospitalEndTime.getUTCMinutes() / 60);

      let hourDay;
      if (this.data.hostRoom)
        hourDay = this.data.hostRoom.room.horaire.find( dayHours=> dayHours.day === day);

      if (!hourDay)
        return false;

      let roomStartTime, roomEndTime;

      roomStartTime = new Date(hourDay.start);
      roomEndTime = new Date(hourDay.end);

      roomStartTime = roomStartTime.getUTCHours() + (roomStartTime.getUTCMinutes() / 60);
      roomEndTime = roomEndTime.getUTCHours() + (roomEndTime.getUTCMinutes() / 60);
      
      return startTime >= hospitalStartTime && startTime >= roomStartTime && endTime <= hospitalEndTime && endTime <= roomEndTime;
    }
    return false;
  }

  get selectedPeriodOK(): boolean{
    if (this.formGroup && this.formGroup.value) {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      const selectedDate = new Date(this.formGroup.value.date);
      tomorrow.setHours(0,0,0,0);
      selectedDate.setHours(0,0,0,0);
      if (selectedDate.getTime() < tomorrow.getTime()) {
        return false;
      }
      const startTime = this.formGroup.value.startTime;
      const endTime = this.formGroup.value.endTime;
      return (
        Number(startTime.split(':')[0]) < Number(endTime.split(':')[0])
      );
    }
    return false;
  }

  updateIsTimeAvailableVariable(): void{
    if (this.formGroup && this.formGroup.value) {
      const startTime = this.formGroup.value.startTime;
      const endTime = this.formGroup.value.endTime;
      const mStartTime = new Date(this.data.date);
      const mEndTime = new Date(this.data.date);
      
      mStartTime.setUTCHours(startTime.split(':')[0]);
      mStartTime.setUTCMinutes(startTime.split(':')[1]);
      mStartTime.setUTCSeconds(0);
      mStartTime.setUTCMilliseconds(0);
      mEndTime.setUTCHours(endTime.split(':')[0]);
      mEndTime.setUTCMinutes(endTime.split(':')[1]);
      mEndTime.setUTCSeconds(0);
      mEndTime.setUTCMilliseconds(0);
      setTimeout(() => {
        this.isTimeAvailable = this.checkIfRoomHoursAvailable(mStartTime,mEndTime) && 
        this.selectedPeriodOK && this.checkHospitalLimits;
      })
    }
  }

  constructor(
    private formBuilder: FormBuilder, 
    private profileService: ProfileService,
    private errorService: ErrorService,
    private userService: UserService,
    private specialtyService: SpecialtyService,
    private roomsService: RoomService,
    private vacationService: VacationService,
    private hrsuiteService: HrsuiteService,
    public dialogRef: MatDialogRef<AddVacationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private bufferProgramService: BufferProgramService,
    private popupManagerService: PopupManagerService,
    private datePipe: DatePipe,
    private paramedicalService: ParamedicalService
  ) {
    this.isTVO = this.data.isTVO;
    this.rooms = data.rooms;
    this.date = moment(data.date).clone().utc();
    this.isVacationModes = data.isVacationModes
    const room = this.rooms.find((room) => String(room.room._id) === String(this.data.roomId))
    this.roomHospitalId = room.room.hospital._id ? room.room.hospital._id : room.room.hospital ? room.room.hospital : undefined
    this.isMultiHospitals = this.userService.isCurrentUserHasMultipleHospitalsSelected()
    this.openType = this.data.openType;
    this.doctorAgenda = this.data.doctorAgenda;
  }

  getEndTime(): string {
    let hourDay = { end : null}, periodSelected;
    const day = this.data.day;

    if(this.formRadio && this.formRadio.get('vacationPeriod')) {
      let period = this.formRadio.get('vacationPeriod').value === 'day' ? 'afternoon' : this.formRadio.get('vacationPeriod').value;
      periodSelected = period + 'End';
    }

    if(this.data.hostRoom && !this.hasVacationSlots ) {
      hourDay = this.data.hostRoom.room.horaire.find(dayHours => dayHours.day === day);
    }
    else if(this.hasVacationSlots) {
      const hospital = getSelectedHospitalData(this.userService.getCurrentUser(), this.roomHospitalId);
      if(hospital.settings && hospital.settings.VACATIONS_SLOTS && hospital.settings.VACATIONS_SLOTS[periodSelected]) {
        hourDay.end = hospital.settings.VACATIONS_SLOTS[periodSelected];
      }
    }

    if(!hourDay) {
      return INTERVENTION_END_TIME;
    }

    const roomEndTime = new Date(hourDay.end);
    return `${roomEndTime.getUTCHours()}:${roomEndTime.getUTCMinutes()}`;
  }

  changeSurgeon() {
    this.isFocused = !this.isFocused;
  }

  getVacationPeriod(): string {
    if(this.formGroup && this.formGroup.get('vacationPeriod')) {
      return this.formGroup.get('vacationPeriod').value;
    }
    return 'morning';
  }

  getStartTime(): string {
    let hourDay = { start : null}, periodSelected;
    const day = this.data.day;

    if(this.formRadio && this.formRadio.get('vacationPeriod')) {
      let period = this.formRadio.get('vacationPeriod').value === 'day' ? 'morning' : this.formRadio.get('vacationPeriod').value;
      periodSelected = period + 'Start';
    }

    if(this.data.hostRoom && !this.hasVacationSlots) {
      hourDay = this.data.hostRoom.room.horaire.find(dayHours => dayHours.day === day);
    }
    else if(this.hasVacationSlots) {
      const hospital = getSelectedHospitalData(this.userService.getCurrentUser(), this.roomHospitalId);
      if(hospital.settings && hospital.settings.VACATIONS_SLOTS && hospital.settings.VACATIONS_SLOTS[periodSelected]) {
        hourDay.start = hospital.settings.VACATIONS_SLOTS[periodSelected];
      }
    }

    if(!hourDay) {
      return INTERVENTION_START_TIME;
    }

    const roomStartTime = new Date(hourDay.start);
    return `${roomStartTime.getUTCHours()}:${roomStartTime.getUTCMinutes()}`;
  }
  

  setStartTime(event): void {
    const time = this.convertTimeTo24Format(event);
    this.formGroup.controls.startTime.setValue(time);
    this.updateIsTimeAvailableVariable();
  }

  setEndTime(event): void {
    const time = this.convertTimeTo24Format(event);
    this.formGroup.controls.endTime.setValue(time);
    this.updateIsTimeAvailableVariable();
  }

  changeVacationPeriod(event) {
    this.formRadio.setValue({ vacationPeriod: event});
  }

  convertTimeTo24Format(time: string): string {
    // time is one of the following formats : '00:00' or '00:00 AM' or '00:00 PM'
    if (time.includes('M')) {
      // time is 12 format
      let hours = time.split(':')[0];
      let minutes = time.split(':')[1].split(' ')[0];
      if (time.includes('A')) {
        // time is AM
        if (hours === '12') {
          hours = '00';
        }
      } else {
        // time is PM
        if (hours !== '12') {
          hours = `${+hours + 12}`;
        }
      }
      return `${hours}:${minutes}`
    } else {
      // time is 24 format
      return time;
    }
  }

  async ngOnInit() {
    this.getSpecialties();
    await this.getRoles();
    const room = this.rooms.find((room) => String(room.room._id) === String(this.data.roomId))
    this.roomHospitalId = room.room.hospital._id ? room.room.hospital._id : room.room.hospital ? room.room.hospital : undefined
    if(this.roomHospitalId) {
      const hospital = getSelectedHospitalData(this.userService.getCurrentUser(), this.roomHospitalId);
      if(hospital.settings && hospital.settings.VACATIONS_SLOTS) {
        this.hasVacationSlots = true;
        // create the radio button form
        this.formRadio = this.formBuilder.group({
          vacationPeriod: ['day', Validators.required]
        })
      }
    }
    await this.makeForm();

    if (this.openType === 'EDIT'){
      await this.profileChange(this.doctorAgenda.surgeon);
      this.needs = this.doctorAgenda.needs;
      this.startTime = this.formatTime(this.doctorAgenda.startTime);
      this.endTime = this.formatTime(this.doctorAgenda.endTime);
      this.formGroup.get('startTime').patchValue(this.startTime);
      this.formGroup.get('endTime').patchValue(this.endTime);
    }
    await this.setMissingRoles()
    this.updateIsTimeAvailableVariable();
  }

  setMissingRoles() {
    return new Promise<void>((resolve, reject) => {
      this.roles.forEach(role => {
        const roleExists = this.needs.some(need => 
          need.role && String(need.role._id) === String(role._id)
        );
        
        if (!roleExists) {
          this.needs.push({ need: 0, role });
        }
        this.needs = this.needs.filter(need => Object.keys(need).length > 0);
        
      });
      resolve();
    })
  }

  getRoles() {
    return new Promise<void>((resolve, reject) => {
      if (this.getRolesSubscription) {
        this.getRolesSubscription.unsubscribe();
      }

      this.getRolesSubscription = this.paramedicalService.getRoles().subscribe((res) => {
        this.roles = res.filter((role) => role.name !== "IADE");
        resolve();
      }, (error) => {
        this.errorService.handleError(error)
        reject();
      })
    })
  }

  formatTime(dateString) {
    const date = new Date(dateString);
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    return formattedTime;
  }

  getSpecialtyNeeds() {
    return new Promise((resolve, reject) => {
      if (this.profileSelected) {
        if (this.surgeon.surgeonNeeds &&
          this.surgeon.surgeonNeeds.length > 0 &&
          Object.keys(this.surgeon.surgeonNeeds[0]).length > 0) {
            const hospitalId = this.data.hostRoom.room.hospital._id ? this.data.hostRoom.room.hospital._id : this.data.hostRoom.room.hospital
            const needs = JSON.parse(JSON.stringify(this.surgeon.surgeonNeeds.filter((need) => String(need.role.hospital) === String(hospitalId) && String(need.role.name) !== "IADE")))
            resolve(needs);
        } else {
          const specialty = this.surgeon.specialties.find((specialty) => String(specialty.hospital) === String(this.roomHospitalId))
          resolve(specialty && specialty.needs ? JSON.parse(JSON.stringify(specialty.needs.filter((need) => String(need.role.name) !== "IADE"))) : []);
        }
      }
      resolve([])
    })
  }

  async makeForm() {
    if (this.actifVacationMode === "choosePracticioner") {
      this.needs = await this.getSpecialtyNeeds();
      this.formGroup = this.formBuilder.group({
        startTime: [this.getStartTime(), [Validators.required, Validators.minLength(2)]],
        endTime: [this.getEndTime(), [Validators.required, Validators.minLength(2)]],
      });
    } else {
      await this.getSpecialties();
      if (this.surgeonProfiles.length > 0 && this.surgeonProfiles.some(profile => profile.checked)) {
        this.profileSelected = true;
      }
      this.formGroup = this.formBuilder.group({
        date: [{value: this.date.format(), disabled: false}, [Validators.required]],
        selectedRoom: [this.data.roomId, [Validators.required]],
        startTime: [this.getStartTime(), [Validators.required, Validators.minLength(2)]],
        endTime: [this.getEndTime(), [Validators.required, Validators.minLength(2)]],
        specialties: this.formBuilder.array([]),   
        selectAllProfiles: [true]
      });
      if (!this.gotData) {
        this.getSurgeons();
      }
      this.setSurgeonProfiles();
      this.updateIsTimeAvailableVariable();
    }
  }

  toggleAllSpecialties(checked: boolean): void {
    this.isspecialtiestoggle = checked;
    this.selectedSpecialties = [];
    if (checked) {
      this.selectedSpecialties = this.specialties.map(specialty => specialty._id);
    } else {
      this.profileSelected = false;
    }
    this.setSurgeonProfiles();
    this.updateIsTimeAvailableVariable();
  }

  onProfileCheckedChange(checked: boolean , profile:any) {
      if (checked) {
        this.profileSelected = true;
      } else {
        const otherProfiles = this.surgeonProfiles.filter(p => p !== profile);
        this.profileSelected = otherProfiles.some(p => p.checked);
      } 
    }
  
  getIsSurgeonNeeds(): boolean {
    if (this.profileSelected) {
      if (this.surgeon.surgeonNeeds) {
        return true;
      } else {
        return false;
      }
    }
  }

  async profileChange(profile: Profile) {
    this.surgeon = profile;
    this.isFocused = false;
    this.profileSelected = true;
    this.needs = await this.getSpecialtyNeeds();
    await this.setMissingRoles();

    if (this.openType !== 'EDIT'){
      await this.makeForm();
      await this.setMissingRoles();
    }
  }
  submitProfile() {
    const data: any = {};
    let isRoomHoursAvailable: boolean = false;
    if (this.formGroup.valid ) {
      const startTime = this.formGroup.value.startTime;
      const endTime = this.formGroup.value.endTime;
      const mStartTime = new Date(this.data.date);
      const mEndTime = new Date(this.data.date);
      mStartTime.setUTCHours(startTime.split(':')[0]);
      mStartTime.setUTCMinutes(startTime.split(':')[1]);
      mStartTime.setUTCSeconds(0);
      mStartTime.setUTCMilliseconds(0);
      mEndTime.setUTCHours(endTime.split(':')[0]);
      mEndTime.setUTCMinutes(endTime.split(':')[1]);
      mEndTime.setUTCSeconds(0);
      mEndTime.setUTCMilliseconds(0);
      isRoomHoursAvailable = this.checkIfRoomHoursAvailable(mStartTime, mEndTime);

      if (this.actifVacationMode === "choosePracticioner") {
        data.startTime = mStartTime.toISOString();
        data.endTime = mEndTime.toISOString();
        data._id = Date.now().toString();
        data.day = this.data.day;
        data.isModified = true;
        data.opening = true;
        data.room = this.data.roomId;
        data.surgeon = (this.surgeon) ? this.surgeon : null,
        data.notify = this.data.notifyAnesth;
        data.needs = this.needs.filter((need) => {
          if (parseInt(need.need) > 0) {
            return {
              role: need.role,
              need: need.need
            }
          }
        } 
        )
        data.hospital = this.data.hostRoom.room.hospital._id ? this.data.hostRoom.room.hospital._id : this.data.hostRoom.room.hospital;
      } else {
        const individuals = [];
        this.surgeonProfiles.forEach(profiles => {
          if (profiles.checked) {
            individuals.push(profiles._id);
          }
          if (!profiles.haveUser) {
            profiles.checked = false;
          } else {
            profiles.checked = true;
          } 
        });
        data.vacation = {
          hospital: this.currentRoomData ? this.currentRoomData.room.hospital._id : this.data.hostRoom.room.hospital._id,
          needs: 1,
          voluntaries: [],
          chosenOnes: [],
          dateofcreation: moment().format("YYYY-MM-DD"),
          period: 'Journée',
          date: moment(this.formGroup.value.date).format("YYYY-MM-DD"),
          residency: 'Titulaire et Vacataire',
          state: "OPENED",
          position: "CHIRURGIEN",
          seniority: undefined,
          individuals,
          informations: {
            specialties: this.formGroup.value.specialties,
            room: this.formGroup.value.selectedRoom,
            type: undefined,
            startTime: mStartTime.toISOString(),
            endTime: mEndTime.toISOString()
          }
        }
        data.message = {
          text: "(Vacation supprimée)",
        }
      }
      data.vacationMode = this.actifVacationMode;
    }
    this.hrsuiteService.isChangeAnesthNeedsSubject.next(true)
    this.dialogRef.close( isRoomHoursAvailable ? data : {isError : true});
  }

  constructEditedVacation(): any {
    const data: any = {};
    let isRoomHoursAvailable: boolean = false;
    const startTime = this.formGroup.value.startTime;
    const endTime = this.formGroup.value.endTime;
    const mStartTime = new Date(this.data.date);
    const mEndTime = new Date(this.data.date);
    mStartTime.setUTCHours(startTime.split(':')[0]);
    mStartTime.setUTCMinutes(startTime.split(':')[1]);
    mStartTime.setUTCSeconds(0);
    mStartTime.setUTCMilliseconds(0);
    mEndTime.setUTCHours(endTime.split(':')[0]);
    mEndTime.setUTCMinutes(endTime.split(':')[1]);
    mEndTime.setUTCSeconds(0);
    mEndTime.setUTCMilliseconds(0);
    isRoomHoursAvailable = this.checkIfRoomHoursAvailable(mStartTime, mEndTime);
    data.startTime = mStartTime.toISOString();
    data.endTime = mEndTime.toISOString();
    data._id = Date.now().toString();
    data.day = this.data.day;
    data.isModified = true;
    data.isEdited = isNaN(new Date(Number(this.doctorAgenda._id)).getTime()) ? false : true;
    data.opening = true;
    data.room = this.data.roomId;
    data.surgeon = this.surgeon,
    data.notify = this.data.notifyAnesth;
    data.surgeonOpeningId = this.doctorAgenda._id;
    data.needs = this.needs.filter((need) => {
      if (parseInt(need.need) > 0) {
        return {
          role: need.role,
          need: need.need
        }
      }
    })
    data.vacationMode = this.actifVacationMode;
    data.hospital = this.data.hostRoom.room.hospital._id;

    return { data, isRoomHoursAvailable };
  }

  editProfile() {
    if (this.formGroup.valid ) {
      if (this.isTVO) {
        const constructedVacation = this.constructEditedVacation();
        this.dialogRef.close( constructedVacation.isRoomHoursAvailable ? constructedVacation.data : {isError : true});
      } else {
        if (String(this.doctorAgenda.surgeon._id) === String(this.surgeon._id)) {
          // If same surgeon
          // There is no buffer programs related to the previous surgeon
          const constructedVacation = this.constructEditedVacation();
  
          this.hrsuiteService.isChangeAnesthNeedsSubject.next(true)
          this.dialogRef.close( constructedVacation.isRoomHoursAvailable ? constructedVacation.data : {isError : true});
        } else {
          // We edited the surgoen
          // Verify if we have programs attached to previous surgeon
          if(this.getRoomsSubscription) this.getRoomsSubscription.unsubscribe();
          this.getRoomsSubscription = this.bufferProgramService
            .getRoomPrograms(this.data.date.substring(0, 10), this.data.roomId)
            .subscribe((res) => {
              if (res.existPrograms) {
                // We have a buffer program that is linked to the previous surgeon
                var description = "";
                const programs = res.programs;
                if (res.existPrograms) {
                  description = `<p>Cette vacation est déjà associée à un programme le ${this.datePipe.transform(this.data.date.substring(0, 10), 'dd/MM/yyyy')}</p><p>Êtes vous sûr(e) de vouloir éditer cette vacation ?</p>`;
                }
                this.popupManagerService.openConfirmationPopup('Editer cette vacation ?', description, 370, 'danger', 'Oui, éditer').afterClosed()
                  .subscribe(res => {
                    if (res) {
                      // The user confirmed the changement
                      const constructedVacation = this.constructEditedVacation();
                      constructedVacation.data.programsToSave = programs;
  
                      this.hrsuiteService.isChangeAnesthNeedsSubject.next(true)
                      this.dialogRef.close( constructedVacation.isRoomHoursAvailable ? constructedVacation.data : {isError : true});
                    }
                  });
              } else {
                // There is no buffer programs related to the previous surgeon
                const constructedVacation = this.constructEditedVacation();
  
                this.hrsuiteService.isChangeAnesthNeedsSubject.next(true)
                this.dialogRef.close( constructedVacation.isRoomHoursAvailable ? constructedVacation.data : {isError : true});
              }
          });
        }
      }
    }
  }
  
  checkIfRoomHoursAvailable(startTime:Date, endTime:Date):boolean{
    let endhour = this.getFormatLTS(endTime);
    let starthour = this.getFormatLTS(startTime);
    let agenda;

    if (this.actifVacationMode === "choosePracticioner") {
      agenda = 
        this.data.hostRoom.surgeonopening && this.data.hostRoom.surgeonopening.length > 0
        ? this.data.hostRoom.surgeonopening.filter((agenda: DoctorAgenda)=> agenda.opening)
        : this.data.hostRoom.surgeonopeninghistory;
    } else {
        if (this.currentRoomData) {
        agenda = this.currentRoomData.surgeonopening && this.currentRoomData.surgeonopening.length > 0
        ? this.currentRoomData.surgeonopening.filter((agenda: DoctorAgenda)=> agenda.opening)
        : this.currentRoomData.surgeonopeninghistory;
      } else {
        agenda = 
        this.data.hostRoom.surgeonopening && this.data.hostRoom.surgeonopening.length > 0
        ? this.data.hostRoom.surgeonopening.filter((agenda: DoctorAgenda)=> agenda.opening)
        : this.data.hostRoom.surgeonopeninghistory;
      }
    }

    if (this.vacationRequests && this.vacationRequests.length > 0) {
      for (const vacationRequest of this.vacationRequests) {
        const date = new Date(this.formGroup.value.date).getTime()
        const vDate = new Date(vacationRequest.date).getTime()

        const room = this.formGroup.value.selectedRoom
        const vRoom = vacationRequest.informations.room._id

        const hostStartTime:string = this.getFormatLTS(vacationRequest.informations.startTime);
        const hostEndTime:string = this.getFormatLTS(vacationRequest.informations.endTime);

        if (date === vDate && room == vRoom) {
          if (starthour >= hostStartTime && endhour <= hostEndTime) {
            return false;
          }
        }
      };
    }

    for (let index = 0; index < agenda.length; index++) {
      const hostAgenda = agenda[index];
      const hostStartTime:string = this.getFormatLTS(hostAgenda.startTime);
      const hostEndTime:string = this.getFormatLTS(hostAgenda.endTime);
      if( 
          (starthour < hostStartTime && hostStartTime < endhour) ||
          (starthour < hostEndTime && hostEndTime < endhour) ||
          (hostStartTime < starthour && starthour < hostEndTime) ||
          (hostStartTime < endhour && endhour < hostEndTime) ||
          (starthour === hostStartTime && endhour === hostEndTime) 
      ){
        if (this.openType !== 'EDIT' || hostAgenda._id !== this.doctorAgenda._id){
        return false;}
      }
    }
    return true;
  }

  getFormatLTS(dateTime : string | Date): string{
    const date: Date = new Date(dateTime);
    let hours = date.getUTCHours() < 10 ? '0'+ date.getUTCHours() : date.getUTCHours();
    let minutes = date.getUTCMinutes() < 10 ? '0'+ date.getUTCMinutes() : date.getUTCMinutes();
    return hours + ':' + minutes
  }

  close(): void {
    this.dialogRef.close();
  }

  async toggleClick(event): Promise<void> {
    this.actifVacationMode = event
    this.profileSelected = false;
    this.surgeon = null;
    await this.makeForm()
    await this.setMissingRoles();
    await this.getActiveVacationRequests();
  }

  getSpecialties() {
    if(this.getAllSpecialtiesFromHospitalSubscription) this.getAllSpecialtiesFromHospitalSubscription.unsubscribe();
    return new Promise<void>((resolve, reject) => {
      this.getAllSpecialtiesFromHospitalSubscription = this.specialtyService.getAllSpecialtiesFromHospital(true, this.roomHospitalId)
      .subscribe((results) => {
        this.specialties = results  
        this.specialties = this.specialties.filter(e => e.name != "Extraclinique" && e.name != "Urgences" && e.name != "Anesthésie-réanimation")
        this.sortSpecialties();
        this.loadSpecialties = true;
        resolve();
      }, error => {
        reject();
        this.errorService.handleError(error)
      });
    })
  }
  async getActiveVacationRequests(): Promise<void> {
    const startDate: string = moment(this.formGroup.value.date).format("YYYY-MM-DD");
    const endDate: string = moment(this.formGroup.value.date).format("YYYY-MM-DD");

    if(this.getActiveVacationRequestSubscription){
      this.getActiveVacationRequestSubscription.unsubscribe();
    }
    await new Promise<void>((resolve, reject) => { 
      if(this.getActiveVacationRequestSubscription) this.getActiveVacationRequestSubscription.unsubscribe();
      this.getActiveVacationRequestSubscription = this.vacationService
      .getOpenVacationsFromTo(startDate, endDate)
      .subscribe((res) => {
        this.vacationRequests = res;
        resolve();
      },
      (error) => {
        this.errorService.handleError(error);
        reject(error);
      })
    })
  }

  sortSpecialties(): void {
    this.specialties = this.specialties.sort((a, b) => a.priority - b.priority);
  }

  toggledPresentOnlyRow(): void {
    this.toggledPresentOnly = !this.toggledPresentOnly;
    if (!this.gotData) {
      this.getSurgeons();
    }
    this.setSurgeonProfiles();
  }

  selectAllSurgeons(event): void {
    if (event.checked) {
      this.surgeonProfiles.forEach(surgeon => {
        if (!surgeon.haveUser) {
          surgeon.checked = false;
        } else {
          surgeon.checked = true;
          this.profileSelected = true;
        } 
      });
    } else {
      this.surgeonProfiles.forEach(surgeon => {        
        if (!surgeon.haveUser) {
          surgeon.checked = false;
        } else {
          surgeon.checked = false;
        } 
        this.profileSelected = false;
      });
    }
  }

  getSurgeons(): void {
    if (this.getProfilesBySpecSubscription) {
      this.getProfilesBySpecSubscription.unsubscribe();
    }
    if (this.formGroup.valid) {
      this.surgeonsLoading = true;
      const date = this.formGroup.value.date;
      const room = this.rooms.find((room) => String(room.room._id) === String(this.data.roomId))
      this.getProfilesBySpecSubscription = this.profileService.getAllSurgeonsOnlyUser(
        room.room.hospital._id,
        date
      )
      .subscribe(
        (res) => {
          this.specialtySurgeonsData = res;
          this.setSurgeonProfiles();
          
          this.surgeonProfiles.forEach(surgeon => {
            if (!surgeon.haveUser) {
              surgeon.checked = false;
            } else {
              surgeon.checked = true;
            } 
          });
          this.surgeonProfiles.sort((a, b) => a.lastName.localeCompare(b.lastName));
          this.surgeonsLoading = false;
          this.gotData = true;
        }, err => {
          this.errorService.handleError(err)
          this.surgeonsLoading = false;
        }
      )
    }
  }

  setSurgeonProfiles(): void {
    this.surgeonsLoading = true;
    this.surgeonProfiles = [];
    this.selectedSpecialties.forEach(specialtyId => {
      const specialty = this.specialtySurgeonsData.find(specialty => specialty._id === specialtyId);
  
      if (specialty && specialty.surgeons) {
        // Filtrer les chirurgiens en fonction de la présence si nécessaire
        const surgeons = this.toggledPresentOnly ? specialty.surgeons.filter(surgeon => surgeon.present) : specialty.surgeons;
  
        // Ajouter les chirurgiens à la liste des profils de chirurgiens, en évitant les doublons
        surgeons.forEach(surgeon => {
          if (!this.surgeonProfiles.some(profile => profile._id === surgeon._id)) {
            this.surgeonProfiles.push(surgeon);
          }
        });
      }
    });    
  // Cocher tous les profils de chirurgiens
  if (this.surgeonProfiles.length > 0) {
    this.surgeonProfiles.forEach(surgeon => {
      if (!surgeon.haveUser) {
        surgeon.checked = false;
      } else {
        surgeon.checked = true;
        this.profileSelected = true;
      } 
    });
  }
  this.surgeonProfiles.length === 0 ? this.profileSelected = false : null;

  this.surgeonProfiles.sort((a, b) => a.lastName.localeCompare(b.lastName));
  this.surgeonsLoading = false;
  }

  changedSpecialty(event :any, specialtyId): void {
    let specialtiesArray = this.formGroup.get('specialties') as FormArray;
    const index = specialtiesArray.controls.findIndex(control => control.value === specialtyId);
  
    if (index === -1) {
      specialtiesArray.push(this.formBuilder.control(specialtyId));
      this.selectedSpecialties.push(specialtyId);
    } else {
      specialtiesArray.removeAt(index);
      this.selectedSpecialties = this.selectedSpecialties.filter(id => id !== specialtyId);
    }
    if (!this.gotData) {
      this.getSurgeons();
    }
    this.setSurgeonProfiles();
    this.updateIsTimeAvailableVariable();
  }

  async changedRoom(): Promise<void> {
    await this.setCurrentRoomData();
  }

  async setCurrentRoomData(): Promise<void> {
    this.currentRoomData = this.rooms.find((e) => {
      if (e.room._id === this.formGroup.value.selectedRoom) {
        return e;
      }
    })
    this.roomHospitalId = this.currentRoomData.room.hospital._id
    await this.getSpecialties();
    this.updateIsTimeAvailableVariable();
  }

  async dateChanged(): Promise<void> {
    if (this.getHospitalRoomsDaysSurgeonOpeningsSubscription) {
      this.getHospitalRoomsDaysSurgeonOpeningsSubscription.unsubscribe();
    }
    await new Promise<void>((resolve, reject) => {
      this.getHospitalRoomsDaysSurgeonOpeningsSubscription = this.roomsService
        .getHospitalRoomsDaysSurgeonOpenings(this.formGroup.value.date)
        .subscribe(
          async (rooms) => {
            this.rooms = rooms;
            sortRoomsByPriority(this.rooms, false)
            await this.setCurrentRoomData();
            resolve();
          },
          (error) => {
            this.errorService.handleError(error);
            reject(error);
          }
        );
    });
    this.gotData = false;
    await this.getActiveVacationRequests();
    this.getSurgeons()
  }

  ngOnDestroy() {
    if(this.getAllSpecialtiesFromHospitalSubscription)
      this.getAllSpecialtiesFromHospitalSubscription.unsubscribe();
    
    if(this.getRoomsSubscription)
      this.getRoomsSubscription.unsubscribe();

    if(this.searchSpecSurgeonByNameSubscription)
      this.searchSpecSurgeonByNameSubscription.unsubscribe();

    if(this.getProfilesBySpecSubscription)
      this.getProfilesBySpecSubscription.unsubscribe();

    if(this.getActiveVacationRequestSubscription)
      this.getActiveVacationRequestSubscription.unsubscribe();

    if(this.getRolesSubscription)
      this.getRolesSubscription.unsubscribe();

    if(this.getHospitalRoomsDaysSurgeonOpeningsSubscription) 
      this.getHospitalRoomsDaysSurgeonOpeningsSubscription.unsubscribe();
  }
}
