import {Injectable} from '@angular/core';
import {cycle} from '../models/cycle.model';
import {CycleDalService} from '../dal/cycle.dal.service';
import {cycleCalendar} from '../models/cycleCalendar.model';
import {Observable} from 'rxjs';
import {Calendar} from '../models/calendar.model';

@Injectable({
  providedIn: 'root'
})
export class CycleService {

  constructor(
    private cycleDAL: CycleDalService
  ) {
  }

  checkConflicts(fromDate: string, toDate: string, cycle: cycle): Observable<any> {
    return this.cycleDAL.checkConflicts(fromDate, toDate, cycle);
  }

  getEffectif(week: number, cycleId: number): Observable<any> {
    return this.cycleDAL.getEffectif(week, cycleId);
  }

  deleteCycle(cycle: cycle): Observable<any> {
    return this.cycleDAL.deleteCycle(cycle);
  }

  deleteCalendar(cycleCalendar: cycleCalendar): Observable<any> {
    return this.cycleDAL.deleteCalendar(cycleCalendar);
  }

  updateCycleCalendar(calendar: cycleCalendar): Observable<any> {
    return this.cycleDAL.updateCycleCalendar(calendar);
  }

  createCycleCalendar(calendar: cycleCalendar): Observable<any> {
    return this.cycleDAL.createCycleCalendar(calendar);
  }

  getCalendarsOfCycle(_id: string, cycle: cycle, weekNumbers: number): Observable<any> {
    return this.cycleDAL.getCalendarsOfCycle(_id, cycle, weekNumbers);
  }

  getCycles(position? : string): Observable<any> {
    return this.cycleDAL.getCycles(position);
  }

  generateCalendars(startDate: any, endDate: any, cycle: cycle, keepOldCalendars: boolean): Observable<any> {
    return this.cycleDAL.generateCycle(startDate, endDate, cycle, keepOldCalendars);
  }

  generateProfiledCalendars(startDate: any, endDate: any, cycle: cycle, keepOldCalendars: boolean, profileIds: string[], startingWeekNumber?: number): Observable<any> {
    return this.cycleDAL.generateProfiledCalendars(startDate, endDate, cycle, keepOldCalendars, profileIds, startingWeekNumber);
  }

  createCycle(cycle: cycle, cycleSourceId: string): Observable<any> {
    return this.cycleDAL.createCycle(cycle, cycleSourceId);
  }

  editCycle(cycle: cycle): Observable<any> {
    return this.cycleDAL.editCycle(cycle);
  }

  updatePeriodAvailabilityCycle(
    calendar: cycleCalendar,
    indexes: number[]
  ): Observable<any> {
    return this.cycleDAL.updatePeriodAvailabilityCycle(calendar,indexes);
  }

  deletePeriodCycle(
    calendar: cycleCalendar,
    indexes: number[],
  ): Observable<any> {
    return this.cycleDAL.deletePeriodCycle(calendar,indexes);
  }
}
