import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';

import {urlEndPoints} from '../config/end-points';

@Injectable()
export class VacationDal {

  constructor(private httpClient: HttpClient) {
  }

  addVacation(vacation): Observable<any>{
    return this.httpClient.post<any>(urlEndPoints.vacations, vacation);
  }

  getAllVacations(){
    return this.httpClient.get<any>(urlEndPoints.vacations);
  }

  getOpenVacations(){
    return this.httpClient.get<any>(urlEndPoints.vacations +"?state=OPENED");
  }

  getOpenVacationsFromTo(startDate?: string , endDate?: string, position?: string, page?: number){
    const query = '&fromDate=' + startDate + '&toDate=' + endDate + (page ? '&page=' + page : '');
    return this.httpClient.get<any>(urlEndPoints.vacations +"fromTo?state=OPENED" + (position ? '&position=' + position : '') + query);
  }

  getClosedVacations(){
    return this.httpClient.get<any>(urlEndPoints.vacations +"?state=CLOSED");
  }

  getClosedVacationsFromTo(startDate?: string , endDate?: string, position?: string, page?: number){
    const query = '&fromDate=' + startDate + '&toDate=' + endDate + (page ? '&page=' + page : '');
    return this.httpClient.get<any>(urlEndPoints.vacations +"fromTo?state=CLOSED" + (position ? '&position=' + position : '') + query);
  }

  updateVacation(vacation){
    return this.httpClient.put<any>(urlEndPoints.vacations + vacation._id, vacation);
  }

  updateVolunteers(vacation){
    return this.httpClient.put<any>(urlEndPoints.vacations + `${vacation._id}/voluntaries`, vacation);
  }

  getVacationRequestHistory(profileId, showOnHold: boolean = false, startDate?, endDate?){
    return this.httpClient.get<any>(`${urlEndPoints.vacations}profile/${profileId}/${!showOnHold}?fromDate=${startDate}&toDate=${endDate}`);
  }

  deleteVacation(vacationID){
    return this.httpClient.delete<any>(urlEndPoints.vacations + vacationID);
  }

}
