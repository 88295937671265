import { Component, Input, OnInit } from '@angular/core';
import {AddChannelPopupComponent} from "../shared/components/add-channel-popup/add-channel-popup.component";
import {DefaultPopupComponent} from "../shared/components/default-popup/default-popup.component";
import {MatDialog} from "@angular/material/dialog";
import {PopupManagerService} from "../shared/services/popup-manager.service";
import { setTimeout } from 'timers';

@Component({
  selector: 'app-theming',
  templateUrl: './theming.component.html',
  styleUrls: ['./theming.component.scss']
})
export class ThemingComponent implements OnInit {
  public isSpinning : Boolean = false;
  public isDisabled : Boolean = false;
  constructor(private matDialog: MatDialog, private popupManagerService: PopupManagerService) { }

    ngOnInit() {
    }

    onClick(e){
      // console.info(e)
    }

  openDangerPopup() {
      // this.popupManagerService.openConfirmationPopup('This isssss a danger popup', 'This is a danger popup description', 0).afterClosed().subscribe(res => {
      //   console.info(res)
      // })
  }

  openClosePopup() {
    this.popupManagerService.openInfoPopup('This is a close popup', 'This is a close popup description', 0).afterClosed().subscribe(res => {
    })
  }

  openDefaultPopup(){
    this.popupManagerService.openUnsavedChangesPopup('lalala').afterClosed().subscribe(res => {
    })
  }

  test() {
    this.isSpinning = true;
    this.isDisabled = true;
  }
}
