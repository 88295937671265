import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { NavigationService } from 'src/app/core/services/navigation.service';
import { pages } from '../../config/pages';
import { deactivateComponentService } from '../../services/desactivate.service';
import { UtilisService } from '../../services/utilis.service';
import { HrsuiteService } from 'src/app/hrsuite/hrsuite.service';

@Component({
  selector: 'app-commission-regulation-btton',
  templateUrl: './commission-regulation-btton.component.html',
  styleUrls: ['./commission-regulation-btton.component.scss']
})
export class CommissionRegulationBttonComponent implements OnInit {

  @Input() isThereNewModifs:boolean;
  @Output() saveAgendas=new EventEmitter<boolean>();
  commissionRegulationLink = pages.commissionRegulation
  popupSubscription: Subscription;

  constructor(
    private navigationServ : NavigationService,
    private deactivateService : deactivateComponentService,
    private utilisService : UtilisService,
    private hrsuiteService : HrsuiteService
  ) { }

  ngOnInit() {
  }

  navigate(){
    this.hrsuiteService.toggleNightSubject.next(false);
    this.popupSubscription = this.goToNextEvent().subscribe(res => {
      if (res && res !== "cancel") {
        this.navigationServ.navigateTo(this.commissionRegulationLink, null, null, false);
      } else if (res === false) {
        this.save()
      }
    })
  }

  save() {
    this.deactivateService.switchDeactivate.next(true);
    this.saveAgendas.emit(true);
  }

  goToNextEvent() {
    if (this.isThereNewModifs) return this.utilisService.openUnSavedChangesPopup()
    else return new Observable(sub => sub.next(true));
  }

}
