import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from "@sentry/angular";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    handleError(error: any): void {
        // const chunkFailedMessage = /Loading chunk .+ failed/;

        // // If we got error because of failing in importing a module, we simply reload the page
        // if (chunkFailedMessage.test(error.message)) {
        //     window.location.reload();
        // }

        // We print the error and we send it to sentry
        console.error(error);
        Sentry.captureException(error);
    }
}