import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { StorageService } from '../core/services/storage.service';
import { User } from '../shared/models/user.model';
import { BLOCK_MANAGER } from '../shared/const/positions.const';
import { ANESTHESIST_WITH_ACCENT, ANESTHETIST, COMMISSION_REGULATION, Iade, NURSE, OPPOSITE_ZOOM_VALUE, OPTIONS, SURGEON } from '../shared/const/glabals.const';
import { UserService } from 'src/app/shared/services/user.service';
import { NavigationEnd, Router } from '@angular/router';
import { pages } from 'src/app/shared/config/pages';
import { NavigationService } from '../core/services/navigation.service';
import { UtilisService } from '../shared/services/utilis.service';
import { deactivateComponentService } from '../shared/services/desactivate.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { HrsuiteService } from './hrsuite.service';
import { filter, takeUntil } from 'rxjs/operators';
import { MatDialog, MatMenuTrigger } from '@angular/material';
import { AddProfileDialogComponent } from '../shared/components/add-profile-dialog/add-profile-dialog.component';
import { addVacationHrsuitePopupComponent } from '../shared/components/add-vacation-hrsuite/add-vacation-hrsuite-popup.component';
import { RelaunchAvailabilityComponent } from '../shared/components/relaunch-availability/relaunch-availability.component';
import { CalendarRequestService } from '../shared/services/calendar-request.service';
import { ErrorService } from '../shared/services/error.service';
import { AddSurgeonDialogComponent } from '../shared/components/add-surgeon-dialog/add-surgeon-dialog.component';
import { HospitalService } from '../shared/services/hospital.service';
import { ParamedicalService } from '../shared/services/paramedical.service';
import { ReasonMapService } from '../shared/services/reasonMap.service';

@Component({
  selector: 'app-hrsuite',
  templateUrl: './hrsuite.component.html',
  styleUrls: ['./hrsuite.component.scss']
})
export class HrsuiteComponent implements OnInit, OnDestroy {
  // reference to the MatMenuTrigger in the DOM 
  @ViewChild(MatMenuTrigger, {static: false}) matMenuTrigger: MatMenuTrigger; 
  // we create an object that contains coordinates for the right click menu (on right click on doctor agenda)
  @ViewChild('moreActions', {static: false}) moreActionsButton: ElementRef; 
  public menuTopLeftPosition =  {x: '0', y: '0'} 

  pages: any[];
  navLinks: any[];
  headerMode: string = "calendars";
  actifPage: string = "Ibode / Ide / AS";
  headerPages: string[] = [];
  requestsCounter : number = 0;
  profilesType : string;
  currentRouteIsCommission: any;
  isUserHighLevel: boolean;
  public isUserHasLowLevelAccess: boolean
  public isNight: boolean = false
  public isInternResponsible: boolean;
  public importButtonName: string;
  isIade: boolean;
  isSurgeon: boolean;
  onHeaderModeChangeSubscription : Subscription;
  getNumberOfCRSubscription : Subscription;
  isSurgeonOpeningsRoomsSubscription : Subscription;
  isChangeSurgeonRoomsSubscription : Subscription;
  isChangeAnesthNeedsSubscription : Subscription;
  isSurgeonOpeningsRoomDetailsSubscription : Subscription;
  isAnesthResponsibleRoomDetailsSubscription: Subscription;
  private isPerInterventionsPageSubscription: Subscription;
  private reloadActiveRequestsCounterSubscription: Subscription;
  isAnesthNeedsPageSubscription: Subscription;
  nightToggleSubscription: Subscription;
  private routerSubscription: Subscription;
  isSurgeonOpeningsRooms: boolean;
  isPerInterventionsPage: boolean;
  isSurgeonOpeningsRoomDetails: boolean;
  isAnesthNeedsPage: boolean = false;
  isVacationPagebool: boolean ;
  isCadreDeBloclevel5: boolean;
  isAnesthResponsibleRoomDetails: boolean;
  public doesHospitalHaveCalendarsDataPipelineOption: boolean;
  isCadreDeBloc: boolean;
  isChangeAnesthNeeds: boolean = false;
  toggledEvents : boolean;
  toggledNight : boolean;
  public isMenuOpen: boolean = false;
  private menuSubscription: Subscription;
  isChirPage: boolean = false;
  public isIadeReslevel3: boolean
  private navigationSubscription: Subscription;

  get isThereNewModifs(): boolean{
    return false;
  }

  constructor(
    private calendarRequestService : CalendarRequestService,
    private storageService: StorageService,
    private userService: UserService,
    private navigationServ : NavigationService,
    private utilisService:UtilisService,
    private deactivateService:deactivateComponentService,
    private router: Router,
    public dialog: MatDialog,
    public hrsuiteService : HrsuiteService,
    private errorService : ErrorService,
    private hospitalService: HospitalService,
    private paramedicalService: ParamedicalService,
    private reasonMapService: ReasonMapService
  ) {
    this.doesHospitalHaveCalendarsDataPipelineOption = this.hospitalService.doesHospitalHaveCalendarsDataPipelineOption();
    this.isUserHighLevel = !this.userService.isAnesWithLowLevelAccess()
    this.isUserHasLowLevelAccess = this.userService.isUserHasLowLevelAccess();
    this.isIadeReslevel3 = this.userService.isIadRes() && this.userService.getLevelAccess() == 3;
    this.isCadreDeBloc = this.userService.isCadreBloc();
    this.isCadreDeBloclevel5 = this.userService.isCadreBloc() && this.userService.getLevelAccess() == 5;
    this.isIade = userService.isIad();
    this.isSurgeon = this.userService.isSurgeon();
    this.isInternResponsible = this.userService.isInternResponsible();
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.currentRouteIsCommission = val.url.includes(COMMISSION_REGULATION)
      }
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.isVacationPagebool = this.router.url.endsWith("par-salles");
        this.isAnesthNeedsPage = this.router.url.endsWith("besoins");
      }
    });

    this.pages = [];
    this.headerPages = [];

    this.navLinks = [
      {
        label: 'Ibode / Ide / AS', link: NURSE
      }, {
        label: 'Chirurgiens', link: SURGEON
      }, {
        label: 'Anesthésistes / Iade', link: ANESTHESIST_WITH_ACCENT
      }
    ];

    this.initImportButtonName();
    this.onHeaderModeChange()
    this.isSurgeonOpeningsRoomsSubjectListener();
    this.isAnesthNeedsSubjectListener();
    this.isSurgeonOpeningsRoomDetailsSubjectListener();
    this.isAnesthResponsibleRoomDetailsSubjectListener();
    this.isChangeSurgeonRoomsListener();
    this.isChangeAnesthNeedsListener();
    this.isPerInterventionsPageSubjectListener();
    this.subscribeToReloadActiveRequestsCounterSubject();
}

  ngOnInit(): void {
    this.setAuthorizedPages()
    this.setRelatedLinksActifToPages(this.router.url)
    this.subscribeToRouteChange()

    this.hrsuiteService.toggleNightSubject.subscribe(val => {
      this.isNight = val
    })

    this.updateIsChirPage();
  }

  subscribeToReloadActiveRequestsCounterSubject(): void {
    if (this.reloadActiveRequestsCounterSubscription) {
      this.reloadActiveRequestsCounterSubscription.unsubscribe();
    }

    this.reloadActiveRequestsCounterSubscription = this.hrsuiteService.reloadActiveRequestsCounterSubject.subscribe(() => {
      this.getNumberOfCR();
    });
  }

  public get isClosingRoom() {
    return this.hrsuiteService.isClosingRoom;
  }

  public get isSavingSurgeonOpenings() {
    return this.hrsuiteService.isSavingSurgeonOpenings;
  }

  public get isSavingAnesthNeeds() {
    return this.hrsuiteService.isSavingAnesthNeeds;
  }

  get isSurgeonPage(){   
    return this.actifPage === 'Chirurgiens'
  }

  public get showMoreActionsButton() {
    return (
      (this.doesHospitalHaveCalendarsDataPipelineOption && this.actifPage === 'Ibode / Ide / AS' && this.isCadreDeBloc) ||
      (!this.isUserHasLowLevelAccess && (!this.isSurgeonPage || !this.isInternResponsible)) ||
      (!this.isSurgeonPage && !this.isUserHasLowLevelAccess)
    );
  }

  initImportButtonName(): void {
    const CalendarsDataPipelineOption = this.userService.getCurrentUser().profile.hospitals[0].options.find((e) => {
      return e.name === OPTIONS.CALENDARS_DATA_PIPELINE;
    });

    let softwareName = CalendarsDataPipelineOption && CalendarsDataPipelineOption.softwareName ? CalendarsDataPipelineOption.softwareName : '';

    this.importButtonName = `Actualiser depuis ${softwareName}`;
  }

  isSurgeonOpeningsRoomsSubjectListener(){
    if (this.isSurgeonOpeningsRoomsSubscription) {
      this.isSurgeonOpeningsRoomsSubscription.unsubscribe();
    }

    this.isSurgeonOpeningsRoomsSubscription = this.hrsuiteService.isSurgeonOpeningsRoomsSubject.subscribe(result => {
      this.isSurgeonOpeningsRooms = result;
    })
  }

  isAnesthNeedsSubjectListener(){
    if (this.isAnesthNeedsPageSubscription) {
      this.isAnesthNeedsPageSubscription.unsubscribe();
    }

    this.isAnesthNeedsPageSubscription = this.hrsuiteService.isAnesthNeedsPageSubject.subscribe(result => {
      this.isAnesthNeedsPage = result;
    })
  }

  isSurgeonOpeningsRoomDetailsSubjectListener(){
    if (this.isSurgeonOpeningsRoomDetailsSubscription) {
      this.isSurgeonOpeningsRoomDetailsSubscription.unsubscribe();
    }

    this.isSurgeonOpeningsRoomDetailsSubscription = this.hrsuiteService.isSurgeonOpeningsRoomDetailsSubject.subscribe(result => {
      this.isSurgeonOpeningsRoomDetails = result;
    })
  }

  isAnesthResponsibleRoomDetailsSubjectListener(){
    if (this.isAnesthResponsibleRoomDetailsSubscription) {
      this.isAnesthResponsibleRoomDetailsSubscription.unsubscribe();
    }

    this.isAnesthResponsibleRoomDetailsSubscription = this.hrsuiteService.isAnesthResponsibleRoomDetailsSubject.subscribe(result => {
      this.isAnesthResponsibleRoomDetails = result;
    })
  }

  isPerInterventionsPageSubjectListener() {
    if (this.isPerInterventionsPageSubscription) {
      this.isPerInterventionsPageSubscription.unsubscribe();
    }

    this.isPerInterventionsPageSubscription = this.hrsuiteService.isPerInterventionsPageSubject.subscribe(result => {
      this.isPerInterventionsPage = result;
    })
  }

  setAuthorizedPages() {
    const currentUser: User = this.storageService.getUser();
    if (currentUser && currentUser.profile) {
      if (currentUser.levelOfAccess >= 2 && currentUser.levelOfAccess < 5 && this.userService.isAnesth()) {
        this.pages = this.navLinks.slice(1);
        if (this.userService.isIadRes()) {
          this.pages = this.navLinks.slice(1,3);
          this.pages[1].label = "Iade"
          this.actifPage = "Iade"
        }
      } else if (currentUser.levelOfAccess === 2 && currentUser.profile.position === 'IBODE'
        || currentUser.levelOfAccess === 4 && currentUser.profile.position === BLOCK_MANAGER) {
        this.pages = this.navLinks.slice(0, 2);
      } else if (currentUser.levelOfAccess === 5) {
        this.pages = this.navLinks;
      } else if (this.userService.isCadreBlocWithMediumLevel()) {
        this.pages = this.navLinks.slice(0, 2);
      } else if (currentUser.levelOfAccess === 1 && currentUser.profile.position === SURGEON) {
        this.pages.push(this.navLinks[1])
      }
      this.headerPages = this.pages.map(p => p.label)
    }
  }

  subscribeToRouteChange() {
    
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
    this.routerSubscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.setRelatedLinksActifToPages(event.urlAfterRedirects)
      this.updateIsChirPage();
    });
  }

  onHeaderModeChange(){
    if (this.onHeaderModeChangeSubscription) {
      this.onHeaderModeChangeSubscription.unsubscribe();
    }

    this.onHeaderModeChangeSubscription =  this.router.events.pipe(
      filter(
          (event) => event instanceof NavigationEnd || event instanceof Router
      )
    ).subscribe((navigationEnd : any) => {
      if(navigationEnd.url.includes("/hrsuite/demande-rh")){
        this.headerMode = "demande-rh"
      }else if(navigationEnd.url.includes("/hrsuite/commission-regulation")){
        this.headerMode = "commission-regulation"
      }else{
        this.headerMode = "calendars"
      }
    });
  }

  setRelatedLinksActifToPages(url) {
    if (!url) {
      return;
    }

    const isPerRoomsPage = url.includes(pages.parSalles) || url.includes(pages.parSallesBesoins);
    const isPerSurgeonsPage = url === pages.parPraticiens;
    const isPerInterventionsPage = url === pages.parInterventions;
    const isRoomDetailsPage = url.includes(`${pages.parSalles}/details`) || url.includes(`${pages.parSallesBesoins}/details`);

    const isAnesth = url.includes(pages.anesthesistes);
    const isInf = url.includes(pages.hrsuiteNurse);
    
    if (isPerRoomsPage || isPerSurgeonsPage || isRoomDetailsPage || isPerInterventionsPage) {
      this.actifPage = "Chirurgiens";
      this.profilesType = NURSE;
      if (isPerSurgeonsPage)
      {
        this.getNumberOfCR();
      }
    } else if (isAnesth) {
      if (this.userService.isIadRes()) {
        this.actifPage = "Iade";  
      } else {
        this.actifPage = "Anesthésistes / Iade";  
      }
      this.profilesType = ANESTHETIST;
      this.getNumberOfCR();
    } else if (isInf) {
      this.actifPage = "Ibode / Ide / AS";
      this.profilesType = NURSE;
      this.getNumberOfCR();
    }
  }

  setRelatedLinksActifToPage(label: string, value: boolean) {
    const page = this.pages.find(page => page.label === label)
    if (page) {
      page.relatedLinksActif = value
    }
  }

  navigateTo(link): Promise<boolean> {
    if(this.navigationSubscription) this.navigationSubscription.unsubscribe();
    return new Promise((resolve) => {
      this.navigationSubscription = this.goToNextEvent().subscribe(res => {
        if (res && res !== "cancel") {
          this.isNight = false
          this.navigationServ.navigateTo(pages.hrSuite + link.link, null, null, false)
            .then(() => {
              resolve(true);
            })
            .catch(error => {
              resolve(false);
            });
        } else if (res === false) {
          this.save();
          resolve(false);
        } else {
          resolve(false);
        }
      });
    });
  }

  isChangeSurgeonRoomsListener() {
    if (this.isChangeSurgeonRoomsSubscription) {
      this.isChangeSurgeonRoomsSubscription.unsubscribe();
    }

    this.isChangeSurgeonRoomsSubscription = this.hrsuiteService.isChangeSurgeonRoomsSubject.subscribe(isChange => {
      this.hrsuiteService.isChangeSurgeonRooms = isChange;
    })
  }

  isChangeAnesthNeedsListener() {
    if (this.isChangeAnesthNeedsSubscription) {
      this.isChangeAnesthNeedsSubscription.unsubscribe();
    }

    this.isChangeAnesthNeedsSubscription = this.hrsuiteService.isChangeAnesthNeedsSubject.subscribe(isChange => {
      this.isChangeAnesthNeeds = isChange;
    })
  }

  navigateToPage(page) {
    const previousPage = this.actifPage;
    const navigateToPage = this.pages.find(p => p.label === page);
    if (navigateToPage) {
      this.hrsuiteService.toggleNightSubject.next(false);
      this.navigateTo(navigateToPage).then(success => {
        if (success) {
          this.actifPage = navigateToPage.label;
          this.updateIsChirPage();
        } else {
          this.actifPage = previousPage;
        }
      }).catch(error => {
        this.actifPage = previousPage;
      });
    }
  }

  save() {
    this.deactivateService.switchDeactivate.next(true);
     //if(this.isThereNewModifs)
     //  this.currentChildComp.save();
  }

  goToNextEvent() {
    if (this.isThereNewModifs) return this.utilisService.openUnSavedChangesPopup()
    else return new Observable(sub => sub.next(true));
  }

  onActivate(componentRef) {
    // if(componentRef instanceof SwitchComponent)
    //   this.currentChildComp = componentRef
  }

  addProfileClick(): void {
    let closeRef;

    if (['Anesthésistes / Iade', 'Ibode / Ide / AS', 'Iade'].includes(this.actifPage)) {
      closeRef = this.dialog.open(AddProfileDialogComponent, {
        data: { type: this.profilesType, openType: 'ADD' }
      });
    } else {
      closeRef = this.dialog.open(AddSurgeonDialogComponent, {
        data: { openType: 'ADD' }
      });
    }
    closeRef.afterClosed().subscribe((result) => {
      if(result){
        this.hrsuiteService.profilesUpdateSubject.next(this.profilesType)
      }
    });
  }
  
  async openVacationPopup() {
    const dialogRef = this.dialog.open(addVacationHrsuitePopupComponent, {
      width: '600px',
      data: {
        position: await this.getPosition()
      }
    }
    );
    
    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  getNumberOfCR() {
    let period;

    if (this.hospitalService.doesHospitalHaveDayNightOption()) {
      period = this.isNight ? 'NIGHT' : 'DAY';
    }
    
    let position;
    if (this.actifPage === "Anesthésistes / Iade" || this.actifPage === "Iade") {
      position = ANESTHETIST;
    } else if (this.actifPage === "Chirurgiens") {
      position = SURGEON;
    } else {
      position = NURSE;
    }

    if (this.getNumberOfCRSubscription) {
      this.getNumberOfCRSubscription.unsubscribe();
    }
    
    this.getNumberOfCRSubscription = this.calendarRequestService.getActiveRequestsCounter(position, period).subscribe(res => {
      this.requestsCounter = res;
    }, error => this.errorService.handleError(error))
  }

  async getPosition() {
    if (this.actifPage === "Ibode / Ide / AS") {
      const formations = await this.paramedicalService.getFormations().toPromise()
      let resultArray = formations.map((formation) => formation)
      return resultArray;
      // return ["IBODE / IDE", "AS"]
    } else if(this.actifPage === "Anesthésistes / Iade") {
      if (this.isInternResponsible) {
        return ["Anesthésiste"];
      } else {
        return ["Anesthésiste", "IADE"]
      }
    } else {
      return [];
    }
  }

  openMoreActions(): void {
    if (!this.moreActionsButton) {
      return;
    }

    if (this.menuSubscription) {
      this.menuSubscription.unsubscribe();
    }

    this.menuSubscription = this.matMenuTrigger.menuClosed.subscribe(() => {
      this.isMenuOpen = false;
    })
    
    const rect = this.moreActionsButton.nativeElement.getBoundingClientRect();
    
    this.menuTopLeftPosition.x = (((rect.left - 225 + 41) * OPPOSITE_ZOOM_VALUE)) + 'px'; 
    this.menuTopLeftPosition.y = (rect.bottom * OPPOSITE_ZOOM_VALUE) + 'px';

    // we open the menu 
    this.matMenuTrigger.openMenu();
    this.isMenuOpen = true;
  }

  async openPopupRelaunchAvai() {
    this.utilisService.openPopup(RelaunchAvailabilityComponent, {
      width: '650px',
      data: {
        position: await this.getPosition()
      }

    })
  }

  changeView() {
    this.navigateToRequestPage();
    this.toggledNight = false;
  }

  navigateToRequestPage(){
    const state = { isNight: this.isNight };
    if (this.actifPage === "Ibode / Ide / AS") {
      this.navigationServ.navigateTo(pages.demandeRh + "/validation/" + NURSE, null, null, false, state);
    } else if(this.actifPage === "Anesthésistes / Iade" || this.actifPage === 'Iade') {
      this.navigationServ.navigateTo(pages.demandeRh + "/validation/" + ANESTHESIST_WITH_ACCENT, null, null, false, state);
    } else if(this.actifPage === "Chirurgiens") {
      this.navigationServ.navigateTo(pages.demandeRh + "/vacataire-validation/" + SURGEON, null, null, false);
    }
  }

  triggerAddIntervention(): void {
    this.hrsuiteService.addInterventionSubject.next(true)
  }

  triggerCloseRoom(): void {
    this.hrsuiteService.closeRoomSubject.next(true)
  }

  toggleEventRow(){
    this.toggledEvents = !this.toggledEvents;
    this.hrsuiteService.toggleEventsSubject.next(true)
  }

  saveRooms(){
    if (!this.hrsuiteService.isChangeSurgeonRooms) {
      this.hrsuiteService.saveRoomsSubject.next(true)
    }
  }

  refreshRooms(){
    if (!this.hrsuiteService.isChangeSurgeonRooms) {
      this.hrsuiteService.refreshRoomsSubject.next(true)
    }
  }

  saveAnesthNeeds(){
    if (this.isChangeAnesthNeeds) {
      this.hrsuiteService.saveAnesthNeedsSubject.next(true)
    }
  }

  refreshAnesthNeeds(){
    if (this.isChangeAnesthNeeds) {
      this.hrsuiteService.refreshAnesthNeedsSubject.next(true)
    }
  }

  triggerRequestCalendarsFromHospital() {
    this.hrsuiteService.requestCalendarsFromHospitalSubject.next();
  }

  updateIsChirPage() {
    if (this.actifPage === 'Chirurgiens'){
      this.isChirPage = true;
    } else {
      this.isChirPage = false;  
    }
  }

  ngOnDestroy(): void {
    if(this.onHeaderModeChangeSubscription){
      this.onHeaderModeChangeSubscription.unsubscribe()
    }

    if(this.getNumberOfCRSubscription){
      this.getNumberOfCRSubscription.unsubscribe();
    }

    if(this.isSurgeonOpeningsRoomsSubscription){
      this.isSurgeonOpeningsRoomsSubscription.unsubscribe();
    }

    if(this.isSurgeonOpeningsRoomDetailsSubscription){
      this.isSurgeonOpeningsRoomDetailsSubscription.unsubscribe();
    }

    if(this.isAnesthResponsibleRoomDetailsSubscription){
      this.isAnesthResponsibleRoomDetailsSubscription.unsubscribe();
    }

    if(this.isAnesthNeedsPageSubscription){
      this.isAnesthNeedsPageSubscription.unsubscribe();
    }

    if(this.isChangeSurgeonRoomsSubscription){
      this.isChangeSurgeonRoomsSubscription.unsubscribe();
    }

    if(this.isChangeAnesthNeedsSubscription){
      this.isChangeAnesthNeedsSubscription.unsubscribe();
    }

    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }

    if (this.isPerInterventionsPageSubscription) {
      this.isPerInterventionsPageSubscription.unsubscribe();
    }

    if (this.nightToggleSubscription) {
      this.nightToggleSubscription.unsubscribe();
    }

    if (this.reloadActiveRequestsCounterSubscription) {
      this.reloadActiveRequestsCounterSubscription.unsubscribe();
    }

    if (this.menuSubscription) {
      this.menuSubscription.unsubscribe();
    }

    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }

    this.hrsuiteService.toggleNightSubject.next(false);
  }

}
