import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef, MatPaginator, MatTableDataSource, MAT_DIALOG_DATA} from '@angular/material';
import {Subscription} from 'rxjs';
import {ErrorService} from 'src/app/shared/services/error.service';
import {startWith, switchMap} from 'rxjs/operators';
import {RealProgramService} from '../../services/real-program.service';
import * as moment from 'moment';

@Component({
  selector: 'app-intervention-inf-dialog',
  templateUrl: './intervention-inf-dialog.component.html',
  styleUrls: ['./intervention-inf-dialog.component.scss']
})

export class InterventionInfDialogComponent implements OnInit, OnDestroy {

  interventionsList = new MatTableDataSource<any>();
  public interventionColumns: string[] = ['surgeon', 'anesthesist', 'paramedical', 'date', 'act', 'urgence', 'room'];

  private paginator: MatPaginator;

  isLoading: boolean = true;

  pageIndex: number = 0;
  length: number;
  pageLimit: number = 10;

  nurseId: string;
  startDate: string;
  endDate: string;
  public interventionsCountWithoutSearch: number = 0;
  public searchMetadata: any;

  public getDataSubscription: Subscription;

  @ViewChild(MatPaginator, {static: false}) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
  }

  constructor(private errorService: ErrorService, public dialog: MatDialog, public dialogRef: MatDialogRef<InterventionInfDialogComponent>, private realProgramsService: RealProgramService, @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.nurseId = this.data.nurseId;
    this.startDate = this.data.startDate;
    this.endDate = this.data.endDate;
  }

  ngAfterViewInit() {
    if (this.paginator && this.paginator.page) {
      this.doFilter('');
    }
  }

  doFilter(searchString: string = ''): void {
    this.interventionsList.data = [];
    
    if (this.getDataSubscription) {
      this.getDataSubscription.unsubscribe();
    }

    this.getDataSubscription = this.paginator.page.pipe(
      startWith({}),
      switchMap(() => {
        this.isLoading = true;
        this.pageIndex = this.paginator.pageIndex;
        return this.realProgramsService.getRealProgramsInf(this.nurseId, searchString, this.startDate, this.endDate, this.pageLimit, this.pageIndex + 1, true)
      })
    ).subscribe((results: any) => {
      this.interventionsList.data = results.data.docs;
      this.length = results.data.totalDocs;
      if (searchString && searchString.length > 0) {
        this.searchMetadata = {
          count: this.length,
          total: results.interventionsCountWithoutSearch,
          matchPercentage: results.interventionsCountWithoutSearch > 0 ? ((this.length / results.interventionsCountWithoutSearch) * 100).toFixed(0) : 0
        };
      } else {
        this.searchMetadata = null;
      }
      this.isLoading = false;
    });
  }


  close(): void {
    this.dialogRef.close();
  }

  getDate(date) {
    return moment(date).format("DD/MM/YYYY")
  }

  isUrgence(intervention) {
    return intervention && intervention.isUrgences
  }

  ngOnDestroy() {
    if (this.getDataSubscription) {
      this.getDataSubscription.unsubscribe();
    }
  }
}
