import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { urlEndPoints } from '../config/end-points';
import { Observable } from 'rxjs';
import { Calendar, postCalendars } from '../models/calendar.model';
import { Profile } from '../models/profile.model';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  constructor(private httpClient: HttpClient) {
  }

  createDayAvailability(calendartoCreate: Calendar[], isExtraclinique : boolean): Observable<Calendar[]> {
    return this.httpClient.post<Calendar[]>(urlEndPoints.calendars, postCalendars(calendartoCreate, isExtraclinique));
  }

  changeDayAvailability(updatedCalendars: Calendar[], isExtraclinique : boolean): Observable<any> {
    return this.httpClient.put<Calendar[]>(urlEndPoints.calendars, postCalendars(updatedCalendars, isExtraclinique));
  }

  removeDayavailability(calendarId): Observable<any> {
    return this.httpClient.delete<any>(urlEndPoints.calendars + calendarId);
  }

  deleteMultipleCalendars(profileId, fromDate, toDate): Observable<any> {
    return this.httpClient.delete<any>(urlEndPoints.calendars + `many?profileId=${profileId}&fromDate=${fromDate}&toDate=${toDate}`);
  }

  getAnesthesIsteGarde(day?: String): Observable<any[]> {
    return this.httpClient.get<Profile[]>(urlEndPoints.calendars + 'service/isgarde/Anesthésiste?day=' + day);
  }

  getInfirmiereAstreinte(day?: String): Observable<Profile[]> {
    return this.httpClient.get<Profile[]>(urlEndPoints.calendars + 'service/isgarde/Infirmiere?day=' + day);
  }

  updatePeriodAvailability(calendars: Calendar[], fromDate: string, toDate: string, isExtraclinique: boolean): Observable<any> {
    let params = new HttpParams();
    params = params.append('fromDate', fromDate);
    params = params.append('toDate', toDate);
    return this.httpClient.post<Calendar[]>(urlEndPoints.calendars + 'updateCal', postCalendars(calendars, isExtraclinique)[0], { params });
  }

  getProfileGuardsWithCoWorkers(from: string, to: string, profileId: string): Observable<any> {
    return this.httpClient.get<any[]>(
      urlEndPoints.calendars +
        `guard/profile/${profileId}?fromDate=${from}&toDate=${to}`
    );
  }

  requestCalendarsFromHospital(fromDate: string, toDate: string, hospitals: string[]) {
    return this.httpClient.post<any>(urlEndPoints.calendars + `requestCalendarsFromHospital?fromDate=${fromDate}&toDate=${toDate}`, {
      hospitals: hospitals
    });
  }

  relaunchAvailability(body) {
    return this.httpClient.post<any>(urlEndPoints.calendars + `relaunchAvailability`, body)
  }

  checkDisponibilityService(profileId: string, isNight: boolean , from: string, to: string,) {
    return this.httpClient.get<any>(urlEndPoints.calendars + `checkDisponibilityService/${profileId}/${isNight}?fromDate=${from}&toDate=${to}`)
  }
  
  checkWorkTime(profileId: string, fromDate: string, toDate: string, startTime?: string, endTime?: string, hospitalId?: string) {
    const request = urlEndPoints.calendars +
    "checkWorkTime" +
    "/" + profileId +
    "?fromDate=" + fromDate +
    "&toDate=" + toDate +
    (startTime ? "&startTime=" + startTime : "") +
    (endTime ? "&endTime=" + endTime : "") +
    (hospitalId ? "&hospital=" + hospitalId : "");
    return this.httpClient.get<any>(request);
  }

  getWorkTime(profileId: string, fromDate: string, toDate: string, startTime?: string, endTime?: string, hospitalIds?: any[]) {
    const request = urlEndPoints.calendars +
    "getWorkTime" +
    "/" + profileId +
    "?fromDate=" + fromDate +
    "&toDate=" + toDate +
    (startTime ? "&startTime=" + startTime : "") +
    (endTime ? "&endTime=" + endTime : "") 
    return this.httpClient.post<any>(request, hospitalIds);
  }

  getLastUpdateDate(startDate: string, endDate: string, position :string): Observable<any> {
    return this.httpClient.get<any>(
      urlEndPoints.calendars +
        "lastUpdateDate?fromDate=" +
        startDate +
        "&toDate=" +
        endDate +
        "&position=" +
        position 
    );
  }
}

