import { Pipe, PipeTransform } from '@angular/core';
import { Iade } from '../const/glabals.const';

@Pipe({
  name: 'toIADE'
})
export class toIADEPipe implements PipeTransform {

  transform(strIade:string): any {
    return strIade && strIade.toLowerCase() === Iade.toLowerCase() ? Iade.toUpperCase() :  strIade;
  }

}
