import { Injectable } from '@angular/core';
import { RankingDal } from '../dal/ranking.dal';
import { Observable } from 'rxjs';
import { Rating } from '../models/rating.model';

@Injectable({
  providedIn: 'root'
})
export class RankingService {

  constructor(private rankingDal: RankingDal) { }

  getRatingBySpecialties(specialesIds: string[]): Observable<Rating[]> {
    return this.rankingDal.getRatingBySpecialties(specialesIds);
  }
  getSpecialtiesWithRanks(args?: {formation?: string, hospitalId?: string, isIadeResp?: boolean}): Observable<any> {
    return this.rankingDal.getSpecialtiesWithRanks(args);
  }

  getDefaultRatingsBySpecialtyName(specialtyName: string): Observable<Rating[]>{
    return this.rankingDal.getDefaultRatingsBySpecialtyName(specialtyName);
  }

  getRatingsBySpecialtyId(specialesId: string)  : Observable<Rating[]>{
    return this.rankingDal.getRatingsBySpecialtyId(specialesId);
  }
  
  getRatingsBySpecialtyName(specialtyName: string): Observable<Rating>{
    return this.rankingDal.getRatingsBySpecialtyName(specialtyName);
  }

  addUpdateRatingSystems(ratingSystems: any[]): Observable<any> {
    return this.rankingDal.addUpdateRatingSystems(ratingSystems);
  }

  updateRatingSystem(ratingSystem: Rating): Observable<Rating> {
    return this.rankingDal.updateRatingSystem(ratingSystem);
  }

  addRatingSystem(ratingSystem: Rating): Observable<Rating> {
    return this.rankingDal.addRatingSystem(ratingSystem);
  }
}
